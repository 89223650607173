import {
	AfterViewChecked,
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter, forwardRef, HostBinding, Input, OnChanges,
	OnInit,
	Output,
	Renderer2, SimpleChange, SimpleChanges, ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Helpers} from '../../../services/helpers';

@Component({
	selector: 'app-textarea-auto-sizing',
	templateUrl: './textarea-auto-sizing.component.html',
	styleUrls: ['./textarea-auto-sizing.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => TextareaAutoSizingComponent),
		multi: true
	}]
})
export class TextareaAutoSizingComponent implements OnInit, ControlValueAccessor {

	@Input() value: any;
	@Input() placeholder = '';
	@Input() maxHeight = -1;
	_value: FormControl;
	@Output() resizeEvent = new EventEmitter();
	@Input() enterSubmit = false;
	@Input() breakLine = false;
	@Input() focus = new EventEmitter();

	// Element
	@ViewChild('submitScreen') elBtnRef: ElementRef;
	@ViewChild('textareaEl') elTexteareaRef: ElementRef;

	textarea: any;
	textareaClone: any;
	offset: any;
	height: string;
	valueBack: string;
	maxlength: any;

	// Both onChange and onTouched are functions
	onChange: any = () => {};
	onTouched: any = () => {};

	constructor(
		private el: ElementRef,
		private renderer: Renderer2,
		private changeDetectorRef: ChangeDetectorRef
	) {
	}

	ngOnInit() {
		this.textarea = this.el.nativeElement.querySelector('textarea');
		this.offset = this.textarea.offsetHeight - this.textarea.clientHeight;
		this.height = 'auto';
		let textareaClone = this.el.nativeElement.querySelector('#textarea-resize').cloneNode(true);
		this.textareaClone = this.el.nativeElement.querySelector('.fake').appendChild(textareaClone);

		this._value = new FormControl();
		this._value.setValue('');
		this._value.valueChanges.subscribe(value => {

			if (value) {
				// Não deve ter quebra de linha
				if (this.enterSubmit) {

					if (this.breakLine) {
						this._value.setValue(value, {emitEvent: false});
						value = value;
					} else {
						this._value.setValue(value.replace(/\n/g, ''), {emitEvent: false});
						value = value.replace(/\n/g, '');
					}
				}
			}

			this.onChange(value);
			this.resizeTextarea();
		});

		this.maxlength = this.el.nativeElement.getAttribute('maxlength');

		this.focus.subscribe(() => {
			console.log('focus');
			this.elTexteareaRef.nativeElement.focus();
		});
	}

	onKeyDown(event) {
		if (event.code === 'Enter' || event.code === 'NumpadEnter') {
			if (this.enterSubmit) {
				this.elBtnRef.nativeElement.click();
				event.stopPropagation();
				event.preventDefault();
			}
		}
	}

	resizeTextarea() {
		let height = this.height;

		// Cria textarea fake, para testar se deve ser redimensinado
		this.textareaClone.value = this._value.value;
		this.textareaClone.style.height = 'auto';
		if (this.maxHeight !== -1) {
			if (this.textareaClone.scrollHeight + this.offset > this.maxHeight) {
				this.textareaClone.style.height = this.maxHeight + 'px';
			} else {
				this.textareaClone.style.height = (this.textareaClone.scrollHeight + this.offset) + 'px';
			}
		} else {
			this.textareaClone.style.height = (this.textareaClone.scrollHeight + this.offset) + 'px';
		}

		this.height = this.textareaClone.style.height;
		this.resizeEvent.emit();

		if (height !== this.height) {
			this.resizeEvent.emit();
		}
	}

	updateChanges() {
		if (this.enterSubmit && this._value.value) {
			if (this.breakLine) {
				this.onChange(this._value.value);
			} else {
				this.onChange(this._value.value.replace(/\n/g, ''));
			}
		} else {
			this.onChange(this._value.value);
		}
	}

	///////////////
	// OVERRIDES //
	///////////////

	/**
	 * Writes a new item to the element.
	 * @param value the value
	 */
	writeValue(value: number): void {
		this._value.setValue(value);
		this.updateChanges();
	}

	/**
	 * Registers a callback function that should be called when the control's value changes in the UI.
	 * @param fn
	 */
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	/**
	 * Registers a callback function that should be called when the control receives a blur event.
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

}
