import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var TaskListSharedService = /** @class */ (function () {
    function TaskListSharedService() {
        this.count = 0;
        this.messageSource = new BehaviorSubject(0);
        this.event = this.messageSource.asObservable();
    }
    TaskListSharedService.prototype.refresh = function () {
        this.count++;
        this.messageSource.next(this.count);
    };
    TaskListSharedService.ngInjectableDef = i0.defineInjectable({ factory: function TaskListSharedService_Factory() { return new TaskListSharedService(); }, token: TaskListSharedService, providedIn: "root" });
    return TaskListSharedService;
}());
export { TaskListSharedService };
