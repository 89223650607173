export class Helpers {

	/**
	 * Const filterArray
	 */
	static WITH_ZERO = 0;


	static unique_hash() {
		// Math.random should be unique because of its seeding algorithm.
		// Convert it to base 36 (numbers + letters), and grab the first 9 characters
		// after the decimal.
		return '_' + Math.random().toString(36).substr(2, 9);
	}

	static scrollToLeft(element: Element, to, duration) {
		let start = element.scrollLeft,
			change = to - start,
			currentTime = 0,
			increment = 20;

		let animateScroll = function () {
			currentTime += increment;
			let val = Helpers.easeInOutQuad(currentTime, start, change, duration);
			element.scrollLeft = val;
			if (currentTime < duration) {
				setTimeout(animateScroll, increment);
			}
		};
		animateScroll();
	}

	static easeInOutQuad(t, b, c, d) {
		t /= d / 2;
		if (t < 1) {
			return c / 2 * t * t + b;
		}

		t--;

		return -c / 2 * (t * (t - 2) - 1) + b;
	}

	/**
	 * numberFormat
	 * @param number
	 * @param {number} decimals
	 * @param {string} decPoint
	 * @param {string} thousandsSep
	 * @returns {string}
	 */
	static numberFormat(number, decimals = 2, decPoint = ',', thousandsSep = '.') {
		number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
		let n = !isFinite(+number) ? 0 : +number;
		let prec = !isFinite(+decimals) ? 2 : Math.abs(decimals);
		let sep = (typeof thousandsSep === 'undefined') ? '.' : thousandsSep;
		let dec = (typeof decPoint === 'undefined') ? ',' : decPoint;

		let toFixedFix = function (_n, _prec) {
			let k = Math.pow(10, _prec);
			return '' + (Math.round(_n * k) / k)
				.toFixed(_prec);
		};


		let s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');

		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}

		if ((s[1] || '').length < prec) {
			s[1] = s[1] || '';
			s[1] += new Array(prec - s[1].length + 1).join('0');
		}

		return s.join(dec);
	}


	/**
	 * Remove valores null de arrays e objetos
	 * @param array
	 * @returns {{}}
	 */
	static removeNull(array) {

		let newArray = {};

		for (let i in array) {
			if (array[i]) {
				newArray[i] = array[i];
			}
		}

		return newArray;
	}

	/**
	 * Apenas números
	 */

	static onlyNumbers(string: string): string {
		if (string === null){
			return '';
		}

		string = string.toString();
		return string.replace(/[^0-9]/g, '');
	}

	/**
	 * Remove valores empty
	 * @param array
	 * @param options
	 * @returns {{}}
	 */
	static filterArray(array, options = []) {

		let newArray = {};

		for (let i in array) {
			if (!Helpers.empty(array[i])) {
				newArray[i] = array[i];
			}

			// Não deve remover 0
			if (options.indexOf(Helpers.WITH_ZERO) !== -1 && (array[i] === 0 || array[i] === '0')) {
				newArray[i] = array[i];
			}
		}

		return newArray;
	}

	/**
	 * Remove valores empty
	 * @param array
	 * @returns {{}}
	 */
	static filterObject(array) {

		let _array = {...array};
		let newArray = {};

		for (let i in _array) {

			if (typeof _array[i] === 'object') {
				_array[i] = Helpers.filterObject(_array[i]);
			}

			if (!Helpers.empty(_array[i])) {
				newArray[i] = _array[i];
			}
		}

		return newArray;
	}

	/**
	 * Normaliza String
	 * @param {string} string
	 * @returns {string}
	 */
	static normalizeString(string: string): string {
		string = string
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.toLocaleLowerCase();

		return string;
	}

	/**
	 * Empty variable
	 * @param {string} string
	 * @returns {string}
	 */
	static empty(mixedVar): boolean {
		//  discuss at: http://locutus.io/php/empty/
		// original by: Philippe Baumann
		//    input by: Onno Marsman (https://twitter.com/onnomarsman)
		//    input by: LH
		//    input by: Stoyan Kyosev (http://www.svest.org/)
		// bugfixed by: Kevin van Zonneveld (http://kvz.io)
		// improved by: Onno Marsman (https://twitter.com/onnomarsman)
		// improved by: Francesco
		// improved by: Marc Jansen
		// improved by: Rafał Kukawski (http://blog.kukawski.pl)
		//   example 1: empty(null)
		//   returns 1: true
		//   example 2: empty(undefined)
		//   returns 2: true
		//   example 3: empty([])
		//   returns 3: true
		//   example 4: empty({})
		//   returns 4: true
		//   example 5: empty({'aFunc' : function () { alert('humpty'); } })
		//   returns 5: false

		let undef;
		let key;
		let i;
		let len;
		let emptyValues = [undef, null, false, 0, '', '0'];

		for (i = 0, len = emptyValues.length; i < len; i++) {
			if (mixedVar === emptyValues[i]) {
				return true;
			}
		}

		if (typeof mixedVar === 'object') {
			for (key in mixedVar) {
				if (mixedVar.hasOwnProperty(key)) {
					return false;
				}
			}
			return true;
		}

		return false;
	}

	/**
	 * Check Email
	 * @param mixedVar
	 * @returns {boolean}
	 */
	static verifyEmail(email): boolean {
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		return re.test(String(email).toLowerCase());
	}

	/**
	 * Check Email
	 * @param mixedVar
	 * @returns {boolean}
	 */
	static verifyPhone(email): boolean {
		if (!email) {
			return false;
		}
		return email.length >= 10 && email.length <= 11;
	}

}
