import { LocationCitiesService } from 'src/app/content/models/location-cities.service';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';

@Injectable()
export class EquivalenceCityResolverService implements Resolve<any> {

	constructor(
		private _citiesService: LocationCitiesService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {
		let params = {...route.queryParams};
		return this._citiesService.search(params);
	}
}
