var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { EquivalenceNotFoundService } from '../models/equivalence-not-found.service';
var EquivalenceNotFoundResolverService = /** @class */ (function () {
    function EquivalenceNotFoundResolverService(equivalenceNotFoundService) {
        this.equivalenceNotFoundService = equivalenceNotFoundService;
    }
    EquivalenceNotFoundResolverService.prototype.resolve = function (route) {
        var params = __assign({}, route.queryParams);
        return this.equivalenceNotFoundService.search(params);
    };
    return EquivalenceNotFoundResolverService;
}());
export { EquivalenceNotFoundResolverService };
