import {Component, OnDestroy, OnInit} from '@angular/core';
import {Calendar} from '../../../services/calendar';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {Global} from '../../../services/global';
import {AgendaService} from '../../models/agenda.service';
import * as moment from 'moment';
import {ModalContactDuplicateService} from '../../shareds/modal-contact-duplicate.service';
import {CalendarSharedService} from '../../shareds/calendar.service';
import {skip, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AgendaResolver} from '../../resolves/agenda-resolver.service';

@Component({
	selector: 'app-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {

	date: moment.Moment;
	currentYear: any;
	currentMonth: any;
	daysShort: string [];
	monthNames: string [];
	calendar: any [] = [];
	calendarService: Calendar;

	monthName: string;

	filters: Partial<Filters> = {
		type_in: [],
		user_id_in: [],
	};

	users: any;
	commitments: any;

	searching = false;
	starting = true;

	actions = [
		{
			id: 'add',
			body: '<i class="fas fa-plus"></i>'
		}
	];

	// Destruir todos os subscrive
	private destroy$ = new Subject();

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private agendaService: AgendaService,
		private router: Router,
		private modalContactDuplicateService: ModalContactDuplicateService,
		private calendarSharedService: CalendarSharedService,
		private _agendaResolver: AgendaResolver,
	) {

		this._agendaResolver.resolve(this.route.snapshot).subscribe((agenda) => {
			this.loadCalendar(agenda);
			this.init();
			this.starting = false;
		}, () => {

		});


	}

	ngOnInit() {

	}

	init() {

		this.calendarService = new Calendar();

		this.users = Global.info.users_admin;

		this.calendarSharedService
			.event
			.pipe(skip(1))
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.refresh(true);
			});

		this.date = moment();

		this.daysShort = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];
		this.monthNames = [
			'Janeiro',
			'Fevereiro',
			'Março',
			'Abril',
			'Maio',
			'Junho',
			'Julho',
			'Agosto',
			'Setembro',
			'Outubro',
			'Novembro',
			'Dezembro'
		];

		this.calendar = this.calendarService.getMonth(this.currentMonth, this.currentYear);

		this.setMonth();

	}

	setAction(action: any) {
		if (action.id === 'add') {
			this.router.navigate(['/calendar/add']);
		}
	}

	/**
	 * Define tipos
	 * @param {string} type
	 */
	setTypes(type: string) {
		if (this.filters.type_in.indexOf(type) === -1) {
			this.filters.type_in.push(type);
		} else {
			this.filters.type_in.splice(this.filters.type_in.indexOf(type), 1);
		}

		this.refresh();
	}

	/**
	 * Define usuarios
	 * @param {number} user
	 */
	setUsers(user: number) {
		if (this.filters.user_id_in.indexOf(user) === -1) {
			this.filters.user_id_in.push(user);
		} else {
			this.filters.user_id_in.splice(this.filters.user_id_in.indexOf(user), 1);
		}

		this.refresh();
	}

	loadCalendar(data: any) {
		this.commitments = data.results;
	}

	/**
	 * Atualizar calendario
	 * @returns {Subscription}
	 */
	refresh(cleanFilters: boolean = false) {
		this.searching = true;

		if (cleanFilters) {
			this.filters = {};
		}


		this.filters.root = {
			'organize_by_day': true
		};

		this.filters.limit = 200;

		this.filters.date_gt = moment(this.date)
			.subtract('1', 'M')
			.format('YYYYMM') + '20000000';

		this.filters.date_lt = moment(this.date)
			.add('1', 'M')
			.format('YYYYMM') + '11000000';

		return this.agendaService
			.search(this.filters)
			.subscribe(resp => {
				this.searching = false;
				this.setMonth();
				this.loadCalendar(resp);
			});
	}

	/**
	 * Voltar Mês
	 * @returns {Subscription}
	 */
	lastMonth() {
		this.date.subtract('1', 'M');
		return this.refresh();
	}

	/**
	 * Proximo Mês
	 * @returns {Subscription}
	 */
	nextMonth() {
		this.date.add('1', 'M');
		return this.refresh();
	}

	/**
	 * Definir mês
	 */
	setMonth() {
		this.currentYear = this.date.year();
		this.currentMonth = this.date.month();

		this.monthName = this.monthNames[this.currentMonth];

		this.calendar = this.calendarService.getMonth(this.currentMonth, this.currentYear);
	}

	openEventAdd() {
		this.modalContactDuplicateService.open('event-add');
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

}

interface Filters {
	root?: any;
	limit?: number;
	date_gt?: string;
	date_lt?: string;
	type_in: string [];
	user_id_in: number [];
}
