import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { mergeMapTo } from "rxjs/operators";
import { take } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
// import * as firebase from 'firebase';
import { PushTokenService } from "../content/models/push-token.service";
import { browser } from "protractor";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";

declare var PushNotification: any;

@Injectable()
export class MessagingService {
	currentMessage = new BehaviorSubject(null);

	constructor(
		private router: Router,
		// private angularFireDB: AngularFireDatabase,
		// private angularFireAuth: AngularFireAuth,
		private pushTokenService: PushTokenService // private angularFireMessaging: AngularFireMessaging
	) {
		// this.angularFireMessaging.messaging.subscribe(
		// 	(_messaging) => {
		// 		_messaging.onMessage = _messaging.onMessage.bind(_messaging);
		// 		_messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
		// 	}
		// );
	}

	/**
	 * update token in firebase database
	 *
	 * @param userId userId as a key
	 * @param token token as a value
	 */
	updateToken(userId, token) {
		// we can change this function to request our backend service
		// this.angularFireAuth.authState.pipe(take(1)).subscribe(
		// 	() => {
		// 		const data = {};
		// 		data[userId] = token;
		// 		this.angularFireDB.object('fcmTokens/').update(data);
		// 	});
	}

	/**
	 * request permission for notification from firebase cloud messaging
	 *
	 * @param userId userId
	 */
	requestPermission() {
		if (environment.mobile) {
			if (window["direct_to"]) {
				this.router.navigateByUrl(window["direct_to"]);
			}

			window.addEventListener(
				"direct_to",
				e => {
					console.log(window["direct_to"]);
					this.router.navigateByUrl(window["direct_to"]);
				},
				false
			);

			// Deleta Canal
			PushNotification.deleteChannel(
				() => {},
				() => {},
				"bdi"
			);

			// Cria novamente
			PushNotification.createChannel(
				() => {
					// alert('createChannel success');
				},
				() => {
					// alert('createChannel error');
				},
				{
					id: "bdi2",
					description: "Banco dos imoveis N",
					importance: 5,
					sound: "notification_sound",
					vibration: [100, 200, 300, 400, 500, 400, 300, 200, 400]
				}
			);

			const push = PushNotification.init({
				android: {
					vibrate: true,
					sound: true,
					forceShow: true
				},
				ios: {
					gcmSandbox: false,
					sound: true,
					vibration: true,
					badge: true,
					alert: true
				},
				windows: {}
			});

			// Salva Push Token do Android
			push.on("registration", data => {
				// alert(data.registrationId);
				return this.pushTokenService
					.create({
						token: data.registrationId,
						type: "FCM",
						device: "android"
					})
					.subscribe(resp => {
						console.log(resp);
					});
			});

			// Salva Push Token do Android
			push.on("notification", data => {
				// alert(JSON.stringify(data));

				// push.clearNotification(() => {
				// 	console.log('success');
				// }, () => {
				// 	console.log('error');
				// }, 145);

				if (!data.additionalData.foreground) {
					// Redirecionamento para página
					if (data.additionalData.redirect) {
						this.router.navigateByUrl(data.additionalData.redirect);
					}
				}
			});
		} else {
			// this.angularFireMessaging.requestToken.subscribe(
			// 	(token) => {
			// 		return this.pushTokenService.create({
			// 			token: token,
			// 			type: 'FCM',
			// 			device: 'browser'
			// 		}).subscribe(resp => {
			// 			console.log(resp);
			// 		});
			//
			// 	},
			// 	(err) => {
			// 		console.error('Unable to get permission to notify.', err);
			// 	}
			// );
		}
	}

	/**
	 * hook method when new notification received in foreground
	 */
	receiveMessage() {
		// this.angularFireMessaging.messages.subscribe(
		// 	(payload) => {
		// 		console.log('new message received. ', payload);
		// 		this.currentMessage.next(payload);
		// 	});
	}
}
