import * as io from 'socket.io-client';
import { environment } from '../../environments/environment';
import { WebsocketService } from '../content/shareds/websocket.service';
import { Global } from './global';
import * as i0 from "@angular/core";
var WebSocket = /** @class */ (function () {
    /**
     * Constructor
     * @param {WebsocketService} websocketService
     */
    function WebSocket() {
    }
    /**
     * Enviar Mensagem
     * @param data
     * @param {boolean} save
     * @returns {boolean}
     */
    WebSocket.sendMessage = function (data, save) {
        if (save === void 0) { save = false; }
        if (!Global.user) {
            return;
        }
        data.request_id = Global.user.id + '_' + this.messageID++;
        if (!WebSocket.socket || !WebSocket.socket.connected) {
            if (save) {
                WebSocket.queuePackets.push(data);
            }
            return false;
        }
        if (WebSocket.socket.connected) {
            WebSocket.socket.emit('message', data);
        }
        return data.request_id;
    };
    /**
     * Conecta com servidor
     * @param {string} token
     */
    WebSocket.connect = function (data) {
        WebSocket.socket = io(environment.SERVER, {
            query: "token=" + data.token
        });
        WebSocket.socket.on('connect', function () {
            console.log('Conectado');
            WebsocketService.changeStatus('connect');
            if (WebSocket.room) {
                WebSocket.sendMessage(WebSocket.room);
            }
            /**
             * Envia todas as mensagens
             */
            for (var i in WebSocket.queuePackets) {
                console.log(WebSocket.queuePackets[i]);
                WebSocket.sendMessage(WebSocket.queuePackets[i]);
            }
            WebSocket.queuePackets = [];
        });
        WebSocket.socket.on('message', WebSocket.message);
        WebSocket.socket.on('disconnect', WebSocket.disconnect);
        WebSocket.socket.on('reconnecting', WebSocket.reconnecting);
    };
    WebSocket.setRoom = function (room, _id) {
        WebSocket.room = {
            type: 'room',
            content: {
                body: {
                    name: room,
                    id: _id,
                }
            }
        };
        WebSocket.sendMessage(WebSocket.room);
    };
    WebSocket.message = function (data) {
        console.log(data);
        WebsocketService.changeMessage(data);
        if (data.type === 'mutation') {
            WebsocketService.changeMutation(data);
        }
        if (data.type === 'event') {
            WebsocketService.changeEvents(data);
        }
    };
    WebSocket.disconnect = function () {
        WebsocketService.changeStatus('disconnect');
    };
    WebSocket.reconnecting = function () {
        console.log('Reconectando');
        WebsocketService.changeStatus('reconnecting');
    };
    WebSocket.isConnected = function () {
        return WebSocket.socket.connected;
    };
    WebSocket.messageID = 0;
    WebSocket.queuePackets = [];
    WebSocket.socket = false;
    WebSocket.ngInjectableDef = i0.defineInjectable({ factory: function WebSocket_Factory() { return new WebSocket(); }, token: WebSocket, providedIn: "root" });
    return WebSocket;
}());
export { WebSocket };
