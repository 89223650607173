<div class="modal-screen modal-select-properties change-mobile-page">

	<div class="modal-body d-flex">

		<div class="header-modal">
			<div class="i-close" (click)="close()">
				<div class="icon-close-only black s32x32 display-close"></div>
				<img src="assets/svg/home.svg" class="display-back">
			</div>

			<div class="title">
				Criar chat de parceria
			</div>
		</div>

		<div class="search-list-container">

			<div class="row m-0" *ngIf="error">
				<div class="col-12">
					<div class="text-error-form">
						{{ error }}
					</div>
				</div>
			</div>

			<form [formGroup]="form" (submit)="clean()" class="position-relative">

				<div class="loading" *ngIf="creating">
					<div class="loading-icon">
						<div class="load">
							<div></div>
						</div>
					</div>
				</div>

				<div class="row m-0 pt-3 pb-2" *ngIf="MASTER && contacts.length">

					<div class="col">
						<div class="field-title"><i _ngcontent-c3="" class="far fa-envelope-open"></i> Contatos
							associados</div>
						<div *ngFor="let contact of contacts" class="table" style="
    border: 1px dashed #d2d2d2ee;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
">
							<div>

								<div class="td td-min p-1">
									<app-initials [name]="contact.name"></app-initials>
								</div>

								<div class="td p-1">
									<div class="name text-primary">{{contact.name}}</div>
									<div class="phone" *ngIf="contact.phones.length">
										{{contact.phones[0].number || phone}}
									</div>
									<div class="email" *ngIf="contact.email">{{contact.email}}</div>
								</div>

								<div class="td">
								</div>
							</div>

							<div class="tickets-users" *ngIf="contact.tickets_users && contact.tickets_users.length">
								<div *ngFor="let user of contact.tickets_users">
									<app-initials [name]="user.name" [clean]="true" size="xs"></app-initials>
									{{user.name}} - <span class="color-orange">{{user.realestate.name}}</span>
								</div>
							</div>
						</div>
					</div>

					<hr>
				</div>

				<!-- Imóvel -->
				<div class="row m-0 pb-2" *ngIf="property">
					<div class="col">
						<div class="name-input">
							Parceria do Imóvel
						</div>
						<div>
							<app-properties-card-simple [property]="property" [link]="false">
							</app-properties-card-simple>
						</div>
					</div>
				</div>
				<!--// Imóvel -->

				<!-- Demanda de Cliente -->
				<div class="row m-0 pb-2" *ngIf="singleContact">
					<div class="col">
						<div class="card clients-shared-card">
							<div class="title-demand">
								<i class="far fa-address-card"></i>
								Cliente #{{singleContact.id}}
							</div>

							<div class="info">
								<div class="info-key">Localização buscada:</div>
								<div class="info-value">{{singleContact.location.neighborhood}},
									{{singleContact.location.city}} -
									{{singleContact.location.estate}}</div>
							</div>

							<div class="info">
								<div class="info-key">Imóvel buscado:</div>
								<div class="info-value">{{singleContact.types_name}}</div>
							</div>

							<div class="info">
								<div class="info-key">Valor buscado:</div>
								<div class="info-value">Até
									{{singleContact.price | price}}
								</div>
							</div>

							<div class="compositions">
								<div class="composition">
									<i class="fas fa-bath"></i>
									<div class="number">{{ singleContact.area_total }}m²</div>
								</div>
								<div class="composition">
									<i class="fas fa-bed"></i>
									<div class="number">{{ singleContact.bedroom }}</div>
								</div>
								<div class="composition">
									<i class="fas fa-car-side"></i>
									<div class="number">{{ singleContact.vacancy }}</div>
								</div>
								<div class="composition">
									<i class="fas fa-bath"></i>
									<div class="number">{{ singleContact.bathroom }}</div>
								</div>
							</div>

						</div>
					</div>
				</div>
				<!--// Demanda de Cliente -->

				<!-- Demanda de Imóvel -->
				<div class="row m-0 pb-2" *ngIf="singleProperty">
					<div class="col">
						<div class="card property-demand-card">
							<div class="title-demand">
								<i class="fas fa-home"></i> Demanda #{{singleProperty.id}}
							</div>

							<div class="info">
								<div class="info-key">Localização buscada:</div>
								<div class="info-value">{{singleProperty.location.neighborhood}},
									{{singleProperty.location.city}} -
									{{singleProperty.location.estate}}</div>
							</div>

							<div class="info">
								<div class="info-key">Imóvel buscado:</div>
								<div class="info-value">{{singleProperty.types_name}}</div>
							</div>

							<div class="info">
								<div class="info-key">Valor buscado:</div>
								<div class="info-value">Até R$ {{singleProperty.price | price}}</div>
							</div>

							<div class="compositions">
								<div class="composition">
									<i class="fas fa-bath"></i>
									<div class="number">{{ singleProperty.area }}m²</div>
								</div>
								<div class="composition">
									<i class="fas fa-bed"></i>
									<div class="number">{{ singleProperty.bedroom }}</div>
								</div>
								<div class="composition">
									<i class="fas fa-car-side"></i>
									<div class="number">{{ singleProperty.vacancy }}</div>
								</div>
								<div class="composition">
									<i class="fas fa-bath"></i>
									<div class="number">{{ singleProperty.bathroom }}</div>
								</div>
							</div>

						</div>
					</div>
				</div>
				<!--// Demanda de Imóvel -->

				<div class="row m-0">
					<div class="col">
						<label>
							<input type="checkbox" formControlName="caption">
							Captação
						</label>
					</div>
				</div>

				<div class="row m-0">
					<div class="col">
						<label>
							<input type="checkbox" formControlName="administration">
							Administração
						</label>
					</div>
				</div>

				<br><br>
				<b>
					Ativar contato posteriormente:
				</b>

				<div class="row m-0">
					<div class="col">
						<label>
							<input type="checkbox" formControlName="lazy_contact">
							Ativar contato posteriormente
						</label>
					</div>
				</div>

				<div class="row m-0" *ngIf="MASTER && !singleContact">
					<div class="col">
						<div class="field-container pr-sm-0"
							[ngClass]="{'error-input': isFieldValid('users_participants')}">
							<app-input-multi-users [allUsers]="true" [showRealEstate]="true"
								formControlName="users_participants">
							</app-input-multi-users>

							<div class="name">
								Participantes
							</div>
						</div>

						<app-field-error-display errorMsg="Esse campo é obrigatório."
							[displayError]="isFieldValid('users_participants')">
						</app-field-error-display>
					</div>
				</div>

				<div class="row m-0" *ngIf="!contacts.length && !singleContact && !singleProperty && !property">
					<div class="col-12">
						<div class="field-container" [ngClass]="{'error-input': isFieldValid('name')}">
							<input type="text" formControlName="name">
							<div class="name">Titulo</div>
						</div>
						<app-field-error-display errorMsg="Esse campo é obrigatório."
							[displayError]="isFieldValid('name')">
						</app-field-error-display>
					</div>
				</div>

				<div class="row m-0">
					<div class="col-12">
						<div class="field-container" [ngClass]="{'error-input': isFieldValid('message')}">
							<!--<input type="text" formControlName="message" [placeholder]="placeHolder">-->
							<app-textarea-auto-sizing [maxHeight]="100" [enterSubmit]="false" [breakLine]="true"
								[placeholder]="placeHolder" formControlName="message">
							</app-textarea-auto-sizing>

							<div class="name">Mensagem</div>
						</div>

						<app-field-error-display errorMsg="Esse campo é obrigatório."
							[displayError]="isFieldValid('message')">
						</app-field-error-display>
					</div>
				</div>

				<div class="row m-0"
					*ngIf="MASTER && !contacts.length && !singleContact && !singleProperty && !property">
					<div class="col-12">
						<div class="field-container" [ngClass]="{'error-input': isFieldValid('contact_name')}">
							<input type="text" formControlName="contact_name">
							<div class="name">Nome do contato</div>
						</div>
						<app-field-error-display errorMsg="Informe o nome do contato"
							[displayError]="isFieldValid('contact_name')">
						</app-field-error-display>
					</div>
				</div>

				<div class="row m-0"
					*ngIf="MASTER && !contacts.length && !singleContact && !singleProperty && !property">
					<div class="col-12">
						<div class="field-container" [ngClass]="{'error-input': isFieldValid('contact_phone')}">
							<input type="text" formControlName="contact_phone" appMaskPhone>
							<div class="name">Telefone do contato</div>
						</div>

						<app-field-error-display errorMsg="Informe um telefone válido"
							[displayError]="isFieldValid('contact_phone')">
						</app-field-error-display>
					</div>
				</div>

				<div class="row m-0"
					*ngIf="MASTER && !contacts.length && !singleContact && !singleProperty && !property">
					<div class="col-12">
						<div class="field-container" [ngClass]="{'error-input': isFieldValid('contact_email')}">
							<input type="text" formControlName="contact_email">
							<div class="name">Email do contato</div>
						</div>
						<app-field-error-display errorMsg="Informe um email válido"
							[displayError]="isFieldValid('contact_email')">
						</app-field-error-display>
					</div>
				</div>

				<div class="text-center pt-2">
					<button class="btn btn-primary" (click)="create()" type="button">
						Criar Conversa
					</button>
				</div>
			</form>
		</div>

	</div>
</div>
