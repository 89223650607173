import { Component, forwardRef, OnChanges, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Helpers } from '../../../services/helpers';

@Component({
	selector: 'input-number-increment',
	templateUrl: './input-number-increment.component.html',
	styleUrls: ['./input-number-increment.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => InputNumberIncrementComponent),
		multi: true
	}]
})
export class InputNumberIncrementComponent implements OnInit, OnChanges, ControlValueAccessor {

	value: any;

	@Input() error = false;
	@Input() limit: number;
	@Output() change = new EventEmitter();
	firstTime = true;

	// Both onChange and onTouched are functions
	onChange: any = () => {
	}
	onTouched: any = () => {
	}

	constructor() {

	}

	ngOnInit() {
		this.writeValue('25');
		if (this.limit < this.value) {
			this.value = this.limit;
		}
	}


	ngOnChanges(): void {
	}

	plus() {
		this.value = Math.floor(this.value) + 1;
		if (this.limit < this.value) {
			this.value = 0;
		}
		this.updateChange();
	}

	minus() {
		this.value = Math.floor(this.value) - 1;

		if (!!this.limit && this.value < 0) {
			this.value = this.limit;
		} else if (this.value < 0) {
			this.value = 0;
		}

		this.updateChange();
	}

	/**
	 * Update Model
	 * @param {KeyboardEvent} event
	 */
	updateChange() {
		this.onChange(this.value);
		this.change.emit('');
	}

	@HostListener('input', ['$event'])
	onKeyup($event: any) {

		// let selectionStart = $event.target.selectionStart;
		// let selectionEnd = $event.target.selectionEnd;

		this.value = Helpers.onlyNumbers($event.target.value);
		this.value = Math.floor(this.value);

		if (this.limit < this.value) {
			this.value = this.limit;
		}

		$event.target.value = this.value;
		this.updateChange();
	}

	///////////////
	// OVERRIDES //
	///////////////

	/**
	 * Writes a new item to the element.
	 * @param value the value
	 */
	writeValue(value: any): void {
		if (value === undefined) {
			value = 0;
		}

		this.value = value;
		this.updateChange();
	}

	/**
	 * Registers a callback function that should be called when the control's value changes in the UI.
	 * @param fn
	 */
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	/**
	 * Registers a callback function that should be called when the control receives a blur event.
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
	}


}
