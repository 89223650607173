
<div class="page-flex">

	<div class="flex-header">
		<app-nav-bar [title]="'Configurações Gerais'">
		</app-nav-bar>
	</div>

	<div class="flex-body">
		<div class="max-width">
			<form [formGroup]="form">
				<div class="card p-4">
					<div class="title-form">
						Informações da imobiliária
						<small>Informações referentes a sua imobiliária.</small>
					</div>

					<div class="row">
						<div class="col-md-4">
							<div class="field-container" [class.error-input]="isFieldValid('name')">
								<div>
									<input type="text" formControlName="name">
								</div>
								<div class="name">NOME DA imobiliária</div>
							</div>

							<app-field-error-display [displayError]="isFieldValid('name')"
								errorMsg="Este campo é obrigatório!">
							</app-field-error-display>
						</div>

						<div class="col-md-4">
							<div class="field-container" [class.error-input]="isFieldValid('email')">
								<div>
									<input type="text" formControlName="email">
								</div>
								<div class="name">EMAIL DE CONTATO</div>
							</div>

							<!-- <app-field-error-display [displayError]="isFieldValid('email')" errorMsg="Este campo é obrigatório!">
							</app-field-error-display> -->
						</div>
					</div>

					<hr>

					<!-- Localização -->
					<div class="title-form">
						Localização
						<small>Localização referentes a sua imobiliária.</small>
					</div>

					<div class="row">
						<div class="col-md-4">
							<div class="field-container">
								<input type="text" formControlName="cep">
								<div class="name">CEP</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-6">
							<div class="field-container">
								<input type="text" formControlName="address">
								<div class="name">Endereço</div>
							</div>
						</div>

						<div class="col-md-4">
							<div class="field-container">
								<input type="text" formControlName="number">
								<div class="name">Número</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-4">
							<div class="select-container">
								<select type="text" formControlName="estate">
									<option [value]="estate.uf" *ngFor="let estate of estates">{{estate.name}}</option>
								</select>
								<div class="name">Estado</div>
							</div>
						</div>

						<div class="col-md-4">
							<div class="field-container">
								<input type="text" formControlName="city">
								<div class="name">Cidade</div>
							</div>
						</div>

						<div class="col-md-4">
							<div class="field-container">
								<input type="text" formControlName="neighborhood">
								<div class="name">Bairro</div>
							</div>
						</div>
					</div>
					<!--// Localização -->

					<hr><br>

					<!-- Redes Sociais -->
					<div class="title-form">
						Redes Sociais
						<small>Links de suas redes sociais</small>
					</div>

					<div class="row">
						<div class="col-md-4">
							<div class="field-container">
								<input type="text" formControlName="MEDIA_FACEBOOK">
								<div class="name"><i class="fab fa-facebook-f"></i> Facebook</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="field-container">
								<input type="text" formControlName="MEDIA_TWITTER">
								<div class="name"><i class="fab fa-twitter"></i> Twitter</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
							<div class="field-container">
								<input type="text" formControlName="MEDIA_YOUTUBE">
								<div class="name"><i class="fab fa-youtube"></i> Youtube</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="field-container">
								<input type="text" formControlName="MEDIA_GOOGLE_PLUS">
								<div class="name"><i class="fab google-plus-g"></i>Google Plus</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
							<div class="field-container">
								<input type="text" formControlName="MEDIA_INSTAGRAM">
								<div class="name"><i class="fab fa-instagram"></i> Instagram</div>
							</div>
						</div>
					</div>
					<!--// Redes Sociais -->

				</div>
			</form>
		</div>
	</div>

	<div class="flex-footer">
		<div class="actions-bottom">
			<!--<button class="btn btn-light" (click)="save()" type="button" *ngIf="">-->
			<!--Salvar e voltar-->
			<!--</button>-->
			<button class="btn btn-primary" (click)="save()" type="button">
				Salvar
			</button>
		</div>
	</div>
</div>
