import {Component, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Helpers} from '../../../services/helpers';
import {debounceTime, distinctUntilChanged, mergeMap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {UsersService} from '../../models/users.service';
import {LocationCitiesService} from '../../models/location-cities.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {LocationNeighborhoodsService} from '../../models/location-neighborhoods.service';
import {LocationNeighborhoodsSubneighborhoodsService} from '../../models/location-neighborhoods-subneighborhoods.service';

@Component({
	selector: 'input-autocomplete-location',
	templateUrl: './input-autocomplete-location.component.html',
	styleUrls: ['./input-autocomplete-location.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => InputAutocompleteLocationComponent),
		multi: true
	}]
})
export class InputAutocompleteLocationComponent implements OnInit, OnChanges, ControlValueAccessor {

	value: any;
	_selectedLocation: any;

	@Input() onlyInCity: '';
	@Input() city = false;
	@Input() neighborhood = false;
	@Input() error = false;

	searchTextChanged = new Subject<string>();
	textModel: string;
	locations = [];
	loading = false;

	// Usuário selecionado
	selectedLocation: any;

	// Ver Autocomplete
	showAutocomplete = false;

	// Index selecionado
	selectNumber = 0;

	// Both onChange and onTouched are functions
	onChange: any = () => {
	};
	onTouched: any = () => {
	};

	constructor(
		private locationCitiesService: LocationCitiesService,
		private locationNeighborhoodsService: LocationNeighborhoodsSubneighborhoodsService
	) {

	}

	ngOnInit() {
		this.searchTextChanged
			.pipe(debounceTime(350))
			.pipe(distinctUntilChanged())
			.pipe(mergeMap(search => this.searchUsers(search)))
			.subscribe(data => {
				this.locations = data.results;
				this.loading = false;
			});
	}

	/**
	 * Changes
	 */
	ngOnChanges() {
		if (Helpers.empty(this.value)) {
			this.selectedLocation = '';
			this.textModel = '';
		}
	}

	/**
	 * Seleciona usuário
	 * @param location
	 */
	selectLocation(location: any) {
		this.locations = [];
		this.selectedLocation = location;
		this.setFocus(false);
		if (this.city) {
			this.value = this.selectedLocation.city;
		} else if (this.neighborhood) {
			this.value = this.selectedLocation.neighborhood;
		}
		this.updateChange();
	}

	/**
	 * Remover seleção
	 * @param user
	 */
	removeSelectUser() {
		this.selectedLocation = '';
		this.textModel = '';
		this.value = 0;
		this.updateChange();
	}

	setFocus(focus) {
		this.showAutocomplete = focus;
	}

	/**
	 * Busca Cidade
	 * @param search
	 * @returns {Observable<any>}
	 */
	searchUsers(search) {
		if (this.city) {
			return this.locationCitiesService.search({
				'city': search
			});
		} else if (this.neighborhood) {
			return this.locationNeighborhoodsService.search({
				'city': this.onlyInCity,
				'no_repeat': true,
				'neighborhood': search
			});
		}
	}

	/**
	 * Evento change no input
	 * @param $event
	 */
	changeLocationInput($event) {
		this.loading = true;
		this.showAutocomplete = true;
		let text = $event.target.value;
		this.updateChange();
		this.searchTextChanged.next(text);
	}

	/**
	 * Update Model
	 * @param {KeyboardEvent} event
	 */
	updateChange() {
		this.textModel = this.value;
		this.onChange(this.value);
	}

	@HostListener('document:keydown.arrowup', ['$event'])
	handleUpEvent(event: KeyboardEvent) {
		if (this.showAutocomplete) {
			this.selectNumber--;
			if (this.selectNumber < 0) {
				this.selectNumber = this.locations.length - 1;

				if (this.selectNumber < 0) {
					this.selectNumber = 0;
				}
			}
		}
	}

	@HostListener('document:keydown.arrowdown', ['$event'])
	handleDownEvent(event: KeyboardEvent) {
		if (this.showAutocomplete) {
			this.selectNumber++;
			if (this.selectNumber >= this.locations.length) {
				this.selectNumber = 0;
			}
		}
	}

	@HostListener('document:keydown.enter', ['$event'])
	handleEnterEvent(event: KeyboardEvent) {
		if (this.showAutocomplete && this.locations.length) {
			this.selectLocation(this.locations[this.selectNumber]);
		}
	}

	@HostListener('document:keydown.Tab', ['$event'])
	handleTabEvent(event: KeyboardEvent) {
		this.setFocus(false);
	}

	///////////////
	// OVERRIDES //
	///////////////

	/**
	 * Writes a new item to the element.
	 * @param value the value
	 */
	writeValue(value: number): void {
		this.value = value;
		this.updateChange();
	}

	/**
	 * Registers a callback function that should be called when the control's value changes in the UI.
	 * @param fn
	 */
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	/**
	 * Registers a callback function that should be called when the control receives a blur event.
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

}

