import { Routes } from "@angular/router";
import { ContactsInteractionsSingleResolver } from "../../resolves/contacts-interactions-single-resolver";
import { ContactsListComponent } from "./contacts-list/contacts-list.component";
import { ContactsViewResolver } from "../../resolves/contacts-view-resolver";
import { ContactsInteractionsResolver } from "../../resolves/contacts-interactions-resolver";
import { ContactsSearchService } from "../../resolves/contacts-search.service";
import { ContactsAddComponent } from "./contacts-add/contacts-add.component";
import { ContactsViewAddInteractionsComponent } from "./contacts-view/contacts-view-add-interactions/contacts-view-add-interactions.component";
import { ContactsViewEditInteractionsComponent } from "./contacts-view/contacts-view-edit-interactions/contacts-view-edit-interactions.component";
import { ContactsViewComponent } from "./contacts-view/contacts-view.component";
var ɵ0 = {
    buyers: true
}, ɵ1 = {
    title: "Adicionar Contato"
};
var routes = [
    {
        path: "",
        children: [
            {
                path: "list",
                component: ContactsListComponent,
                resolve: {
                    contacts: ContactsSearchService
                }
            },
            {
                path: "buyers/lists",
                component: ContactsListComponent,
                data: ɵ0,
                resolve: {
                    contacts: ContactsSearchService
                }
            },
            {
                path: "add",
                component: ContactsAddComponent,
                resolve: {},
                data: ɵ1
            },
            {
                path: "edit/:id",
                component: ContactsAddComponent,
                resolve: {}
            },
            {
                path: "view/:id",
                component: ContactsViewComponent,
                resolve: {
                    contact: ContactsViewResolver,
                    interactions: ContactsInteractionsResolver
                },
                children: [
                    {
                        path: "add-interaction",
                        component: ContactsViewAddInteractionsComponent
                    },
                    {
                        path: "edit-interaction/:idInteraction",
                        component: ContactsViewEditInteractionsComponent,
                        resolve: {
                            interaction: ContactsInteractionsSingleResolver
                        }
                    }
                ]
            }
        ]
    }
];
var ContactsRoutingModule = /** @class */ (function () {
    function ContactsRoutingModule() {
    }
    return ContactsRoutingModule;
}());
export { ContactsRoutingModule };
export { ɵ0, ɵ1 };
