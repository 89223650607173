import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { TasksService } from '../models/tasks.service';
import { FunnelSalesService } from '../models/funnel-sales.service';
import { Global } from '../../services/global';

@Injectable()
export class FunnelSalesStepsResolverService implements Resolve<any> {

	constructor(
		private funnelSalesService: FunnelSalesService
	) {

	}

	resolve() {
		let responsable_user = Global.user.id;
		responsable_user = Math.floor(responsable_user);

		return this.funnelSalesService.search({
			root: {
				with_contacts: true
			},
			realtors_managers_in: [responsable_user]
		});
	}
}
