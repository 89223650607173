
<div class="page-flex no-over">

	<div class="flex-header">
		<app-nav-bar [title]="'Lista de Corretores'" [actions]="actions" (actionEvent)="setAction($event)">
		</app-nav-bar>
	</div>

    <div class="loading" *ngIf="searching">
        <div class="loading-icon">
            <div class="load">
                <div></div>
            </div>
        </div>
    </div>

	<div class="flex-body users-list" #topProperties>
		<div class="max-width">

			<div class="header-search">

				<h1 class="title hide-mobile">
					<div class="row">
						<div class="col">
							Meus Corretores
						</div>
					</div>
				</h1>

				<div class="tabs hide-mobile">
					<div class="tab active">
						Todos ({{users_count}})
					</div>
				</div>

				<div class="row m-0 pt-2">
					<div class="col m-0">
					</div>
					<div class="col text-right m-0">
						<form [formGroup]="filters" (change)="updateParams()" class="d-inline-block">
							<label class="select-container no-name">
								<select formControlName="sort">
									<option [value]="''">Ordernar por</option>
									<option [value]="'-created_at'">Data da criação</option>
									<option [value]="'name'">Alfabética - A á Z</option>
									<option [value]="'-name'">Alfabética - Z á A</option>
								</select>
							</label>
						</form>
					</div>
				</div>
			</div>

			<div class="alert-dark p-3 font-weight-500 hide-mobile">
				<div class="table">
					<div class="td td-min pr-3">
						<img src="assets/images/logo-icon.png" alt="" width="30">
					</div>
					<div class="td">
						Mostrará a lista dos corretores que estão cadastrados no sistema.
					</div>
				</div>
			</div>
			<br>

			<div class="row">
				<div class="col-md-3 p-2 m-0" *ngFor="let user of users">
					<app-users-card [user]="user" (delete)="setDeleteUser(user.id)"></app-users-card>
				</div>
			</div>

			<div class="pt-4">
				<app-pagination [total]="users_count"
								[perPage]="perPage"
								(changePage)="setPage($event)"
								[(currentPage)]="currentPage">
				</app-pagination>
			</div>
		</div>
	</div>
</div>

<app-modal-delete title="Deletar Corretor"
[openModal]="delete_modal"
(confirm)="deleteUser()">
Você deseja deletar esse Corretor?</app-modal-delete>
