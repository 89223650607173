import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	OnDestroy
} from "@angular/core";
import { RouterHistoric } from "../../../../shareds/router-historic.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Location } from "@angular/common";
import { CalendarSharedService } from "../../../../shareds/calendar.service";
import { Helpers } from "../../../../../services/helpers";
import { ActivatedRoute, Router } from "@angular/router";
import { PlaySound } from "../../../../../services/PlaySound";
import { AgendaService } from "../../../../models/agenda.service";
import { ToastrService } from "ngx-toastr";
import { ContactsInteractionsService } from "../../../../models/contacts-interactions.service";
import { TitleService } from "src/app/services/title.service";
import { Subscribable } from "rxjs/src/internal/types";
import { Subscription, Subject } from "rxjs";
import { ContactInteractionsSharedService } from "src/app/content/shareds/contact-interactions-shared.service";
import { ModalContactDuplicateService } from "../../../../shareds/modal-contact-duplicate.service";
import { Properties } from "src/app/content/interfaces/properties";

@Component({
	selector: "app-contacts-view-add-interactions",
	templateUrl: "./contacts-view-add-interactions.component.html",
	styleUrls: ["./contacts-view-add-interactions.component.scss"]
})
export class ContactsViewAddInteractionsComponent implements OnInit, OnDestroy {
	@Output() saved = new EventEmitter();
	@Input() form: FormGroup;

	private formSubmitAttempt = false;

	contactID = 0;

	openModal = false;
	currentTab: "contact_nota" | "properties_visit" | "commitment" | "task" =
		"properties_visit";
	visitType: "not_like" | "thinking" | "reasonable" | "like" | "" = "";
	typeCommitment:
		| "visit"
		| "meeting"
		| "email"
		| "call"
		| "after_sales"
		| "" = "";

	properties_list = [];
	private _destroyed$ = new Subject();

	constructor(
		private route: ActivatedRoute,
		private _fb: FormBuilder,
		private router: Router,
		private modalContactDuplicateService: ModalContactDuplicateService,
		private toastr: ToastrService,
		private contactsInteractionsService: ContactsInteractionsService,
		private _titleService: TitleService,
		private _contactsSharedService: ContactInteractionsSharedService
	) {}

	ngOnInit() {
		this.contactID = this.route.parent.snapshot.params.id;
		this.contactsInteractionsService.setContact(this.contactID);

		let contactName = this.route.parent.snapshot.data.contact.name;
		this._titleService.setTitle(`${contactName} - Criar Interação`);
		this.selectForm();
	}

	save(): Subscription | boolean {
		this.formSubmitAttempt = false;

		/**
		 * Formulário com erros
		 */
		if (!this.form.valid) {
			this.formSubmitAttempt = true;
			PlaySound.play("error");
			this.toastr.error("Preencha os campos corretamente.");
			return false;
		}

		let data = {
			type: this.currentTab,
			...this.form.value
		};

		switch (this.currentTab) {
			case "properties_visit":
				data["propertie_opinion"] = this.visitType;
				data["propertie_id"] = 1; // REVIEW: Retirar property_id
				break;

			case "commitment":
				data = {};
				data["commitment"] = { ...this.form.value };
				data["type"] = this.currentTab;
				data["commitment"]["all_day"] = data["commitment"]["all_day"]
					? 1
					: 0;
				data["commitment"]["realized"] = data["commitment"]["realized"]
					? 1
					: 0;
				break;

			case "task":
				data = {};
				data["task"] = { ...this.form.value };
				data["type"] = this.currentTab;
				data["task"]["all_day"] = data["task"]["all_day"] ? 1 : 0;
				data["task"]["realized"] = data["task"]["realized"] ? 1 : 0;
				break;

			case "contact_nota":
				break;
		}

		return this.contactsInteractionsService.create(data).subscribe(
			res => {
				this.toastr.success("Interação adicionada com sucesso!");
				this.close();
				this._contactsSharedService.refresh();
			},
			err => {
				this.toastr.error("Erro ao criar interação.");
			}
		);
	}

	openModalProperty() {
		this.modalContactDuplicateService.open("modal-select-properties");
	}

	/**
	 * Verifica se campo é valido
	 * @param {string} field
	 * @returns {boolean}
	 */
	isFieldValid(field: string, parent?: string): boolean {
		if (!!parent) {
			return (
				!this.form.get(parent).get(field).valid &&
				this.formSubmitAttempt
			);
		} else {
			return !this.form.get(field).valid && this.formSubmitAttempt;
		}
	}

	buildFormNote(): void {
		this.form = this._fb.group({
			message: ["", Validators.required],
			type: [this.currentTab, Validators.required]
		});
	}

	buildFormVisit(): void {
		this.form = this._fb.group({
			message: [""],
			type: [this.currentTab, Validators.required],
			propertie_opinion: [""],
			property: []
		});
	}

	builFormCommitment(): void {
		this.form = this._fb.group({
			name: ["", Validators.required],
			start_at: ["", Validators.required],
			description: [""],
			realized: [0],
			all_day: [0],
			shared_users: [""],
			type: [""]
		});
	}

	buildFormTask(): void {
		this.form = this._fb.group({
			name: ["", Validators.required],
			all_day: [false],
			deadline_at: [""],
			comments: [""],
			realized: [0],
			task_list_id: [0]
		});
	}

	allDay(event: any) {
		this.form.get("all_day").setValue(event);
	}

	setVisitType(
		type: "not_like" | "thinking" | "reasonable" | "like" | ""
	): void {
		this.visitType = type;
		this.form.patchValue({ propertie_opinion: type });
	}

	setCommitType(
		type: "visit" | "meeting" | "email" | "call" | "after_sales"
	) {
		this.typeCommitment = type;
		this.form.patchValue({ type: type });
	}

	close() {
		if (this.router.url.indexOf("sales-funnel") !== -1) {
			this.router.navigateByUrl(
				"/sales-funnel/list/contacts/view/" + this.contactID
			);
		} else {
			this.router.navigateByUrl("/contacts/view/" + this.contactID);
		}
	}

	checkTab(tab: string): boolean {
		return tab === this.currentTab;
	}

	changeTab(
		tab: "contact_nota" | "properties_visit" | "commitment" | "task"
	) {
		this.currentTab = tab;
		this.formSubmitAttempt = false;
		this.visitType = "";
		this.typeCommitment = "";

		this.selectForm();
	}

	private selectForm() {
		delete this.form;
		switch (this.currentTab) {
			case "contact_nota":
				this.buildFormNote();
				break;

			case "properties_visit":
				this.buildFormVisit();
				break;

			case "commitment":
				this.builFormCommitment();
				break;

			case "task":
				this.buildFormTask();
				break;
		}
	}

	/**
	 * Adiciona a propriedade
	 *
	 * @param {Properties} property
	 * @memberof ContactsViewAddInteractionsComponent
	 */
	addProperty(property: Properties) {
		// this.properties_list.push(property);
		// this.form.get('properties').setValue(this.properties_list);

		this.form.get("property").setValue(property);
		console.log(this.form.get("property").value);
	}

	/**
	 * Deleta as propriedades
	 *
	 * @param {*} id
	 * @memberof ContactsViewAddInteractionsComponent
	 */
	deleteProperty() {
		// for (let i = 0; i < this.properties_list.length; i++) {
		// 	const element = this.properties_list[i];

		// 	if (element.id === id) {
		// 		this.properties_list.splice(i, 1);
		// 	}
		// }

		this.form.get("properties").setValue({});
	}

	ngOnDestroy() {
		this._destroyed$.next();
		this._destroyed$.complete();
	}
}
