var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ChangeDetectorRef, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormArray, FormBuilder, FormControl } from "@angular/forms";
import { skip, takeUntil } from "rxjs/operators";
import { ContactsService } from "../../../models/contacts.service";
import { Helpers } from "../../../../services/helpers";
import { Global } from "../../../../services/global";
import { TitleService } from "src/app/services/title.service";
import { environment } from "../../../../../environments/environment";
import { ToastrService } from "ngx-toastr";
import { GlobalService } from "../../../shareds/global.service";
import { Subject } from "rxjs";
import { ModalPageMobileService } from "../../../shareds/modal-page-mobile.service";
var ContactsListComponent = /** @class */ (function () {
    function ContactsListComponent(route, router, fb, _globalService, toastr, changeDetectorRef, contactsService, modalPageMobileService, _titleService) {
        this.route = route;
        this.router = router;
        this.fb = fb;
        this._globalService = _globalService;
        this.toastr = toastr;
        this.changeDetectorRef = changeDetectorRef;
        this.contactsService = contactsService;
        this.modalPageMobileService = modalPageMobileService;
        this._titleService = _titleService;
        // Contato selecionados
        this.contactsSelected = [];
        // Se todos os recursos estão selecionados e existem mais recursos em outra página
        this.selectedAllPage = false;
        // Selecionar todos os contatos
        this.selectedAllContacts = false;
        this.perPage = 20;
        this.currentPage = 1;
        this.isSearch = false;
        // Buscando...
        this.searching = false;
        this.modalDeleteContact = false;
        this.colors = [
            "#ff5e65",
            "#f76352",
            "#ff5e84",
            "#a05fb5",
            "#df7dc6",
            "#28c28b",
            "#ffc157",
            "#ff8681",
            "#00c7e2",
            "#01e6de",
            "#009bda",
            "#0079aa"
        ];
        this.destroy$ = new Subject();
        this.BUYERS = false;
        this.MASTER = 0;
        this.MOBILE = false;
        this.title = "";
        this.tabs = [
            {
                id: "Todos",
                title: "Todos",
                badge: "--",
                active: true
            }
        ];
        this.actions = [
            {
                id: "filters",
                body: '<i class="fas fa-filter"></i>'
            },
            {
                id: "add",
                body: '<i class="fas fa-plus"></i>'
            }
        ];
    }
    ContactsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._globalService.user
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (user) {
            _this.me = user;
        });
        this.BUYERS = this.route.snapshot.data.buyers;
        this.MASTER = Global.user.user_master_bdi;
        this.MOBILE = environment.mobile;
        this.contacts = this.route.snapshot.data.contacts.results;
        this.contacts_count = this.route.snapshot.data.contacts.count;
        this.tabs[0].badge = this.contacts_count;
        this.types = Global.info.contacts.types;
        this.users_admin = Global.info.users_admin;
        this.estates = Global.info.estates;
        // Configura Filtros
        this.configureFilters(this.contactsService.processFilters(this.route.snapshot.queryParams));
        /**
         * Query subscribe
         * @type {Subscription}
         */
        this.subParams = this.route.queryParams
            .pipe(skip(1))
            .subscribe(function (data) {
            data = _this.contactsService.processFilters(data);
            _this.loadContacts(data);
        });
        this.setTitle();
        this.setTitleSearch();
        this.modalPageMobileService.open("contact-mininal-view");
    };
    ContactsListComponent.prototype.setAction = function (action) {
        if (action.id === "filters") {
            this.modalPageMobileService.open("contacts-filters");
        }
        else if (action.id === "add") {
            this.router.navigate(["/contacts/add"]);
        }
    };
    ContactsListComponent.prototype.setTab = function (tab) {
        // this.destroyHttp$.next();
        //
        // this.title = '';
        //
        // if (tab.id === 'my-properties') {
        // 	this.PARTNERSHIP = false;
        // 	this.APPROVAL = false;
        // 	this.listType = 'properties';
        // 	// this.router.navigate(['/properties/list']);
        // } else if (tab.id === 'partners') {
        // 	this.PARTNERSHIP = true;
        // 	this.APPROVAL = false;
        // 	this.listType = 'partners';
        // } else if (tab.id === 'approval') {
        // 	this.APPROVAL = true;
        // 	this.PARTNERSHIP = false;
        // 	this.listType = 'approval';
        // 	// this.router.navigate(['/properties/approval']);
        // }
        //
        // this.starting = true;
        //
        // this.loadCached();
    };
    ContactsListComponent.prototype.setTitleSearch = function () {
        if (!this.BUYERS) {
            this.title = "Contatos";
        }
        else {
            this.title = "Compradores";
        }
    };
    ContactsListComponent.prototype.openView = function (contact) {
        console.log(contact);
        this.contactView = contact;
        this.modalPageMobileService.open("contact-mininal-view");
    };
    /**
     * Resgata mais contatos
     * @param data
     * @returns {Subscription}
     */
    ContactsListComponent.prototype.loadContacts = function (data) {
        var _this = this;
        if (this.searching) {
            return false;
        }
        this.selectedAllPage = false;
        this.selectedAllContacts = false;
        this.contactsSelected = [];
        this.searching = true;
        if (this.BUYERS) {
            data["type_in"] = [3, 8];
        }
        return this.contactsService.search(data).subscribe(function (contacts) {
            _this.contacts = [];
            _this.changeDetectorRef.detectChanges();
            _this.contacts = contacts.results;
            _this.contacts_count = contacts.count;
            _this.tabs[0].badge = _this.contacts_count;
            _this.searching = false;
        });
    };
    /**
     * Configura os filtros inicialmente, vindo da URL
     * @param data
     */
    ContactsListComponent.prototype.configureFilters = function (data) {
        var types = this.types.map(function (c) {
            if (data.type_in) {
                return new FormControl(data.type_in.indexOf(c.id) !== -1);
            }
            else {
                return new FormControl(false);
            }
        });
        var users = this.users_admin.map(function (c) {
            if (data.realtors_managers_in) {
                return new FormControl(data.realtors_managers_in.indexOf(c.id) !== -1);
            }
            else {
                return new FormControl(false);
            }
        });
        if (!data.offset) {
            data.offset = 0;
        }
        this.currentPage = parseInt(String(data.offset / this.perPage), 10) + 1;
        data.offset = (this.currentPage - 1) * this.perPage;
        console.log(this.currentPage);
        this.filters = this.fb.group({
            cod: [data.cod || "", []],
            sort: [data.sort || "", []],
            limit: [data.limit || "", []],
            offset: [data.offset, []],
            name: [data.name || "", []],
            email: [data.email || "", []],
            phone: [data.phone || "", []],
            archive: [data.archive || "", []],
            favorite: [data.favorite || "", []],
            date_lte: [data.date_lte || "", []],
            date_gte: [data.date_gte || "", []],
            type: [data.type || "", []],
            types: new FormArray(types),
            users: new FormArray(users),
            finality: [data.finality || "", []],
            price_lte: [data.price_lte || "", []],
            ps_estate: [data.ps_estate || "", []],
            ps_city: [data.ps_city || "", []],
            ps_neighborhood: [data.ps_neighborhood || "", []]
        });
        /**
         * Verifica se existe algo sendo buscado
         * @type {{}}
         */
        var params = Helpers.filterArray(this.getFilters(), [
            Helpers.WITH_ZERO
        ]);
        params.offset = undefined;
        this.isSearch = !Helpers.empty(Helpers.filterArray(params, [Helpers.WITH_ZERO]));
    };
    /**
     * Ordernar resultado
     * @param {string} sort
     */
    ContactsListComponent.prototype.sort = function (sort) {
        var _sort = this.filters.controls.sort.value;
        if (_sort === sort) {
            this.filters.controls.sort.setValue("-" + sort);
        }
        else if (_sort.substring(1) === sort) {
            this.filters.controls.sort.setValue("");
        }
        else {
            this.filters.controls.sort.setValue(sort);
        }
        this.updateParams();
    };
    ContactsListComponent.prototype.closeContactView = function () {
        this.modalPageMobileService.close("contacts-filters");
    };
    /**
     * Trocar página
     * @param {number} page
     */
    ContactsListComponent.prototype.setPage = function (page) {
        this.currentPage = page;
        this.setTitle();
        page = page - 1;
        this.filters.controls.offset.setValue(page * this.perPage);
        this.updateParams(false);
    };
    /**
     * Faz o tratamento dos filtros antes da busca
     * @returns {any}
     */
    ContactsListComponent.prototype.getFilters = function () {
        var _this = this;
        var filters = __assign({}, this.filters.value);
        filters.type_in = filters.types
            .map(function (v, i) { return (v ? _this.types[i].id : null); })
            .filter(function (v) { return v !== null; });
        filters.realtors_managers_in = filters.users
            .map(function (v, i) { return (v ? _this.users_admin[i].id : null); })
            .filter(function (v) { return v !== null; });
        // Para não ficar url imenso
        filters.users = undefined;
        filters.types = undefined;
        return filters;
    };
    /**
     * Atualiza novos parametros
     */
    ContactsListComponent.prototype.updateParams = function (newSearch) {
        if (newSearch === void 0) { newSearch = true; }
        // Reset página
        if (newSearch) {
            this.filters.controls.offset.setValue(undefined);
            this.currentPage = 1;
        }
        /**
         * Verifica se existe algo sendo buscado
         * @type {{}}
         */
        var params = Helpers.filterArray(this.getFilters(), [
            Helpers.WITH_ZERO
        ]);
        params.offset = undefined;
        this.isSearch = !Helpers.empty(Helpers.filterArray(params, [Helpers.WITH_ZERO]));
        this.router.navigate([], {
            queryParams: Helpers.filterArray(this.getFilters(), [
                Helpers.WITH_ZERO
            ])
        });
    };
    /**
     * Limpar Busca
     */
    ContactsListComponent.prototype.clearParams = function () {
        this.filters.reset();
        this.updateParams();
    };
    /**
     * Busca valor de key nas array de contatos pelo ID
     * @param {number} id
     * @returns {number}
     */
    ContactsListComponent.prototype.searchContactByID = function (id) {
        for (var i in this.contacts) {
            if (id === Number(this.contacts[i].id)) {
                return Number(i);
            }
        }
        return -1;
    };
    /**
     * Favoritar Contatos
     */
    ContactsListComponent.prototype.favoriteContact = function (id, favorite) {
        var index = this.searchContactByID(id);
        console.log(index);
        this.contacts[index].favorite = favorite;
        this.contactsService
            .update(id, {
            favorite: favorite ? 1 : 0
        })
            .subscribe(function (contact) { });
    };
    /**
     * Arquivar Contato
     */
    ContactsListComponent.prototype.archiveContact = function (id, archive) {
        var index = this.searchContactByID(id);
        console.log(index);
        this.contacts[index].archive = archive;
        this.contactsService
            .update(id, {
            archive: archive ? 1 : 0
        })
            .subscribe(function (contact) { });
    };
    ContactsListComponent.prototype.openModalDelete = function (id) {
        var index = this.searchContactByID(id);
        this.contactDelete = this.contacts[index];
        this.modalDeleteContact = true;
    };
    /**
     * Deletar Contato
     */
    ContactsListComponent.prototype.deleteContact = function (id) {
        var _this = this;
        this.modalDeleteContact = false;
        var index = this.searchContactByID(id);
        this.contactsService.delete(id).subscribe(function (contact) {
            _this.toastr.success("Contato deletado com sucesso!");
            _this.refresh();
            _this.modalDeleteContact = false;
            _this.modalPageMobileService.close("contacts-filters");
        });
    };
    /**
     * Atualizar Muitos Contatos
     */
    ContactsListComponent.prototype.favoriteManyContact = function (favorite) {
        for (var i in this.contacts) {
            if (this.contactsSelected.indexOf(this.contacts[i].id) !== -1) {
                this.contacts[i].favorite = favorite;
            }
        }
        this.contactsService
            .favorite({
            ids: this.selectedAllContacts ? "ALL" : this.contactsSelected,
            status: favorite ? 1 : 0
        })
            .subscribe(function (contact) { });
    };
    ContactsListComponent.prototype.checkContact = function (id, checked) {
        if (checked) {
            this.contactsSelected.push(id);
        }
        else {
            var index = this.contactsSelected.indexOf(id);
            if (index !== -1) {
                this.contactsSelected.splice(index, 1);
            }
        }
        this.checkAllSelected();
    };
    /**
     * Selecionar todos os contatos
     */
    ContactsListComponent.prototype.selectAllContacts = function () {
        this.selectedAllContacts = false;
        this.contactsSelected = [];
        for (var i in this.contacts) {
            this.contacts[i].checked = true;
            this.contactsSelected.push(this.contacts[i].id);
        }
        this.checkAllSelected();
    };
    /**
     * Deselecionar todos os contatos
     */
    ContactsListComponent.prototype.deselectAllContacts = function () {
        this.contactsSelected = [];
        for (var i in this.contacts) {
            this.contacts[i].checked = false;
        }
        this.checkAllSelected();
    };
    /**
     * Verifica se todos os contatos estão selecionados
     * e se existem mais contatos do que o total dessa página
     * para da a opção para o usuário poder selecionar todos
     * os contatos da lista
     */
    ContactsListComponent.prototype.checkAllSelected = function () {
        this.selectedAllPage = false;
        this.selectedAllContacts = false;
        if (this.contactsSelected.length === this.contacts.length &&
            this.contacts.length < this.contacts_count) {
            this.selectedAllPage = true;
        }
    };
    ContactsListComponent.prototype.createPartners = function () {
        this.router.navigate(["", { outlets: { modal: "add-chat-partners" } }], {
            queryParams: {
                contacts_to_tickets: this.contactsSelected
            }
        });
    };
    ContactsListComponent.prototype.applyFilter = function () {
        this.updateParams();
    };
    ContactsListComponent.prototype.openWhatsapp = function (number) {
        window.open("whatsapp://send?phone=55" + number, "_system");
    };
    /**
     * Atualiza contatos
     */
    ContactsListComponent.prototype.refresh = function () {
        var data = Helpers.filterArray(this.getFilters(), [Helpers.WITH_ZERO]);
        this.loadContacts(data);
    };
    ContactsListComponent.prototype.setTitle = function () {
        var title = "Lista de Contatos";
        this._titleService.setListTitle(title, this.currentPage);
    };
    ContactsListComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
    };
    return ContactsListComponent;
}());
export { ContactsListComponent };
