import { NgZone } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";
import { CompleteHeightService } from "../content/shareds/complete-height.service";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
var CordovaService = /** @class */ (function () {
    function CordovaService(zone, location, router) {
        var _this = this;
        this.zone = zone;
        this.location = location;
        this.router = router;
        this.home = 0;
        if (environment.mobile) {
            this.resume = new BehaviorSubject(null);
            // InAppBrowser
            try {
                window.open = cordova.InAppBrowser.open;
            }
            catch (e) {
                alert(e);
            }
            if (window.MobileAccessibility) {
                window.MobileAccessibility.usePreferredTextZoom(false);
            }
            document.addEventListener("resume", function () {
                _this.zone.run(function () {
                    _this.onResume();
                });
            }, false);
            window.addEventListener("keyboardDidShow", function () {
                _this.zone.run(function () {
                    _this.onKeyboardDidShow();
                });
            }, false);
            window.addEventListener("keyboardDidHide", function () {
                _this.zone.run(function () {
                    _this.onKeyboardDidHide();
                });
            }, false);
            document.addEventListener("backbutton", function () {
                _this.onBackKeyDown();
            }, false);
        }
    }
    Object.defineProperty(CordovaService.prototype, "cordova", {
        get: function () {
            return window().cordova;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CordovaService.prototype, "onCordova", {
        get: function () {
            return !!window().cordova;
        },
        enumerable: true,
        configurable: true
    });
    CordovaService.touchSound = function () {
        console.log("touchSound");
        if (environment.mobile) {
            nativeclick.trigger();
        }
    };
    CordovaService.prototype.onKeyboardDidShow = function () {
        var _this = this;
        document.activeElement.scrollIntoView();
        setTimeout(function () {
            window.scrollBy(0, -10);
        }, 1);
        // window.plugins.toast.showWithOptions(
        // 	{
        // 		message: 'onKeyboardDidShow',
        // 		duration: 'short', // which is 2000 ms. "long" is 4000. Or specify the nr of ms yourself.
        // 		position: 'bottom',
        // 		addPixelsY: -40  // added a negative value to move it up a bit (default 0)
        // 	}
        // );
        CompleteHeightService.update();
        Promise.resolve(null).then(function () {
            CompleteHeightService.update();
            _this.zone.run(function () { });
        });
    };
    CordovaService.prototype.onKeyboardDidHide = function () {
        // window.plugins.toast.showWithOptions(
        // 	{
        // 		message: 'onKeyboardDidHide',
        // 		duration: 'short', // which is 2000 ms. "long" is 4000. Or specify the nr of ms yourself.
        // 		position: 'bottom',
        // 		addPixelsY: -40  // added a negative value to move it up a bit (default 0)
        // 	}
        // );
        console.log("down");
        CompleteHeightService.update();
    };
    CordovaService.prototype.onResume = function () {
        // window.plugins.toast.showWithOptions(
        // 	{
        // 		message: 'resume',
        // 		duration: 'short', // which is 2000 ms. "long" is 4000. Or specify the nr of ms yourself.
        // 		position: 'bottom',
        // 		addPixelsY: -40  // added a negative value to move it up a bit (default 0)
        // 	}
        // );
        this.resume.next(true);
    };
    CordovaService.prototype.onBackKeyDown = function () {
        // window.plugins.toast.showWithOptions(
        // 	{
        // 		message: 'resume',
        // 		duration: 'short', // which is 2000 ms. "long" is 4000. Or specify the nr of ms yourself.
        // 		position: 'bottom',
        // 		addPixelsY: -40  // added a negative value to move it up a bit (default 0)
        // 	}
        // );
        var _this = this;
        setTimeout(function () {
            if (_this.router.url === "/") {
                _this.home++;
            }
            else {
                _this.home = 0;
            }
            _this.zone.run(function () {
                if (_this.home > 1) {
                    _this.home = 0;
                    navigator.app.exitApp();
                }
                else {
                    _this.location.back();
                }
            });
        }, 1);
    };
    return CordovaService;
}());
export { CordovaService };
