import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {TasksService} from '../models/tasks.service';
import {Injectable} from '@angular/core';
import {TicketsService} from '../models/tickets.service';
import {ContactsService} from '../models/contacts.service';

@Injectable()
export class PartnersContactsLoadService implements Resolve<any> {

	constructor(
		private contactsService: ContactsService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {

		let contacts_to_tickets = route.queryParams.contacts_to_tickets;

		if (contacts_to_tickets) {

			let filters = {};
			console.log(typeof contacts_to_tickets);
			if (typeof contacts_to_tickets === 'object') {
				contacts_to_tickets = contacts_to_tickets;
			} else {
				contacts_to_tickets = [contacts_to_tickets];
			}

			contacts_to_tickets = contacts_to_tickets.map(function (x) {
				return parseInt(x, 10);
			});

			filters['id_in'] = contacts_to_tickets;

			return this.contactsService.search(filters);
		}

		return {
			results: [],
			count: 0
		};
	}
}
