///<reference path="../../services/global.ts"/>
import {Injectable} from '@angular/core';
import {InfoPlatformService} from '../models/info-platform.service';
import {Resolve} from '@angular/router';
import {map} from 'rxjs/operators';
import {Global} from '../../services/global';
import {NotificationsService} from '../models/notifications.service';

@Injectable()
export class BadgesResolverService implements Resolve<any> {

	constructor(private _notificationsService: NotificationsService) {
	}

	resolve() {
		return this._notificationsService
			.badges()
			.pipe(map((data: any) => {
				Global.badges = data;
				return data;
			}));
	}
}
