import {Component, OnInit, } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {PlaySound} from '../../../../services/PlaySound';
import {ToastrService} from 'ngx-toastr';
import {ContactsInteractionsService} from '../../../models/contacts-interactions.service';
import { TitleService } from 'src/app/services/title.service';
import { ContactInteractionsSharedService } from 'src/app/content/shareds/contact-interactions-shared.service';
import { Subscription } from 'rxjs';
import { TaskListSharedService } from 'src/app/content/shareds/task-list-shared.service';
import { TasksListService } from 'src/app/content/models/tasks-list.service';
import {Location} from '@angular/common';
import {RouterHistoric} from '../../../shareds/router-historic.service';


@Component({
	selector: 'app-task-list-add',
	templateUrl: './task-list-add.component.html',
	styleUrls: ['./task-list-add.component.scss']
})
export class TaskListAddComponent implements OnInit {
	formSubmitAttempt: boolean;
	form: FormGroup;

	constructor(
		private route: ActivatedRoute,
		private _fb: FormBuilder,
		private router: Router,
		private location: Location,
		private toastr: ToastrService,
		private _taskListService: TasksListService,
		private _taskListShare: TaskListSharedService
	) { }

	ngOnInit() {
		this.buildForm();
	}


	/**
	 * Verifica se campo é valido
	 * @param {string} field
	 * @returns {boolean}
	 */
	isFieldValid(field: string, parent?: string): boolean {
		return (!this.form.get(field).valid && this.formSubmitAttempt);
	}


	buildForm(): void {
		this.form = this._fb.group({
			name: ['', Validators.required],
			shared_users: [[]]
		});
	}

	close() {
		this.router.navigate([ 'tasks', {outlets: { modal: null } } ]);
	}

	save(): Subscription | boolean {
		this.formSubmitAttempt = false;

		/**
		 * Formulário com erros
		 */
		if (!this.form.valid) {
			this.formSubmitAttempt = true;
			PlaySound.play('error');
			this.toastr.error('Preencha os campos corretamente.');
			return false;
		}

		let data = {...this.form.value};

		return this._taskListService
			.create(data).subscribe(res => {
				this.toastr.success('Lista adicionada com sucesso!');
				this.close();
				this._taskListShare.refresh();
			}, err => {
				this.toastr.error('Erro ao criar lista.');
			});
	}

}
