import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TaskSharedService {

	private messageSource = new BehaviorSubject(false);
	currentTask = this.messageSource.asObservable();

	constructor() {
	}

	changeTask(task: any) {
		this.messageSource.next(task);
	}

}
