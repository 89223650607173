import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, NavigationCancel, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {PropertiesService} from '../../models/properties.service';
import {Tasks} from '../../interfaces/tasks';
import { TitleService } from 'src/app/services/title.service';
import { TaskListSharedService } from '../../shareds/task-list-shared.service';
import { Subject } from 'rxjs';
import { takeUntil, skip } from 'rxjs/operators';
import { TasksListService } from '../../models/tasks-list.service';

@Component({
	selector: 'app-tasks',
	templateUrl: './tasks.component.html',
	styleUrls: ['./tasks.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class TasksComponent implements OnInit, OnDestroy {

	taskLists: Array<Tasks>;
	currentList: number | any = 0;
	loading = false;
	subParams: any;
	subRouter: any;
	inbox: any;
	destroy$ = new Subject();

	items = [1, 2, 3, 4, 5];

	constructor(
		private route: ActivatedRoute,
		private http: HttpClient,
		private router: Router,
		private _titleService: TitleService,
		private _taskListShare: TaskListSharedService,
		private _taskListService: TasksListService,
		private _router: Router
	) {
		this._taskListShare
		.event
		.pipe(takeUntil(this.destroy$))
		.pipe(skip(1))
		.subscribe(() => {
			console.log('b');

			this.refresh();
		});
	}

	ngOnInit() {
		this.taskLists = this.route.snapshot.data.taskLists;
		for (let i in this.taskLists) {
			// Caixa de entrada
			if (this.taskLists[i].id === 0) {
				this.inbox = this.taskLists[i];
				this.taskLists.splice(parseInt(i), 1);
			}
		}

		// Já existe Lista selecionada
		if (this.route.firstChild) {
			this.currentList = this.route.firstChild.snapshot.params['id'];
			this.setListTitle();
		}

		this.subRouter = this.router.events
			.subscribe((event) => {
				if (
					event instanceof NavigationEnd ||
					event instanceof NavigationCancel
				) {
					this.loading = false;
				}
			});

	}

	refresh() {
		return this._taskListService
			.getLists()
			.subscribe(res => {
				this.taskLists = res;

				for (let i in this.taskLists) {
					// Caixa de entrada
					if (this.taskLists[i].id === 0) {
						this.inbox = this.taskLists[i];
						this.taskLists.splice(parseInt(i), 1);
					}
				}
			});
	}


	setList(taskListId: number | 'favorite'): void {
		this.currentList = taskListId;
		this.setListTitle();

		this.loading = true;
	}

	private setListTitle() {
		let title: string;
		console.log('TCL: TasksComponent -> privatesetListTitle -> this.currentList', this.currentList);
		if (this.currentList === 0 || this.currentList === undefined) {
			title = 'Caixa de Entrada';
		} else if (this.currentList === 'favorite') {
			title = 'Com estrela';
		} else {

			for (let i = 0; i < this.taskLists.length; i++) {
				const element = this.taskLists[i];
				console.log('TCL: TasksComponent -> privatesetListTitle -> element', element);
				if (element.id === parseInt(this.currentList)) {

					let listName = this.taskLists[i].name;
					title = `${listName}`;
				}
			}
		}
		this._titleService.setTitle(title);
	}

	addList(): void {
		this._router.navigate(['/tasks/add-list']);
	}

	ngOnDestroy() {
		this.subRouter.unsubscribe();
		this.destroy$.unsubscribe();
	}

}
