var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { CrudManager } from '../../services/crud-manager';
import * as i0 from "@angular/core";
var FunnelSalesService = /** @class */ (function (_super) {
    __extends(FunnelSalesService, _super);
    function FunnelSalesService() {
        var _this = _super.call(this) || this;
        _this.path = '/admin/sales-funnel';
        return _this;
    }
    /**
     * Organizar Etapa
     * @param {number} id
     * @param {number} sortable
     * @returns {Observable<any>}
     */
    FunnelSalesService.prototype.sortableStep = function (id, sortable) {
        var _this = this;
        return this.http
            .post(environment.API + this.path + '/sortable/steps', {
            'step': id,
            'order_funnel': sortable,
        })
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }))
            .pipe(catchError(this.responseTreatment.handleError));
    };
    /**
     * Organizar Contato
     * @param {number} id
     * @param {number} idStep
     * @param {number} sortable
     * @param {number} realtors_managers
     * @returns {Observable<any>}
     */
    FunnelSalesService.prototype.sortableContact = function (id, idStep, sortable, realtors_managers) {
        var _this = this;
        if (realtors_managers === void 0) { realtors_managers = 0; }
        return this.http
            .post(environment.API + this.path + '/sortable/' + id, {
            'funnel_step': idStep,
            'sortable': sortable,
            'realtors_managers': realtors_managers,
        })
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }))
            .pipe(catchError(this.responseTreatment.handleError));
    };
    FunnelSalesService.ngInjectableDef = i0.defineInjectable({ factory: function FunnelSalesService_Factory() { return new FunnelSalesService(); }, token: FunnelSalesService, providedIn: "root" });
    return FunnelSalesService;
}(CrudManager));
export { FunnelSalesService };
