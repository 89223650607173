import { Helpers } from '../../services/helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
var ModalPageMobileService = /** @class */ (function () {
    function ModalPageMobileService(route, router, location) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.location = location;
        this.pages = [];
        this.route.fragment.subscribe(function (fragment) {
            if (fragment) {
                _this.open(fragment);
            }
            else {
                _this.closeAll();
            }
        });
    }
    ModalPageMobileService.prototype.add = function (page) {
        if (Helpers.empty(page.name)) {
            throw new Error('Nome da página não definida');
        }
        // add page to array of active pages
        this.pages.push(page);
    };
    ModalPageMobileService.prototype.remove = function (page) {
        // remove page from array of active pages
        this.pages = this.pages.filter(function (x) { return x.name !== page.name; });
    };
    ModalPageMobileService.prototype.open = function (id) {
        // open page specified by id
        var page = this.pages.filter(function (x) { return x.name === id; })[0];
        if (page) {
            page.open();
            this.router.navigate([], {
                fragment: id,
                preserveQueryParams: true
            });
        }
    };
    ModalPageMobileService.prototype.close = function (id) {
        // close page specified by id
        var page = this.pages.filter(function (x) { return x.name === id; })[0];
        if (page) {
            page.close();
        }
    };
    ModalPageMobileService.prototype.removeHash = function (page) {
        this.router.navigate([], {
            fragment: undefined,
            preserveQueryParams: true,
            replaceUrl: true
        });
    };
    ModalPageMobileService.prototype.closeAll = function () {
        this.pages.map(function (page) {
            page.close();
        });
    };
    ModalPageMobileService.ngInjectableDef = i0.defineInjectable({ factory: function ModalPageMobileService_Factory() { return new ModalPageMobileService(i0.inject(i1.ActivatedRoute), i0.inject(i1.Router), i0.inject(i2.Location)); }, token: ModalPageMobileService, providedIn: "root" });
    return ModalPageMobileService;
}());
export { ModalPageMobileService };
