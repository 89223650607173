import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { TasksService } from "../../models/tasks.service";
import { Tasks } from "../../interfaces/tasks";
import { Helpers } from "../../../services/helpers";
import { TaskSharedService } from "../../shareds/task.service";
import { skip } from "rxjs/operators";
import { TitleService } from "src/app/services/title.service";

@Component({
	selector: "app-tasks-edit",
	templateUrl: "./tasks-edit.component.html",
	styleUrls: ["./tasks-edit.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class TasksEditComponent implements OnInit, OnDestroy {
	task: Tasks;
	// Model da subtarefa
	subtaskText = "";

	// Model para comentário
	commentText = "";

	// Opções do sortable
	options: any;

	// Ultimo nome da lista
	lastName: string;

	// ScrollElement
	@ViewChild("taskbody") elBodyRef: ElementRef;

	subParams: any;
	subSearchComment: any;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private tasksTasksService: TasksService,
		private changeDetectorRef: ChangeDetectorRef,
		private taskObservable: TaskSharedService,
		private _titleService: TitleService
	) {}

	ngOnInit() {
		this.task = this.route.snapshot.data.task;
		this.lastName = this.task.name;

		this._titleService.setTitle(`${this.task.name}`);

		this.subParams = this.route.paramMap.pipe(skip(1)).subscribe(params => {
			this.task = this.route.snapshot.data.task;
			this.lastName = this.task.name;

			// Remove long polling
			if (this.subSearchComment) {
				this.subSearchComment.unsubscribe();
			}
			this.searchComment();
		});

		// Configurações do Sortable
		this.options = {
			group: "tasks",
			onEnd: (event: any) => {
				this.onEndSortable(event);
			}
		};

		this.searchComment();
	}

	/**
	 * Buscar novos comentários
	 * @returns {Subscription}
	 */
	searchComment() {
		let id_gt = 0;
		if (this.task.group_comments.length) {
			id_gt = this.task.group_comments[
				this.task.group_comments.length - 1
			].id;
		}

		this.subSearchComment = this.tasksTasksService
			.searchComment(this.task.id, {
				id_gt: id_gt
			})
			.subscribe(data => {
				for (let i in data.results) {
					this.task.group_comments.push(data.results[i]);
					this.changeDetectorRef.detectChanges();
					this.elBodyRef.nativeElement.scrollTop =
						this.elBodyRef.nativeElement.scrollHeight + 300;
				}
				setTimeout(() => this.searchComment(), 1500);
			});
	}

	/**
	 * Favorita ou desfavorita tarefa
	 * @param {number} taskID
	 * @param {number} favorite
	 */
	setFavorite(favorite: number) {
		this.task.favorite = favorite;

		return this.updateTask({
			favorite: favorite ? 1 : 0
		});
	}

	/**
	 * Atualizar alguma informações da tarefa
	 * @param body
	 * @returns {Subscription}
	 */
	updateTask(body) {
		body.getTask = true;

		// Se for name, verifica se é diferente
		if (body.name) {
			if (this.lastName === body.name) {
				return false;
			}
			this.lastName = body.name;
		}

		if (body.realized !== undefined) {
			body.realized = body.realized ? 1 : 0;
		}

		return this.tasksTasksService
			.update(this.task.id, body)
			.subscribe(task => {
				// Atualizar shared
				this.taskObservable.changeTask(task);
				this._titleService.setTitle(`${body.name}`);
			});
	}

	/**
	 * Adicionar subtarefa
	 * @param name
	 */
	addSubTask(name: string) {
		name = name.replace(/\n/g, "");

		if (Helpers.empty(name)) {
			return false;
		}

		this.subtaskText = "";

		// Adiciona temporario
		this.task.sub_tasks.push({
			name: name
		});

		return this.tasksTasksService
			.createSubTask(this.task.id, {
				name: name
			})
			.subscribe(data => {
				// Remove temporário
				this.task.sub_tasks.splice(this.task.sub_tasks.length - 1, 1);

				this.task.sub_tasks.push(data);
			});
	}

	/**
	 * Adicionar comentário
	 * @param message
	 */
	addComment(message: string) {
		message = message.replace(/\n/g, "");

		if (Helpers.empty(message)) {
			return false;
		}

		this.commentText = "";

		return this.tasksTasksService
			.createComment(this.task.id, {
				message: message
			})
			.subscribe(data => {
				// Remove temporario
				// this.task.group_comments.splice(this.task.group_comments.length - 1, 1);
				// this.task.group_comments.push(data);

				// Bottom
				this.elBodyRef.nativeElement.scrollTop =
					this.elBodyRef.nativeElement.scrollHeight + 200;
			});
	}

	/**
	 * Ordena subtarefa
	 * @param event
	 */
	onEndSortable(event: any): void {
		let subTaskId = this.task.sub_tasks[event.newIndex].id;

		this.tasksTasksService
			.updateSubTask(subTaskId, {
				sortable: event.newIndex
			})
			.subscribe(data => {
				console.log(data);
			});
	}

	/**
	 * Realiza tarefa
	 * @param {number} subTaskId
	 * @param checked
	 */
	subTaskCheck(subTaskId: number, checked): void {
		for (let i in this.task.sub_tasks) {
			if (this.task.sub_tasks[i].id === subTaskId) {
				this.task.sub_tasks[i].realized = checked;
			}
		}

		this.tasksTasksService
			.updateSubTask(subTaskId, {
				realized: checked ? 1 : 0
			})
			.subscribe(data => {
				console.log(data);
			});
	}

	/**
	 * Realiza tarefa
	 * @param {number} subTaskId
	 * @param checked
	 */
	deleteSubTask(subTaskId: number, checked): void {
		// Remover da Array
		let index = 0;
		for (let i in this.task.sub_tasks) {
			if (this.task.sub_tasks[i].id === subTaskId) {
				index = Number(i);
				this.task.sub_tasks.splice(index, 1);
				break;
			}
		}

		this.tasksTasksService.deleteSubTask(subTaskId).subscribe(data => {
			console.log(data);
		});
	}

	/**
	 * Deixa scroll no final das notas
	 * @param target
	 */
	scrollEndNote(target, comments) {
		if (document.activeElement === comments) {
			// let hide = false;
			// let top = this.elBodyRef.nativeElement.scrollTop + this.elBodyRef.nativeElement.clientHeight;
			// let note = this.elBodyRef.nativeElement.querySelector('#note');
			//
			// console.log(note.offsetTop + note.clientHeight);
			// console.log(top);
			//
			// if(note.offsetTop + note.clientHeight > top){
			//     hide = true;
			// }
			//
			// console.log(hide);
			// Move Scroll
			// let offset = (this.elBodyRef.nativeElement.scrollHeight - this.elBodyRef.nativeElement.clientHeight);
			// this.elBodyRef.nativeElement.scrollTop = offset - (this.elBodyRef.nativeElement.scrollHeight - target.offsetTop - 94);
			// this.changeDetectorRef.detectChanges();
		}
	}

	ngOnDestroy() {
		this.subParams.unsubscribe();
		this.subSearchComment.unsubscribe();
	}
}
