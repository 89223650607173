import { Injectable } from '@angular/core';
import { CrudManager } from '../../services/crud-manager';
import { isArray } from 'util';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ClientsDemandService extends CrudManager {
	path = '/admin/clients-demand';

	constructor() {
		super();
	}

	processFilters(data = {}) {
		let params = { ...data };

		return params;
	}

}
