<div class="app-users autocomplete" (clickOutside)="setFocus(false)">
    <input type="text"
           (focus)="setFocus(true)"
           *ngIf="!selectedUser"
           [(ngModel)]="textModel"
           (input)="changeLocationInput($event)">

    <div class="user" *ngIf="selectedUser">
        <div class="table">
            <div class="td td-min">
                <app-user-img [user]="selectedUser" [size]="'small'"></app-user-img>
            </div>
            <div class="td td-overflow-text pl-2 text-primary font-weight-bold">
                {{selectedUser.name}}
            </div>
            <div class="td text-primary font-weight-bold remove" (click)="removeSelectUser()">
                <i class="fas fa-times"></i>
            </div>
        </div>
    </div>

    <div class="app-users--loading-icon" *ngIf="loading">
        <i class="fas fa-spinner"></i>
    </div>

    <ul class="autocomplete-list" *ngIf="showAutocomplete">
        <li class="user-row"
            [class.active]="selectNumber == index"
            (click)="selectUser(user)"
            *ngFor="let user of this.users; let index = index">
            <div class="table" [class.blocked]="user.blocked">
                <div class="td td-img">
                    <app-user-img [user]="user" [size]="'small'"></app-user-img>
                </div>
                <div class="td font-weight-bold td-overflow-text">
                    <span class="username text-primary">
                        {{user.name}}
                    </span>
                    <br>
                    <span class="role td-overflow-text" *ngIf="!MASTER">
                        <span *ngIf="user.blocked">Bloqueado - </span> {{user.role_name}}
                    </span>
					<span class="role td-overflow-text" *ngIf="MASTER">
                        {{user.realestate.name}}
                    </span>
                </div>
            </div>
        </li>

        <li class="notfound" *ngIf="!users.length">
            Nenhum usuário encontrado.
        </li>
    </ul>
</div>
