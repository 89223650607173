import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {debounceTime, distinctUntilChanged, filter, mergeMap} from 'rxjs/operators';
import {Helpers} from '../../../services/helpers';
import {ContactsService} from '../../models/contacts.service';
import {Subject} from 'rxjs';
import {Contacts} from '../../interfaces/contacts';

@Directive({
    selector: '[appContactDuplicatePhone]'
})
export class ContactDuplicatePhoneDirective {

    focus = false;
    currentContact: any;
    currentText = '';
    searchTextChanged = new Subject<string>();

    @Output() appContactDuplicatePhone = new EventEmitter();

    constructor(
        private elementRef: ElementRef,
        private contactsService: ContactsService
    ) {
        this.searchTextChanged
            .pipe(debounceTime(350))
            .pipe(distinctUntilChanged())
            .pipe(filter(t => !!t))
            .pipe(mergeMap(search => this.searchUsers(search)))
            .subscribe(data => {
                this.searchTextChanged.next(null);
                this.setContact();
                for (let i in data['results']) {
                    if (data['results'].hasOwnProperty(i)) {
                        let contact: Contacts = data['results'][i];
                        for (let i in contact.phones) {
                            if (contact.phones[i].number.toString() === this.currentText.toString()) {
                                this.setContact(contact);
                                break;
                            }
                        }
                    }
                }
            });
    }

    setContact(contact?) {
        this.currentContact = contact;

        if (!this.focus) {
            if (this.currentContact && !!this.elementRef.nativeElement.value) {
                this.appContactDuplicatePhone.emit(this.currentContact);
            }
        }
    }

    @Input('maskInput') maskInput: string;

    searchUsers(search) {
        return this.contactsService.search({
            'phone': search
        });
    }

    @HostListener('focus', ['$event'])
    onFocus($event: any) {
        this.focus = true;
    }

    @HostListener('blur', ['$event'])
    onBlur() {
        this.focus = false;

        if (this.currentContact && !!this.elementRef.nativeElement.value) {
            this.appContactDuplicatePhone.emit(this.currentContact);
        }
    }

    @HostListener('change')
    onChange() {
    }

    @HostListener('keyup', ['$event'])
    onKeyup($event: any) {
        let newVal = $event.target.value;
        console.log(newVal);

        if (this.currentText === newVal) {
            return;
        }

        this.currentText = Helpers.onlyNumbers(newVal);

        this.setContact();

        if (Helpers.verifyPhone(this.currentText)) {
            this.searchTextChanged.next(this.currentText);
        } else {
            this.searchTextChanged.next(null);
        }

    }

}
