import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation
} from "@angular/core";
import { Contacts } from "../../../interfaces/contacts";
import { ActivatedRoute, Router } from "@angular/router";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { skip, takeUntil } from "rxjs/operators";
import { ContactsService } from "../../../models/contacts.service";
import { Helpers } from "../../../../services/helpers";
import { EnterLeaveLeftIn } from "../../../animations/enter-leave-leftIn";
import { Global } from "../../../../services/global";
import { ModalAnimation } from "../../../animations/animations";
import { TitleService } from "src/app/services/title.service";
import { environment } from "../../../../../environments/environment";
import { ToastrService } from "ngx-toastr";
import { Users } from "../../../interfaces/users";
import { GlobalService } from "../../../shareds/global.service";
import { Subject } from "rxjs";
import { ModalPageMobileService } from "../../../shareds/modal-page-mobile.service";

@Component({
	selector: "app-contacts-list",
	templateUrl: "./contacts-list.component.html",
	styleUrls: ["./contacts-list.component.scss"],
	encapsulation: ViewEncapsulation.None,
	animations: [EnterLeaveLeftIn, ModalAnimation]
})
export class ContactsListComponent implements OnInit, OnDestroy {
	me: Users;

	contacts: Array<Contacts>;
	contacts_count: number;

	// Contato selecionados
	contactsSelected: Array<number> = [];
	// Se todos os recursos estão selecionados e existem mais recursos em outra página
	selectedAllPage = false;
	// Selecionar todos os contatos
	selectedAllContacts = false;

	perPage = 20;
	currentPage = 1;

	isSearch = false;

	// Buscando...
	searching = false;

	// Filtros
	filters: FormGroup;

	contactDelete: Contacts;
	modalDeleteContact = false;

	contactView: Contacts;

	// Auxiliaresx
	subParams: any;
	types: Array<any>;
	users_admin: Array<any>;
	estates: Array<any>;
	colors: string[] = [
		"#ff5e65",
		"#f76352",
		"#ff5e84",
		"#a05fb5",
		"#df7dc6",
		"#28c28b",
		"#ffc157",
		"#ff8681",
		"#00c7e2",
		"#01e6de",
		"#009bda",
		"#0079aa"
	];

	private destroy$ = new Subject();

	BUYERS = false;
	MASTER = 0;
	MOBILE = false;

	title = "";

	tabs: any = [
		{
			id: "Todos",
			title: "Todos",
			badge: "--",
			active: true
		}
	];

	actions = [
		{
			id: "filters",
			body: '<i class="fas fa-filter"></i>'
		},
		{
			id: "add",
			body: '<i class="fas fa-plus"></i>'
		}
	];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private _globalService: GlobalService,
		private toastr: ToastrService,
		private changeDetectorRef: ChangeDetectorRef,
		private contactsService: ContactsService,
		private modalPageMobileService: ModalPageMobileService,
		private _titleService: TitleService
	) {}

	ngOnInit() {
		this._globalService.user
			.pipe(takeUntil(this.destroy$))
			.subscribe((user: any) => {
				this.me = user;
			});

		this.BUYERS = this.route.snapshot.data.buyers;
		this.MASTER = Global.user.user_master_bdi;
		this.MOBILE = environment.mobile;

		this.contacts = this.route.snapshot.data.contacts.results;
		this.contacts_count = this.route.snapshot.data.contacts.count;
		this.tabs[0].badge = this.contacts_count;

		this.types = Global.info.contacts.types;
		this.users_admin = Global.info.users_admin;
		this.estates = Global.info.estates;

		// Configura Filtros
		this.configureFilters(
			this.contactsService.processFilters(this.route.snapshot.queryParams)
		);

		/**
		 * Query subscribe
		 * @type {Subscription}
		 */
		this.subParams = this.route.queryParams
			.pipe(skip(1))
			.subscribe(data => {
				data = this.contactsService.processFilters(data);
				this.loadContacts(data);
			});

		this.setTitle();
		this.setTitleSearch();

		this.modalPageMobileService.open("contact-mininal-view");
	}

	setAction(action: any) {
		if (action.id === "filters") {
			this.modalPageMobileService.open("contacts-filters");
		} else if (action.id === "add") {
			this.router.navigate(["/contacts/add"]);
		}
	}

	setTab(tab: any) {
		// this.destroyHttp$.next();
		//
		// this.title = '';
		//
		// if (tab.id === 'my-properties') {
		// 	this.PARTNERSHIP = false;
		// 	this.APPROVAL = false;
		// 	this.listType = 'properties';
		// 	// this.router.navigate(['/properties/list']);
		// } else if (tab.id === 'partners') {
		// 	this.PARTNERSHIP = true;
		// 	this.APPROVAL = false;
		// 	this.listType = 'partners';
		// } else if (tab.id === 'approval') {
		// 	this.APPROVAL = true;
		// 	this.PARTNERSHIP = false;
		// 	this.listType = 'approval';
		// 	// this.router.navigate(['/properties/approval']);
		// }
		//
		// this.starting = true;
		//
		// this.loadCached();
	}

	setTitleSearch() {
		if (!this.BUYERS) {
			this.title = "Contatos";
		} else {
			this.title = "Compradores";
		}
	}

	openView(contact: Contacts) {
		console.log(contact);
		this.contactView = contact;
		this.modalPageMobileService.open("contact-mininal-view");
	}

	/**
	 * Resgata mais contatos
	 * @param data
	 * @returns {Subscription}
	 */

	loadContacts(data) {
		if (this.searching) {
			return false;
		}

		this.selectedAllPage = false;
		this.selectedAllContacts = false;
		this.contactsSelected = [];

		this.searching = true;

		if (this.BUYERS) {
			data["type_in"] = [3, 8];
		}

		return this.contactsService.search(data).subscribe(contacts => {
			this.contacts = [];
			this.changeDetectorRef.detectChanges();

			this.contacts = contacts.results;
			this.contacts_count = contacts.count;
			this.tabs[0].badge = this.contacts_count;
			this.searching = false;
		});
	}

	/**
	 * Configura os filtros inicialmente, vindo da URL
	 * @param data
	 */
	configureFilters(data?: any) {
		const types = this.types.map(c => {
			if (data.type_in) {
				return new FormControl(data.type_in.indexOf(c.id) !== -1);
			} else {
				return new FormControl(false);
			}
		});

		const users = this.users_admin.map(c => {
			if (data.realtors_managers_in) {
				return new FormControl(
					data.realtors_managers_in.indexOf(c.id) !== -1
				);
			} else {
				return new FormControl(false);
			}
		});

		if (!data.offset) {
			data.offset = 0;
		}
		this.currentPage = parseInt(String(data.offset / this.perPage), 10) + 1;
		data.offset = (this.currentPage - 1) * this.perPage;
		console.log(this.currentPage);

		this.filters = this.fb.group({
			cod: [data.cod || "", []],
			sort: [data.sort || "", []],
			limit: [data.limit || "", []],
			offset: [data.offset, []],
			name: [data.name || "", []],
			email: [data.email || "", []],
			phone: [data.phone || "", []],
			archive: [data.archive || "", []],
			favorite: [data.favorite || "", []],
			date_lte: [data.date_lte || "", []],
			date_gte: [data.date_gte || "", []],
			type: [data.type || "", []],
			types: new FormArray(types),
			users: new FormArray(users),
			finality: [data.finality || "", []],
			price_lte: [data.price_lte || "", []],
			ps_estate: [data.ps_estate || "", []],
			ps_city: [data.ps_city || "", []],
			ps_neighborhood: [data.ps_neighborhood || "", []]
		});

		/**
		 * Verifica se existe algo sendo buscado
		 * @type {{}}
		 */
		let params: any = Helpers.filterArray(this.getFilters(), [
			Helpers.WITH_ZERO
		]);
		params.offset = undefined;
		this.isSearch = !Helpers.empty(
			Helpers.filterArray(params, [Helpers.WITH_ZERO])
		);
	}

	/**
	 * Ordernar resultado
	 * @param {string} sort
	 */
	sort(sort: string) {
		let _sort: string = this.filters.controls.sort.value;

		if (_sort === sort) {
			this.filters.controls.sort.setValue("-" + sort);
		} else if (_sort.substring(1) === sort) {
			this.filters.controls.sort.setValue("");
		} else {
			this.filters.controls.sort.setValue(sort);
		}

		this.updateParams();
	}

	closeContactView() {
		this.modalPageMobileService.close("contacts-filters");
	}

	/**
	 * Trocar página
	 * @param {number} page
	 */
	setPage(page: number) {
		this.currentPage = page;
		this.setTitle();
		page = page - 1;

		this.filters.controls.offset.setValue(page * this.perPage);

		this.updateParams(false);
	}

	/**
	 * Faz o tratamento dos filtros antes da busca
	 * @returns {any}
	 */
	getFilters() {
		let filters = { ...this.filters.value };
		filters.type_in = filters.types
			.map((v, i) => (v ? this.types[i].id : null))
			.filter(v => v !== null);

		filters.realtors_managers_in = filters.users
			.map((v, i) => (v ? this.users_admin[i].id : null))
			.filter(v => v !== null);

		// Para não ficar url imenso
		filters.users = undefined;
		filters.types = undefined;

		return filters;
	}

	/**
	 * Atualiza novos parametros
	 */
	updateParams(newSearch = true) {
		// Reset página
		if (newSearch) {
			this.filters.controls.offset.setValue(undefined);
			this.currentPage = 1;
		}

		/**
		 * Verifica se existe algo sendo buscado
		 * @type {{}}
		 */
		let params: any = Helpers.filterArray(this.getFilters(), [
			Helpers.WITH_ZERO
		]);
		params.offset = undefined;
		this.isSearch = !Helpers.empty(
			Helpers.filterArray(params, [Helpers.WITH_ZERO])
		);

		this.router.navigate([], {
			queryParams: Helpers.filterArray(this.getFilters(), [
				Helpers.WITH_ZERO
			])
		});
	}

	/**
	 * Limpar Busca
	 */
	clearParams() {
		this.filters.reset();
		this.updateParams();
	}

	/**
	 * Busca valor de key nas array de contatos pelo ID
	 * @param {number} id
	 * @returns {number}
	 */
	searchContactByID(id: number) {
		for (let i in this.contacts) {
			if (id === Number(this.contacts[i].id)) {
				return Number(i);
			}
		}

		return -1;
	}

	/**
	 * Favoritar Contatos
	 */
	favoriteContact(id: number, favorite: boolean) {
		let index = this.searchContactByID(id);
		console.log(index);

		this.contacts[index].favorite = favorite;

		this.contactsService
			.update(id, {
				favorite: favorite ? 1 : 0
			})
			.subscribe(contact => {});
	}

	/**
	 * Arquivar Contato
	 */
	archiveContact(id: number, archive: boolean) {
		let index = this.searchContactByID(id);
		console.log(index);

		this.contacts[index].archive = archive;

		this.contactsService
			.update(id, {
				archive: archive ? 1 : 0
			})
			.subscribe(contact => {});
	}

	openModalDelete(id: number) {
		let index = this.searchContactByID(id);

		this.contactDelete = this.contacts[index];
		this.modalDeleteContact = true;
	}

	/**
	 * Deletar Contato
	 */
	deleteContact(id: number) {
		this.modalDeleteContact = false;

		let index = this.searchContactByID(id);

		this.contactsService.delete(id).subscribe(contact => {
			this.toastr.success("Contato deletado com sucesso!");
			this.refresh();
			this.modalDeleteContact = false;
			this.modalPageMobileService.close("contacts-filters");
		});
	}

	/**
	 * Atualizar Muitos Contatos
	 */
	favoriteManyContact(favorite) {
		for (let i in this.contacts) {
			if (this.contactsSelected.indexOf(this.contacts[i].id) !== -1) {
				this.contacts[i].favorite = favorite;
			}
		}

		this.contactsService
			.favorite({
				ids: this.selectedAllContacts ? "ALL" : this.contactsSelected,
				status: favorite ? 1 : 0
			})
			.subscribe(contact => {});
	}

	checkContact(id: number, checked: boolean) {
		if (checked) {
			this.contactsSelected.push(id);
		} else {
			let index = this.contactsSelected.indexOf(id);
			if (index !== -1) {
				this.contactsSelected.splice(index, 1);
			}
		}

		this.checkAllSelected();
	}

	/**
	 * Selecionar todos os contatos
	 */
	selectAllContacts() {
		this.selectedAllContacts = false;

		this.contactsSelected = [];

		for (let i in this.contacts) {
			this.contacts[i].checked = true;
			this.contactsSelected.push(this.contacts[i].id);
		}

		this.checkAllSelected();
	}

	/**
	 * Deselecionar todos os contatos
	 */
	deselectAllContacts() {
		this.contactsSelected = [];

		for (let i in this.contacts) {
			this.contacts[i].checked = false;
		}

		this.checkAllSelected();
	}

	/**
	 * Verifica se todos os contatos estão selecionados
	 * e se existem mais contatos do que o total dessa página
	 * para da a opção para o usuário poder selecionar todos
	 * os contatos da lista
	 */
	checkAllSelected() {
		this.selectedAllPage = false;
		this.selectedAllContacts = false;

		if (
			this.contactsSelected.length === this.contacts.length &&
			this.contacts.length < this.contacts_count
		) {
			this.selectedAllPage = true;
		}
	}

	createPartners() {
		this.router.navigate(
			["", { outlets: { modal: "add-chat-partners" } }],
			{
				queryParams: {
					contacts_to_tickets: this.contactsSelected
				}
			}
		);
	}

	applyFilter() {
		this.updateParams();
	}

	openWhatsapp(number: string) {
		window.open("whatsapp://send?phone=55" + number, "_system");
	}

	/**
	 * Atualiza contatos
	 */
	refresh() {
		let data = Helpers.filterArray(this.getFilters(), [Helpers.WITH_ZERO]);

		this.loadContacts(data);
	}

	private setTitle() {
		let title = "Lista de Contatos";
		this._titleService.setListTitle(title, this.currentPage);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}
}
