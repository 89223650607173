<div class="modal-screen modal-select-properties change-mobile-page">

		<div class="modal-body d-flex">

			<div class="header-page">
				<div class="i-close" (click)="close()">
					<div class="icon-close-only black s32x32 display-close"></div>
					<img src="/assets/svg/home.svg" class="display-back">
				</div>

				<div class="title" *ngIf="checkTab('contact_nota')">
					Editar Notar
				</div>

				<div class="title" *ngIf="checkTab('properties_visit')">
					Editar Visita
				</div>

				<div class="title" *ngIf="checkTab('commitment')">
					Editar Compromisso
				</div>

				<div class="title" *ngIf="checkTab('task')">
					Editar Tarefa
				</div>
			</div>

			<div class="search-list-container">
				<form [formGroup]="form" (submit)="save()" *ngIf="checkTab('contact_nota')">

					<div class="row m-0">
						<div class="col">
							<div class="field-container pr-sm-0">
								<textarea name="" id="" cols="30" rows="10" formControlName="message" [class.error-input]="isFieldValid('message')"></textarea>

								<div class="name">
									Observação
								</div>
							</div>

							<app-field-error-display [displayError]="isFieldValid('message')" errorMsg="O campo é obrigatório!"></app-field-error-display>
						</div>
					</div>

					<div class="text-center pt-2">
						<button class="btn btn-primary" (click)="save()" type="button">
							Editar Compromisso
						</button>
					</div>
				</form>

				<form [formGroup]="form" (submit)="save()" *ngIf="checkTab('properties_visit')">

					<div class="row m-0">
						<div class="col-12 pb-2">

							<div class="name-input">Oque o cliente achou do imóvel?</div>

							<div class="btn-group-checked btn-group-border">
								<div class="btn" [class.active]="visitType == 'not_like'" (click)="setVisitType('not_like')">
									Não Gostou
								</div>
								<div class="btn" [class.active]="visitType == 'thinking'" (click)="setVisitType('thinking')">
									Vai pensar
								</div>
								<div class="btn" [class.active]="visitType == 'reasonable'" (click)="setVisitType('reasonable')">
									Razoável
								</div>
								<div class="btn" [class.active]="visitType == 'like'" (click)="setVisitType('like')">
									Gostou
								</div>
								<div class="btn" (click)="setVisitType('')">
									<i class="fas fa-times"></i>
								</div>
							</div>
						</div>
					</div>

					<div class="row m-0">
						<div class="col">
							<div class="field-container pr-sm-0">
								<textarea cols="30" rows="3" formControlName="message" [class.error-input]="isFieldValid('message')"></textarea>

								<div class="name">
									Observação
								</div>
							</div>

							<app-field-error-display [displayError]="isFieldValid('message')" [errorMsg]="'O campo é obrigatório!'"></app-field-error-display>
						</div>
					</div>

					<div class="text-center pt-2">
						<button class="btn btn-primary" (click)="save()" type="button">
							Editar Compromisso
						</button>
					</div>
				</form>

				<form [formGroup]="form" (submit)="save()" *ngIf="checkTab('commitment')">
					<div>
						<div class="row m-0">
							<div class="col-12">
								<div class="field-container" [class.error-input]="isFieldValid('name')">
									<input type="text" formControlName="name">
									<div class="name">Nome do evento</div>
								</div>

								<app-field-error-display [displayError]="isFieldValid('name')" errorMsg="Informe o nome do evento">
								</app-field-error-display>
							</div>
						</div>

						<div class="row m-0">
							<div class="col-12 pb-2">

								<div class="name-input">Tipo do Compromisso</div>

								<div class="btn-group-checked btn-group-border">
									<div class="btn" [class.active]="typeCommitment == 'visit'" (click)="setCommitType('visit')">
										<i class="fas fa-map-marker-alt"></i> Visita
									</div>
									<div class="btn" [class.active]="typeCommitment == 'meeting'" (click)="setCommitType('meeting')">
										<i class="fas fa-users"></i> Reunião
									</div>
									<div class="btn" [class.active]="typeCommitment == 'email'" (click)="setCommitType('email')">
										<i class="fas fa-envelope"></i> E-mail
									</div>
									<div class="btn" [class.active]="typeCommitment == 'call'" (click)="setCommitType('call')">
										<i class="fas fa-phone"></i> Ligação
									</div>
									<div class="btn" [class.active]="typeCommitment == 'after_sales'" (click)="setCommitType('after_sales')">
										<i class="fas fa-user-tag"></i> Pós-Venda
									</div>
									<div class="btn" (click)="setCommitType('')">
										<i class="fas fa-times"></i>
									</div>
								</div>
							</div>
						</div>

						<div class="row m-0">
							<div class="col-md-7">
								<div class="field-container pr-sm-0" [class.error-input]="isFieldValid('start_at')">
									<app-input-datepicker formControlName="start_at" [allDay]="form.get('all_day').value"
									 (allDayChange)="allDay($event)">
									</app-input-datepicker>
									<div class="name">
										<i class="far fa-calendar-alt"></i>
										Data do Compromisso
									</div>
								</div>

								<app-field-error-display [displayError]="isFieldValid('start_at')" errorMsg="Informe o nome do evento">
								</app-field-error-display>
							</div>
						</div>

						<div class="row m-0">
							<div class="col-12">
								<div class="field-container pr-sm-0">
									<app-input-multi-users formControlName="shared_users">
									</app-input-multi-users>
									<div class="name">
										<i class="fas fa-user-plus"></i>
										Compartilhar com usuários
									</div>
								</div>
							</div>
						</div>

						<div class="row m-0">
							<div class="col">
								<div class="field-container pr-sm-0">
									<textarea name="" id="" cols="30" rows="10" formControlName="description"></textarea>

									<div class="name">
										Observação
									</div>
								</div>
							</div>
						</div>

						<div class="row m-0">
							<div class="col">
								<label class="checkbox-container">
									<input type="checkbox" formControlName="realized">
									<div class="checkbox"></div>
									<span class="text"><i class="fas fa-check"></i> Compromisso realizado</span>
								</label>
							</div>
						</div>

						<div class="text-center pt-2">
							<button class="btn btn-primary" (click)="save()" type="button">
								Editar Compromisso
							</button>
						</div>
					</div>
				</form>

				<form [formGroup]="form" (submit)="save()" *ngIf="checkTab('task')">
					<div class="row m-0">
						<div class="col-12">
							<div class="field-container" [class.error-input]="isFieldValid('name')">
								<input type="text" formControlName="name">
								<div class="name">Nome da tarefa</div>
							</div>

							<app-field-error-display [displayError]="isFieldValid('name')" errorMsg="Informe o nome do evento">
							</app-field-error-display>
						</div>
					</div>

					<div class="row m-0">
						<div class="col-md-7">
							<div class="field-container pr-sm-0" [class.error-input]="isFieldValid('deadline_at')">
								<app-input-datepicker formControlName="deadline_at" [allDay]="form.get('all_day').value" (allDayChange)="allDay($event)">
								</app-input-datepicker>
								<div class="name">
									<i class="far fa-calendar-alt"></i>
									Definir data limite em
								</div>
							</div>

							<app-field-error-display [displayError]="isFieldValid('deadline_at')" errorMsg="Informe o nome do evento">
							</app-field-error-display>
						</div>
					</div>


					<div class="row m-0">
						<div class="col">
							<div class="field-container pr-sm-0">
								<textarea cols="30" rows="5" formControlName="comments"></textarea>

								<div class="name">
									Nota
								</div>
							</div>
						</div>
					</div>

					<div class="row m-0">
						<div class="col">
							<label class="checkbox-container">
								<input type="checkbox" formControlName="realized">
								<div class="checkbox"></div>
								<span class="text"><i class="fas fa-check"></i> Tarefa realizada</span>
							</label>
						</div>
					</div>

					<div class="text-center pt-2">
						<button class="btn btn-primary" (click)="save()" type="button">
							Editar Compromisso
						</button>
					</div>
				</form>

			</div>

		</div>

	</div>
