import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class WebsocketService {

	static messageSource = new BehaviorSubject(false);
	static statusSource = new BehaviorSubject('');
	static mutationSource = new BehaviorSubject({});
	static eventsSource = new BehaviorSubject({});

	static message = WebsocketService.messageSource.asObservable();
	static status = WebsocketService.statusSource.asObservable();
	static mutation = WebsocketService.mutationSource.asObservable();
	static events = WebsocketService.eventsSource.asObservable();

	constructor() {
	}

	static changeMessage(data: any) {
		WebsocketService.messageSource.next(data);
	}

	static changeStatus(status: string) {
		WebsocketService.statusSource.next(status);
	}

	static changeMutation(data: any) {
		WebsocketService.mutationSource.next(data);
	}

	static changeEvents(data: any) {
		WebsocketService.eventsSource.next(data);
	}

}
