import { ResponseTreatment } from '../../services/response-treatment';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NotificationsService = /** @class */ (function () {
    function NotificationsService(http) {
        this.http = http;
        this.responseTreatment = new ResponseTreatment;
    }
    /**
     * Badges
     * @param data
     * @returns {Observable<Object>}
     */
    NotificationsService.prototype.badges = function () {
        var _this = this;
        return this.http
            .get(environment.API + '/admin/notifications/badges')
            .pipe(map(function (res) { return _this.responseTreatment.pipe(res); }));
    };
    NotificationsService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(i0.inject(i1.HttpClient)); }, token: NotificationsService, providedIn: "root" });
    return NotificationsService;
}());
export { NotificationsService };
