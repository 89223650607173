var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var Helpers = /** @class */ (function () {
    function Helpers() {
    }
    Helpers.unique_hash = function () {
        // Math.random should be unique because of its seeding algorithm.
        // Convert it to base 36 (numbers + letters), and grab the first 9 characters
        // after the decimal.
        return '_' + Math.random().toString(36).substr(2, 9);
    };
    Helpers.scrollToLeft = function (element, to, duration) {
        var start = element.scrollLeft, change = to - start, currentTime = 0, increment = 20;
        var animateScroll = function () {
            currentTime += increment;
            var val = Helpers.easeInOutQuad(currentTime, start, change, duration);
            element.scrollLeft = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    };
    Helpers.easeInOutQuad = function (t, b, c, d) {
        t /= d / 2;
        if (t < 1) {
            return c / 2 * t * t + b;
        }
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };
    /**
     * numberFormat
     * @param number
     * @param {number} decimals
     * @param {string} decPoint
     * @param {string} thousandsSep
     * @returns {string}
     */
    Helpers.numberFormat = function (number, decimals, decPoint, thousandsSep) {
        if (decimals === void 0) { decimals = 2; }
        if (decPoint === void 0) { decPoint = ','; }
        if (thousandsSep === void 0) { thousandsSep = '.'; }
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number;
        var prec = !isFinite(+decimals) ? 2 : Math.abs(decimals);
        var sep = (typeof thousandsSep === 'undefined') ? '.' : thousandsSep;
        var dec = (typeof decPoint === 'undefined') ? ',' : decPoint;
        var toFixedFix = function (_n, _prec) {
            var k = Math.pow(10, _prec);
            return '' + (Math.round(_n * k) / k)
                .toFixed(_prec);
        };
        var s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    };
    /**
     * Remove valores null de arrays e objetos
     * @param array
     * @returns {{}}
     */
    Helpers.removeNull = function (array) {
        var newArray = {};
        for (var i in array) {
            if (array[i]) {
                newArray[i] = array[i];
            }
        }
        return newArray;
    };
    /**
     * Apenas números
     */
    Helpers.onlyNumbers = function (string) {
        if (string === null) {
            return '';
        }
        string = string.toString();
        return string.replace(/[^0-9]/g, '');
    };
    /**
     * Remove valores empty
     * @param array
     * @param options
     * @returns {{}}
     */
    Helpers.filterArray = function (array, options) {
        if (options === void 0) { options = []; }
        var newArray = {};
        for (var i in array) {
            if (!Helpers.empty(array[i])) {
                newArray[i] = array[i];
            }
            // Não deve remover 0
            if (options.indexOf(Helpers.WITH_ZERO) !== -1 && (array[i] === 0 || array[i] === '0')) {
                newArray[i] = array[i];
            }
        }
        return newArray;
    };
    /**
     * Remove valores empty
     * @param array
     * @returns {{}}
     */
    Helpers.filterObject = function (array) {
        var _array = __assign({}, array);
        var newArray = {};
        for (var i in _array) {
            if (typeof _array[i] === 'object') {
                _array[i] = Helpers.filterObject(_array[i]);
            }
            if (!Helpers.empty(_array[i])) {
                newArray[i] = _array[i];
            }
        }
        return newArray;
    };
    /**
     * Normaliza String
     * @param {string} string
     * @returns {string}
     */
    Helpers.normalizeString = function (string) {
        string = string
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLocaleLowerCase();
        return string;
    };
    /**
     * Empty variable
     * @param {string} string
     * @returns {string}
     */
    Helpers.empty = function (mixedVar) {
        //  discuss at: http://locutus.io/php/empty/
        // original by: Philippe Baumann
        //    input by: Onno Marsman (https://twitter.com/onnomarsman)
        //    input by: LH
        //    input by: Stoyan Kyosev (http://www.svest.org/)
        // bugfixed by: Kevin van Zonneveld (http://kvz.io)
        // improved by: Onno Marsman (https://twitter.com/onnomarsman)
        // improved by: Francesco
        // improved by: Marc Jansen
        // improved by: Rafał Kukawski (http://blog.kukawski.pl)
        //   example 1: empty(null)
        //   returns 1: true
        //   example 2: empty(undefined)
        //   returns 2: true
        //   example 3: empty([])
        //   returns 3: true
        //   example 4: empty({})
        //   returns 4: true
        //   example 5: empty({'aFunc' : function () { alert('humpty'); } })
        //   returns 5: false
        var undef;
        var key;
        var i;
        var len;
        var emptyValues = [undef, null, false, 0, '', '0'];
        for (i = 0, len = emptyValues.length; i < len; i++) {
            if (mixedVar === emptyValues[i]) {
                return true;
            }
        }
        if (typeof mixedVar === 'object') {
            for (key in mixedVar) {
                if (mixedVar.hasOwnProperty(key)) {
                    return false;
                }
            }
            return true;
        }
        return false;
    };
    /**
     * Check Email
     * @param mixedVar
     * @returns {boolean}
     */
    Helpers.verifyEmail = function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    /**
     * Check Email
     * @param mixedVar
     * @returns {boolean}
     */
    Helpers.verifyPhone = function (email) {
        if (!email) {
            return false;
        }
        return email.length >= 10 && email.length <= 11;
    };
    /**
     * Const filterArray
     */
    Helpers.WITH_ZERO = 0;
    return Helpers;
}());
export { Helpers };
