import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Properties} from '../../interfaces/properties';
import {PricePipe} from '../../pipes/price.pipe';
import {ModalAnimation} from '../../animations/animations';
import {ModalDefault} from '../../shareds/modal-default.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
	selector: 'app-modal-property-view',
	templateUrl: './modal-property-view.component.html',
	styleUrls: ['./modal-property-view.component.scss'],
	animations: [ModalAnimation],
	providers: [PricePipe],
})
export class ModalPropertyViewComponent implements OnInit, OnChanges {

	openModal = false;

	property: Properties;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private pricePipe: PricePipe
	) {
		this.property = this.route.snapshot.data.property;
	}

	ngOnInit() {
		this.updateLoad();
	}

	updateLoad() {
		if (this.property) {
			let details = [];

			if (this.property.area_total) {
				details.push({
					name: 'Área Total',
					value: this.property.area_total + 'm2',
				});
			}

			if (this.property.area_useful) {
				details.push({
					name: 'Área Útil',
					value: this.property.area_useful + 'm2',
				});
			}

			if (this.property.townhouse_price) {
				details.push({
					name: 'Condominio',
					value: 'R$ ' + this.pricePipe.transform(this.property.townhouse_price),
				});
			}

			if (this.property.IPTU) {
				details.push({
					name: 'IPTU',
					value: 'R$ ' + this.pricePipe.transform(this.property.IPTU)
				});
			}

			if (this.property.financing) {
				details.push({
					name: 'Financia?',
					value: 'Sim'
				});
			}
			if (this.property.FGTS) {
				details.push({
					name: 'Aceita FGTS?',
					value: 'Sim'
				});
			}
			if (this.property.credit_card) {
				details.push({
					name: 'Aceita Cartão de Crédito?',
					value: 'Sim'
				});
			}
			if (this.property.MCMV) {
				details.push({
					name: 'Minha Casa Minha Vida?',
					value: 'Sim'
				});
			}
			if (this.property.exchange) {
				details.push({
					name: 'exchange',
					value: 'Sim'
				});
			}

			for (let i in this.property.composition) {
				details.push(this.property.composition[i]);
			}

			this.property.details = details;
		}
	}

	createPartners() {

		this.router.navigate
		(
			['', {outlets: {modal: 'add-chat-partners'}}],
			{
				queryParams: {
					property_id: this.property.id
				}
			}
		);
	}

	close() {
		this.router.navigate(['/', { outlets: { modal: null } }]);
	}

	ngOnChanges(): void {
		this.updateLoad();
	}

}
