<div class="contact-page-list mobile-page">
	<div class="flex-grow-0">
		<app-nav-bar
			[title]="title"
			[tabs]="tabs"
			[actions]="actions"
			(actionEvent)="setAction($event)"
			(tabEvent)="setTab($event)"
		>
		</app-nav-bar>
	</div>

	<div class="flex-grow-0 show-mobile">
		<div class="search-box" *ngIf="isSearch" (click)="clearParams()">
			<div class="table">
				<div class="td">
					{{ contacts_count }} resultados para sua busca
				</div>
				<div class="td td-min">
					Limpar
				</div>
			</div>
		</div>
	</div>

	<div class=" m-0 p-0 filters">
		<div class="filters-container" appCompleteHeight>
			<div class="show-mobile">
				<app-modal-page-mobile
					[title]="'Filtrar'"
					name="contacts-filters"
				>
					<ng-container
						*ngTemplateOutlet="filtersTemplate"
					></ng-container>
				</app-modal-page-mobile>
			</div>

			<div class="hide-mobile">
				<ng-container
					*ngTemplateOutlet="filtersTemplate"
				></ng-container>
			</div>
		</div>
	</div>

	<div class="contacts-page-list align-top position-relative">
		<!--<div [routerModalPageMobile]="'contacts-filters'" class="btn-circle-action search-btn show-mobile">-->
		<!--<i class="fas fa-search"></i>-->
		<!--</div>-->

		<div class="loading" *ngIf="searching">
			<div class="loading-icon">
				<div class="load">
					<div></div>
				</div>
			</div>
		</div>

		<div class="contacts-list-cards">
			<div class="header-search hide-mobile">
				<h1 class="title">
					<div class="row m-0">
						<div class="col" *ngIf="!BUYERS">
							Contatos
						</div>
						<div class="col" *ngIf="BUYERS">
							Compradores
						</div>
					</div>
				</h1>

				<div class="tabs">
					<div class="tab active">
						Todos ({{ contacts_count | number }})
					</div>
					<!--<div class="tab">-->
					<!--Arquivados (12.200)-->
					<!--</div>-->
					<!--<div class="tab">-->
					<!--Favoritos (12.200)-->
					<!--</div>-->
				</div>

				<!--<sticky [sticky-offset-top]="50">-->
				<!---->
				<!--</sticky>-->

				<div class="table pt-3" *ngIf="contacts.length">
					<div class="td">
						<!-- Select -->

						<div
							class="header-icon select-all"
							(click)="selectAllContacts()"
							*ngIf="contactsSelected.length == 0"
						>
							<i class="far fa-square"></i>
							<span>Selecionar todos</span>
						</div>

						<div
							class="header-icon"
							(click)="deselectAllContacts()"
							*ngIf="contactsSelected.length != 0"
						>
							<i
								class="far fa-minus-square"
								*ngIf="
									contactsSelected.length != contacts.length
								"
							>
							</i>
							<i
								class="fas fa-check-square"
								*ngIf="
									contactsSelected.length == contacts.length
								"
							>
							</i>
						</div>

						<!-- Select -->

						<div
							class="header-icon"
							*ngIf="contactsSelected.length != 0"
						>
							<i class="fas fa-archive"></i>
						</div>

						<!--<div class="header-icon" *ngIf="contactsSelected.length != 0">-->
						<!--<i class="fas fa-file-download"></i>-->
						<!--</div>-->

						<div
							class="header-icon"
							(click)="favoriteManyContact(false)"
							*ngIf="contactsSelected.length != 0"
						>
							<i class="far fa-star"></i>
						</div>

						<div
							class="header-icon"
							(click)="favoriteManyContact(true)"
							*ngIf="contactsSelected.length != 0"
						>
							<i class="fas fa-star"></i>
						</div>

						<div
							class="header-icon"
							(click)="favoriteManyContact(true)"
							*ngIf="contactsSelected.length != 0"
						>
							<i class="far fa-trash-alt"></i>
						</div>

						<div
							class="header-icon"
							(click)="createPartners(true)"
							*ngIf="
								contactsSelected.length != 0 && BUYERS && MASTER
							"
						>
							<i class="far fa-handshake text-main"></i>
						</div>
					</div>
					<div class="td text-right">
						<div
							class="btn btn-primary hide-mobile"
							[routerLink]="['/contacts/add']"
						>
							Novo Contato
						</div>

						<div
							class="btn-circle-action add-btn show-mobile"
							[routerLink]="['/contacts/add']"
						>
							<i class="fas fa-plus"></i>
						</div>
					</div>
				</div>
			</div>

			<div
				class="alert alert-light text-center"
				*ngIf="selectedAllPage && !selectedAllContacts"
			>
				Todos os {{ contactsSelected.length }} os contatos nesta página
				estão selecionadas.
				<span
					class="text-main click"
					(click)="selectedAllContacts = true"
					>Selecionar todas as {{ contacts_count | number }} contatos
					nessa lista.</span
				>
			</div>
			<div
				class="alert alert-light text-center"
				*ngIf="selectedAllPage && selectedAllContacts"
			>
				Todos os {{ contacts_count | number }} contatos dessa lista
				estão selecionadas.
				<span class="text-main click" (click)="deselectAllContacts()"
					>Limpar seleção</span
				>
			</div>

			<div class="alert-dark p-3 font-weight-500 hide-mobile">
				<div class="table">
					<div class="td td-min pr-3">
						<img
							src="assets/images/logo-icon.png"
							alt=""
							width="30"
						/>
					</div>
					<div class="td">
						Todos seus clientes e contatos cadastrados, irão
						aparecer aqui, clique na lupa para filtrar seus
						contatos, e no sinal de
						<span class="text-main font-weight-bold">+</span> para
						cadastrar novos contatos, compradores e inquilinos e
						etc...
					</div>
				</div>
			</div>

			<table
				class="w-100 contacts-list-rows contacts-desktop"
				*ngIf="contacts.length"
				[@enterLeaveLeftIn]="contacts.length"
			>
				<thead>
					<tr class="header-table">
						<td class="align-middle"></td>
						<td class="align-middle"></td>
						<td
							class="td-min align-middle text-center p-1"
							(click)="sort('cod')"
						>
							<div class="text-nowrap">
								Cód.
								<i
									class="fas fa-angle-down"
									*ngIf="filters.controls.sort.value == 'cod'"
								></i>
								<i
									class="fas fa-angle-up"
									*ngIf="
										filters.controls.sort.value == '-cod'
									"
								></i>
							</div>
						</td>
						<td class="align-middle" (click)="sort('name')">
							<div class="text-nowrap">
								Nome
								<i
									class="fas fa-angle-down"
									*ngIf="
										filters.controls.sort.value == 'name'
									"
								></i>
								<i
									class="fas fa-angle-up"
									*ngIf="
										filters.controls.sort.value == '-name'
									"
								></i>
							</div>
						</td>
						<td class="align-middle hide-l1200">
							<div class="text-nowrap">
								Telefone
							</div>
						</td>
						<td>
							<div
								class="text-nowrap"
								(click)="sort('created_at')"
							>
								Criado em
								<i
									class="fas fa-angle-down"
									*ngIf="
										filters.controls.sort.value ==
										'created_at'
									"
								></i>
								<i
									class="fas fa-angle-up"
									*ngIf="
										filters.controls.sort.value ==
										'-created_at'
									"
								></i>
							</div>
						</td>
						<td class="align-middle" (click)="sort('type')">
							<div class="text-nowrap">
								Tipo
								<i
									class="fas fa-angle-down"
									*ngIf="
										filters.controls.sort.value == 'type'
									"
								></i>
								<i
									class="fas fa-angle-up"
									*ngIf="
										filters.controls.sort.value == '-type'
									"
								></i>
							</div>
						</td>
						<td></td>
					</tr>
				</thead>

				<tbody
					*ngFor="let contact of contacts"
					[class.selected]="contact.checked"
					class="contact-row"
				>
					<tr>
						<td class="align-middle td-min p-1">
							<div class="text-nowrap">
								<label class="checkbox-container clean">
									<input
										type="checkbox"
										[(ngModel)]="contact.checked"
										(change)="
											checkContact(
												contact.id,
												contact.checked
											)
										"
									/>
									<div class="checkbox"></div>
									<span class="text"></span>
								</label>
							</div>
						</td>
						<td
							class="align-middle text-center p-1 text-nowrap td-min"
						>
							<span
								class="favorite-container"
								(click)="favoriteContact(contact.id, true)"
								*ngIf="!contact.favorite"
							>
								<i class="far fa-star"></i>
							</span>
							<span
								class="favorite-container"
								(click)="favoriteContact(contact.id, false)"
								*ngIf="contact.favorite"
							>
								<i class="fas fa-star"></i>
							</span>

							<span
								class="archive-container ml-3"
								(click)="archiveContact(contact.id, true)"
								*ngIf="!contact.archive"
							>
								<i class="fas fa-archive"></i>
							</span>
							<span
								class="archive-container active ml-3"
								(click)="archiveContact(contact.id, false)"
								*ngIf="contact.archive"
							>
								<i class="fas fa-archive"></i>
							</span>
						</td>
						<td
							class="td-min cod text-center align-middle"
							[routerLink]="['/contacts/edit', contact.id]"
						>
							<span class="cod font-weight-bold">{{
								contact.cod
							}}</span>
						</td>
						<td class="align-middle">
							<table>
								<td
									class="p-0"
									[routerLink]="[
										'/contacts/edit',
										contact.id
									]"
								>
									<app-initials
										[name]="contact.name"
									></app-initials>
								</td>
								<td class="p-0 pl-2">
									<div
										class="name text-nowrap text-ellipsis"
										[routerLink]="[
											'/contacts/edit',
											contact.id
										]"
									>
										{{ contact.name }}
									</div>
									<div
										class="email text-nowrap text-ellipsis text-muted"
									>
										{{ contact.email }}
									</div>
									<div
										class="show-l1200 text-ellipsis text-muted"
										*ngIf="
											!!contact.phones &&
											contact.phones.length
										"
									>
										{{ contact.phones[0].number | phone }}
									</div>
								</td>
							</table>
						</td>
						<td class="align-middle text-nowrap hide-l1200">
							<span
								*ngIf="contact.phones && contact.phones.length"
							>
								{{ contact.phones[0].number | phone }}
							</span>

							<span
								*ngIf="
									!contact.phones || !contact.phones.length
								"
							>
								--
							</span>
						</td>
						<td class="align-middle">
							<!--<i class="far fa-clock"></i>-->
							{{
								contact.created_at
									| amDateFormat: "D MMM - HH:mm"
							}}
						</td>
						<td
							class="align-middle font-weight-bold"
							[style.color]="colors[contact.type]"
						>
							{{ contact.type_name }}
						</td>
						<td class="align-middle td-min">
							<div class="text-nowrap">
								<div
									class="icon"
									[routerLink]="[
										'/contacts/view',
										contact.id
									]"
								>
									<i class="far fa-address-book"></i>
								</div>
								<div
									class="icon"
									[routerLink]="[
										'/contacts/edit',
										contact.id
									]"
									*ngIf="me.permissions.USERS_MANAGER"
								>
									<i class="fas fa-pencil-alt"></i>
								</div>
								<div
									class="icon"
									(click)="openModalDelete(contact.id)"
									*ngIf="me.permissions.EDIT_CONTACTS"
								>
									<i class="far fa-trash-alt"></i>
								</div>
								<span>
									<app-drop-menu>
										<header>
											<div class="icon">
												<i
													class="fas fa-ellipsis-h"
												></i>
											</div>
										</header>

										<section>
											Teste
										</section>
									</app-drop-menu>
								</span>
							</div>
						</td>
					</tr>
					<tr class="contact-row-info" *ngIf="BUYERS">
						<td></td>
						<td></td>
						<td></td>
						<td class="pt-0">
							<div>
								<b class="text-main"
									><i class="fas fa-map-marker-alt"></i>
									Localização procurada</b
								>
							</div>
							<div *ngIf="!contact.propertie_search.length">
								Não informado
							</div>
							<ul *ngIf="contact.propertie_search.length">
								<li
									*ngFor="
										let perfil of contact.propertie_search
									"
								>
									<span *ngIf="perfil.location">
										<span
											*ngIf="perfil.location.neighborhood"
											>{{
												perfil.location.neighborhood
											}}</span
										>,
										<span *ngIf="perfil.location.city">{{
											perfil.location.city
										}}</span>
										-
										<span *ngIf="perfil.location.estate">{{
											perfil.location.estate
										}}</span>
									</span>
									<span *ngIf="!perfil.location">
										Não informado
									</span>
								</li>
							</ul>

							<div>
								<b class="text-main"
									><i class="fas fa-money-bill-wave"></i>
									Valor Buscado</b
								>
							</div>
							<div>
								{{ contact.propertie_search | buyersPrice }}
							</div>

							<div
								*ngIf="contact.tickets_users"
								class="tickets-users"
							>
								<b>Tickets</b>
								<div
									class="tickets-users"
									*ngIf="contact.tickets_users.length"
								>
									<div
										*ngFor="
											let user of contact.tickets_users
										"
									>
										<app-initials
											[name]="user.name"
											[clean]="true"
											size="xs"
										></app-initials>
										{{ user.name }} -
										<span class="color-orange">{{
											user.realestate.name
										}}</span>
									</div>
								</div>
							</div>
						</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
				</tbody>
			</table>

			<table
				class="w-100 contacts-list-rows contacts-mobile"
				*ngIf="contacts.length"
				[@enterLeaveLeftIn]="contacts.length"
			>
				<tbody
					*ngFor="let contact of contacts"
					[class.selected]="contact.checked"
					class="contact-row"
					(click)="openView(contact)"
					[class.buyers]="BUYERS"
				>
					<tr>
						<td [class.pb-0]="BUYERS">
							<app-initials [name]="contact.name"></app-initials>
						</td>
						<td [class.pb-0]="BUYERS">
							<div class="name text-nowrap text-ellipsis">
								<!--<span class="cod font-weight-bold ">{{contact.cod}}</span>-->
								<span
									class="favorited"
									*ngIf="contact.favorite"
								>
									<i class="fas fa-star"></i>
								</span>
								<span class="favorited" *ngIf="contact.archive">
									<i class="fas fa-archive"></i>
								</span>
								{{ contact.name }}
							</div>

							<div
								class="email text-nowrap text-ellipsis text-muted"
							>
								{{ contact.email || "--" }}
							</div>

							<div
								class="show-l1200 text-ellipsis text-muted"
								*ngIf="
									!!contact.phones && contact.phones.length
								"
							>
								{{ contact.phones[0].number | phone }}
							</div>
						</td>
						<td [class.pb-0]="BUYERS" class="align-top">
							<div
								class="align-middle font-weight-bold type"
								[style.background]="colors[contact.type]"
							>
								{{ contact.type_name }}
							</div>
						</td>
					</tr>

					<tr *ngIf="BUYERS">
						<td></td>
						<td colspan="2" class="td td-overflow-text">
							<div class="property-search">
								<div>
									<b class="text-main"
										><i class="fas fa-map-marker-alt"></i>
										Localização procurada</b
									>
								</div>
								<div *ngIf="!contact.propertie_search.length">
									Não informado
								</div>
								<ul *ngIf="contact.propertie_search.length">
									<li
										*ngFor="
											let perfil of contact.propertie_search
										"
									>
										<span *ngIf="perfil.location">
											<span
												*ngIf="
													perfil.location.neighborhood
												"
												>{{
													perfil.location.neighborhood
												}}</span
											>,
											<span
												*ngIf="perfil.location.city"
												>{{
													perfil.location.city
												}}</span
											>
											-
											<span
												*ngIf="perfil.location.estate"
												>{{
													perfil.location.estate
												}}</span
											>
										</span>
										<span *ngIf="!perfil.location">
											Não informado
										</span>
									</li>
								</ul>

								<div>
									<b class="text-main"
										><i class="fas fa-money-bill-wave"></i>
										Valor Buscado</b
									>
								</div>
								<div>
									{{ contact.propertie_search | buyersPrice }}
								</div>

								<div
									class="tickets-users"
									*ngIf="
										contact.tickets &&
										contact.tickets.length
									"
								>
									<a
										[routerLink]="[
											'/partnership/conversation',
											ticket.id
										]"
										*ngFor="let ticket of contact.tickets"
										class="ticket-single"
									>
										<div class="ticket-name">
											<b>{{ ticket.name }}</b>
										</div>
										<div *ngFor="let user of ticket.users">
											<app-initials
												[name]="user.name"
												[clean]="true"
												size="xs"
											></app-initials>
											{{ user.name }} -
											{{ user.realestate.name }}
										</div>
									</a>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<table
				class="w-100 contacts-list-rows contacts-mobile"
				*ngIf="contacts.length && false"
				[@enterLeaveLeftIn]="contacts.length"
			>
				<tr
					*ngFor="let contact of contacts"
					[class.selected]="contact.checked"
					class="contact-row"
				>
					<td class="align-top td-min td-checked">
						<div class="text-nowrap">
							<label class="checkbox-container clean">
								<input
									type="checkbox"
									[(ngModel)]="contact.checked"
									(change)="
										checkContact(
											contact.id,
											contact.checked
										)
									"
								/>
								<div class="checkbox"></div>
								<span class="text"></span>
							</label>
						</div>
					</td>
					<td
						class="align-top contact-row-content-info td-overflow-text"
					>
						<table class="w-100">
							<td
								class="p-0 td-min"
								[routerLink]="['/contacts/edit', contact.id]"
							>
								<app-initials
									[name]="contact.name"
								></app-initials>
							</td>
							<td class="p-0 pl-2">
								<div
									class="name text-nowrap text-ellipsis"
									[routerLink]="[
										'/contacts/edit',
										contact.id
									]"
								>
									<span class="cod font-weight-bold">{{
										contact.cod
									}}</span>
									{{ contact.name }}
								</div>
								<div
									class="align-middle font-weight-bold"
									[style.color]="colors[contact.type]"
								>
									{{ contact.type_name }}
								</div>
							</td>

							<td class="text-nowrap td-min p-0">
								<span
									class="favorite-container"
									(click)="favoriteContact(contact.id, true)"
									*ngIf="!contact.favorite"
								>
									<i class="far fa-star"></i>
								</span>
								<span
									class="favorite-container"
									(click)="favoriteContact(contact.id, false)"
									*ngIf="contact.favorite"
								>
									<i class="fas fa-star"></i>
								</span>

								<span
									class="archive-container ml-3"
									(click)="archiveContact(contact.id, true)"
									*ngIf="!contact.archive"
								>
									<i class="fas fa-archive"></i>
								</span>
								<span
									class="archive-container active ml-3"
									(click)="archiveContact(contact.id, false)"
									*ngIf="contact.archive"
								>
									<i class="fas fa-archive"></i>
								</span>
							</td>
						</table>

						<span *ngIf="BUYERS">
							<div>
								<b>Localização procurada</b>
							</div>
							<div *ngIf="!contact.propertie_search.length">
								Não informado
							</div>
							<ul *ngIf="contact.propertie_search.length">
								<li
									*ngFor="
										let perfil of contact.propertie_search
									"
								>
									{{ perfil.location.neighborhood }},
									{{ perfil.location.city }} -
									{{ perfil.location.estate }}
								</li>
							</ul>

							<div>
								<b>Valor Buscado</b>
							</div>
							<div>
								{{ contact.propertie_search | buyersPrice }}
							</div>

							<div
								class="tickets-users"
								*ngIf="
									contact.tickets_users &&
									contact.tickets_users.length
								"
							>
								<div *ngFor="let user of contact.tickets_users">
									<app-initials
										[name]="user.name"
										[clean]="true"
										size="xs"
									></app-initials>
									{{ user.name }} -
									<span class="color-orange">{{
										user.realestate.name
									}}</span>
								</div>
							</div>
						</span>

						<div class="text-nowrap actions">
							<a
								class="icon"
								*ngIf="contact.email"
								href="mailto:{{ contact.email }}"
							>
								<i class="far fa-envelope-open"></i>
							</a>

							<a
								class="icon"
								*ngIf="contact.phones.length"
								href="tel:0{{ contact.phones[0].number }}"
							>
								<i class="fas fa-phone"></i>
							</a>

							<a
								class="icon whatsapp"
								*ngIf="
									contact.phones.length &&
									contact.phones[0].number.length >= 11 &&
									!MOBILE
								"
								href="https://api.whatsapp.com/send?phone=55{{
									contact.phones[0].number
								}}"
								target="_system"
							>
								<i class="fab fa-whatsapp"></i>
							</a>
							<a
								class="icon whatsapp"
								*ngIf="
									contact.phones.length &&
									contact.phones[0].number.length >= 11 &&
									MOBILE
								"
								href="whatsapp://send?phone=55{{
									contact.phones[0].number
								}}"
								target="_system"
							>
								<i class="fab fa-whatsapp"></i>
							</a>

							<div
								class="icon"
								[routerLink]="['/contacts/edit', contact.id]"
							>
								<i class="fas fa-pencil-alt"></i>
							</div>

							<div
								class="icon remove"
								(click)="openModalDelete(contact.id)"
							>
								<i class="far fa-trash-alt"></i>
							</div>

							<span>
								<app-drop-menu>
									<header>
										<div class="icon">
											<i class="fas fa-ellipsis-h"></i>
										</div>
									</header>

									<section>
										Teste
									</section>
								</app-drop-menu>
							</span>
						</div>
					</td>
				</tr>
			</table>

			<div class="pt-4">
				<app-pagination
					[total]="contacts_count"
					[perPage]="perPage"
					(changePage)="setPage($event)"
					[(currentPage)]="currentPage"
				>
				</app-pagination>
			</div>

			<div class="not-found text-center" *ngIf="!contacts.length">
				<div class="icon">
					<i class="fas fa-user"></i>
				</div>

				<h3>Nenhum contato encontrado.</h3>
			</div>
		</div>
	</div>
</div>

<app-modal-page-mobile
	[clean]="true"
	name="contact-mininal-view"
	[animation]="'fadein'"
>
	<div class="modal-body text-center contact-fast-view" *ngIf="contactView">
		<div class="btn-close" (click)="closeContactView()">
			<div class="icon-close-only black s25x25"></div>
		</div>

		<span [routerLink]="['/contacts/edit', contactView.id]">
			<app-initials
				[name]="contactView.name"
				[size]="'md'"
			></app-initials>

			<div class="edit" *ngIf="me.permissions.EDIT_CONTACTS">
				Editar Contato
			</div>
		</span>

		<div class="name">
			{{ contactView.name }}
		</div>

		<div class="email" *ngIf="contactView.email">
			{{ contactView.email }}
		</div>

		<div class="phones" *ngIf="contactView.phones.length">
			<div class="phone" *ngFor="let phone of contactView.phones">
				{{ phone.number | phone }}
				<div class="phone-actions">
					<span class="whatsapp" (click)="openWhatsapp(phone.number)">
						<i class="fab fa-whatsapp"></i>
					</span>

					<a href="tel:0{{ phone.number }}">
						<span>
							<i class="fas fa-phone"></i>
						</span>
					</a>
				</div>
			</div>
		</div>

		<div class="property-search" *ngIf="BUYERS">
			<div>
				<b class="text-main"
					><i class="fas fa-map-marker-alt"></i> Localização
					procurada</b
				>
			</div>
			<div *ngIf="!contactView.propertie_search.length">
				Não informado
			</div>
			<ul *ngIf="contactView.propertie_search.length">
				<li *ngFor="let perfil of contactView.propertie_search">
					<span *ngIf="perfil.location">
						<span *ngIf="perfil.location.neighborhood">{{
							perfil.location.neighborhood
						}}</span
						>,
						<span *ngIf="perfil.location.city">{{
							perfil.location.city
						}}</span>
						-
						<span *ngIf="perfil.location.estate">{{
							perfil.location.estate
						}}</span>
					</span>
					<span *ngIf="!perfil.location">Não informado</span>
				</li>
			</ul>

			<div>
				<b class="text-main"
					><i class="fas fa-money-bill-wave"></i> Valor Buscado</b
				>
			</div>
			<div>
				{{ contactView.propertie_search | buyersPrice }}
			</div>

			<div
				class="tickets-users"
				*ngIf="contactView.tickets && contactView.tickets.length"
			>
				<a
					[routerLink]="['/partnership/conversation', ticket.id]"
					*ngFor="let ticket of contactView.tickets"
					class="ticket-single"
				>
					<div class="ticket-name">
						<b>{{ ticket.name }}</b>
					</div>
					<div *ngFor="let user of ticket.users">
						<app-initials
							[name]="user.name"
							[clean]="true"
							size="xs"
						></app-initials>
						{{ user.name }} -
						{{ user.realestate.name }}
					</div>
				</a>
			</div>
		</div>

		<hr />

		<div class="actions">
			<span
				class="favorite-container"
				*ngIf="!contactView.favorite"
				(click)="favoriteContact(contactView.id, true)"
			>
				<i class="far fa-star"></i>
			</span>
			<span
				class="favorited"
				*ngIf="contactView.favorite"
				(click)="favoriteContact(contactView.id, false)"
			>
				<i class="fas fa-star"></i>
			</span>

			<span
				class=""
				*ngIf="!contactView.archive"
				(click)="archiveContact(contactView.id, true)"
			>
				<i class="fas fa-archive"></i>
			</span>
			<span
				class="archived"
				*ngIf="contactView.archive"
				(click)="archiveContact(contactView.id, false)"
			>
				<i class="fas fa-archive"></i>
			</span>

			<span [routerLink]="['/contacts/view', contactView.id]">
				<i class="far fa-address-book"></i>
			</span>
			<span
				[routerLink]="['/contacts/edit', contactView.id]"
				*ngIf="me.permissions.EDIT_CONTACTS"
			>
				<i class="fas fa-pencil-alt"></i>
			</span>
			<span
				(click)="openModalDelete(contactView.id)"
				*ngIf="me.permissions.EDIT_CONTACTS"
			>
				<i class="far fa-trash-alt"></i>
			</span>
		</div>
	</div>
</app-modal-page-mobile>

<!-- Deletar um contato -->
<div
	class="modal-screen"
	#deleteOneUser
	[@ModalAnimation]="modalDeleteContact"
	*ngIf="modalDeleteContact"
>
	<div class="i-close">
		<i class="fa fa-times" aria-hidden="true"></i>
	</div>

	<div class="dialog-content">
		<div class="dialog text-center d-t">
			<div class="icon-circle" (click)="modalDeleteContact = false">
				<i class="far fa-trash-alt"></i>
			</div>

			<div class="text m-t text-white pb-3">
				Tem certeza que seja apagar o contato
				<span class="font-weight-800">{{ contactDelete.name }}</span
				>?

				<br /><br />
				<small class="text-white">
					<b class="text-danger">ATENÇÃO!</b>
					Ao deletar o contato,
					<b>imóveis</b>, <b>tarefas</b>, <b>compromissos</b> e tudo
					associado a este usuário serão desacioados, mas
					<b class="text-danger">NÃO SERÃO DELETADOS</b>.</small
				>
			</div>

			<div class="btn-group m-t ng-scope">
				<div
					class="btn btn-default m-r font-weight-600"
					(click)="modalDeleteContact = false"
				>
					Cancelar
				</div>

				<div
					class="btn btn-danger"
					(click)="deleteContact(contactDelete.id)"
				>
					Sim, deletar contato
				</div>
			</div>
		</div>
	</div>
</div>
<!--// Deletar um contato -->

<!-- Deletar um contato -->
<div
	class="modal-screen"
	#deleteOneUser
	[@ModalAnimation]="modalDeleteContact"
	*ngIf="modalDeleteContact"
>
	<div class="i-close" (click)="modalDeleteContact = false">
		<i class="fa fa-times" aria-hidden="true"></i>
	</div>

	<div class="dialog-content">
		<div class="dialog text-center d-t">
			<div class="icon-circle">
				<i class="far fa-trash-alt"></i>
			</div>

			<div class="text m-t text-white pb-3">
				Tem certeza que seja apagar o contato
				<span class="font-weight-800">{{ contactDelete.name }}</span
				>?

				<br /><br />
				<small class="text-white">
					<b class="text-danger">ATENÇÃO!</b>
					Ao deletar o contato,
					<b>imóveis</b>, <b>tarefas</b>, <b>compromissos</b> e tudo
					associado a este usuário serão desacioados, mas
					<b class="text-danger">NÃO SERÃO DELETADOS</b>.</small
				>
			</div>

			<div class="btn-group m-t ng-scope">
				<div
					class="btn btn-default m-r font-weight-600"
					(click)="modalDeleteContact = false"
				>
					Cancelar
				</div>

				<div
					class="btn btn-danger"
					(click)="deleteContact(contactDelete.id)"
				>
					Sim, deletar contato
				</div>
			</div>
		</div>
	</div>
</div>
<!--// Deletar um contato -->

<ng-template #filtersTemplate>
	<div class="filters-body p-4">
		<form
			[formGroup]="filters"
			class="filters align-top"
			(change)="updateParams()"
		>
			<div class="body">
				<div>
					<div class="card-title filter-title">
						Filtrar contatos

						<div class="reset-filters" (click)="clearParams()">
							Limpar
						</div>
					</div>

					<hr />

					<div class="body p-1">
						<div class="field-container pr-sm-0">
							<input type="text" formControlName="cod" />
							<div class="name">CÓDIGO DO CONTATO</div>
						</div>

						<div class="field-container pr-sm-0">
							<input type="text" formControlName="name" />
							<div class="name">NOME COMPLETO</div>
						</div>

						<div class="field-container pr-sm-0">
							<input type="text" formControlName="email" />
							<div class="name">Email</div>
						</div>

						<div class="field-container pr-sm-0">
							<input
								type="text"
								formControlName="phone"
								appMaskPhone
							/>
							<div class="name">Telefone</div>
						</div>

						<hr />

						<div class="field-container pr-sm-0">
							<app-input-datepicker
								formControlName="date_gte"
								(changeDate)="updateParams()"
							>
							</app-input-datepicker>
							<div class="name">Criado apartir de</div>
						</div>

						<div class="field-container pr-sm-0">
							<app-input-datepicker
								formControlName="date_lte"
								(changeDate)="updateParams()"
							>
							</app-input-datepicker>
							<div class="name">Criado antes de</div>
						</div>

						<hr />

						<label class="select-container">
							<select formControlName="archive">
								<option [value]="''">Todos</option>
								<option [value]="0">Não Arquivados</option>
								<option [value]="1">Arquivados</option>
							</select>
							<div class="name">Arquivados</div>
						</label>

						<div class="filter">
							<div class="filter-title">
								Tipo
							</div>
							<div
								formArrayName="types"
								*ngFor="
									let type of filters.controls.types.controls;
									let i = index
								"
							>
								<label class="checkbox-container clean">
									<input
										type="checkbox"
										[formControlName]="i"
									/>
									<div class="checkbox"></div>
									<span class="text">{{
										types[i].name
									}}</span>
								</label>
							</div>
						</div>

						<div class="filter" formArrayName="users">
							<div class="filter-title">
								Responsável
							</div>
							<div
								*ngFor="
									let user of filters.controls.users.controls;
									let i = index
								"
							>
								<label class="checkbox-container clean">
									<input
										type="checkbox"
										[formControlName]="i"
									/>
									<div class="checkbox"></div>
									<span class="text">
										<app-user-img
											[user]="users_admin[i]"
											[size]="'small'"
										></app-user-img>
										{{ users_admin[i].name }}
									</span>
								</label>
							</div>
						</div>

						<hr />

						<span *ngIf="BUYERS">
							<div class="filter-title">
								Imóvel Buscado
							</div>

							<label class="select-container">
								<select formControlName="type">
									<option [value]="''">Todos</option>
									<option [value]="3">Venda</option>
									<option [value]="8">Locação</option>
								</select>
								<div class="name">Finalidade</div>
							</label>

							<div class="field-container pr-sm-0 price">
								<input
									type="text"
									formControlName="price_lte"
									appMaskNumber
								/>
								<div class="name">Valor Buscado</div>
							</div>

							<div class="select-container">
								<select type="text" formControlName="ps_estate">
									<option [value]="''">
										Todos
									</option>
									<option
										[value]="estate.uf"
										*ngFor="let estate of estates"
									>
										{{ estate.name }}
									</option>
								</select>
								<div class="name">Estado</div>
							</div>

							<div class="field-container pr-sm-0">
								<input type="text" formControlName="ps_city" />
								<div class="name">Cidade</div>
							</div>

							<div class="field-container pr-sm-0">
								<input
									type="text"
									formControlName="ps_neighborhood"
								/>
								<div class="name">Bairro</div>
							</div>
						</span>
					</div>
				</div>
			</div>
		</form>
	</div>

	<div class="filter-actions">
		<!--<div class="cancel-link" [routerModalPageMobile]="'properties-filters'" [close]="true">-->
		<!--Cancelar-->
		<!--</div>-->

		<div
			class="apply-link"
			(click)="applyFilter()"
			[routerModalPageMobile]="'properties-filters'"
			[close]="true"
		>
			Buscar
		</div>
	</div>
</ng-template>
