/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./task-list-add.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../components/field-error-display/field-error-display.component.ngfactory";
import * as i4 from "../../../../components/field-error-display/field-error-display.component";
import * as i5 from "../../../components/input-multi-users/input-multi-users.component.ngfactory";
import * as i6 from "../../../components/input-multi-users/input-multi-users.component";
import * as i7 from "../../../models/users.service";
import * as i8 from "./task-list-add.component";
import * as i9 from "@angular/router";
import * as i10 from "@angular/common";
import * as i11 from "ngx-toastr";
import * as i12 from "../../../models/tasks-list.service";
import * as i13 from "../../../shareds/task-list-shared.service";
var styles_TaskListAddComponent = [i0.styles];
var RenderType_TaskListAddComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TaskListAddComponent, data: {} });
export { RenderType_TaskListAddComponent as RenderType_TaskListAddComponent };
export function View_TaskListAddComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 42, "div", [["class", "modal-screen modal-select-properties change-mobile-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 41, "div", [["class", "modal-body d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "header-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "i-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "icon-close-only black s32x32 display-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "display-back"], ["src", "/assets/svg/home.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Adicionar lista "])), (_l()(), i1.ɵeld(8, 0, null, null, 34, "div", [["class", "search-list-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 33, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("submit" === en)) {
        var pd_2 = (_co.save() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i2.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(11, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(13, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 28, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 12, "div", [["class", "row m-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 11, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 8, "div", [["class", "field-container"]], [[2, "error-input", null]], null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 5, "input", [["formControlName", "name"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 19).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 19)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 19)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(21, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_j]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i1.ɵdid(23, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(24, 0, null, null, 1, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nome da Lista"])), (_l()(), i1.ɵeld(26, 0, null, null, 1, "app-field-error-display", [["errorMsg", "Informe o nome da Lista"]], null, null, null, i3.View_FieldErrorDisplayComponent_0, i3.RenderType_FieldErrorDisplayComponent)), i1.ɵdid(27, 49152, null, 0, i4.FieldErrorDisplayComponent, [], { errorMsg: [0, "errorMsg"], displayError: [1, "displayError"] }, null), (_l()(), i1.ɵeld(28, 0, null, null, 11, "div", [["class", "row m-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 10, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 9, "div", [["class", "field-container pr-sm-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 5, "app-input-multi-users", [["formControlName", "shared_users"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [["document", "keydown.arrowup"], ["document", "keydown.arrowdown"], ["document", "keydown.enter"]], function (_v, en, $event) { var ad = true; if (("document:keydown.arrowup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 32).handleUpEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keydown.arrowdown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 32).handleDownEvent($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:keydown.enter" === en)) {
        var pd_2 = (i1.ɵnov(_v, 32).handleEnterEvent($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_InputMultiUsersComponent_0, i5.RenderType_InputMultiUsersComponent)), i1.ɵdid(32, 638976, null, 0, i6.InputMultiUsersComponent, [i7.UsersService], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.InputMultiUsersComponent]), i1.ɵdid(34, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_j]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i1.ɵdid(36, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(37, 0, null, null, 2, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(38, 0, null, null, 0, "i", [["class", "fas fa-user-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Compartilhar com usu\u00E1rios "])), (_l()(), i1.ɵeld(40, 0, null, null, 2, "div", [["class", "text-center pt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(41, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Criar Lista "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 11, 0, currVal_7); var currVal_16 = "name"; _ck(_v, 21, 0, currVal_16); var currVal_17 = "Informe o nome da Lista"; var currVal_18 = _co.isFieldValid("name"); _ck(_v, 27, 0, currVal_17, currVal_18); _ck(_v, 32, 0); var currVal_26 = "shared_users"; _ck(_v, 34, 0, currVal_26); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 13).ngClassValid; var currVal_5 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 9, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.isFieldValid("name"); _ck(_v, 17, 0, currVal_8); var currVal_9 = i1.ɵnov(_v, 23).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 23).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 23).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 23).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 23).ngClassValid; var currVal_14 = i1.ɵnov(_v, 23).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 23).ngClassPending; _ck(_v, 18, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_19 = i1.ɵnov(_v, 36).ngClassUntouched; var currVal_20 = i1.ɵnov(_v, 36).ngClassTouched; var currVal_21 = i1.ɵnov(_v, 36).ngClassPristine; var currVal_22 = i1.ɵnov(_v, 36).ngClassDirty; var currVal_23 = i1.ɵnov(_v, 36).ngClassValid; var currVal_24 = i1.ɵnov(_v, 36).ngClassInvalid; var currVal_25 = i1.ɵnov(_v, 36).ngClassPending; _ck(_v, 31, 0, currVal_19, currVal_20, currVal_21, currVal_22, currVal_23, currVal_24, currVal_25); }); }
export function View_TaskListAddComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-task-list-add", [], null, null, null, View_TaskListAddComponent_0, RenderType_TaskListAddComponent)), i1.ɵdid(1, 114688, null, 0, i8.TaskListAddComponent, [i9.ActivatedRoute, i2.FormBuilder, i9.Router, i10.Location, i11.ToastrService, i12.TasksListService, i13.TaskListSharedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TaskListAddComponentNgFactory = i1.ɵccf("app-task-list-add", i8.TaskListAddComponent, View_TaskListAddComponent_Host_0, {}, {}, []);
export { TaskListAddComponentNgFactory as TaskListAddComponentNgFactory };
