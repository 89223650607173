import {CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router/src/router_state';
import {AuthService} from './auth.service';
import {promise} from 'selenium-webdriver';
import {map, catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {Global} from './global';
import {Location} from '@angular/common';

@Injectable()
export class CompleteRegisterGuard implements CanActivate {

    constructor(private router: Router,
                private authService: AuthService,
				private location: Location
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const redirectUrl = route['_routerState']['url'];

		console.log(state.url);

        if (Global.user.realestate.type === 0 && state.url !== '/partnership/conversation/complete-register') {
			this.router.navigateByUrl('/complete-register');
            return true;
		}

		return true;
	}
}
