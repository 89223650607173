export class Calendar {

    public weeks_name = [
        'DOM',
        'SEG',
        'TER',
        'QUA',
        'QUI',
        'SEX',
        'SAB'
    ];

    public months_name = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
    ];

    constructor() {

    }

    /**
     * Retorna Mês do calendário, com mês atual, anterior e proximo
     * @param {number} month
     * @param {number} year
     */
    getMonth(month: number, year: number) {
        let currentMonth,
            daysCurrentMonth,
            daysLastMonth;

        let monthView = month + 1;

        currentMonth = new Date(year, month, 1).getDay();
        daysCurrentMonth = new Date(year, month + 1, 0).getDate();

        if (month === 0) {
            daysLastMonth = new Date(year - 1, 11, 0).getDate();
        } else {
            daysLastMonth = new Date(year, month, 0).getDate();
        }

        let days_month = [];

        // Ultimo Mês
        for (let i = daysLastMonth - currentMonth; i < daysLastMonth; i++) {
            let _month = monthView - 1;
            let _year = year;
            if (_month < 1) {
                _month = 12;
                _year = _year - 1;
            }

            days_month.push({
                'day': i + 1,
                'month': _month,
                'today': new Date().getDate() === (i + 1) && new Date().getMonth() + 1 === _month && new Date().getFullYear() === _year,
                'year': _year,
                'date': (i + 1) + '/' + _month + '/' + _year,
                'dateHash':  _year + ('0' + _month).slice(-2) + ('0' + (i + 1)).slice(-2),
                'last_month': true
            });
        }

        // Atual Mês
        for (let i = 0; i < daysCurrentMonth; i++) {

            let _month = monthView;
            let _year = year;

            days_month.push({
                'day': i + 1,
                'month': _month,
                'today': new Date().getDate() === (i + 1) && new Date().getMonth() + 1 === _month && new Date().getFullYear() === _year,
                'year': _year,
                'date': (i + 1) + '/' + _month + '/' + _year,
                'dateHash':  _year + ('0' + _month).slice(-2) + ('0' + (i + 1)).slice(-2),
                'current_month': true
            });
        }

        // Proximo Mês
        for (let a = days_month.length - 1, i = 0; a < 41; i++, a++) {

            let _month = monthView + 1;
            let _year = year;
            if (_month > 12) {
                _month = 1;
                _year = _year + 1;
            }

            days_month.push({
                'day': i + 1,
                'month': _month,
                'today': new Date().getDate() === (i + 1) && new Date().getMonth() + 1 === _month && new Date().getFullYear() === _year,
                'year': _year,
                'date': (i + 1) + '/' + _month + '/' + _year,
                'dateHash':  _year + ('0' + _month).slice(-2) + ('0' + (i + 1)).slice(-2),
                'next_month': true
            });
        }

        // Separar em semanas
        let i, j, temparray, chunk;

        chunk = 7;
        temparray = [];

        for (i = 0, j = days_month.length; i < j; i += chunk) {
            temparray.push(days_month.slice(i, i + chunk));
        }

        return temparray;
    }
}
