import {Component, OnInit, HostListener, Input, ChangeDetectorRef, OnDestroy, Output, EventEmitter} from '@angular/core';
import {Users} from '../../interfaces/users';
import {Global} from '../../../services/global';
import {trigger, transition, style, animate} from '@angular/animations';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {NotificationsService} from '../../models/notifications.service';
import {map, skip, takeUntil} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {GlobalService} from '../../shareds/global.service';
import {WebserverResponseTypes} from '../../interfaces/webserver-response-types';
import {PlaySound} from '../../../services/PlaySound';
import {WebsocketService} from '../../shareds/websocket.service';
import {Subject} from 'rxjs';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	animations: [
		trigger('mobileNavAnimate', [
			transition(':enter', [
				style({
					width: '0px',
					opacity: '0'
				}),
				animate('100ms ease-out', style({
					width: '200px',
					opacity: '1'
				}))
			]),
			transition(':leave', [
				animate('100ms ease-in',
					style({
						width: '0px',
						opacity: '0'
					}))
			])
		]),
		trigger('showOverlay', [
			transition(':enter', [
				style({
					opacity: '0'
				}),
				animate('100ms ease-out', style({
					opacity: '1'
				}))
			]),
			transition(':leave', [
				animate('100ms ease-in', style({opacity: '0'}))
			])
		]),
		trigger('collapseAnimate', [
			transition(':enter', [
				style({
					height: 0,
					opacity: 1
				}),
				animate('100ms 0s linear', style({
					height: '*',
					opacity: 1
				}))
			]),
			transition(':leave', [
				style({
					height: '*',
					opacity: 1
				}),
				animate('100ms 0s linear', style({
					height: 0
				}))
			])
		])
	]
})
export class HeaderComponent implements OnInit, OnDestroy {
	needComplete = Global.user.realestate.type === 0;

	chatPartnersBadged = 0;
	chatPropertiesBadged = 0;
	chatAgendaBadged = 0;
	user: Users;
	menuShow: boolean;
	@Output() menuShowVarChange = new  EventEmitter<boolean>();

	@Input()
	get menuShowVar() {
		return this.menuShow;
	}

	set menuShowVar(val) {
		this.menuShow = val;
		this.menuShowVarChange.emit(this.menuShow);
	}


	subMenuOpen = 'all';
	innerWidth: number;
	userDropState: boolean;
	menuFastMobile = true;

	MASTER = 0;
	APP_MOBILE = false;

	// Destruir todos os subscrive
	private destroy$ = new Subject();

	constructor(private _router: Router,
				private _notificationsService: NotificationsService,
				private _globalService: GlobalService,
				private _changeDetectorRef: ChangeDetectorRef,
				private _authService: AuthService) {
		this.user = Global.user;

		this.MASTER = Global.user.user_master_bdi;
		this.APP_MOBILE = environment.mobile;

		console.log(Global.badges);

		this.chatPartnersBadged = Global.badges.chat_partners;
		this.chatPropertiesBadged = Global.badges.properties;
		this.chatAgendaBadged = Global.badges.agenda;

		this._globalService
			.user
			.pipe(takeUntil(this.destroy$))
			.pipe(skip(1))
			.subscribe((user: any) => {

				if (user === null) {
					return;
				}

				this.user = user;
			});

		this._globalService
			.menuFastMobile
			.pipe(takeUntil(this.destroy$))
			.pipe(skip(1))
			.subscribe((menuFastMobile: any) => {
				this.menuFastMobile = menuFastMobile;
			});

		this._globalService
			.menuMobile
			.pipe(takeUntil(this.destroy$))
			.pipe(skip(1))
			.subscribe((menuShow: any) => {
				this.menuShowVar = menuShow;
			});

		this._globalService
			.badges
			.pipe(takeUntil(this.destroy$))
			.pipe(skip(1))
			.subscribe((badges: any) => {
				this.chatPartnersBadged = Global.badges.chat_partners;
				this.chatPropertiesBadged = Global.badges.properties;
				this.chatAgendaBadged = Global.badges.agenda;
			});
	}

	ngOnInit() {
		this.user = Global.user;
		this.firstCheckWidth();

		this._router.events.subscribe(events => {
			this.userDropState = false;
			this.menuShowVar = false;
		});
	}

	onPan(evt) {
		console.log(`(${evt.center.x}, ${evt.center.y})`);
	}

	openWebsite() {
		window.open(Global.user.realestate.website, '_system');
	}

	changeUserDropState(): void {
		this.userDropState = this.userDropState ? false : true;
	}

	changeMenu() {
		this.menuShowVar = !this.menuShowVar;
		console.log(this.menuShowVar);
	}

	closeMenu() {
		this.menuShowVar = false;
	}

	closeUserMenu() {
		this.userDropState = false;
	}

	openSubmenu(subMenu: string, event: Event) {
		if (this.innerWidth < 1023) {
			event.stopPropagation();
			this.subMenuOpen = this.subMenuOpen === subMenu ? 'none' : subMenu;
		}
	}

	firstCheckWidth() {
		this.innerWidth = window.innerWidth;
		if (window.innerWidth > 1023) {
			this.subMenuOpen = 'all';
		} else {
			this.subMenuOpen = 'none';
		}
	}

	logout() {
		this._authService.logout();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
		if (this.innerWidth > 1023) {
			this.subMenuOpen = 'all';
		} else {
			this.subMenuOpen = 'none';
		}
	}
}
