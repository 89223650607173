<div class="page-screen change-mobile-page" *ngIf="openPage && !clean"
	 [@ModalAnimation]="animationBottom()"
	 [@FadeIn]="animationFadeIn()">

    <div class="modal-body">

        <!--<div class="i-close" (click)="close()">-->
            <!--<div class="icon-close-only black s32x32 display-close"></div>-->
            <!--<img src="assets/svg/home.svg" class="display-back">-->
        <!--</div>-->

        <!--<div class="title">-->
			<!--<div>-->
            	<!--{{title}}-->
			<!--</div>-->
        <!--</div>-->

		<app-nav-bar [title]="title" [backIcon]="true" (backEvent)="close()"></app-nav-bar>

		<ng-container *ngTemplateOutlet="content"></ng-container>

    </div>

</div>


<div class="page-screen change-mobile-page" *ngIf="openPage && clean"
	 [@ModalAnimation]="animationBottom()"
	 [@FadeIn]="animationFadeIn()">
	<div class="overflow" (click)="close()"></div>
	<ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #content>
	<ng-content></ng-content>
</ng-template>
