import { PropertiesDemandService } from '../models/properties-demand.service';
import * as i0 from "@angular/core";
import * as i1 from "../models/properties-demand.service";
var PartnerPropertiesResolversService = /** @class */ (function () {
    function PartnerPropertiesResolversService(propertiesDemand) {
        this.propertiesDemand = propertiesDemand;
    }
    PartnerPropertiesResolversService.prototype.resolve = function (route) {
        var properties_demand_id = route.queryParams.properties_demand_id;
        if (properties_demand_id) {
            return this.propertiesDemand.get(properties_demand_id);
        }
        return false;
    };
    PartnerPropertiesResolversService.ngInjectableDef = i0.defineInjectable({ factory: function PartnerPropertiesResolversService_Factory() { return new PartnerPropertiesResolversService(i0.inject(i1.PropertiesDemandService)); }, token: PartnerPropertiesResolversService, providedIn: "root" });
    return PartnerPropertiesResolversService;
}());
export { PartnerPropertiesResolversService };
