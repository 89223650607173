import {Injectable} from '@angular/core';
import {CrudManager} from '../../services/crud-manager';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TasksService extends CrudManager {
    path = '/admin/tasks';

    constructor() {
        super();
    }

    processFilters(data = {}) {
        let params = {...data};

        if (params['task_list_id']) {
            params['task_list_id'] = Number(params['task_list_id']);
        }

        if (!params['realized']) {
            params['realized'] = 0;
        } else {
            params['realized'] = Number(params['realized']);
        }

        return params;
    }

    sortable(taskId, sortable, realized = 0) {
        return this.http
            .post(environment.API + this.path + '/' + taskId + '/sortable/' + sortable + '/' + realized, {})
            .pipe(map(data => this.responseTreatment.pipe(data)));
    }

    /**
     * Criar Comentário
     * @param {number} taskID
     * @param body
     * @returns {Observable<any>}
     */
    createComment(taskID: number, body: any): Observable<any> {
        return this.http
            .post(environment.API + this.path + '/' + taskID + '/comments', body)
            .pipe(map(data => this.responseTreatment.pipe(data)));
    }

    /**
     * Buscar Comentários
     * @param {number} taskID
     * @param body
     * @returns {Observable<any>}
     */
    searchComment(taskID: number, body: any): Observable<any> {

        let params = {};
        params['long_polling'] = true;
        params['filters'] = body;

        return this.http
            .post(environment.API + this.path + '/' + taskID + '/comments/search', params)
            .pipe(map(data => this.responseTreatment.pipe(data)));
    }

    /**
     * Criar subtarefa
     * @param {number} taskID
     * @param body
     * @returns {Observable<any>}
     */
    createSubTask(taskID: number, body: any): Observable<any> {
        return this.http
            .post(environment.API + this.path + '/' + taskID + '/sub-tasks', body)
            .pipe(map(data => this.responseTreatment.pipe(data)));
    }

    /**
     * Atualizar subtarefa
     * @param {number} taskID
     * @param body
     * @returns {Observable<any>}
     */
    updateSubTask(subTaskID: number, body: any): Observable<any> {
        return this.http
            .post(environment.API + this.path + '/sub-tasks/' + subTaskID, body)
            .pipe(map(data => this.responseTreatment.pipe(data)));
    }

    /**
     * Deletar subtarefa
     * @param {number} taskID
     * @param body
     * @returns {Observable<any>}
     */
    deleteSubTask(subTaskID: number): Observable<any> {
        return this.http
            .delete(environment.API + this.path + '/sub-tasks/' + subTaskID)
            .pipe(map(data => this.responseTreatment.pipe(data)));
    }

}

