import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CompleteHeightService {

	static updateSource = new BehaviorSubject(0);
	static updateEvent = CompleteHeightService.updateSource.asObservable();

	static update() {
		CompleteHeightService.updateSource.next(Math.random());
	}

}
