var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { LocationCitiesService } from 'src/app/content/models/location-cities.service';
var EquivalenceCityResolverService = /** @class */ (function () {
    function EquivalenceCityResolverService(_citiesService) {
        this._citiesService = _citiesService;
    }
    EquivalenceCityResolverService.prototype.resolve = function (route) {
        var params = __assign({}, route.queryParams);
        return this._citiesService.search(params);
    };
    return EquivalenceCityResolverService;
}());
export { EquivalenceCityResolverService };
