import {Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {GlobalService} from '../../shareds/global.service';
import {Global} from '../../../services/global';
import {Helpers} from '../../../services/helpers';
import {CordovaService} from '../../../services/cordova.service';

@Component({
	selector: 'app-nav-bar',
	templateUrl: './nav-bar.component.html',
	styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

	@Input() title;
	@Input() actions: any [] = [];
	@Input() tabs: any [] = [];
	@Input() backIcon = false;

	@Output() backEvent = new EventEmitter();
	@Output() tabEvent = new EventEmitter();
	@Output() actionEvent = new EventEmitter();

	@ViewChild('tabsElement') elTabsContainer: ElementRef;
	@ViewChildren('tabElement') elTabs: QueryList<ElementRef>;

	// MOBILE = true;

	constructor(
		private _globalService: GlobalService,
	) {
		// this.MOBILE = environment.mobile;
	}

	ngOnInit() {
	}


	setTab(index: number) {
		CordovaService.touchSound();

		this.tabs.forEach((tab) => {
			tab.active = false;
		});

		// let visible = (this.elTabsContainer.nativeElement.scrollLeft + this.elTabsContainer.nativeElement.clientWidth) >
		// 	this.elTabs.toArray()[index].nativeElement.offsetLeft + this.elTabs.toArray()[index].nativeElement.clientWidth
		// 	&& this.elTabs.toArray()[index].nativeElement.offsetLeft >= this.elTabsContainer.nativeElement.scrollLeft;

		// if (!visible) {
			let currentScroll = this.elTabs.toArray()[index].nativeElement.offsetLeft
				+ this.elTabs.toArray()[index].nativeElement.clientWidth / 2
				- this.elTabsContainer.nativeElement.clientWidth / 2;

			Helpers.scrollToLeft(this.elTabsContainer.nativeElement, currentScroll, 300);
		// }

		this.tabs[index].active = true;
		// this.elTabs[index].offsetLeft
		this.tabEvent.emit(this.tabs[index]);
	}

	pressAction(index: number) {
		this.actionEvent.emit(this.actions[index]);
	}

	back() {
		CordovaService.touchSound();
		Global.back = true;
		console.log(Global.back);
		this.backEvent.emit();
	}

	openMenu() {
		CordovaService.touchSound();
		this._globalService.updateMenuMobile(true);
	}

}
