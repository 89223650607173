import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Users} from '../content/interfaces/users';
import {Global} from './global';
import {CookieService} from 'ngx-cookie-service';
import {map, catchError} from 'rxjs/operators';
import {ResponseTreatment} from './response-treatment';
import {WebSocket} from './WebSocket';
import {MessagingService} from './messaging.service';
import {GlobalService} from '../content/shareds/global.service';
import {IndexedDB} from './IndexedDB';

@Injectable({
	providedIn: 'root'
})
export class RegisterService {

	responseTreatment = new ResponseTreatment;
	path = '/admin/accounts-free';

	constructor(
		private http: HttpClient,
	) {

	}

	register(body: any): Observable<any> {
		return this.http
			.post(environment.API + this.path, body)
			.pipe(map(data => this.responseTreatment.pipe(data)))
			.pipe(catchError(this.responseTreatment.handleError));
	}

}
