import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {TasksService} from '../models/tasks.service';

@Injectable()
export class TasksEditResolver implements Resolve<any> {

    constructor(
        private taskService: TasksService
    ) {

    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.taskService.get(route.params.task_id);
    }
}
