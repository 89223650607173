import { LocationSubneighborhoodsService } from './../models/location-subneighborhoods.service';
import { LocationNeighborhoodsService } from './../models/location-neighborhoods.service';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {EquivalenceNotFoundService} from '../models/equivalence-not-found.service';

@Injectable()
export class EquivalenceSubNeighborhoodResolverService implements Resolve<any> {

	constructor(
		private _neighService: LocationSubneighborhoodsService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {
		let params = {...route.queryParams};
		return this._neighService.search(params);
	}
}
