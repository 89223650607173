var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { ResponseTreatment } from './response-treatment';
import { ActivatedRoute } from '@angular/router';
import { InjectorInstance } from './InjectorInstance';
var CrudManager = /** @class */ (function () {
    function CrudManager() {
        this.responseTreatment = new ResponseTreatment();
        this.http = InjectorInstance.Injector.get(HttpClient);
        this.route = InjectorInstance.Injector.get(ActivatedRoute);
    }
    /**
     * Resgatar recurso
     * @param {number} id
     * @param params
     * @returns {Observable<any>}
     */
    CrudManager.prototype.get = function (id, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.http
            .get(environment.API + this.path + '/' + id, {
            params: params
        })
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }))
            .pipe(catchError(this.responseTreatment.handleError));
    };
    /**
     * Resgatar recurso
     * @param {number} id
     * @returns {Observable<any>}
     */
    CrudManager.prototype.getAny = function (id) {
        var _this = this;
        return this.http
            .get(environment.API + this.path + '/' + id)
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }))
            .pipe(catchError(this.responseTreatment.handleError));
    };
    /**
     * Criar recurso
     * @param body
     * @returns {Observable<any>}
     */
    CrudManager.prototype.create = function (body) {
        var _this = this;
        return this.http
            .post(environment.API + this.path, body)
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }))
            .pipe(catchError(this.responseTreatment.handleError));
    };
    /**
     * Atualizar recurso
     * @param {number} id
     * @param body
     * @returns {Observable<any>}
     */
    CrudManager.prototype.update = function (id, body) {
        var _this = this;
        return this.http
            .post(environment.API + this.path + '/' + id, body)
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }))
            .pipe(catchError(this.responseTreatment.handleError));
    };
    /**
     * Resgatar recurso
     * @param {number} id
     * @returns {Observable<any>}
     */
    CrudManager.prototype.updateAny = function (id, body) {
        var _this = this;
        return this.http
            .post(environment.API + this.path + '/' + id, body)
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }))
            .pipe(catchError(this.responseTreatment.handleError));
    };
    /**
     * Deletar recurso
     * @param {number} id
     * @param body
     * @returns {Observable<any>}
     */
    CrudManager.prototype.delete = function (id) {
        var _this = this;
        return this.http
            .delete(environment.API + this.path + '/' + id)
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }))
            .pipe(catchError(this.responseTreatment.handleError));
    };
    /**
     * Buscar recursos
     * @param _body
     * @returns {Observable<any>}
     */
    CrudManager.prototype.search = function (_body) {
        var _this = this;
        var body = __assign({}, _body);
        var params = {};
        if (body.root) {
            for (var i in body.root) {
                params[i] = body.root[i];
            }
            body.root = undefined;
        }
        params['query'] = {};
        params['filters'] = body;
        if (body.sort) {
            params['query']['sort'] = body.sort;
            params['filters']['sort'] = undefined;
        }
        if (body.limit) {
            params['query']['limit'] = body.limit;
            params['filters']['limit'] = undefined;
        }
        if (body.offset) {
            params['query']['offset'] = body.offset;
            params['filters']['offset'] = undefined;
        }
        return this.http
            .post(environment.API + this.path + '/search', params)
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }))
            .pipe(catchError(this.responseTreatment.handleError));
    };
    return CrudManager;
}());
export { CrudManager };
