<div class="pagination" *ngIf="total_pages > 1">
    <div
        (click)="incrementPage(currentPage - 1)"
        [class.hidden]="!last"
        class="page">
        <
    </div>
    <div *ngFor="let page of pages"
         class="page"
         (click)="setPage(page)"
         [class.disable]="page.disable"
         [class.active]="page.active">
        {{page.number}}
    </div>
    <div
        (click)="incrementPage(currentPage + 1)"
        [class.hidden]="!next"
        class="page">
        >
    </div>
</div>
