<div class="modal-screen">
    <div class="modal-body">
        <div class="header">
            <img src="/assets/images/logo-md.png" alt="">
        </div>

        <span *ngIf="false">
            <div class="content text-center">
                <h2>Olá, {{ user.name }}!</h2>
                <br>
                <p>Bem-vindo à profissionalização da parceria imobiliária, à partir de sua filiação, nenhum negócio será perdido.</p>
                <p>Vamos apenas configurar seus ambiente de trabalho antes de começar.</p>
            </div>
        </span>

        <div class="content" *ngIf="true">
            <form [formGroup]="form">
                <div class="title-form">
                    Informações da imobiliária
                    <small>Informações referentes a sua imobiliária.</small>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <div class="field-container" [class.error-input]="isFieldValid('name')">
                            <div>
                                <input type="text" formControlName="name">
                            </div>
                            <div class="name">NOME DA imobiliária</div>
                        </div>

                        <app-field-error-display [displayError]="isFieldValid('name')" errorMsg="Este campo é obrigatório!">
                        </app-field-error-display>
                    </div>

                    <div class="col-md-4">
                        <div class="field-container" [class.error-input]="isFieldValid('email')">
                            <div>
                                <input type="text" formControlName="email">
                            </div>
                            <div class="name">EMAIL DE CONTATO</div>
                        </div>

                        <app-field-error-display [displayError]="isFieldValid('email')" errorMsg="Este campo é obrigatório!">
                        </app-field-error-display>
                    </div>
                </div>

                <hr>

                <!-- Localização -->
                <div class="title-form">
                    Localização
                    <small>Localização referentes a sua imobiliária.</small>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <div class="field-container">
                            <input type="text" formControlName="cep">
                            <div class="name">CEP</div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="field-container">
                            <input type="text" formControlName="address">
                            <div class="name">Endereço</div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="field-container">
                            <input type="text" formControlName="number">
                            <div class="name">Número</div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <div class="select-container">
                            <select type="text" formControlName="estate">
                                <option [value]="estate.UF" *ngFor="let estate of estates">{{estate.name}}</option>
                            </select>
                            <div class="name">Estado</div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="field-container">
                            <input type="text" formControlName="city">
                            <div class="name">Cidade</div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="field-container">
                            <input type="text" formControlName="neighborhood">
                            <div class="name">Bairro</div>
                        </div>
                    </div>
                </div>
                <!--// Localização -->

            </form>
        </div>

        <div class="content" *ngIf="false">
            <div class="row">
                <div class="col-md-12">
                    <div class="title-form">
                        Logo da imobiliaria
                        <small>Logo da sua imobiliaria que irá ficar no seu site.</small>
                    </div>

                    <div class="text-center">

                        <div class="img-border logo-realestate position-relative">

                            <img [src]="realestate.logo">

                            <div class="loading" *ngIf="loadingLogo">
                                <div class="loading-icon">
                                    <div class="load">
                                        <div></div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <small>Tamanho máximo da imagem é 360x250, caso seja enviado uma imagem maior ela será redimensionada.</small>
                        <br>

                        <div class="btn btn-round btn-primary position-relative" ngFileDrop [options]="optionsUpload" (uploadOutput)="onUploadOutput($event, 'realestate_logo')" [uploadInput]="uploadInput">
                            <input type="file" ngFileSelect [options]="optionsUpload" (uploadOutput)="onUploadOutput($event, 'realestate_logo')" [uploadInput]="uploadInput">
                            <i class="fas fa-camera"></i> Mudar
                        </div>

                    </div>

                    <hr><br>

                    <div class="title-form">
                        Imagem de fundo
                        <small>Imagem de fundo que irá ficar no seu site.</small>
                    </div>

                    <div class="text-center">

                        <div class="img-border background-image-realestate position-relative">
                            <img [src]="realestate.background">

                            <div class="loading">
                                <div class="loading-icon" *ngIf="loadingBackground">
                                    <div class="load">
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <small>Tamanho máximo da imagem é 1920x1080, caso seja enviado uma imagem maior ela será redimensionada.</small>
                        <br>
                        <div class="btn btn-round btn-primary position-relative" ngFileDrop [options]="optionsUpload" (uploadOutput)="onUploadOutput($event, 'realestate_background')"
                             [uploadInput]="uploadInput">
                            <input type="file" ngFileSelect [options]="optionsUpload" (uploadOutput)="onUploadOutput($event, 'realestate_background')"
                                   [uploadInput]="uploadInput">
                            <i class="fas fa-camera"></i> Mudar
                        </div>
                    </div>

                    <hr><br>

                    <div class="title-form">
                        Informações e configurações do modelo
                        <small>Imagem de fundo que irá ficar no seu site.</small>
                    </div>


                    <form [formGroup]="form">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="field-container">
                                    <input type="text" formControlName="COLOR_PRIMARY" [(colorPicker)]="form.get('COLOR_PRIMARY').value" [value]="form.get('COLOR_PRIMARY').value">
                                    <div class="name">Cor Primária</div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="field-container">
                                    <input type="text" formControlName="COLOR_SECUNDARY" [(colorPicker)]="form.get('COLOR_SECUNDARY').value" [value]="form.get('COLOR_SECUNDARY').value">
                                    <div class="name">Cor Secundária</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-8">
                                <div class="field-container">
                                    <input type="text" formControlName="SLOGAN">
                                    <div class="name">Frase</div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="actions">
            <div class="btn previous">
                Voltar
            </div>
            <div class="btn next">
                Avançar
            </div>
        </div>

    </div>
</div>
