import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {ResponseTreatment} from './response-treatment';
import {HttpHandler} from '@angular/common/http/src/backend';
import {HttpRequest} from '@angular/common/http/src/request';
import {ActivatedRoute, Router} from '@angular/router';
import {InjectorInstance} from './InjectorInstance';

export class CrudManager {

	path: string;
	responseTreatment = new ResponseTreatment();
	http = InjectorInstance.Injector.get<HttpClient>(HttpClient);
	route = InjectorInstance.Injector.get<ActivatedRoute>(ActivatedRoute);

	/**
	 * Resgatar recurso
	 * @param {number} id
	 * @param params
	 * @returns {Observable<any>}
	 */
	get(id: number, params: any = {}): Observable<any> {
		return this.http
			.get(environment.API + this.path + '/' + id, {
				params: params
			})
			.pipe(map(data => this.responseTreatment.pipe(data)))
			.pipe(catchError(this.responseTreatment.handleError));
	}

	/**
     * Resgatar recurso
     * @param {number} id
     * @returns {Observable<any>}
     */
	getAny(id: any): Observable<any> {
		return this.http
			.get(environment.API + this.path + '/' + id)
			.pipe(map(data => this.responseTreatment.pipe(data)))
			.pipe(catchError(this.responseTreatment.handleError));
	}

	/**
     * Criar recurso
     * @param body
     * @returns {Observable<any>}
     */
	create(body: any): Observable<any> {
		return this.http
			.post(environment.API + this.path, body)
			.pipe(map(data => this.responseTreatment.pipe(data)))
			.pipe(catchError(this.responseTreatment.handleError));
	}

	/**
     * Atualizar recurso
     * @param {number} id
     * @param body
     * @returns {Observable<any>}
     */
	update(id: number, body: any): Observable<any> {
		return this.http
			.post(environment.API + this.path + '/' + id, body)
			.pipe(map(data => this.responseTreatment.pipe(data)))
			.pipe(catchError(this.responseTreatment.handleError));
	}

	/**
     * Resgatar recurso
     * @param {number} id
     * @returns {Observable<any>}
     */
	updateAny(id: any, body: any): Observable<any> {
		return this.http
			.post(environment.API + this.path + '/' + id, body)
			.pipe(map(data => this.responseTreatment.pipe(data)))
			.pipe(catchError(this.responseTreatment.handleError));
	}

	/**
     * Deletar recurso
     * @param {number} id
     * @param body
     * @returns {Observable<any>}
     */
	delete(id: number): Observable<any> {
		return this.http
			.delete(environment.API + this.path + '/' + id)
			.pipe(map(data => this.responseTreatment.pipe(data)))
			.pipe(catchError(this.responseTreatment.handleError));
	}

	/**
     * Buscar recursos
     * @param _body
     * @returns {Observable<any>}
     */
	search(_body: any): Observable<any> {
		let body = {..._body};

		let params = {};

		if (body.root) {
			for (let i in body.root) {
				params[i] = body.root[i];
			}
			body.root = undefined;
		}

		params['query'] = {};
		params['filters'] = body;

		if (body.sort) {
			params['query']['sort'] = body.sort;
			params['filters']['sort'] = undefined;
		}

		if (body.limit) {
			params['query']['limit'] = body.limit;
			params['filters']['limit'] = undefined;
		}

		if (body.offset) {
			params['query']['offset'] = body.offset;
			params['filters']['offset'] = undefined;
		}

		return this.http
			.post(environment.API + this.path + '/search', params)
			.pipe(map(data => this.responseTreatment.pipe(data)))
			.pipe(catchError(this.responseTreatment.handleError));
	}
}
