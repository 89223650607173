import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'phone'
})
export class PhonePipe implements PipeTransform {

	transform(value: string, args?: any): any {

		if (value.length === 10) {
			value = ('' + value).replace(/\D/g, '');
			let match = value.match(/^(\d{2})(\d{4})(\d{4})$/);

			if (match) {
				return '(' + match[1] + ') ' + match[2] + '-' + match[3];
			}
		} else if (value.length === 11) {
			value = ('' + value).replace(/\D/g, '');
			let match = value.match(/^(\d{2})(\d{5})(\d{4})$/);

			if (match) {
				return '(' + match[1] + ') ' + match[2] + '-' + match[3];
			}
		}

		return value;
	}

}
