<div class="app-autocomplete field-container" (clickOutside)="setFocus(false)" [class.error-input]="error">
    <input type="text"
           autocomplete="asdasd"
           autocorrect="off"
           autocapitalize="off"
           spellcheck="false"
           (focus)="setFocus(true)"
           [(ngModel)]="value"
           (input)="changeLocationInput($event)">

    <div class="app-users--loading-icon" *ngIf="loading">
        <i class="fas fa-spinner"></i>
    </div>

    <ul class="autocomplete-list" *ngIf="showAutocomplete">
        <li [class.active]="selectNumber == index"
            (click)="selectLocation(location)"
            *ngFor="let location of locations; let index = index">
            <div class="table">
                <div class="td td-overflow-text text-muted" *ngIf="city">
                    <span class="location"><i class="fas fa-map-marker-alt"></i> {{location.city}}</span>
                </div>
                <div class="td td-overflow-text text-muted" *ngIf="neighborhood">
                    <span class="location"><i class="fas fa-map-marker-alt"></i> {{location.neighborhood}}</span>
                </div>
            </div>
        </li>
    </ul>
</div>
