import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Global} from '../../../services/global';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ModalContactDuplicateService} from '../../shareds/modal-contact-duplicate.service';
import {PlaySound} from '../../../services/PlaySound';
import {ModalAnimation} from '../../animations/animations';
import {Helpers} from '../../../services/helpers';

@Component({
    selector: 'app-contacts-search',
    templateUrl: './contacts-search.component.html',
    styleUrls: ['./contacts-search.component.scss'],
    animations: [ ModalAnimation ]
})
export class ContactsSearchComponent implements OnInit {

    types_properties: any;
    estates: any;
    openModal = false;

    // Filtros
    @Output() changeFilters = new EventEmitter();
    @Input() filtersSearch: any;
    @Input() id: string;
    filters: FormGroup;

    constructor(
        private fb: FormBuilder,
        private modalContactDuplicateService: ModalContactDuplicateService
    ) {
        this.types_properties = Global.info.properties.types;
        this.estates = Global.info.estates;

        const types = this.types_properties.map(c => {
            return new FormControl(false);
        });

        this.filters = this.fb.group({
            cod: ['', []],
            name: ['', []],
            type: ['', []],
            ps_types: new FormArray(types),
            price_lte: ['', []],
            ps_estate: ['', []],
            ps_city: ['', []],
            ps_neighborhood: ['', []],
        });

    }

    clean() { }

    updateChange() {
        let value: any = this.filters.value;

        value.ps_types = value.ps_types
            .map((v, i) => v ? this.types_properties[i].id : null)
            .filter(v => v !== null);

        value = Helpers.filterObject(value);

        this.changeFilters.emit(value);
        this.close();
    }

    ngOnInit() {
        this.modalContactDuplicateService.add(this);
    }

    open() {
        this.openModal = true;
    }

    close() {
        this.openModal = false;
    }

}
