import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {EquivalenceNotFoundService} from '../models/equivalence-not-found.service';

@Injectable()
export class EquivalenceNotFoundResolverService implements Resolve<any> {

	constructor(
		private equivalenceNotFoundService: EquivalenceNotFoundService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {
		let params = {...route.queryParams};
		return this.equivalenceNotFoundService.search(params);
	}
}
