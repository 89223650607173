var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { EventEmitter, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PlaySound } from "../../../../../services/PlaySound";
import { ToastrService } from "ngx-toastr";
import { ContactsInteractionsService } from "../../../../models/contacts-interactions.service";
import { TitleService } from "src/app/services/title.service";
import { Subject } from "rxjs";
import { ContactInteractionsSharedService } from "src/app/content/shareds/contact-interactions-shared.service";
import { ModalContactDuplicateService } from "../../../../shareds/modal-contact-duplicate.service";
var ContactsViewAddInteractionsComponent = /** @class */ (function () {
    function ContactsViewAddInteractionsComponent(route, _fb, router, modalContactDuplicateService, toastr, contactsInteractionsService, _titleService, _contactsSharedService) {
        this.route = route;
        this._fb = _fb;
        this.router = router;
        this.modalContactDuplicateService = modalContactDuplicateService;
        this.toastr = toastr;
        this.contactsInteractionsService = contactsInteractionsService;
        this._titleService = _titleService;
        this._contactsSharedService = _contactsSharedService;
        this.saved = new EventEmitter();
        this.formSubmitAttempt = false;
        this.contactID = 0;
        this.openModal = false;
        this.currentTab = "properties_visit";
        this.visitType = "";
        this.typeCommitment = "";
        this.properties_list = [];
        this._destroyed$ = new Subject();
    }
    ContactsViewAddInteractionsComponent.prototype.ngOnInit = function () {
        this.contactID = this.route.parent.snapshot.params.id;
        this.contactsInteractionsService.setContact(this.contactID);
        var contactName = this.route.parent.snapshot.data.contact.name;
        this._titleService.setTitle(contactName + " - Criar Intera\u00E7\u00E3o");
        this.selectForm();
    };
    ContactsViewAddInteractionsComponent.prototype.save = function () {
        var _this = this;
        this.formSubmitAttempt = false;
        /**
         * Formulário com erros
         */
        if (!this.form.valid) {
            this.formSubmitAttempt = true;
            PlaySound.play("error");
            this.toastr.error("Preencha os campos corretamente.");
            return false;
        }
        var data = __assign({ type: this.currentTab }, this.form.value);
        switch (this.currentTab) {
            case "properties_visit":
                data["propertie_opinion"] = this.visitType;
                data["propertie_id"] = 1; // REVIEW: Retirar property_id
                break;
            case "commitment":
                data = {};
                data["commitment"] = __assign({}, this.form.value);
                data["type"] = this.currentTab;
                data["commitment"]["all_day"] = data["commitment"]["all_day"]
                    ? 1
                    : 0;
                data["commitment"]["realized"] = data["commitment"]["realized"]
                    ? 1
                    : 0;
                break;
            case "task":
                data = {};
                data["task"] = __assign({}, this.form.value);
                data["type"] = this.currentTab;
                data["task"]["all_day"] = data["task"]["all_day"] ? 1 : 0;
                data["task"]["realized"] = data["task"]["realized"] ? 1 : 0;
                break;
            case "contact_nota":
                break;
        }
        return this.contactsInteractionsService.create(data).subscribe(function (res) {
            _this.toastr.success("Interação adicionada com sucesso!");
            _this.close();
            _this._contactsSharedService.refresh();
        }, function (err) {
            _this.toastr.error("Erro ao criar interação.");
        });
    };
    ContactsViewAddInteractionsComponent.prototype.openModalProperty = function () {
        this.modalContactDuplicateService.open("modal-select-properties");
    };
    /**
     * Verifica se campo é valido
     * @param {string} field
     * @returns {boolean}
     */
    ContactsViewAddInteractionsComponent.prototype.isFieldValid = function (field, parent) {
        if (!!parent) {
            return (!this.form.get(parent).get(field).valid &&
                this.formSubmitAttempt);
        }
        else {
            return !this.form.get(field).valid && this.formSubmitAttempt;
        }
    };
    ContactsViewAddInteractionsComponent.prototype.buildFormNote = function () {
        this.form = this._fb.group({
            message: ["", Validators.required],
            type: [this.currentTab, Validators.required]
        });
    };
    ContactsViewAddInteractionsComponent.prototype.buildFormVisit = function () {
        this.form = this._fb.group({
            message: [""],
            type: [this.currentTab, Validators.required],
            propertie_opinion: [""],
            property: []
        });
    };
    ContactsViewAddInteractionsComponent.prototype.builFormCommitment = function () {
        this.form = this._fb.group({
            name: ["", Validators.required],
            start_at: ["", Validators.required],
            description: [""],
            realized: [0],
            all_day: [0],
            shared_users: [""],
            type: [""]
        });
    };
    ContactsViewAddInteractionsComponent.prototype.buildFormTask = function () {
        this.form = this._fb.group({
            name: ["", Validators.required],
            all_day: [false],
            deadline_at: [""],
            comments: [""],
            realized: [0],
            task_list_id: [0]
        });
    };
    ContactsViewAddInteractionsComponent.prototype.allDay = function (event) {
        this.form.get("all_day").setValue(event);
    };
    ContactsViewAddInteractionsComponent.prototype.setVisitType = function (type) {
        this.visitType = type;
        this.form.patchValue({ propertie_opinion: type });
    };
    ContactsViewAddInteractionsComponent.prototype.setCommitType = function (type) {
        this.typeCommitment = type;
        this.form.patchValue({ type: type });
    };
    ContactsViewAddInteractionsComponent.prototype.close = function () {
        if (this.router.url.indexOf("sales-funnel") !== -1) {
            this.router.navigateByUrl("/sales-funnel/list/contacts/view/" + this.contactID);
        }
        else {
            this.router.navigateByUrl("/contacts/view/" + this.contactID);
        }
    };
    ContactsViewAddInteractionsComponent.prototype.checkTab = function (tab) {
        return tab === this.currentTab;
    };
    ContactsViewAddInteractionsComponent.prototype.changeTab = function (tab) {
        this.currentTab = tab;
        this.formSubmitAttempt = false;
        this.visitType = "";
        this.typeCommitment = "";
        this.selectForm();
    };
    ContactsViewAddInteractionsComponent.prototype.selectForm = function () {
        delete this.form;
        switch (this.currentTab) {
            case "contact_nota":
                this.buildFormNote();
                break;
            case "properties_visit":
                this.buildFormVisit();
                break;
            case "commitment":
                this.builFormCommitment();
                break;
            case "task":
                this.buildFormTask();
                break;
        }
    };
    /**
     * Adiciona a propriedade
     *
     * @param {Properties} property
     * @memberof ContactsViewAddInteractionsComponent
     */
    ContactsViewAddInteractionsComponent.prototype.addProperty = function (property) {
        // this.properties_list.push(property);
        // this.form.get('properties').setValue(this.properties_list);
        this.form.get("property").setValue(property);
        console.log(this.form.get("property").value);
    };
    /**
     * Deleta as propriedades
     *
     * @param {*} id
     * @memberof ContactsViewAddInteractionsComponent
     */
    ContactsViewAddInteractionsComponent.prototype.deleteProperty = function () {
        // for (let i = 0; i < this.properties_list.length; i++) {
        // 	const element = this.properties_list[i];
        // 	if (element.id === id) {
        // 		this.properties_list.splice(i, 1);
        // 	}
        // }
        this.form.get("properties").setValue({});
    };
    ContactsViewAddInteractionsComponent.prototype.ngOnDestroy = function () {
        this._destroyed$.next();
        this._destroyed$.complete();
    };
    return ContactsViewAddInteractionsComponent;
}());
export { ContactsViewAddInteractionsComponent };
