<div class="page-flex no-over">

	<div class="flex-header">
		<app-nav-bar title="Lista de Bairros Erro">
		</app-nav-bar>
	</div>

	<div class="loading" *ngIf="searching">
		<div class="loading-icon">
			<div class="load">
				<div></div>
			</div>
		</div>
	</div>

	<div class="flex-body" #topProperties>
    	<div class="equivalence-list">

        <div class="max-width">

            <div class="header-search">

                <h1 class="title">
                    <div class="row">
                        <div class="col">
                            <i class="fas fa-key"></i> &nbsp; Bairros Erro
                        </div>
                    </div>
                </h1>

                <div class="tabs">
                    <div class="tab active">
                        Todos ({{locations_count}})
                    </div>
                </div>

                <div class="row m-0 pt-3">
                    <div class="col m-0 p-0">
                        <form [formGroup]="filters" (change)="updateParams()" class="d-inline-block">
                            <input type="text" formControlName="textual_search" class="input" placeholder="Buscar localização...">
                        </form>
                    </div>
                    <div class="col text-right m-0 p-0">
                        <form [formGroup]="filters" (change)="updateParams()" class="d-inline-block">
                            <label class="select-container no-name">
                                <select formControlName="sort">
                                    <option [value]="''">Ordernar por</option>
                                    <option [value]="'neighborhood_error'">Alfabética - Bairro Erro</option>
                                    <option [value]="'neighborhood'">Alfabética - Bairro</option>
                                    <option [value]="'city'">Alfabética - Cidade</option>
                                    <option [value]="'estate'">Alfabética - Estado</option>
                                </select>
                            </label>
                        </form>
                    </div>
                </div>
            </div>

            <div class="row">

            </div>

            <div class="not-found text-center" *ngIf="!locations.length">

                <div class="icon">
                    <i class="fas fa-key"></i>
                </div>

                <h3>Nenhuma localização encontrada.</h3>

            </div>

            <table class="w-100 list-table hide-mobile" *ngIf="locations.length">
                <tr class="header-table">
                    <td class="align-middle" (click)="sort('neighborhood_error')">
                        <div class="text-nowrap font-weight-bold">
                            Bairro Erro
                            <i class="fas fa-angle-down" *ngIf="filters.controls.sort.value == 'neighborhood_error'"></i>
                            <i class="fas fa-angle-up" *ngIf="filters.controls.sort.value == '-neighborhood_error'"></i>
                        </div>
                    </td>
                    <td class="align-middle" (click)="sort('neighborhood')">
                        <div class="text-nowrap font-weight-bold">
                            Bairro
                            <i class="fas fa-angle-down" *ngIf="filters.controls.sort.value == 'neighborhood'"></i>
                            <i class="fas fa-angle-up" *ngIf="filters.controls.sort.value == '-neighborhood'"></i>
                        </div>
                    </td>
                    <td class="align-middle" (click)="sort('city')">
                        <div class="text-nowrap font-weight-bold">
                            Cidade
                            <i class="fas fa-angle-down" *ngIf="filters.controls.sort.value == 'city'"></i>
                            <i class="fas fa-angle-up" *ngIf="filters.controls.sort.value == '-city'"></i>
                        </div>
                    </td>
                    <td class="align-middle" (click)="sort('estate')">
                        <div class="text-nowrap font-weight-bold">
                            Estado
                            <i class="fas fa-angle-down" *ngIf="filters.controls.sort.value == 'estate'"></i>
                            <i class="fas fa-angle-up" *ngIf="filters.controls.sort.value == '-estate'"></i>
                        </div>
                    </td>
                    <td></td>
                </tr>

                <tr class="list-row" *ngFor="let location of locations">
                    <td class="td-overflow-text">
                        <div class="text-nowrap">
                            {{ location.neighborhood_error }}
                        </div>
                    </td>
                    <td class="td-overflow-text">
                        <div class="text-nowrap">
                            {{ location.neighborhood }}
                        </div>
                    </td>
                    <td class="td-overflow-text">
                        <div class="text-nowrap">
                            {{ location.city }}
                        </div>
                    </td>
                    <td class="td-overflow-text">
                        <div class="text-nowrap">
                            {{ location.estate }}
                        </div>
                    </td>
                    <td class="align-middle td-min">
                        <div class="text-nowrap">
                            <div class="icon" (click)="delete(location.id)">
                                <i class="far fa-trash-alt"></i>
                            </div>
                        </div>
                    </td>
                </tr>


            </table>

			<table class="w-100 list-table show-mobile" *ngIf="locations.length">
				<tr class="header-table">
					<td></td>
					<td></td>
				</tr>

				<tr class="list-row" *ngFor="let location of locations">
					<td class="td-overflow-text">
						<div class="text-nowrap">
							<b>{{ location.neighborhood_error }}</b>
						</div>
						<div class="text-nowrap">
							<i>{{ location.neighborhood }}</i>
						</div>

						<div class="text-nowrap">
							{{ location.city }} - {{ location.estate }}
						</div>
					</td>
					<td class="align-middle td-min">
						<div class="text-nowrap">
							<div class="icon" (click)="delete(location.id)">
								<i class="far fa-trash-alt"></i>
							</div>
						</div>
					</td>
				</tr>


			</table>

            <div class="pt-4">
                <app-pagination [total]="locations_count" [perPage]="perPage" (changePage)="setPage($event)"
                    [(currentPage)]="currentPage">
                </app-pagination>
            </div>
        </div>
		</div>
	</div>
</div>
