import { ResponseTreatment } from '../../services/response-treatment';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var InfoPlatformService = /** @class */ (function () {
    function InfoPlatformService(http) {
        this.http = http;
        this.responseTreatment = new ResponseTreatment;
    }
    /**
     * Rota de Login
     * @param data
     * @returns {Observable<Object>}
     */
    InfoPlatformService.prototype.getInfo = function () {
        var _this = this;
        return this.http
            .get(environment.API + '/admin/get-info')
            .pipe(map(function (res) { return _this.responseTreatment.pipe(res); }));
    };
    InfoPlatformService.ngInjectableDef = i0.defineInjectable({ factory: function InfoPlatformService_Factory() { return new InfoPlatformService(i0.inject(i1.HttpClient)); }, token: InfoPlatformService, providedIn: "root" });
    return InfoPlatformService;
}());
export { InfoPlatformService };
