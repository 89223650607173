<div class="page-register">
	<!-- Register -->
	<div class="register-screen" *ngIf="!formSuccess">
		<img src="assets/images/logo-md.png" class="logo" />

		<div class="container-hide" [@minRegister]="loading">
			<form [formGroup]="form" (submit)="submit()">
				<div
					class="name-realestate"
					[class.error]="isFieldValid('name_realestate')"
				>
					<input
						type="text"
						placeholder="Nome da Imobiliária"
						formControlName="name_realestate"
						required="true"
					/>
					<app-field-error-display [displayError]="isFieldValid('name_realestate')"
											 errorMsg="Este campo é obrigatório">
					</app-field-error-display>
				</div>

				<div class="name" [class.error]="isFieldValid('name')">
					<input
						type="text"
						placeholder="Nome"
						formControlName="name"
						required="true"
					/>
					<app-field-error-display [displayError]="isFieldValid('name')"
											 errorMsg="Este campo é obrigatório">
					</app-field-error-display>
				</div>

				<div class="email" [class.error]="isFieldValid('email')">
					<input
						type="text"
						placeholder="Email"
						formControlName="email"
						required="true"
					/>
					<app-field-error-display [displayError]="isFieldValid('email')"
											 *ngIf="isFieldValid('email') && !form.get('email').errors['used']"
											 errorMsg="Informe um email valido!">
					</app-field-error-display>

					<app-field-error-display [displayError]="isFieldValid('email')"
											 *ngIf="isFieldValid('email') && form.get('email').errors['used']"
											 errorMsg="Este email já está sendo utilizado!">
					</app-field-error-display>
				</div>

				<div class="name" [class.error]="isFieldValid('phone')">
					<input
						type="text"
						placeholder="Telefone"
						formControlName="phone"
						required="true"
						appMaskPhone
					/>
					<app-field-error-display [displayError]="isFieldValid('phone')"
											 *ngIf="isFieldValid('phone') && !form.get('phone').errors['used']"
											 errorMsg="Informe um telefone valido!">
					</app-field-error-display>

					<app-field-error-display [displayError]="isFieldValid('phone')"
											 *ngIf="isFieldValid('phone') && form.get('phone').errors['used']"
											 errorMsg="Este telefone já está sendo utilizado!">
					</app-field-error-display>
				</div>

				<div class="password" [class.error]="isFieldValid('password')">
					<input
						type="password"
						placeholder="Senha"
						formControlName="password"
					/>
					<app-field-error-display [displayError]="isFieldValid('password')"
											 errorMsg="Este campo é obrigatório">
					</app-field-error-display>
				</div>

				<button class="btn">
					Registrar
				</button>
			</form>
			<div class="alert alert-danger" *ngIf="!!error">
				{{ error }}
			</div>

			<div
				class="alert alert-danger"
				*ngIf="!!form.controls.email.hasError('email') && formAttempt"
			>
				O email digitado é inválido.
			</div>

			<div class="forget-password">
				<a [routerLink]="'/login'">
					Voltar para o Login
				</a>
			</div>

			Copyright © 2017, Banco dos Imoveis Inc. - Banco dos Imoveis. Todos
			os direitos reservados.
		</div>

		<div class="_loading-icon" [@displayLoading]="loading">
			<div class="_load">
				<div></div>
				<div></div>
			</div>
		</div>
	</div>

	<div class="register-success" *ngIf="formSuccess">
		<img src="assets/images/logo-md.png" class="logo" />

		<div class="sucess-title">Parabéns você se registrou com sucesso!</div>

		<div class="login-info">
			Para acessar sua conta você precisa fazer o login, os seus dados de
			acesso são:

			<div class="login-data">
				<b>Login:</b> {{ form.get("email").value }}
			</div>
			<div class="password-data">
				<b>Password:</b> {{ form.get("password").value }}
			</div>
		</div>

		<div class="forget-password">
			<a [routerLink]="'/login'">
				Voltar para o Login
			</a>
		</div>
	</div>
	<!--// Register -->

	<!-- Esqueci a senha -->
	<!--<div class="login-screen" *ngIf="">-->
	<!--<img src="/src/assets/images/logo-md.png" class="logo">-->

	<!--<div class="email-forget-password">-->
	<!--Insira o seu email ou login no campo. Você receberá um email com o link para pode redefir sua senha.-->
	<!--<input type="text" placeholder="Login ou email">-->
	<!--</div>-->

	<!--<button class="btn">-->
	<!--Entrar no Painel-->
	<!--</button>-->

	<!--<div class="back">-->
	<!--<a ui-sref="login">-->
	<!--<i class="fas fa-arrow-left"></i> Voltar para Login-->
	<!--</a>-->
	<!--</div>-->
	<!--</div>-->
	<!--// Esqueci a senha -->
</div>
