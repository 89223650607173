<div class="mobile-form page-flex">

	<div class="flex-header">
		<app-nav-bar [title]="'Adicionar Sub Bairro'" [backIcon]="true" (backEvent)="backUrl()"></app-nav-bar>
	</div>

	<div class="flex-body">

		<div class="max-width">

		    <form [formGroup]="form">

		        <div class="card p-4 mb-4">
		            <div class="title-form">
		                <i class="fas fa-key"></i> Adicionar Novo Sub-Bairro
		                <small>Informe os dados do básicos</small>
		            </div>

		            <span>

		                <div class="row">

		                    <div class="col-md-6">
		                        <div class="field-container" [class.error-input]="isFieldValid('neighborhood_id')">
									<app-equivalence-search-neighborhoods (select)="getNeigh($event)">
		                            </app-equivalence-search-neighborhoods>
		                            <div class="name">Bairro</div>
		                        </div>

		                        <app-field-error-display [displayError]="isFieldValid('neighborhood_id')" errorMsg="Campo obrigatório.">
		                        </app-field-error-display>
		                    </div>

		                    <div class="col-md-6">
		                        <div class="field-container" [class.error-input]="isFieldValid('name')">
		                            <input type="text" formControlName="name" autocomplete="cWebnifLT">
		                            <div class="name">Sub-Bairro</div>
		                        </div>

		                        <app-field-error-display [displayError]="isFieldValid('name')" errorMsg="Campo obrigatório.">
		                        </app-field-error-display>
		                    </div>
		                </div>

		            </span>
		        </div>

		        <div class="p-4 pb-5"></div>
		    </form>
		</div>
	</div>

	<div class="flex-footer">
		<div class="actions-bottom">
			<!--<button class="btn btn-light" (click)="save()" type="button" *ngIf="">-->
			<!--Salvar e voltar-->
			<!--</button>-->
			<button class="btn btn-primary" (click)="save(true)" type="button">
				Salvar
			</button>
		</div>
	</div>
</div>
