import { ClientsDemandAddComponent } from './clients-demand-add/clients-demand-add.component';
import { ClientsDemandComponent } from './clientes-demand/clients-demand.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { PagesComponent } from './pages.component';
import { RouterModule, Routes } from '@angular/router';
import { LayoutModule } from '../layout/layout.module';
import { LoginComponent } from './login/login.component';
import { PropertiesListComponent } from './properties-list/properties-list.component';
import { ComponentsModule } from '../components/components.module';
import { ContactsListComponent } from './contacts-list/contacts-list.component';
import { UsersListComponent } from './users-list/users-list.component';
import { TicketsViewComponent } from './tickets-view/tickets-view.component';
import { SalesFunnelListComponent } from './sales-funnel-list/sales-funnel-list.component';
import { TicketsListComponent } from './tickets-list/tickets-list.component';
import { TasksComponent } from './tasks/tasks.component';
import { CalendarComponent } from './calendar/calendar.component';
import { PartnershipComponent } from './partnership/partnership.component';
import { ConversationComponent } from './partnership/conversation/conversation.component';
import { ConversationDefaultComponent } from './partnership/conversation-default/conversation-default.component';
import { SettingsAccountComponent } from './settings-account/settings-account.component';
import { SettingsWebsiteDesignComponent } from './settings-website-design/settings-website-design.component';
import { SettingsThemeComponent } from './settings-theme/settings-theme.component';
import { SettingsMetatagsComponent } from './settings-metatags/settings-metatags.component';
import { SettingsGeneralComponent } from './settings-general/settings-general.component';
import { PortalsComponent } from './portals/portals.component';
import { PropertiesPartnershipComponent } from './properties-partnership/properties-partnership.component';
import { PropertiesAddComponent } from './properties-add/properties-add.component';
import { NotificationsComponent } from './notifications/notifications.component';
import {
	FormsModule,
	ReactiveFormsModule
} from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from '../../services/token-interceptor.service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { PricePipe } from '../pipes/price.pipe';
import { ClickOutsideDirective } from '../directives/click-outside/click-outside.directive';
import { TaskListsComponent } from './task-lists/task-lists.component';
import { MomentModule } from 'angular2-moment';
import { TasksEditComponent } from './tasks-edit/tasks-edit.component';
import { CompleteHeightDirective } from '../directives/complete-height/complete-height.directive';
import { SortablejsModule } from 'angular-sortablejs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MaskInputDirective } from '../directives/mask-input/mask-input.directive';
import { NgxUploaderModule } from 'ngx-uploader';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxPhoneMaskBrModule } from 'ngx-phone-mask-br';
import { NgxMaskModule } from 'ngx-mask';
import { PartnershipSearchComponent } from './partnership/partnership-search/partnership-search.component';
import { ContactsAddComponent } from './contacts-add/contacts-add.component';
import { ContactsSearchComponent } from './contacts-search/contacts-search.component';
import { UsersAddComponent } from './users-add/users-add.component';
import { PartnershipAddComponent } from './partnership/partnership-add/partnership-add.component';
import { EventAddComponent } from './calendar/event-add/event-add.component';
import { ContactsViewComponent } from './contacts-view/contacts-view.component';
import { PropertiesFeaturesComponent } from './properties/properties-features/properties-features.component';
import { ViewAddComponent } from './calendar/view-add/view-add.component';
import { CalendarViewDayComponent } from './calendar/calendar-view-day/calendar-view-day.component';
import { NotFoundListComponent } from './equivalence/not-found/list/list.component';
import { NeighborhoodAddComponent } from './equivalence/neighborhood/neighborhood-add/neighborhood-add.component';
import { CalendarAddComponent } from './calendar/calendar-add/calendar-add.component';
import { LogoutPageComponent } from './logout-page/logout-page.component';
import { NeighborhoodListComponent } from './equivalence/neighborhood/neighborhood-list/neighborhood-list.component';
import { SubNeighborhoodAddComponent } from './equivalence/sub-neighborhood/sub-neighborhood-add/sub-neighborhood-add.component';
import { SubNeighborhoodListComponent } from './equivalence/sub-neighborhood/sub-neighborhood-list/sub-neighborhood-list.component';
import { CityAddComponent } from './equivalence/city/city-add/city-add.component';
import { CityListComponent } from './equivalence/city/city-list/city-list.component';
import {
	SubNeighborhoodErrorAddComponent
} from './equivalence/sub-neighborhood-error/sub-neighborhood-error-add/sub-neighborhood-error-add.component';
import { SubNeighborhoodErrorListComponent } from './equivalence/sub-neighborhood-error/sub-neighborhood-error-list/sub-neighborhood-error-list.component';
import { NeighborhoodErrorAddComponent } from './equivalence/neighborhood-error/neighborhood-error-add/neighborhood-error-add.component';
import { NeighborhoodErrorListComponent } from './equivalence/neighborhood-error/neighborhood-error-list/neighborhood-error-list.component';
import { InitialSettingsComponent } from './initial-settings/initial-settings.component';
import { ContactsViewAddInteractionsComponent } from './contacts-view/contacts-view-add-interactions/contacts-view-add-interactions.component';
import { ContactsViewEditInteractionsComponent } from './contacts-view/contacts-view-edit-interactions/contacts-view-edit-interactions.component';
import { TaskListAddComponent } from './task-lists/task-list-add/task-list-add.component';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { TasksRealizedResolver } from '../resolves/tasks-realized-resolver.service';
import { ContactsInteractionsResolver } from '../resolves/contacts-interactions-resolver';
import { ContactsViewResolver } from '../resolves/contacts-view-resolver';
import { ContactsInteractionsSingleResolver } from '../resolves/contacts-interactions-single-resolver';
import { EquivalenceNotFoundResolverService } from '../resolves/equivalence-not-found-resolver.service';
import { TasksEditResolver } from '../resolves/tasks-edit-resolver.service';
import { EquivalenceSubNeighborhoodResolverService } from '../resolves/equivalence-sub-neighborhood-resolve.service';
import { AgendaResolver } from '../resolves/agenda-resolver.service';
import { EquivalenceNeighborhoodResolverService } from '../resolves/equivalence-neighborhood-resolve.service';
import { AgendaViewResolver } from '../resolves/agenda-view-resolver';
import { NeedAuthGuard } from '../../services/NeedAuthGuard';
import { TasksResolver } from '../resolves/tasks-resolver.service';
import { PropertiesSearchResolver } from '../resolves/properties-search-resolver';
import { NeedAuthGuardLogin } from '../../services/NeedAuthGuardLogin';
import { PartnersContactsLoadService } from '../resolves/partners-contacts-load.service';
import { ContactsSearchService } from '../resolves/contacts-search.service';
import { EquivalenceSubNeighborhoodErrorResolverService } from '../resolves/equivalence-sub-neighorhood-error-resolve.service';
import { EquivalenceNeighborhoodErrorResolverService } from '../resolves/equivalence-neighborhood-error-resolve.service';
import { TasksListResolver } from '../resolves/tasks-list-resolver.service';
import { RealEstateGetService } from '../resolves/real-estate-get.service';
import { ContactsInfoResolver } from '../resolves/contacts-info-resolver';
import { PartnershipResolver } from '../resolves/partnership.service';
import { PropertiesViewResolver } from '../resolves/properties-view-resolver';
import { FunnelSalesStepsResolverService } from '../resolves/funnel-sales-steps-resolver.service';
import { EquivalenceCityResolverService } from '../resolves/equivalence-city-resolver.service';
import { UsersListResolverService } from '../resolves/users-list-resolver.service';
import { PartnershipConversationService } from '../resolves/partnership-conversation.service';
import { InfoPlatformResolver } from '../resolves/info-platform-resolver.service';
import { PropertiesInfoResolver } from '../resolves/properties-info-resolver';
import { BadgesResolverService } from '../resolves/badges-resolver.service';
import { ClientsSharedComponent } from './clients-shared/clients-shared.component';
import { ClientsSharedLoadingComponent } from './clients-shared/clients-shared-loading/clients-shared-loading.component';
import { PropertiesDemandComponent } from './properties-demand/properties-demand.component';
import { PropertiesDemandAddComponent } from './properties-demand-add/properties-demand-add.component';
import { PartnershipAutomaticMessageComponent } from './partnership/partnership-automatic-message/partnership-automatic-message.component';
import { ReloginComponent } from './relogin/relogin.component';
import { RegisterComponent } from './register/register.component';

@NgModule({
	imports: [
		LayoutModule,
		RouterModule,
		ComponentsModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		RouterModule,
		MomentModule,
		SortablejsModule,
		NgxUploaderModule,
		NgScrollbarModule,
		InfiniteScrollModule,
		NgxMaskModule.forRoot(),
		NgxPhoneMaskBrModule,
		NgxCurrencyModule.forRoot({
			align: 'right',
			allowNegative: true,
			allowZero: true,
			decimal: ',',
			precision: 2,
			prefix: 'R$ ',
			suffix: '',
			thousands: '.',
			nullable: true
		}),
		ColorPickerModule
	],
	declarations: [
		HomeComponent,
		PagesComponent,
		LoginComponent,
		PropertiesListComponent,
		ContactsListComponent,
		UsersListComponent,
		TicketsViewComponent,
		SalesFunnelListComponent,
		TicketsListComponent,
		TasksComponent,
		CalendarComponent,
		PartnershipComponent,
		ConversationComponent,
		ConversationDefaultComponent,
		SettingsAccountComponent,
		SettingsWebsiteDesignComponent,
		SettingsThemeComponent,
		SettingsMetatagsComponent,
		SettingsGeneralComponent,
		PortalsComponent,
		PropertiesPartnershipComponent,
		PropertiesAddComponent,
		NotificationsComponent,
		CompleteHeightDirective,
		TaskListsComponent,
		TasksEditComponent,
		PartnershipSearchComponent,
		ContactsAddComponent,
		ContactsSearchComponent,
		UsersAddComponent,
		PartnershipAddComponent,
		EventAddComponent,
		ContactsViewComponent,
		PropertiesFeaturesComponent,
		ViewAddComponent,
		CalendarViewDayComponent,
		NotFoundListComponent,
		NeighborhoodAddComponent,
		CalendarAddComponent,
		LogoutPageComponent,
		NeighborhoodListComponent,
		SubNeighborhoodAddComponent,
		SubNeighborhoodListComponent,
		CityAddComponent,
		CityListComponent,
		SubNeighborhoodErrorAddComponent,
		SubNeighborhoodErrorListComponent,
		NeighborhoodErrorAddComponent,
		NeighborhoodErrorListComponent,
		InitialSettingsComponent,
		ContactsViewAddInteractionsComponent,
		ContactsViewEditInteractionsComponent,
		TaskListAddComponent,
		ClientsSharedComponent,
		ClientsSharedLoadingComponent,
		PropertiesDemandComponent,
		PropertiesDemandAddComponent,
		PartnershipAutomaticMessageComponent,
		ClientsDemandComponent,
		ClientsDemandAddComponent,
		ReloginComponent,
		RegisterComponent
	],
	providers: [],
	exports: [
		UsersAddComponent,
		PartnershipAddComponent,
		EventAddComponent,
		ContactsViewComponent,
		PropertiesFeaturesComponent,
		ViewAddComponent,
		CalendarViewDayComponent,
		NotFoundListComponent,
		NeighborhoodAddComponent,
		CalendarAddComponent,
		InitialSettingsComponent,
		ContactsViewAddInteractionsComponent,
		SubNeighborhoodErrorAddComponent,
		ContactsViewEditInteractionsComponent,
		TaskListAddComponent,
		ClientsSharedComponent,
		ClientsSharedLoadingComponent,
		PropertiesDemandComponent,
		PartnershipAutomaticMessageComponent,
		ClientsDemandComponent,
		ClientsDemandAddComponent,
		RegisterComponent
	]
})

export class PagesModule {

}
