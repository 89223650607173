import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {PropertiesService} from '../models/properties.service';
import {ContactsService} from '../models/contacts.service';

@Injectable()
export class ContactsInfoResolver implements Resolve<any> {

    constructor(
        private contactsService: ContactsService
    ) {

    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.contactsService.getAny('info');
    }
}

