var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Helpers } from '../../../../../services/helpers';
import { skip } from 'rxjs/operators';
import { EquivalenceNotFoundService } from '../../../../models/equivalence-not-found.service';
import { ToastrService } from 'ngx-toastr';
var NotFoundListComponent = /** @class */ (function () {
    function NotFoundListComponent(route, router, fb, equivalenceNotFoundService, _toastrService) {
        this.route = route;
        this.router = router;
        this.fb = fb;
        this.equivalenceNotFoundService = equivalenceNotFoundService;
        this._toastrService = _toastrService;
        this.locations_count = 0;
        this.perPage = 40;
        this.currentPage = 1;
        // Buscando...
        this.searching = false;
        this.modalStatus = false;
    }
    NotFoundListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.locations = this.route.snapshot.data.locations.results;
        this.locations_count = this.route.snapshot.data.locations.count;
        this.configureFilters(this.route.snapshot.queryParams);
        this.route.queryParams
            .pipe(skip(1))
            .subscribe(function (data) {
            _this.loadLocations(data);
        });
    };
    // Abre o modal e define uma localizacao como atual
    NotFoundListComponent.prototype.openModal = function (location) {
        this.currentLocation = location;
        this.modalStatus = true;
    };
    NotFoundListComponent.prototype.configureFilters = function (data) {
        if (data === void 0) { data = {}; }
        this.filters = this.fb.group({
            sort: [data.sort || '', []],
            limit: [data.limit || '', []],
            textual_search: [data.textual_search || '', []],
            offset: [data.offset, []],
        });
        console.log(this.filters);
    };
    NotFoundListComponent.prototype.sort = function (sort) {
    };
    /**
     * Resgata mais contatos
     * @param data
     * @returns {Subscription}
     */
    NotFoundListComponent.prototype.loadLocations = function (data) {
        var _this = this;
        if (this.searching) {
            return false;
        }
        this.searching = true;
        return this.equivalenceNotFoundService
            .search(data)
            .subscribe(function (locations) {
            _this.locations = locations.results;
            _this.locations_count = locations.count;
            _this.searching = false;
        });
    };
    /**
     * Faz o tratamento dos filtros antes da busca
     * @returns {any}
     */
    NotFoundListComponent.prototype.getFilters = function () {
        var filters = __assign({}, this.filters.value);
        return filters;
    };
    /**
     * Atualiza novos parametros
     */
    NotFoundListComponent.prototype.updateParams = function (newSearch) {
        if (newSearch === void 0) { newSearch = true; }
        // Reset página
        if (newSearch) {
            this.filters.controls.offset.setValue(undefined);
            this.currentPage = 1;
        }
        this.router.navigate([], {
            queryParams: Helpers.filterArray(this.getFilters(), [
                Helpers.WITH_ZERO
            ])
        });
    };
    /**
     * Trocar página
     * @param {number} page
     */
    NotFoundListComponent.prototype.setPage = function (page) {
        page = page - 1;
        this.filters.controls.offset.setValue(page * this.perPage);
        this.updateParams(false);
    };
    NotFoundListComponent.prototype.setItemDelete = function (id) {
        this.delete_item_id = id;
        this.delete_modal = true;
    };
    NotFoundListComponent.prototype.deleteConfirm = function () {
        var _this = this;
        this.equivalenceNotFoundService.delete(this.delete_item_id)
            .subscribe(function (res) {
            _this._toastrService.success('Deletado com sucesso!');
            _this.loadLocations(_this.route.snapshot.queryParams);
        }, function (err) {
            _this._toastrService.error("Erro ao deletar.");
        });
    };
    return NotFoundListComponent;
}());
export { NotFoundListComponent };
