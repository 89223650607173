/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input-locations.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../directives/click-outside/click-outside.directive";
import * as i4 from "@angular/forms";
import * as i5 from "./input-locations.component";
import * as i6 from "../../models/location-search.service";
var styles_InputLocationsComponent = [i0.styles];
var RenderType_InputLocationsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_InputLocationsComponent, data: {} });
export { RenderType_InputLocationsComponent as RenderType_InputLocationsComponent };
function View_InputLocationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "app-users--loading-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-spinner"]], null, null, null, null, null))], null, null); }
function View_InputLocationsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "title-list"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bairros"]))], null, null); }
function View_InputLocationsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "li", [["class", "location-row"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setLocation(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "td text-muted td-min align-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "location"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-map-marker-alt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "td text-muted pl-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "location"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " - ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectNumber == _v.context.index); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.neighborhood; _ck(_v, 8, 0, currVal_1); var currVal_2 = _v.context.$implicit.city; var currVal_3 = _v.context.$implicit.estate; _ck(_v, 10, 0, currVal_2, currVal_3); }); }
function View_InputLocationsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "title-list"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cidades"]))], null, null); }
function View_InputLocationsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "li", [["class", "location-row"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setLocation(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "td text-muted td-min align-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "location"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-map-marker-alt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "td text-muted pl-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "location"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectNumber == (_co.locations.neighborhood.length + _v.context.index)); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.city; _ck(_v, 8, 0, currVal_1); var currVal_2 = _v.context.$implicit.estate; _ck(_v, 10, 0, currVal_2); }); }
function View_InputLocationsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "notfound"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Nenhum localiza\u00E7\u00E3o encontrado. "]))], null, null); }
function View_InputLocationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "ul", [["class", "autocomplete-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputLocationsComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputLocationsComponent_4)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputLocationsComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputLocationsComponent_6)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputLocationsComponent_7)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.locations.neighborhood.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.locations.neighborhood; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.locations.city.length; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.locations.city; _ck(_v, 8, 0, currVal_3); var currVal_4 = (!_co.locations.neighborhood.length && _co.locations.city.length); _ck(_v, 10, 0, currVal_4); }, null); }
function View_InputLocationsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "location"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " - ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.neighborhood; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.city; var currVal_2 = _v.parent.context.$implicit.estate; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_InputLocationsComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "location"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.city; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.estate; _ck(_v, 4, 0, currVal_1); }); }
function View_InputLocationsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "table locations-selected-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "td td-min"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "location"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fas fa-map-marker-alt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "td td-overflow-text pl-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputLocationsComponent_10)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputLocationsComponent_11)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "td text-primary font-weight-bold remove cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeSelectLocation(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.neighborhood; _ck(_v, 6, 0, currVal_0); var currVal_1 = !_v.context.$implicit.neighborhood; _ck(_v, 8, 0, currVal_1); }, null); }
function View_InputLocationsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputLocationsComponent_9)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_InputLocationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "app-users autocomplete"]], null, [[null, "clickOutside"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("clickOutside" === en)) {
        var pd_1 = (_co.setFocus(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 540672, null, 0, i3.ClickOutsideDirective, [i1.ElementRef], null, { clickOutside: "clickOutside" }), (_l()(), i1.ɵeld(2, 0, null, null, 5, "input", [["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "focus"], [null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("focus" === en)) {
        var pd_4 = (_co.setFocus(true) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.textModel = $event) !== false);
        ad = (pd_5 && ad);
    } if (("input" === en)) {
        var pd_6 = (_co.changeLocationInput($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(5, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(7, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputLocationsComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputLocationsComponent_2)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputLocationsComponent_8)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.textModel; _ck(_v, 5, 0, currVal_7); var currVal_8 = _co.loading; _ck(_v, 9, 0, currVal_8); var currVal_9 = _co.showAutocomplete; _ck(_v, 11, 0, currVal_9); var currVal_10 = _co.selectedsUser; _ck(_v, 13, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_InputLocationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-input-locations", [], null, [["document", "keydown.arrowup"], ["document", "keydown.arrowdown"], ["document", "keydown.enter"]], function (_v, en, $event) { var ad = true; if (("document:keydown.arrowup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).handleUpEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keydown.arrowdown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).handleDownEvent($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:keydown.enter" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).handleEnterEvent($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_InputLocationsComponent_0, RenderType_InputLocationsComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.InputLocationsComponent]), i1.ɵdid(2, 638976, null, 0, i5.InputLocationsComponent, [i6.LocationSearchService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var InputLocationsComponentNgFactory = i1.ɵccf("app-input-locations", i5.InputLocationsComponent, View_InputLocationsComponent_Host_0, { value: "value" }, { valueChange: "valueChange", change: "change" }, []);
export { InputLocationsComponentNgFactory as InputLocationsComponentNgFactory };
