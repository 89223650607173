import {Component, Input, OnChanges, OnInit} from '@angular/core';

@Component({
	selector: 'app-initials',
	templateUrl: './initials.component.html',
	styleUrls: ['./initials.component.scss']
})
export class InitialsComponent implements OnInit, OnChanges {

	@Input() clean = false;
	@Input() name;
	@Input() size;
	style: object;
	initials: string;
	// colors: string [] = [
	//     '#ff5e65',
	//     '#f76352',
	//     '#ff5e84',
	//     '#a05fb5',
	//     '#df7dc6',
	//     '#28c28b',
	//     '#ffc157',
	//     '#ff8681',
	//     '#00c7e2',
	//     '#01e6de',
	//     '#009bda',
	//     '#0079aa',
	// ];
	colors: string [] = [
		'#3ec55d',
		'#00c5d6',
		'#f15588',
		'#ffb229',
		'#8f63ff',
		'#8f63ff',
		'#00b1cd',
	];

	constructor() {

	}

	ngOnInit() {
		if (!!this.name.length) {

			let colorNumber = 0;
			for (let i in this.name) {
				colorNumber += this.name[i].charCodeAt(0);
			}

			this.initials = this.name[0].toUpperCase();

			let color = this.colors[colorNumber % this.colors.length];

			this.style = {
				color: color
			};

			if (!this.clean) {
				this.style['background'] = color + '24';
			}
		}
	}

	ngOnChanges() {
		if (!!this.name.length) {

			let colorNumber = 0;
			for (let i in this.name) {
				colorNumber += this.name[i].charCodeAt(0);
			}

			this.initials = this.name[0].toUpperCase();

			let color = this.colors[colorNumber % this.colors.length];

			this.style = {
				color: color
			};

			if (!this.clean) {
				this.style['background'] = color + '24';
			}
		}
	}

}
