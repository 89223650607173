<div class="page-login">
	<!-- Login -->
	<div class="login-screen">
		<img src="assets/images/logo-md.png" class="logo">

		<div>
			<b>Conectando ao servidor...</b>
		</div>
		<br>
		<div class="_loading-icon">
			<div class="_load">
				<div></div>
				<div></div>
			</div>
		</div>
	</div>
	<!--// Login -->
</div>
