import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as moment from 'moment';
import 'moment/min/locales';
import 'hammerjs';

moment.locale('pt-br');

if (environment.production) {
  enableProdMode();
}

Element.prototype['documentOffsetTop'] = function () {
	return this.offsetTop + ( this.offsetParent ? this.offsetParent.documentOffsetTop() : 0 );
};

Element.prototype['isOutOfViewport'] = function () {

	// Get element's bounding
	let bounding = this.getBoundingClientRect();

	// Check if it's out of the viewport on each side
	let out: any = {};
	out.top = bounding.top < 0;
	out.left = bounding.left < 0;
	out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
	out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
	out.any = out.top || out.left || out.bottom || out.right;
	out.all = out.top && out.left && out.bottom && out.right;

	return out;
};

platformBrowserDynamic().bootstrapModule(AppModule)
	.catch(err => console.log(err));
