import { LocationSubNeighborhoodErrorService } from './../../../../models/location-subneighborhood-error.service';
import { LocationNeighborhoodsService } from './../../../../models/location-neighborhoods.service';
import { EquivalenceNotFoundService } from './../../../../models/equivalence-not-found.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Helpers } from './../../../../../services/helpers';
import { Component, OnInit } from '@angular/core';
import { skip } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LocationSubneighborhoodsService } from 'src/app/content/models/location-subneighborhoods.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-sub-neighborhood-error-list',
  templateUrl: './sub-neighborhood-error-list.component.html',
  styleUrls: ['./sub-neighborhood-error-list.component.scss']
})
export class SubNeighborhoodErrorListComponent implements OnInit {

	locations: ISubNeigh[];
	locations_count = 0;
	filters: FormGroup;

	perPage = 40;
	currentPage = 1;

	// Buscando...
	searching = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private _subNeighErrorService: LocationSubNeighborhoodErrorService,
		private _toastrService: ToastrService,
		private _titleService: TitleService
	) {

	}

	ngOnInit() {

		this.locations = this.route.snapshot.data.locations.results;
		this.locations_count = this.route.snapshot.data.locations.count;

		this.configureFilters(this.route.snapshot.queryParams);

		this.route.queryParams
			.pipe(skip(1))
			.subscribe(data => {
				this.loadLocations(data);
			});

			this.setTitle();
	}

	private setTitle() {
		let title = 'Lista de Sub Bairros Erro';
		this._titleService.setListTitle(title, this.currentPage);
	}


	refresh() {
		return this.loadLocations(this.route.snapshot.queryParams);
	}

	configureFilters(data: any = {}) {

		this.filters = this.fb.group({
			sort: [data.sort || '', []],
			limit: [data.limit || '', []],
			textual_search: [data.textual_search || '', []],
			offset: [data.offset, []],
		});

		console.log(this.filters);
	}

	sort(sort: string) {

	}

	/**
     * Resgata mais contatos
     * @param data
     * @returns {Subscription}
     */
	loadLocations(data) {

		if (this.searching) {
			return false;
		}

		this.searching = true;

		return this._subNeighErrorService
			.search(data)
			.subscribe(locations => {
				this.locations = locations.results;
				this.locations_count = locations.count;
				this.searching = false;
			});
	}

	/**
     * Faz o tratamento dos filtros antes da busca
     * @returns {any}
     */
	getFilters() {
		let filters = { ... this.filters.value };

		return filters;
	}

	/**
     * Atualiza novos parametros
     */
	updateParams(newSearch = true) {
		// Reset página
		if (newSearch) {
			this.filters.controls.offset.setValue(undefined);
			this.currentPage = 1;
		}

		this.router.navigate([], {
			queryParams: Helpers.filterArray(this.getFilters(), [
				Helpers.WITH_ZERO
			])
		});
	}

	/**
     * Trocar página
     * @param {number} page
     */
	setPage(page: number) {
		this.currentPage = page;
		this.setTitle();
		page = page - 1;

		this.filters.controls.offset.setValue(page * this.perPage);

		this.updateParams(false);
	}

	delete(id: number) {

		this._subNeighErrorService.delete(id)
			.subscribe((res) => {
				this._toastrService.success('Deletado com sucesso!');
				this.loadLocations(this.route.snapshot.queryParams);
			}, (err) => {
				this._toastrService.error(`Erro ao deletar.`);
			});
	}
}

interface ISubNeigh {
	city: string;
	estate: string;
	neighborhood: string;
	subneighborhood: string;
	subneighborhood_error: string;
}
