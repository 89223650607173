<div class="max-width">
    <div class="card p-4">

        <div class="title-form">
            Portais Imobiliários
            <small>Integração com portais com o Banco dos Imóveis</small>
        </div>

        <div class="row">
            <div class="col-md-4">
                <div class="portal">
                    <div class="title">Viva Real</div>

                    <div class="image">
                        <img src="/src/assets/images/portals-vivareal.png">
                    </div>

                    <div class="btn btn-primary btn-round">Configurar</div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="portal">
                    <div class="title">Imovel Web</div>

                    <div class="image">
                        <img src="/src/assets/images/portals-imovelweb.png">
                    </div>

                    <div class="btn btn-primary btn-round">Configurar</div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="portal">
                    <div class="title">Mercado Livre</div>

                    <div class="image">
                        <img src="/src/assets/images/portals-mercadolivre.png">
                    </div>

                    <div class="btn btn-primary btn-round">Configurar</div>
                </div>
            </div>
        </div>

    </div>
</div>
