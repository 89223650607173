import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {flatMap, takeUntil} from 'rxjs/operators';
import {WebsocketService} from '../content/shareds/websocket.service';
import {WebSocket} from './WebSocket';
import {Subscriber} from 'rxjs/src/internal/Subscriber';

export class IndexedDB {

	static db: IDBDatabase;
	table: IDBObjectStore;

	constructor(
		private table_name: string,
	) {
	}

	connect(): Observable<any> {
		let destroy$ = new Subject();

		return Observable.create((observer) => {

			if (IndexedDB.db) {
				observer.next(this);
				observer.complete();
			}

			let dbReq = indexedDB.open('bdi-cached', 1);

			dbReq.onupgradeneeded = (event: any) => {
				// Set the db variable to our database so we can use it!
				IndexedDB.db = event.target.result;

				// Create an object store named notes. Object stores
				// in databases are where data are stored.
				this.table = IndexedDB.db.createObjectStore(this.table_name, {
					keyPath: 'id',
					autoIncrement: true
				});
			};

			dbReq.onsuccess = (event: any) => {
				IndexedDB.db = event.target.result;
				observer.next(this);
				observer.complete();
				destroy$.next();
			};

			dbReq.onerror = (event: any) => {
				observer.error(event.target.message);
				observer.complete();
				destroy$.next();
			};

		})
		.pipe(takeUntil(destroy$));
	}

	getValue(key: string) {
		let destroy$ = new Subject();

		if (!IndexedDB.db) {
			return this.connect()
				.pipe(flatMap(() => this.getValue(key)))
				.pipe(takeUntil(destroy$));
		}

		return Observable.create((observer) => {

			let transaction = IndexedDB.db.transaction([this.table_name], 'readwrite');
			let store = transaction.objectStore(this.table_name);

			let req = store.get(key);

			req.onsuccess = (event: any) => {
				let item = event.target.result;
				if (item) {
					observer.next(event.target.result);
					observer.complete();
				} else {
					observer.error(undefined);
					observer.complete();
				}
				destroy$.next();
			};

			req.onerror = (event: any) => {
				observer.error(event.target.message);
				observer.complete();
				destroy$.next();
			};

		}).pipe(takeUntil(destroy$));
	}

	setValue(key: string, value: string) {
		let destroy$ = new Subject();

		if (!IndexedDB.db) {
			return this.connect().subscribe(() => {
				return this.setValue(key, value);
			}, (err) => {
				return Observable.create((observer) => {
					observer.error(err);
					observer.complete();
					destroy$.next();
				}).pipe(takeUntil(destroy$));
			});
		}

		return Observable.create((observer) => {

			let transaction = IndexedDB.db.transaction([this.table_name], 'readwrite');
			let store = transaction.objectStore(this.table_name);

			let data = {
				id: key,
				data: value,
				timestamp: Date.now()
			};

			store.add(data);

			transaction.oncomplete = (event: any) => {
				observer.next();
				observer.complete();
				destroy$.next();
			};

			transaction.onerror = (event: any) => {
				observer.error(event.target.message);
				observer.complete();
				destroy$.next();
			};

		}).pipe(takeUntil(destroy$));
	}

	deleteValue(id: string) {
		let destroy$ = new Subject();

		if (!IndexedDB.db) {
			return this.connect().subscribe(() => {
				return this.deleteValue(id);
			}, (err) => {
				return Observable.create((observer) => {
					observer.error(err);
					observer.complete();
					destroy$.next();
				}).pipe(takeUntil(destroy$));
			});
		}

		return Observable.create((observer) => {

			let transaction = IndexedDB.db.transaction([this.table_name], 'readwrite');
			let store = transaction.objectStore(this.table_name);

			store.delete(id);

			transaction.oncomplete = (event: any) => {
				observer.next();
				observer.complete();
				destroy$.next();
			};

			transaction.onerror = (event: any) => {
				observer.error(event.target.message);
				observer.complete();
				destroy$.next();
			};

		}).pipe(takeUntil(destroy$));
	}

	putValue(key: string, value: string) {
		let destroy$ = new Subject();

		if (!IndexedDB.db) {
			return this.connect()
				.pipe(flatMap(() => this.putValue(key, value)))
				.pipe(takeUntil(destroy$));
		}

		return Observable.create((observer) => {

			let transaction = IndexedDB.db.transaction([this.table_name], 'readwrite');
			let store = transaction.objectStore(this.table_name);

			let data = {
				id: key,
				data: value,
				timestamp: Date.now()
			};

			store.put(data);

			transaction.oncomplete = (event: any) => {
				observer.next();
				observer.complete();
				destroy$.next();
			};

			transaction.onerror = (event: any) => {
				observer.error(event.target.message);
				observer.complete();
				destroy$.next();
			};

		}).pipe(takeUntil(destroy$));
	}

	deleteDatabase() {
		window.indexedDB.deleteDatabase('bdi-cached');

		if (IndexedDB.db) {
			IndexedDB.db.close();
			IndexedDB.db = null;
		}
	}



}
