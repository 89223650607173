export enum ErrorCode {
	SERVER_ERROR = 1,
	UNAUTHORIZED = 2,
	TOKEN_INVALID = 3,
	INCORRECT_LOGIN = 4,
	EMAIL_IN_USE = 10000,
	CPF_IN_USE = 10001,
	CNPJ_IN_USE = 10002,
	LOGIN_IN_USE = 10003,
	DATA_ERROR = 20000,
	DATA_REQUIRED = 20001,
	DATA_TYPE_ERROR = 20002,
	DATA_RANGE_MAX = 20003,
	DATA_RANGE_MIN = 20004,
	DATA_INVALID_CPF = 20005,
	DATA_INVALID_CEP = 20006,
	DATA_INVALID_EMAIL = 20007,
	DATA_NOT_ALLOWED_DATA = 20008,
	DATA_USER_TYPE_NOT_FOUND = 22000,
	DATA_PROPERTIES_TYPE_NOT_FOUND = 22100,
	NOT_FOUND = 30000,
	ACCOUNT_NOT_FOUND = 30001,
	REAL_ESTATE_NOT_EXIST = 30002,
	AGENDA_NOT_EXIST = 30003,
	PROPERTIES_NOT_EXIST = 30004,
	PROPERTIES_RDMS_NOT_EXIST = 30005,
	CONTACTS_NOT_EXIST = 30006,
	CONTACTS_RDMS_NOT_EXIST = 30007,
	CONTACTS_INTERATION_NOT_EXIST = 30008,
	TASK_LIST_NOT_EXIST = 30009,
	TASK_NOT_EXIST = 30010,

	UPLOAD_FILE_ERROR = 40000,
	UPLOAD_FILE_NOT_ALLOWED = 40001,
	REALESTATE_LIMIT_PROPERTIES_EXCEEDED = 50000,
	REALESTATE_LIMIT_IMAGES_EXCEEDED = 50001,
	PROPERTIES_COD_EXIST = 60000,
	PROPERTIES_COD_BDI_NOT_USE = 60001,
	CONTACTS_COD_EXIST = 70000,
	CONTACTS_PHONE_IN_USE = 70001,
	CONTACTS_EMAIL_IN_USE = 70002,
	FUNNEL_STEP_NOT_EMPTY = 80000,
	FUNNEL_STEP_EMPTY = 80001,
	FUNNEL_STEP_EXIST = 80002,
	LOCATION_EXIST = 90000,
	IMPORT_ELEMENT_NOT_FOUND_LISTING_ID = 100000,
	IMPORT_ELEMENT_NOT_FOUND_TRANSACTION_TYPE = 100001,
	IMPORT_ELEMENT_NOT_FOUND_STATE = 100002,
	IMPORT_ELEMENT_NOT_FOUND_CITY = 100003,
	IMPORT_ELEMENT_NOT_FOUND_NEIGHBORHOOD = 100004,
	IMPORT_ELEMENT_NOT_FOUND_PROPERTY_TYPE = 100005,
	IMPORT_ELEMENT_NOT_FOUND_LISTINGS = 100006,
	IMPORT_INCORRECT_PROPERTY_TYPE = 100007,
	IMPORT_INCORRECT_TRANSACTION_TYPE = 100008,
	IMPORT_XML_ERROR = 100009,
	IMPORT_XML_NOT_FOUND = 100010,
	IMPORT_ELEMENT_DUPLICATE_LISTING_ID = 100011,
}
