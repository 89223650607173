<div class="propertie-card black">

	<!--<a [routerLink]="['/properties/edit', property.id]" class="__link"></a>-->

	<div class="propertie-card-box-shadow">

		<div class="propertie-card-inside">
			<div class="media-container">
				<div class="image">
					<div class="cover"
						 [ngStyle]="{ 'background-image': 'url(' + property.images[0].url + ')'}"
					>
					</div>
				</div>

				<div class="featured" *ngIf="property.featured_home">
					Destaque
				</div>

				<div class="blocked" *ngIf="property.status != 1">
					<div class="box">
						{{ property.status_name }}
					</div>
				</div>

				<div class="prices">
					<div class="price sale" *ngIf="!!property.sale_price">
						Venda
						<div class="number">
							R$ {{ property.sale_price | price }}
						</div>
					</div>

					<div class="price rent" *ngIf="!!property.rent_price">
						Locação
						<div class="number">
							R$ {{ property.rent_price | price }}
						</div>
					</div>
				</div>
			</div>
			<div class="info-container">

				<div class="edit">

				</div>

				<div class="favorite">
					<i class="fas fa-star"></i>
				</div>

				<div class="header">
					<div class="header-type">
						<div class="cod">
							{{ property.cod }}
						</div>
						<div class="cod">
							{{ property.type_name }}
						</div>
					</div>
					<div class="property-title">
						{{ property.neighborhood }}, {{ property.city }} / {{ property.estate }}
					</div>
					<div class="type">
						{{ property.address }}
					</div>
				</div>

				<div class="compositions">
					<div class="composition">
						<i class="fas fa-chart-area"></i>
						<div class="number">{{ property.area }}m²</div>
					</div>
					<div class="composition">
						<i class="fas fa-bed"></i>
						<div class="number">{{ property.bedrooms }}</div>
					</div>
					<div class="composition">
						<i class="fas fa-car-side"></i>
						<div class="number">{{ property.vacancies }}</div>
					</div>
					<div class="composition">
						<i class="fas fa-bath"></i>
						<div class="number">{{ property.bathrooms }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
