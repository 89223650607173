import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output} from '@angular/core';
import {InputUsersComponent} from '../input-users/input-users.component';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {debounceTime, distinctUntilChanged, mergeMap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {UsersService} from '../../models/users.service';
import {Helpers} from '../../../services/helpers';

@Component({
	selector: 'app-input-multi-users',
	templateUrl: './input-multi-users.component.html',
	styleUrls: ['./input-multi-users.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: InputMultiUsersComponent,
		multi: true
	}]
})
export class InputMultiUsersComponent implements OnInit, OnChanges, ControlValueAccessor {

	// ID do usuário (Model)
	@Input() allUsers = false;
	@Input() showRealEstate = false;
	@Input() value = [];
	@Output() valueChange = new EventEmitter();

	// Evento Change
	@Output() change = new EventEmitter();

	searchTextChanged = new Subject<string>();
	textModel: string;
	users = [];
	loading = false;

	// Usuário selecionado
	selectedsUser = [];

	// Ver Autocomplete
	showAutocomplete = false;

	// Index selecionado
	selectNumber = 0;

	// Both onChange and onTouched are functions
	onChange: any = () => {
	};
	onTouched: any = () => {
	};

	constructor(
		private usersService: UsersService
	) {

	}

	ngOnInit() {

		this.searchTextChanged
			.pipe(debounceTime(350))
			.pipe(distinctUntilChanged())
			.pipe(mergeMap(search => this.searchUsers(search)))
			.subscribe(data => {
				this.users = data.results;
				for (let i in this.users) {
					if (this.value.indexOf(this.users[i].id) !== -1) {
						this.users.splice(Number(i), 1);
					}
				}

				this.loading = false;
			});
	}

	/**
	 * Changes
	 */
	ngOnChanges() {
		if (Helpers.empty(this.value)) {
			this.value = [];
			this.selectedsUser = [];
			this.textModel = '';
		}
	}

	/**
	 * Seleciona usuário
	 * @param user
	 */
	selectUser(user: any) {
		if (!user) {
			return false;
		}

		this.showAutocomplete = false;

		this.textModel = '';
		this.users = [];
		this.selectedsUser.push(user);
		this.value.push(user.id);

		this.searchTextChanged.next(null);
		this.updateChange();
	}

	/**
	 * Remover seleção
	 * @param user
	 */
	removeSelectUser(user: any) {

		console.log(user.id);
		console.log(this.value);

		if (this.value.indexOf(user.id) !== -1) {
			this.selectedsUser.splice(this.value.indexOf(user.id), 1);
			this.value.splice(this.value.indexOf(user.id), 1);
		}

		this.updateChange();
	}

	setFocus(focus) {
		this.showAutocomplete = focus;
	}

	/**
	 * Busca Usuários pelo texto no Input
	 * @param search
	 * @returns {Observable<any>}
	 */
	searchUsers(search) {
		return this.usersService.search({
			all_users: this.allUsers,
			textual_search: search
		});
	}

	/**
	 * Evento change no input
	 * @param $event
	 */
	changeLocationInput($event) {
		this.loading = true;
		this.showAutocomplete = true;
		let text = $event.target.value;
		this.searchTextChanged.next(text);
	}

	/**
	 * Update Model
	 * @param {KeyboardEvent} event
	 */
	updateChange() {
		if (Helpers.empty(this.value)) {
			this.value = [];
			this.selectedsUser = [];
			this.textModel = '';
		}

		this.valueChange.emit(this.value);
		this.change.emit();
		this.onChange(this.value);
	}

	@HostListener('document:keydown.arrowup', ['$event'])
	handleUpEvent(event: KeyboardEvent) {
		if (this.showAutocomplete) {
			this.selectNumber--;
			if (this.selectNumber < 0) {
				this.selectNumber = this.users.length - 1;

				if (this.selectNumber < 0) {
					this.selectNumber = 0;
				}
			}
		}
	}

	@HostListener('document:keydown.arrowdown', ['$event'])
	handleDownEvent(event: KeyboardEvent) {
		if (this.showAutocomplete) {
			this.selectNumber++;
			if (this.selectNumber >= this.users.length) {
				this.selectNumber = 0;
			}
		}
	}

	@HostListener('document:keydown.enter', ['$event'])
	handleEnterEvent(event: KeyboardEvent) {
		if (this.showAutocomplete) {
			this.selectUser(this.users[this.selectNumber]);
		}
	}

	///////////////
	// OVERRIDES //
	///////////////

	/**
	 * Writes a new item to the element.
	 * @param value the value
	 */
	writeValue(value: any): void {
		this.value = [];

		if (typeof value === 'object') {
			this.selectedsUser = value;

			for (let i in this.selectedsUser) {
				this.value.push(this.selectedsUser[i].id);
			}
		} else {
			this.selectedsUser = [];
		}

		this.updateChange();
	}

	/**
	 * Registers a callback function that should be called when the control's value changes in the UI.
	 * @param fn
	 */
	registerOnChange(fn: any): void {
		this.onChange = fn;
		this.updateChange();
	}

	/**
	 * Registers a callback function that should be called when the control receives a blur event.
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

}
