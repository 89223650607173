import {Directive, HostListener, Input} from '@angular/core';
import {ModalPageMobileService} from '../../shareds/modal-page-mobile.service';

@Directive({
	selector: '[routerModalPageMobile]'
})
export class RouterModalPageMobileDirective {

	@Input() routerModalPageMobile;
	@Input() close = false;

	constructor(
		private modalPageMobileService: ModalPageMobileService
	) {

	}

	@HostListener('click')
	onClick() {

		if (this.close) {
			this.modalPageMobileService.close(this.routerModalPageMobile);
		} else {
			this.modalPageMobileService.open(this.routerModalPageMobile);
		}
	}

}
