import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Global } from "../../../../services/global";
import { ContactsService } from "../../../models/contacts.service";
import { FormBuilder } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ModalContactDuplicateService } from "../../../shareds/modal-contact-duplicate.service";
import { Contacts } from "../../../interfaces/contacts";
import { IContactsInteractions } from "../../../interfaces/contacts-interactions";
import { ContactsInteractionsService } from "../../../models/contacts-interactions.service";
import { TitleService } from "src/app/services/title.service";
import { ContactInteractionsSharedService } from "../../../shareds/contact-interactions-shared.service";
import { takeUntil, skip } from "rxjs/operators";
import { Subject } from "rxjs";
import { RouterHistoric } from "../../../shareds/router-historic.service";
import { Location } from "@angular/common";

@Component({
	selector: "app-contacts-view",
	templateUrl: "./contacts-view.component.html",
	styleUrls: ["./contacts-view.component.scss"]
})
export class ContactsViewComponent implements OnInit, OnDestroy {
	types: any;
	types_properties: any;
	users_admin: any;
	estates: any;
	steps_funnel: any;
	nextCod = "";
	contact: Contacts;
	interactions: IContactsInteractions[];
	interactions_count: number;
	currentInteraction: IContactsInteractions;
	modalDelete: boolean;
	modalTitle: string;

	colors: string[] = [
		"#ff5e65",
		"#f76352",
		"#ff5e84",
		"#a05fb5",
		"#df7dc6",
		"#28c28b",
		"#ffc157",
		"#ff8681",
		"#00c7e2",
		"#01e6de",
		"#009bda",
		"#0079aa"
	];

	currentTab = 0;
	destroy$ = new Subject();

	urlNewCommitments = [];

	constructor(
		private fb: FormBuilder,
		private contactsService: ContactsService,
		private route: ActivatedRoute,
		public modalContactDuplicateService: ModalContactDuplicateService,
		private toastr: ToastrService,
		private _contactInteractService: ContactsInteractionsService,
		private _titleService: TitleService,
		private location: Location,
		private router: Router,
		private _contactsSharedService: ContactInteractionsSharedService
	) { }

	ngOnInit() {
		this.types = Global.info.contacts.types;
		this.types_properties = Global.info.properties.types;
		this.steps_funnel = Global.info.contacts.steps_funnel;
		this.users_admin = Global.info.users_admin;
		this.estates = Global.info.estates;

		this.contact = this.route.snapshot.data.contact;
		this._contactInteractService.setContact(this.contact.id);

		this.interactions = this.route.snapshot.data.interactions.results;
		this.interactions_count = this.route.snapshot.data.interactions.count;

		this.checkStep();
		this._titleService.setTitle(this.contact.name);

		this._contactsSharedService.event
			.pipe(takeUntil(this.destroy$))
			.pipe(skip(1))
			.subscribe(() => {
				console.log("b");

				this.refresh();
			});

		if (this.router.url.indexOf("sales-funnel") !== -1) {
			this.urlNewCommitments = [
				"/sales-funnel/list/contacts/view",
				this.contact.id,
				"add-interaction"
			];
		} else {
			this.urlNewCommitments = [
				"/contacts/view",
				this.contact.id,
				"add-interaction"
			];
		}

		console.log("TCL: ContactsViewComponent -> ngOnInit -> this.user", this.contact)
	}

	private checkStep() {
		for (let i in this.steps_funnel) {
			if (this.steps_funnel[i].id === this.contact.funnel_step) {
				this.contact.funnel_step_index = Number(i);
			}
		}
	}

	updateFunnelStep(step, funnel_step_index): void {
		console.log("TCL: ContactsViewComponent -> step", step);

		let data = {
			funnel_step: step.id
		};

		this.contactsService.update(this.contact.id, data).subscribe(
			res => {
				this.contact["funnel_step"] = res["funnel_step"];
				this.checkStep();
				this.toastr.success("A etapa do funil foi atualizada!");
			},
			err => {
				this.toastr.error("Ocorreu um erro no servidor!");
			}
		);
	}

	/**
	 * Abre o link no whatsapp
	 * @param {string} number
	 */
	openWhatsapp(number: string) {
		window.open("whatsapp://send?phone=55" + number, "_system");
	}

	/**
	 * Abre o link no whatsapp Web
	 * @param {string} number
	 */
	openWhatsappWeb(number: string) {
		window.open(
			"https://api.whatsapp.com/send?phone=55" + number,
			"_system"
		);
	}

	backUrl() {
		if (RouterHistoric.urlContain("/sales-funnel/")) {
			this.router.navigateByUrl("/sales-funnel/list");
		} else if (RouterHistoric.urlContain("/contacts/")) {
			this.location.back();
		} else {
			this.router.navigateByUrl("/contacts/list");
		}
	}

	openDeleteModal(interaction: IContactsInteractions) {
		this.currentInteraction = interaction;

		switch (this.currentInteraction.type) {
			case "contact_nota":
				this.modalTitle = "Deletar nota";
				break;

			case "properties_visit":
				this.modalTitle = "Deletar Visita";
				break;

			case "commitment":
				this.modalTitle = `Deletar compromisso`;
				break;

			case "task":
				this.modalTitle = `Deletar Tarefa`;
				break;
		}

		this.modalDelete = true;
	}

	deleteInteractions(interactionId: number) {
		console.log(
			"TCL: ContactsViewComponent -> deleteInteractions -> interactionId",
			interactionId
		);
		this._contactInteractService.delete(interactionId).subscribe(
			res => {
				for (let i = 0; i < this.interactions.length; i++) {
					const element = this.interactions[i];
					if (element.id === interactionId) {
						this.interactions.splice(i, 1);
					}
				}
				this.modalDelete = false;
			},
			err => {
				this.toastr.error("Ocorreu um erro no servidor!");
			}
		);
	}

	changePageTab(tabId: number) {
		this.currentTab = tabId;
	}

	refresh() {
		return this._contactInteractService
			.setContact(this.contact.id)
			.search({})
			.subscribe(res => {
				this.interactions = res.results;
				this.interactions_count = res.count;
			});
	}

	setRealizedCommitment(interaction: IContactsInteractions) {
		let realized = interaction.commitment.realized;
		realized = realized ? 0 : 1;
		this._contactInteractService
			.update(interaction.id, {
				commitment: { realized },
				type: "commitment"
			})
			.subscribe((res: IContactsInteractions) => {
				for (let i = 0; i < this.interactions.length; i++) {
					const element = this.interactions[i];
					if (element.id === res.id) {
						this.interactions[i].commitment.realized =
							res.commitment.realized;
					}
				}
			});
	}

	setRealizedTask(interaction: IContactsInteractions) {
		let realized = interaction.task.realized;
		realized = realized ? 0 : 1;
		this._contactInteractService
			.update(interaction.id, { task: { realized }, type: "task" })
			.subscribe((res: IContactsInteractions) => {
				for (let i = 0; i < this.interactions.length; i++) {
					const element = this.interactions[i];
					if (element.id === res.id) {
						this.interactions[i].task.realized = res.task.realized;
					}
				}
			});
	}

	/**
	 * Abre o modal para que seja possivel criar um chat de parceria com o usuario da view
	 */
	createChatPartner() {
		this.router.navigate(
			["", { outlets: { modal: "add-chat-partners" } }],
			{
				queryParams: {
					contacts_to_tickets: [this.contact.id]
				}
			}
		);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}
}
