<div class="page-flex no-over">

	<div class="flex-header">
		<app-nav-bar [title]="'Agenda'" [actions]="actions" (actionEvent)="setAction($event)">
		</app-nav-bar>
	</div>

	<div class="flex-body">

		<app-loading-page [show]="starting"></app-loading-page>

		<div class="full-calendar">
			<!-- MENU -->
			<div class="calendar-actions">

				<div class="scroll-over">
					<div class="show-mobile">
						<app-modal-page-mobile [title]="'Filtrar'" name="calendar-filters">
							<ng-container *ngTemplateOutlet="filtersTemplate"></ng-container>
						</app-modal-page-mobile>
					</div>

					<div class="hide-mobile">
						<ng-container *ngTemplateOutlet="filtersTemplate"></ng-container>
					</div>
				</div>
			</div>
			<!--// MENU -->

			<div class="calendar-container position-relative">
				<div class="scroll-over">
					<div class="loading" *ngIf="searching">
						<div class="loading-icon">
							<div class="load">
								<div></div>
							</div>
						</div>
					</div>

					<div class="table header-calendar">
						<div class="td text-left">

							<div class="change-month" (click)="lastMonth()">
								<i class="fas fa-arrow-left"></i>
							</div>

							<div class="title-calendar">
								{{ monthName }} {{ currentYear }}
							</div>

							<div class="change-month" (click)="nextMonth()">
								<i class="fas fa-arrow-right"></i>
							</div>

						</div>
						<div class="td text-right">
							<div class="btn btn-primary hide-mobile" [routerLink]="['/calendar/add']">
								Novo Compromisso
							</div>
						</div>
					</div>

					<!--<div class="btn-circle-action add-btn show-mobile" [routerLink]="['/calendar/add']">-->
						<!--<i class="fas fa-plus"></i>-->
					<!--</div>-->
 					<!--<div [routerModalPageMobile]="'calendar-filters'" class="btn-circle-action search-btn show-mobile">-->
						<!--<i class="fas fa-search"></i>-->
					<!--</div>-->

					<div class="calendar">
						<div class="week">
							<div class="week-name" *ngFor="let week of daysShort">
								{{week}}
							</div>
						</div>
						<div class="week" *ngFor="let week of calendar">
							<div class="day"
								 [routerLink]="['/calendar/day/', day.day, day.month, day.year]"
								 [class.today]="day.today"
								 [class.last-month]="day.last_month"
								 [class.next-month]="day.next_month"
								 [class.expired]="commitments[day.dateHash] && commitments[day.dateHash].events_expired"
								 [class.future]="commitments[day.dateHash] && commitments[day.dateHash].events_future"
								 *ngFor="let day of week">

								<div class="day-number">
									{{day.day}}

									<ng-container *ngIf="commitments[day.dateHash]">
										<div class="events-count"
											 [class.expired]="commitments[day.dateHash].events_expired"
											 [class.future]="commitments[day.dateHash].events_future">
											{{ commitments[day.dateHash].events_total }}
										</div>
									</ng-container>
								</div>

								<!-- Compromissos -->
								<ng-container *ngIf="!!commitments[day.dateHash]">
									<ng-container *ngFor="let commitment of commitments[day.dateHash]['events']; let i = index">
										<div class="commitment {{commitment.type}}" *ngIf="i < 3">
											<i class="fas fa-map-marker-alt" *ngIf="commitment.type == 'visit'"></i>
											<i class="fas fa-users" *ngIf="commitment.type == 'meeting'"></i>
											<i class="far fa-envelope-open" *ngIf="commitment.type == 'email'"></i>
											<i class="fas fa-phone" *ngIf="commitment.type == 'call'"></i>
											<i class="far fa-bookmark" *ngIf="commitment.type == 'after_sales'"></i>
											<i class="fas fa-tasks" *ngIf="commitment.type == 'task'"></i>
											<i class="fas fa-ticket-alt" *ngIf="commitment.type == 'ticket'"></i>

											<b>{{commitment.start_at | amDateFormat:'HH:mm'}}</b> - {{commitment.name}}
										</div>
									</ng-container>

									<div class="commitment more" *ngIf="commitments[day.dateHash]['events_total'] > 3">
										+{{commitments[day.dateHash]['events_total'] - 3}} Compromissos
									</div>
								</ng-container>
								<!--// Compromissos -->

							</div>
						</div>
					</div>

					<!--<div class="alert-dark p-4 font-weight-500">-->
						<!--<div class="table">-->
							<!--<div class="td td-min pr-3">-->
								<!--<img src="assets/images/logo-icon.png" alt="" width="30">-->
							<!--</div>-->
							<!--<div class="td">-->
								<!--Nunca mais perca um compromisso, tudo anotado com dia e hora agendados.-->
							<!--</div>-->
						<!--</div>-->
					<!--</div>-->
				</div>
			</div>
		</div>
	</div>
</div>

		<ng-template #filtersTemplate>
			<div class="filters-body">
				<div class="title">
					Tipo do Evento
				</div>
				<div class="body p-3">

					<div>
						<label class="checkbox-container">
							<input type="checkbox" (change)="setTypes('visit')">
							<div class="checkbox"></div>
							<span class="text"><i class="fas fa-map-marker-alt"></i> Visita</span>
						</label>
					</div>

					<div>
						<label class="checkbox-container">
							<input type="checkbox" (change)="setTypes('meeting')">
							<div class="checkbox"></div>
							<span class="text"><i class="fas fa-users"></i> Reunião</span>
						</label>
					</div>

					<div>
						<label class="checkbox-container">
							<input type="checkbox" (change)="setTypes('email')">
							<div class="checkbox"></div>
							<span class="text"><i class="far fa-envelope-open"></i> Email</span>
						</label>
					</div>

					<div>
						<label class="checkbox-container">
							<input type="checkbox" (change)="setTypes('call')">
							<div class="checkbox"></div>
							<span class="text"><i class="fas fa-phone"></i> Ligação</span>
						</label>
					</div>

					<div>
						<label class="checkbox-container">
							<input type="checkbox" (change)="setTypes('after_sales')">
							<div class="checkbox"></div>
							<span class="text"><i class="far fa-bookmark"></i> Pós-Venda</span>
						</label>
					</div>

					<div>
						<label class="checkbox-container">
							<input type="checkbox" (change)="setTypes('task')">
							<div class="checkbox"></div>
							<span class="text"><i class="fas fa-tasks"></i> Tarefas</span>
						</label>
					</div>

					<div>
						<label class="checkbox-container">
							<input type="checkbox" (change)="setTypes('ticket')">
							<div class="checkbox"></div>
							<span class="text"><i class="fas fa-ticket-alt"></i> Ticket</span>
						</label>
					</div>
				</div>

				<div class="title">
					Visualizar por Corretores
				</div>

				<div class="body p-3">

					<div *ngFor="let user of users">
						<label class="checkbox-container">
							<input type="checkbox" (change)="setUsers(user.id)">
							<div class="checkbox"></div>
							<span class="text">
									<app-user-img [user]="user" size="small"></app-user-img>
									{{user.name}}
								</span>
						</label>
					</div>

				</div>
			</div>
		</ng-template>

		<router-outlet>
		</router-outlet>
