import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {TasksService} from '../models/tasks.service';
import {Injectable} from '@angular/core';

@Injectable()
export class TasksRealizedResolver implements Resolve<any> {

    constructor(
        private taskService: TasksService
    ) {

    }

    resolve(route: ActivatedRouteSnapshot) {
        let params = {...route.queryParams};
        params['task_list_id'] = route.params.id;
        params['realized'] = 1;
        return this.taskService.search(this.taskService.processFilters(params));
    }
}
