import {Users} from '../content/interfaces/users';
import {map} from 'rxjs/operators';

/**
 * Variaveis globais do sistema
 */
export class Global {
	static token: string;
	static userLogged = false;
	static user: Users;
	static info: any;
	static badges: {
		total: number,
		agenda: number,
		properties: number,
		chat_partners: number
	};
	static mobile = false;
	static back = false;
}
