/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-users.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../user-img/user-img.component.ngfactory";
import * as i3 from "../user-img/user-img.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "./select-users.component";
var styles_SelectUsersComponent = [i0.styles];
var RenderType_SelectUsersComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SelectUsersComponent, data: {} });
export { RenderType_SelectUsersComponent as RenderType_SelectUsersComponent };
function View_SelectUsersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-user-img", [], null, null, null, i2.View_UserImgComponent_0, i2.RenderType_UserImgComponent)), i1.ɵdid(2, 638976, null, 0, i3.UserImgComponent, [], { user: [0, "user"], size: [1, "size"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedUser; var currVal_1 = "small"; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.selectedUser.name; _ck(_v, 3, 0, currVal_2); }); }
function View_SelectUsersComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "option"]], [[2, "active", null]], [[null, "mouseenter"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = ((_co.selectNumber = _v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.selectUser(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-user-img", [], null, null, null, i2.View_UserImgComponent_0, i2.RenderType_UserImgComponent)), i1.ɵdid(2, 638976, null, 0, i3.UserImgComponent, [], { user: [0, "user"], size: [1, "size"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit; var currVal_2 = "small"; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectNumber == _v.context.index); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_3); }); }
function View_SelectUsersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "autocomplete-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectUsersComponent_3)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.users; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SelectUsersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "select-users"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "select"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showAutocomplete = !_co.showAutocomplete) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectUsersComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectUsersComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedUser; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.showAutocomplete; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_SelectUsersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-select-users", [], null, [["document", "keydown.arrowup"], ["document", "keydown.arrowdown"], ["document", "keydown.enter"]], function (_v, en, $event) { var ad = true; if (("document:keydown.arrowup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).handleUpEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keydown.arrowdown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).handleDownEvent($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:keydown.enter" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).handleEnterEvent($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_SelectUsersComponent_0, RenderType_SelectUsersComponent)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.SelectUsersComponent]), i1.ɵdid(2, 638976, null, 0, i6.SelectUsersComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SelectUsersComponentNgFactory = i1.ɵccf("app-select-users", i6.SelectUsersComponent, View_SelectUsersComponent_Host_0, { users: "users" }, { change: "change" }, []);
export { SelectUsersComponentNgFactory as SelectUsersComponentNgFactory };
