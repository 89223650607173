<div class="tasks" appCompleteHeight>
    <div class="tasks-groups">
		<div class="tasks-groups--container" appCompleteHeight>
			<div class="title">
				Minhas Listas
			</div>

			<div class="task-groups-scroll">
				<!-- Caixa de Entrada -->
				<a href class="task-group"
						[class.active]="currentList == 0"
						(click)="setList(0)"
						[routerLink]="['/tasks/list', 0]">
					<div class="icon">
						<i class="fas fa-inbox"></i>
					</div>
					<div class="name">Caixa de Entrada</div>

					<div class="info">
						<span class="task-expired" *ngIf="inbox.tasks_expired">
							{{ inbox.tasks_expired }}
						</span>
						<span class="task-count">
							{{ inbox.tasks_count }}
						</span>
					</div>
				</a>
				<!-- Caixa de Entrada -->

				<!-- Group -->
				<div class="task-group"
					 [class.active]="currentList == 'favorite'"
					 (click)="setList('favorite')"
					>
					<div class="icon">
						<i class="far fa-star"></i>
					</div>
					<div class="name">Com estrela</div>
				</div>
				<!-- Group -->


				<!-- TaskList -->
				<a class="task-group"
					[class.active]="currentList == tasklist.id"
					(click)="setList(tasklist.id)"
					[routerLink]="['/tasks/list', tasklist.id]"
					 *ngFor="let tasklist of taskLists; let i = index;">
					<div class="icon">
						<i class="fas fa-user-friends" *ngIf="tasklist.shared_users.length > 1"></i>
						<i class="fas fa-tasks" *ngIf="tasklist.shared_users.length <= 1"></i>
					</div>
					<div class="name">{{ tasklist.name }}</div>

					<div class="info">
						<span class="task-expired" *ngIf="tasklist.tasks_expired">
							{{ tasklist.tasks_expired }}
						</span>
						<span class="task-count">
							{{ tasklist.tasks_count }}
						</span>
					</div>
				</a>
				<!-- TaskList -->
			</div>

			<div class="add-task" [routerLink]="['/tasks', {outlets: { modal: 'add-list' } } ]">
				<div class="icon">
					<i class="fas fa-plus"></i>
				</div>
				<div class="name">Adicionar Lista</div>
			</div>
		</div>
    </div>

    <div class="tasks-list" [class.loading-opacity]="loading">
		<router-outlet></router-outlet>
    </div>

</div>

<router-outlet name="modal">
</router-outlet>
