import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tickets-card',
  templateUrl: './tickets-card.component.html',
  styleUrls: ['./tickets-card.component.scss']
})
export class TicketsCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
