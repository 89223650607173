/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./drop-menu.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directives/click-outside/click-outside.directive";
import * as i3 from "@angular/common";
import * as i4 from "./drop-menu.component";
var styles_DropMenuComponent = [i0.styles];
var RenderType_DropMenuComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DropMenuComponent, data: { "animation": [{ type: 7, name: "dropAnimate", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { top: "30px", opacity: 0 }, offset: null }, { type: 3, steps: [{ type: 4, styles: { type: 6, styles: { top: "15px" }, offset: null }, timings: "300ms ease-in-out" }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "200ms linear" }], options: null }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 3, steps: [{ type: 4, styles: { type: 6, styles: { top: "30px" }, offset: null }, timings: "300ms ease-in-out" }, { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "200ms linear" }], options: null }, { type: 6, styles: { top: "30px", opacity: 1 }, offset: null }], options: null }], options: {} }] } });
export { RenderType_DropMenuComponent as RenderType_DropMenuComponent };
function View_DropMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "drop-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ul", [["class", "drop-list"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDrop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵncd(null, 1)], null, null); }
export function View_DropMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "drop-menu"]], null, [[null, "clickOutside"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("clickOutside" === en)) {
        var pd_1 = (_co.closeDrop() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 540672, null, 0, i2.ClickOutsideDirective, [i1.ElementRef], { slim: [0, "slim"] }, { clickOutside: "clickOutside" }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "drop-header"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeDrop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropMenuComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.dropStatus; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_DropMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-drop-menu", [], null, null, null, View_DropMenuComponent_0, RenderType_DropMenuComponent)), i1.ɵdid(1, 114688, null, 0, i4.DropMenuComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DropMenuComponentNgFactory = i1.ɵccf("app-drop-menu", i4.DropMenuComponent, View_DropMenuComponent_Host_0, {}, {}, ["[id=drop-btn]", "[id=drop-list]"]);
export { DropMenuComponentNgFactory as DropMenuComponentNgFactory };
