import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscribable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {ResponseTreatment} from '../../services/response-treatment';
import {Users} from '../interfaces/users';
import {CrudManager} from '../../services/crud-manager';

@Injectable({
  providedIn: 'root'
})
export class FunnelSalesService extends CrudManager {
    path = '/admin/sales-funnel';

    constructor() {
        super();
    }

    /**
     * Organizar Etapa
     * @param {number} id
     * @param {number} sortable
     * @returns {Observable<any>}
     */
    sortableStep(id: number, sortable: number) {
        return this.http
            .post(environment.API + this.path + '/sortable/steps', {
                'step': id,
                'order_funnel': sortable,
            })
            .pipe(map(data => this.responseTreatment.pipe(data)))
            .pipe(catchError(this.responseTreatment.handleError));
    }

    /**
     * Organizar Contato
     * @param {number} id
     * @param {number} idStep
     * @param {number} sortable
     * @param {number} realtors_managers
     * @returns {Observable<any>}
     */
    sortableContact(id: number, idStep: number, sortable: number, realtors_managers: number = 0) {
        return this.http
            .post(environment.API + this.path + '/sortable/' + id, {
                'funnel_step': idStep,
                'sortable': sortable,
                'realtors_managers': realtors_managers,
            })
            .pipe(map(data => this.responseTreatment.pipe(data)))
            .pipe(catchError(this.responseTreatment.handleError));
    }
}
