import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {
	transition,
	trigger,
	query,
	style,
	animate,
	group,
	animateChild
} from '@angular/animations';
import {Global} from '../../services/global';
import {WebserverResponseTypes} from '../interfaces/webserver-response-types';
import {skip, takeUntil} from 'rxjs/operators';
import {WebsocketService} from '../shareds/websocket.service';
import {GlobalService} from '../shareds/global.service';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {PlaySound} from '../../services/PlaySound';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-pages',
	templateUrl: './pages.component.html',
	styleUrls: ['./pages.component.scss'],
	animations: [

		trigger('routeAnimations', [
			transition('* => *', [
				query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
				group([
					query(':enter', [
						style({ /* transform: 'translateY(50%)',*/  opacity: 0.0, 'z-index': 190 }),
						animate('0.3s ease-in-out', style({ /* transform: 'translateY(0%)',*/  opacity: 1.0 }))
					], { optional: true }),
					// query(':leave', [
					// 	style({ transform: 'translateY(0%)' }),
					// 	animate('0.5s ease-in-out', style({ transform: 'translateY(100%)' }))
					// ], { optional: true }),
				])
			]),
		]),
		trigger('routeAnimationsBack', [
			transition('* => *', [
				query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
				group([
					query(':enter', [
						style({ /* transform: 'translateY(0%)' */ opacity: 1.0, 'z-index': 180 })
					], { optional: true }),
					query(':leave', [
						style({ /* transform: 'translateY(0%)' */ opacity: 1.0, 'z-index': 190 }),
						animate('0.3s ease-in-out', style({ /* transform: 'translateY(50%)',*/ opacity: 0.0 }))
					], { optional: true }),
				])
			])
		])

		// trigger('routerTransition', [
		// 	// The '* => *' will trigger the animation to change between any two states
		// 	transition('* => *', [
		// 		// The query function has three params.
		// 		// First is the event, so this will apply on entering or when the element is added to the DOM.
		// 		// Second is a list of styles or animations to apply.
		// 		// Third we add a config object with optional set to true, this is to signal
		// 		// angular that the animation may not apply as it may or may not be in the DOM.
		// 		query(
		// 			':enter',
		// 			[style({opacity: 0, transform: 'translateX(50%)'})],
		// 			{optional: true}
		// 		),
		// 		query(
		// 			':leave',
		// 			// here we apply a style and use the animate function to apply the style over 0.3 seconds
		// 			[style({opacity: 1}), animate('0.2s', style({opacity: 0}))],
		// 			{optional: true}
		// 		),
		// 		query(
		// 			':enter',
		// 			[style({opacity: 0, transform: 'translateX(0%)'}), animate('0.2s', style({opacity: 1}))],
		// 			{optional: true}
		// 		)
		// 	])
		// ])
	]
})
export class PagesComponent implements OnInit, OnDestroy {

	menuShow = false;

	touchstartX = 0;
	touchstartY = 0;
	touchendX = 0;
	touchendY = 0;

	newUpdateRequired = false;

	// Destruir todos os subscrive
	private destroy$ = new Subject();
	private menuFastMobile = true;

	constructor(
		private ngZone: NgZone,
		private _globalService: GlobalService,
		private router: Router,
		private http: HttpClient
	) {
	}

	onActivate() {
		// Para chat manter loading por mais algum tempo
		if (this.router.url.indexOf('partnership/conversation/') === -1) {
			this._globalService.updateLoadingScreen(false);
		}
		
		this.http
			.get(environment.API + '/health-check')
			.subscribe((v) => {
				console.log(v);
				
				this.newUpdateRequired = true;
			});
	}

	activatedRoute(o: any) {
		if (!Global.back) {
			return o.isActivated ? o.activatedRoute : '';
		}

		return null;
	}

	activatedRouteBack(o: any) {
		if (Global.back) {
			return o.isActivated ? o.activatedRoute : '';
		}

		return null;
	}

	ngOnInit() {

		document.addEventListener('touchstart', (e) => {
			this.touchstartX = e.changedTouches[0].screenX;
			this.touchstartY = e.changedTouches[0].screenY;
		}, false);

		document.addEventListener('touchend', (e) => {
			this.touchendX = e.changedTouches[0].screenX;
			this.touchendY = e.changedTouches[0].screenY;
			this.handleGesure();
		}, false);

		this._globalService
			.menuFastMobile
			.pipe(takeUntil(this.destroy$))
			.pipe(skip(1))
			.subscribe((menuFastMobile: any) => {
				this.menuFastMobile = menuFastMobile;
			});


		WebsocketService
			.mutation
			.pipe(skip(1))
			.pipe(takeUntil(this.destroy$))
			.subscribe((data: any) => {
				if (data.subtype) {
					let body = data.body;

					switch (data.subtype) {
						case WebserverResponseTypes.BADGES_CHANGE:
							this._globalService.updateBadge(body);
							break;

						case WebserverResponseTypes.NEW_TICKET:
						case WebserverResponseTypes.NEW_POST:
						case WebserverResponseTypes.NEW_TICKET_MANY:
							if (this.router.url.indexOf('/partnership') !== 0) {
								// Temp
								Global.badges.total = 10;
								this._globalService.updateBadge(Global.badges);

								PlaySound.play('chat-notification');
							}
							break;
					}
				}
			});
	}

	onPan(evt) {
		console.log(`(${evt.center.x}, ${evt.center.y})`);
	}

	handleGesure() {
		this.ngZone.run(() => {
			if (this.touchendX < this.touchstartX && this.touchstartX - this.touchendX >= 100) {
				this.menuShow = false;
			}

			// if (this.touchendX > this.touchstartX && this.touchendX - this.touchstartX >= 100) {
			// 	this.menuShow = true;
			// }
		});
	}

	getState(outlet) {
		console.log(outlet.activatedRouteData.state);
		return outlet.activatedRouteData.state;
	}

	openLinkUpdate() {
		window.open(
			'https://play.google.com/store/apps/details?id=br.com.bancodosimoveis.app.admin',
			"_system"
		);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

}
