/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pages.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./initial-settings/initial-settings.component.ngfactory";
import * as i3 from "./initial-settings/initial-settings.component";
import * as i4 from "../layout/header/header.component.ngfactory";
import * as i5 from "../layout/header/header.component";
import * as i6 from "@angular/router";
import * as i7 from "../models/notifications.service";
import * as i8 from "../shareds/global.service";
import * as i9 from "../../services/auth.service";
import * as i10 from "@angular/common";
import * as i11 from "./pages.component";
import * as i12 from "@angular/common/http";
var styles_PagesComponent = [i0.styles];
var RenderType_PagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PagesComponent, data: { "animation": [{ type: 7, name: "routeAnimations", definitions: [{ type: 1, expr: "* => *", animation: [{ type: 11, selector: ":enter, :leave", animation: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: { optional: true } }, { type: 3, steps: [{ type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0, "z-index": 190 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "0.3s ease-in-out" }], options: { optional: true } }], options: null }], options: null }], options: {} }, { type: 7, name: "routeAnimationsBack", definitions: [{ type: 1, expr: "* => *", animation: [{ type: 11, selector: ":enter, :leave", animation: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: { optional: true } }, { type: 3, steps: [{ type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 1, "z-index": 180 }, offset: null }], options: { optional: true } }, { type: 11, selector: ":leave", animation: [{ type: 6, styles: { opacity: 1, "z-index": 190 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "0.3s ease-in-out" }], options: { optional: true } }], options: null }], options: null }], options: {} }] } });
export { RenderType_PagesComponent as RenderType_PagesComponent };
function View_PagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-initial-settings", [], null, null, null, i2.View_InitialSettingsComponent_0, i2.RenderType_InitialSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i3.InitialSettingsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_PagesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "update-version"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "logo"], ["src", "assets/images/logo-md.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Atualiza\u00E7\u00E3o Obrigat\u00F3ria!"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Por favor atualize o aplicativo, esta vers\u00E3o n\u00E3o receber\u00E1 mais suporte. "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary bt-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLinkUpdate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Clique aqui para atualizar"]))], null, null); }
export function View_PagesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, [[null, "menuShowVarChange"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("menuShowVarChange" === en)) {
        var pd_1 = ((_co.menuShow = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_HeaderComponent_0, i4.RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i5.HeaderComponent, [i6.Router, i7.NotificationsService, i8.GlobalService, i1.ChangeDetectorRef, i9.AuthService], { menuShowVar: [0, "menuShowVar"] }, { menuShowVarChange: "menuShowVarChange" }), (_l()(), i1.ɵeld(2, 0, null, null, 2, "main", [["class", "main-page menu-fast-open"]], [[24, "@routeAnimations", 0], [24, "@routeAnimationsBack", 0], [2, "menu-open", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, [[null, "activate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activate" === en)) {
        var pd_0 = (_co.onActivate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 212992, [["o", 4]], 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, { activateEvents: "activate" }), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [["name", "modal"]], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, "modal"], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["toastContainer", ""]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PagesComponent_1)), i1.ɵdid(9, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PagesComponent_2)), i1.ɵdid(11, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuShow; _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); _ck(_v, 6, 0); var currVal_4 = false; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.newUpdateRequired; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.activatedRoute(i1.ɵnov(_v, 4)); var currVal_2 = _co.activatedRouteBack(i1.ɵnov(_v, 4)); var currVal_3 = _co.menuShow; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_PagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pages", [], null, null, null, View_PagesComponent_0, RenderType_PagesComponent)), i1.ɵdid(1, 245760, null, 0, i11.PagesComponent, [i1.NgZone, i8.GlobalService, i6.Router, i12.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PagesComponentNgFactory = i1.ɵccf("app-pages", i11.PagesComponent, View_PagesComponent_Host_0, {}, {}, []);
export { PagesComponentNgFactory as PagesComponentNgFactory };
