<div class="modal-screen modal-select-properties change-mobile-page" *ngIf="openModal" [@ModalAnimation]="openModal">

	<div class="modal-body d-flex">

		<div class="header-search-property">
			<div class="i-close" (click)="close()">
				<div class="icon-close-only black s32x32 display-close"></div>
				<img src="/assets/svg/home.svg" class="display-back">
			</div>

			<div class="title">
				Selecionar Imóvel
			</div>

			<!--<div class="search">-->
			<!--<input type="text"-->
			<!--class="input"-->
			<!--[(ngModel)]="textModel"-->
			<!--(input)="changeLocationInput($event)"-->
			<!--placeholder="Buscar imóveis por Localização, Código, Tipo, etc..">-->
			<!--</div>-->

			<b class="text-main text-uppercase title-properties active" *ngIf="properties.length && !loading">
				{{properties_count}} Imóveis encontrados

				<div class="btn-circle-action search-btn float-right" (click)="openFilters()">
					<i class="fas fa-search"></i>
				</div>
			</b>
		</div>

		<div class="search-list-container position-relative"
			 infiniteScroll
			 [infiniteScrollDistance]="250"
			 [infiniteScrollThrottle]="50"
			 (scrolled)="onScroll()"
			 (scrolledUp)="onScroll()"
			 [scrollWindow]="false">

			<div class="search-list">
				<div class="search-not-found" *ngIf="!properties.length && !loading">
					<img src="assets/images/notfound-properties.png">
					Nenhum imóvel encontrado

					<div class="btn btn-primary" (click)="openFilters()">
						Realizar nova busca
					</div>
				</div>
				<div class="property cursor-pointer" *ngFor="let property of properties"
					 (click)="selectProperty = property.id">
					<div class="table property-card" [class.bdi-group]="property.bdi_group" [class.active]="selectProperty == property.id">

						<img src="assets/images/logo-96x96.png" class="bdi-logo" *ngIf="property.bdi_group">

						<div class="td td-min align-top image-property">
							<div class="image-size"></div>

							<div class="image">
								<div class="cover"
									 [ngStyle]="{ 'background-image': 'url(' + property.images[0].url + ')'}">
								</div>

								<div class="check-btn" *ngIf="selectProperty == property.id">
									<i class="fas fa-check"></i>
								</div>
							</div>
						</div>
						<div class="td pl-3 align-top td-overflow-text">
							<div class="tag">
								{{property.cod}}
							</div>
							<div class="tag">
								{{property.type_name}}
							</div>
							<div class="location text-ellipsis">
								{{property.neighborhood}}, {{property.city}} - {{property.estate}}
							</div>

							<div class="compositions">
								<div class="composition">
									<i class="fas fa-chart-area"></i>
									<div class="number">{{ property.area }}m²</div>
								</div>
								<div class="composition">
									<i class="fas fa-bed"></i>
									<div class="number">{{ property.bedrooms }}</div>
								</div>
								<div class="composition">
									<i class="fas fa-car-side"></i>
									<div class="number">{{ property.vacancies }}</div>
								</div>
								<div class="composition">
									<i class="fas fa-bath"></i>
									<div class="number">{{ property.bathrooms }}</div>
								</div>
							</div>

							<div class="sale" *ngIf="property.sale_price">
								<span class="type">Venda</span> R${{property.sale_price | price}}
							</div>

							<div class="rent" *ngIf="property.rent_price">
								<span class="type">Locação</span> R${{property.rent_price | price}}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="loading-icon-ring" *ngIf="loading"></div>

			<div class="loading-icon-ring" *ngIf="loadingMore"></div>
		</div>

		<div class="select-propertie-action ">
			<div class="d-flex">
				<div class="flex-grow-1">
					<div class="btn btn-cancel" (click)="close()">
						Cancelar
					</div>
				</div>
				<div class="flex-grow-1">
					<div class="btn btn-apply" [class.disabled]="!selectProperty" (click)="defineProperty()">
						Selecionar
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal-body d-flex" *ngIf="tab == 'filters'">

		<div class="header-search-property">
			<div class="i-close" (click)="tab = 'properties'">
				<div class="icon-close-only black s32x32 display-close"></div>
				<img src="/assets/svg/home.svg" class="display-back">
			</div>

			<div class="title">
				Filtrar Imóveis
			</div>
		</div>

		<div class="search-list-container position-relative">
			<div class="search-list">
				<div class="filters-body p-3">
					<form [formGroup]="filters">

						<div class="body">

							<label class="select-container">
								<select formControlName="sort">
									<option [value]="'-created_at'">Data da adição</option>
									<option [value]="'price'">Preço - Menor para Maior</option>
									<option [value]="'-price'">Preço - Maior para Menor</option>
								</select>
								<div class="name">Ordenar por</div>
							</label>

							<div class="filter">
								<div class="filter-title">
									VISUALIZAR IMÓVEIS PARCEIROS?
								</div>
								<div formGroupName="root">
									<label class="checkbox-container clean">
										<input type="checkbox" formControlName="bdi_group">
										<div class="checkbox"></div>
										<span class="text">Sim</span>
									</label>
								</div>
							</div>

							<div class="field-container pr-sm-0" *ngIf="!APPROVAL">
								<input type="text" formControlName="cod">
								<div class="name"><i class="fas fa-hashtag"></i> CÓDIGO DO IMÓVEL</div>
							</div>

							<label class="select-container">
								<select formControlName="finality">
									<option [value]="''">Todos</option>
									<option [value]="'sale'">Venda</option>
									<option [value]="'rent'">Locação</option>
								</select>
								<div class="name">Finalidade</div>
							</label>

							<label class="select-container" *ngIf="!APPROVAL && !PARTNERSHIP">
								<select formControlName="status">
									<option [value]="''">Todos</option>
									<option *ngFor="let status of info.properties.status" [value]="status.id">
										{{status.name}}
									</option>
								</select>
								<div class="name">ESTADO DO IMÓVEL</div>
							</label>

							<div class="input">
								<div class="field-container pr-sm-0">
									<app-input-locations formControlName="location">
									</app-input-locations>
									<div class="name">
										<i class="fas fa-map-marker-alt"></i> Localização
									</div>
								</div>
							</div>

							<hr *ngIf="!APPROVAL">

							<div class="filter">
								<div class="filter-title">
									Tipo
								</div>
								<div formArrayName="types"
									 *ngFor="let type of filters.controls.types.controls; let i = index">
									<label class="checkbox-container clean">
										<input type="checkbox" [formControlName]="i">
										<div class="checkbox"></div>
										<span class="text">{{types[i].name}}</span>
									</label>
								</div>
							</div>

							<div class="pb-2">
								<div class="row p-0 m-0">
									<div class="col-6 p-1">
										<div class="input">
											<div class="field-container pr-sm-0">
												<input type="text" placeholder="0,00" formControlName="price_gte"
													   appMaskNumber
													   prefix="R$">
												<div class="name">Preço Minimo</div>
											</div>
										</div>
									</div>
									<div class="col-6 p-1">
										<div class="input">
											<div class="field-container pr-sm-0">
												<input type="text" placeholder="ilimitado" formControlName="price_lte"
													   appMaskNumber prefix="R$">
												<div class="name">Preço Máximo</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="pb-2">
								<div class="field-title">DORMITÓRIOS</div>
								<div class="btn-group btn-selects">
									<div class="btn" *ngFor="let number of arrayNumber"
										 (click)="changeNumber('bedrooms', number)"
										 [class.active]="number == this.filters.get('bedrooms').value">
										{{number}}
									</div>
								</div>
							</div>

							<div class="pb-2">
								<div class="field-title">SUÍTES</div>
								<div class="btn-group btn-selects">
									<div class="btn" *ngFor="let number of arrayNumber"
										 (click)="changeNumber('suites', number)"
										 [class.active]="number == this.filters.get('suites').value">
										{{number}}
									</div>
								</div>
							</div>

							<div class="pb-2">
								<div class="field-title">BANHEIROS</div>
								<div class="btn-group btn-selects">
									<div class="btn" *ngFor="let number of arrayNumber"
										 (click)="changeNumber('bathrooms', number)"
										 [class.active]="number == this.filters.get('bathrooms').value">
										{{number}}
									</div>
								</div>
							</div>

							<div class="pb-2">
								<div class="field-title">VAGAS</div>
								<div class="btn-group btn-selects">
									<div class="btn" *ngFor="let number of arrayNumber"
										 (click)="changeNumber('vacancies', number)"
										 [class.active]="number == this.filters.get('vacancies').value">
										{{number}}
									</div>
								</div>
							</div>

							<div class="pb-2">
								<div class="row p-0 m-0">
									<div class="col-6 p-1">
										<div class="input">
											<div class="field-container pr-sm-0">
												<input type="text" placeholder="0 m²" formControlName="area_gte"
													   appMaskNumber [decimals]="0" suffix=" m²">
												<div class="name">Área Minima</div>
											</div>
										</div>
									</div>
									<div class="col-6 p-1">
										<div class="input">
											<div class="field-container pr-sm-0">
												<input type="text" placeholder="ilimitado" formControlName="area_lte"
													   appMaskNumber [decimals]="0" suffix=" m²">
												<div class="name">Área Máximo</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="field-container pr-sm-0" *ngIf="!PARTNERSHIP">
								<input type="text" formControlName="address">
								<div class="name">ENDEREÇO</div>
							</div>

							<div class="field-container pr-sm-0" *ngIf="!PARTNERSHIP">
								<input type="text" formControlName="townhouse_name">
								<div class="name">NOME DO CONDOMÍNIO</div>
							</div>

							<!--<div class="field-container pr-sm-0" *ngIf="!PARTNERSHIP">-->
								<!--<input type="text" formControlName="contact.name">-->
								<!--<div class="name">PROPRIETÁRIO</div>-->
							<!--</div>-->

						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="select-propertie-action">
			<div class="d-flex">
				<div class="flex-grow-1">
					<div class="btn btn-cancel" (click)="cancelFilters()">
						Cancelar
					</div>
				</div>
				<div class="flex-grow-1">
					<div class="btn btn-apply" (click)="updateParams()">
						Buscar
					</div>
				</div>
			</div>
		</div>

	</div>
</div>
