import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {PropertiesService} from '../models/properties.service';
import {AgendaService} from '../models/agenda.service';

@Injectable()
export class AgendaViewResolver implements Resolve<any> {

    constructor(
        private agendaService: AgendaService
    ) {

    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.agendaService.get(route.params.id);
    }
}

