import {Injectable} from '@angular/core';
import {Helpers} from '../../services/helpers';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class RouterHistoric {

	static currentUrl = '/';
	static previousUrl = '/';

	constructor(
		private router: Router
	) {
		this.router.events
			.subscribe(e => {
				if (e instanceof NavigationEnd) {
					RouterHistoric.previousUrl = RouterHistoric.currentUrl;
					RouterHistoric.currentUrl = e.url;

					console.log(RouterHistoric.previousUrl);
				}
			});
	}

	static urlContain(contain: string) {
		return RouterHistoric.previousUrl.indexOf(contain) !== -1;
	}

	static lastUrl() {
		return RouterHistoric.previousUrl;
	}

}
