import { AbstractControl } from '@angular/forms';

export class AppValidators {

	static phone(control: AbstractControl) {
		if (control.value.length >= 10 && control.value.length <= 11) {
			return null;
		}
		return { invalidPhone: true };
	}

	static password(control: AbstractControl) {
		if (control.value.length >= 6 && control.value.length <= 12) {
			return null;
		}
		return { invalidPassword: true };
	}

	static passwordNotRequired(control: AbstractControl) {
		if (control.value.length === 0 || control.value.length >= 6 && control.value.length <= 12) {
			return null;
		}
		return { invalidPassword: true };
	}

}
