
<div class="page-background page-flex">

	<div class="flex-grow-0">
		<app-nav-bar [title]="'Gerenciar Temas'">
		</app-nav-bar>
	</div>

	<div class="max-width">
		<div class="card p-4">
			<div class="row">
				<div class="col-md-12">
					<div class="title-form">
						Tema do site
						<small>Escolha o tema do site da sua imobiliária.</small>
					</div>

					<div class="row">
						<div class="col-md-4 text-center" *ngFor="let theme of themeList">
							<div class="theme" [ngClass]="{'active': checkTheme(theme)}">
								<div class="name">{{theme.name}}</div>
								<div class="image">
									<img [src]="theme.image" alt="">
								</div>
								<div class="actions">
									<span><button class="btn btn-primary btn-round" (click)="openTheme(theme)">Pré-visualizar</button></span>
									<span><button class="btn btn-secundary btn-round" (click)="changeTheme(theme)">Definir</button></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
