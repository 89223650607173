<div class="modal-screen" *ngIf="openModal" [@ModalAnimation]="openModal">

    <div class="i-close" (click)="close()">
        <i class="fa fa-times" aria-hidden="true"></i>
    </div>

    <div class="modal-body">
        <div class="title">
            Filtrar Contatos
        </div>

        <form [formGroup]="filters" (submit)="clean()">
            <div class="row m-0">
                <div class="col-3">
                    <div class="field-container">
                        <input type="text" formControlName="cod">
                        <div class="name">Código</div>
                    </div>
                </div>

                <div class="col-9">
                    <div class="field-container">
                        <input type="text" formControlName="name">
                        <div class="name">NOME COMPLETO</div>
                    </div>
                </div>
            </div>

            <div class="row m-0">
                <div class="col-6">
                    <label class="select-container">
                        <select formControlName="type">
                            <option [value]="''">Todos</option>
                            <option [value]="3">Venda</option>
                            <option [value]="8">Locação</option>
                        </select>
                        <div class="name">Finalidade</div>
                    </label>
                </div>

                <div class="col-6">
                    <div class="field-container pr-sm-0">
                        <input type="text" formControlName="price_lte">
                        <div class="name">Valor Buscado</div>
                    </div>
                </div>
            </div>

            <div class="row m-0">
                <div class="col-12">
                    <div class="name-input">Tipos de Imóvel</div>
                    <div class="row" formArrayName="ps_types">
                        <div class="col-md-3" *ngFor="let type of filters.get('ps_types').controls; let x = index">
                            <label class="checkbox-container clean">
                                <input type="checkbox" [formControlName]="x">
                                <div class="checkbox"></div>
                                <span class="text">{{ types_properties[x].name }}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row m-0">
                <div class="col">
                    <div class="select-container">
                        <select type="text" formControlName="ps_estate">
                            <option
                                [value]="''">
                                Todos
                            </option>
                            <option
                                [value]="estate.uf"
                                *ngFor="let estate of estates">
                                {{ estate.name }}
                            </option>
                        </select>
                        <div class="name">Estado</div>
                    </div>
                </div>

                <div class="col">
                    <div class="field-container pr-sm-0">
                        <input type="text" formControlName="ps_city">
                        <div class="name">Cidade</div>
                    </div>
                </div>

                <div class="col">
                    <div class="field-container pr-sm-0">
                        <input type="text" formControlName="ps_neighborhood">
                        <div class="name">Bairro</div>
                    </div>
                </div>
            </div>

            <div class="text-center pt-2">
                <button class="btn btn-primary" (click)="updateChange()" type="button">
                    Buscar
                </button>
            </div>
        </form>

    </div>
</div>
