<div class="modal-screen" *ngIf="openModal" [@EnterLeave]="openModal">

	<div class="modal-body d-flex">

		<div class="header-search-property">
			<div class="i-close" (click)="close()">
				<div class="icon-close-only black s32x32 display-close"></div>
				<img src="/assets/svg/home.svg" class="display-back">
			</div>

			<div class="title">
				{{title}}
			</div>

			<div class="modal-box-text">
				<ng-content></ng-content>
			</div>
		</div>

	</div>
</div>
