var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { EventEmitter, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PlaySound } from "../../../../../services/PlaySound";
import { ToastrService } from "ngx-toastr";
import { ContactsInteractionsService } from "../../../../models/contacts-interactions.service";
import { TitleService } from "src/app/services/title.service";
import { ContactInteractionsSharedService } from "src/app/content/shareds/contact-interactions-shared.service";
var ContactsViewEditInteractionsComponent = /** @class */ (function () {
    function ContactsViewEditInteractionsComponent(route, _fb, router, toastr, contactsInteractionsService, _titleService, _contactsSharedService) {
        this.route = route;
        this._fb = _fb;
        this.router = router;
        this.toastr = toastr;
        this.contactsInteractionsService = contactsInteractionsService;
        this._titleService = _titleService;
        this._contactsSharedService = _contactsSharedService;
        this.saved = new EventEmitter();
        this.formSubmitAttempt = false;
        this.contactID = 0;
        this.openModal = false;
        // visitType:  'not_like' | 'thinking' | 'reasonable' | 'like' | '' = '';
        // typeCommitment: 'visit' | 'meeting' | 'email' | 'call' | 'after_sales' | '' = '';
        this.visitType = "";
        this.typeCommitment = "";
    }
    ContactsViewEditInteractionsComponent.prototype.ngOnInit = function () {
        this.contactID = this.route.parent.snapshot.params.id;
        this.contactsInteractionsService.setContact(this.contactID);
        var contactName = this.route.parent.snapshot.data.contact.name;
        this.interaction = this.route.snapshot.data.interaction;
        if (!!this.interaction.commitment) {
            this.typeCommitment = this.interaction.commitment.type;
        }
        this._titleService.setTitle(contactName + " - Editar Intera\u00E7\u00E3o");
        this.selectForm();
    };
    ContactsViewEditInteractionsComponent.prototype.save = function () {
        var _this = this;
        this.formSubmitAttempt = false;
        /**
         * Formulário com erros
         */
        if (!this.form.valid) {
            this.formSubmitAttempt = true;
            PlaySound.play("error");
            this.toastr.error("Preencha os campos corretamente.");
            return false;
        }
        var data = __assign({ type: this.interaction.type }, this.form.value);
        switch (this.interaction.type) {
            case "properties_visit":
                data["propertie_opinion"] = this.visitType;
                data["propertie_id"] = 1; // REVIEW: Retirar property_id
                break;
            case "commitment":
                data = {};
                data["commitment"] = __assign({}, this.form.value);
                data["type"] = this.interaction.type;
                data["commitment"]["all_day"] = data["commitment"]["all_day"]
                    ? 1
                    : 0;
                data["commitment"]["realized"] = data["commitment"]["realized"]
                    ? 1
                    : 0;
                break;
            case "task":
                data = {};
                data["task"] = __assign({}, this.form.value);
                data["type"] = this.interaction.type;
                data["task"]["all_day"] = data["task"]["all_day"] ? 1 : 0;
                data["task"]["realized"] = data["task"]["realized"] ? 1 : 0;
                break;
            case "contact_nota":
                break;
        }
        return this.contactsInteractionsService
            .update(this.interaction.id, data)
            .subscribe(function (res) {
            _this.toastr.success("Interação atualizada com sucesso!");
            _this.close();
            _this._contactsSharedService.refresh();
        }, function (err) {
            _this.toastr.error("Erro ao criar interação.");
        });
    };
    /**
     * Verifica se campo é valido
     * @param {string} field
     * @returns {boolean}
     */
    ContactsViewEditInteractionsComponent.prototype.isFieldValid = function (field, parent) {
        if (!!parent) {
            return (!this.form.get(parent).get(field).valid &&
                this.formSubmitAttempt);
        }
        else {
            return !this.form.get(field).valid && this.formSubmitAttempt;
        }
    };
    ContactsViewEditInteractionsComponent.prototype.buildFormNote = function () {
        this.form = this._fb.group({
            message: [this.interaction.message, Validators.required],
            type: [this.interaction.type, Validators.required]
        });
    };
    ContactsViewEditInteractionsComponent.prototype.buildFormVisit = function () {
        this.form = this._fb.group({
            message: [this.interaction.message],
            type: [this.interaction.type, Validators.required],
            propertie_opinion: [this.interaction.propertie_id]
        });
    };
    ContactsViewEditInteractionsComponent.prototype.builFormCommitment = function () {
        var commitment = this.interaction.commitment;
        this.form = this._fb.group({
            name: [commitment.name, Validators.required],
            start_at: [commitment.start_at, Validators.required],
            description: [commitment.description],
            realized: [commitment.realized],
            all_day: [commitment.all_day],
            shared_users: [commitment.shared_users_data],
            type: [commitment.type]
        });
    };
    ContactsViewEditInteractionsComponent.prototype.buildFormTask = function () {
        var task = this.interaction.task;
        this.form = this._fb.group({
            name: [task.name, Validators.required],
            all_day: [task.all_day],
            deadline_at: [task.deadline_at],
            comments: [task.comments],
            realized: [task.realized]
        });
    };
    ContactsViewEditInteractionsComponent.prototype.allDay = function (event) {
        this.form.get("all_day").setValue(event);
    };
    ContactsViewEditInteractionsComponent.prototype.setVisitType = function (type) {
        this.visitType = type;
        this.form.patchValue({ propertie_opinion: type });
    };
    ContactsViewEditInteractionsComponent.prototype.setCommitType = function (type) {
        this.typeCommitment = type;
        this.form.patchValue({ type: type });
    };
    ContactsViewEditInteractionsComponent.prototype.close = function () {
        if (this.router.url.indexOf("sales-funnel") !== -1) {
            this.router.navigateByUrl("/sales-funnel/list/contacts/view/" + this.contactID);
        }
        else {
            this.router.navigateByUrl("/contacts/view/" + this.contactID);
        }
    };
    ContactsViewEditInteractionsComponent.prototype.checkTab = function (tab) {
        return tab === this.interaction.type;
    };
    ContactsViewEditInteractionsComponent.prototype.selectForm = function () {
        delete this.form;
        switch (this.interaction.type) {
            case "contact_nota":
                this.buildFormNote();
                break;
            case "properties_visit":
                this.buildFormVisit();
                break;
            case "commitment":
                this.builFormCommitment();
                break;
            case "task":
                this.buildFormTask();
                break;
        }
    };
    return ContactsViewEditInteractionsComponent;
}());
export { ContactsViewEditInteractionsComponent };
