import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscribable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {ResponseTreatment} from '../../services/response-treatment';
import {Users} from '../interfaces/users';
import {CrudManager} from '../../services/crud-manager';

@Injectable({
  providedIn: 'root'
})
export class LocationCitiesService extends CrudManager {
    path = '/admin/locations/cities';

    constructor() {
        super();
    }
}
