import {Component, Input, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
	selector: 'app-loading-page',
	templateUrl: './loading-page.component.html',
	styleUrls: ['./loading-page.component.scss'],
	animations: [
		trigger('EnterLeave', [
			transition(':enter', [
				style({ opacity: '0.0' }),
				animate('0.3s ease-out', style({ opacity: '1.0' }))
			]),
			transition(':leave', [
				animate('0.3s ease-out', style({ opacity: '0.0' }))
			])
		])
	]
})
export class LoadingPageComponent implements OnInit {

	@Input() show = false;
	@Input() opacity = '1.0';

	constructor() {
	}

	ngOnInit() {
	}

}
