/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-page-mobile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../nav-bar/nav-bar.component.ngfactory";
import * as i3 from "../nav-bar/nav-bar.component";
import * as i4 from "../../shareds/global.service";
import * as i5 from "@angular/common";
import * as i6 from "./modal-page-mobile.component";
import * as i7 from "../../shareds/modal-page-mobile.service";
var styles_ModalPageMobileComponent = [i0.styles];
var RenderType_ModalPageMobileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalPageMobileComponent, data: { "animation": [{ type: 7, name: "ModalAnimation", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: "0.0", transform: "translateY(100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: "1.0", transform: "translateY(0%)" }, offset: null }, timings: "0.25s ease-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: "0.0", transform: "translateY(100%)" }, offset: null }, timings: "0.25s ease-out" }], options: null }], options: {} }, { type: 7, name: "FadeIn", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: "0.0" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: "1.0" }, offset: null }, timings: "0.25s ease-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { opacity: "1.0" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: "0.0" }, offset: null }, timings: "0.25s ease-out" }], options: null }], options: {} }] } });
export { RenderType_ModalPageMobileComponent as RenderType_ModalPageMobileComponent };
function View_ModalPageMobileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ModalPageMobileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "page-screen change-mobile-page"]], [[24, "@ModalAnimation", 0], [24, "@FadeIn", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-nav-bar", [], null, [[null, "backEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backEvent" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NavBarComponent_0, i2.RenderType_NavBarComponent)), i1.ɵdid(3, 114688, null, 0, i3.NavBarComponent, [i4.GlobalService], { title: [0, "title"], backIcon: [1, "backIcon"] }, { backEvent: "backEvent" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPageMobileComponent_2)), i1.ɵdid(5, 540672, null, 0, i5.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.title; var currVal_3 = true; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v.parent, 4); _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.animationBottom(); var currVal_1 = _co.animationFadeIn(); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ModalPageMobileComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ModalPageMobileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "page-screen change-mobile-page"]], [[24, "@ModalAnimation", 0], [24, "@FadeIn", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "overflow"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPageMobileComponent_4)), i1.ɵdid(3, 540672, null, 0, i5.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_2 = i1.ɵnov(_v.parent, 4); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.animationBottom(); var currVal_1 = _co.animationFadeIn(); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ModalPageMobileComponent_5(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_ModalPageMobileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPageMobileComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPageMobileComponent_3)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["content", 2]], null, 0, null, View_ModalPageMobileComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.openPage && !_co.clean); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.openPage && _co.clean); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ModalPageMobileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-page-mobile", [], null, null, null, View_ModalPageMobileComponent_0, RenderType_ModalPageMobileComponent)), i1.ɵdid(1, 245760, null, 0, i6.ModalPageMobileComponent, [i4.GlobalService, i7.ModalPageMobileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalPageMobileComponentNgFactory = i1.ɵccf("app-modal-page-mobile", i6.ModalPageMobileComponent, View_ModalPageMobileComponent_Host_0, { title: "title", name: "name", clean: "clean", animation: "animation" }, {}, ["*"]);
export { ModalPageMobileComponentNgFactory as ModalPageMobileComponentNgFactory };
