/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./modal.component";
import * as i4 from "../../shareds/modal-contact-duplicate.service";
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: { "animation": [{ type: 7, name: "EnterLeave", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: "0.0" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: "1.0" }, offset: null }, timings: "0.3s ease-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: "0.0" }, offset: null }, timings: "0.3s ease-out" }], options: null }], options: {} }] } });
export { RenderType_ModalComponent as RenderType_ModalComponent };
function View_ModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "modal-screen"]], [[24, "@EnterLeave", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "modal-body d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "header-search-property"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "i-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "icon-close-only black s32x32 display-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "display-back"], ["src", "/assets/svg/home.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "modal-box-text"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.openModal; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 7, 0, currVal_1); }); }
export function View_ModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.openModal; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModalComponent, [i4.ModalContactDuplicateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalComponentNgFactory = i1.ɵccf("app-modal", i3.ModalComponent, View_ModalComponent_Host_0, { id: "id", error: "error", title: "title", openModal: "openModal" }, { openModalChange: "openModalChange" }, ["*"]);
export { ModalComponentNgFactory as ModalComponentNgFactory };
