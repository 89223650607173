import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ModalContactDuplicateService } from '../../shareds/modal-contact-duplicate.service';
import { PlaySound } from '../../../services/PlaySound';

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
	animations: [
		trigger('EnterLeave', [
			transition(':enter', [
				style({ opacity: '0.0' }),
				animate('0.3s ease-out', style({ opacity: '1.0' }))
			]),
			transition(':leave', [
				animate('0.3s ease-out', style({ opacity: '0.0' }))
			])
		])
	]
})
export class ModalComponent implements OnInit {

	@Input() id: string;
	@Input() error = false;
	@Input() title: string;
	@Input() openModal = false;
	@Output() openModalChange = new EventEmitter<boolean>();

	constructor(
		private modalContactDuplicateService: ModalContactDuplicateService
	) {
	}

	ngOnInit() {
		this.modalContactDuplicateService.add(this);
	}

	open() {
		this.openModal = true;
		this.openModalChange.emit(true);
		if (this.error) {
			PlaySound.play('error');
		}
	}

	close() {
		this.openModal = false;
		this.openModalChange.emit(false);
	}

}
