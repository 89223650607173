import * as i0 from "@angular/core";
var ModalContactDuplicateService = /** @class */ (function () {
    function ModalContactDuplicateService() {
        this.modals = [];
    }
    ModalContactDuplicateService.prototype.add = function (modal) {
        // add modal to array of active modals
        this.modals.push(modal);
    };
    ModalContactDuplicateService.prototype.remove = function (id) {
        // remove modal from array of active modals
        this.modals = this.modals.filter(function (x) { return x.id !== id; });
    };
    ModalContactDuplicateService.prototype.open = function (id) {
        console.log(id);
        console.log(this.modals);
        // open modal specified by id
        var modal = this.modals.filter(function (x) { return x.id === id; })[0];
        if (modal) {
            modal.open();
        }
    };
    ModalContactDuplicateService.prototype.close = function (id) {
        // close modal specified by id
        var modal = this.modals.filter(function (x) { return x.id === id; })[0];
        if (modal) {
            modal.close();
        }
    };
    ModalContactDuplicateService.ngInjectableDef = i0.defineInjectable({ factory: function ModalContactDuplicateService_Factory() { return new ModalContactDuplicateService(); }, token: ModalContactDuplicateService, providedIn: "root" });
    return ModalContactDuplicateService;
}());
export { ModalContactDuplicateService };
