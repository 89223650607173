import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Global} from './global';
import {CookieService} from 'ngx-cookie-service';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

	constructor(
		private cookieService: CookieService,
		private _router: Router,
	) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


		let token = this.cookieService.get('_UID_');

		let request = req.clone({
			setHeaders: {
				'Authorization': 'Bearer ' + token
			}
		});

		// return next.handle(request);

		/**
		 * continues request execution
		 */
		return next.handle(request).pipe(catchError((error, caught) => {
			this.handleAuthError(error);
			return of(error);
		}) as any);
	}

	/**
	 * manage errors
	 * @param err
	 * @returns {any}
	 */
	private handleAuthError(err: HttpErrorResponse): Observable<any> {

		if (err.error.code === 3 && Global.userLogged) {
			this._router.navigate(['/login']);
		}

		throw err;
	}
}
