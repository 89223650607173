import { AuthService } from './../../../services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-logout-page',
	templateUrl: './logout-page.component.html',
	styleUrls: ['./logout-page.component.scss']
})
export class LogoutPageComponent implements OnInit {

	constructor(private _authService: AuthService) {
	}

	ngOnInit() {
		this._authService.logout();
	}

}
