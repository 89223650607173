import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {PropertiesService} from '../models/properties.service';
import {UsersService} from '../models/users.service';

@Injectable()
export class UsersViewResolver implements Resolve<any> {

	constructor(
		private usersService: UsersService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {
		return this.usersService.get(route.params.id);
	}
}

