import { ActivatedRoute } from '@angular/router';
import { PlaySound } from './../../../../../services/PlaySound';
import { LocationSubneighborhoodsService } from './../../../../models/location-subneighborhoods.service';
import { LocationNeighborhoodsService } from './../../../../models/location-neighborhoods.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
	selector: 'app-sub-neighborhood-add',
	templateUrl: './sub-neighborhood-add.component.html',
	styleUrls: ['./sub-neighborhood-add.component.scss']
})
export class SubNeighborhoodAddComponent implements OnInit {

	form: FormGroup;
	formSubmitAttempt = false;

	constructor(
		private fb: FormBuilder,
		private _subNeighEqService: LocationSubneighborhoodsService,
		private toastr: ToastrService,
		private route: ActivatedRoute,
		private location: Location
	) {

	}

	ngOnInit() {
		this.form = this.fb.group({
			neighborhood_id: ['', [Validators.required]],
			name: ['', [Validators.required]],
		});

		if (!!this.route.snapshot.queryParams.neighborhood) {
			this.form.get('name').setValue(this.route.snapshot.queryParams.neighborhood);
		}
	}

	getNeigh(neighborhood: any) {
		this.form.get('neighborhood_id').setValue(neighborhood.id);
	}

	save(addNew?: boolean) {
		let data = this.form.value;
		this.formSubmitAttempt = true;


		if (!this.form.valid) {
			PlaySound.play('error');
			this.toastr.error('Preencha os campos corretamente.');
			return false;
		}

		return this._subNeighEqService
			.create(this.form.value)
			.subscribe(res => {
				this.toastr.success('Sub-bairro criado com sucesso');
				this.backUrl();
				// if (addNew) {
				// 	this.form.reset();

				// } else {

				// }
			}, err => {
				if(err.message) {
					alert(err.message);
				}
			});
	}

	/**
     * Verifica se campo é valido
     * @param {string} field
     * @returns {boolean}
     */
	isFieldValid(field: string) {
		return (!this.form.get(field).valid && this.formSubmitAttempt);
	}

	backUrl() {
		this.location.back();
	}
}
