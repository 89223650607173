import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {TasksService} from '../models/tasks.service';
import {Injectable} from '@angular/core';
import {TicketsService} from '../models/tickets.service';
import {IndexedDB} from '../../services/IndexedDB';
import {Observable} from 'rxjs';
import {Global} from '../../services/global';

@Injectable()
export class PartnershipResolver implements Resolve<any> {

	constructor(
		private ticketsService: TicketsService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {

		return Observable.create(obs => {
			this.search(route).subscribe(data => {
				// db2.putValue('tickets' + Global.user.id, data).subscribe(() => {});
				obs.next(data);
				obs.complete();
			});

			// new IndexedDB('cached')
			// 	.connect()
			// 	.subscribe((db2: IndexedDB) => {

			// 		db2.getValue('tickets' + Global.user.id)
			// 			.subscribe((success: any) => {

			// 				let body = success.data;
			// 				let last = false;

			// 				try {
			// 					last = body.last_updated;
			// 				} catch (e) {

			// 				}

			// 				if (last) {
			// 					body.cached = true;
			// 					obs.next(body);
			// 					obs.complete();
			// 				} else {
			// 					this.search(route).subscribe(data => {
			// 						db2.putValue('tickets' + Global.user.id, data).subscribe(() => {});
			// 						obs.next(data);
			// 						obs.complete();
			// 					});
			// 				}
			// 			}, err => {
			// 				this.search(route).subscribe(data => {
			// 					db2.putValue('tickets' + Global.user.id, data).subscribe(() => {});
			// 					obs.next(data);
			// 					obs.complete();
			// 				});
			// 			} );

			// 	}, err => {
			// 		this.search(route).subscribe(data => {
			// 			obs.next(data);
			// 			obs.complete();
			// 		});
			// 	} );
		});
	}


	search(route: ActivatedRouteSnapshot) {
		let filters: any = this.ticketsService.processFilters(route.queryParams);
		console.log(filters);

		if (!route.queryParams.closed) {
			filters.closed = 0;
		}
		filters.last_updated = 1;

		return this.ticketsService.search(this.ticketsService.processFilters(filters));
	}
}
