import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	NgZone, OnDestroy,
	OnInit,
	QueryList,
	ViewChild
} from '@angular/core';
import { PropertiesService } from "../../models/properties.service";
import { FunnelSalesService } from "../../models/funnel-sales.service";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { Properties } from "../../interfaces/properties";
import { SortablejsOptions } from "angular-sortablejs";
import { Helpers } from "../../../services/helpers";
import { Global } from "../../../services/global";
import { FunnelSalesSteps } from "../../interfaces/funnel-sales-steps";
import { ContactsService } from "../../models/contacts.service";
import { skip } from "rxjs/operators";
import { ModalContactDuplicateService } from "../../shareds/modal-contact-duplicate.service";
import { environment } from "../../../../environments/environment";

@Component({
	selector: "app-sales-funnel-list",
	templateUrl: "./sales-funnel-list.component.html",
	styleUrls: ["./sales-funnel-list.component.scss"]
})
export class SalesFunnelListComponent implements OnInit, OnDestroy {
	@ViewChild("funnelContainer") elFunnelContainerRef: ElementRef;
	@ViewChild("inputNameStep") inputNameStepEl: ElementRef;

	optionsSortable: SortablejsOptions;
	optionsSortableContacts: SortablejsOptions;

	steps: FunnelSalesSteps[] = [];

	createrStep = false;
	creatingStep = false;
	nameNewStep: string;
	searching = false;
	filtersSearch = {};
	currentFilterSearch: FunnelSalesSteps;

	MASTER = false;
	MOBILE = false;
	current_user: any;
	users: any;
	responsable_user: any;

	subParams: any;
	subParams2: any;

	start = false;
	colorDrop: number;

	// Contato selecionados
	contactsSelected: Array<number> = [];

	/**Adiciona acoes */
	actions = [
		{
			id: "chat",
			body: '<i class="far fa-handshake"></i>'
		}
	];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private ngZone: NgZone,
		private changeDetectorRef: ChangeDetectorRef,
		private contactsService: ContactsService,
		public modalContactDuplicateService: ModalContactDuplicateService,
		private funnelSalesService: FunnelSalesService
	) { }

	ngOnInit() {
		this.updateLoadSteps(this.route.snapshot.data.steps);

		this.users = Global.info.users_admin;
		this.current_user = Global.user;

		this.responsable_user =
			this.route.snapshot.queryParams["responsable_user"] ||
			Global.user.id;

		// Configurações do Sortable
		this.optionsSortable = {
			disabled: environment.mobile,
			group: "steps",
			animation: 0,
			scroll: true,
			handle: ".step-header",
			ghostClass: "step-selected",
			dragClass: "step-dragged",
			onMove: (evt: any) => {
				console.log("list - " + evt.draggedRect);
				return true;
			},
			onUpdate: event => {
				this.sortableStepUpdate(event);
			}
		};

		this.MOBILE = environment.mobile;

		this.optionsSortableContacts = {
			disabled: environment.mobile,
			animation: 0,
			scroll: true,
			scrollSensitivity: 500,
			scrollSpeed: 1,
			group: "contacts",
			ghostClass: "card-selected",
			dragClass: "card-dragged",
			onEnd: event => {
				this.sortableContactUpdate(event);
			}
		};

		this.ngZone.run(() => {
			this.start = true;
		});

		/**
		 * Query subscribe
		 * @type {Subscription}
		 */
		this.subParams = this.route.queryParams
			.pipe(skip(1))
			.subscribe(data => {
				this.responsable_user =
					data["responsable_user"] || Global.user.id;
				this.responsable_user = Math.floor(this.responsable_user);

				return this.loadSteps({
					root: {
						with_contacts: true
					},
					realtors_managers_in: [this.responsable_user]
				});
			});

		this.subParams2 = this.updateOnReturn();
	}

	/**
	 * Carregar etapas
	 * @param steps
	 */
	updateLoadSteps(steps: any) {
		this.steps = steps;

		for (let i in this.steps) {
			this.steps[i].filters = {
				offset: 0
			};
		}
	}

	/**
	 * Abre o drop de cores
	 *
	 * @param {number} index
	 * @memberof SalesFunnelListComponent
	 */
	opendDropColor(index: number) {
		if (index === this.colorDrop) {
			this.colorDrop = undefined;
		} else {
			this.colorDrop = index;
		}
	}

	/**
	 * Fecha o drop de cores
	 *
	 * @memberof SalesFunnelListComponent
	 */
	closeColorDrop(index: number) {
		if (index === this.colorDrop) {
			this.colorDrop = undefined;
		}
	}

	/**
	 * Muda a cor do step funnel
	 *
	 * @param {number} id
	 * @param {string} color
	 * @memberof SalesFunnelListComponent
	 */
	changeColor(step: FunnelSalesSteps, color: string) {
		return this.funnelSalesService
			.update(step.id, {
				color
			})
			.subscribe(res => {
				console.log(
					"TCL: SalesFunnelListComponent -> changeColor -> res",
					res
				);
				step["color"] = color;
			});
	}

	/**
	 * Busca valor de key nas array das etapas pelo ID
	 * @param {number} id
	 * @returns {number}
	 */
	searchStepByID(id: number) {
		for (let i in this.steps) {
			let step = this.steps[i];

			if (id === Number(step.id)) {
				return Number(i);
			}
		}

		return -1;
	}

	/**
	 * Carregar etapas
	 * @param data
	 * @returns {Subscription}
	 */
	loadSteps(data: any) {
		this.searching = true;

		this.deselectAll();

		return this.funnelSalesService.search(data).subscribe(steps => {
			this.updateLoadSteps(steps);
			this.searching = false;
		});
	}

	/**
	 * Buscar contatos
	 * @param data
	 * @param {number} stepID
	 * @param {boolean} searching
	 * @returns {Subscription}
	 */
	searchContacts(data: any, stepID: number, searching: boolean = true) {
		let stepIndex = this.searchStepByID(stepID);
		this.steps[stepIndex].searching = true;
		this.steps[stepIndex].filters = data;

		return this.contactsService.search(data).subscribe(contacts => {
			let _stepIndex = this.searchStepByID(stepID);
			this.steps[_stepIndex].search = searching;
			this.steps[_stepIndex].searching = false;
			this.steps[_stepIndex].contacts.results = contacts.results;
			this.steps[_stepIndex].contacts.search_count = contacts.count;
		});
	}

	loadMoreContactsStep(step: FunnelSalesSteps) {
		let indexStep = this.searchStepByID(step.id);
		//
		this.steps[indexStep].filters.offset += 20;

		// Etapa do funil
		this.steps[indexStep].filters.funnel_step = step.id;

		// Reponsável pelo ticket
		this.steps[indexStep].filters.realtors_managers_in = [
			this.responsable_user
		];

		// Ordem do funil
		this.steps[indexStep].filters.sort =
			"order_funnel.user_" + this.responsable_user;

		// Limite
		this.steps[indexStep].filters.limit = 20;

		this.steps[indexStep].load_more_contacts = true;

		return this.contactsService
			.search(this.steps[indexStep].filters)
			.subscribe(contacts => {
				let _stepIndex = this.searchStepByID(step.id);
				this.steps[_stepIndex].load_more_contacts = false;
				this.steps[_stepIndex].contacts.results = this.steps[
					_stepIndex
				].contacts.results.concat(contacts.results);
			});
	}

	/**
	 * Limpa Busca de etapa
	 */
	cleanSearch(step: FunnelSalesSteps) {
		let data: any = {};

		// Etapa do funil
		data.funnel_step = this.currentFilterSearch.id;

		// Reponsável pelo ticket
		data.realtors_managers_in = [this.responsable_user];

		// Zera offset
		data.offset = 0;

		data.sort = "order_funnel.user_" + this.responsable_user;

		return this.searchContacts(data, step.id, false);
	}

	/**
	 * Filtrar Contatos em determinada etapa
	 * Abri filtro de contatos para etapa
	 * @param {FunnelSalesSteps} step
	 */
	openFilter(step: FunnelSalesSteps) {
		this.currentFilterSearch = step;
		this.modalContactDuplicateService.open("contacts-search");
	}

	/**
	 * Retorno dos filtros para busca
	 * @param data
	 */
	updateFilterSearch(data: any) {
		// Etapa do funil
		data.funnel_step = this.currentFilterSearch.id;

		// Reponsável pelo ticket
		data.realtors_managers_in = [this.responsable_user];

		// Zera offset
		data.offset = 0;

		return this.searchContacts(data, this.currentFilterSearch.id);
	}

	/**
	 * Update Sortable Step
	 * @param event
	 */
	sortableStepUpdate(event: any) {
		return this.funnelSalesService
			.sortableStep(this.steps[event.newIndex].id, event.newIndex)
			.subscribe(res => {
				console.log(res);
			});
	}

	/**
	 * Criar Etapa
	 * @returns {boolean}
	 */
	createStep() {
		if (!Helpers.empty(this.nameNewStep)) {
			if (this.creatingStep) {
				return false;
			}

			this.creatingStep = true;

			this.funnelSalesService
				.create({
					step: this.nameNewStep
				})
				.subscribe(res => {
					this.createrStep = false;
					this.creatingStep = false;

					res.contacts = {
						count: 0,
						results: []
					};

					this.steps.push(res);

					this.changeDetectorRef.detectChanges();

					this.elFunnelContainerRef.nativeElement.scrollLeft = this.elFunnelContainerRef.nativeElement.scrollWidth;
				});

			this.nameNewStep = "";
		}
	}

	/**
	 * Editar nome da etapa
	 * @param {FunnelSalesSteps} step
	 */
	editNameStep(step: FunnelSalesSteps) {
		let indexStep = this.searchStepByID(step.id);
		this.steps[indexStep].edit = true;
		this.changeDetectorRef.detectChanges();

		setTimeout(() => this.inputNameStepEl.nativeElement.focus());
	}

	/**
	 * Editar nome da etapa
	 * @param {FunnelSalesSteps} step
	 */
	changeNameStep(step: FunnelSalesSteps) {
		this.closeEditNameStep(step);

		return this.funnelSalesService
			.update(step.id, {
				name: step.name
			})
			.subscribe(res => { });
	}

	/**
	 * Fechar Edit Name
	 * @param {FunnelSalesSteps} step
	 */
	closeEditNameStep(step: FunnelSalesSteps) {
		let indexStep = this.searchStepByID(step.id);
		this.steps[indexStep].edit = false;
	}

	/**
	 * Update Sortable Step
	 * @param event
	 */
	sortableContactUpdate(event: any) {
		this.changeDetectorRef.detectChanges();
		let stepNew = this.searchStepByID(Math.floor(event.to.id));
		let stepOld = this.searchStepByID(Math.floor(event.from.id));
		let oldIndex = event.oldIndex;
		let newIndex = event.newIndex;

		let contact = this.steps[stepNew].contacts.results[newIndex];

		this.steps[stepNew].filters.offset++;
		this.steps[stepOld].filters.offset--;

		this.steps[stepNew].contacts.count++;
		this.steps[stepOld].contacts.count--;

		if (this.steps[stepNew].search) {
			this.steps[stepNew].filters.offset--;
			this.steps[stepNew].contacts.count--;
			this.steps[stepNew].contacts.results.splice(newIndex, 1);
			newIndex = 0;
		}

		this.changeDetectorRef.detectChanges();

		return this.funnelSalesService
			.sortableContact(
				contact.id,
				this.steps[stepNew].id,
				newIndex,
				this.responsable_user
			)
			.subscribe(res => {
				console.log(res);
			});
	}

	/**
	 * Update Sortable Step
	 * @param event
	 */
	setDownContact(stepId, indexId, contactId) {
		this.steps[stepId].contacts.results.splice(indexId, 1);

		return this.funnelSalesService
			.sortableContact(contactId, stepId, 1000000, this.responsable_user)
			.subscribe(res => {
				console.log(res);
			});
	}

	/**
	 * Quando o usuário retorna para o funil o mesmo é atualizado
	 *
	 * @returns
	 * @memberof SalesFunnelListComponent
	 */
	updateOnReturn() {
		return this.router.events.subscribe((route) => {
			// see also
			if (route instanceof NavigationEnd) {
				if (route.url === "/sales-funnel/list") {

					return this.loadSteps({
						root: {
							with_contacts: true
						},
						realtors_managers_in: [this.responsable_user]
					});
				}
			}
		});
	}

	/**
	 * Mudar Usuário Responsável
	 */
	changeUsers() {
		this.router.navigate([], {
			queryParams: {
				responsable_user: this.responsable_user
			}
		});
	}

	/**
	 *
	 * @param {number} index
	 * @param {number} stepIndex
	 * @param {number} order
	 */
	setOrderContact(index: number, stepIndex: number, order: number) {
		let contact = this.steps[stepIndex].contacts.results[index];
		this.steps[stepIndex].contacts.results.splice(index, 1);
		this.steps[stepIndex].contacts.results.splice(order, 0, contact);
	}

	/**
	 * Busca valor de key nas array de contatos pelo ID
	 * @param {number} id
	 * @returns {number}
	 */
	searchContactByID(id: number, indexStep: number) {
		for (let i in this.steps[indexStep].contacts.results) {
			let contact = this.steps[indexStep].contacts.results[i];

			if (id === Number(contact.id)) {
				return Number(i);
			}
		}

		return -1;
	}

	checkContact(id: number, checked: boolean) {
		if (checked) {
			this.contactsSelected.push(id);
		} else {
			let index = this.contactsSelected.indexOf(id);
			if (index !== -1) {
				this.contactsSelected.splice(index, 1);
			}
		}
	}

	setAction(action: any) {
		if (action.id === "chat") {
			if (this.contactsSelected.length > 0) {
				this.createPartners();
			}
		}
	}

	createPartners() {
		this.router.navigate(
			["", { outlets: { modal: "add-chat-partners" } }],
			{
				queryParams: {
					contacts_to_tickets: this.contactsSelected
				}
			}
		);
	}

	deselectAll() {
		this.contactsSelected = [];

		for (let i in this.steps) {
			for (let x in this.steps[i].contacts.results) {
				this.steps[i].contacts.results[x].checked = false;
			}
		}
	}

	/**
	 * Favoritar Contatos
	 */
	favoriteContact(id: number, indexStep: number, favorite: boolean) {
		let index = this.searchContactByID(id, indexStep);

		this.steps[indexStep].contacts.results[index].favorite = favorite;

		// Coloca no topo da lista
		if (favorite) {
			this.setOrderContact(index, indexStep, 0);
		}

		this.contactsService
			.favorite({
				ids: id,
				status: favorite ? 1 : 0,
				first_order: true
			})
			.subscribe(contact => { });
	}

	/**
	 * Arquivar Contato
	 */
	archiveContact(id: number, indexStep: number, archive: boolean) {
		let index = this.searchContactByID(id, indexStep);

		this.steps[indexStep].contacts.results[index].favorite = archive;

		this.contactsService
			.update(id, {
				archive: archive ? 1 : 0
			})
			.subscribe(contact => { });
	}

	ngOnDestroy() {

		if(this.subParams) {
			this.subParams.unsubscribe();
		}

		if(this.subParams2) {
			this.subParams2.unsubscribe();
		}
	}
}
