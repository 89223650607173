<div class="modal-screen modal-select-properties change-mobile-page">

	<div class="modal-body d-flex">

		<app-nav-bar [title]="'Criar Compromisso'" (backEvent)="close()" [backIcon]="true">
		</app-nav-bar>

		<div class="header-page hide-mobile">
			<div class="i-close" (click)="close()">
				<div class="icon-close-only black s32x32 display-close"></div>
				<img src="assets/svg/home.svg" class="display-back">
			</div>

			<div class="title">
				Criar Compromisso
			</div>
		</div>

		<div class="search-list-container">
			<form [formGroup]="form" (submit)="clean()">
				<div class="row m-0">
					<div class="col-12">
						<div class="field-container" [class.error-input]="isFieldValid('name')">
							<input type="text" formControlName="name">
							<div class="name">Nome do evento</div>
						</div>

						<app-field-error-display [displayError]="isFieldValid('name')"
							errorMsg="Informe o nome do evento">
						</app-field-error-display>
					</div>
				</div>

				<div class="row m-0">
					<div class="col-12 pb-2">

						<div class="name-input">Tipo do Compromisso</div>

						<div class="btn-group-checked btn-group-border">
							<div class="btn" [class.active]="type == 'visit'" (click)="setType('visit')">
								<i class="fas fa-map-marker-alt"></i> Visita
							</div>
							<div class="btn" [class.active]="type == 'meeting'" (click)="setType('meeting')">
								<i class="fas fa-users"></i> Reunião
							</div>
							<div class="btn" [class.active]="type == 'email'" (click)="setType('email')">
								<i class="fas fa-envelope"></i> E-mail
							</div>
							<div class="btn" [class.active]="type == 'call'" (click)="setType('call')">
								<i class="fas fa-phone"></i> Ligação
							</div>
							<div class="btn" [class.active]="type == 'after_sales'" (click)="setType('after_sales')">
								<i class="fas fa-user-tag"></i> Pós-Venda
							</div>
							<div class="btn" (click)="setType('')">
								<i class="fas fa-times"></i>
							</div>
						</div>
					</div>
				</div>

				<div class="row m-0">
					<div class="col-md-7">
						<div class="field-container pr-sm-0" [class.error-input]="isFieldValid('start_at')">
							<app-input-datepicker formControlName="start_at" [allDay]="form.get('all_day').value"
								(allDayChange)="allDay($event)">
							</app-input-datepicker>
							<div class="name">
								<i class="far fa-calendar-alt"></i>
								Data do Compromisso
							</div>
						</div>

						<app-field-error-display [displayError]="isFieldValid('start_at')"
							errorMsg="Informe a data do evento!">
						</app-field-error-display>
					</div>
				</div>

				<div class="row m-0">
					<div class="col-12">
						<div class="field-container pr-sm-0">
							<app-input-multi-users formControlName="shared_users">
							</app-input-multi-users>
							<div class="name">
								<i class="fas fa-user-plus"></i>
								Compartilhar com usuários
							</div>
						</div>

						<app-field-error-display [displayError]="isFieldValid('start_at')"
							errorMsg="Informe os participantes do evento!">
						</app-field-error-display>
					</div>
				</div>

				<div class="row m-0">
					<div class="col">
						<div class="field-container pr-sm-0">
							<textarea name="" id="" cols="30" rows="10" formControlName="description"></textarea>

							<div class="name">
								Observação
							</div>
						</div>
					</div>
				</div>

				<div class="row m-0">
					<div class="col">
						<label class="checkbox-container">
							<input type="checkbox" formControlName="realized">
							<div class="checkbox"></div>
							<span class="text"><i class="fas fa-check"></i> Compromisso realizado</span>
						</label>
					</div>
				</div>

				<div class="text-center pt-2 hide-mobile">
					<button class="btn btn-primary" (click)="save()" type="button">
						Criar Compromisso
					</button>
				</div>
			</form>
		</div>

		<div class="flex-footer show-mobile w-100">
			<div class="actions-bottom">
				<button class="btn btn-primary" (click)="save()" type="button" id="save">
					Criar Compromisso
				</button>
			</div>
		</div>

	</div>

</div>
