import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {trigger, style, transition, animate, group} from '@angular/animations';

@Component({
	selector: 'app-drop-menu',
	templateUrl: './drop-menu.component.html',
	styleUrls: ['./drop-menu.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: [
		trigger('dropAnimate', [
			transition(':enter', [
				style({
					top: '30px',
					opacity: 0
				}),
				group([
					animate('300ms ease-in-out', style({
						top: '15px'
					})),
					animate('200ms linear', style({
						opacity: 1
					}))
				])
			]),
			transition(':leave', [
				group([
					animate('300ms ease-in-out', style({
						top: '30px'
					})),
					animate('200ms linear', style({
						opacity: 0
					}))
				]),
				style({
					top: '30px',
					opacity: 1
				}),
			])
		])
	]
})
export class DropMenuComponent implements OnInit {

	dropStatus: boolean = false;

	constructor() {
	}

	ngOnInit() {

	}

	changeDrop() {
		this.dropStatus = this.dropStatus ? false : true;
	}

	closeDrop() {
		this.dropStatus = false;
	}
}
