import {Component, ElementRef, EventEmitter, forwardRef, HostListener, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {debounceTime, distinctUntilChanged, mergeMap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Helpers} from '../../../services/helpers';
import {LocationCitiesService} from '../../models/location-cities.service';

@Component({
	selector: 'app-equivalence-search-cities',
	templateUrl: './equivalence-search-cities.component.html',
	styleUrls: ['./equivalence-search-cities.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => EquivalenceSearchCitiesComponent),
		multi: true
	}]
})
export class EquivalenceSearchCitiesComponent implements OnInit, OnChanges, ControlValueAccessor {

	@Output() error = false;
	@Output() select = new EventEmitter();

	value: any;

	searchTextChanged = new Subject<string>();
	textModel: string;
	locations = [];
	loading = false;

	@ViewChild('input') elInput: ElementRef;

	// Usuário selecionado
	selectedLocation: any;

	// Ver Autocomplete
	showAutocomplete = false;

	// Index selecionado
	selectNumber = 0;

	// Both onChange and onTouched are functions
	onChange: any = () => {
	}
	onTouched: any = () => {
	}

	constructor(
		private locationCitiesService: LocationCitiesService
	) {

	}

	/**
     * Changes
     */
	ngOnChanges() {
		if (Helpers.empty(this.value)) {
			this.selectedLocation = '';
		}
	}

	ngOnInit() {

		this.searchTextChanged
			.pipe(debounceTime(350))
			.pipe(distinctUntilChanged())
			.pipe(mergeMap(search => this.searchLocations(search)))
			.subscribe(data => {
				console.log(data);
				this.locations = data.results;
				this.loading = false;
			});

	}

	/**
     * Evento change no input
     * @param $event
     */
	changeLocationInput($event) {
		this.loading = true;
		this.showAutocomplete = true;
		let text = $event.target.value;
		this.updateChange();
		this.searchTextChanged.next(text);
	}

	/**
     * Seleciona usuário
     * @param location
     */
	selectLocation(location: any) {
		this.locations = [];
		this.selectedLocation = location;
		this.value = location;

		this.updateChange();
	}

	setFocus(focus) {
		this.showAutocomplete = focus;
		this.value = '';
	}

	/**
     * Remover Localização
     * @param location
     */
	removeLocation() {
		setTimeout(() => this.elInput.nativeElement.focus());
		this.locations = [];
		this.selectedLocation = false;
		this.value = '';
		this.updateChange();
	}

	/**
     * Busca Bairros
     * @param search
     * @returns {Observable<any>}
     */
	searchLocations(search) {
		return this.locationCitiesService.search({
			'full_search': search,
			'limit': 4
		});
	}

	/**
     * Update Model
     * @param {KeyboardEvent} event
     */
	updateChange() {
		this.select.emit(this.value);
		this.onChange(this.value);
	}


	@HostListener('document:keydown.arrowup', ['$event'])
	handleUpEvent(event: KeyboardEvent) {
		if (this.showAutocomplete) {
			this.selectNumber--;
			if (this.selectNumber < 0) {
				this.selectNumber = this.locations.length - 1;

				if (this.selectNumber < 0) {
					this.selectNumber = 0;
				}
			}
		}
	}

	@HostListener('document:keydown.arrowdown', ['$event'])
	handleDownEvent(event: KeyboardEvent) {
		if (this.showAutocomplete) {
			this.selectNumber++;
			if (this.selectNumber >= this.locations.length) {
				this.selectNumber = 0;
			}
		}
	}

	@HostListener('document:keydown.enter', ['$event'])
	handleEnterEvent(event: KeyboardEvent) {
		if (this.showAutocomplete && this.locations.length) {
			this.selectLocation(this.locations[this.selectNumber]);
		}
	}

	///////////////
	// OVERRIDES //
	///////////////

	/**
     * Writes a new item to the element.
     * @param value the value
     */
	writeValue(value: number): void {
		this.value = value;
		this.updateChange();
	}

	/**
     * Registers a callback function that should be called when the control's value changes in the UI.
     * @param fn
     */
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	/**
     * Registers a callback function that should be called when the control receives a blur event.
     * @param fn
     */
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

}
