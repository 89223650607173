import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Global } from '../../../../services/global';
import { Contacts } from '../../../interfaces/contacts';
import { PlaySound } from '../../../../services/PlaySound';
import { ToastrService } from 'ngx-toastr';
import { ContactsService } from '../../../models/contacts.service';
import { ModalContactDuplicateService } from '../../../shareds/modal-contact-duplicate.service';
import { Helpers } from '../../../../services/helpers';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {forkJoin, Subject} from 'rxjs';
import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { TitleService } from 'src/app/services/title.service';
import {RouterHistoric} from '../../../shareds/router-historic.service';
import {Location} from '@angular/common';

@Component({
	selector: 'app-contacts-add',
	templateUrl: './contacts-add.component.html',
	styleUrls: ['./contacts-add.component.scss'],
	animations: [
		trigger('displayForm', [
			transition(':enter', [
				style({ opacity: '0.0', height: '0px' }),
				animate('0.3s ease-out', style({ opacity: '1.0', height: '*' }))
			]),
			transition(':leave', [
				style({ opacity: '0.0', height: '*' }),
				animate('0.3s ease-out', style({ opacity: '0.0', height: '0px' }))
			]),
		]),
	]
})
export class ContactsAddComponent implements OnInit, OnDestroy {

	id = 0;
	propertie_search_tab = 0;
	contact = new Contacts();

	// Mostrar informações de etapa de funil e etc
	displayForm = false;

	types: any;
	types_properties: any;
	users_admin: any;
	estates: any;
	steps_funnel: any;
	nextCod = '';
	starting = true;

	// Form Group
	form: FormGroup;
	private formSubmitAttempt = false;

	saving = false;

	//
	contactDuplicate: Contacts;
	contactDuplicateType: string;

	// Destruir todos os subscrive
	private destroy$ = new Subject();

	constructor(
		private fb: FormBuilder,
		private contactsService: ContactsService,
		private route: ActivatedRoute,
		private location: Location,
		public modalContactDuplicateService: ModalContactDuplicateService,
		private toastr: ToastrService,
		private _titleService: TitleService,
		private router: Router
	) {
		this.types = Global.info.contacts.types;
		this.types_properties = Global.info.properties.types;
		this.steps_funnel = Global.info.contacts.steps_funnel;
		this.users_admin = Global.info.users_admin;
		this.estates = Global.info.estates;

		this.configureForm();

		// Define Default
		this.setType({ id: 7 });
		this.setFunnelStep({ id: this.steps_funnel[0].id });

		if (this.route.snapshot.params.id) {
			forkJoin([
				this.contactsService.get(this.route.snapshot.params.id),
				this.contactsService.getAny('info')
			]).subscribe((res) => {
				this.loadContact(res[0]);
				this.nextCod = res[1].next_cod;
				this.starting = false;
			});

		} else {
			this.contactsService.getAny('info').subscribe( (properties_info) => {
				this.starting = false;
				this.nextCod = properties_info.next_cod;
			}, () => {
				this.starting = false;
			});
		}


		// this.nextCod = this.route.snapshot.data.properties_info.next_cod;

	}

	ngOnInit() {

	}

	configureForm() {
		const users = this.users_admin.map(c => {
			// if (data.realtors_managers_in) {
			//     return new FormControl(data.realtors_managers_in.indexOf(c.id) !== -1);
			// } else {
			return new FormControl(false);
			// }
		});

		const types = this.types.map(c => {
			return new FormControl(false);
		});

		const types_properties = this.types_properties.map(c => {
			return new FormControl(false);
		});

		this.form = this.fb.group({
			cod: ['', []],
			name: ['', [Validators.required]],
			email: ['', [Validators.email]],
			phones: this.fb.array([]),
			type: ['', []],
			funnel_step: ['', []],
			realtors_managers: new FormArray(users),
			gender: ['', []],
			type_people: ['', []],
			RG: ['', []],
			CPF: ['', []],
			CNPJ: ['', []],
			CEP: ['', []],
			address: ['', []],
			number: ['', []],
			estate: ['', []],
			city: ['', []],
			neighborhood: ['', []],
			date_birth: ['', []],
			nationality: ['', []],
			naturalness: ['', []],
			occupation: ['', []],
			comments: ['', []],
			propertie_search: this.fb.array([]),
		});

		this.addPhone();
		this.addPerfilSearch();
	}

	loadContact(data?: any) {

		if (!data) {
			return false;
		}

		this.id = data.id;
		this.contact = data;

		const realtors_managers = this.users_admin.map((v, i) => {
			console.log(v.id);
			if (data.realtors_managers.indexOf(v.id) !== -1) {
				(<FormArray>this.form.controls['realtors_managers'])
					.at(i)
					.setValue(true);
			}
		});

		this.setType({ id: data.type });
		this.setFunnelStep({ id: data.type.funnel_step });

		if (!Helpers.empty(data.propertie_search)) {

			this.removePerfilSearch(0);

			for (let i = 0; i < data.propertie_search.length; i++) {
				// data.propertie_search[i].price = data.propertie_search[i].price / 100.0;
				data.propertie_search[i].price = data.propertie_search[i].price;

				this.addPerfilSearch();
			}

			(<FormGroup>this.form.get('propertie_search'))
				.patchValue(data.propertie_search);
		}

		if (!Helpers.empty(data.phones)) {

			const control = <FormArray>this.form.get('phones');
			for (let i = control.length - 1; i >= 0; i--) {
				control.removeAt(i);
			}

			for (let i = 0; i < data.phones.length; i++) {
				this.addPhone();
			}

			(<FormGroup>this.form.get('phones'))
				.patchValue(data.phones);
		}

		// cod
		this.form.patchValue({
			cod: data.cod || '',
			name: data.name || '',
			email: data.email || '',
			type: data.type || '',
			funnel_step: data.funnel_step || '',
			realtors_managers: data.realtors_managers || '',
			gender: data.gender || '',
			type_people: data.type_people || '',
			RG: data.RG || '',
			CPF: data.CPF || '',
			CNPJ: data.CNPJ || '',
			CEP: data.CEP || '',
			address: data.address || '',
			number: data.number || '',
			estate: data.estate || '',
			city: data.city || '',
			neighborhood: data.neighborhood || '',
			date_birth: data.date_birth || '',
			nationality: data.nationality || '',
			naturalness: data.naturalness || '',
			occupation: data.occupation || '',
			comments: data.comments || '',
		});

		this._titleService.setEditTitle(data.name);
	}

	/**
     * Verifica se campo é valido
     * @param {string} field
     * @returns {boolean}
     */
	isFieldValid(field: string) {
		return (!this.form.get(field).valid && this.formSubmitAttempt);
	}

	/**
     * Remove Telefone
     * @param index
     */
	removePhone(index) {
		(<FormArray>this.form.get('phones'))
			.removeAt(index);
	}

	/**
     * Adicionar telefone
     */
	addPhone() {
		(<FormArray>this.form.get('phones'))
			.push(this.fb.group({
				type: ['Residencial', []],
				operator: ['', []],
				number: ['', []]
			}));
	}

	/**
     * Remover perfil de busca Imóvel
     * @param index
     */
	removePerfilSearch(index) {
		(<FormArray>this.form.get('propertie_search'))
			.removeAt(index);

		this.subscribePropertiesSearch();
	}

	/**
     * Limpar perfil de busca Imóvel
     * @param index
     */
	cleanPerfilSearch(index) {

		const types = this.types.map(c => {
			return false;
		});

		(<FormGroup>this.form.get('propertie_search.' + index)).patchValue({
			bedroom: 0,
			bathroom: 0,
			suite: 0,
			vacancy: 0,
			area_total: 0,
			area_useful: 0,
			subtypes: [],
			types: types,
			price: '',
			finality: '',
			location: {
				estate: '',
				city: '',
				neighborhood: '',
			}
		});
	}

	/**
     * Adicionar Perfil de busca Imóvel
     */
	addPerfilSearch() {
		const types = this.types.map(c => {
			return new FormControl(false);
		});

		(<FormArray>this.form.get('propertie_search'))
			.push(this.fb.group({
				bedroom: [0, []],
				bathroom: [0, []],
				suite: [0, []],
				vacancy: [0, []],
				area_total: [0, []],
				area_useful: [0, []],
				subtypes: new FormArray([]),
				types: new FormArray(types),
				price: ['', []],
				finality: ['', []],
				location: this.fb.group({
					estate: ['', []],
					city: ['', []],
					neighborhood: ['', []],
				})
			}));

		let index = (<FormArray>this.form.get('propertie_search')).length - 1;

		this.subscribePropertiesSearch();
	}


	subscribePropertiesSearch() {

		this.destroy$.next();

		for (let i = 0; i < (<FormArray>this.form.get('propertie_search')).length; i++) {
			let index = i;
			this.form.get('propertie_search.' + index)
				.valueChanges
				.pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
				.pipe(takeUntil(this.destroy$))
				.subscribe((property_search) => {

					let city = property_search.location.city;
					let estate = property_search.location.estate;
					let neighborhood = property_search.location.neighborhood;

					let location_required = !Helpers.empty(neighborhood) || (Helpers.empty(city) && !Helpers.empty(estate)) || (!Helpers.empty(city) && Helpers.empty(estate));
					location_required = location_required || !Helpers.empty(Helpers.filterObject(property_search));

					if (location_required) {

						(<FormControl>this.form.get('propertie_search.' + index + '.location.estate')).setValidators([Validators.required]);
						(<FormControl>this.form.get('propertie_search.' + index + '.location.estate')).updateValueAndValidity();

						(<FormControl>this.form.get('propertie_search.' + index + '.location.city')).setValidators([Validators.required]);
						(<FormControl>this.form.get('propertie_search.' + index + '.location.city')).updateValueAndValidity();

					} else {
						(<FormControl>this.form.get('propertie_search.' + index + '.location.estate')).setValidators([]);
						(<FormControl>this.form.get('propertie_search.' + index + '.location.estate')).updateValueAndValidity();

						(<FormControl>this.form.get('propertie_search.' + index + '.location.city')).setValidators([]);
						(<FormControl>this.form.get('propertie_search.' + index + '.location.city')).updateValueAndValidity();
					}
				});
		}
	}

	/**
     * Define Status
     * @param status
     */
	setType(type: any): void {
		this.contact.type = type.id;
		this.form.controls.type.setValue(type.id);

		if (this.contact.type === 3 || this.contact.type === 8 || this.contact.type === 1) {
			this.displayForm = true;
		} else {
			this.displayForm = false;
		}
	}

	/**
     * Define Etapa
     * @param status
     */
	setFunnelStep(funnel_step: any): void {
		this.contact.funnel_step = funnel_step.id;
		this.form.controls.funnel_step.setValue(funnel_step.id);
	}

	/**
     * Alerta que existe imóvel duplicado
     * @param {Contacts} contact
     * @param {string} type
     */
	setContactDuplicate(contact: Contacts, type: string) {
		if (!!contact) {
			if (this.contact.id === contact.id) {
				return;
			}
			this.contactDuplicate = contact;
			this.contactDuplicateType = type;
			this.modalContactDuplicateService.open('user-duplicate');
		}
	}

	useOtherContact(contact: Contacts) {
		if (this.contactDuplicateType === 'email') {
			(<FormControl>this.form.get('email')).setValue('');
		} else {
			for (let x in contact.phones) {
				for (let i = 0; i < (this.form.get('phones') as FormArray).length; i++) {
					let number = (this.form.get('phones') as FormArray).at(i).get('number').value;
					if (contact.phones[x].number.toString() === number) {
						(this.form.get('phones') as FormArray).at(i).get('number').setValue('');
					}
				}
			}
		}
	}

	useThisContact(contact) {
		// this.contact.contact_id = contact.id;
		// this.contact.contact = contact;
	}

	/**
     * Salva Contato
     */
	save() {

		if (this.saving) {
			return false;
		}

		this.saving = true;

		this.formSubmitAttempt = true;

		let contact = { ...this.form.value };

		/**
         * Telefones
         */
		let phones = [];
		for (let x in contact.phones) {
			if (!Helpers.empty(contact.phones[x].number)) {
				phones.push(contact.phones[x]);
			}
		}
		contact.phones = phones;

		/**
         * Telefones
         */
		let propertie_search = [];

		if (this.contact.type === 3 || this.contact.type === 8) {

			for (let x in contact.propertie_search) {

				let item = contact.propertie_search[x];

				if (!Helpers.empty(Helpers.filterObject(item))) {

					item.types = item.types
						.map((v, i) => v ? this.types[i].id : null)
						.filter(v => v !== null);

					// Finalidade Venda
					if (this.contact.type === 3) {
						item.finality = 0;
					}

					// Finalidade Locação
					if (this.contact.type === 8) {
						item.finality = 1;
					}

					// item.price = item.price;

					propertie_search.push(item);
				}
			}
		}

		contact.propertie_search = propertie_search;

		/**
         * Subtipos
         */
		contact.realtors_managers = this.form.value.realtors_managers
			.map((v, i) => v ? this.users_admin[i].id : null)
			.filter(v => v !== null);

		let el = document.getElementsByClassName('error-input')[0];
		if (!!el) {
			el.scrollIntoView();
		}

		/**
         * Formulário com erros
         */
		if (!this.form.valid) {
			PlaySound.play('error');
			this.toastr.error('Preencha os campos corretamente.');
			this.saving = false;
			return false;
		}

		if (this.id) {
			this.contactsService
				.update(this.id, contact)
				.subscribe(data => {
					this.toastr.success('Contato editado com sucesso!');
					this.router.navigateByUrl('/contacts/list');
					this.saving = false;
				});
		} else {
			this.contactsService
				.create(contact)
				.subscribe(data => {
					this.toastr.success('Contato adicionado com sucesso!');
					this.router.navigate(['/contacts/view', data.id]);
					this.saving = false;
				}, err => {
					PlaySound.play('error');
					this.toastr.error(err.message);
					this.saving = false;
				});
		}
	}

	backUrl() {
		if (RouterHistoric.urlContain('/contacts/')) {
			this.location.back();
		} else {
			this.router.navigateByUrl('/contacts/list' );
		}
	}

	// Destroy
	ngOnDestroy(): void {
		this.destroy$.next();
	}

}
