import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {WebsocketService} from './websocket.service';
import {WebSocket} from '../../services/WebSocket';
import {takeUntil} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class SocketIOResponseService {

	constructor() {
	}

	response(data: any): Observable<any> {

		let destroy$ = new Subject();

		return Observable.create(observer => {

			if (!WebSocket.isConnected()) {
				observer.error({
					code: 0,
					error: true,
					message: 'Não foi possível conectar com o servidor, verifique sua internet.',
				});
				observer.complete();
				destroy$.next();
			}

			let request_id = WebSocket.sendMessage(data);

			WebsocketService.message
				.pipe(takeUntil(destroy$))
				.subscribe((resp: any) => {
					console.log(request_id);
					console.log(resp);
					if (resp.request_id === request_id) {
						observer.next(resp.body);
						observer.complete();
						destroy$.next();
					}
				});
		})
			.pipe(takeUntil(destroy$));
	}

}
