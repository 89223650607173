import {Directive, ElementRef, HostListener, Input, OnInit, Renderer} from '@angular/core';
import {Helpers} from '../../../services/helpers';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
	selector: '[appMaskCep]',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: MaskCepDirective,
		multi: true
	}]
})
export class MaskCepDirective implements OnInit  {

	onTouched: any = () => {
	};
	onChange: any = () => {
	};

	constructor(
		private el: ElementRef,
		private _renderer: Renderer) {
	}

	ngOnInit() {
		this._renderer.setElementAttribute(this.el.nativeElement, 'autocomplete', 'off');
		this._renderer.setElementAttribute(this.el.nativeElement, 'autocorrect', 'off');
		this._renderer.setElementAttribute(this.el.nativeElement, 'autocapitalize', 'off');
		this._renderer.setElementAttribute(this.el.nativeElement, 'spellcheck', 'false');
	}

	@HostListener('input', ['$event'])
	onInput($event: Event) {
		this.tratament(this.el.nativeElement.value);
	}

	@HostListener('keypress', ['$event'])
	onKeypress($event: any) {
		let value = Helpers.onlyNumbers(this.el.nativeElement.value);
		let key = $event.keyCode || $event.charCode;

		if (value.length >= 8) {
			$event.preventDefault();
			$event.stopImmediatePropagation();
			return false;
		}

		return true;
	}

	tratament(value: string) {

		if (value === undefined) {
			value = '';
		}

		let newVal = Helpers.onlyNumbers(value);
		newVal = newVal.substring(0, 8);
		if (newVal.length > 5) {
			newVal = newVal.replace(/^(\d{0,5})(\d{0,3})/, '$1-$2');
		}

		let lastLength = value.length;
		let newLength = newVal.length;

		let lastCaret = this.el.nativeElement.selectionStart;

		this.el.nativeElement.value = newVal;
		this.onChange(Helpers.onlyNumbers(newVal));

		/**
		 * Caret
		 */
		setTimeout(() => {
			this.el.nativeElement.selectionStart = newVal.length;
			this.el.nativeElement.selectionEnd = newVal.length;
		}, 1);

		this.el.nativeElement.selectionStart = newVal.length;
		this.el.nativeElement.selectionEnd = newVal.length;
	}

	writeValue(value: any): void {
		this.tratament(value);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
}
