import { Observable, Subject } from 'rxjs';
import { WebsocketService } from './websocket.service';
import { WebSocket } from '../../services/WebSocket';
import { takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
var SocketIOResponseService = /** @class */ (function () {
    function SocketIOResponseService() {
    }
    SocketIOResponseService.prototype.response = function (data) {
        var destroy$ = new Subject();
        return Observable.create(function (observer) {
            if (!WebSocket.isConnected()) {
                observer.error({
                    code: 0,
                    error: true,
                    message: 'Não foi possível conectar com o servidor, verifique sua internet.',
                });
                observer.complete();
                destroy$.next();
            }
            var request_id = WebSocket.sendMessage(data);
            WebsocketService.message
                .pipe(takeUntil(destroy$))
                .subscribe(function (resp) {
                console.log(request_id);
                console.log(resp);
                if (resp.request_id === request_id) {
                    observer.next(resp.body);
                    observer.complete();
                    destroy$.next();
                }
            });
        })
            .pipe(takeUntil(destroy$));
    };
    SocketIOResponseService.ngInjectableDef = i0.defineInjectable({ factory: function SocketIOResponseService_Factory() { return new SocketIOResponseService(); }, token: SocketIOResponseService, providedIn: "root" });
    return SocketIOResponseService;
}());
export { SocketIOResponseService };
