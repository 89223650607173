import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { AgendaService } from "../../../models/agenda.service";
import { TitleService } from "src/app/services/title.service";
import { balancePreviousStylesIntoKeyframes } from "@angular/animations/browser/src/util";

@Component({
	selector: "app-calendar-view-day",
	templateUrl: "./calendar-view-day.component.html",
	styleUrls: ["./calendar-view-day.component.scss"]
})
export class CalendarViewDayComponent implements OnInit {
	currentDay: moment.Moment;
	day: number;
	currentDateString: string;
	currentDayString: string;

	searching = true;
	filters: any = {
		offset: 0
	};

	commitments = [];
	commitments_count = 0;

	constructor(
		private route: ActivatedRoute,
		private agendaService: AgendaService,
		private _titleService: TitleService
	) {}

	ngOnInit() {
		let date = this.route.snapshot.params.day;
		date += "/" + this.route.snapshot.params.month;
		date += "/" + this.route.snapshot.params.year;

		this.currentDay = moment(date, "D/M/YYYY");
		this.day = this.currentDay.date();
		this.currentDateString = this.currentDay.format("D [de] MMMM YYYY");
		this.currentDayString = this.currentDay.format("YYYYMMDD");
		this._titleService.setTitle(this.currentDateString);

		this.loadAgenda();
	}

	setRealized(id: number, realized: boolean) {
		// Busca em Tarefas realizas
		for (let i in this.commitments) {
			if (this.commitments[i].id === id) {
				this.commitments[i].realized = realized;
			}
		}

		this.agendaService
			.update(id, {
				realized: realized ? 1 : 0
			})
			.subscribe(data => {
				console.log(data);
			});
	}

	delete(id: number) {
		// for (let i in this.commitments) {
		// 	if (this.commitments[i].id === id) {
		// 		this.commitments.splice(Number(i), 1);
		// 		break;
		// 	}
		// }

		this.agendaService.delete(id).subscribe(data => {
			for (let i in this.commitments) {
				if (this.commitments[i].id === id) {
					this.commitments.splice(Number(i), 1);
					break;
				}
			}
		});
	}

	loadAgenda() {
		this.searching = true;

		this.filters.date_gte =
			moment(this.currentDay).format("YYYYMMDD") + "000000";

		this.filters.date_lte =
			moment(this.currentDay).format("YYYYMMDD") + "235959";

		return this.agendaService.search(this.filters).subscribe(resp => {
			this.searching = false;
			this.commitments = resp.results;
			this.commitments_count = resp.count;
			this.filters.offset += resp.results.length;
		});
	}

	trackByFn(index, item) {
		return item.id; // or item.id
	}
}
