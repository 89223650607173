import { CrudManager } from './../../services/crud-manager';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationNeighborhoodErrorService extends CrudManager {
	path = '/admin/locations/neighborhoods-errors';

	constructor() {
		super();
	}
}
