import { AutomaticMessageService } from './../models/automatic-message.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { PropertiesDemandService } from '../models/properties-demand.service';

@Injectable({
	providedIn: 'root'
})
export class AutomaticMessageResolverService {

	constructor(
		private automaticMessageService: AutomaticMessageService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {

		let automatic_id = route.queryParams.automatic_id;

		if (automatic_id) {

			return this.automaticMessageService.get(automatic_id);

		}

		return false;
	}
}
