/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-delete.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./modal-delete.component";
var styles_ModalDeleteComponent = [i0.styles];
var RenderType_ModalDeleteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalDeleteComponent, data: { "animation": [{ type: 7, name: "EnterLeave", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: "0.0" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: "1.0" }, offset: null }, timings: "0.3s ease-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: "0.0" }, offset: null }, timings: "0.3s ease-out" }], options: null }], options: {} }] } });
export { RenderType_ModalDeleteComponent as RenderType_ModalDeleteComponent };
function View_ModalDeleteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "modal-screen"]], [[24, "@EnterLeave", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "modal-body pd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "i-close inside"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "modal-box-text"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [["class", "btn-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-main"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancelar "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "span", [["class", "pr-1 pl-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "span", [["class", "btn-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn btn-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Deletar "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.openModal; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 5, 0, currVal_1); }); }
export function View_ModalDeleteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalDeleteComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.openModal; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ModalDeleteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-delete", [], null, null, null, View_ModalDeleteComponent_0, RenderType_ModalDeleteComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModalDeleteComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalDeleteComponentNgFactory = i1.ɵccf("app-modal-delete", i3.ModalDeleteComponent, View_ModalDeleteComponent_Host_0, { id: "id", error: "error", title: "title", openModal: "openModal", fn: "fn" }, { confirm: "confirm", openModalChange: "openModalChange" }, ["*"]);
export { ModalDeleteComponentNgFactory as ModalDeleteComponentNgFactory };
