import {CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router/src/router_state';
import {AuthService} from './auth.service';
import {promise} from 'selenium-webdriver';
import {map, catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {Global} from './global';
import {Location} from '@angular/common';

@Injectable()
export class NeedAuthGuardLogin implements CanActivate {

    constructor(private router: Router,
                private authService: AuthService,
                private location: Location,
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const redirectUrl = route['_routerState']['url'];

        if (Global.userLogged) {
            if (redirectUrl) {
                this.location.replaceState(redirectUrl);
            } else {
                this.location.replaceState('/');
            }
            return true;
        }

        return this.authService
            .checkUserLogged()
            .pipe(map(data => {
                    this.authService.setUser(data);
                    return true;
                }))
            .pipe(catchError(
                err => {
                    return of(true);
                }));
    }
}
