import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { ResponseTreatment } from './response-treatment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RegisterService = /** @class */ (function () {
    function RegisterService(http) {
        this.http = http;
        this.responseTreatment = new ResponseTreatment;
        this.path = '/admin/accounts-free';
    }
    RegisterService.prototype.register = function (body) {
        var _this = this;
        return this.http
            .post(environment.API + this.path, body)
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }))
            .pipe(catchError(this.responseTreatment.handleError));
    };
    RegisterService.ngInjectableDef = i0.defineInjectable({ factory: function RegisterService_Factory() { return new RegisterService(i0.inject(i1.HttpClient)); }, token: RegisterService, providedIn: "root" });
    return RegisterService;
}());
export { RegisterService };
