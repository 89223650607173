/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input-number-increment.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./input-number-increment.component";
var styles_InputNumberIncrementComponent = [i0.styles];
var RenderType_InputNumberIncrementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputNumberIncrementComponent, data: {} });
export { RenderType_InputNumberIncrementComponent as RenderType_InputNumberIncrementComponent };
export function View_InputNumberIncrementComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "field-container input-number"]], [[2, "error-input", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["autocapitalize", "off"], ["autocomplete", "asdasd"], ["autocorrect", "off"], ["spellcheck", "false"], ["type", "type"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.value = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "plus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.plus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" + "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "minus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.minus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" - "]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.value; _ck(_v, 4, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 6).ngClassValid; var currVal_6 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_InputNumberIncrementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "input-number-increment", [], null, [[null, "input"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onKeyup($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_InputNumberIncrementComponent_0, RenderType_InputNumberIncrementComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.InputNumberIncrementComponent]), i1.ɵdid(2, 638976, null, 0, i3.InputNumberIncrementComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var InputNumberIncrementComponentNgFactory = i1.ɵccf("input-number-increment", i3.InputNumberIncrementComponent, View_InputNumberIncrementComponent_Host_0, { error: "error", limit: "limit" }, { change: "change" }, []);
export { InputNumberIncrementComponentNgFactory as InputNumberIncrementComponentNgFactory };
