import {Injectable} from '@angular/core';
import {CrudManager} from '../../services/crud-manager';

@Injectable({
	providedIn: 'root'
})
export class ContactsInteractionsService extends CrudManager {
	path = '/admin/contacts/:id/interactions';

	constructor() {
		super();
	}

	setContact(contact_id: number): ContactsInteractionsService {
		this.path = '/admin/contacts/' + contact_id + '/interactions';
		return this;
	}
}
