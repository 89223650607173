var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { Global } from './../../../services/global';
import { AppValidators } from './../../shareds/validators';
import { PlaySound } from './../../../services/PlaySound';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../../models/users.service';
import { ErrorCode } from '../../../services/ErrorCode';
import { ActivatedRoute, Router } from '@angular/router';
import { Helpers } from '../../../services/helpers';
import { RouterHistoric } from '../../shareds/router-historic.service';
import { Location } from '@angular/common';
var UsersAddComponent = /** @class */ (function () {
    function UsersAddComponent(_fb, toastr, router, location, route, changeDetectorRef, _usersService) {
        this._fb = _fb;
        this.toastr = toastr;
        this.router = router;
        this.location = location;
        this.route = route;
        this.changeDetectorRef = changeDetectorRef;
        this._usersService = _usersService;
        this.id = 0;
    }
    UsersAddComponent.prototype.ngOnInit = function () {
        this.buildForm();
        this.loadUser(this.route.snapshot.data.user);
        this.roles = Global.info.users.types;
    };
    /**
     *
     * @param data
     * @returns {boolean}
     */
    UsersAddComponent.prototype.loadUser = function (data) {
        console.log('TCL: UsersAddComponent -> loadUser -> data', data);
        if (Helpers.empty(data)) {
            return false;
        }
        this.id = data.id;
        this.user = data;
        this.oldUser = __assign({}, data);
        // name
        this.form.controls['name']
            .setValue(data.name || '', { onlySelf: true });
        // email
        this.form.controls['email']
            .setValue(data.email || '', { onlySelf: true });
        // phone
        console.log(data.phones[0]);
        if (!!data.phones[0]) {
            this.form.controls['phones']
                .setValue(data.phones || [], { onlySelf: true });
        }
        // gender
        this.form.controls['gender']
            .setValue(Number(data.gender), { onlySelf: true });
        // role
        this.form.controls['role']
            .setValue(data.role || '', { onlySelf: true });
        console.log(data.login);
        this.form.controls['login']
            .setValue(data.login || '', { onlySelf: true });
        this.form.controls['permissions'].get('REALESTATE_MANAGER')
            .setValue(Number(data.permissions['REALESTATE_MANAGER']), { onlySelf: true });
        this.form.controls['permissions'].get('ADD_PROPERTIES')
            .setValue(Number(data.permissions['ADD_PROPERTIES']), { onlySelf: true });
        this.form.controls['permissions'].get('EDIT_PROPERTIES')
            .setValue(Number(data.permissions['EDIT_PROPERTIES']), { onlySelf: true });
        this.form.controls['permissions'].get('VIEW_ADDRESS_PROPERTIES')
            .setValue(Number(data.permissions['VIEW_ADDRESS_PROPERTIES']), { onlySelf: true });
        this.form.controls['permissions'].get('CONTACT_VIEW_PROPRIETARY')
            .setValue(Number(data.permissions['CONTACT_VIEW_PROPRIETARY']), { onlySelf: true });
        this.form.controls['permissions'].get('CONTACT_VIEW_BUYERS_PROPERTIES')
            .setValue(Number(data.permissions['CONTACT_VIEW_BUYERS_PROPERTIES']), { onlySelf: true });
        this.form.controls['permissions'].get('FUNNEL_STEP_MANAGER')
            .setValue(Number(data.permissions['FUNNEL_STEP_MANAGER']), { onlySelf: true });
        this.form.controls['permissions'].get('PARTNER_PERFIL_MANAGER')
            .setValue(Number(data.permissions['PARTNER_PERFIL_MANAGER']), { onlySelf: true });
        console.log('a: ' + Number(data.permissions['ADD_PROPERTIES']));
        console.log('b: ' + Number(data.permissions['EDIT_PROPERTIES']));
        console.log('c: ' + Number(data.permissions['VIEW_ADDRESS_PROPERTIES']));
        console.log('d: ' + Number(data.permissions['CONTACT_VIEW_PROPRIETARY']));
        console.log('e: ' + Number(data.permissions['CONTACT_VIEW_BUYERS_PROPERTIES']));
        console.log('f: ' + Number(data.permissions['FUNNEL_STEP_MANAGER']));
        console.log('g: ' + Number(data.permissions['PARTNER_PERFIL_MANAGER']));
        console.log(this.form.controls['permissions'].get('ADD_PROPERTIES').value);
        console.log(this.form.controls['permissions'].get('EDIT_PROPERTIES').value);
        console.log(this.form.controls['permissions'].get('VIEW_ADDRESS_PROPERTIES').value);
        console.log(this.form.controls['permissions'].get('CONTACT_VIEW_PROPRIETARY').value);
        console.log(this.form.controls['permissions'].get('CONTACT_VIEW_BUYERS_PROPERTIES').value);
        console.log(this.form.controls['permissions'].get('FUNNEL_STEP_MANAGER').value);
        console.log(this.form.controls['permissions'].get('PARTNER_PERFIL_MANAGER').value);
        // for (const key in data.permissions) {
        // 	if (data.permissions.hasOwnProperty(key)) {
        // 		const element = data.permissions[key];
        // 	}
        // }
    };
    UsersAddComponent.prototype.backUrl = function () {
        this.router.navigateByUrl('/users/list');
    };
    UsersAddComponent.prototype.buildForm = function () {
        this.form = this._fb.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            phones: this._fb.array([
                this._fb.group({
                    number: ''
                })
            ]),
            password: ['', [!!this.id ? Validators.required : AppValidators.passwordNotRequired]],
            login: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(12)]],
            role: ['', []],
            gender: [1, [Validators.required]],
            permissions: this._fb.group({
                // USERS_MANAGER: [0, [Validators.required]],
                REALESTATE_MANAGER: [0, [Validators.required]],
                // AGENDA_USERS_MANAGER: [1, [Validators.required]],
                ADD_PROPERTIES: [0, [Validators.required]],
                EDIT_PROPERTIES: [0, [Validators.required]],
                VIEW_ADDRESS_PROPERTIES: [0, [Validators.required]],
                CONTACT_VIEW_PROPRIETARY: [0, [Validators.required]],
                CONTACT_VIEW_BUYERS_PROPERTIES: [0, [Validators.required]],
                FUNNEL_STEP_MANAGER: [0, [Validators.required]],
                PARTNER_PERFIL_MANAGER: [0, [Validators.required]],
            })
        });
    };
    UsersAddComponent.prototype.isFieldValid = function (field) {
        return (!this.form.get(field).valid && this.formSubmitAttempt);
    };
    UsersAddComponent.prototype.save = function () {
        var _this = this;
        var data = this.form.value;
        this.formSubmitAttempt = true;
        // this.form.value['phones'].forEach(element => {
        // 	if (element['number'] === null) {
        // 		element['number'] = '';
        // 	}
        // });
        if (!this.form.valid) {
            PlaySound.play('error');
            this.toastr.error('Preencha os campos corretamente.');
            this.formSubmitAttempt = true;
            this.changeDetectorRef.detectChanges();
            var el = document.getElementsByClassName('error-input')[0];
            if (!!el) {
                el.scrollIntoView();
            }
            return false;
        }
        if (this.id) {
            return this._usersService
                .update(this.id, data)
                .subscribe(function (user) {
                _this.toastr.success('Dados atualizados com sucesso!');
                if (RouterHistoric.urlContain('/users/list')) {
                    _this.location.back();
                }
                else {
                    _this.router.navigateByUrl('/users/list');
                }
            }, function (err) {
                if (ErrorCode.EMAIL_IN_USE === err.code) {
                    _this.form.get('email').setErrors({
                        'used': true
                    });
                }
                if (ErrorCode.LOGIN_IN_USE === err.code) {
                    _this.form.get('login').setErrors({
                        'used': true
                    });
                }
                _this.formSubmitAttempt = true;
                _this.changeDetectorRef.detectChanges();
                var el = document.getElementsByClassName('error-input')[0];
                if (!!el) {
                    el.scrollIntoView();
                }
                PlaySound.play('error');
                _this.toastr.error(err.message);
            });
        }
        else {
            return this._usersService
                .create(data)
                .subscribe(function (user) {
                _this.toastr.success('Dados atualizados com sucesso!');
                _this.router.navigateByUrl('/users/list');
            }, function (err) {
                if (ErrorCode.EMAIL_IN_USE === err.code) {
                    _this.form.get('email').setErrors({
                        'used': true
                    });
                }
                if (ErrorCode.LOGIN_IN_USE === err.code) {
                    _this.form.get('login').setErrors({
                        'used': true
                    });
                }
                _this.formSubmitAttempt = true;
                _this.changeDetectorRef.detectChanges();
                var el = document.getElementsByClassName('error-input')[0];
                if (!!el) {
                    el.scrollIntoView();
                }
                PlaySound.play('error');
                _this.toastr.error(err.message);
            });
        }
    };
    UsersAddComponent.prototype.trackByFn = function (index, item) {
        return item.id; // or item.id
    };
    return UsersAddComponent;
}());
export { UsersAddComponent };
