/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./initials.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./initials.component";
var styles_InitialsComponent = [i0.styles];
var RenderType_InitialsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InitialsComponent, data: {} });
export { RenderType_InitialsComponent as RenderType_InitialsComponent };
export function View_InitialsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "initials-letter"]], [[2, "initials-big", null], [2, "initials-md", null], [2, "initials-small", null], [2, "initials-xs", null], [2, "size-90", null]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵted(2, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.style; _ck(_v, 1, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.size == "big"); var currVal_1 = (_co.size == "md"); var currVal_2 = (_co.size == "small"); var currVal_3 = (_co.size == "xs"); var currVal_4 = (_co.size == "size-90"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = _co.initials; _ck(_v, 2, 0, currVal_6); }); }
export function View_InitialsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-initials", [], null, null, null, View_InitialsComponent_0, RenderType_InitialsComponent)), i1.ɵdid(1, 638976, null, 0, i3.InitialsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InitialsComponentNgFactory = i1.ɵccf("app-initials", i3.InitialsComponent, View_InitialsComponent_Host_0, { clean: "clean", name: "name", size: "size" }, {}, []);
export { InitialsComponentNgFactory as InitialsComponentNgFactory };
