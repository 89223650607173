import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var CalendarSharedService = /** @class */ (function () {
    function CalendarSharedService() {
        this.count = 0;
        this.messageSource = new BehaviorSubject(0);
        this.event = this.messageSource.asObservable();
    }
    CalendarSharedService.prototype.refresh = function () {
        this.count++;
        this.messageSource.next(this.count);
    };
    CalendarSharedService.ngInjectableDef = i0.defineInjectable({ factory: function CalendarSharedService_Factory() { return new CalendarSharedService(); }, token: CalendarSharedService, providedIn: "root" });
    return CalendarSharedService;
}());
export { CalendarSharedService };
