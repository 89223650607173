/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./equivalence-search-cities.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../../directives/click-outside/click-outside.directive";
import * as i5 from "./equivalence-search-cities.component";
import * as i6 from "../../models/location-cities.service";
var styles_EquivalenceSearchCitiesComponent = [i0.styles];
var RenderType_EquivalenceSearchCitiesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EquivalenceSearchCitiesComponent, data: {} });
export { RenderType_EquivalenceSearchCitiesComponent as RenderType_EquivalenceSearchCitiesComponent };
function View_EquivalenceSearchCitiesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["input", 1]], null, 5, "input", [["autocapitalize", "off"], ["autocomplete", "asdasd"], ["autocorrect", "off"], ["spellcheck", "false"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "focus"], [null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("focus" === en)) {
        var pd_4 = (_co.setFocus(true) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.value = $event) !== false);
        ad = (pd_5 && ad);
    } if (("input" === en)) {
        var pd_6 = (_co.changeLocationInput($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(3, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.value; _ck(_v, 3, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_EquivalenceSearchCitiesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "td td-overflow-text pl-2 text-primary font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "location"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-map-marker-alt"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", ", ", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "td text-primary font-weight-bold remove cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeLocation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedLocation.city; var currVal_1 = _co.selectedLocation.estate; _ck(_v, 5, 0, currVal_0, currVal_1); }); }
function View_EquivalenceSearchCitiesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "app-users--loading-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-spinner"]], null, null, null, null, null))], null, null); }
function View_EquivalenceSearchCitiesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "li", [], [[2, "active", null]], [[null, "mouseenter"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = ((_co.selectNumber = _v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.selectLocation(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "td text-muted td-min"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "location"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-map-marker-alt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "td text-muted pl-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "location"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectNumber == _v.context.index); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.city; _ck(_v, 8, 0, currVal_1); var currVal_2 = _v.context.$implicit.estate; _ck(_v, 10, 0, currVal_2); }); }
function View_EquivalenceSearchCitiesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "td td-overflow-text text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "location"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-map-marker-alt"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Nenhuma localiza\u00E7\u00E3o encontrada."]))], null, null); }
function View_EquivalenceSearchCitiesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ul", [["class", "autocomplete-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquivalenceSearchCitiesComponent_5)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquivalenceSearchCitiesComponent_6)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.locations; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.locations.length; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_EquivalenceSearchCitiesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { elInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "app-autocomplete field-container"]], [[2, "error-input", null]], [[null, "clickOutside"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("clickOutside" === en)) {
        var pd_1 = (_co.setFocus(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 540672, null, 0, i4.ClickOutsideDirective, [i1.ElementRef], null, { clickOutside: "clickOutside" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquivalenceSearchCitiesComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquivalenceSearchCitiesComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquivalenceSearchCitiesComponent_3)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquivalenceSearchCitiesComponent_4)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.selectedLocation; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.selectedLocation; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.loading; _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.showAutocomplete && !_co.selectedLocation); _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 1, 0, currVal_0); }); }
export function View_EquivalenceSearchCitiesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-equivalence-search-cities", [], null, [["document", "keydown.arrowup"], ["document", "keydown.arrowdown"], ["document", "keydown.enter"]], function (_v, en, $event) { var ad = true; if (("document:keydown.arrowup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).handleUpEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keydown.arrowdown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).handleDownEvent($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:keydown.enter" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).handleEnterEvent($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_EquivalenceSearchCitiesComponent_0, RenderType_EquivalenceSearchCitiesComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.EquivalenceSearchCitiesComponent]), i1.ɵdid(2, 638976, null, 0, i5.EquivalenceSearchCitiesComponent, [i6.LocationCitiesService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var EquivalenceSearchCitiesComponentNgFactory = i1.ɵccf("app-equivalence-search-cities", i5.EquivalenceSearchCitiesComponent, View_EquivalenceSearchCitiesComponent_Host_0, {}, { error: "error", select: "select" }, []);
export { EquivalenceSearchCitiesComponentNgFactory as EquivalenceSearchCitiesComponentNgFactory };
