import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PlaySound } from "../../../../../services/PlaySound";
import { ToastrService } from "ngx-toastr";
import { ContactsInteractionsService } from "../../../../models/contacts-interactions.service";
import { TitleService } from "src/app/services/title.service";
import { Subscription } from "rxjs";
import { ContactInteractionsSharedService } from "src/app/content/shareds/contact-interactions-shared.service";
import { IContactsInteractions } from "src/app/content/interfaces/contacts-interactions";

@Component({
	selector: "app-contacts-view-edit-interactions",
	templateUrl: "./contacts-view-edit-interactions.component.html",
	styleUrls: ["./contacts-view-edit-interactions.component.scss"]
})
export class ContactsViewEditInteractionsComponent implements OnInit {
	@Output() saved = new EventEmitter();
	@Input() form: FormGroup;

	private formSubmitAttempt = false;

	contactID = 0;

	openModal = false;
	// visitType:  'not_like' | 'thinking' | 'reasonable' | 'like' | '' = '';
	// typeCommitment: 'visit' | 'meeting' | 'email' | 'call' | 'after_sales' | '' = '';
	visitType = "";
	typeCommitment = "";
	interaction: IContactsInteractions;

	constructor(
		private route: ActivatedRoute,
		private _fb: FormBuilder,
		private router: Router,
		private toastr: ToastrService,
		private contactsInteractionsService: ContactsInteractionsService,
		private _titleService: TitleService,
		private _contactsSharedService: ContactInteractionsSharedService
	) {}

	ngOnInit() {
		this.contactID = this.route.parent.snapshot.params.id;
		this.contactsInteractionsService.setContact(this.contactID);

		let contactName = this.route.parent.snapshot.data.contact.name;
		this.interaction = this.route.snapshot.data.interaction;
		if (!!this.interaction.commitment) {
			this.typeCommitment = this.interaction.commitment.type;
		}

		this._titleService.setTitle(`${contactName} - Editar Interação`);
		this.selectForm();
	}

	save(): Subscription | boolean {
		this.formSubmitAttempt = false;

		/**
		 * Formulário com erros
		 */
		if (!this.form.valid) {
			this.formSubmitAttempt = true;
			PlaySound.play("error");
			this.toastr.error("Preencha os campos corretamente.");
			return false;
		}

		let data = {
			type: this.interaction.type,
			...this.form.value
		};

		switch (this.interaction.type) {
			case "properties_visit":
				data["propertie_opinion"] = this.visitType;
				data["propertie_id"] = 1; // REVIEW: Retirar property_id
				break;

			case "commitment":
				data = {};
				data["commitment"] = { ...this.form.value };
				data["type"] = this.interaction.type;
				data["commitment"]["all_day"] = data["commitment"]["all_day"]
					? 1
					: 0;
				data["commitment"]["realized"] = data["commitment"]["realized"]
					? 1
					: 0;
				break;

			case "task":
				data = {};
				data["task"] = { ...this.form.value };
				data["type"] = this.interaction.type;
				data["task"]["all_day"] = data["task"]["all_day"] ? 1 : 0;
				data["task"]["realized"] = data["task"]["realized"] ? 1 : 0;
				break;

			case "contact_nota":
				break;
		}

		return this.contactsInteractionsService
			.update(this.interaction.id, data)
			.subscribe(
				res => {
					this.toastr.success("Interação atualizada com sucesso!");
					this.close();
					this._contactsSharedService.refresh();
				},
				err => {
					this.toastr.error("Erro ao criar interação.");
				}
			);
	}

	/**
	 * Verifica se campo é valido
	 * @param {string} field
	 * @returns {boolean}
	 */
	isFieldValid(field: string, parent?: string): boolean {
		if (!!parent) {
			return (
				!this.form.get(parent).get(field).valid &&
				this.formSubmitAttempt
			);
		} else {
			return !this.form.get(field).valid && this.formSubmitAttempt;
		}
	}

	buildFormNote(): void {
		this.form = this._fb.group({
			message: [this.interaction.message, Validators.required],
			type: [this.interaction.type, Validators.required]
		});
	}

	buildFormVisit(): void {
		this.form = this._fb.group({
			message: [this.interaction.message],
			type: [this.interaction.type, Validators.required],
			propertie_opinion: [this.interaction.propertie_id]
		});
	}

	builFormCommitment(): void {
		let commitment = this.interaction.commitment;

		this.form = this._fb.group({
			name: [commitment.name, Validators.required],
			start_at: [commitment.start_at, Validators.required],
			description: [commitment.description],
			realized: [commitment.realized],
			all_day: [commitment.all_day],
			shared_users: [commitment.shared_users_data],
			type: [commitment.type]
		});
	}

	buildFormTask(): void {
		let task = this.interaction.task;
		this.form = this._fb.group({
			name: [task.name, Validators.required],
			all_day: [task.all_day],
			deadline_at: [task.deadline_at],
			comments: [task.comments],
			realized: [task.realized]
		});
	}

	allDay(event: any) {
		this.form.get("all_day").setValue(event);
	}

	setVisitType(
		type: "not_like" | "thinking" | "reasonable" | "like" | ""
	): void {
		this.visitType = type;
		this.form.patchValue({ propertie_opinion: type });
	}

	setCommitType(
		type: "visit" | "meeting" | "email" | "call" | "after_sales"
	) {
		this.typeCommitment = type;
		this.form.patchValue({ type: type });
	}

	close() {
		if (this.router.url.indexOf("sales-funnel") !== -1) {
			this.router.navigateByUrl(
				"/sales-funnel/list/contacts/view/" + this.contactID
			);
		} else {
			this.router.navigateByUrl("/contacts/view/" + this.contactID);
		}
	}

	checkTab(tab: string): boolean {
		return tab === this.interaction.type;
	}

	private selectForm() {
		delete this.form;
		switch (this.interaction.type) {
			case "contact_nota":
				this.buildFormNote();
				break;

			case "properties_visit":
				this.buildFormVisit();
				break;

			case "commitment":
				this.builFormCommitment();
				break;

			case "task":
				this.buildFormTask();
				break;
		}
	}
}
