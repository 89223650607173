import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Users} from '../../interfaces/users';

@Component({
	selector: 'app-select-users',
	templateUrl: './select-users.component.html',
	styleUrls: ['./select-users.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: SelectUsersComponent,
		multi: true
	}]
})
export class SelectUsersComponent implements OnInit, OnChanges, ControlValueAccessor {

	@Input() users: Users[];
	@Output() change = new EventEmitter();

	selectedUser: any;
	showAutocomplete = false;
	selectNumber = 0;

	// Both onChange and onTouched are functions
	onChange: any = () => {
	};
	onTouched: any = () => {
	};

	constructor() {

	}

	ngOnInit() {
		if (!this.selectedUser) {
			this.selectedUser = this.users[0];
		}
	}


	/**
	 * Selecionar Usuário
	 * @param user
	 */
	selectUser(user) {
		this.selectedUser = user;
		this.showAutocomplete = false;
		this.updateChange();
	}

	updateChange() {
		this.onChange(this.selectedUser.id);
		this.change.emit();
	}

	@HostListener('document:keydown.arrowup', ['$event'])
	handleUpEvent(event: KeyboardEvent) {
		if (this.showAutocomplete) {
			this.selectNumber--;
			if (this.selectNumber < 0) {
				this.selectNumber = this.users.length - 1;

				if (this.selectNumber < 0) {
					this.selectNumber = 0;
				}
			}
		}
	}

	@HostListener('document:keydown.arrowdown', ['$event'])
	handleDownEvent(event: KeyboardEvent) {
		if (this.showAutocomplete) {
			this.selectNumber++;
			if (this.selectNumber >= this.users.length) {
				this.selectNumber = 0;
			}
		}
	}

	@HostListener('document:keydown.enter', ['$event'])
	handleEnterEvent(event: KeyboardEvent) {
		if (this.showAutocomplete) {
			this.selectUser(this.users[this.selectNumber]);
		}
	}

	///////////////
	// OVERRIDES //
	///////////////

	/**
	 * Writes a new item to the element.
	 * @param value the value
	 */
	writeValue(value: number): void {
		if (value !== undefined) {
			for (let i in this.users) {
				let user = this.users[i];
				if (Number(user.id) === Number(value)) {
					this.selectedUser = user;
					break;
				}
			}
		}

		// this.updateChange();
	}

	/**
	 * Registers a callback function that should be called when the control's value changes in the UI.
	 * @param fn
	 */
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	/**
	 * Registers a callback function that should be called when the control receives a blur event.
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	ngOnChanges(): void {

	}

	setDisabledState(isDisabled: boolean): void {
	}


}
