import {ChangeDetectorRef, Component, HostListener, NgZone, OnInit} from '@angular/core';
import {RouterHistoric} from './content/shareds/router-historic.service';
import {Global} from './services/global';
import {Router, NavigationEnd, ActivatedRoute, NavigationStart, NavigationError} from '@angular/router';
import {map, filter, mergeMap, takeUntil, skip} from 'rxjs/operators';
import { Subscribable } from 'rxjs/src/internal/types';
import { TitleService } from './services/title.service';
import {MessagingService} from './services/messaging.service';
import {SwPush} from '@angular/service-worker';
import {CordovaService} from './services/cordova.service';
import {ConnectionService} from 'ng-connection-service';
import {GlobalService} from './content/shareds/global.service';
import {animate, style, transition, trigger} from '@angular/animations';

declare var window: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [
		trigger('fadeIn', [
			transition(':leave', [
				animate('0.3s ease-out', style({ opacity: '0.0' }))
			])
		]),
	]
})
export class AppComponent implements OnInit {
	title = 'dashboard-ng';
	routerEvents: any;

	loading = false;
	update = false;
	_loadingScreen = true;

	VAPID_PUBLIC_KEY = 'BG3vVnN0muQlTlGlhBBvH4cYgUn2jSk2jH5uQ1UUMXqmID47ymHzalAhXrp3xFiI_6mO3_T3FnojKHL_hMBHp4E';
	VAPID_PRIVATE_KEY = '1xIxr6k1cG8q_0UV3n7m9uOCwvrvOguKMQ_tEwL64Dk';

	constructor(
		private routerHistoric: RouterHistoric,
		private _router: Router,
		private _route: ActivatedRoute,
		private _changeDetectorRef: ChangeDetectorRef,
		private ngZone: NgZone,
		private cordovaService: CordovaService,
		private _activatedRoute: ActivatedRoute,
		private _globalService: GlobalService,
		private _titleService: TitleService
		// private swPush: SwPush
	) {
		// Mobile
		Global.mobile = window.innerWidth < 1024;
	}

	routerObserverTitle(): void {
		// Pega o Observable do roteador para definir o titulo dinamicamente
		this.routerEvents = this._router.events
		// Filtra apenas os eventos que tem a instancia determinada
		.pipe(filter(event => event instanceof NavigationEnd))
		// Procura a propriedade activatedRoute
		.pipe(map(() => this._activatedRoute))
		// Entra na propriedade filho da rota atual
		.pipe(map(route => {
			while (route.firstChild) { route = route.firstChild; }

			return route;
		}))
		// Converte o insere o route.data no route
		.pipe(mergeMap(route => route.data))
		// Executa a funcao quando tudo termina
		.subscribe(event => {
			if (!!event['title']) {
				this._titleService.setTitle(event['title']);
			}
		});
	}

	loadingScreen() {
		this._router.events
			// Executa a funcao quando tudo termina
			.subscribe((event: any) => {

				if (event instanceof NavigationStart) {
					this.loading = true;
					CordovaService.touchSound();
				}

				if (event instanceof NavigationEnd || event instanceof NavigationError) {

					Global.back = false;

					this.loading = false;

					this.ngZone.run(() => {
						this.update = !this.update;
						this._changeDetectorRef.detectChanges();
						console.log('Change Page');
					});
				}
			});

		this._route
			.queryParams
			.subscribe(data => {
				this.ngZone.run(() => { this.update = !this.update; });
			});

		this._globalService
			.loadingScreen
			.subscribe(loadingScreen => {
				this.ngZone.run(() => { this._loadingScreen = loadingScreen; });
			});

	}

	ngOnInit(): void {
		this.routerObserverTitle();
		this.loadingScreen();
	}

	// subscribeToPush() {
	// 	this.swPush.requestSubscription({
	// 			serverPublicKey: this.VAPID_PUBLIC_KEY
	// 		})
	// 		.then(sub => {
	// 			console.log('Send ' + sub + ' to server');
	// 		}, error => {
	// 			console.log(error);
	// 		})
	// 		.catch(console.error);
	// }

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		// Mobile
		Global.mobile = window.innerWidth < 1024;
	}
}
