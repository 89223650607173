import { PropertiesService } from './../models/properties.service';
import * as i0 from "@angular/core";
import * as i1 from "../models/properties.service";
var PartnersPropertiesGetResolverService = /** @class */ (function () {
    function PartnersPropertiesGetResolverService(propertiesService) {
        this.propertiesService = propertiesService;
    }
    PartnersPropertiesGetResolverService.prototype.resolve = function (route) {
        var property_id = route.queryParams.property_id;
        if (property_id) {
            return this.propertiesService.get(property_id);
        }
        return false;
    };
    PartnersPropertiesGetResolverService.ngInjectableDef = i0.defineInjectable({ factory: function PartnersPropertiesGetResolverService_Factory() { return new PartnersPropertiesGetResolverService(i0.inject(i1.PropertiesService)); }, token: PartnersPropertiesGetResolverService, providedIn: "root" });
    return PartnersPropertiesGetResolverService;
}());
export { PartnersPropertiesGetResolverService };
