<router-outlet></router-outlet>

<div class="position-relative">
	<div class="flex-header">
		<app-nav-bar
			[title]="'Funil de Vendas'"
			[actions]="actions"
			(actionEvent)="setAction($event)"
		>
		</app-nav-bar>
	</div>

	<app-contacts-search
		id="contacts-search"
		(changeFilters)="updateFilterSearch($event)"
		[(filtersSearch)]="filtersSearch"
	>
	</app-contacts-search>

	<div class="loading" *ngIf="searching">
		<div class="loading-icon">
			<div class="load">
				<div></div>
			</div>
		</div>
	</div>

	<div class="header" *ngIf="!MOBILE">
		<div class="max-width">
			<div class="table">
				<div class="td">
					<!-- Select -->
					<div class="btn btn-primary" (click)="deselectAll()">
						Deselecionar todos
					</div>
					<!-- Select -->

					<div
						class="header-icon d-inline-block"
						(click)="createPartners()"
					>
						<i class="far fa-handshake"></i>
					</div>
				</div>
				<div class="td">
					<div class="float-right">
						<b>Responsável</b>

						<app-select-users
							(change)="changeUsers()"
							[users]="users"
							[(ngModel)]="responsable_user"
						>
						</app-select-users>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		class="sales-funnel"
		appCompleteHeight
		[subtractHeight]="MOBILE ? 54 : 0"
		#funnelContainer
	>
		<ng-container *ngIf="start">
			<div
				class="steps"
				[sortablejsOptions]="optionsSortable"
				[sortablejs]="steps"
			>
				<div
					class="card step"
					[max]="true"
					[subtractHeight]="MOBILE ? 64 : 20"
					appCompleteHeight
					*ngFor="let step of steps; let indexStep = index"
				>
					<div class="loading" *ngIf="step.searching">
						<div class="loading-icon">
							<div class="load">
								<div></div>
							</div>
						</div>
					</div>

					<div class="step-header">
						<div class="step-name">
							<div class="table">
								<div class="td">
									<div class="d-flex">
										<div class="flex-grow-0">
											<div class="number">
												{{ step.contacts.count }}
											</div>
										</div>

										<div class="flex-grow-1 pr-2">
											<div
												class="name"
												*ngIf="!step.edit"
												(click)="editNameStep(step)"
											>
												<div
													class="color-dot"
													[ngStyle]="{
														background: step.color
													}"
													*ngIf="!!step.color"
												></div>
												{{ step.name }}
											</div>
											<div class="edit" *ngIf="step.edit">
												<input
													type="text"
													[(ngModel)]="step.name"
													class="input"
													(change)="
														changeNameStep(step)
													"
													(blur)="
														closeEditNameStep(step)
													"
													#inputNameStep
												/>
											</div>
										</div>
									</div>
								</div>

								<div
									class="td td-min"
									clickOutside
									(clickOutside)="closeColorDrop(indexStep)"
								>
									<div
										class="btn-color-wheel"
										(click)="opendDropColor(indexStep)"
									>
										<img
											src="assets/images/color-wheel.png"
											class="color-wheel"
										/>
									</div>

									<div
										class="container-color-drop"
										*ngIf="colorDrop === indexStep"
									>
										<div class="color-drop">
											<div class="row m-0">
												<div class="col-4">
													<div
														class="color-check"
														[ngClass]="{
															active:
																step.color ===
																'#007bff'
														}"
														[ngStyle]="{
															background:
																'#007bff'
														}"
														(click)="
															changeColor(
																step,
																'#007bff'
															)
														"
													></div>
												</div>

												<div class="col-4">
													<div
														class="color-check"
														[ngClass]="{
															active:
																step.color ===
																'#dc3545'
														}"
														[ngStyle]="{
															background:
																'#dc3545'
														}"
														(click)="
															changeColor(
																step,
																'#dc3545'
															)
														"
													></div>
												</div>

												<div class="col-4">
													<div
														class="color-check"
														[ngClass]="{
															active:
																step.color ===
																'#20c997'
														}"
														[ngStyle]="{
															background:
																'#20c997'
														}"
														(click)="
															changeColor(
																step,
																'#20c997'
															)
														"
													></div>
												</div>

												<div class="col-4">
													<div
														class="color-check"
														[ngClass]="{
															active:
																step.color ===
																'#6f42c1'
														}"
														[ngStyle]="{
															background:
																'#6f42c1'
														}"
														(click)="
															changeColor(
																step,
																'#6f42c1'
															)
														"
													></div>
												</div>

												<div class="col-4">
													<div
														class="color-check"
														[ngClass]="{
															active:
																step.color ===
																'#ffc107'
														}"
														[ngStyle]="{
															background:
																'#ffc107'
														}"
														(click)="
															changeColor(
																step,
																'#ffc107'
															)
														"
													></div>
												</div>

												<div class="col-4">
													<div
														class="color-check"
														[ngClass]="{
															active:
																step.color ===
																'#343A40'
														}"
														[ngStyle]="{
															background:
																'#343A40'
														}"
														(click)="
															changeColor(
																step,
																'#343A40'
															)
														"
													></div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="td td-min">
									<div
										class="text-main"
										(click)="openFilter(step)"
									>
										<i class="fas fa-search"></i>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="searching_step" *ngIf="step.search">
						{{ step.contacts.search_count }} clientes filtrados

						<div
							class="icon-close-only s18x18 float-right cursor-pointer"
							(click)="cleanSearch(step)"
						></div>
					</div>
					<div class="searching_info" *ngIf="step.search">
						Contatos inseridos em <b>{{ step.name }}</b
						>, serão colocados no topo.
					</div>

					<div
						class="contact-list"
						id="{{ step.id }}"
						[sortablejsOptions]="optionsSortableContacts"
						[sortablejs]="step.contacts.results"
					>
						<ng-container
							*ngFor="
								let contact of step.contacts.results;
								let indexContact = index
							"
						>
							<!-- Row Contacts -->
							<div class="contact">
								<div class="contact-info">
									<div class="tr">
										<div class="image-container td-min">
											<label class="checkbox-container">
												<input
													type="checkbox"
													[(ngModel)]="
														contact.checked
													"
													(change)="
														checkContact(
															contact.id,
															contact.checked
														)
													"
												/>
												<div class="checkbox"></div>
											</label>

											<app-initials [name]="contact.name">
											</app-initials>
										</div>
										<div
											class="info td-overflow-text cursor-pointer"
											[routerLink]="[
												'/sales-funnel/list/contacts/view',
												contact.id
											]"
										>
											<div class="contact-name">
												{{ contact.name }}--
											</div>
											<div class="contact-price">
												{{
													contact.propertie_search
														| buyersPrice
												}}
											</div>
										</div>
									</div>

									<div class="tr">
										<div class="td"></div>
										<div class="td">
											<div class="actions">
												<!-- Archived -->
												<button
													*ngIf="
														current_user.id ==
														responsable_user
													"
													(click)="
														archiveContact(
															contact.id,
															indexStep,
															false
														)
													"
													class="btn btn-shadow-2 btn-icon btn-round bg-white"
												>
													<i
														class="fas fa-archive"
													></i>
												</button>
												<!--/ Archived -->

												<!-- Favoritar -->
												<button
													(click)="
														favoriteContact(
															contact.id,
															indexStep,
															false
														)
													"
													*ngIf="
														contact.favorite &&
														current_user.id ==
															responsable_user
													"
													class="btn btn-shadow-2 btn-icon btn-round bg-white favorited"
												>
													<i class="fas fa-star"></i>
												</button>

												<button
													(click)="
														favoriteContact(
															contact.id,
															indexStep,
															true
														)
													"
													*ngIf="
														!contact.favorite &&
														current_user.id ==
															responsable_user
													"
													class="btn btn-shadow-2 btn-icon btn-round bg-white"
												>
													<i class="fas fa-star"></i>
												</button>
												<!--/ Favoritar -->

												<!-- Archived -->
												<a
													class="btn btn-shadow-2 btn-icon btn-round bg-white"
													*ngIf="
														contact.phones.length
													"
													href="tel:{{
														contact.phones[0].number
													}}"
												>
													<i
														class="fas fa-phone text-blue"
													></i>
												</a>
												<!--/ Archived -->

												<!-- Archived -->
												<a
													class="btn btn-shadow-2 btn-icon btn-round whatsapp"
													*ngIf="
														contact.phones.length &&
														contact.phones[0].number
															.length >= 11 &&
														!MOBILE
													"
													href="https://api.whatsapp.com/send?phone=55{{
														contact.phones[0].number
													}}"
													target="_system"
												>
													<i
														class="fab fa-whatsapp"
													></i>
												</a>
												<!--/ Archived -->

												<!-- Archived -->
												<a
													class="btn btn-shadow-2 btn-icon btn-round icon-down"
													(click)="
														setDownContact(
															indexStep,
															indexContact,
															contact.id
														)
													"
												>
													<i
														class="fas fa-arrow-down"
													></i>
												</a>
												<!--/ Archived -->
											</div>
										</div>
									</div>
								</div>

								<div
									class="tickets-users"
									*ngIf="
										contact.tickets &&
										contact.tickets.length
									"
								>
									<a
										[routerLink]="[
											'/partnership/conversation',
											ticket.id
										]"
										*ngFor="let ticket of contact.tickets"
										class="ticket-single"
									>
										<div class="ticket-name">
											{{ ticket.name }}
										</div>
										<div *ngFor="let user of ticket.users">
											<app-initials
												[name]="user.name"
												[clean]="true"
												size="xs"
											></app-initials>
											{{ user.name }} -
											{{ user.realestate.name }}
										</div>
									</a>
								</div>
							</div>
							<!--// Row Contacts -->
						</ng-container>

						<div
							class="no-contact"
							*ngIf="step.contacts.results.length == 0"
						>
							Nenhum contato
						</div>

						<div
							class="loading-icon-ring"
							*ngIf="step.load_more_contacts"
						></div>

						<!-- Carregar mais contatos lista padrão -->
						<div
							class="load_more_contacts"
							*ngIf="
								step.contacts.results.length !=
									step.contacts.count &&
								!step.load_more_contacts &&
								!step.search
							"
							(click)="loadMoreContactsStep(step)"
						>
							Carregar mais contatos
						</div>
						<!--// Carregar mais contatos lista padrão -->

						<!-- Carregar mais contatos busca -->
						<div
							class="load_more_contacts"
							*ngIf="
								step.contacts.results.length !=
									step.contacts.search_count &&
								!step.load_more_contacts &&
								step.search
							"
							(click)="loadMoreContactsStep(step)"
						>
							Carregar mais contatos
						</div>
						<!--// Carregar mais contatos busca -->
					</div>
				</div>

				<div class="card step add" [class.creating]="createrStep">
					<div
						class="add"
						*ngIf="!createrStep"
						(click)="createrStep = true"
					>
						<i class="fas fa-plus"></i> Adicionar outra etapa
					</div>

					<form
						class="form-create"
						(submit)="createStep()"
						*ngIf="createrStep"
					>
						<input
							type="text"
							placeholder="Insira o nome da etapa"
							[(ngModel)]="nameNewStep"
							[ngModelOptions]="{ standalone: true }"
							(blur)="createrStep = false"
						/>
						<button>Criar etapa</button>

						<div
							class="icon-close"
							(click)="createrStep = false"
						></div>
					</form>
				</div>
			</div>
		</ng-container>
	</div>
</div>
