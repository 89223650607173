import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {TasksService} from '../models/tasks.service';
import {Injectable} from '@angular/core';
import {TicketsService} from '../models/tickets.service';
import {ContactsService} from '../models/contacts.service';

@Injectable()
export class ContactsSearchService implements Resolve<any> {

	constructor(
		private contactsService: ContactsService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {

		let filters = this.contactsService.processFilters(route.queryParams);

		if (!!route.data.buyers) {
			filters['type_in'] = [3, 8];
		}

		return this.contactsService.search(filters);
	}
}
