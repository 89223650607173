
<app-initials [name]="name" *ngIf="!image" [size]="size" [clean]="true"></app-initials>

<div class="circle-avatar border"
     [class.big]="size == 'big'"
     [class.normal]="size == 'normal'"
     [class.small]="size == 'small'"
     [class.xs]="size == 'xss'"
     *ngIf="image">
    <img [src]="image">
</div>
