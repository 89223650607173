import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var CompleteHeightService = /** @class */ (function () {
    function CompleteHeightService() {
    }
    CompleteHeightService.update = function () {
        CompleteHeightService.updateSource.next(Math.random());
    };
    CompleteHeightService.updateSource = new BehaviorSubject(0);
    CompleteHeightService.updateEvent = CompleteHeightService.updateSource.asObservable();
    CompleteHeightService.ngInjectableDef = i0.defineInjectable({ factory: function CompleteHeightService_Factory() { return new CompleteHeightService(); }, token: CompleteHeightService, providedIn: "root" });
    return CompleteHeightService;
}());
export { CompleteHeightService };
