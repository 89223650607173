import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {interval} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-string-time',
    templateUrl: './string-time.component.html',
    styleUrls: ['./string-time.component.scss']
})
export class StringTimeComponent implements OnInit, OnDestroy {

    @Input()
    date: string;

    @Input()
    dateFormat: string;

    dateString: number;

    sub: any;

    constructor() {
    }

    ngOnInit() {
        this.dateString = moment().diff(this.date, 'seconds');

        this.sub = interval(50000).subscribe(
            data => {
                this.dateString = moment().diff(this.date, 'seconds');
            }
        );
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

}
