import { PartnersPropertiesGetResolverService } from "./content/resolves/partners-properties-get-resolver.service";
import { PartnerPropertiesResolversService } from "./content/resolves/partner-properties-resolvers.service";
import { PartnerClientDemandResolverService } from "./content/resolves/partner-chat-contact-resolver.service";
import { Routes } from "@angular/router";
import { ContactsInteractionsSingleResolver } from "./content/resolves/contacts-interactions-single-resolver";
import { TasksRealizedResolver } from "./content/resolves/tasks-realized-resolver.service";
import { ContactsInteractionsResolver } from "./content/resolves/contacts-interactions-resolver";
import { EquivalenceSubNeighborhoodResolverService } from "./content/resolves/equivalence-sub-neighborhood-resolve.service";
import { LoginComponent } from "./content/pages/login/login.component";
import { CalendarComponent } from "./content/pages/calendar/calendar.component";
import { SettingsMetatagsComponent } from "./content/pages/settings-metatags/settings-metatags.component";
import { SubNeighborhoodErrorListComponent } from "./content/pages/equivalence/sub-neighborhood-error/sub-neighborhood-error-list/sub-neighborhood-error-list.component";
import { PartnershipAddComponent } from "./content/pages/partnership/partnership-add/partnership-add.component";
import { EquivalenceCityResolverService } from "./content/resolves/equivalence-city-resolver.service";
import { UsersAddComponent } from "./content/pages/users-add/users-add.component";
import { SalesFunnelListComponent } from "./content/pages/sales-funnel-list/sales-funnel-list.component";
import { TasksEditResolver } from "./content/resolves/tasks-edit-resolver.service";
import { CityAddComponent } from "./content/pages/equivalence/city/city-add/city-add.component";
import { AgendaViewResolver } from "./content/resolves/agenda-view-resolver";
import { CityListComponent } from "./content/pages/equivalence/city/city-list/city-list.component";
import { NeighborhoodListComponent } from "./content/pages/equivalence/neighborhood/neighborhood-list/neighborhood-list.component";
import { NeighborhoodErrorListComponent } from "./content/pages/equivalence/neighborhood-error/neighborhood-error-list/neighborhood-error-list.component";
import { FunnelSalesStepsResolverService } from "./content/resolves/funnel-sales-steps-resolver.service";
import { TicketsViewComponent } from "./content/pages/tickets-view/tickets-view.component";
import { SettingsGeneralComponent } from "./content/pages/settings-general/settings-general.component";
import { NeighborhoodAddComponent } from "./content/pages/equivalence/neighborhood/neighborhood-add/neighborhood-add.component";
import { LogoutPageComponent } from "./content/pages/logout-page/logout-page.component";
import { TaskListsComponent } from "./content/pages/task-lists/task-lists.component";
import { SettingsAccountComponent } from "./content/pages/settings-account/settings-account.component";
import { PagesComponent } from "./content/pages/pages.component";
import { NeighborhoodErrorAddComponent } from "./content/pages/equivalence/neighborhood-error/neighborhood-error-add/neighborhood-error-add.component";
import { UsersListResolverService } from "./content/resolves/users-list-resolver.service";
import { TaskListAddComponent } from "./content/pages/task-lists/task-list-add/task-list-add.component";
import { SubNeighborhoodErrorAddComponent } from "./content/pages/equivalence/sub-neighborhood-error/sub-neighborhood-error-add/sub-neighborhood-error-add.component";
import { PortalsComponent } from "./content/pages/portals/portals.component";
import { ContactsViewResolver } from "./content/resolves/contacts-view-resolver";
import { TasksComponent } from "./content/pages/tasks/tasks.component";
import { TasksEditComponent } from "./content/pages/tasks-edit/tasks-edit.component";
import { PartnersContactsLoadService } from "./content/resolves/partners-contacts-load.service";
import { EquivalenceNeighborhoodErrorResolverService } from "./content/resolves/equivalence-neighborhood-error-resolve.service";
import { RealEstateGetService } from "./content/resolves/real-estate-get.service";
import { EquivalenceSubNeighborhoodErrorResolverService } from "./content/resolves/equivalence-sub-neighorhood-error-resolve.service";
import { TicketsListComponent } from "./content/pages/tickets-list/tickets-list.component";
import { EquivalenceNeighborhoodResolverService } from "./content/resolves/equivalence-neighborhood-resolve.service";
import { NotificationsComponent } from "./content/pages/notifications/notifications.component";
import { PropertiesViewResolver } from "./content/resolves/properties-view-resolver";
import { SettingsWebsiteDesignComponent } from "./content/pages/settings-website-design/settings-website-design.component";
import { SubNeighborhoodListComponent } from "./content/pages/equivalence/sub-neighborhood/sub-neighborhood-list/sub-neighborhood-list.component";
import { TasksResolver } from "./content/resolves/tasks-resolver.service";
import { CalendarViewDayComponent } from "./content/pages/calendar/calendar-view-day/calendar-view-day.component";
import { CalendarAddComponent } from "./content/pages/calendar/calendar-add/calendar-add.component";
import { NotFoundListComponent } from "./content/pages/equivalence/not-found/list/list.component";
import { NeedAuthGuardLogin } from "./services/NeedAuthGuardLogin";
import { NeedAuthGuard } from "./services/NeedAuthGuard";
import { EquivalenceNotFoundResolverService } from "./content/resolves/equivalence-not-found-resolver.service";
import { TasksListResolver } from "./content/resolves/tasks-list-resolver.service";
import { SubNeighborhoodAddComponent } from "./content/pages/equivalence/sub-neighborhood/sub-neighborhood-add/sub-neighborhood-add.component";
import { UsersListComponent } from "./content/pages/users-list/users-list.component";
import { SettingsThemeComponent } from "./content/pages/settings-theme/settings-theme.component";
import { ModalPropertyViewComponent } from "./content/components/modal-property-view/modal-property-view.component";
import { UsersViewResolver } from "./content/resolves/users-view-resolver";
import { ReloginComponent } from "./content/pages/relogin/relogin.component";
import { RegisterComponent } from "./content/pages/register/register.component";
import { CompleteRegisterGuard } from "./services/CompleteRegisterGuard";
import { ContactsViewComponent } from "./content/views/contacts/contacts-view/contacts-view.component";
import { ContactsViewAddInteractionsComponent } from "./content/views/contacts/contacts-view/contacts-view-add-interactions/contacts-view-add-interactions.component";
import { ContactsViewEditInteractionsComponent } from "./content/views/contacts/contacts-view/contacts-view-edit-interactions/contacts-view-edit-interactions.component";
var ɵ0 = {
    title: "Login"
}, ɵ1 = {
    title: "Registro"
}, ɵ2 = {
    title: "Notificações"
}, ɵ3 = {
    title: "Adicionar Corretor"
}, ɵ4 = {
    title: "Funil de Vendas"
}, ɵ5 = {
    title: "Caixa de Entrada"
}, ɵ6 = {
    title: "Calendário"
}, ɵ7 = {
    title: "Adicionar Compromissos"
}, ɵ8 = {
    title: "Configurações de Portais"
}, ɵ9 = {
    title: "Meu Perfil"
}, ɵ10 = {
    title: "Configurações do Site"
}, ɵ11 = {
    title: "Tema"
}, ɵ12 = {
    title: "SEO"
}, ɵ13 = {
    title: "Configurações"
}, ɵ14 = {
    title: "Adicionar Cidade"
}, ɵ15 = {
    title: "Adicionar Bairro"
}, ɵ16 = {
    title: "Adicionar Bairro Erro"
}, ɵ17 = {
    title: "Adicionar Sub Bairro"
}, ɵ18 = {
    title: "Adicionar Sub Bairro Erro"
};
var routes = [
    // Adicionar chat de parceria
    {
        path: "add-chat-partners",
        component: PartnershipAddComponent,
        outlet: "modal",
        resolve: {
            contacts: PartnersContactsLoadService,
            singleContact: PartnerClientDemandResolverService,
            singleProperty: PartnerPropertiesResolversService,
            property: PartnersPropertiesGetResolverService
        }
    },
    {
        path: "view-property/:id",
        component: ModalPropertyViewComponent,
        outlet: "modal",
        resolve: {
            property: PropertiesViewResolver
        }
    },
    // Login
    {
        path: "login",
        component: LoginComponent,
        canActivate: [NeedAuthGuardLogin],
        data: ɵ0
    },
    // Register
    {
        path: "register",
        component: RegisterComponent,
        canActivate: [NeedAuthGuardLogin],
        data: ɵ1
    },
    // re-login-loop
    {
        path: "re-login-loop",
        component: ReloginComponent
    },
    // Pages
    {
        path: "",
        component: PagesComponent,
        canActivate: [NeedAuthGuard],
        children: [
            // Completar Registro
            {
                path: "complete-register",
                redirectTo: "/partnership/conversation/complete-register"
            },
            // Home
            {
                path: "",
                loadChildren: "./content/views/home/home.module#HomeModule"
            },
            // Properties
            {
                path: "properties",
                loadChildren: "./content/views/properties/properties.module#PropertiesModule"
            },
            // Partnership
            {
                path: "partnership",
                loadChildren: "./content/views/partnership/partnership.module#PartnershipModule",
                canActivate: [CompleteRegisterGuard]
            },
            // Demanda de Clientes
            {
                path: "clients-shared",
                loadChildren: "./content/views/clients-shared/clients-shared.module#ClientsSharedModule"
            },
            // Demanda de Imóveis
            {
                path: "properties-demand",
                loadChildren: "./content/views/properties-demand/properties-demand.module#PropertiesDemandModule",
                canActivate: [CompleteRegisterGuard]
            },
            // Demanda de Clientes
            {
                path: "clients-demand",
                loadChildren: "./content/views/clients-demand/clients-demand.module#ClientsDemandModule",
                canActivate: [CompleteRegisterGuard]
            },
            // Contacts
            {
                path: "contacts",
                loadChildren: "./content/views/contacts/contacts.module#ContactsModule"
            },
            // Notifications
            {
                path: "notifications",
                component: NotificationsComponent,
                data: ɵ2
            },
            // Users
            {
                path: "users",
                children: [
                    {
                        path: "list",
                        component: UsersListComponent,
                        resolve: {
                            users: UsersListResolverService
                        }
                    },
                    {
                        path: "add",
                        component: UsersAddComponent,
                        data: ɵ3
                    },
                    {
                        path: "edit/:id",
                        component: UsersAddComponent,
                        resolve: {
                            user: UsersViewResolver
                        }
                    }
                ]
            },
            // Sales Funnel
            {
                path: "sales-funnel/list",
                component: SalesFunnelListComponent,
                resolve: {
                    steps: FunnelSalesStepsResolverService
                },
                data: ɵ4,
                children: [
                    {
                        path: "contacts/view/:id",
                        component: ContactsViewComponent,
                        resolve: {
                            contact: ContactsViewResolver,
                            interactions: ContactsInteractionsResolver
                        },
                        children: [
                            {
                                path: "add-interaction",
                                component: ContactsViewAddInteractionsComponent
                            },
                            {
                                path: "edit-interaction/:idInteraction",
                                component: ContactsViewEditInteractionsComponent,
                                resolve: {
                                    interaction: ContactsInteractionsSingleResolver
                                }
                            }
                        ]
                    }
                ]
            },
            // Tasks
            {
                path: "tasks",
                resolve: {
                    taskLists: TasksListResolver
                },
                children: [
                    {
                        path: "add-list",
                        component: TaskListAddComponent,
                        outlet: "modal"
                    },
                    {
                        path: "",
                        component: TasksComponent,
                        data: ɵ5,
                        children: [
                            {
                                path: "list/:id",
                                component: TaskListsComponent,
                                resolve: {
                                    tasks: TasksResolver,
                                    tasksRealized: TasksRealizedResolver
                                },
                                children: [
                                    {
                                        path: "task/:task_id",
                                        component: TasksEditComponent,
                                        resolve: {
                                            task: TasksEditResolver
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            // Calendar
            {
                path: "calendar",
                component: CalendarComponent,
                data: ɵ6,
                children: [
                    {
                        path: "add",
                        component: CalendarAddComponent,
                        data: ɵ7
                    },
                    {
                        path: "edit/:id",
                        component: CalendarAddComponent,
                        resolve: {
                            commitments: AgendaViewResolver
                        }
                    },
                    {
                        path: "day/:day/:month/:year",
                        component: CalendarViewDayComponent
                    }
                ]
            },
            // Tickets
            {
                path: "tickets",
                children: [
                    {
                        path: "list",
                        component: TicketsListComponent
                    },
                    {
                        path: "view/:id",
                        component: TicketsViewComponent
                    }
                ]
            },
            {
                path: "settings",
                children: [
                    // Portals
                    {
                        path: "portals",
                        component: PortalsComponent,
                        resolve: {
                            realestate: RealEstateGetService
                        },
                        data: ɵ8
                    },
                    // Settings
                    {
                        path: "account",
                        component: SettingsAccountComponent,
                        resolve: {
                            realestate: RealEstateGetService
                        },
                        data: ɵ9
                    },
                    {
                        path: "website",
                        component: SettingsWebsiteDesignComponent,
                        resolve: {
                            realestate: RealEstateGetService
                        },
                        data: ɵ10
                    },
                    {
                        path: "theme",
                        component: SettingsThemeComponent,
                        resolve: {
                            realestate: RealEstateGetService
                        },
                        data: ɵ11
                    },
                    {
                        path: "meta-tags",
                        component: SettingsMetatagsComponent,
                        resolve: {
                            realestate: RealEstateGetService
                        },
                        data: ɵ12
                    },
                    {
                        path: "general",
                        component: SettingsGeneralComponent,
                        resolve: {
                            realestate: RealEstateGetService
                        },
                        data: ɵ13
                    }
                ]
            },
            // Equivalencias
            {
                path: "equivalence",
                children: [
                    {
                        path: "not-found/list",
                        component: NotFoundListComponent,
                        resolve: {
                            locations: EquivalenceNotFoundResolverService
                        }
                    },
                    {
                        path: "city",
                        children: [
                            {
                                path: "add",
                                component: CityAddComponent,
                                data: ɵ14
                            },
                            {
                                path: "list",
                                component: CityListComponent,
                                resolve: {
                                    locations: EquivalenceCityResolverService
                                }
                            }
                        ]
                    },
                    {
                        path: "neighborhood",
                        children: [
                            {
                                path: "add",
                                component: NeighborhoodAddComponent,
                                data: ɵ15
                            },
                            {
                                path: "list",
                                component: NeighborhoodListComponent,
                                resolve: {
                                    locations: EquivalenceNeighborhoodResolverService
                                }
                            }
                        ]
                    },
                    {
                        path: "neighborhood-error",
                        children: [
                            {
                                path: "add",
                                component: NeighborhoodErrorAddComponent,
                                data: ɵ16
                            },
                            {
                                path: "list",
                                component: NeighborhoodErrorListComponent,
                                resolve: {
                                    locations: EquivalenceNeighborhoodErrorResolverService
                                }
                            }
                        ]
                    },
                    {
                        path: "sub-neighborhood",
                        children: [
                            {
                                path: "add",
                                component: SubNeighborhoodAddComponent,
                                data: ɵ17
                            },
                            {
                                path: "list",
                                component: SubNeighborhoodListComponent,
                                resolve: {
                                    locations: EquivalenceSubNeighborhoodResolverService
                                }
                            }
                        ]
                    },
                    {
                        path: "sub-neighborhood-error",
                        children: [
                            {
                                path: "add",
                                component: SubNeighborhoodErrorAddComponent,
                                data: ɵ18
                            },
                            {
                                path: "list",
                                component: SubNeighborhoodErrorListComponent,
                                resolve: {
                                    locations: EquivalenceSubNeighborhoodErrorResolverService
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: "logout",
                component: LogoutPageComponent
            }
        ]
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18 };
