<div class="task-list-content">
	<div class="content-list">
		<div class="content-list--inside scroll-height" appCompleteHeight>
			<div class="background-close-edit" (click)="closeEdit()"></div>

			<div class="todo">
				<form (submit)="createTask()">
					<input
						type="text"
						placeholder="Adicionar um tarefa..."
						maxlength="180"
						name="taskname"
						[(ngModel)]="taskName"
					/>
				</form>
			</div>

			<div
				class="tasks-all"
				id="no-realized"
				[sortablejsOptions]="options"
				[sortablejs]="tasks"
			>
				<!-- Task Card -->
				<div class="task" *ngFor="let task of tasks">
					<div
						class="card"
						[class.future]="task.status_date == 'future'"
						[class.expired]="task.status_date == 'expired'"
						[class.today]="task.status_date == 'today'"
						[class.active]="selectTask == task.task_id"
						(click)="clickTask(task.task_id)"
						(dblclick)="dblclickTask(task.task_id)"
					>
						<div class="status">
							<div class="bar"></div>
						</div>

						<div class="checkbox">
							<input
								type="checkbox"
								[checked]="task.realized"
								(change)="realizeTask(task.task_id, true)"
							/>
						</div>

						<div class="name td-max-width-0">
							<div class="text-ellipsis">
								<!-- Mini Tag de Status-->
								<div
									class="tag-status"
									*ngIf="task.status_date == 'expired'"
								>
									Vencido
								</div>
								<div
									class="tag-status"
									*ngIf="task.status_date == 'today'"
								>
									Hoje
								</div>
								<!--// Mini Tag de Status-->

								{{ task.name }}
							</div>
							<div class="date" *ngIf="task.deadline_at">
								{{
									task.deadline_at
										| amDateFormat: "DD-MM-YYYY HH:mm"
								}}
							</div>
							<div class="text-primary" *ngIf="task.contact">
								<app-initials
									[name]="task.contact.name"
									[size]="'xs'"
								></app-initials>
								{{ task.contact.name }}
							</div>
						</div>

						<div class="actions">
							<i
								class="far fa-comment comments"
								*ngIf="task.group_comments.length"
							></i>

							<span class="pr-2" *ngIf="task.assign_user">
								<app-user-img
									[user]="task.assign_user"
									[size]="'small'"
								></app-user-img>
							</span>

							<!-- Favorito -->
							<i
								class="far fa-star favorite"
								(click)="
									setFavorite(
										task.task_id,
										!task.favorite,
										$event
									)
								"
								*ngIf="!task.favorite"
							></i>
							<i
								class="fas fa-star favorite favorited"
								(click)="
									setFavorite(
										task.task_id,
										!task.favorite,
										$event
									)
								"
								*ngIf="task.favorite"
							></i>
							<!--// Favorito -->
						</div>
					</div>
				</div>
				<!--// Task Card -->
			</div>

			<div class="show-finish">
				Mostrar tarefas concluidas
			</div>

			<div
				class="tasks-all tasks-realized"
				id="realized"
				[sortablejsOptions]="options"
				[sortablejs]="tasksRealized"
			>
				<!-- Task Card -->
				<div class="task" *ngFor="let task of tasksRealized">
					<div
						class="card"
						[class.future]="task.status_date == 'future'"
						[class.expired]="task.status_date == 'expired'"
						[class.today]="task.status_date == 'today'"
						[class.active]="selectTask == task.task_id"
						(click)="clickTask(task.task_id)"
						(dblclick)="dblclickTask(task.task_id)"
					>
						<div class="status">
							<div class="bar"></div>
						</div>

						<div class="checkbox">
							<input
								type="checkbox"
								[checked]="task.realized"
								(change)="realizeTask(task.task_id, true)"
							/>
						</div>

						<div class="name td-max-width-0">
							<div class="text-ellipsis">
								<!-- Mini Tag de Status-->
								<div
									class="tag-status"
									*ngIf="task.status_date == 'expired'"
								>
									Vencido
								</div>
								<div
									class="tag-status"
									*ngIf="task.status_date == 'today'"
								>
									Hoje
								</div>
								<!--// Mini Tag de Status-->

								{{ task.name }}
							</div>
							<div class="date" *ngIf="task.deadline_at">
								{{
									task.deadline_at
										| amDateFormat: "DD-MM-YYYY HH:mm"
								}}
							</div>
							<div class="text-primary" *ngIf="task.contact">
								<app-initials
									[name]="task.contact.name"
									[size]="'xs'"
								></app-initials>
								{{ task.contact.name }}
							</div>
						</div>

						<div class="actions">
							<i
								class="far fa-comment comments"
								*ngIf="task.group_comments.length"
							></i>

							<span class="pr-2" *ngIf="task.assign_user">
								<app-user-img
									[user]="task.assign_user"
									[size]="'small'"
								></app-user-img>
							</span>

							<!-- Favorito -->
							<i
								class="far fa-star favorite"
								(click)="
									setFavorite(
										task.task_id,
										!task.favorite,
										$event
									)
								"
								*ngIf="!task.favorite"
							></i>
							<i
								class="fas fa-star favorite favorited"
								(click)="
									setFavorite(
										task.task_id,
										!task.favorite,
										$event
									)
								"
								*ngIf="task.favorite"
							></i>
							<!--// Favorito -->
						</div>
					</div>
				</div>
				<!--// Task Card -->
			</div>
		</div>
	</div>

	<div class="task-edit-column">
		<div class="task-edit-column--animation" [class.open]="openEdit">
			<div class="loading-icon" *ngIf="loading">
				<div class="load">
					<div></div>
					<div></div>
				</div>
			</div>
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
