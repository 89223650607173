import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {PropertiesService} from '../models/properties.service';
import {ContactsService} from '../models/contacts.service';
import {ContactsInteractionsService} from '../models/contacts-interactions.service';

@Injectable()
export class ContactsInteractionsSingleResolver implements Resolve<any> {

	constructor(
		private contactsInteractionsService: ContactsInteractionsService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {
		return this.contactsInteractionsService
			.setContact(route.parent.params.id)
			.get(route.params.idInteraction);
	}
}

