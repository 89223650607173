/**
 * Interface Users
 */
export class Contacts {
	id: number;
	cod: string;
	email?: string;
	name: string;
	phones?: any;
	type: number;
	funnel_step: number;
	funnel_step_index: number;
	status: number;
	type_name?: string;
	created_at?: string;
	favorite?: boolean;
	archive?: boolean;
	propertie_search?: [any];
	updated_at?: string;
	tickets_cache?: {
		id: number,
		name: string,
		users_participants: number[],
		closed: number,
	}[];

	type_background?: string;
	checked?: boolean;
}
