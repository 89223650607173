import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Helpers } from '../../../../../services/helpers';
import { skip } from 'rxjs/operators';
import { EquivalenceNotFoundService } from '../../../../models/equivalence-not-found.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})
export class NotFoundListComponent implements OnInit {

	locations: NotFound[];
	locations_count = 0;
	filters: FormGroup;

	perPage = 40;
	currentPage = 1;

	// Buscando...
	searching = false;
	currentLocation: NotFound;
	modalStatus = false;

	delete_modal: boolean;
	delete_item_id: number;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private equivalenceNotFoundService: EquivalenceNotFoundService,
		private _toastrService: ToastrService
	) {

	}

	ngOnInit() {

		this.locations = this.route.snapshot.data.locations.results;
		this.locations_count = this.route.snapshot.data.locations.count;

		this.configureFilters(this.route.snapshot.queryParams);

		this.route.queryParams
			.pipe(skip(1))
			.subscribe(data => {
				this.loadLocations(data);
			});
	}

	// Abre o modal e define uma localizacao como atual
	openModal(location: NotFound) {
		this.currentLocation = location;
		this.modalStatus = true;
	}

	configureFilters(data: any = {}) {

		this.filters = this.fb.group({
			sort: [data.sort || '', []],
			limit: [data.limit || '', []],
			textual_search: [data.textual_search || '', []],
			offset: [data.offset, []],
		});

		console.log(this.filters);
	}

	sort(sort: string) {

	}

	/**
     * Resgata mais contatos
     * @param data
     * @returns {Subscription}
     */
	loadLocations(data) {

		if (this.searching) {
			return false;
		}

		this.searching = true;

		return this.equivalenceNotFoundService
			.search(data)
			.subscribe(locations => {
				this.locations = locations.results;
				this.locations_count = locations.count;
				this.searching = false;
			});
	}

	/**
     * Faz o tratamento dos filtros antes da busca
     * @returns {any}
     */
	getFilters() {
		let filters = { ... this.filters.value };

		return filters;
	}

	/**
     * Atualiza novos parametros
     */
	updateParams(newSearch = true) {
		// Reset página
		if (newSearch) {
			this.filters.controls.offset.setValue(undefined);
			this.currentPage = 1;
		}

		this.router.navigate([], {
			queryParams: Helpers.filterArray(this.getFilters(), [
				Helpers.WITH_ZERO
			])
		});
	}

	/**
     * Trocar página
     * @param {number} page
     */
	setPage(page: number) {
		page = page - 1;

		this.filters.controls.offset.setValue(page * this.perPage);

		this.updateParams(false);
	}

	setItemDelete(id: number) {
		this.delete_item_id = id;
		this.delete_modal = true;
	}

	deleteConfirm() {
		this.equivalenceNotFoundService.delete(this.delete_item_id)
			.subscribe((res) => {
				this._toastrService.success('Deletado com sucesso!');
				this.loadLocations(this.route.snapshot.queryParams);
			}, (err) => {
				this._toastrService.error(`Erro ao deletar.`);
			});
	}

}

interface NotFound {
	id: number;
	error_count: number;
	city: string;
	estate: string;
	neighborhood: string;
}
