import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Global} from '../../services/global';

@Injectable({
	providedIn: 'root'
})
export class GlobalService {

	private userSource = new BehaviorSubject(false);
	user = this.userSource.asObservable();

	private badgesSource = new BehaviorSubject(false);
	badges = this.badgesSource.asObservable();

	private loadingScreenSource = new BehaviorSubject(true);
	loadingScreen = this.loadingScreenSource.asObservable();

	private menuMobileSource = new BehaviorSubject(false);
	menuMobile = this.menuMobileSource.asObservable();

	private menuFastMobileSource = new BehaviorSubject(false);
	menuFastMobile = this.menuFastMobileSource.asObservable();

	constructor() {
	}

	updateUser(user: any) {
		Global.user = user;
		this.userSource.next(user);
	}

	updateBadge(badges: any) {
		Global.badges = badges;
		this.badgesSource.next(badges);
	}

	updateLoadingScreen(loadingScreen: any) {
		console.log(loadingScreen);
		this.loadingScreenSource.next(loadingScreen);
	}

	updateMenuMobile(menuMobile: any) {
		this.menuMobileSource.next(menuMobile);
	}

	updateFastMenuMobile(menuFastMobile: any) {
		this.menuFastMobileSource.next(menuFastMobile);
	}

}
