import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ContactsInteractionsSingleResolver } from "../../resolves/contacts-interactions-single-resolver";
import { ContactsListComponent } from "./contacts-list/contacts-list.component";
import { ContactsViewResolver } from "../../resolves/contacts-view-resolver";
import { ContactsInteractionsResolver } from "../../resolves/contacts-interactions-resolver";
import { ContactsSearchService } from "../../resolves/contacts-search.service";
import { ContactsAddComponent } from "./contacts-add/contacts-add.component";
import { ContactsViewAddInteractionsComponent } from "./contacts-view/contacts-view-add-interactions/contacts-view-add-interactions.component";
import { ContactsViewEditInteractionsComponent } from "./contacts-view/contacts-view-edit-interactions/contacts-view-edit-interactions.component";
import { ContactsInfoResolver } from "../../resolves/contacts-info-resolver";
import { ContactsViewComponent } from "./contacts-view/contacts-view.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ComponentsModule } from "../../components/components.module";
import { SortablejsModule } from "angular-sortablejs";
import { CommonModule } from "@angular/common";
import { MomentModule } from "angular2-moment";

const routes: Routes = [
	{
		path: "",
		children: [
			{
				path: "list",
				component: ContactsListComponent,
				resolve: {
					contacts: ContactsSearchService
				}
			},
			{
				path: "buyers/lists",
				component: ContactsListComponent,
				data: {
					buyers: true
				},
				resolve: {
					contacts: ContactsSearchService
				}
			},
			{
				path: "add",
				component: ContactsAddComponent,
				resolve: {
					// 'properties_info': ContactsInfoResolver
				},
				data: {
					title: "Adicionar Contato"
				}
			},
			{
				path: "edit/:id",
				component: ContactsAddComponent,
				resolve: {
					// 'property': ContactsViewResolver,
					// 'properties_info': ContactsInfoResolver
				}
			},
			{
				path: "view/:id",
				component: ContactsViewComponent,
				resolve: {
					contact: ContactsViewResolver,
					interactions: ContactsInteractionsResolver
				},
				children: [
					{
						path: "add-interaction",
						component: ContactsViewAddInteractionsComponent
					},
					{
						path: "edit-interaction/:idInteraction",
						component: ContactsViewEditInteractionsComponent,
						resolve: {
							interaction: ContactsInteractionsSingleResolver
						}
					}
				]
			}
		]
	}
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SortablejsModule,
		ComponentsModule,
		MomentModule,
		RouterModule.forChild(routes)
	],
	exports: [RouterModule],
	declarations: [
		ContactsListComponent,
		ContactsListComponent,
		ContactsAddComponent,
		ContactsAddComponent,
		ContactsViewComponent,
		ContactsViewAddInteractionsComponent,
		ContactsViewEditInteractionsComponent
	]
})
export class ContactsRoutingModule {}
