import {Injectable} from '@angular/core';
import {CrudManager} from '../../services/crud-manager';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AgendaService extends CrudManager {
    path = '/admin/agenda';

    constructor() {
        super();
    }
}

