<br>
<div class="ticket-view">
    <div class="max-width">
        <div class="row">
            <div class="col-md-8">
                <!-- Ticket Post -->
                <div class="card">
                    <div class="post-container">

                        <div class="image-container">
                            <div class="circle-avatar circle-avatar-mini">
                                <img src="/src/assets/temp/contact-none-woman.jpg">
                            </div>
                        </div>

                        <div class="post-content">
                            <div class="name">Banco dos Imóveis</div>
                            <div class="date">24 Jul 2018 - 03:10</div>
                            <div class="message">
                                <p>Na verdade esse cliente quer um sobrado em condomínio fechado até 250.000, oferecer aquele que o cliente do Ailton está fechando.</p>
                                <p>Na verdade esse cliente quer um sobrado em condomínio fechado até 250.000, oferecer aquele que o cliente do Ailton está fechando.</p>
                            </div>

                            <div class="files division">
                                <div class="d-title">Arquivos Anexados</div>

                                <!-- File -->
                                <div class="table file">
                                    <div class="td td-min">
                                        <div class="file-icon" data-type="doc"></div>
                                    </div>
                                    <div class="td p-l-10">
                                        <div class="file-name">vertigo.docx</div>
                                        <div class="file-size">421.9KB</div>
                                    </div>
                                </div>
                                <!--// File -->

                                <!-- File -->
                                <div class="table file">
                                    <div class="td td-min">
                                        <div class="file-icon" data-type="doc"></div>
                                    </div>
                                    <div class="td p-l-10">
                                        <div class="file-name">vertigo.docx</div>
                                        <div class="file-size">421.9KB</div>
                                    </div>
                                </div>
                                <!--// File -->

                            </div>

                            <div class="images division">
                                <div class="d-title">Imagens Anexados</div>
                                <!-- Image -->
                                <div class="image" style="background-image: url('https://cdn-images-1.medium.com/max/1600/1*6EbSjy9tyK1Woz_V2p9uPQ.png')">
                                </div>
                                <!--// Image -->
                                <!-- Image -->
                                <div class="image" style="background-image: url('https://cdn-images-1.medium.com/max/1600/1*6EbSjy9tyK1Woz_V2p9uPQ.png')">
                                </div>
                                <!--// Image -->
                            </div>

                        </div>
                    </div>
                </div>
                <!--// Ticket Post -->

            </div>
            <div class="col-md-4">
                <!-- Informações do ticket -->
                <div class="card ticket-info contact-list">
                    <div class="card-title">
                        Contatos associados
                    </div>

                    <div class="contact-user">
                        <div class="image-container">
                            <div class="initials-letter">
                                F
                            </div>
                        </div>

                        <div class="name-container">
                            <div class="name">
                                <div class="cod">232</div>
                                Alberto Ammar
                            </div>

                            <div class="contact">
                                <div class="email">
                                    <span>
                                        <i class="far fa-envelope icon"></i>
                                        <a href="" class="dashed">albertoammarw@gmail.com</a>
                                    </span>
                                </div>
                                <div class="phone">
                                    <span>
                                        <i class="fas fa-phone icon"></i>
                                        <a href="" class="dashed">(11) 99989-4641</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contact-user">
                        <div class="image-container">
                            <div class="initials-letter">
                                F
                            </div>
                        </div>

                        <div class="name-container">
                            <div class="name">
                                <div class="cod">232</div>
                                Alberto Ammar
                            </div>

                            <div class="contact">
                                <div class="email">
                                    <span>
                                        <i class="far fa-envelope icon"></i>
                                        <a href="" class="dashed">albertoammarw@gmail.com</a>
                                    </span>
                                </div>
                                <div class="phone">
                                    <span>
                                        <i class="fas fa-phone icon"></i>
                                        <a href="" class="dashed">(11) 99989-4641</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--// Informações do ticket -->

                <!-- Informações do ticket -->
                <div class="card ticket-info">
                    <div class="card-title">
                        Informações do Ticket
                    </div>

                    <div class="row p-0 m-0">
                        <div class="col-md-6 p-0 m-0 ticket-info-key">
                            ID
                        </div>
                        <div class="col-md-6 p-0 m-0 ticket-info-value">
                            #231
                        </div>
                    </div>

                    <div class="row p-0 m-0">
                        <div class="col-md-6 p-0 m-0 ticket-info-key">
                            Departamento
                        </div>
                        <div class="col-md-6 p-0 m-0 ticket-info-value">
                            Suporte e Dúvidas
                        </div>
                    </div>

                    <div class="row p-0 m-0">
                        <div class="col-md-6 p-0 m-0 ticket-info-key">
                            Prioridade
                        </div>
                        <div class="col-md-6 p-0 m-0 ticket-info-value">
                            <div class="low">Baixa</div>
                        </div>
                    </div>

                    <div class="row p-0 m-0">
                        <div class="col-md-6 p-0 m-0 ticket-info-key">
                            Status
                        </div>
                        <div class="col-md-6 p-0 m-0 ticket-info-value">
                            <div class="low">Aberto</div>
                        </div>
                    </div>

                    <div class="row p-0 m-0">
                        <div class="col-md-6 p-0 m-0 ticket-info-key">
                            Termômetro
                        </div>
                        <div class="col-md-6 p-0 m-0 ticket-info-value">
                            <div class="low">Frio</div>
                        </div>
                    </div>
                </div>
                <!--//Informações do ticket -->
            </div>
        </div>
    </div>
</div>
