<div class="property-add mobile-form page-flex">

	<div class="flex-header">
		<app-nav-bar [title]="!this.id ? 'Adicionar Contato' : 'Editar Contato #' + this.contact.cod" [backIcon]="true" (backEvent)="backUrl()">
		</app-nav-bar>
	</div>

	<div class="flex-body">

		<app-loading-page [show]="starting"></app-loading-page>

		<div class="max-width">
			<app-modal-contact-duplicate [contactPage]="true" [contact]="contactDuplicate" [type]="contactDuplicateType"
				(useOtherPhone)="useOtherContact($event)" (useOtherEmail)="useOtherContact($event)"
				(useThisContact)="useThisContact($event)" id="user-duplicate">
			</app-modal-contact-duplicate>

			<form [formGroup]="form">

				<div class="card p-4 mb-4">
					<div class="title-form">
						<i class="fas fa-user"></i> Informações Básicas
						<small>Informe os dados do básicos do contato</small>
					</div>

					<span>

						<div class="row">

							<div class="col-md-2">
								<div class="field-container" [class.error-input]="isFieldValid('cod')">
									<input type="text" formControlName="cod" [placeholder]="nextCod" autocomplete="cWebnifLT"
										[currentId]="id" appContactsDuplicateCod>
									<div class="name">Código</div>
								</div>

								<app-field-error-display [displayError]="isFieldValid('cod')"
									errorMsg="Este código já está em uso!">
								</app-field-error-display>
							</div>

							<div class="col-md-4">
								<div class="field-container" [class.error-input]="isFieldValid('name')">
									<input type="text" formControlName="name" autocomplete="cWebnifLT">
									<div class="name">Nome</div>
								</div>

								<app-field-error-display [displayError]="isFieldValid('name')"
									errorMsg="Informe o nome do contato!">
								</app-field-error-display>
							</div>

							<div class="col-md-4">
								<div class="field-container" [class.error-input]="isFieldValid('email')">
									<input type="text" autocomplete="cWebnifLT"
										(appContactDuplicateEmail)="setContactDuplicate($event, 'email')"
										[contactId]="contact.id"
										formControlName="email">
									<div class="name">EMAIL</div>

									<app-field-error-display [displayError]="isFieldValid('email')"
										errorMsg="Informe um email válido">
									</app-field-error-display>
								</div>
							</div>
						</div>

						<span formArrayName="phones">

							<div class="pt-2" *ngFor="let phone of form.get('phones')['controls']; let i=index">

								<div class="text-primary font-weight-bold">
									TELEFONE {{i + 1}}

									<span (click)="removePhone(i)" *ngIf="form.controls['phones'].length > 1">X</span>
								</div>

								<div class="table">
									<div class="row" [formGroupName]="i">
										<div class="col-md-4">
											<div class="select-container">
												<select formControlName="type">
													<option [value]="'Residencial'">Residencial</option>
													<option [value]="'Celular'">Celular</option>
													<option [value]="'Comercial'">Comercial</option>
													<option [value]="'FAX'">FAX</option>
													<option [value]="'Whatsapp'">Whatsapp</option>
												</select>
												<div class="name">Tipo</div>
											</div>
										</div>

										<div class="col-md-4">
											<div class="select-container">
												<select formControlName="operator">
													<option [value]="'--'">--</option>
													<option [value]="'vivo'">Vivo</option>
													<option [value]="'claro'">Claro</option>
													<option [value]="'oi'">Oi</option>
													<option [value]="'tim'">Tim</option>
													<option [value]="'nextel'">Nextel</option>
													<option [value]="'outras'">Outras</option>
												</select>
												<div class="name">Operadora</div>
											</div>
										</div>

										<div class="col-md-4">
											<div class="field-container">
												<input type="text" formControlName="number"
													(appContactDuplicatePhone)="setContactDuplicate($event, 'phone')"
													appMaskPhone>
												<div class="name">Telefone</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="btn btn-primary" (click)="addPhone()">Adicionar telefone</div>
						</span>

					</span>
				</div>

				<div class="card p-4 mb-4">
					<div class="title-form">
						<i class="fas fa-info-circle"></i> Informações básicas
						<small>Informações básicas do imóvel</small>
					</div>

					<!-- Tipo do Contato -->
					<div class="pb-0">
						<div class="subtitle-form">
							<i class="fas fa-caret-right"></i> Tipo do Contato
						</div>

						<div class="btn-group-checked">
							<div class="btn" [class.active]="s.id == contact.type" (click)="setType(s)" *ngFor="let s of types">
								{{s.name}}
							</div>
						</div>
					</div>
					<!--// Tipo do Contato -->

					<div [@displayForm]="displayForm" *ngIf="displayForm">
						<!-- Corretor Responsável -->
						<div class="pb-0" formArrayName="realtors_managers">
							<div class="subtitle-form">
								<i class="fas fa-caret-right"></i> Corretor Responsável
							</div>

							<div class="row">
								<div *ngFor="let user of form.controls.realtors_managers.controls; let i = index"
									class="col-md-4 col-sm-6 col-xs-12">
									<label class="checkbox-container clean">
										<input type="checkbox" [formControlName]="i">
										<div class="checkbox"></div>
										<span class="text">
											<app-user-img [user]="users_admin[i]" [size]="'small'"></app-user-img>
											{{users_admin[i].name}}
										</span>
									</label>
								</div>
							</div>
						</div>
						<!--// Corretor Responsável -->

						<!-- Etapa do Funil -->
						<div class="pb-0" *ngIf="this.contact.type === 3 || this.contact.type === 8">
							<div class="subtitle-form">
								<i class="fas fa-caret-right"></i> Etapa do Funil
							</div>

							<div class="btn-group-checked">
								<div class="btn" [class.active]="s.id == contact.funnel_step" (click)="setFunnelStep(s)"
									*ngFor="let s of steps_funnel">
									{{s.name}}
								</div>
							</div>
						</div>
						<!--// Etapa do Funil -->
					</div>
				</div>

				<div class="card p-4 mb-4" formArrayName="propertie_search" *ngIf="displayForm && this.contact.type !== 1">
					<div class="title-form">
						<i class="fas fa-home"></i> Perfil do imóvel buscado
						<small>Coloque as especificações do imóvel que o cliente deseja encontrar</small>
					</div>

					<div class="alert-dark p-4 font-weight-500">
						- O <span class="color-green font-weight-bold">Perfil de imóvel buscado</span>, tem a finalidade de
						auxiliar na busca do comprador pelo imóvel que o mesmo procura.<br>
						- Seu preenchimento é opcional, mas caso seja inserido algum valor em qualquer um dos campos, <span
							class="color-green font-weight-bold">Estado</span> e <span
							class="color-green font-weight-bold">Cidade</span> se tornam obrigatórios.
					</div>

					<div class="tabs">
						<div *ngFor="let property_search of form.get('propertie_search').controls; let i=index" class="tab"
							(click)="propertie_search_tab = i" [class.active]="propertie_search_tab == i">
							Perfil #{{i + 1}}

							<span class="icon-close" *ngIf="form.get('propertie_search').controls.length > 1"
								(click)="removePerfilSearch(i)"></span>
						</div>

						<div class="tab" (click)="addPerfilSearch()">
							<i class="fas fa-plus text-green"></i>
						</div>
					</div>

					<div *ngFor="let property_search of form.get('propertie_search').controls; let i=index">
						<div [formGroupName]="i" *ngIf="propertie_search_tab == i" class="pt-2">

							<div class="btn btn-danger clean-perfil" (click)="cleanPerfilSearch(i)">
								<i class="far fa-trash-alt"></i> Limpar Perfil
							</div>

							<div class="row" formGroupName="location">
								<div class="col-md-4">
									<div class="select-container"
										[class.error-input]="isFieldValid('propertie_search.'+ i +'.location.estate')">
										<select type="text" formControlName="estate">
											<option [value]="''">Selecione um Estado</option>
											<option *ngFor="let estate of estates" [value]="estate.UF">
												{{ estate.name }}
											</option>
										</select>
										<div class="name">Estado</div>
									</div>

									<app-field-error-display
										[displayError]="isFieldValid('propertie_search.'+ i +'.location.estate')"
										errorMsg="Informe o estado">
									</app-field-error-display>
								</div>

								<div class="col-md-4">
									<div class="field-container">
										<div [class.error-input]="isFieldValid('propertie_search.'+ i +'.location.city')">
											<input-autocomplete-location
												[error]="isFieldValid('propertie_search.'+ i +'.location.city')" [city]="true"
												formControlName="city">
											</input-autocomplete-location>
										</div>
										<div class="name">Cidade</div>
									</div>

									<app-field-error-display
										[displayError]="isFieldValid('propertie_search.'+ i +'.location.city')"
										errorMsg="Este campo é obrigatório!">
									</app-field-error-display>
								</div>

								<div class="col-md-4">

									<div class="field-container">
										<div
											[class.error-input]="isFieldValid('propertie_search.'+ i +'.location.neighborhood')">
											<input-autocomplete-location
												[error]="isFieldValid('propertie_search.'+ i +'.location.neighborhood')"
												[onlyInCity]="this.form.get('propertie_search.'+ i +'.location.city').value"
												[neighborhood]="true" formControlName="neighborhood">
											</input-autocomplete-location>
										</div>
										<div class="name">Bairro</div>
									</div>

									<app-field-error-display
										[displayError]="isFieldValid('propertie_search.'+ i +'.location.neighborhood')"
										errorMsg="Este campo é obrigatório!">
									</app-field-error-display>
								</div>
							</div>

							<div class="row">
								<div class="col-md-5">
									<div class="field-container price"
										[class.error-input]="isFieldValid('propertie_search.'+ i +'.price')">
										<input type="text" formControlName="price" appMaskNumber>
										<div class="name">VALOR BUSCADO</div>
										<small>
											* R$ 0,00 será considerado qualquer valor
										</small>

										<app-field-error-display [displayError]="isFieldValid('propertie_search.'+ i +'.price')"
											errorMsg="Informe o valor buscado!">
										</app-field-error-display>
									</div>
								</div>
							</div>

							<div>
								<div class="subtitle-form">
									<i class="fas fa-caret-right"></i> Tipos de Imóvel
								</div>
								<div class="row" formArrayName="types">
									<div class="col-md-3"
										*ngFor="let type of form.get('propertie_search.'+ i +'.types').controls; let x = index">
										<label class="checkbox-container clean">
											<input type="checkbox" [formControlName]="x">
											<div class="checkbox"></div>
											<span class="text">{{ types_properties[x].name }}</span>
										</label>
									</div>
								</div>
							</div>

							<!-- Composição do Imóvel -->
							<div class="pb-0">
								<div class="subtitle-form">
									<i class="fas fa-caret-right"></i> Composição
								</div>

								<div class="row">

									<div class="col-md-3 text-center">
										<div class="field-container pr-sm-0">
											<input-number-increment formControlName="bedroom">
											</input-number-increment>
											<div class="name text-center">Quartos</div>
										</div>
									</div>

									<div class="col-md-3 text-center">
										<div class="field-container pr-sm-0">
											<input-number-increment formControlName="suite">
											</input-number-increment>
											<div class="name text-center">Suites</div>
										</div>
									</div>

									<div class="col-md-3 text-center">
										<div class="field-container pr-sm-0">
											<input-number-increment formControlName="bathroom">
											</input-number-increment>
											<div class="name text-center">Banheiros</div>
										</div>
									</div>

									<div class="col-md-3 text-center">
										<div class="field-container pr-sm-0">
											<input-number-increment formControlName="vacancy">
											</input-number-increment>
											<div class="name text-center">Vagas</div>
										</div>
									</div>

								</div>
							</div>
							<!--// Composição do Imóvel -->

							<!-- Medidas do Imóvel -->
							<div class="pb-0">
								<div class="subtitle-form">
									<i class="fas fa-caret-right"></i> Medidas
								</div>

								<div class="row">

									<div class="col-md-3 text-center">
										<div class="field-container area">
											<input type="text" formControlName="area_useful" appMaskNumber [decimals]="0">
											<div class="name">Área de Útil</div>
										</div>
									</div>

									<div class="col-md-3 text-center">
										<div class="field-container area">
											<input type="text" formControlName="area_useful" appMaskNumber [decimals]="0">
											<div class="name">Área de Total</div>
										</div>
									</div>

								</div>
							</div>
							<!--// Medidas do Imóvel -->
						</div>
					</div>
				</div>

				<!-- Localização -->
				<div class="card p-4 mb-4">
					<div class="title-form">
						<i class="fas fa-map-marker-alt"></i> Localização
						<small>Informe a localização do imóvel</small>
					</div>

					<div class="row">
						<div class="col-md-4">
							<div class="field-container">
								<input type="text" formControlName="CEP" appMaskCep>
								<div class="name">CEP</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-6">
							<div class="field-container">
								<input type="text" formControlName="address">
								<div class="name">Endereço</div>
							</div>
						</div>

						<div class="col-md-2">
							<div class="field-container">
								<input type="number" formControlName="number">
								<div class="name">Número</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-4">
							<div class="select-container" [class.error-input]="isFieldValid('estate')">
								<select type="text" formControlName="estate">
									<option [value]="''">Selecione um estado</option>
									<option *ngFor="let estate of estates" [value]="estate.uf">
										{{ estate.name }}
									</option>
								</select>
								<div class="name">Estado</div>
							</div>

							<app-field-error-display [displayError]="isFieldValid('estate')" errorMsg="Informe o estado">
							</app-field-error-display>
						</div>

						<div class="col-md-4">
							<div class="field-container">
								<div [class.error-input]="isFieldValid('city')">
									<input-autocomplete-location [error]="isFieldValid('city')" [city]="true"
										formControlName="city">
									</input-autocomplete-location>
								</div>
								<div class="name">Cidade</div>
							</div>

							<app-field-error-display [displayError]="isFieldValid('city')" errorMsg="Este campo é obrigatório!">
							</app-field-error-display>
						</div>

						<div class="col-md-4">

							<div class="field-container">
								<div [class.error-input]="isFieldValid('neighborhood')">
									<input-autocomplete-location [error]="isFieldValid('neighborhood')"
										[onlyInCity]="this.form.get('city').value" [neighborhood]="true"
										formControlName="neighborhood">
									</input-autocomplete-location>
								</div>
								<div class="name">Bairro</div>
							</div>

							<app-field-error-display [displayError]="isFieldValid('neighborhood')"
								errorMsg="Este campo é obrigatório!">
							</app-field-error-display>
						</div>
					</div>

				</div>
				<!--// Localização -->

				<!-- Informações adicionais -->
				<div class="card p-4 mb-4">
					<div class="title-form">
						<i class="fas fa-map-marker-alt"></i> Informações adicionais
						<small>Demais informações do contato</small>
					</div>

					<div class="row">

						<div class="col-md-4">
							<div class="field-container">
								<div class="input-space">
									<div class="row">
										<div class="col-md-6 p-0">
											<label class="checkbox-container clean">
												<input type="radio" [value]="1" name="gender" formControlName="gender">
												<div class="checkbox"></div>
												<span class="text">
													Feminino
												</span>
											</label>
										</div>

										<div class="col-md-6 p-0">
											<label class="checkbox-container clean">
												<input type="radio" [value]="2" name="gender" formControlName="gender">
												<div class="checkbox"></div>
												<span class="text">
													Masculino
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="name">Sexo</div>
							</div>
						</div>

						<div class="col-md-4">
							<div class="field-container">
								<div class="input-space">
									<div class="row">
										<div class="col-md-6 p-0">
											<label class="checkbox-container clean">
												<input type="radio" [value]="1" name="type_people"
													formControlName="type_people">
												<div class="checkbox"></div>
												<span class="text">
													Física
												</span>
											</label>
										</div>

										<div class="col-md-6 p-0">
											<label class="checkbox-container clean">
												<input type="radio" [value]="2" name="type_people"
													formControlName="type_people">
												<div class="checkbox"></div>
												<span class="text">
													Jurídica
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="name">Tipo</div>
							</div>
						</div>

						<div class="col-md-4">
							<div class="field-container">
								<input type="text" formControlName="date_birth" appMaskBirth>
								<div class="name">Data de Nascimento</div>
							</div>
						</div>

						<div class="col-md-4">
							<div class="field-container">
								<input type="text" formControlName="RG" appMaskRG>
								<div class="name">RG</div>
							</div>
						</div>

						<div class="col-md-4">
							<div class="field-container">
								<input type="text" formControlName="CPF" appMaskCPF>
								<div class="name">CPF</div>
							</div>
						</div>

						<div class="col-md-4">
							<div class="field-container">
								<input type="text" formControlName="CNPJ" appMaskCNPJ>
								<div class="name">CNPJ</div>
							</div>
						</div>

						<div class="col-md-4">
							<div class="field-container">
								<input type="text" formControlName="nationality">
								<div class="name">Nacionalidade</div>
							</div>
						</div>

						<div class="col-md-4">
							<div class="field-container">
								<input type="text" formControlName="naturalness">
								<div class="name">Naturalidade</div>
							</div>
						</div>

						<div class="col-md-4">
							<div class="field-container">
								<input type="text" formControlName="occupation">
								<div class="name">Profissão</div>
							</div>
						</div>

						<div class="col-md-12 pb-2">
							<div class="field-container">
								<textarea rows="5" formControlName="comments"></textarea>
								<div class="name">OBSERVAÇÕES INTERNAS</div>
							</div>
							<small>*Esta informação é de uso interno e <span class="text-danger font-weight-bold">NÃO</span>
								será divulgado no site e portais.
							</small>
						</div>

					</div>

				</div>
				<!--// Informações adicionais -->

			</form>

		</div>

	</div>

	<div class="flex-footer">
		<div class="actions-bottom">
			<!--<button class="btn btn-light" (click)="save()" type="button" *ngIf="">-->
			<!--Salvar e voltar-->
			<!--</button>-->
			<button class="btn btn-primary" (click)="save()" type="button">
				Salvar
			</button>
		</div>
	</div>

</div>
