<div class="ticket-card card">
    <div class="title-container">
        <div class="name">
            <div class="cod">232</div>
            Cliente - Elaine referencia 12854 ( cliente interessada,mandou mandar mensagem no Wats Esperando sua resposta
            <span class="badge badge-danger">Esperando sua resposta</span>
        </div>

        <div class="date">
            <i class="far fa-clock"></i> há 15 minutos
        </div>
        <div class="info">
            <div>
                <b>Integrantes:</b> Alberto Ammar
            </div>
            <div>
                <b>Contato associado:</b> <a href="" class="dashed">Alberto Ammar</a>
            </div>
            <div>
                <b>Valor buscado:</b> <a href="" class="dashed">Júlio</a>
            </div>
        </div>
    </div>

    <div class="info-container">
        <div>
            <b>Status:</b> Aberto
        </div>
        <div>
            <b>Categoria:</b> Parceria
        </div>
        <div>
            <b>Prioridade:</b> <div class="medium">Baixa</div>

        </div>
    </div>

</div>
