var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import * as moment from 'moment';
import { AgendaService } from '../models/agenda.service';
var AgendaResolver = /** @class */ (function () {
    function AgendaResolver(agendaService) {
        this.agendaService = agendaService;
    }
    AgendaResolver.prototype.resolve = function (route) {
        var params = __assign({}, route.queryParams);
        // this.filters.date_gt = moment()
        //     .subtract('1', 'M')
        //     .format('YYYYMM') + '20000000';
        // this.filters.date_lt = moment()
        //     .add('1', 'M')
        //     .format('YYYYMM') + '11000000';
        return this.agendaService.search({
            root: {
                'organize_by_day': true
            },
            limit: 200,
            date_gt: moment()
                .subtract('1', 'M')
                .format('YYYYMM') + '20000000',
            date_lt: moment()
                .add('1', 'M')
                .format('YYYYMM') + '11000000'
        });
    };
    return AgendaResolver;
}());
export { AgendaResolver };
