<div class="nav-bar show-mobile">
	<div class="table">
		<div class="td back" (click)="back()" *ngIf="backIcon">
			<img src="assets/images/icon-back-white.png">
		</div>
		<div class="td back" (click)="openMenu()" *ngIf="!backIcon">
			<i class="fas fa-bars"></i>
		</div>

		<div class="td td-overflow-text">
			<span class="nav-title">
				{{title || '-'}}
			</span>
		</div>
		<div class="td text-right td-min text-nowrap">
			<div class="icon-action" *ngFor="let action of actions; let i = index" (click)="pressAction(i)">
				<span [innerHTML]="action.body"></span>
			</div>
		</div>
	</div>

	<div class="tabs" #tabsElement>
		<ng-container *ngFor="let tab of tabs; let i = index">
			<div class="tab" [style.width]="tab.width" [class.active]="tab.active" (click)="setTab(i)" #tabElement>
				<span [innerHTML]="tab.title"></span>
				<div class="badge" *ngIf="tab.badge">
					{{tab.badge}}
				</div>
			</div>
		</ng-container>
	</div>
</div>

<div class="nav-space show-mobile" [class.add-tabs]="tabs.length"></div>
