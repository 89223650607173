import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { PropertiesDemandService } from '../models/properties-demand.service';

@Injectable({
	providedIn: 'root'
})
export class PartnerPropertiesResolversService {

	constructor(
		private propertiesDemand: PropertiesDemandService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {

		let properties_demand_id = route.queryParams.properties_demand_id;

		if (properties_demand_id) {

			return this.propertiesDemand.get(properties_demand_id);

		}

		return false;
	}
}
