/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./loading-page.component";
var styles_LoadingPageComponent = [i0.styles];
var RenderType_LoadingPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingPageComponent, data: { "animation": [{ type: 7, name: "EnterLeave", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: "0.0" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: "1.0" }, offset: null }, timings: "0.3s ease-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: "0.0" }, offset: null }, timings: "0.3s ease-out" }], options: null }], options: {} }] } });
export { RenderType_LoadingPageComponent as RenderType_LoadingPageComponent };
function View_LoadingPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "loading"]], [[24, "@EnterLeave", 0], [4, "opacity", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "loading-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "load"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; var currVal_1 = _co.opacity; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_LoadingPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadingPageComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoadingPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading-page", [], null, null, null, View_LoadingPageComponent_0, RenderType_LoadingPageComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoadingPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingPageComponentNgFactory = i1.ɵccf("app-loading-page", i3.LoadingPageComponent, View_LoadingPageComponent_Host_0, { show: "show", opacity: "opacity" }, {}, []);
export { LoadingPageComponentNgFactory as LoadingPageComponentNgFactory };
