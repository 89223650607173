var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { CrudManager } from '../../services/crud-manager';
import { isArray } from 'util';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
var PropertiesService = /** @class */ (function (_super) {
    __extends(PropertiesService, _super);
    function PropertiesService() {
        var _this = _super.call(this) || this;
        _this.path = '/admin/properties';
        return _this;
    }
    PropertiesService.prototype.processFilters = function (data) {
        if (data === void 0) { data = {}; }
        var params = __assign({}, data);
        params['property_id'] = undefined;
        // Converter tipos
        if (params['type_in']) {
            if (!isArray(params['type_in'])) {
                params['type_in'] = [params['type_in']];
            }
            var type_in = [];
            for (var i in params['type_in']) {
                type_in.push(parseInt(params['type_in'][i]));
            }
            params['type_in'] = type_in;
        }
        if (params['bathrooms']) {
            params['bathrooms'] = parseInt(params['bathrooms']);
        }
        if (params['bedrooms']) {
            params['bedrooms'] = parseInt(params['bedrooms']);
        }
        if (params['price_gte']) {
            params['price_gte'] = parseInt(params['price_gte']);
        }
        // if (params['status']) {
        //     params['status'] = parseInt(params['status']);
        // }
        if (params['suites']) {
            params['suites'] = parseInt(params['suites']);
        }
        if (params['vacancies']) {
            params['vacancies'] = parseInt(params['vacancies']);
        }
        if (!params['status']) {
            params['status'] = 1;
        }
        return params;
    };
    PropertiesService.prototype.sortableProperty = function (id, sortable) {
        var _this = this;
        return this.http
            .post(environment.API + this.path + '/' + id + '/featured-home/' + sortable, {})
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }))
            .pipe(catchError(this.responseTreatment.handleError));
    };
    PropertiesService.ngInjectableDef = i0.defineInjectable({ factory: function PropertiesService_Factory() { return new PropertiesService(); }, token: PropertiesService, providedIn: "root" });
    return PropertiesService;
}(CrudManager));
export { PropertiesService };
