import {Injectable} from '@angular/core';
import {CrudManager} from '../../services/crud-manager';

@Injectable({
	providedIn: 'root'
})
export class LocationNeighborhoodsSubneighborhoodsService extends CrudManager {
	path = '/admin/locations/neighborhoods-subneighborhoods';

	constructor() {
		super();
	}
}
