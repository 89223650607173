export class PlaySound {

	static soundsAudio: { [key: string]: any } = {};
	static sounds = {
		'chat-notification': 'assets/sounds/chat-notification.mp3',
		'error': 'assets/sounds/error.mp3',
	};

	static load() {
		for (let i in PlaySound.sounds) {
			PlaySound.soundsAudio[i] = new Audio();
			PlaySound.soundsAudio[i].src = PlaySound.sounds[i];
			PlaySound.soundsAudio[i].load();
		}
	}

	static play(sound: string) {
		PlaySound.soundsAudio[sound].currentTime = 0;
		PlaySound.soundsAudio[sound].play();
	}
}
