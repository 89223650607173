import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {debounceTime, distinctUntilChanged, filter, map, mergeMap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Helpers} from '../../../services/helpers';
import {LocationSearchService} from '../../models/location-search.service';
import {Global} from '../../../services/global';

@Component({
	selector: 'app-input-locations',
	templateUrl: './input-locations.component.html',
	styleUrls: ['./input-locations.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: InputLocationsComponent,
		multi: true
	}]
})
export class InputLocationsComponent implements OnInit, OnChanges, ControlValueAccessor {

	// ID do usuário (Model)
	@Input() value = [];
	@Output() valueChange = new EventEmitter();

	// Evento Change
	@Output() change = new EventEmitter();

	searchTextChanged = new Subject<string>();
	textModel: string;

	locations: {
		city: any [],
		neighborhood: any [],
	} = {
		city: [],
		neighborhood: []
	};

	loading = false;

	// Usuário selecionado
	selectedsUser = [];

	// Ver Autocomplete
	showAutocomplete = false;

	// Index selecionado
	selectNumber = 0;

	// Both onChange and onTouched are functions
	onChange: any = () => {};
	onTouched: any = () => {};

	constructor(
		private _locationSearchService: LocationSearchService
	) {
		this._locationSearchService.setRealEstate(Global.user.realestate);
	}

	ngOnInit() {

		this.searchTextChanged
			.pipe(filter(resp => !Helpers.empty(resp)))
			.pipe(debounceTime(350))
			.pipe(distinctUntilChanged())
			.pipe(map(e => {
				this.loading = true;
				return e;
			}))
			.pipe(mergeMap(search => this.searchLocations(search)))
			.subscribe((data: any) => {
				this.locations = data;

				this.loading = false;
			});
	}

	/**
	 * Changes
	 */
	ngOnChanges() {
		if (Helpers.empty(this.value)) {
			this.value = [];
			this.selectedsUser = [];
			this.textModel = '';
		}
	}

	/**
	 * Seleciona localização
	 * @param location
	 */
	setLocation(location: any) {
		if (!location) {
			return false;
		}

		if (!this.value || !this.value.length) {
			this.value = [];
		}

		this.searchTextChanged.next('');

		this.showAutocomplete = false;

		this.textModel = '';
		this.locations = {
			city: [],
			neighborhood: []
		};

		for (let i in this.value) {
			if (this.value[i].full_string === location.full_string) {
				return false;
			}
		}

		this.value.push(location);

		this.updateChange();
	}

	/**
	 * Remover localização
	 * @param location
	 */
	removeSelectLocation(location: any) {

		if (!location) {
			return false;
		}

		for (let i in this.value) {
			if (this.value[i].full_string === location.full_string) {
				this.value.splice(Number(i), 1);
				break;
			}
		}

		this.updateChange();
	}

	setFocus(focus) {
		this.showAutocomplete = focus;
	}

	/**
	 * Busca Usuários pelo texto no Input
	 * @param search
	 * @returns {Observable<any>}
	 */
	searchLocations(search) {
		return this._locationSearchService.search({
			search: search
		});
	}

	/**
	 * Evento change no input
	 * @param $event
	 */
	changeLocationInput($event) {
		this.showAutocomplete = true;
		let text = $event.target.value;
		this.searchTextChanged.next(text);
	}

	/**
	 * Update Model
	 * @param {KeyboardEvent} event
	 */
	updateChange() {

		if (typeof this.value !== 'object') {
			this.value = [];
			this.textModel = '';
		}

		this.change.emit();
		this.onChange(this.value);
		this.valueChange.emit(this.value);
	}

	@HostListener('document:keydown.arrowup', ['$event'])
	handleUpEvent(event: KeyboardEvent) {
		if (this.showAutocomplete) {
			this.selectNumber--;
			if (this.selectNumber < 0) {
				this.selectNumber = this.locations.neighborhood.length + this.locations.city.length - 1;

				if (this.selectNumber < 0) {
					this.selectNumber = 0;
				}
			}
		}
	}

	@HostListener('document:keydown.arrowdown', ['$event'])
	handleDownEvent(event: KeyboardEvent) {
		if (this.showAutocomplete) {
			this.selectNumber++;
			if (this.selectNumber >= this.locations.neighborhood.length + this.locations.city.length) {
				this.selectNumber = 0;
			}
		}
	}

	@HostListener('document:keydown.enter', ['$event'])
	handleEnterEvent(event: KeyboardEvent) {
		if (this.showAutocomplete) {

			if (this.selectNumber > this.locations.neighborhood.length - 1) {
				this.setLocation(this.locations.city[this.selectNumber - this.locations.neighborhood.length]);
			} else {
				this.setLocation(this.locations.neighborhood[this.selectNumber]);
			}
		}
	}

	///////////////
	// OVERRIDES //
	///////////////

	/**
	 * Writes a new item to the element.
	 * @param value the value
	 */
	writeValue(value: any): void {
		this.value = value;

		this.updateChange();
	}

	/**
	 * Registers a callback function that should be called when the control's value changes in the UI.
	 * @param fn
	 */
	registerOnChange(fn: any): void {
		this.onChange = fn;
		this.updateChange();
	}

	/**
	 * Registers a callback function that should be called when the control receives a blur event.
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

}
