<div class="property-add mobile-form page-flex">

	<div class="flex-header">
		<app-nav-bar [title]="!this.id ? 'Adicionar Contato' : 'Editar Corretor'" [backIcon]="true" (backEvent)="backUrl()">
		</app-nav-bar>
	</div>

	<div class="flex-body">
		<div class="max-width">

			<form [formGroup]="form">

				<div class="alert-dark mt-3 p-3 font-weight-500 hide-mobile">
					<div class="table">
						<div class="td td-min pr-3">
							<img src="assets/images/logo-icon.png" alt="" width="30">
						</div>
						<div class="td">
							Adicione novos corretores, e atribua a ordem de hierarquia, exemplo: pode cadastrar imóveis?,
							pode visualizar contatos?.
						</div>
					</div>
				</div>
				<br>

				<div class="card p-4 mb-4">
					<div class="title-form">
						<i class="fas fa-user"></i> Informações Básicas
						<small>Informe os dados do básicos do contato</small>
					</div>

					<span>

						<div class="row">

							<div class="col-md-4">
								<div class="field-container" [class.error-input]="isFieldValid('name')">
									<input type="text" formControlName="name" autocomplete="cWebnifLT">
									<div class="name">Nome</div>
								</div>

								<app-field-error-display [displayError]="isFieldValid('name')"
									errorMsg="Informe o nome do contato!">
								</app-field-error-display>
							</div>

							<div class="col-md-4">
								<div class="field-container">
									<div class="input-space">
										<div class="row">
											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="0" name="gender" formControlName="gender">
													<div class="checkbox"></div>
													<span class="text">
														Masculino
													</span>
												</label>
											</div>

											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="1" name="gender" formControlName="gender">
													<div class="checkbox"></div>
													<span class="text">
														Feminino
													</span>
												</label>
											</div>
										</div>
									</div>
									<div class="name">Genêro</div>
								</div>
							</div>

						</div>

						<div class="row">
							<div class="col-md-4">
								<div class="field-container" [class.error-input]="isFieldValid('email')">
									<input type="text" autocomplete="cWebnifLT" formControlName="email">
									<div class="name">EMAIL</div>

									<app-field-error-display [displayError]="isFieldValid('email')"
										*ngIf="isFieldValid('email') && !form.get('email').errors['used']"
										errorMsg="Informe um email valido!">
									</app-field-error-display>

									<app-field-error-display [displayError]="isFieldValid('email')"
										*ngIf="isFieldValid('email') && form.get('email').errors['used']"
										errorMsg="Este email já está sendo utilizado!">
									</app-field-error-display>
								</div>
							</div>

							<div class="col-md-4" formArrayName="phones">
								<div *ngFor="let phone of form.get('phones')['controls']; let i = index">
									<div [formGroupName]="i">
										<div class="field-container">
											<!-- [class.error-input]="isFieldValid('number')" -->
											<input type="text" autocomplete="cWebnifLT" formControlName="number"
												appMaskPhone>
											<div class="name">Telefone</div>

											<!-- <app-field-error-display [displayError]="isFieldValid('number')"
												errorMsg="Informe um telefone válido!">
											</app-field-error-display> -->
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="row">

							<div class="col-md-4">
								<div class="field-container" [class.error-input]="isFieldValid('login')">
									<input type="text" formControlName="login" autocomplete="cWebnifLT">
									<div class="name">Login</div>
								</div>

								<app-field-error-display [displayError]="isFieldValid('login')"
									*ngIf="isFieldValid('login') && !form.get('login').errors['used']"
									errorMsg="Informe um login valido!">
								</app-field-error-display>

								<app-field-error-display [displayError]="isFieldValid('login')"
									*ngIf="isFieldValid('login') && form.get('login').errors['used']"
									errorMsg="Este login já está sendo utilizado!">
								</app-field-error-display>
							</div>

							<div class="col-md-4">
								<div class="field-container" [class.error-input]="isFieldValid('password')">
									<input type="text" formControlName="password" autocomplete="cWebnifLT">
									<div class="name">Senha</div>
								</div>

								<div class="info-password">Se você não quer mudar a senha atual, deixe o campo Senha vazio.
								</div>

								<app-field-error-display [displayError]="isFieldValid('password')"
									errorMsg="Informe uma senha válida!">
								</app-field-error-display>
							</div>

							<div class="col-md-4">
								<div class="select-container" [class.error-input]="isFieldValid('role')">
									<select formControlName="role">
										<option value="" disabled>Selecione um cargo</option>
										<option [value]="role.id" *ngFor="let role of roles; trackBy:trackByFn">
											{{role.name}}
										</option>
									</select>
									<div class="name">CARGO</div>
								</div>

								<app-field-error-display [displayError]="isFieldValid('role')"
									errorMsg="Selecione um cargo!">
								</app-field-error-display>
							</div>
						</div>
					</span>
				</div>


				<div class="card p-4 mb-4" formGroupName="permissions">
					<div class="title-form">
						<i class="fas fa-user"></i> Permissões
						<small>Informe as permissões do usuário</small>
					</div>

					<span>

						<div class="row">

							<div class="col-md-4">
								<div class="field-container">
									<div class="input-space">
										<div class="row">
											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="1" name="REALESTATE_MANAGER"
														formControlName="REALESTATE_MANAGER">
													<div class="checkbox"></div>
													<span class="text">
														Sim
													</span>
												</label>
											</div>

											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="0" name="REALESTATE_MANAGER"
														formControlName="REALESTATE_MANAGER">
													<div class="checkbox"></div>
													<span class="text">
														Não
													</span>
												</label>
											</div>
										</div>
									</div>
									<div class="name">Podem editar informações da imobiliária?</div>
								</div>
							</div>


							<div class="col-md-4">
								<div class="field-container">
									<div class="input-space">
										<div class="row">
											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="1" name="ADD_PROPERTIES"
														formControlName="ADD_PROPERTIES">
													<div class="checkbox"></div>
													<span class="text">
														Sim
													</span>
												</label>
											</div>

											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="0" name="ADD_PROPERTIES"
														formControlName="ADD_PROPERTIES">
													<div class="checkbox"></div>
													<span class="text">
														Não
													</span>
												</label>
											</div>
										</div>
									</div>
									<div class="name">Podem adicionar imóveis?</div>
								</div>
							</div>

							<div class="col-md-4">
								<div class="field-container">
									<div class="input-space">
										<div class="row">
											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="1" name="EDIT_PROPERTIES"
														formControlName="EDIT_PROPERTIES">
													<div class="checkbox"></div>
													<span class="text">
														Sim
													</span>
												</label>
											</div>

											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="0" name="EDIT_PROPERTIES"
														formControlName="EDIT_PROPERTIES">
													<div class="checkbox"></div>
													<span class="text">
														Não
													</span>
												</label>
											</div>
										</div>
									</div>
									<div class="name">Podem editar imóveis?</div>
								</div>
							</div>

							<div class="col-md-4">
								<div class="field-container">
									<div class="input-space">
										<div class="row">
											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="1" name="VIEW_ADDRESS_PROPERTIES"
														formControlName="VIEW_ADDRESS_PROPERTIES">
													<div class="checkbox"></div>
													<span class="text">
														Sim
													</span>
												</label>
											</div>

											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="0" name="VIEW_ADDRESS_PROPERTIES"
														formControlName="VIEW_ADDRESS_PROPERTIES">
													<div class="checkbox"></div>
													<span class="text">
														Não
													</span>
												</label>
											</div>
										</div>
									</div>
									<div class="name">Pode ver endereço do imóvel?</div>
								</div>
							</div>

							<div class="col-md-4">
								<div class="field-container">
									<div class="input-space">
										<div class="row">
											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="1" name="CONTACT_VIEW_PROPRIETARY"
														formControlName="CONTACT_VIEW_PROPRIETARY">
													<div class="checkbox"></div>
													<span class="text">
														Sim
													</span>
												</label>
											</div>

											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="0" name="CONTACT_VIEW_PROPRIETARY"
														formControlName="CONTACT_VIEW_PROPRIETARY">
													<div class="checkbox"></div>
													<span class="text">
														Não
													</span>
												</label>
											</div>
										</div>
									</div>
									<div class="name">Pode ver dados de proprietários?</div>
								</div>
							</div>

							<div class="col-md-4">
								<div class="field-container">
									<div class="input-space">
										<div class="row">
											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="1" name="CONTACT_VIEW_BUYERS_PROPERTIES"
														formControlName="CONTACT_VIEW_BUYERS_PROPERTIES">
													<div class="checkbox"></div>
													<span class="text">
														Sim
													</span>
												</label>
											</div>

											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="0" name="CONTACT_VIEW_BUYERS_PROPERTIES"
														formControlName="CONTACT_VIEW_BUYERS_PROPERTIES">
													<div class="checkbox"></div>
													<span class="text">
														Não
													</span>
												</label>
											</div>
										</div>
									</div>
									<div class="name">Pode ver todos os compradores?</div>
								</div>
							</div>

							<div class="col-md-4">
								<div class="field-container">
									<div class="input-space">
										<div class="row">
											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="1" name="FUNNEL_STEP_MANAGER"
														formControlName="FUNNEL_STEP_MANAGER">
													<div class="checkbox"></div>
													<span class="text">
														Sim
													</span>
												</label>
											</div>

											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="0" name="FUNNEL_STEP_MANAGER"
														formControlName="FUNNEL_STEP_MANAGER">
													<div class="checkbox"></div>
													<span class="text">
														Não
													</span>
												</label>
											</div>
										</div>
									</div>
									<div class="name">Gerenciar etapas do funíl de vendas?</div>
								</div>
							</div>

							<div class="col-md-4">
								<div class="field-container">
									<div class="input-space">
										<div class="row">
											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="1" name="PARTNER_PERFIL_MANAGER"
														formControlName="PARTNER_PERFIL_MANAGER">
													<div class="checkbox"></div>
													<span class="text">
														Sim
													</span>
												</label>
											</div>

											<div class="col-md-6 p-0">
												<label class="checkbox-container clean">
													<input type="radio" [value]="0" name="PARTNER_PERFIL_MANAGER"
														formControlName="PARTNER_PERFIL_MANAGER">
													<div class="checkbox"></div>
													<span class="text">
														Não
													</span>
												</label>
											</div>
										</div>
									</div>
									<div class="name">Gerenciar filtro de Imóveis parceiros?</div>
								</div>
							</div>



						</div>
					</span>
				</div>

			</form>

		</div>
	</div>

	<div class="flex-footer">
		<div class="actions-bottom">
			<button class="btn btn-primary" (click)="save()" type="button" id="save">
				Salvar
			</button>
		</div>
	</div>
</div>
