var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { CrudManager } from '../../services/crud-manager';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
var TicketsService = /** @class */ (function (_super) {
    __extends(TicketsService, _super);
    function TicketsService() {
        var _this = _super.call(this) || this;
        _this.path = '/admin/tickets';
        return _this;
    }
    TicketsService.prototype.processFilters = function (data) {
        if (data === void 0) { data = {}; }
        var params = __assign({}, data);
        params['limit'] = 20;
        if (params['closed']) {
            params['closed'] = Number(params['closed']);
        }
        return params;
    };
    TicketsService.prototype.enableContacts = function (id) {
        var _this = this;
        return this.http
            .post(environment.API + this.path + '/enabled-contacts/' + id, {})
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }));
    };
    TicketsService.ngInjectableDef = i0.defineInjectable({ factory: function TicketsService_Factory() { return new TicketsService(); }, token: TicketsService, providedIn: "root" });
    return TicketsService;
}(CrudManager));
export { TicketsService };
