import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {TasksService} from '../models/tasks.service';
import {Injectable} from '@angular/core';
import {TicketsService} from '../models/tickets.service';

@Injectable()
export class PartnershipConversationService implements Resolve<any> {

	constructor(
		private ticketsService: TicketsService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {
		return this.ticketsService.get(route.params.id);
	}
}
