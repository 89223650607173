import { Component, OnInit } from '@angular/core';
import { Global } from 'src/app/services/global';
import { RealEstateService } from '../../models/real-estate.service';
import { ITheme } from '../../interfaces/theme';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-settings-theme',
	templateUrl: './settings-theme.component.html',
	styleUrls: ['./settings-theme.component.scss']
})
export class SettingsThemeComponent implements OnInit {

	themeList: ITheme[] = Global.info.realestate.themes;
	currentTheme: string = Global.user.realestate.theme;

	constructor(private _realEstateService: RealEstateService, private _toastService: ToastrService) {

	}

	ngOnInit() {
		console.log('TCL: SettingsThemeComponent -> themeList', this.themeList);
	}

	checkTheme(theme: any): boolean {
		return this.currentTheme === theme.hash;
	}

	openTheme(theme) {
		window.open(Global.user.realestate.website + '?__preview_hash__=' + theme.hash, '_system');
	}

	changeTheme(theme: ITheme) {
		let data = {
			theme: theme.hash
		};

		return this._realEstateService
			.updateMe(data)
			.subscribe(realestate => {
				this.currentTheme = realestate.theme;
				this._toastService.success('Tema alterado com sucesso!');
			}, err => {
				this._toastService.error('Ocorreu um erro no servidor.');
			});
	}
}
