import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class TitleService {

	constructor(private _titleService: Title,
				private route: ActivatedRoute) {}

	setTitle(title: string) {
		this._titleService.setTitle(`${title} | Banco dos Imóveis`);
	}

	setEditTitle(title: string) {
		this._titleService.setTitle(`Editar ${title} | Banco dos Imóveis`);
	}

	setListTitle(title: string, page?: number) {
		if (!!page) {
			title = `${title} - Pagina ${page} | Banco dos Imóveis`;
		} else {
			title = `${title}`;
		}
		this._titleService.setTitle(title);
	}
}
