import { PlaySound } from './../../../services/PlaySound';
import { Global } from './../../../services/global';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RealEstateService } from '../../models/real-estate.service';

@Component({
	selector: 'app-settings-metatags',
	templateUrl: './settings-metatags.component.html',
	styleUrls: ['./settings-metatags.component.scss']
})
export class SettingsMetatagsComponent implements OnInit {

	realestate: any;
	form: FormGroup;
	formSubmitAttempt: boolean;
	estates: any;

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private toastr: ToastrService,
		private changeDetectorRef: ChangeDetectorRef,
		private realEstateService: RealEstateService
	) {
	}

	ngOnInit() {
		this.realestate = this.route.snapshot.data.realestate;
		console.log(this.realestate);
		this.buildForm();
	}

	buildForm() {
		this.form = this.fb.group({
			SEO_TITLE: [this.realestate.settings.SEO_TITLE],
			SEO_KEYWORDS: [this.realestate.settings.SEO_KEYWORDS],
			SEO_DESCRIPTION: [this.realestate.settings.SEO_DESCRIPTION],
			GOOGLE_SITE_VERIFICATION: [this.realestate.settings.GOOGLE_SITE_VERIFICATION],
			GOOGLE_ANALYTICS: [this.realestate.settings.GOOGLE_ANALYTICS]
		});
	}

	save() {
		let data = this.form.value;
		this.formSubmitAttempt = true;

		if (this.form.invalid) {
			PlaySound.play('error');
			this.toastr.error('Preencha os campos corretamente.');
			return false;
		}

		return this.realEstateService
			.updateMe(data)
			.subscribe(user => {
				this.toastr.success('Dados atualizados com sucesso!');
			}, err => {
				PlaySound.play('error');
				this.toastr.error(err.message);
			});
	}

	/**
     * Verifica se campo é valido
     * @param {string} field
     * @returns {boolean}
     */
	isFieldValid(field: string) {
		return (!this.form.get(field).valid && this.formSubmitAttempt);
	}

}
