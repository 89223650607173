import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {PropertiesService} from '../models/properties.service';
import {Observable} from 'rxjs';
import {IndexedDB} from '../../services/IndexedDB';

@Injectable()
export class PropertiesSearchResolver implements Resolve<any> {

	constructor(
		private propertiesService: PropertiesService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {

		return Observable.create(obs => {
			new IndexedDB('cached').getValue('properties-lists')
				.subscribe((success: any) => {

					console.log('success -- ');

					let body = success.data;
					let last = false;

					try {
						last = body.last_updated;
					} catch (e) {

					}

					if (last) {
						body.cached = true;
						obs.next(body);
						obs.complete();
					} else {
						this.search(route).subscribe(data => {
							new IndexedDB('cached').putValue('properties-lists', data).subscribe(() => {});
							obs.next(data);
							obs.complete();
						});
					}
				}, err => {
					this.search(route).subscribe(data => {
						new IndexedDB('cached').putValue('properties-lists', data).subscribe(() => {});
						obs.next(data);
						obs.complete();
					});
				} );

		});

	}

	search(route: ActivatedRouteSnapshot) {
		let filters = this.propertiesService.processFilters(route.queryParams);

		if (!!route.data.partners) {
			if (filters['root']) {
				filters['root']['bdi_group'] = true;
				filters['root']['only_bdi_group'] = true;
			} else {
				filters['root'] = {
					bdi_group: true,
					only_bdi_group: true,
				};
			}
		}

		if (!!route.data.approval) {
			filters['status'] = -1;
		}

		if (!!route.data.features_home) {
			filters['featured_home'] = true;
			filters['sort'] = 'featured_home';
		}

		filters['last_updated'] = 1;

		return this.propertiesService.search(filters);
	}
}

