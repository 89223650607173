import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {

    @Input() total: number;
    @Input() perPage: number;
    @Input() currentPage: number;
    @Output() changePage = new EventEmitter();

    pages: Array<any>;
    last = true;
    next = true;
    total_pages: number;

    constructor() {
    }

    ngOnInit() {
        this.configurePage();
    }

    incrementPage(number) {
        this.currentPage = number;
        this.changePage.emit(number);
        this.configurePage();
    }

    setPage(page) {
        this.currentPage = page.number;
        this.changePage.emit(page.number);
        this.configurePage();
    }

    configurePage() {
        this.total_pages = parseInt(String(this.total / this.perPage), 10) + 1;

        if (this.total === this.perPage) {
            this.total_pages = 1;
        }

        let start = this.currentPage - 3;
        let end = this.currentPage + 2;

        if (this.currentPage <= 4) {
            start = 0;
            end = 5;
        } else {
            if (this.total_pages - this.currentPage <= 3) {
                start = this.total_pages - 5;
                end = this.total_pages;
            }
        }

        if (0 > start) {
            start = 0;
        }

        if (end > this.total_pages) {
            end = this.total_pages;
        }

        this.pages = [];

        /**
         * Adiciona pagina inicial
         */
        if (start > 1) {
            this.pages.push({
                number: 1,
            });
            this.pages.push({
                disable: true,
                number: '...',
            });
        }

        for (let i = start; i < end; i++) {
            this.pages.push({
                active: (i + 1 === this.currentPage),
                number: i + 1,
            });
        }

        /**
         * Adiciona pagina final
         */
        if (this.total_pages - end >= 2) {
            this.pages.push({
                disable: true,
                number: '...',
            });

            this.pages.push({
                number: this.total_pages,
            });
        }

        this.last = (this.currentPage !== 1);
        this.next = (this.currentPage !== this.total_pages);
    }

    ngOnChanges() {
        this.configurePage();
    }

}
