import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from "@angular/forms";
import { ModalAnimation } from "../../../animations/animations";
import { environment } from "../../../../../environments/environment";
import { TicketsService } from "../../../models/tickets.service";
import { Router, ActivatedRoute } from "@angular/router";
import { PartnersChatService } from "../../../models/partners-chat.service";
import { Contacts } from "../../../interfaces/contacts";
import { Global } from "../../../../services/global";
import { PlaySound } from "../../../../services/PlaySound";
import { Helpers } from "../../../../services/helpers";
import { ContactsService } from "../../../models/contacts.service";
import { ToastrService } from "ngx-toastr";

@Component({
	selector: "app-partnership-add",
	templateUrl: "./partnership-add.component.html",
	styleUrls: ["./partnership-add.component.scss"],
	animations: [ModalAnimation]
})
export class PartnershipAddComponent implements OnInit, OnDestroy {
	// Filtros
	@Input() form: FormGroup;
	@Output() changeFilters = new EventEmitter();
	@Input() id: string;

	contacts: Contacts[] = [];
	property_id = 0;
	openModal = false;
	MASTER = 0;
	partnerID: number;
	creating = false;
	singleContact: any;
	singleProperty: any;
	property: any;
	error: string;
	formAttemptSubmit: boolean;
	placeHolder = "Digite uma mensagem";

	constructor(
		private fb: FormBuilder,
		private ticketsService: TicketsService,
		private router: Router,
		private route: ActivatedRoute,
		private partnersChatService: PartnersChatService,
		private toastr: ToastrService,
		private contactsService: ContactsService,
		private _activatedRoute: ActivatedRoute
	) {
		// Master BDI
		this.MASTER = Global.user.user_master_bdi;

		this.contacts = this.route.snapshot.data.contacts.results;
		this.singleContact = this.route.snapshot.data.singleContact;
		this.singleProperty = this.route.snapshot.data.singleProperty;
		this.property = this.route.snapshot.data.property;

		if (this.property) {
			this.placeHolder = `Olá, tenho interesse em realizar parceria com o imóvel #${this.property.id} - ${this.property.title}. Gostaria de mais informações.`;
		}

		if (this.route.snapshot.queryParams.property_id) {
			this.property_id = this.route.snapshot.queryParams.property_id;
		}

		this.form = this.fb.group({
			name: ["", [Validators.required]],
			users_participants: [[], [Validators.required]],
			contacts_id: [[], []],
			message: ["", [Validators.required]],
			property_id: [this.property_id, []],
			property_demand_id: 0,
			client_demand_id: 0,
			contact_name: "",
			contact_phone: ["", [Validators.minLength(10)]],
			contact_email: ["", [Validators.email]],
			caption: false,
			administration: false,
			lazy_contact: false
		});

		if (this.singleContact) {
			this.form.get("client_demand_id").setValue(this.singleContact.id);
		}

		if (this.singleProperty) {
			this.form
				.get("property_demand_id")
				.setValue(this.singleProperty.id);
		}

		// TODO Ativar
		if (this.MASTER === 0) {
			(<FormControl>(
				this.form.controls["users_participants"]
			)).setValidators([]);
			(<FormControl>(
				this.form.controls["users_participants"]
			)).updateValueAndValidity();
		}

		if (!!this.singleContact || !!this.singleProperty) {
			(<FormControl>this.form.controls["message"]).setValidators([]);
			(<FormControl>(
				this.form.controls["message"]
			)).updateValueAndValidity();

			(<FormControl>this.form.controls["name"]).setValidators([]);
			(<FormControl>this.form.controls["name"]).updateValueAndValidity();

			(<FormControl>this.form.controls["contacts_id"]).setValidators([]);
			(<FormControl>(
				this.form.controls["contacts_id"]
			)).updateValueAndValidity();

			(<FormControl>(
				this.form.controls["users_participants"]
			)).setValidators([]);
			(<FormControl>(
				this.form.controls["users_participants"]
			)).updateValueAndValidity();
		} else if (!!this.property) {
			(<FormControl>this.form.controls["name"]).setValidators([]);
			(<FormControl>this.form.controls["name"]).updateValueAndValidity();

			(<FormControl>this.form.controls["message"]).setValidators([]);
			(<FormControl>(
				this.form.controls["message"]
			)).updateValueAndValidity();
		} else if (this.contacts.length > 0) {
			(<FormControl>this.form.controls["contacts_id"]).setValidators([]);
			(<FormControl>(
				this.form.controls["contacts_id"]
			)).updateValueAndValidity();

			(<FormControl>this.form.controls["name"]).setValidators([]);
			(<FormControl>this.form.controls["name"]).updateValueAndValidity();

			(<FormControl>(
				this.form.controls["users_participants"]
			)).setValidators([]);
			(<FormControl>(
				this.form.controls["users_participants"]
			)).updateValueAndValidity();
		} else {
			(<FormControl>this.form.controls["contacts_id"]).setValidators([]);
			(<FormControl>(
				this.form.controls["contacts_id"]
			)).updateValueAndValidity();
		}
	}

	// TODO Ativar
	isFieldValid(formName: string) {
		return this.form.get(formName).invalid && this.formAttemptSubmit;
	}

	ngOnInit() {
		this.openModal = true;
		if (!!this._activatedRoute.snapshot.queryParams.id) {
			this.partnerID = this._activatedRoute.snapshot.queryParams.id;
		}
	}

	create() {
		let data = this.form.value;

		// Contato não pode estar definido
		if (data.contact_email || data.contact_phone) {
			(<FormControl>this.form.controls["contact_name"]).setValidators([
				Validators.required
			]);
			(<FormControl>(
				this.form.controls["contact_name"]
			)).updateValueAndValidity();

			data.contact_phones = [];
			if (data.contact_phone) {
				data.contact_phones = [
					{
						number: data.contact_phone
					}
				];
			}
		} else {
			(<FormControl>this.form.controls["contact_name"]).setValidators([]);
			(<FormControl>(
				this.form.controls["contact_name"]
			)).updateValueAndValidity();
		}

		if (!this.form.valid) {
			this.formAttemptSubmit = true;
			return false;
		}

		if (this.creating) {
			return false;
		}

		this.creating = true;

		data.category = 1;
		data.status = 3;
		data.type = "partners";
		data.by_contacts = this.contacts.map(c => c.id);
		data.property_id = Number(data.property_id);

		if (!!this.property) {
			data.name = `Parceria no Imóvel #${this.property.id}`;
		}

		if (
			Helpers.empty(data.message) &&
			this.placeHolder !== "Digite uma mensagem"
		) {
			data.message = this.placeHolder;
		}

		if (data.contact_name || data.contact_email || data.contact_phone) {
			this.contactsService
				.create({
					name: data.contact_name,
					type: 3,
					email: data.contact_email,
					phones: data.contact_phones
				})
				.subscribe(
					contact => {
						data.contacts_id = [contact.id];
						this.createPartnershipChat(data);
					},
					err => {
						PlaySound.play("error");
						this.toastr.error(err.message);
						this.creating = false;
					}
				);
		} else {
			this.createPartnershipChat(data);
		}
	}

	createPartnershipChat(data) {
		console.log(
			"TCL: PartnershipAddComponent -> createPartnershipChat -> data",
			data
		);
		console.log("teste");

		this.partnersChatService.create(data).subscribe(
			resp => {
				this.creating = false;

				console.log(resp.body);

				if (resp.body.length) {
					this.router
						.navigate(["/", { outlets: { modal: null } }])
						.then(() => {
							this.router.navigate(["/partnership"], {
								replaceUrl: true
							});
						});
				} else {
					this.router
						.navigate(["/", { outlets: { modal: null } }])
						.then(() => {
							this.router.navigateByUrl(
								"/partnership/conversation/" + resp.body.id,
								{
									replaceUrl: true
								}
							);
						});
				}
			},
			err => {
				PlaySound.play("error");
				this.creating = false;
				this.error = err.message;
			}
		);
	}

	clean() {}

	close() {
		this.router.navigate(["/", { outlets: { modal: null } }]);
		// this.router.navigate([ '/partnership', {outlets: { modal: null } } ]);
		this.openModal = false;
	}

	ngOnDestroy() {
		console.log("ss");
		this.openModal = false;
	}
}
