import { EventEmitter, OnChanges, OnInit } from '@angular/core';
import { UsersService } from '../../models/users.service';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Helpers } from '../../../services/helpers';
import { ControlValueAccessor } from '@angular/forms';
import { Global } from '../../../services/global';
var InputUsersComponent = /** @class */ (function () {
    function InputUsersComponent(usersService) {
        this.usersService = usersService;
        this.allUsers = false;
        this.valueChange = new EventEmitter();
        // Evento Change
        this.change = new EventEmitter();
        this.searchTextChanged = new Subject();
        this.users = [];
        this.loading = false;
        // Ver Autocomplete
        this.showAutocomplete = false;
        // Index selecionado
        this.selectNumber = 0;
        this.MASTER = 0;
        // Both onChange and onTouched are functions
        this.onChange = function () {
        };
        this.onTouched = function () {
        };
    }
    Object.defineProperty(InputUsersComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (value) {
            this._value = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputUsersComponent.prototype, "user", {
        // Usuário selecionado
        get: function () {
            return this._selectedUser;
        },
        set: function (user) {
            if (Helpers.empty(user)) {
                this.selectedUser = '';
                this.value = 0;
                this.textModel = '';
            }
            else {
                this.selectedUser = user;
                this.setFocus(false);
                this.value = this.selectedUser.id;
            }
            this._selectedUser = this.selectedUser;
        },
        enumerable: true,
        configurable: true
    });
    InputUsersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.MASTER = Global.user.user_master_bdi;
        this.searchTextChanged
            .pipe(debounceTime(350))
            .pipe(distinctUntilChanged())
            .pipe(mergeMap(function (search) { return _this.searchUsers(search); }))
            .subscribe(function (data) {
            _this.users = data.results;
            _this.loading = false;
        });
    };
    /**
     * Changes
     */
    InputUsersComponent.prototype.ngOnChanges = function () {
        if (Helpers.empty(this.value)) {
            this.selectedUser = '';
            this.textModel = '';
        }
    };
    /**
     * Seleciona usuário
     * @param user
     */
    InputUsersComponent.prototype.selectUser = function (user) {
        this.selectedUser = user;
        this.setFocus(false);
        this.value = this.selectedUser.id;
        this.updateChange();
    };
    /**
     * Remover seleção
     * @param user
     */
    InputUsersComponent.prototype.removeSelectUser = function () {
        this.selectedUser = '';
        this.textModel = '';
        this.value = 0;
        this.updateChange();
    };
    InputUsersComponent.prototype.setFocus = function (focus) {
        this.showAutocomplete = focus;
    };
    /**
     * Busca Usuários pelo texto no Input
     * @param search
     * @returns {Observable<any>}
     */
    InputUsersComponent.prototype.searchUsers = function (search) {
        return this.usersService.search({
            all_users: this.allUsers,
            'name': search
        });
    };
    /**
     * Evento change no input
     * @param $event
     */
    InputUsersComponent.prototype.changeLocationInput = function ($event) {
        this.loading = true;
        this.showAutocomplete = true;
        var text = $event.target.value;
        this.searchTextChanged.next(text);
    };
    /**
     * Update Model
     * @param {KeyboardEvent} event
     */
    InputUsersComponent.prototype.updateChange = function () {
        this.valueChange.emit(this.value);
        this.change.emit();
        this.onChange(this.value);
    };
    InputUsersComponent.prototype.handleUpEvent = function (event) {
        if (this.showAutocomplete) {
            this.selectNumber--;
            if (this.selectNumber < 0) {
                this.selectNumber = this.users.length - 1;
                if (this.selectNumber < 0) {
                    this.selectNumber = 0;
                }
            }
        }
    };
    InputUsersComponent.prototype.handleDownEvent = function (event) {
        if (this.showAutocomplete) {
            this.selectNumber++;
            if (this.selectNumber >= this.users.length) {
                this.selectNumber = 0;
            }
        }
    };
    InputUsersComponent.prototype.handleEnterEvent = function (event) {
        if (this.showAutocomplete) {
            this.selectUser(this.users[this.selectNumber]);
        }
    };
    ///////////////
    // OVERRIDES //
    ///////////////
    /**
     * Writes a new item to the element.
     * @param value the value
     */
    InputUsersComponent.prototype.writeValue = function (value) {
        this.value = value;
        this.updateChange();
    };
    /**
     * Registers a callback function that should be called when the control's value changes in the UI.
     * @param fn
     */
    InputUsersComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    /**
     * Registers a callback function that should be called when the control receives a blur event.
     * @param fn
     */
    InputUsersComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    return InputUsersComponent;
}());
export { InputUsersComponent };
