<div class="modal-screen modal-select-properties change-mobile-page">

	<div class="modal-body d-flex">

		<div class="header-modal">
			<div class="i-close" (click)="close()">
				<div class="icon-close-only black s32x32 display-close"></div>
				<img src="assets/svg/home.svg" class="display-back">
			</div>

			<div class="title">
				Visualizar Imóvel
			</div>
		</div>

		<div class="search-list-container">
			<div class="header">
				<div class="bdi-btn float-right hide-mobile" (click)="createPartners()">
					<i class="far fa-handshake"></i> Solicitar parceria
				</div>

				<div class="property-title">
					<div class="cod">{{ property.cod }}</div>
					{{ property.title }}
				</div>

				<div class="bdi-btn show-mobile" (click)="createPartners()">
					<i class="far fa-handshake"></i> Solicitar parceria
				</div>
			</div>

			<div class="images">
				<img [src]="property.images[0].url" alt="">
			</div>

			<div class="body">
				<div class="prices">
					<div class="price sale" *ngIf="!!property.sale_price">
						Venda
						<div class="number">
							R$ {{ property.sale_price | price }}
						</div>
					</div>

					<div class="price rent" *ngIf="!!property.rent_price">
						Locação
						<div class="number">
							R$ {{ property.rent_price | price }}
						</div>
					</div>
				</div>

				<div *ngIf="property.details.length">
					<h2>Detalhes do imóvel</h2>

					<div class="benif-unit" *ngFor="let details of property.details">
						<div class="bullet">
							<i class="fa fa-circle" aria-hidden="true"></i>
						</div>

						<b>{{details.name }}</b>: {{details.value}}
					</div>
				</div>

				<div class="description" *ngIf="property.description">
					<h2>Descrição</h2>
					<div>{{ property.description }}</div>
				</div>

				<div *ngIf="property.benefits_names.length">
					<h2>Características</h2>
					<div class="row m-0">
						<div class="col-md-4 col-sm-12 benif-unit"
							 *ngFor="let item of property.benefits_names">
							<i class="fa fa-check" aria-hidden="true"></i> {{item.name}}
						</div>
					</div>
				</div>

				<div *ngIf="property.places_nearby_names.length">
					<h2>Próximos do Imóvel</h2>
					<div class="row m-0">
						<div class="col-md-4 col-sm-12 benif-unit"
							 *ngFor="let item of property.places_nearby_names">
							<i class="fa fa-check text-secondary" aria-hidden="true"></i> {{item.name}}
						</div>
					</div>
				</div>

				<div class="bdi-btn full" (click)="createPartners()">
					<i class="far fa-handshake"></i> Solicitar parceria
				</div>

			</div>
		</div>

	</div>
</div>
