import { Injectable, NgZone } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { mergeMapTo } from "rxjs/operators";
import { take } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";
// import * as firebase from 'firebase';
import { PushTokenService } from "../content/models/push-token.service";
import { browser } from "protractor";
import { environment } from "../../environments/environment";
import { CompleteHeightService } from "../content/shareds/complete-height.service";
import { Location } from "@angular/common";
import { RouterHistoric } from "../content/shareds/router-historic.service";
import { Router } from "@angular/router";

declare var PushNotification: any;
declare var window: any;
declare var nativeclick: any;
declare var navigator: any;
declare var cordova: any;

@Injectable()
export class CordovaService {
	private resume: BehaviorSubject<boolean>;

	private home = 0;

	constructor(
		private zone: NgZone,
		private location: Location,
		private router: Router
	) {
		if (environment.mobile) {
			this.resume = new BehaviorSubject<boolean>(null);

			// InAppBrowser
			try {
				window.open = cordova.InAppBrowser.open;
			} catch (e) {
				alert(e);
			}

			if (window.MobileAccessibility) {
				window.MobileAccessibility.usePreferredTextZoom(false);
			}

			document.addEventListener(
				"resume",
				() => {
					this.zone.run(() => {
						this.onResume();
					});
				},
				false
			);

			window.addEventListener(
				"keyboardDidShow",
				() => {
					this.zone.run(() => {
						this.onKeyboardDidShow();
					});
				},
				false
			);

			window.addEventListener(
				"keyboardDidHide",
				() => {
					this.zone.run(() => {
						this.onKeyboardDidHide();
					});
				},
				false
			);

			document.addEventListener(
				"backbutton",
				() => {
					this.onBackKeyDown();
				},
				false
			);
		}
	}

	get cordova(): any {
		return window().cordova;
	}

	get onCordova(): Boolean {
		return !!window().cordova;
	}

	static touchSound() {
		console.log("touchSound");
		if (environment.mobile) {
			nativeclick.trigger();
		}
	}

	public onKeyboardDidShow(): void {
		document.activeElement.scrollIntoView();
		setTimeout(() => {
			window.scrollBy(0, -10);
		}, 1);

		// window.plugins.toast.showWithOptions(
		// 	{
		// 		message: 'onKeyboardDidShow',
		// 		duration: 'short', // which is 2000 ms. "long" is 4000. Or specify the nr of ms yourself.
		// 		position: 'bottom',
		// 		addPixelsY: -40  // added a negative value to move it up a bit (default 0)
		// 	}
		// );
		CompleteHeightService.update();

		Promise.resolve(null).then(() => {
			CompleteHeightService.update();
			this.zone.run(() => {});
		});
	}

	public onKeyboardDidHide(): void {
		// window.plugins.toast.showWithOptions(
		// 	{
		// 		message: 'onKeyboardDidHide',
		// 		duration: 'short', // which is 2000 ms. "long" is 4000. Or specify the nr of ms yourself.
		// 		position: 'bottom',
		// 		addPixelsY: -40  // added a negative value to move it up a bit (default 0)
		// 	}
		// );
		console.log("down");
		CompleteHeightService.update();
	}

	public onResume(): void {
		// window.plugins.toast.showWithOptions(
		// 	{
		// 		message: 'resume',
		// 		duration: 'short', // which is 2000 ms. "long" is 4000. Or specify the nr of ms yourself.
		// 		position: 'bottom',
		// 		addPixelsY: -40  // added a negative value to move it up a bit (default 0)
		// 	}
		// );

		this.resume.next(true);
	}

	public onBackKeyDown() {
		// window.plugins.toast.showWithOptions(
		// 	{
		// 		message: 'resume',
		// 		duration: 'short', // which is 2000 ms. "long" is 4000. Or specify the nr of ms yourself.
		// 		position: 'bottom',
		// 		addPixelsY: -40  // added a negative value to move it up a bit (default 0)
		// 	}
		// );

		setTimeout(() => {
			if (this.router.url === "/") {
				this.home++;
			} else {
				this.home = 0;
			}

			this.zone.run(() => {
				if (this.home > 1) {
					this.home = 0;
					navigator.app.exitApp();
				} else {
					this.location.back();
				}
			});
		}, 1);
	}
}
