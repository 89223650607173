import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Users} from '../../interfaces/users';

@Component({
	selector: 'app-users-card',
	templateUrl: './users-card.component.html',
	styleUrls: ['./users-card.component.scss']
})
export class UsersCardComponent implements OnInit {

	@Input() user: Users;
	@Output() delete = new EventEmitter();

	constructor() {
	}

	deleteEmit() {
		this.delete.emit();
	}

	ngOnInit() {
	}

}
