import { PropertiesService } from './../models/properties.service';


import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { PropertiesDemandService } from '../models/properties-demand.service';

@Injectable({
	providedIn: 'root'
})
export class PartnersPropertiesGetResolverService {

	constructor(
		private propertiesService: PropertiesService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {

		let property_id = route.queryParams.property_id;

		if (property_id) {

			return this.propertiesService.get(property_id);

		}

		return false;
	}
}

