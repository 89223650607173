import { PlaySound } from './../../../services/PlaySound';
import { Global } from './../../../services/global';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RealEstateService } from '../../models/real-estate.service';

@Component({
	selector: 'app-settings-general',
	templateUrl: './settings-general.component.html',
	styleUrls: ['./settings-general.component.scss']
})
export class SettingsGeneralComponent implements OnInit {

	realestate: any;
	form: FormGroup;
	formSubmitAttempt: boolean;
	estates: any;

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private toastr: ToastrService,
		private changeDetectorRef: ChangeDetectorRef,
		private realEstateService: RealEstateService
	) {
	}

	ngOnInit() {
		this.realestate = this.route.snapshot.data.realestate;
		console.log(this.realestate);
		this.buildForm();
		this.estates = Global.info.estates;
	}

	buildForm() {
		this.form = this.fb.group({
			name: [this.realestate.name, [Validators.required]],
			email: [this.realestate.email],
			cep: [this.realestate.cep],
			address: [this.realestate.address],
			number: [this.realestate.number],
			estate: [this.realestate.estate],
			city: [this.realestate.city],
			neighborhood: [this.realestate.neighborhood],
			MEDIA_FACEBOOK: [this.realestate.settings.MEDIA_FACEBOOK],
			MEDIA_TWITTER: [this.realestate.settings.MEDIA_TWITTER],
			MEDIA_YOUTUBE: [this.realestate.settings.MEDIA_YOUTUBE],
			MEDIA_GOOGLE_PLUS: [this.realestate.settings.MEDIA_GOOGLE_PLUS],
			MEDIA_INSTAGRAM: [this.realestate.settings.MEDIA_INSTAGRAM]
		});
	}

	save() {
		let data = this.form.value;
		this.formSubmitAttempt = true;

		if (!this.form.valid) {
			PlaySound.play('error');
			this.toastr.error('Preencha os campos corretamente.');
			return false;
		}

		return this.realEstateService
			.updateMe(data)
			.subscribe(user => {
				this.toastr.success('Dados atualizados com sucesso!');
			}, err => {
				PlaySound.play('error');
				this.toastr.error(err.message);
			});
	}

	/**
     * Verifica se campo é valido
     * @param {string} field
     * @returns {boolean}
     */
	isFieldValid(field: string) {
		return (!this.form.get(field).valid && this.formSubmitAttempt);
	}
}
