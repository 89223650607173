import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {TasksService} from '../models/tasks.service';
import {AgendaService} from '../models/agenda.service';
import {Helpers} from '../../services/helpers';
import {UsersService} from '../models/users.service';

@Injectable()
export class UsersListResolverService implements Resolve<any> {

    constructor(
        private usersService: UsersService
    ) {

    }

    resolve(route: ActivatedRouteSnapshot) {
        let params = {...route.queryParams};
        return this.usersService.search(params);
    }
}
