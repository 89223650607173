import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoginService } from "../../models/login.service";
import { AuthService } from "../../../services/auth.service";
import { map } from "rxjs/operators";
import { ActivatedRoute, Router, RouterStateSnapshot } from "@angular/router";
import { Global } from "../../../services/global";
import { Location } from "@angular/common";
import { GlobalService } from "../../shareds/global.service";
import { IndexedDB } from "../../../services/IndexedDB";
import {
	animate,
	query,
	stagger,
	state,
	style,
	transition,
	trigger
} from "@angular/animations";
import { RegisterService } from "src/app/services/register.service";

@Component({
	selector: "app-register",
	templateUrl: "./register.component.html",
	styleUrls: ["./register.component.scss"],
	animations: [
		trigger("minRegister", [
			state(
				"loading",
				style({
					height: 0,
					opacity: 0,
					transform: "scale(0.0)"
				})
			),
			transition("* => loading", [
				style({
					transform: "scale(1.0)",
					opacity: "1.0",
					overflow: "hidden",
					height: "*"
				}),
				animate(
					400,
					style({
						height: 0,
						opacity: 0,
						transform: "scale(0.0)"
					})
				)
			]),

			state(
				"*",
				style({
					transform: "scale(1.0)",
					opacity: "1.0",
					overflow: "hidden",
					height: "*"
				})
			),
			transition("loading => *", [
				style({
					height: 0,
					opacity: 0,
					transform: "scale(0.0)"
				}),
				animate(
					400,
					style({
						transform: "scale(1.0)",
						opacity: "1.0",
						overflow: "hidden",
						height: "*"
					})
				)
			])
		]),
		trigger("displayLoading", [
			state(
				"*",
				style({
					display: "none"
				})
			),
			transition("* => loading", [
				style({
					display: "none"
				}),
				query("*", [style({ display: "block" })], { delay: 20000 })
			])
		])
	]
})
export class RegisterComponent implements OnInit {
	form: FormGroup;
	error = "";

	loading = "";

	formAttempt = false;
	formSuccess = false;

	constructor(
		private fb: FormBuilder,
		private registerService: RegisterService,
		private route: ActivatedRoute,
		private _globalService: GlobalService,
		private location: Location
	) {
		if (this.route.snapshot.queryParams.redirectUrl) {
			this.location.replaceState(
				this.route.snapshot.queryParams.redirectUrl
			);
		} else {
			this.location.replaceState("/");
		}
	}

	isFieldValid(name: string) {
		return this.form.get(name).invalid && this.formAttempt;
	}

	ngOnInit() {
		this._globalService.updateLoadingScreen(false);

		this.form = this.fb.group({
			name: ["", [Validators.required]],
			name_realestate: ["", [Validators.required]],
			phone: ["", [Validators.required, Validators.minLength(10)]],
			email: ["", [Validators.required, Validators.email]],
			password: ["", [Validators.required]]
		});
	}

	submit() {
		this.error = "";
		this.formAttempt = true;

		console.log(
			this.form.controls["email"].setValue(
				this.form.controls["email"].value.replace(/ /g, "")
			)
		);
		// this.form.patchValue({

		// })
		if (this.form.valid) {
			let value = this.form.value;

			// this._globalService.updateLoadingScreen(true);

			this.loading = "loading";

			setTimeout(() => {
				this.registerService.register(this.form.value).subscribe(
					data => {
						this.formSuccess = true;
						this.formAttempt = false;
						this.loading = "";
					},
					data => {
						this.loading = "";

						// this._globalService.updateLoadingScreen(false);
						if (data.message) {
							this.error = "Erro ao conectar com servidor.";
						} else if (!this.error) {
							this.error = "Erro ao conectar com servidor.";
						}
					}
				);
			}, 1000);
		}

		// console.log(this.form.valid);
		// console.log(this.form.value);
	}
}
