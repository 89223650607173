import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import * as moment from 'moment';
import {TasksService} from '../models/tasks.service';
import {AgendaService} from '../models/agenda.service';
import {Helpers} from '../../services/helpers';

@Injectable()
export class AgendaResolver implements Resolve<any> {

	constructor(
		private agendaService: AgendaService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {
		let params = {...route.queryParams};

		// this.filters.date_gt = moment()
		//     .subtract('1', 'M')
		//     .format('YYYYMM') + '20000000';
		// this.filters.date_lt = moment()
		//     .add('1', 'M')
		//     .format('YYYYMM') + '11000000';

		return this.agendaService.search({
			root: {
				'organize_by_day': true
			},
			limit: 200,
			date_gt: moment()
				.subtract('1', 'M')
				.format('YYYYMM') + '20000000',
			date_lt: moment()
				.add('1', 'M')
				.format('YYYYMM') + '11000000'
		});
	}
}
