var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { LocationSubNeighborhoodErrorService } from './../models/location-subneighborhood-error.service';
var EquivalenceSubNeighborhoodErrorResolverService = /** @class */ (function () {
    function EquivalenceSubNeighborhoodErrorResolverService(_neighService) {
        this._neighService = _neighService;
    }
    EquivalenceSubNeighborhoodErrorResolverService.prototype.resolve = function (route) {
        var params = __assign({}, route.queryParams);
        return this._neighService.search(params);
    };
    return EquivalenceSubNeighborhoodErrorResolverService;
}());
export { EquivalenceSubNeighborhoodErrorResolverService };
