import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Users} from '../content/interfaces/users';
import {Global} from './global';
import {CookieService} from 'ngx-cookie-service';
import {map} from 'rxjs/operators';
import {ResponseTreatment} from './response-treatment';
import {WebSocket} from './WebSocket';
import {MessagingService} from './messaging.service';
import {GlobalService} from '../content/shareds/global.service';
import {IndexedDB} from './IndexedDB';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	responseTreatment = new ResponseTreatment;

	constructor(
		private http: HttpClient,
		private cookieService: CookieService,
		private _router: Router,
		private _globalService: GlobalService,
		private messagingService: MessagingService
	) {

	}

	checkUserLogged(): Observable<any> {
		return this.http
			.get(environment.API + '/admin/initial')
			.pipe(map(data => this.responseTreatment.pipe(data)));
	}

	setUser(user: Users) {
		if (Global.userLogged) {
			return true;
		}

		Global.user = user;
		Global.userLogged = true;

		this._globalService.updateUser(Global.user);

		if (user.token) {
			Global.token = user.token;
			this.cookieService.set('_UID_', Global.token, 365, '/');
		} else {
			Global.token = user.token;
		}

		this.messagingService.requestPermission();
		this.messagingService.receiveMessage();

		WebSocket.connect({
			token: Global.token
		});
	}

	logout(): void {
		WebSocket.socket.disconnect();

		Global.user = null;
		Global.userLogged = false;
		this.cookieService.delete('_UID_', '/');

		this._globalService.updateUser(null);
		this._router.navigate(['/login']);

		// Deletar banco de dados local
		new IndexedDB('').deleteDatabase();
	}
}
