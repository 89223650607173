<!--<button class="button button-primary" (click)="subscribeToPush()">-->
	<!--Subscribe-->
<!--</button>-->
<div *ngIf="update"></div>

<router-outlet></router-outlet>
<router-outlet name="modal"></router-outlet>

<!--<div class="loading" *ngIf="loading">-->
	<!--<div class="loading-icon">-->
		<!--<div class="load">-->
			<!--<div></div>-->
		<!--</div>-->
	<!--</div>-->
<!--</div>-->

<div class="loading-screen" [@fadeIn]="_loadingScreen" *ngIf="_loadingScreen">
    <img src="assets/images/logo-md.png" class="logo">
    <div class="circle"></div>
</div>
