import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscribable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {ResponseTreatment} from '../../services/response-treatment';
import {Users} from '../interfaces/users';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

    responseTreatment = new ResponseTreatment;

    constructor(private http: HttpClient) {
    }

    /**
     * Rota de Login
     * @param data
     * @returns {Observable<Object>}
     */
    login(data): Observable<Users> {
        return this.http
            .post(environment.API + '/admin/auth', data)
            .pipe(map(
                res => this.responseTreatment.pipe(res)
            ));
    }
}
