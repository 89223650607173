import {CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router/src/router_state';
import {AuthService} from './auth.service';
import {promise} from 'selenium-webdriver';
import {map, catchError} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Global} from './global';

@Injectable()
export class NeedAuthGuard implements CanActivate {

	constructor(private router: Router, private authService: AuthService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const redirectUrl = route['_routerState']['url'];

		if (Global.userLogged) {
			return true;
		}

		return new Observable<boolean>((observer) => {
			this.authService
				.checkUserLogged()
				.pipe(map(data => {
					Global.badges = data.badges;
					Global.info = data.get_info;
					this.authService.setUser(data.user);

					observer.next(true);
					observer.complete();
				}))
				.pipe(catchError((err: any) => {

					if (err && err.code === 3) {
						console.log('navigateByUrl');
						this.router.navigateByUrl(
							this.router.createUrlTree(
								['/login'], {
									queryParams: {
										redirectUrl
									}
								}
							)
						);
					} else {
						this.router.navigateByUrl(
							this.router.createUrlTree(
								['/re-login-loop'], {
									queryParams: {
										redirectUrl
									}
								}
							)
						);
					}

					observer.error(false);
					observer.complete();

					return of(false);
				})).subscribe(() => {});
		});

	}
}
