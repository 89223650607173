import {Injectable} from '@angular/core';
import {Helpers} from '../../services/helpers';
import {ActivatedRoute, Router} from '@angular/router';
import {RouterHistoric} from './router-historic.service';
import {Location} from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class ModalPageMobileService {

	private pages: any[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
	) {
		this.route.fragment.subscribe((fragment: string) => {
			if (fragment) {
				this.open(fragment);
			} else {
				this.closeAll();
			}
		});
	}

	add(page: any) {
		if (Helpers.empty(page.name)) {
			throw new Error('Nome da página não definida');
		}
		// add page to array of active pages
		this.pages.push(page);
	}

	remove(page: any) {
		// remove page from array of active pages
		this.pages = this.pages.filter(x => x.name !== page.name);
	}

	open(id: string) {
		// open page specified by id
		let page: any = this.pages.filter(x => x.name === id)[0];

		if (page) {
			page.open();

			this.router.navigate([], {
				fragment: id,
				preserveQueryParams: true
			});
		}
	}

	close(id: string) {
		// close page specified by id
		let page: any = this.pages.filter(x => x.name === id)[0];

		if (page) {
			page.close();
		}
	}

	removeHash(page: any) {
		this.router.navigate([], {
			fragment: undefined,
			preserveQueryParams: true,
			replaceUrl: true
		});
	}

	closeAll() {
		this.pages.map(page => {
			page.close();
		});
	}
}
