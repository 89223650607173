<div class="field-container input-number" [class.error-input]="error">
    <input type="type"
           autocomplete="asdasd"
           autocorrect="off"
           autocapitalize="off"
           spellcheck="false"
           [(ngModel)]="value">

    <div class="plus" (click)="plus()">
        +
    </div>
    <div class="minus" (click)="minus()">
        -
    </div>
</div>
