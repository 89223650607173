<div class="fake"></div>
<textarea type="text"
          name="value"
          rows="1"
		  [placeholder]="placeholder"
          [maxlength]="this.maxlength"
          id="textarea-resize"
          (keydown)="onKeyDown($event)"
          [formControl]="_value"
          [style.height]="height" #textareaEl></textarea>
<button class="over-screen" #submitScreen></button>
