import {Injectable} from '@angular/core';
import {CrudManager} from '../../services/crud-manager';
import {Global} from '../../services/global';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {RealEstate} from '../interfaces/real-estate';

@Injectable({
	providedIn: 'root'
})
export class LocationSearchService extends CrudManager {
	path = '/admin/locations/lazy-search';

	constructor() {
		super();
		this.path = '/realestate/lazy-search?token=' + Global.user.realestate.hash;
	}

	setRealEstate(realEstate: RealEstate) {
		this.path = '/realestate/lazy-search?token=' + realEstate.hash;
	}

	/**
	 * Buscar recursos
	 * @param _body
	 * @returns {Observable<any>}
	 */
	search(_body: any): Observable<any> {
		let body = {..._body};

		return this.http
			.post(environment.API + '/admin/locations/lazy-search/equivalence', body)
			.pipe(map(data => this.responseTreatment.pipe(data)))
			.pipe(catchError(this.responseTreatment.handleError));
	}
}
