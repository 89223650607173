import {
	ApplicationRef,
	ChangeDetectorRef,
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	Output
} from '@angular/core';

@Directive({
	selector: '[clickOutside]'
})
export class ClickOutsideDirective implements OnChanges {

	@Input() slim = false;

	@Output()
	public clickOutside = new EventEmitter();

	constructor(
		private _elementRef: ElementRef
	) {
	}

	childCheck(elements, targetElement) {
		for (let i = 0; i < elements.length; i++) {
			if (elements[i].contains(targetElement)) {
				return true;
			} else {
				if (elements[i].childElementCount > 0) {
					if (this.childCheck(elements[i].childNodes, targetElement)) {
						return true;
					}
				}
			}
		}

		return false;
	}

	@HostListener('document:click', ['$event.target'])
	public onClick(targetElement) {
		const clickedInside = this._elementRef.nativeElement.contains(targetElement);

		if (this.slim) {
			if (!clickedInside) {
				this.clickOutside.emit();
			}
		}

		if (!clickedInside) {
			if (!this.childCheck(this._elementRef.nativeElement.childNodes, targetElement)) {
				this.clickOutside.emit();
			}
		}
	}

	ngOnChanges(): void {
		// console.log('change');
	}

}
