import { BrowserModule } from "@angular/platform-browser";
import { Injector, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PagesModule } from "./content/pages/pages.module";
import { LayoutModule } from "./content/layout/layout.module";
import { NeedAuthGuard } from "./services/NeedAuthGuard";
import { HttpClientModule } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { InjectorInstance } from "./services/InjectorInstance";
import { SortablejsModule } from "angular-sortablejs";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PlaySound } from "./services/PlaySound";
import { ToastrModule } from "ngx-toastr";
import { ColorPickerModule } from "ngx-color-picker";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NeedAuthGuardLogin } from "./services/NeedAuthGuardLogin";
import { ServiceWorkerModule } from "@angular/service-worker";
import { MessagingService } from "./services/messaging.service";
import { CordovaService } from "./services/cordova.service";
import { ContactsModule } from "./content/views/contacts/contacts.module";

@NgModule({
	declarations: [AppComponent],
	imports: [
		ServiceWorkerModule.register("/ngsw-worker.js"),
		BrowserModule,
		AppRoutingModule,
		PagesModule,
		ContactsModule,
		LayoutModule,
		HttpClientModule,
		BrowserAnimationsModule,
		SortablejsModule.forRoot({
			animation: 10
		}),
		// NgxMaskModule.forRoot(),
		ToastrModule.forRoot({
			positionClass: "toast-bottom-right"
		}),
		ColorPickerModule,
		InfiniteScrollModule
		// AngularFireDatabaseModule,
		// AngularFireAuthModule,
		// ConnectionServiceModule,
		// AngularFireMessagingModule,
		// AngularFireModule.initializeApp(environment.firebase),
	],
	providers: [
		NeedAuthGuard,
		NeedAuthGuardLogin,
		MessagingService,
		CordovaService,
		CookieService
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	/**
	 * Injector
	 * @param {Injector} injector
	 */
	constructor(private injector: Injector) {
		InjectorInstance.Injector = this.injector;

		PlaySound.load();

		/**
		 * Barulho touch para se assemelhar com app nativo
		 */
		document.addEventListener("click", function(e: any) {
			if (
				e.target.tagName === "INPUT" ||
				e.target.tagName === "BUTTON" ||
				e.target.tagName === "SELECT" ||
				e.target.tagName === "TEXTAREA" ||
				e.target.className.indexOf("btn") !== -1 ||
				e.target.className.indexOf("click-sound") !== -1
			) {
				CordovaService.touchSound();
			}
		});

		/**
		 * Quando o input está fora da visão do viewport os navegadores ajustam o input
		 * para aparecer na parte visivel do tela. O problema disso é que ele definem
		 * que você está usando o scroll do body, caso esteja usam um div scrollable
		 * sua tela fica toda desconfigurada.
		 * Este código corrige isso, corrigindo o div scrollable antes que o navegador
		 * perceba que precisa modificar o body, entretanto os formulários sempre precisa estar
		 * dentro de um .mobile-form
		 */
		document.addEventListener("input", function(e: any) {
			if (e.target.isOutOfViewport().any) {
				let s =
					e.target.offsetTop +
					(e.target.offsetParent
						? e.target.offsetParent.documentOffsetTop()
						: 0);
				let top = s - 100;

				setTimeout(() => {
					document.getElementsByClassName(
						"main-page"
					)[0].scrollTop = 0;
					document.getElementsByClassName(
						"flex-body"
					)[0].scrollTop = top;
				}, 0);
				Promise.resolve(null).then(() => {
					document.getElementsByClassName(
						"main-page"
					)[0].scrollTop = 0;
					document.getElementsByClassName(
						"flex-body"
					)[0].scrollTop = top;
				});
			}
		});
	}
}
