import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoginService } from "../../models/login.service";
import { AuthService } from "../../../services/auth.service";
import { map } from "rxjs/operators";
import { ActivatedRoute, Router, RouterStateSnapshot } from "@angular/router";
import { Global } from "../../../services/global";
import { Location } from "@angular/common";
import { GlobalService } from "../../shareds/global.service";
import { IndexedDB } from "../../../services/IndexedDB";
import {
	animate,
	query,
	stagger,
	state,
	style,
	transition,
	trigger
} from "@angular/animations";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
	animations: [
		trigger("minLogin", [
			state(
				"loading",
				style({
					height: 0,
					opacity: 0,
					transform: "scale(0.0)"
				})
			),
			transition("* => loading", [
				style({
					transform: "scale(1.0)",
					opacity: "1.0",
					overflow: "hidden",
					height: "*"
				}),
				animate(
					400,
					style({
						height: 0,
						opacity: 0,
						transform: "scale(0.0)"
					})
				)
			]),

			state(
				"*",
				style({
					transform: "scale(1.0)",
					opacity: "1.0",
					overflow: "hidden",
					height: "*"
				})
			),
			transition("loading => *", [
				style({
					height: 0,
					opacity: 0,
					transform: "scale(0.0)"
				}),
				animate(
					400,
					style({
						transform: "scale(1.0)",
						opacity: "1.0",
						overflow: "hidden",
						height: "*"
					})
				)
			])
		]),
		trigger("displayLoading", [
			state(
				"*",
				style({
					display: "none"
				})
			),
			transition("* => loading", [
				style({
					display: "none"
				}),
				query("*", [style({ display: "block" })], { delay: 20000 })
			])
		])
	]
})
export class LoginComponent implements OnInit {
	form: FormGroup;
	error = "";

	loading = "";

	constructor(
		private fb: FormBuilder,
		private loginService: LoginService,
		private authService: AuthService,
		private route: ActivatedRoute,
		private _globalService: GlobalService,
		private location: Location,
		private router: Router
	) {
		// Direciona usuário
		if (Global.userLogged) {
			if (this.route.snapshot.queryParams.redirectUrl) {
				this.location.replaceState(
					this.route.snapshot.queryParams.redirectUrl
				);
			} else {
				this.location.replaceState("/");
			}
		}
	}

	ngOnInit() {
		this._globalService.updateLoadingScreen(false);

		this.form = this.fb.group({
			login: ["", [Validators.required]],
			password: ["", []]
		});
	}

	submit() {
		this.error = "";
		if (this.form.valid) {
			let value = this.form.value;
			value.login = value.login.replace(/ /g, "");

			// this._globalService.updateLoadingScreen(true);

			this.loading = "loading";

			setTimeout(() => {
				this.loginService.login(this.form.value).subscribe(
					data => {
						new IndexedDB("").deleteDatabase();

						// Salva Usuário
						this.authService.setUser(data);

						this.authService.checkUserLogged().subscribe(
							result => {
								Global.badges = result.badges;
								Global.info = result.get_info;
								this.authService.setUser(result.user);

								if (
									this.route.snapshot.queryParams.redirectUrl
								) {
									this.router.navigateByUrl(
										this.route.snapshot.queryParams
											.redirectUrl
									);
								} else {
									this.router.navigateByUrl("/");
								}

								// this.loading = '';
							},
							() => {
								// this._globalService.updateLoadingScreen(false);
								this.loading = "";
							}
						);
					},
					data => {
						this.loading = "";

						// this._globalService.updateLoadingScreen(false);
						this.error = data.error.message;
						if (!this.error) {
							this.error = "Erro ao conectar com servidor.";
						}
					}
				);
			}, 1000);
		}

		// console.log(this.form.valid);
		// console.log(this.form.value);
	}
}
