/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settings-theme.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../components/nav-bar/nav-bar.component.ngfactory";
import * as i4 from "../../components/nav-bar/nav-bar.component";
import * as i5 from "../../shareds/global.service";
import * as i6 from "./settings-theme.component";
import * as i7 from "../../models/real-estate.service";
import * as i8 from "ngx-toastr";
var styles_SettingsThemeComponent = [i0.styles];
var RenderType_SettingsThemeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettingsThemeComponent, data: {} });
export { RenderType_SettingsThemeComponent as RenderType_SettingsThemeComponent };
function View_SettingsThemeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "col-md-4 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "theme"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "active": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-primary btn-round"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openTheme(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Pr\u00E9-visualizar"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-secundary btn-round"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeTheme(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Definir"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "theme"; var currVal_1 = _ck(_v, 3, 0, _co.checkTheme(_v.context.$implicit)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.$implicit.image; _ck(_v, 7, 0, currVal_3); }); }
export function View_SettingsThemeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "page-background page-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "flex-grow-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-nav-bar", [], null, null, null, i3.View_NavBarComponent_0, i3.RenderType_NavBarComponent)), i1.ɵdid(3, 114688, null, 0, i4.NavBarComponent, [i5.GlobalService], { title: [0, "title"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "max-width"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "card p-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "title-form"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Tema do site "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Escolha o tema do site da sua imobili\u00E1ria."])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsThemeComponent_1)), i1.ɵdid(14, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Gerenciar Temas"; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.themeList; _ck(_v, 14, 0, currVal_1); }, null); }
export function View_SettingsThemeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-settings-theme", [], null, null, null, View_SettingsThemeComponent_0, RenderType_SettingsThemeComponent)), i1.ɵdid(1, 114688, null, 0, i6.SettingsThemeComponent, [i7.RealEstateService, i8.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SettingsThemeComponentNgFactory = i1.ɵccf("app-settings-theme", i6.SettingsThemeComponent, View_SettingsThemeComponent_Host_0, {}, {}, []);
export { SettingsThemeComponentNgFactory as SettingsThemeComponentNgFactory };
