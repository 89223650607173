var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Helpers } from './../../../../../services/helpers';
import { OnInit } from '@angular/core';
import { skip } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LocationCitiesService } from 'src/app/content/models/location-cities.service';
import { TitleService } from 'src/app/services/title.service';
var CityListComponent = /** @class */ (function () {
    function CityListComponent(route, router, fb, _cityEqService, _toastrService, _titleService) {
        this.route = route;
        this.router = router;
        this.fb = fb;
        this._cityEqService = _cityEqService;
        this._toastrService = _toastrService;
        this._titleService = _titleService;
        this.locations_count = 0;
        this.perPage = 40;
        this.currentPage = 1;
        // Buscando...
        this.searching = false;
    }
    CityListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.locations = this.route.snapshot.data.locations.results;
        this.locations_count = this.route.snapshot.data.locations.count;
        this.configureFilters(this.route.snapshot.queryParams);
        this.route.queryParams
            .pipe(skip(1))
            .subscribe(function (data) {
            _this.loadLocations(data);
        });
        this.setTitle();
    };
    CityListComponent.prototype.setTitle = function () {
        var title = 'Lista de Cidades';
        this._titleService.setListTitle(title, this.currentPage);
    };
    CityListComponent.prototype.refresh = function () {
        return this.loadLocations(this.route.snapshot.queryParams);
    };
    CityListComponent.prototype.configureFilters = function (data) {
        if (data === void 0) { data = {}; }
        if (!!this.route.snapshot.queryParams.offset) {
            this.currentPage = parseInt(String(this.route.snapshot.queryParams.offset / this.perPage), 10) + 1;
            this.route.snapshot.queryParams.offset = (this.currentPage - 1) * this.perPage;
        }
        this.filters = this.fb.group({
            sort: [data.sort || '', []],
            limit: [data.limit || '', []],
            textual_search: [data.textual_search || '', []],
            offset: [data.offset, []],
        });
        console.log(this.filters);
    };
    CityListComponent.prototype.sort = function (sort) {
    };
    /**
     * Resgata mais contatos
     * @param data
     * @returns {Subscription}
     */
    CityListComponent.prototype.loadLocations = function (data) {
        var _this = this;
        if (this.searching) {
            return false;
        }
        this.searching = true;
        return this._cityEqService
            .search(data)
            .subscribe(function (locations) {
            _this.locations = locations.results;
            _this.locations_count = locations.count;
            _this.searching = false;
        });
    };
    /**
     * Faz o tratamento dos filtros antes da busca
     * @returns {any}
     */
    CityListComponent.prototype.getFilters = function () {
        var filters = __assign({}, this.filters.value);
        return filters;
    };
    /**
     * Atualiza novos parametros
     */
    CityListComponent.prototype.updateParams = function (newSearch) {
        if (newSearch === void 0) { newSearch = true; }
        // Reset página
        if (newSearch) {
            this.filters.controls.offset.setValue(undefined);
            this.currentPage = 1;
        }
        this.router.navigate([], {
            queryParams: Helpers.filterArray(this.getFilters(), [
                Helpers.WITH_ZERO
            ])
        });
    };
    /**
     * Trocar página
     * @param {number} page
     */
    CityListComponent.prototype.setPage = function (page) {
        this.currentPage = page;
        this.setTitle();
        page = page - 1;
        this.filters.controls.offset.setValue(page * this.perPage);
        this.updateParams(false);
    };
    CityListComponent.prototype.delete = function (id) {
        var _this = this;
        this._cityEqService.delete(id)
            .subscribe(function (res) {
            _this._toastrService.success('Deletado com sucesso!');
            _this.loadLocations(_this.route.snapshot.queryParams);
        }, function (err) {
            _this._toastrService.error("Erro ao deletar.");
        });
    };
    return CityListComponent;
}());
export { CityListComponent };
