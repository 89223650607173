<div class="app-input-datepicker {{theme}}"
     (clickOutside)="toggleDatePicker(false)">
    <!--<input type="text">-->
    <div class="input" (click)="toggleDatePicker(true)">
        <div class="empty" *ngIf="emptyCalendar">
        </div>
        <div class="table" *ngIf="!emptyCalendar">
            <div class="td td-min text-primary calendar-icon">
                <i class="far fa-calendar-alt"></i>
            </div>
            <div class="td td-overflow-text pl-2 text-primary font-weight-bold">
                <div class="date" *ngIf="selectedDateStringView">{{selectedDateStringView}}</div>
                <div class="time" *ngIf="hours && !allDay && !noTime">Ás {{hours}}</div>
                <div class="time" *ngIf="allDay && !noTime">O dia todo</div>
            </div>
            <div class="td text-primary font-weight-bold" (click)="empty($event)">
                <i class="fas fa-times"></i>
            </div>
        </div>
    </div>

    <div class="datepicker-over" *ngIf="_openDatePicker">
        <div class="datepicker">
            <div class="title-year">
                2019
            </div>
            <div class="title-date-current">
                Sex, 22 de Fev
            </div>
            <div class="month">
                <i class="fas fa-angle-right right"
                   (click)="nextMonth()"
                    ></i>
                <i class="fas fa-angle-left left"
                   (click)="previousMonth()"
                    ></i>

                <div class="month-name">
                    {{monthName}}, {{calendarYear}}
                </div>
            </div>
            <div class="week week-name">
                <div class="day" *ngFor="let name of weeks_name">
                    {{name}}
                </div>
            </div>

            <div class="week" *ngFor="let week of weeks">
                <div class="day"
                     *ngFor="let day of week">
                    <div class="day-inside"
                         (click)="selectDate(day)"
                         [class.current-day]="day.date == currentDate"
                         [class.select-day]="day.date == selectedDateString"
                         [class.last_month]="day.last_month"
                         [class.current_month]="day.current_month"
                         [class.next_month]="day.next_month">
                    {{day.day}}
                    </div>
                </div>
            </div>

            <div class="allday" *ngIf="!noTime">
                <div class="select-container">
                    <select [(ngModel)]="allDay" (change)="changeAllDay()">
                        <option [ngValue]="true">Dia todo</option>
                        <option [ngValue]="false">Escolher Data...</option>
                    </select>
                </div>
            </div>

            <form (change)="this.updateChanges()">
            <div class="timepicker" *ngIf="!allDay && !noTime">
                <div class="time">
                    <div class="table">
                        <div class="td td-min">
                            <div class="hour">
                                <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="hourModel" value="20" maxlength="2" (input)="changeHour(hour)" #hour>
                            </div>
                        </div>
                        <div class="td td-min">:</div>
                        <div class="td td-min">
                            <div class="minute">
                                <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="minuteModel" value="20" maxlength="2" (input)="changeMinute(minute)" #minute>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </form>

            <div class="row pt-3">
                <div class="col">
                    <button class="btn btn-light btn-sm w-100" (click)="cancel()">Cancelar</button>
                </div>
                <div class="col">
                    <button class="btn btn-primary btn-sm w-100" (click)="toggleDatePicker(false)">Salvar</button>
                </div>
            </div>
        </div>
    </div>
</div>
