import { CrudManager } from '../../services/crud-manager';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationSubNeighborhoodErrorService extends CrudManager {
	path = '/admin/locations/subneighborhoods-errors';

	constructor() {
		super();
	}
}
