<div class="alert-top" *ngIf="false">
	Ambiente de testes
</div>

<div class="header header-plataform hide-mobile">
	<div class="max-width">
		<div class="logo-container">
			<a [routerLink]="['/']">
				<img src="assets/images/logo.png" alt="" />
			</a>
		</div>
		<div class="user-container">
			<div
				class="btn btn-primary btn-round"
				(click)="openWebsite()"
				*ngIf="!needComplete"
			>
				Meu site
			</div>

			<!--<div class="notification">-->
			<!--<div class="icon">-->
			<!--<i class="far fa-bell"></i>-->
			<!--</div>-->
			<!--</div>-->

			<div class="bar-vertical"></div>

			<div class="user-name">
				<div class="user-dropdown" (clickOutside)="closeUserMenu()">
					<div class="user-header" (click)="changeUserDropState()">
						<app-user-img
							[user]="user"
							[size]="'small'"
						></app-user-img>
						Olá, <b>{{ user.name }}</b>
					</div>

					<div class="user-body" *ngIf="userDropState">
						<ul class="user-menu-list" (click)="closeUserMenu()">
							<li class="user-menu-item user-item">
								<div class="user-container">
									<div class="user-image">
										<app-user-img
											[user]="user"
											[size]="'normal'"
										></app-user-img>
									</div>
									<div class="user-info">
										<div class="name">{{ user.name }}</div>
										<div class="role">
											{{ user.role_name }} -
											{{ user.realestate.name }}
										</div>
									</div>
								</div>
							</li>
							<li class="user-menu-item">
								<a
									class="list-link"
									[routerLink]="['/settings/account']"
								>
									<div class="container-item">
										<div class="icon-cell">
											<i class="fas fa-user-circle"></i>
										</div>
										<div class="text-cell">
											Meu perfil
										</div>
									</div>
								</a>
							</li>
							<li class="user-menu-item">
								<span class="list-link" (click)="logout()">
									<div class="container-item">
										<div class="icon-cell">
											<i
												class="fas fa-sign-out-alt fa-rotate-180"
											></i>
										</div>
										<div class="text-cell">
											Sair
										</div>
									</div>
								</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!--<div class="header mobile-header header-plataform show-mobile">-->

<!--<div class="menu-container">-->
<!--<button (click)="changeMenu()">-->
<!--<i class="fas fa-bars"></i>-->
<!--</button>-->
<!--</div>-->
<!--<div class="logo-container">-->
<!--<a [routerLink]="['/']">-->
<!--<img src="assets/images/logo-icon.png" style="height: 30px;">-->
<!--</a>-->
<!--</div>-->
<!--<div class="user-container">-->
<!--<div class="patners">-->
<!--<div class="icon" [routerLink]="['/partnership']">-->
<!--<i class="far fa-handshake"></i>-->

<!--<div class="badge" *ngIf="chatPartnersBadged">-->
<!--{{chatPartnersBadged}}-->
<!--</div>-->
<!--</div>-->
<!--</div>-->

<!--&lt;!&ndash;<div class="notification">&ndash;&gt;-->
<!--&lt;!&ndash;<div class="icon">&ndash;&gt;-->
<!--&lt;!&ndash;<i class="far fa-bell"></i>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--</div>-->
<!--</div>-->

<div class="fast-menu-bottom">
	<li [routerLink]="['/']">
		<img src="assets/images/fast-icon-home.png" alt="" />
	</li>
	<li [routerLink]="['/properties/list']">
		<img src="assets/images/fast-icon-properties.png" alt="" />
	</li>
	<li [routerLink]="['/partnership']">
		<img
			src="assets/images/fast-icon-chat-partners.png"
			alt=""
			class="center"
		/>
	</li>
	<li [routerLink]="['/properties/partnership']">
		<img src="assets/images/fast-icon-properties-partners.png" alt="" />
	</li>
	<li [routerLink]="['/calendar']">
		<img src="assets/images/fast-icon-agenda.png" alt="" />
	</li>
	<!--<li>-->
	<!--<a [routerLink]="['/sales-funnel/list']">-->
	<!--<span class="drop-title">-->
	<!--<div class="icon">-->
	<!--<i class="fas fa-tachometer-alt"></i>-->
	<!--</div>-->
	<!--Início-->
	<!--</span>-->
	<!--</a>-->
	<!--</li>-->

	<!--<li>-->
	<!--<a [routerLink]="['/sales-funnel/list']">-->
	<!--<span class="drop-title">-->
	<!--<div class="icon">-->
	<!--<i class="fas fa-home"></i>-->
	<!--</div>-->
	<!--Meus imóveis-->
	<!--</span>-->
	<!--</a>-->
	<!--</li>-->

	<!--<li>-->
	<!--<a [routerLink]="['/sales-funnel/list']">-->
	<!--<span class="drop-title">-->
	<!--<div class="icon">-->
	<!--<img src="assets/images/patners-icon-25x25.png">-->
	<!--</div>-->
	<!--Chat-->
	<!--</span>-->
	<!--</a>-->
	<!--</li>-->

	<!--<li>-->
	<!--<a [routerLink]="['/sales-funnel/list']">-->
	<!--<span class="drop-title">-->
	<!--<div class="icon">-->
	<!--<i class="fas fa-filter"></i>-->
	<!--</div>-->
	<!--Funil-->
	<!--</span>-->
	<!--</a>-->
	<!--</li>-->

	<!--<li>-->
	<!--<a [routerLink]="['/sales-funnel/list']">-->
	<!--<span class="drop-title">-->
	<!--<div class="icon">-->
	<!--<i class="fas fa-bars"></i>-->
	<!--</div>-->
	<!--Mais-->
	<!--</span>-->
	<!--</a>-->
	<!--</li>-->
</div>

<div class="nav-bar nav-md">
	<ng-container *ngTemplateOutlet="navContent"></ng-container>
</div>

<div
	class="nav-bar mobile-nav show-mobile"
	[class.menu-open]="menuShowVar"
	(click)="closeMenu()"
>
	<ng-container *ngTemplateOutlet="navContent"></ng-container>
</div>

<div
	class="overlay show-mobile"
	*ngIf="menuShowVar"
	[@showOverlay]="menuShowVar"
	(click)="closeMenu()"
></div>

<ng-template #navContent>
	<ul>
		<li class="user-item show-mobile" [routerLink]="['/settings/account']">
			<div class="user-container">
				<div class="user-image">
					<app-user-img
						[user]="user"
						[size]="'normal'"
					></app-user-img>
				</div>
				<div class="user-info td td-overflow-text">
					<span class="name">{{ user.name }}</span
					><br />
					<!--<div class="role">{{user.role_name}} - {{user.realestate.name}}</div>-->
					<span>Editar Perfil</span>
				</div>
			</div>
		</li>

		<!--<li class="show-mobile">-->
		<!--<a [routerLink]="['/settings/account']">-->
		<!--<span class="drop-title">-->
		<!--<div class="icon">-->
		<!--<i class="fas fa-user-circle"></i>-->
		<!--</div>-->
		<!--Meu Perfil-->
		<!--</span>-->
		<!--</a>-->
		<!--</li>-->

		<li class="active">
			<a [routerLink]="['/']">
				<span class="drop-title">
					<div class="icon">
						<i class="fas fa-tachometer-alt"></i>
					</div>
					Início
				</span>
			</a>
		</li>
		<li
			class="menu menu-submenu"
			[ngClass]="{
				'menu-activated':
					subMenuOpen === 'all' || subMenuOpen === 'properties'
			}"
		>
			<div
				class="table drop-title"
				(click)="openSubmenu('properties', $event)"
			>
				<span class="td">
					<div class="icon">
						<div class="icon-i">
							<i class="fas fa-home"></i>
							<div
								class="badge hide-mobile"
								*ngIf="chatPropertiesBadged"
							>
								{{ chatPropertiesBadged }}
							</div>
						</div>
					</div>
					Imóveis
				</span>
				<span class="icon-drop td-min td">
					<i class="fas fa-arrow-up"></i>
				</span>
			</div>

			<ul class="submenu">
				<li>
					<a [routerLink]="['/properties/list']">
						<i class="fas fa-caret-right arrow-menu"></i> Meus
						Imóveis
					</a>
				</li>
				<li *ngIf="user.permissions.ADD_PROPERTIES">
					<a [routerLink]="['/properties/add']">
						<i class="fas fa-caret-right arrow-menu"></i> Adicionar
						Imóveis
					</a>
				</li>
				<li *ngIf="user.permissions.ADD_PROPERTIES">
					<a [routerLink]="['/properties/approval']">
						<i class="fas fa-caret-right arrow-menu"></i> Imóveis
						para Aprovação
						<div
							class="badge show-mobile"
							*ngIf="chatPropertiesBadged"
						>
							{{ chatPropertiesBadged }}
						</div>
					</a>
				</li>
				<li *ngIf="!APP_MOBILE && user.permissions.REALESTATE_MANAGER">
					<a [routerLink]="['/properties/features']">
						<i class="fas fa-caret-right arrow-menu"></i> Imóves
						destaque
					</a>
				</li>
			</ul>
		</li>

		<li
			class="menu menu-submenu"
			[ngClass]="{
				'menu-activated':
					subMenuOpen === 'all' || subMenuOpen === 'clients'
			}"
		>
			<div
				class="table drop-title"
				(click)="openSubmenu('clients', $event)"
			>
				<span class="td">
					<div class="icon">
						<i class="far fa-envelope-open"></i>
					</div>
					Clientes
				</span>
				<span class="icon-drop td-min td">
					<i class="fas fa-arrow-up"></i>
				</span>
			</div>

			<ul class="submenu">
				<li>
					<a [routerLink]="['/contacts/list']">
						<i class="fas fa-caret-right arrow-menu"></i> Clientes
					</a>
				</li>
				<li>
					<a [routerLink]="['/contacts/add']">
						<i class="fas fa-caret-right arrow-menu"></i> Adicionar
						Clientes
					</a>
				</li>
				<li>
					<a [routerLink]="['/contacts/buyers/lists']">
						<i class="fas fa-caret-right arrow-menu"></i>
						Compradores
					</a>
				</li>
			</ul>
		</li>

		<li *ngIf="!APP_MOBILE || MASTER">
			<a [routerLink]="['/sales-funnel/list']">
				<span class="drop-title">
					<div class="icon">
						<i class="fas fa-filter"></i>
					</div>
					Funil de Vendas
				</span>
			</a>
		</li>

		<li
			class="menu menu-submenu"
			*ngIf="user.permissions.USERS_MANAGER"
			[ngClass]="{
				'menu-activated':
					subMenuOpen === 'all' || subMenuOpen === 'users'
			}"
		>
			<div
				class="table drop-title"
				(click)="openSubmenu('users', $event)"
			>
				<span class="td">
					<div class="icon">
						<i class="fas fa-users"></i>
					</div>
					Corretores
				</span>
				<span class="icon-drop td-min td">
					<i class="fas fa-arrow-up"></i>
				</span>
			</div>

			<ul class="submenu">
				<li>
					<a [routerLink]="['/users/list']">
						<i class="fas fa-caret-right arrow-menu"></i> Lista de
						Corretores
					</a>
				</li>
				<li>
					<a [routerLink]="['/users/add']">
						<i class="fas fa-caret-right arrow-menu"></i> Adicionar
						Corretor
					</a>
				</li>
			</ul>
		</li>

		<li *ngIf="!APP_MOBILE">
			<a [routerLink]="['/tasks']">
				<span class="drop-title">
					<div class="icon">
						<i class="fas fa-tasks"></i>
					</div>
					Tarefas
				</span>
			</a>
		</li>

		<li>
			<a [routerLink]="['/calendar']">
				<span class="drop-title">
					<div class="icon">
						<div class="icon-i">
							<i class="far fa-calendar-alt"></i>
							<div class="badge" *ngIf="chatAgendaBadged">
								{{ chatAgendaBadged }}
							</div>
						</div>
					</div>
					Agenda
				</span>
			</a>
		</li>

		<li
			class="menu menu-submenu"
			*ngIf="MASTER"
			[ngClass]="{
				'menu-activated':
					subMenuOpen === 'all' || subMenuOpen === 'equivalence'
			}"
		>
			<div
				class="table drop-title"
				(click)="openSubmenu('equivalence', $event)"
			>
				<span class="td">
					<div class="icon">
						<i class="fas fa-key"></i>
					</div>
					Equivalência
				</span>
				<span class="icon-drop td-min td">
					<i class="fas fa-arrow-up"></i>
				</span>
			</div>

			<ul class="submenu">
				<li>
					<a [routerLink]="['/equivalence/not-found/list']">
						<i class="fas fa-caret-right arrow-menu"></i>
						Localizações não encontradas
					</a>
				</li>
				<li>
					<a [routerLink]="['/equivalence/city/list']">
						<i class="fas fa-caret-right arrow-menu"></i> Cidades
					</a>
				</li>
				<li>
					<a [routerLink]="['/equivalence/neighborhood/list']">
						<i class="fas fa-caret-right arrow-menu"></i> Bairros
					</a>
				</li>
				<li>
					<a [routerLink]="['/equivalence/neighborhood-error/list']">
						<i class="fas fa-caret-right arrow-menu"></i> Bairros
						Erros
					</a>
				</li>
				<li>
					<a [routerLink]="['/equivalence/sub-neighborhood/list']">
						<i class="fas fa-caret-right arrow-menu"></i> SubBairros
					</a>
				</li>
				<li>
					<a
						[routerLink]="[
							'/equivalence/sub-neighborhood-error/list'
						]"
					>
						<i class="fas fa-caret-right arrow-menu"></i> SubBairros
						Erros
					</a>
				</li>
			</ul>
		</li>

		<li
			class="menu menu-submenu"
			*ngIf="MASTER"
			[ngClass]="{
				'menu-activated':
					subMenuOpen === 'all' || subMenuOpen === 'equivalence'
			}"
		>
			<div
				class="table drop-title"
				(click)="openSubmenu('equivalence', $event)"
			>
				<span class="td">
					<div class="icon">
						<i class="fas fa-key"></i>
					</div>
					Add Equivalência
				</span>
				<span class="icon-drop td-min td">
					<i class="fas fa-arrow-up"></i>
				</span>
			</div>

			<ul class="submenu">
				<li>
					<a [routerLink]="['/equivalence/city/add']">
						<i class="fas fa-caret-right arrow-menu"></i> Cidades
					</a>
				</li>
				<li>
					<a [routerLink]="['/equivalence/neighborhood/add']">
						<i class="fas fa-caret-right arrow-menu"></i> Bairros
					</a>
				</li>
				<li>
					<a [routerLink]="['/equivalence/neighborhood-error/add']">
						<i class="fas fa-caret-right arrow-menu"></i> Bairros
						Erros
					</a>
				</li>
				<li>
					<a [routerLink]="['/equivalence/sub-neighborhood/add']">
						<i class="fas fa-caret-right arrow-menu"></i> SubBairros
					</a>
				</li>
				<li>
					<a
						[routerLink]="[
							'/equivalence/sub-neighborhood-error/add'
						]"
					>
						<i class="fas fa-caret-right arrow-menu"></i> SubBairros
						Erros
					</a>
				</li>
			</ul>
		</li>

		<li
			class="menu menu-submenu"
			[ngClass]="{
				'menu-activated':
					subMenuOpen === 'all' || subMenuOpen === 'partners'
			}"
		>
			<div
				class="table drop-title"
				(click)="openSubmenu('partners', $event)"
			>
				<span class="td">
					<div class="icon">
						<div class="icon-i">
							<i class="far fa-handshake"></i>
							<div
								class="badge hide-mobile"
								*ngIf="chatPartnersBadged"
							>
								{{ chatPartnersBadged }}
							</div>
						</div>
					</div>
					Parceria
				</span>
				<span class="icon-drop td-min td">
					<i class="fas fa-arrow-up"></i>
				</span>
			</div>

			<ul class="submenu">
				<li>
					<a [routerLink]="['/partnership']" *ngIf="!needComplete">
						<i class="fas fa-caret-right arrow-menu"></i> Chat de
						Parcerias

						<div
							class="badge show-mobile"
							*ngIf="chatPartnersBadged"
						>
							{{ chatPartnersBadged }}
						</div>
					</a>
					<a
						[routerLink]="['/complete-register']"
						*ngIf="needComplete"
					>
						<i class="fas fa-caret-right arrow-menu"></i> Chat de
						Parcerias

						<div
							class="badge show-mobile"
							*ngIf="chatPartnersBadged"
						>
							{{ chatPartnersBadged }}
						</div>
					</a>
				</li>
				<li>
					<a [routerLink]="['/properties/partnership']">
						<i class="fas fa-caret-right arrow-menu"></i> Parceria
						em nossos imóveis
					</a>
				</li>
				<li>
					<a [routerLink]="['/clients-demand']">
						<i class="fas fa-caret-right arrow-menu"></i> Tenho
						cliente
					</a>
				</li>
				<li>
					<a [routerLink]="['/properties-demand']">
						<i class="fas fa-caret-right arrow-menu"></i> Preciso de
						Imóveis
					</a>
				</li>
				<!--<li>-->
				<!--<a [routerLink]="['/perfil-partners/list']">-->
				<!--<i class="fas fa-caret-right arrow-menu"></i> Filtro de Imóveis Parceiros-->
				<!--</a>-->
				<!--</li>-->
			</ul>
		</li>

		<li
			class="menu menu-submenu"
			*ngIf="user.permissions.REALESTATE_MANAGER"
			[ngClass]="{
				'menu-activated':
					subMenuOpen === 'all' || subMenuOpen === 'settings'
			}"
		>
			<div
				class="table drop-title"
				(click)="openSubmenu('settings', $event)"
			>
				<span class="td">
					<div class="icon">
						<i class="fas fa-cogs"></i>
					</div>
					Configurações
				</span>
				<span class="icon-drop td-min td">
					<i class="fas fa-arrow-up"></i>
				</span>
			</div>

			<ul class="submenu">
				<!--<li>-->
				<!--<a [routerLink]="['/settings/portals']">-->
				<!--<i class="fas fa-caret-right arrow-menu"></i> Portais-->
				<!--</a>-->
				<!--</li>-->
				<li>
					<a [routerLink]="['/settings/website']">
						<i class="fas fa-caret-right arrow-menu"></i> Configurar
						Design
					</a>
				</li>
				<li>
					<a [routerLink]="['/settings/theme']">
						<i class="fas fa-caret-right arrow-menu"></i> Mudar Tema
					</a>
				</li>
				<li>
					<a [routerLink]="['/settings/meta-tags']">
						<i class="fas fa-caret-right arrow-menu"></i> Meta-tags
					</a>
				</li>
				<li>
					<a [routerLink]="['/settings/general']">
						<i class="fas fa-caret-right arrow-menu"></i>
						Configurações Gerais
					</a>
				</li>
			</ul>
		</li>

		<li class="logout-item show-mobile" (click)="logout()">
			<span>
				<span class="icon">
					<i class="fas fa-sign-out-alt fa-rotate-180"></i>
				</span>
				<span>
					Sair
				</span>
			</span>
		</li>
	</ul>
</ng-template>
