<div class="card user-card text-center p-4">

    <app-user-img [user]="user" [size]="'big'"></app-user-img>

    <div class="name">{{ user.name }}</div>
    <div class="role">{{ user.role_name }}</div>
    <div class="email">{{ user.email }}</div>

    <div class="actions">
        <!--&lt;!&ndash; up &ndash;&gt;-->
        <!--<button class="btn btn-shadow btn-icon btn-round bg-white">-->
            <!--<i class="fas fa-user"></i>-->
        <!--</button>-->
        <!--&lt;!&ndash;/ up &ndash;&gt;-->

        <!-- up -->
        <button class="btn btn-shadow btn-icon btn-round bg-white" [routerLink]="['/users/edit', user.id]">
            <i class="fas fa-edit"></i>
        </button>
        <!--/ up -->

        <!-- up -->
        <button class="btn btn-shadow btn-icon btn-round bg-red" (click)="deleteEmit()">
            <i class="fas fa-trash"></i>
        </button>
        <!--/ up -->
    </div>
</div>
