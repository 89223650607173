import { LocationNeighborhoodErrorService } from './../models/location-neighborhood-error.service';
import { LocationNeighborhoodsService } from '../models/location-neighborhoods.service';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {EquivalenceNotFoundService} from '../models/equivalence-not-found.service';

@Injectable()
export class EquivalenceNeighborhoodErrorResolverService implements Resolve<any> {

	constructor(
		private _neighService: LocationNeighborhoodErrorService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {
		let params = {...route.queryParams};
		return this._neighService.search(params);
	}
}
