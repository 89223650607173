/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./field-error-display.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./field-error-display.component";
var styles_FieldErrorDisplayComponent = [i0.styles];
var RenderType_FieldErrorDisplayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FieldErrorDisplayComponent, data: {} });
export { RenderType_FieldErrorDisplayComponent as RenderType_FieldErrorDisplayComponent };
function View_FieldErrorDisplayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["(Erro)"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "error-msg"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMsg; _ck(_v, 4, 0, currVal_0); }); }
export function View_FieldErrorDisplayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FieldErrorDisplayComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayError; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FieldErrorDisplayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-field-error-display", [], null, null, null, View_FieldErrorDisplayComponent_0, RenderType_FieldErrorDisplayComponent)), i1.ɵdid(1, 49152, null, 0, i3.FieldErrorDisplayComponent, [], null, null)], null, null); }
var FieldErrorDisplayComponentNgFactory = i1.ɵccf("app-field-error-display", i3.FieldErrorDisplayComponent, View_FieldErrorDisplayComponent_Host_0, { errorMsg: "errorMsg", displayError: "displayError" }, {}, []);
export { FieldErrorDisplayComponentNgFactory as FieldErrorDisplayComponentNgFactory };
