import {Component, OnInit} from '@angular/core';
import {Users} from '../../interfaces/users';
import {Global} from '../../../services/global';

@Component({
    selector: 'app-initial-settings',
    templateUrl: './initial-settings.component.html',
    styleUrls: ['./initial-settings.component.scss']
})
export class InitialSettingsComponent implements OnInit {

    user: Users;

    constructor() {
        this.user = Global.user;
    }

    ngOnInit() {

    }

}
