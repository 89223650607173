var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { EventEmitter, OnInit } from '@angular/core';
import { ModalDefault } from '../../shareds/modal-default.service';
import { Subject } from 'rxjs';
import { PropertiesService } from '../../models/properties.service';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { Global } from '../../../services/global';
import { Helpers } from '../../../services/helpers';
import { ActivatedRoute, Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "../../models/properties.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/forms";
var ModalSelectPropertiesComponent = /** @class */ (function (_super) {
    __extends(ModalSelectPropertiesComponent, _super);
    function ModalSelectPropertiesComponent(propertiesService, route, router, fb) {
        var _this = _super.call(this) || this;
        _this.propertiesService = propertiesService;
        _this.route = route;
        _this.router = router;
        _this.fb = fb;
        _this.change = new EventEmitter();
        _this.searchTextChanged = new Subject();
        _this.openModal = false;
        _this.loading = false;
        _this.loadingMore = false;
        _this.properties = [];
        _this.properties_count = 0;
        _this.offset = 0;
        _this.limit = 10;
        _this.selectProperty = 0;
        _this.arrayNumber = [1, 2, 3, 4, 5, '6+'];
        _this.tab = 'filters';
        _this.types = Global.info.properties.types;
        _this.info = Global.info;
        if (_this.openModal) {
            _this.router.navigate([], {
                fragment: 'properties-search-modal',
                preserveQueryParams: true
            });
        }
        _this.route.fragment.subscribe(function (fragment) {
            if (fragment === 'properties-search-modal') {
                _this.openModal = true;
                _this.tab = 'properties';
            }
            else if (fragment === 'properties-search-modal!filters') {
                _this.openModal = true;
                _this.tab = 'filters';
            }
            else {
                _this.tab = 'properties';
                _this.openModal = false;
            }
        });
        return _this;
    }
    ModalSelectPropertiesComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.searchTextChanged.next('');
        var types = this.types.map(function (c) {
            return new FormControl(false);
        });
        this.filters = this.fb.group({
            offset: ['', []],
            sort: ['-created_at', []],
            cod: ['', []],
            root: this.fb.group({
                bdi_group: [true, []],
            }),
            finality: ['', []],
            location: [[]],
            status: [1, []],
            types: new FormArray(types),
            price_gte: ['', []],
            price_lte: ['', []],
            area_gte: ['', []],
            area_lte: ['', []],
            bedrooms: ['', []],
            suites: ['', []],
            bathrooms: ['', []],
            vacancies: ['', []],
            address: ['', []],
            townhouse_name: ['', []],
            'contact.name': ['', []],
        });
    };
    ModalSelectPropertiesComponent.prototype.onScroll = function () {
        var _this = this;
        if (!this.loadingMore && !this.loading) {
            if (this.offset >= this.properties_count) {
                return false;
            }
            this.loadingMore = true;
            return this.propertiesService.search(__assign({ limit: this.limit, offset: this.offset }, Helpers.filterArray(this.getFilters())))
                .subscribe(function (data) {
                _this.properties = _this.properties.concat(data.results);
                _this.properties_count = data.count;
                _this.offset += _this.limit;
                _this.loadingMore = false;
            });
        }
    };
    /**
     * Busca de Imóveis
     * @param {string} search
     * @returns {Observable<any>}
     */
    ModalSelectPropertiesComponent.prototype.searchProperties = function (search) {
        this.properties = [];
        return this.propertiesService.search(__assign({ limit: this.limit }, Helpers.filterArray(this.getFilters())));
    };
    /**
     * Escolhe Imóvel
     */
    ModalSelectPropertiesComponent.prototype.defineProperty = function () {
        if (this.selectProperty) {
            for (var i = 0; i < this.properties.length; i++) {
                if (this.selectProperty === this.properties[i].id) {
                    this.change.emit(this.properties[i]);
                    this.close();
                }
            }
        }
    };
    ModalSelectPropertiesComponent.prototype.openFilters = function () {
        this.router.navigate([], {
            fragment: 'properties-search-modal!filters',
            preserveQueryParams: true
        });
        this.tab = 'filters';
    };
    ModalSelectPropertiesComponent.prototype.open = function () {
        var _this = this;
        _super.prototype.open.call(this);
        this.loading = true;
        // if (!ModalSelectPropertiesComponent.initial_properties) {
        this.searchProperties('')
            .subscribe(function (data) {
            ModalSelectPropertiesComponent.initial_properties = data.results;
            ModalSelectPropertiesComponent.initial_properties_count = data.count;
            _this.properties = ModalSelectPropertiesComponent.initial_properties;
            _this.properties_count = ModalSelectPropertiesComponent.initial_properties_count;
            _this.offset = _this.limit;
            _this.loading = false;
        });
        // } else {
        // 	this.properties = ModalSelectPropertiesComponent.initial_properties;
        // 	this.properties_count = ModalSelectPropertiesComponent.initial_properties_count;
        // 	this.offset = this.limit;
        // 	this.loading = false;
        // }
        this.router.navigate([], {
            fragment: 'properties-search-modal',
            preserveQueryParams: true
        });
    };
    ModalSelectPropertiesComponent.prototype.close = function () {
        _super.prototype.close.call(this);
        this.selectProperty = 0;
        this.router.navigate([], {
            fragment: undefined,
            preserveQueryParams: true
        });
    };
    /**
     * Evento change no input
     * @param $event
     */
    ModalSelectPropertiesComponent.prototype.changeLocationInput = function ($event) {
        this.loading = true;
        var text = $event.target.value;
        this.searchTextChanged.next(text);
    };
    ModalSelectPropertiesComponent.prototype.changeNumber = function (name, number) {
        if (this.filters.get(name).value === number) {
            this.filters.get(name)
                .setValue(null);
        }
        else {
            this.filters.get(name)
                .setValue(number);
        }
        this.updateParams();
    };
    ModalSelectPropertiesComponent.prototype.clearParams = function () {
        this.filters.reset();
        this.updateParams();
    };
    /**
     * Cancelar filtros
     */
    ModalSelectPropertiesComponent.prototype.cancelFilters = function () {
    };
    /**
     * Faz o tratamento dos filtros antes da busca
     * @returns {any}
     */
    ModalSelectPropertiesComponent.prototype.getFilters = function () {
        var _this = this;
        var filters = __assign({}, this.filters.value);
        filters.type_in = filters.types
            .map(function (v, i) { return v ? _this.types[i].id : null; })
            .filter(function (v) { return v !== null; });
        if (filters.location) {
            filters.location = filters.location
                .map(function (v, i) {
                return v.neighborhood ?
                    v.estate + ',' + v.city + ',' + v.neighborhood :
                    v.estate + ',' + v.city;
            })
                .map(function (v, i) { return Helpers.normalizeString(v).replace(/ /g, '-'); })
                .join('_');
        }
        if (filters.price_gte) {
            filters.sort = 'price';
        }
        if (filters.price_lte) {
            filters.sort = '-price';
        }
        // Para não ficar url imenso
        // filters.users = undefined;
        filters.types = undefined;
        return filters;
    };
    ModalSelectPropertiesComponent.prototype.updateParams = function () {
        var _this = this;
        this.tab = 'properties';
        // if (newSearch) {
        // 	this.filters.controls.offset.setValue(undefined);
        // 	this.currentPage = 1;
        // }
        if (JSON.stringify(this.propertiesService.processFilters(this.route.snapshot.queryParams)) === JSON.stringify(Helpers.filterArray(this.getFilters()))) {
            return false;
        }
        this.properties = [];
        this.loading = true;
        this.offset = 0;
        return this.propertiesService.search(__assign({ limit: this.limit, offset: this.offset }, Helpers.filterArray(this.getFilters())))
            .subscribe(function (data) {
            _this.properties = data.results;
            _this.properties_count = data.count;
            _this.offset += _this.limit;
            _this.loadingMore = false;
            _this.loading = false;
        });
    };
    ModalSelectPropertiesComponent.prototype.applyFilter = function () {
        this.updateParams();
    };
    ModalSelectPropertiesComponent.ngInjectableDef = i0.defineInjectable({ factory: function ModalSelectPropertiesComponent_Factory() { return new ModalSelectPropertiesComponent(i0.inject(i1.PropertiesService), i0.inject(i2.ActivatedRoute), i0.inject(i2.Router), i0.inject(i3.FormBuilder)); }, token: ModalSelectPropertiesComponent, providedIn: "root" });
    return ModalSelectPropertiesComponent;
}(ModalDefault));
export { ModalSelectPropertiesComponent };
