import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalDefault } from '../../shareds/modal-default.service';
import { ModalAnimation } from '../../animations/animations';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { PropertiesService } from '../../models/properties.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Global } from '../../../services/global';
import { Helpers } from '../../../services/helpers';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-modal-select-properties',
	templateUrl: './modal-select-properties.component.html',
	styleUrls: ['./modal-select-properties.component.scss'],
	animations: [ModalAnimation],
})
export class ModalSelectPropertiesComponent extends ModalDefault implements OnInit {

	static initial_properties;
	static initial_properties_count;

	@Output() change = new EventEmitter();

	searchTextChanged = new Subject<string>();
	textModel: string;
	openModal = false;
	loading = false;
	loadingMore = false;
	properties = [];
	properties_count = 0;
	offset = 0;
	limit = 10;
	selectProperty = 0;

	// Filtros
	filters: FormGroup;
	types: Array<any>;
	info: any;
	arrayNumber = [1, 2, 3, 4, 5, '6+'];

	tab = 'filters';

	constructor(
		private propertiesService: PropertiesService,
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder
	) {
		super();

		this.types = Global.info.properties.types;
		this.info = Global.info;

		if (this.openModal) {
			this.router.navigate([], {
				fragment: 'properties-search-modal',
				preserveQueryParams: true
			});
		}

		this.route.fragment.subscribe((fragment: string) => {
			if (fragment === 'properties-search-modal') {
				this.openModal = true;
				this.tab = 'properties';
			} else if (fragment === 'properties-search-modal!filters') {
				this.openModal = true;
				this.tab = 'filters';
			} else {
				this.tab = 'properties';
				this.openModal = false;
			}
		});
	}

	ngOnInit() {

		super.ngOnInit();

		this.searchTextChanged.next('');


		let types: any = this.types.map(c => {
			return new FormControl(false);
		});

		this.filters = this.fb.group({
			offset: ['', []],
			sort: ['-created_at', []],
			cod: ['', []],
			root: this.fb.group({
				bdi_group: [true, []],
			}),
			finality: ['', []],
			location: [[]],
			status: [1, []],
			types: new FormArray(types),
			price_gte: ['', []],
			price_lte: ['', []],
			area_gte: ['', []],
			area_lte: ['', []],
			bedrooms: ['', []],
			suites: ['', []],
			bathrooms: ['', []],
			vacancies: ['', []],
			address: ['', []],
			townhouse_name: ['', []],
			'contact.name': ['', []],
		});
	}

	onScroll() {

		if (!this.loadingMore && !this.loading) {

			if (this.offset >= this.properties_count) {
				return false;
			}

			this.loadingMore = true;

			return this.propertiesService.search({
				limit: this.limit,
				offset: this.offset,
				...Helpers.filterArray(this.getFilters())
			})
				.subscribe(data => {
					this.properties = this.properties.concat(data.results);
					this.properties_count = data.count;
					this.offset += this.limit;
					this.loadingMore = false;
				});
		}
	}

	/**
	 * Busca de Imóveis
	 * @param {string} search
	 * @returns {Observable<any>}
	 */
	searchProperties(search: string) {
		this.properties = [];

		return this.propertiesService.search({
			limit: this.limit,
			...Helpers.filterArray(this.getFilters())
		});
	}

	/**
	 * Escolhe Imóvel
	 */
	defineProperty() {
		if (this.selectProperty) {
			for (let i = 0; i < this.properties.length; i++) {
				if (this.selectProperty === this.properties[i].id) {
					this.change.emit(this.properties[i]);
					this.close();
				}
			}
		}
	}

	openFilters() {
		this.router.navigate([], {
			fragment: 'properties-search-modal!filters',
			preserveQueryParams: true
		});

		this.tab = 'filters';
	}

	open() {
		super.open();

		this.loading = true;

		// if (!ModalSelectPropertiesComponent.initial_properties) {
			this.searchProperties('')
				.subscribe(data => {
					ModalSelectPropertiesComponent.initial_properties = data.results;
					ModalSelectPropertiesComponent.initial_properties_count = data.count;
					this.properties = ModalSelectPropertiesComponent.initial_properties;
					this.properties_count = ModalSelectPropertiesComponent.initial_properties_count;
					this.offset = this.limit;
					this.loading = false;
				});
		// } else {
		// 	this.properties = ModalSelectPropertiesComponent.initial_properties;
		// 	this.properties_count = ModalSelectPropertiesComponent.initial_properties_count;
		// 	this.offset = this.limit;
		// 	this.loading = false;
		// }

		this.router.navigate([], {
			fragment: 'properties-search-modal',
			preserveQueryParams: true
		});
	}

	close() {
		super.close();

		this.selectProperty = 0;
		this.router.navigate([], {
			fragment: undefined,
			preserveQueryParams: true
		});
	}

	/**
	 * Evento change no input
	 * @param $event
	 */
	changeLocationInput($event) {
		this.loading = true;
		let text = $event.target.value;
		this.searchTextChanged.next(text);
	}

	changeNumber(name, number) {
		if ((<FormArray>this.filters.get(name)).value === number) {
			(<FormArray>this.filters.get(name))
				.setValue(null);
		} else {
			(<FormArray>this.filters.get(name))
				.setValue(number);
		}

		this.updateParams();
	}

	clearParams() {
		this.filters.reset();
		this.updateParams();
	}

	/**
	 * Cancelar filtros
	 */
	cancelFilters() {
	}

	/**
	 * Faz o tratamento dos filtros antes da busca
	 * @returns {any}
	 */
	getFilters() {
		let filters = { ...this.filters.value };

		filters.type_in = filters.types
			.map((v, i) => v ? this.types[i].id : null)
			.filter(v => v !== null);

		if (filters.location) {
			filters.location = filters.location
				.map((v, i) => {
					return v.neighborhood ?
						v.estate + ',' + v.city + ',' + v.neighborhood :
						v.estate + ',' + v.city;
				})
				.map((v, i) => Helpers.normalizeString(v).replace(/ /g, '-'))
				.join('_');
		}

		if (filters.price_gte) {
			filters.sort = 'price';
		}

		if (filters.price_lte) {
			filters.sort = '-price';
		}

		// Para não ficar url imenso
		// filters.users = undefined;
		filters.types = undefined;

		return filters;
	}

	updateParams() {

		this.tab = 'properties';
		// if (newSearch) {
		// 	this.filters.controls.offset.setValue(undefined);
		// 	this.currentPage = 1;
		// }

		if (JSON.stringify(this.propertiesService.processFilters(this.route.snapshot.queryParams)) === JSON.stringify(Helpers.filterArray(this.getFilters()))) {
			return false;
		}

		this.properties = [];
		this.loading = true;
		this.offset = 0;

		return this.propertiesService.search({
			limit: this.limit,
			offset: this.offset,
			...Helpers.filterArray(this.getFilters())
		})
			.subscribe(data => {
				this.properties = data.results;
				this.properties_count = data.count;
				this.offset += this.limit;
				this.loadingMore = false;
				this.loading = false;
			});
	}

	applyFilter() {
		this.updateParams();
	}

}
