
<div class="page-flex">

	<div class="flex-header">
		<app-nav-bar [title]="'Meu Perfil'">
		</app-nav-bar>
	</div>

	<div class="flex-body">

		<div class="max-width">
			<form [formGroup]="form">

				<div class="card p-4">
					<div class="row">
						<div class="col-md-2 text-center">
							<div class="img-circle img-90 m-auto" *ngIf="user.image">
								<img [src]="user.image">

								<div class="loading">
									<div class="loading-icon" *ngIf="loadingProfile">
										<div class="load">
											<div></div>
										</div>
									</div>
								</div>
							</div>

							<div class="img-circle img-90 m-auto" *ngIf="!user.image">
								<app-initials [name]="user.name" [size]="'size-90'" [clean]="true"></app-initials>

								<div class="loading">
									<div class="loading-icon" *ngIf="loadingProfile">
										<div class="load">
											<div></div>
										</div>
									</div>
								</div>
							</div>

							<br>

							<div class="btn btn-round btn-primary position-relative" ngFileDrop [options]="optionsUpload"
								(uploadOutput)="onUploadOutput($event)">
								<input type="file" ngFileSelect [options]="optionsUpload"
									(uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
								<i class="fas fa-camera"></i> Mudar
							</div>
						</div>
						<br>
						<div class="col-md-10">

							<div class="title-form">
								Configurações da conta
							</div>

							<div class="row">
								<div class="col-md-4">
									<div class="field-container" [class.error-input]="isFieldValid('name')">
										<input type="text" formControlName="name">
										<div class="name">Nome</div>
									</div>

									<app-field-error-display [displayError]="isFieldValid('name')"
										errorMsg="Este campo é obrigatório!">
									</app-field-error-display>
								</div>

								<div class="col-md-4">
									<div class="field-container" [class.error-input]="isFieldValid('email')">
										<input type="text" formControlName="email">
										<div class="name">EMAIL</div>

										<app-field-error-display
											[displayError]="isFieldValid('email') && !isEmailInvalid('email')"
											errorMsg="Este campo é obrigatório!">
										</app-field-error-display>

										<app-field-error-display [displayError]="isEmailInvalid('email')"
											errorMsg="Digite um email válido!">
										</app-field-error-display>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-md-4">
									<div class="field-container" [class.error-input]="isFieldValid('cpf')">
										<input type="text" formControlName="cpf">
										<div class="name">CPF</div>
									</div>
								</div>

								<div class="col-md-4">
									<div class="field-container" [class.error-input]="isFieldValid('creci')">
										<input type="text" formControlName="creci">
										<div class="name">Nº. CRECI</div>
									</div>
								</div>
							</div>

							<div class="row" formArrayName="phones">
								<div class="col-md-4" *ngFor="let phone of form.get('phones')['controls']; let i = index">
									<div class="form-container-array" [formGroupName]="i">
										<div class="field-container">
											<input type="text" formControlName="number" appMaskPhone>
											<div class="name">Telefone {{i +1}}</div>
										</div>
									</div>
								</div>
							</div>

							<hr>

							<div class="title-form">
								Mudar senha
							</div>


							<div class="row">
								<div class="col-md-4">
									<div class="field-container" [class.error-input]="isFieldValid('current_password')">
										<input type="text" formControlName="new_password">
										<div class="name">Nova senha</div>
									</div>
								</div>

								<!--<div class="col-md-4">-->
									<!--<div class="field-container" [class.error-input]="isFieldValid('new_password')">-->
										<!--<input type="text" formControlName="new_password">-->
										<!--<div class="name">Nova Senha</div>-->
									<!--</div>-->
								<!--</div>-->

								<!--<div class="col-md-4">-->
									<!--<div class="field-container" [class.error-input]="isFieldValid('confirm_password')">-->
										<!--<input type="text" formControlName="confirm_password">-->
										<!--<div class="name">Repita a Nova Senha</div>-->
									<!--</div>-->
								<!--</div>-->
							</div>

							<div class="row">
								<div class="col-md-12">
									<button class="btn btn-dark" (click)="changePassword()" type="button">
										Mudar Senha
									</button>
								</div>
							</div>

						</div>
					</div>
				</div>
			</form>
		</div>
	</div>


	<div class="flex-footer">
		<div class="actions-bottom">
			<!--<button class="btn btn-light" (click)="save()" type="button" *ngIf="">-->
			<!--Salvar e voltar-->
			<!--</button>-->
			<button class="btn btn-primary" (click)="save()" type="button">
				Salvar
			</button>
		</div>
	</div>
</div>
