import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {TasksService} from '../models/tasks.service';
import {Injectable} from '@angular/core';
import {TicketsService} from '../models/tickets.service';
import {ContactsService} from '../models/contacts.service';
import {RealEstateService} from '../models/real-estate.service';

@Injectable()
export class RealEstateGetService implements Resolve<any> {

    constructor(
        private realEstateService: RealEstateService
    ) {

    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.realEstateService.getMe();
    }
}
