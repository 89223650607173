<div class="modal-screen calendar-day change-mobile-page">
	<div class="modal-body d-flex">
		<div class="header-search-property">
			<div class="i-close" [routerLink]="['/calendar']">
				<div class="icon-close-only black s32x32 display-close"></div>
				<img src="assets/svg/home.svg" class="display-back" />
			</div>

			<div class="title">
				{{ currentDateString }}
			</div>
		</div>

		<div
			*ngIf="commitments.length && !searching"
			class="search-list-container position-relative"
			infiniteScroll
			[infiniteScrollDistance]="250"
			[infiniteScrollThrottle]="50"
			[scrollWindow]="false"
		>
			<div
				class="event table {{ commitment.type }} event-{{
					commitment.events_status
				}}"
				*ngFor="let commitment of commitments; trackBy: trackByFn"
			>
				<div
					class="td td-min align-top position-relative event-timeline"
				>
					<div
						class="event-circle-status {{ commitment.all_day }}"
					></div>

					<div class="line"></div>

					<!--<div class="event-icon">-->
					<!--<i class="fas fa-map-marker-alt" *ngIf="commitment.type == 'visit'"></i>-->
					<!--<i class="fas fa-users" *ngIf="commitment.type == 'meeting'"></i>-->
					<!--<i class="far fa-envelope-open" *ngIf="commitment.type == 'email'"></i>-->
					<!--<i class="fas fa-phone" *ngIf="commitment.type == 'call'"></i>-->
					<!--<i class="far fa-bookmark" *ngIf="commitment.type == 'after_sales'"></i>-->
					<!--<i class="fas fa-tasks" *ngIf="commitment.type == 'task'"></i>-->
					<!--<i class="fas fa-ticket-alt" *ngIf="commitment.type == 'ticket'"></i>-->
					<!--</div>-->
				</div>

				<div class="td align-top">
					<div class="hour" *ngIf="!commitment.all_day">
						{{ commitment.start_at | amDateFormat: "HH:mm" }}
					</div>

					<div class="hour" *ngIf="commitment.all_day">
						Dia Todo
					</div>

					<div class="commitment-name">
						<div class="event-type">
							<i
								class="fas fa-map-marker-alt"
								*ngIf="commitment.type == 'visit'"
							></i>
							<i
								class="fas fa-users"
								*ngIf="commitment.type == 'meeting'"
							></i>
							<i
								class="far fa-envelope-open"
								*ngIf="commitment.type == 'email'"
							></i>
							<i
								class="fas fa-phone"
								*ngIf="commitment.type == 'call'"
							></i>
							<i
								class="far fa-bookmark"
								*ngIf="commitment.type == 'after_sales'"
							></i>
							<i
								class="fas fa-tasks"
								*ngIf="commitment.type == 'task'"
							></i>
							<i
								class="fas fa-ticket-alt"
								*ngIf="commitment.type == 'ticket'"
							></i>
							Visita
						</div>

						{{ commitment.name }}
					</div>

					<div class="commitment-link">
						<a
							*ngIf="!!commitment.ticket_id"
							[routerLink]="[
								'/partnership/conversation',
								commitment.ticket_id
							]"
							routerLinkActive="router-link-active"
						>
							Abrir Ticket
						</a>
					</div>

					<div class="commitment-description">
						{{ commitment.description }}
					</div>

					<div
						class="commitment-contact"
						*ngIf="commitment.contact_id"
						[routerLink]="['/contacts/view', commitment.contact_id]"
					>
						<b>Contato associado:</b> &nbsp;&nbsp;
						<app-initials
							[name]="commitment.contact_name"
							size="small"
						></app-initials>
						{{ commitment.contact_name }}
					</div>

					<app-user-img
						[user]="user"
						[size]="'small'"
						*ngFor="let user of commitment.shared_users_data"
					>
					</app-user-img>

					<div class="event-action">
						<label
							class="checked realized"
							*ngIf="commitment.realized"
							(click)="setRealized(commitment.id, false)"
						>
							<i class="fas fa-check"></i> Realizado
						</label>

						<label
							class="checked"
							*ngIf="!commitment.realized"
							(click)="setRealized(commitment.id, true)"
						>
							<i class="fas fa-check"></i> Não realizado
						</label>

						<div
							class="action-icon"
							[routerLink]="['/calendar/edit', commitment.id]"
						>
							<i class="fas fa-pencil-alt"></i>
						</div>

						<div
							class="action-icon"
							(click)="delete(commitment.id)"
						>
							<i class="far fa-trash-alt"></i>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="not-events" *ngIf="!commitments.length && !searching">
			<div class="day">
				<img src="assets/images/calendar-day.png" alt="" />
				<span>{{ day }}</span>
			</div>

			<h2>Não existe compromissos para este dia.</h2>

			<small>Clique no botão para criar um novo compromisso</small>
		</div>

		<div class="loading-icon-ring big" *ngIf="searching"></div>

		<div class="calendar-action">
			<div class="d-flex">
				<div class="flex-grow-1">
					<div
						class="btn btn-create"
						[routerLink]="['/calendar/add']"
						[queryParams]="{ day: currentDayString }"
					>
						CRIAR UM EVENTO
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
