import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SocketIOResponseService} from '../shareds/SocketIO-response.service';
import {Global} from '../../services/global';
import {WebSocket} from '../../services/WebSocket';

@Injectable({
	providedIn: 'root'
})
export class PartnersChatService {

	constructor(
		private socketIOResponseService: SocketIOResponseService
	) {

	}

	/**
	 * Criar Post
	 * @param {number} ticket_id
	 * @param body
	 * @returns {Observable<any>}
	 */
	createPost(ticket_id: number, body: any): Observable<any> {
		let data = {
			type: 'request',
			path: 'tickets/' + ticket_id + '/posts',
			body: body
		};

		return this.socketIOResponseService.response(data);
	}

	/**
	 * Criar Post com anexo
	 * @param {number} ticket_id
	 * @param body
	 * @returns {Observable<any>}
	 */
	createPostAttachments(ticket_id: number, body: any): Observable<any> {



		let data = {
			type: 'request',
			method: 'POST',
			path: 'tickets/' + ticket_id + '/attachments',
			body: body
		};

		return this.socketIOResponseService.response(data);
	}

	/**
	 * Criar conversa
	 * @param body
	 * @returns {Observable<any>}
	 */
	create(body: any): Observable<any> {

		let data = {
			type: 'request',
			path: 'tickets',
			body: body
		};

		return this.socketIOResponseService.response(data);
	}

	/**
	 * Atualizar Conversa
	 * @param {number} ticket_id
	 * @param body
	 * @returns {Observable<any>}
	 */
	update(ticket_id: number, body: any): Observable<any> {

		let data = {
			type: 'request',
			method: 'POST',
			path: 'tickets/' + ticket_id,
			body: body
		};

		return this.socketIOResponseService.response(data);
	}

	/**
	 * Atualizar Conversa
	 * @param {number} ticket_id
	 * @param body
	 */
	typing(ticket_id: number) {

		let data = {
			type: 'event',
			event: 'typing',
			body: {
				ticket_id: ticket_id
			}
		};

		WebSocket.sendMessage(data);
	}
}
