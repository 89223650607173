<div class="task-edit">
    <div class="task-edit--inside">

        <div class="header-title">
            <div class="title-checkbox">
                <label class="checkbox-container">
                    <input type="checkbox"
                           [(ngModel)]="task.realized"
                           [checked]="task.realized"
                           (change)="updateTask({realized: task.realized})">
                    <div class="checkbox quad"></div>
                </label>
            </div>
            <div class="title">
                <div class="text-view">
                    <!--{{task.name}}-->
                    <form (submit)="updateTask({name: task.name})">
                        <app-textarea-auto-sizing
                            (change)="updateTask({name: task.name})"
                            maxlength="200"
                            [enterSubmit]="true"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="task.name">
                        </app-textarea-auto-sizing>
                    </form>
                </div>
            </div>

            <!-- Favorito -->
            <i class="far fa-star favorite"
               (click)="setFavorite(!task.favorite)"
               *ngIf="!task.favorite"></i>
            <i class="fas fa-star favorite favorited"
               (click)="setFavorite(!task.favorite)"
               *ngIf="task.favorite"></i>
            <!--// Favorito -->
        </div>

        <div class="task-body scroll-height" [subtractHeight]="94" appCompleteHeight #taskbody>

            <div class="input">
                <div class="field-container pr-sm-0">
                    <app-input-users
                        (change)="updateTask({assign_to: task.assign_to})"
                        [user]="task.assign_user"
                        [(value)]="task.assign_to">
                    </app-input-users>

                    <div class="name">
                        <i class="fas fa-user-plus"></i>
                        Atribuir a
                    </div>
                </div>
            </div>

            <hr>

            <div class="input">
                <div class="field-container pr-sm-0">
                    <app-input-datepicker
                        (changeDate)="updateTask({deadline_at: task.deadline_at})"
                        [(ngModel)]="task.deadline_at">
                    </app-input-datepicker>
                    <div class="name">
                        <i class="far fa-calendar-alt"></i>
                        Definir data limite em
                    </div>
                </div>
            </div>

            <hr>

            <div class="input">
                <div class="field-container pr-sm-0">
                    <app-input-datepicker
                        (changeDate)="updateTask({remember_me_at: task.remember_me_at})"
                        [(ngModel)]="task.remember_me_at">
                    </app-input-datepicker>
                    <div class="name">
                        <i class="far fa-clock"></i>
                        Lembrar em
                    </div>
                </div>
            </div>

            <hr>

            <div class="subtasks"
                 [sortablejsOptions]="options"
                 [sortablejs]="task.sub_tasks">

                <div class="subtask"
                     [class.realized]="subtask.realized"
                     *ngFor="let subtask of task.sub_tasks">
                    <div class="table">
                        <div class="td subtask-checkbox">
                            <label class="checkbox-container">
                                <input type="checkbox"
                                       [checked]="subtask.realized"
                                       (change)="subTaskCheck(subtask.id, !subtask.realized)">
                                <div class="checkbox quad"></div>
                            </label>
                        </div>
                        <div class="td subtask-name">
                            {{subtask.name}}
                        </div>
                        <div class="td align-top">
                            <i class="fas fa-times trash-subtask"
                               (click)="deleteSubTask(subtask.id)"
                            ></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="input">
                <div class="field-container pr-sm-0">
                    <form (submit)="addSubTask(subtaskText)">
                        <app-textarea-auto-sizing
                            maxlength="200"
                            [enterSubmit]="true"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="subtaskText">
                        </app-textarea-auto-sizing>
                    </form>
                    <div class="name">
                        <i class="fas fa-plus text-green"></i>
                        Adicionar Subtarefa
                    </div>
                </div>
            </div>

            <div class="input" id="note">
                <div class="field-container pr-sm-0">
                    <app-textarea-auto-sizing
                        #note
                        (change)="updateTask({comments: task.comments})"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="task.comments"
                        (resizeEvent)="scrollEndNote(endComments, note.textarea)">
                    </app-textarea-auto-sizing>
                    <div class="name">
                        <i class="far fa-sticky-note"></i>
                        Nota
                    </div>
                </div>
            </div>

            <span #endComments></span>

            <hr>

            <div class="comments" *ngIf="task.group_comments.length" #comments>
                <h6 class="text-center">Comentários</h6>

                <!-- Comment -->
                <div class="comment table" *ngFor="let comment of task.group_comments">
                    <div class="img td td-min align-top">
                        <app-user-img [user]="comment.user" [size]="'normal'"></app-user-img>
                    </div>
                    <div class="comment-content td p-l-10 align-top">
                        <div class="username">
                            <span class="text-primary">
                                {{comment.user.name}}
                            </span>

                            <span class="time">
                                <i class="far fa-clock"></i>
                                <app-string-time [date]="comment.created_at">
                                </app-string-time>

                            </span>
                        </div>
                        <div class="comment-message">
                            {{comment.message}}<br>
                        </div>
                    </div>
                </div>
                <!--// Comment -->
            </div>
        </div>

        <div class="add-comment" >
            <!-- Comentário -->
            <div class="input">
                <div class="field-container no-text pr-sm-0">
                    <form (submit)="addComment(commentText)">
                        <input type="text" [(ngModel)]="commentText"
                               maxlength="10000"
                               [ngModelOptions]="{standalone: true}"
                               placeholder="Adicionar Comentário...">
                    </form>
                </div>
            </div>
            <!--// Comentário -->

            <div class="task-info table">
                <div class="td td-min">
                    <div class="back">
                        <i class="fas fa-arrow-left"></i>
                    </div>
                </div>
                <div class="td td-overflow-text">
                    <span class="create_info">
                        Criado por Sergio Don Sergione em Ter, Out 23
                    </span>
                </div>
                <div class="td td-min">
                    <div class="trash text-danger">
                        <i class="far fa-trash-alt"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
