
<!--<div (swipeleft)="menuShow = false" (swiperight)="menuShow = true" class="menu-swipe">-->

	<app-header [(menuShowVar)]="menuShow"></app-header>
	<!--<app-subheader></app-subheader>-->

	<main class="main-page menu-fast-open" [@routeAnimations]="activatedRoute(o)" [@routeAnimationsBack]="activatedRouteBack(o)" [class.menu-open]="menuShow">
		<router-outlet #o="outlet" (activate)='onActivate()'></router-outlet>
	 </main>

	<router-outlet name="modal">
	</router-outlet>

	<div toastContainer></div>

	<app-initial-settings *ngIf="false">
	</app-initial-settings>


	<div class="update-version" *ngIf="newUpdateRequired">
		<div>
			<img src="assets/images/logo-md.png" class="logo">
			<br><br><br>
			<h2>Atualização Obrigatória!</h2>

			<h5>
				Por favor atualize o aplicativo, esta versão não receberá mais suporte.
			</h5>
			<br><br><br>

			<button class="btn btn-primary bt-lg" (click)="openLinkUpdate()">Clique aqui para atualizar</button>
		</div>
	</div>

<!--</div>-->

	<!--<div class="footer max-width text-center pt-4">-->
		<!--<img src="/src/assets/images/logo-md-footer.png" alt="" height="40px">-->
		<!--<div>© 2017-2018 Banco dos Imóveis® Todos os direitos reservados.</div>-->
	<!--</div>-->
