import { NavigationEnd, Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var RouterHistoric = /** @class */ (function () {
    function RouterHistoric(router) {
        this.router = router;
        this.router.events
            .subscribe(function (e) {
            if (e instanceof NavigationEnd) {
                RouterHistoric.previousUrl = RouterHistoric.currentUrl;
                RouterHistoric.currentUrl = e.url;
                console.log(RouterHistoric.previousUrl);
            }
        });
    }
    RouterHistoric.urlContain = function (contain) {
        return RouterHistoric.previousUrl.indexOf(contain) !== -1;
    };
    RouterHistoric.lastUrl = function () {
        return RouterHistoric.previousUrl;
    };
    RouterHistoric.currentUrl = '/';
    RouterHistoric.previousUrl = '/';
    RouterHistoric.ngInjectableDef = i0.defineInjectable({ factory: function RouterHistoric_Factory() { return new RouterHistoric(i0.inject(i1.Router)); }, token: RouterHistoric, providedIn: "root" });
    return RouterHistoric;
}());
export { RouterHistoric };
