import { BehaviorSubject } from 'rxjs';
import { Global } from '../../services/global';
import * as i0 from "@angular/core";
var GlobalService = /** @class */ (function () {
    function GlobalService() {
        this.userSource = new BehaviorSubject(false);
        this.user = this.userSource.asObservable();
        this.badgesSource = new BehaviorSubject(false);
        this.badges = this.badgesSource.asObservable();
        this.loadingScreenSource = new BehaviorSubject(true);
        this.loadingScreen = this.loadingScreenSource.asObservable();
        this.menuMobileSource = new BehaviorSubject(false);
        this.menuMobile = this.menuMobileSource.asObservable();
        this.menuFastMobileSource = new BehaviorSubject(false);
        this.menuFastMobile = this.menuFastMobileSource.asObservable();
    }
    GlobalService.prototype.updateUser = function (user) {
        Global.user = user;
        this.userSource.next(user);
    };
    GlobalService.prototype.updateBadge = function (badges) {
        Global.badges = badges;
        this.badgesSource.next(badges);
    };
    GlobalService.prototype.updateLoadingScreen = function (loadingScreen) {
        console.log(loadingScreen);
        this.loadingScreenSource.next(loadingScreen);
    };
    GlobalService.prototype.updateMenuMobile = function (menuMobile) {
        this.menuMobileSource.next(menuMobile);
    };
    GlobalService.prototype.updateFastMenuMobile = function (menuFastMobile) {
        this.menuFastMobileSource.next(menuFastMobile);
    };
    GlobalService.ngInjectableDef = i0.defineInjectable({ factory: function GlobalService_Factory() { return new GlobalService(); }, token: GlobalService, providedIn: "root" });
    return GlobalService;
}());
export { GlobalService };
