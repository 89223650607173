import { ClientsDemandService } from './../models/clients-demand.service';
import { ClientesSharedService } from '../models/clientes-shared.service';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { TasksService } from '../models/tasks.service';
import { Injectable } from '@angular/core';
import { TicketsService } from '../models/tickets.service';
import { ContactsService } from '../models/contacts.service';

@Injectable()
export class PartnerClientDemandResolverService implements Resolve<any> {

	constructor(
		private contactsService: ClientsDemandService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {

		let clients_demand_id = route.queryParams.clients_demand_id;

		if (clients_demand_id) {

			return this.contactsService.get(clients_demand_id);

		}

		return false;
	}
}
