<div class="page-login">
	<!-- Login -->
	<div class="login-screen">
		<img src="assets/images/logo-md.png" class="logo" />

		<div class="container-hide" [@minLogin]="loading">
			<div class="alert alert-danger" *ngIf="!!error">
				{{ error }}
			</div>

			<form [formGroup]="form" (submit)="submit()">
				<div class="login">
					<input
						type="text"
						placeholder="Login ou email"
						formControlName="login"
						required="true"
					/>
				</div>

				<div class="password">
					<input
						type="password"
						placeholder="Senha"
						formControlName="password"
					/>
				</div>

				<button class="btn">
					Entrar no Painel
				</button>
			</form>

			<div class="forget-password">
				<a [routerLink]="'/register'">
					Não possui uma conta? Clique aqui para criar uma
				</a>
			</div>

			<a
				href="https://api.whatsapp.com/send?phone=5511977477000"
				class="hide-mobile"
				target="_system"
			>
				<button class="btn btn-whats">
					<span class="whats-icon"
						><i class="fab fa-whatsapp"></i
					></span>
					Entrar em contato pelo WhatsApp
				</button>
			</a>

			<a
				href="whatsapp://send?phone=5511977477000"
				class="show-mobile"
				target="_system"
			>
				<button class="btn btn-whats">
					<span class="whats-icon"
						><i class="fab fa-whatsapp"></i
					></span>
					Entrar em contato pelo WhatsApp
				</button>
			</a>

			<!--<div class="forget-password">-->
			<!--<a ui-sref="login.forgetpassword">-->
			<!--Esqueceu a senha?-->
			<!--</a>-->
			<!--</div>-->

			Copyright © 2017, Banco dos Imoveis Inc. - Banco dos Imoveis. Todos
			os direitos reservados.
		</div>

		<div class="_loading-icon" [@displayLoading]="loading">
			<div class="_load">
				<div></div>
				<div></div>
			</div>
		</div>
	</div>
	<!--// Login -->

	<!-- Esqueci a senha -->
	<!--<div class="login-screen" *ngIf="">-->
	<!--<img src="/src/assets/images/logo-md.png" class="logo">-->

	<!--<div class="email-forget-password">-->
	<!--Insira o seu email ou login no campo. Você receberá um email com o link para pode redefir sua senha.-->
	<!--<input type="text" placeholder="Login ou email">-->
	<!--</div>-->

	<!--<button class="btn">-->
	<!--Entrar no Painel-->
	<!--</button>-->

	<!--<div class="back">-->
	<!--<a ui-sref="login">-->
	<!--<i class="fas fa-arrow-left"></i> Voltar para Login-->
	<!--</a>-->
	<!--</div>-->
	<!--</div>-->
	<!--// Esqueci a senha -->
</div>
