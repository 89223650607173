import { AutomaticMessageService } from './../models/automatic-message.service';
import * as i0 from "@angular/core";
import * as i1 from "../models/automatic-message.service";
var AutomaticMessageResolverService = /** @class */ (function () {
    function AutomaticMessageResolverService(automaticMessageService) {
        this.automaticMessageService = automaticMessageService;
    }
    AutomaticMessageResolverService.prototype.resolve = function (route) {
        var automatic_id = route.queryParams.automatic_id;
        if (automatic_id) {
            return this.automaticMessageService.get(automatic_id);
        }
        return false;
    };
    AutomaticMessageResolverService.ngInjectableDef = i0.defineInjectable({ factory: function AutomaticMessageResolverService_Factory() { return new AutomaticMessageResolverService(i0.inject(i1.AutomaticMessageService)); }, token: AutomaticMessageResolverService, providedIn: "root" });
    return AutomaticMessageResolverService;
}());
export { AutomaticMessageResolverService };
