var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit, } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PlaySound } from '../../../../services/PlaySound';
import { ToastrService } from 'ngx-toastr';
import { TaskListSharedService } from 'src/app/content/shareds/task-list-shared.service';
import { TasksListService } from 'src/app/content/models/tasks-list.service';
import { Location } from '@angular/common';
var TaskListAddComponent = /** @class */ (function () {
    function TaskListAddComponent(route, _fb, router, location, toastr, _taskListService, _taskListShare) {
        this.route = route;
        this._fb = _fb;
        this.router = router;
        this.location = location;
        this.toastr = toastr;
        this._taskListService = _taskListService;
        this._taskListShare = _taskListShare;
    }
    TaskListAddComponent.prototype.ngOnInit = function () {
        this.buildForm();
    };
    /**
     * Verifica se campo é valido
     * @param {string} field
     * @returns {boolean}
     */
    TaskListAddComponent.prototype.isFieldValid = function (field, parent) {
        return (!this.form.get(field).valid && this.formSubmitAttempt);
    };
    TaskListAddComponent.prototype.buildForm = function () {
        this.form = this._fb.group({
            name: ['', Validators.required],
            shared_users: [[]]
        });
    };
    TaskListAddComponent.prototype.close = function () {
        this.router.navigate(['tasks', { outlets: { modal: null } }]);
    };
    TaskListAddComponent.prototype.save = function () {
        var _this = this;
        this.formSubmitAttempt = false;
        /**
         * Formulário com erros
         */
        if (!this.form.valid) {
            this.formSubmitAttempt = true;
            PlaySound.play('error');
            this.toastr.error('Preencha os campos corretamente.');
            return false;
        }
        var data = __assign({}, this.form.value);
        return this._taskListService
            .create(data).subscribe(function (res) {
            _this.toastr.success('Lista adicionada com sucesso!');
            _this.close();
            _this._taskListShare.refresh();
        }, function (err) {
            _this.toastr.error('Erro ao criar lista.');
        });
    };
    return TaskListAddComponent;
}());
export { TaskListAddComponent };
