/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-img.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../initials/initials.component.ngfactory";
import * as i3 from "../initials/initials.component";
import * as i4 from "@angular/common";
import * as i5 from "./user-img.component";
var styles_UserImgComponent = [i0.styles];
var RenderType_UserImgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserImgComponent, data: {} });
export { RenderType_UserImgComponent as RenderType_UserImgComponent };
function View_UserImgComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-initials", [], null, null, null, i2.View_InitialsComponent_0, i2.RenderType_InitialsComponent)), i1.ɵdid(1, 638976, null, 0, i3.InitialsComponent, [], { clean: [0, "clean"], name: [1, "name"], size: [2, "size"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.name; var currVal_2 = _co.size; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_UserImgComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "circle-avatar border"]], [[2, "big", null], [2, "normal", null], [2, "small", null], [2, "xs", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.size == "big"); var currVal_1 = (_co.size == "normal"); var currVal_2 = (_co.size == "small"); var currVal_3 = (_co.size == "xss"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.image; _ck(_v, 1, 0, currVal_4); }); }
export function View_UserImgComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserImgComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserImgComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.image; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.image; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_UserImgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-img", [], null, null, null, View_UserImgComponent_0, RenderType_UserImgComponent)), i1.ɵdid(1, 638976, null, 0, i5.UserImgComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserImgComponentNgFactory = i1.ɵccf("app-user-img", i5.UserImgComponent, View_UserImgComponent_Host_0, { user: "user", size: "size" }, {}, []);
export { UserImgComponentNgFactory as UserImgComponentNgFactory };
