import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Global } from './global';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { ResponseTreatment } from './response-treatment';
import { WebSocket } from './WebSocket';
import { MessagingService } from './messaging.service';
import { GlobalService } from '../content/shareds/global.service';
import { IndexedDB } from './IndexedDB';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i3 from "@angular/router";
import * as i4 from "../content/shareds/global.service";
import * as i5 from "./messaging.service";
var AuthService = /** @class */ (function () {
    function AuthService(http, cookieService, _router, _globalService, messagingService) {
        this.http = http;
        this.cookieService = cookieService;
        this._router = _router;
        this._globalService = _globalService;
        this.messagingService = messagingService;
        this.responseTreatment = new ResponseTreatment;
    }
    AuthService.prototype.checkUserLogged = function () {
        var _this = this;
        return this.http
            .get(environment.API + '/admin/initial')
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }));
    };
    AuthService.prototype.setUser = function (user) {
        if (Global.userLogged) {
            return true;
        }
        Global.user = user;
        Global.userLogged = true;
        this._globalService.updateUser(Global.user);
        if (user.token) {
            Global.token = user.token;
            this.cookieService.set('_UID_', Global.token, 365, '/');
        }
        else {
            Global.token = user.token;
        }
        this.messagingService.requestPermission();
        this.messagingService.receiveMessage();
        WebSocket.connect({
            token: Global.token
        });
    };
    AuthService.prototype.logout = function () {
        WebSocket.socket.disconnect();
        Global.user = null;
        Global.userLogged = false;
        this.cookieService.delete('_UID_', '/');
        this._globalService.updateUser(null);
        this._router.navigate(['/login']);
        // Deletar banco de dados local
        new IndexedDB('').deleteDatabase();
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.CookieService), i0.inject(i3.Router), i0.inject(i4.GlobalService), i0.inject(i5.MessagingService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
