import {
	AfterViewChecked,
	ChangeDetectorRef,
	Directive,
	ElementRef,
	HostBinding,
	HostListener,
	Input,
	OnChanges, OnDestroy,
	OnInit
} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {CompleteHeightService} from '../../shareds/complete-height.service';
import {skip} from 'rxjs/operators';

@Directive({
	selector: '[appCompleteHeight]',
})
export class CompleteHeightDirective implements OnInit, OnDestroy, OnChanges, AfterViewChecked {

	@HostBinding('style.height')
	height = '';

	@HostBinding('style.maxHeight')
	maxHeight = '';

	@Input() appCompleteHeight: any;
	@Input() max = false;
	@Input() subtractHeight = 0;

	initialViewCheck = false;

	top: number;

	sub: any;

	constructor(
		private el: ElementRef,
		private router: Router,
		private changeDetectorRef: ChangeDetectorRef,
	) {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				// this.resizeElement();
			}
		});
	}

	ngOnInit() {
		this.resizeElement();

		this.sub = CompleteHeightService.updateEvent
			.pipe(skip(1))
			.subscribe(resp => {
				this.resizeElement();
				// this.changeDetectorRef.detectChanges();
			});
	}

	ngOnChanges() {
		// this.resizeElement();
	}

	ngAfterViewChecked() {

		// console.log(this.initialViewCheck);
		//
		// if (!this.initialViewCheck) {
		//     this.initialViewCheck = true;
		//     setTimeout(() => {
		//         this.resizeElement();
		//     }, 1);
		// }
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.resizeElement();
	}

	resizeElement() {

		let height = Math.min(window.outerHeight, window.innerHeight);

		if (this.max) {
			this.maxHeight = (height - this.el.nativeElement.getBoundingClientRect().top - this.subtractHeight) + 'px';
		} else {
			this.height = (height - this.el.nativeElement.getBoundingClientRect().top - this.subtractHeight) + 'px';
		}
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}

}
