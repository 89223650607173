/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input-users.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../user-img/user-img.component.ngfactory";
import * as i4 from "../user-img/user-img.component";
import * as i5 from "@angular/common";
import * as i6 from "../../directives/click-outside/click-outside.directive";
import * as i7 from "./input-users.component";
import * as i8 from "../../models/users.service";
var styles_InputUsersComponent = [i0.styles];
var RenderType_InputUsersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputUsersComponent, data: {} });
export { RenderType_InputUsersComponent as RenderType_InputUsersComponent };
function View_InputUsersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "input", [["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "focus"], [null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("focus" === en)) {
        var pd_4 = (_co.setFocus(true) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.textModel = $event) !== false);
        ad = (pd_5 && ad);
    } if (("input" === en)) {
        var pd_6 = (_co.changeLocationInput($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(3, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.textModel; _ck(_v, 3, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_InputUsersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "td td-min"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-user-img", [], null, null, null, i3.View_UserImgComponent_0, i3.RenderType_UserImgComponent)), i1.ɵdid(4, 638976, null, 0, i4.UserImgComponent, [], { user: [0, "user"], size: [1, "size"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "td td-overflow-text pl-2 text-primary font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "td text-primary font-weight-bold remove"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeSelectUser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedUser; var currVal_1 = "small"; _ck(_v, 4, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.selectedUser.name; _ck(_v, 6, 0, currVal_2); }); }
function View_InputUsersComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "app-users--loading-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-spinner"]], null, null, null, null, null))], null, null); }
function View_InputUsersComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bloqueado - "]))], null, null); }
function View_InputUsersComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "role td-overflow-text"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputUsersComponent_7)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.blocked; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.role_name; _ck(_v, 3, 0, currVal_1); }); }
function View_InputUsersComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "role td-overflow-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.realestate.name; _ck(_v, 1, 0, currVal_0); }); }
function View_InputUsersComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "li", [["class", "user-row"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectUser(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "table"]], [[2, "blocked", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "td td-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-user-img", [], null, null, null, i3.View_UserImgComponent_0, i3.RenderType_UserImgComponent)), i1.ɵdid(4, 638976, null, 0, i4.UserImgComponent, [], { user: [0, "user"], size: [1, "size"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "td font-weight-bold td-overflow-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "username text-primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputUsersComponent_6)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputUsersComponent_8)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit; var currVal_3 = "small"; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_5 = !_co.MASTER; _ck(_v, 10, 0, currVal_5); var currVal_6 = _co.MASTER; _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectNumber == _v.context.index); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.blocked; _ck(_v, 1, 0, currVal_1); var currVal_4 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_4); }); }
function View_InputUsersComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "notfound"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Nenhum usu\u00E1rio encontrado. "]))], null, null); }
function View_InputUsersComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ul", [["class", "autocomplete-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputUsersComponent_5)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputUsersComponent_9)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.users; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.users.length; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_InputUsersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "app-users autocomplete"]], null, [[null, "clickOutside"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("clickOutside" === en)) {
        var pd_1 = (_co.setFocus(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 540672, null, 0, i6.ClickOutsideDirective, [i1.ElementRef], null, { clickOutside: "clickOutside" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputUsersComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputUsersComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputUsersComponent_3)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputUsersComponent_4)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.selectedUser; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.selectedUser; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.loading; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.showAutocomplete; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_InputUsersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-input-users", [], null, [["document", "keydown.arrowup"], ["document", "keydown.arrowdown"], ["document", "keydown.enter"]], function (_v, en, $event) { var ad = true; if (("document:keydown.arrowup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).handleUpEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keydown.arrowdown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).handleDownEvent($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:keydown.enter" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).handleEnterEvent($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_InputUsersComponent_0, RenderType_InputUsersComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.InputUsersComponent]), i1.ɵdid(2, 638976, null, 0, i7.InputUsersComponent, [i8.UsersService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var InputUsersComponentNgFactory = i1.ɵccf("app-input-users", i7.InputUsersComponent, View_InputUsersComponent_Host_0, { allUsers: "allUsers", value: "value", user: "user" }, { valueChange: "valueChange", change: "change" }, []);
export { InputUsersComponentNgFactory as InputUsersComponentNgFactory };
