<div class="page-flex">

	<div class="flex-header">
		<app-nav-bar [title]="'Meta-Tags'">
		</app-nav-bar>
	</div>

	<div class="flex-body">
		<div class="max-width">
			<form [formGroup]="form">

				<div class="card p-4">

					<div class="title-form">
						SEO e Meta tags
					</div>

					<div class="row">
						<div class="col-md-6">
							<div class="field-container" [class.error-input]="isFieldValid('SEO_TITLE')">
								<div>
									<input type="text" formControlName="SEO_TITLE">
								</div>
								<div class="name">Título da Página</div>
							</div>

							<!-- <app-field-error-display [displayError]="isFieldValid('SEO_TITLE')" errorMsg="Este campo é obrigatório!">
							</app-field-error-display> -->

							<div class="field-container" [class.error-input]="isFieldValid('SEO_KEYWORDS')">
								<div>
									<input type="text" formControlName="SEO_KEYWORDS">
								</div>
								<div class="name">Keywords</div>
							</div>

							<!-- <app-field-error-display [displayError]="isFieldValid('SEO_KEYWORDS')" errorMsg="Este campo é obrigatório!">
							</app-field-error-display> -->

							<div class="field-container" [class.error-input]="isFieldValid('SEO_DESCRIPTION')">
								<div>
									<input type="text" formControlName="SEO_DESCRIPTION">
								</div>
								<div class="name">Descrição</div>
							</div>

							<!-- <app-field-error-display [displayError]="isFieldValid('SEO_DESCRIPTION')" errorMsg="Este campo é obrigatório!">
							</app-field-error-display> -->
						</div>

						<div class="col-md-6">
							<div class="google-search-example">
								<div class="title">{{form.controls['SEO_TITLE'].value}}</div>
								<div class="url">https://www.bancodosimoveis.imob/bdi</div>
								<div class="description">{{form.controls['SEO_DESCRIPTION'].value}}
									<!-- {{form.controls['SEO_DESCRIPTION'].value}} -->

								</div>
							</div>
						</div>
					</div>

					<hr>

					<div class="title-form">
						<i class="fab fa-google text-light"></i> Google
					</div>

					<div class="row">
						<div class="col-md-6">
							<div class="field-container">
								<input type="text" formControlName="GOOGLE_ANALYTICS" placeholder="Exemplo: UA-XXXXXXXXXXX">
								<div class="name">Código Google Analytics</div>
							</div>
						</div>

						<div class="col-md-6">
							<div class="field-container">
								<input type="text" formControlName="GOOGLE_SITE_VERIFICATION"
									placeholder="Exemplo: MYIjYGdMjjrEJHYM5vaw1vnsfx3Q00HRdeGvXG2cdTY">
								<div class="name">Código Site Verification</div>
							</div>
						</div>
					</div>

				</div>

				<!--<div class="actions-bottom">-->
					<!--&lt;!&ndash; <button class="btn btn-light" (click)="save(form)" type="button">-->
						<!--Salvar e voltar-->
					<!--</button> &ndash;&gt;-->
					<!--<button class="btn btn-primary" (click)="save()" type="button" id="save">-->
						<!--Salvar-->
					<!--</button>-->
				<!--</div>-->

			</form>
		</div>
	</div>

	<div class="flex-footer">
		<div class="actions-bottom">
			<!--<button class="btn btn-light" (click)="save()" type="button" *ngIf="">-->
			<!--Salvar e voltar-->
			<!--</button>-->
			<button class="btn btn-primary" (click)="save()" type="button">
				Salvar
			</button>
		</div>
	</div>
</div>
