import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { ResponseTreatment } from '../../services/response-treatment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LoginService = /** @class */ (function () {
    function LoginService(http) {
        this.http = http;
        this.responseTreatment = new ResponseTreatment;
    }
    /**
     * Rota de Login
     * @param data
     * @returns {Observable<Object>}
     */
    LoginService.prototype.login = function (data) {
        var _this = this;
        return this.http
            .post(environment.API + '/admin/auth', data)
            .pipe(map(function (res) { return _this.responseTreatment.pipe(res); }));
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.HttpClient)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
