<div class="max-width">

    <div class="title-date">Hoje - 01/11/2018</div>
    <div class="card notification table not-view">
        <div class="image-container td">
            <div class="img-circle">
                <img src="/src/assets/temp/propertie.png">
            </div>
        </div>
        <div class="info-container td">
            <b>Novo imóvel</b> adicionado pelo <b>site</b>.
            <div class="date">
                <i class="far fa-calendar"></i> 6 meses atrás
            </div>
        </div>
    </div>
    <div class="card notification table">
        <div class="image-container td">
            <div class="img-circle">
                <img src="/src/assets/temp/propertie.png">
            </div>
        </div>
        <div class="info-container td">
            <b>Novo imóvel</b> adicionado pelo <b>site</b>.
            <div class="date">
                <i class="far fa-calendar"></i> 6 meses atrás
            </div>
        </div>
    </div>

    <div class="title-date">ONTEM - 25/10/2018</div>
    <div class="card notification table not-view">
        <div class="image-container td">
            <div class="img-circle">
                <img src="/src/assets/temp/propertie.png">
            </div>
        </div>
        <div class="info-container td">
            <b>Novo imóvel</b> adicionado pelo <b>site</b>.
            <div class="date">
                <i class="far fa-calendar"></i> 6 meses atrás
            </div>
        </div>
    </div>
    <div class="card notification table">
        <div class="image-container td">
            <div class="img-circle">
                <img src="/src/assets/temp/propertie.png">
            </div>
        </div>
        <div class="info-container td">
            <b>Novo imóvel</b> adicionado pelo <b>site</b>.
            <div class="date">
                <i class="far fa-calendar"></i> 6 meses atrás
            </div>
        </div>
    </div>
</div>
