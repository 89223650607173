import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
	ActivatedRoute,
	NavigationCancel,
	NavigationEnd,
	NavigationStart,
	Router
} from "@angular/router";
import { skip } from "rxjs/operators";
import { TasksService } from "../../models/tasks.service";
import { TaskSharedService } from "../../shareds/task.service";
import {
	animate,
	keyframes,
	query,
	stagger,
	style,
	transition,
	trigger
} from "@angular/animations";

@Component({
	selector: "app-task-lists",
	templateUrl: "./task-lists.component.html",
	styleUrls: ["./task-lists.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class TaskListsComponent implements OnInit, OnDestroy {
	// Se painel de edição de tarefa esta aberto
	openEdit: boolean;
	// Task selecionada
	selectTask: number;
	// Tarefas
	tasks: Array<any>;
	tasksRealized: Array<any>;

	// Todo
	taskName: string;
	// ID da lista atual
	listID: number;
	// Se está sendo criado
	creating: boolean;
	// loading
	loading: boolean;

	// Subscriter
	subParams: any;
	subRouter: any;

	// Opções do sortable
	options: any;

	constructor(
		private route: ActivatedRoute,
		private http: HttpClient,
		private router: Router,
		private taskService: TasksService,
		private taskObservable: TaskSharedService
	) {}

	ngOnInit() {
		this.tasks = this.route.snapshot.data.tasks.results;
		this.tasksRealized = this.route.snapshot.data.tasksRealized.results;
		this.listID = this.route.snapshot.params["id"];

		// Atualiza listas
		this.subParams = this.route.paramMap.subscribe(params => {
			this.tasks = this.route.snapshot.data.tasks.results;
			this.tasksRealized = this.route.snapshot.data.tasksRealized.results;

			if (this.route.snapshot.params["id"] !== this.listID) {
				this.openEdit = false;
				console.log("asd");
			}

			this.listID = this.route.snapshot.params["id"];
		});

		// Observa Rotas para loading
		this.subRouter = this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.loading = true;
				this.openEdit = false;
			} else if (
				event instanceof NavigationEnd ||
				event instanceof NavigationCancel
			) {
				this.loading = false;

				// Verifica se editarTask deve ser aberto
				if (this.route.firstChild) {
					this.openEdit = true;
				}
			}
		});

		if (this.route.firstChild) {
			if (this.route.firstChild.snapshot.params.task_id) {
				this.selectTask = this.route.firstChild.snapshot.params.task_id;
				this.openEdit = true;
			}
		}

		// Configurações do Sortable
		this.options = {
			group: "tasks",
			onEnd: (event: any) => {
				this.onEndSortable(event);
			}
		};

		/**
		 * Observa tarefa
		 */
		this.taskObservable.currentTask.subscribe(task => {
			if (task) {
				/**
				 * Realizado
				 */
				for (let i in this.tasks) {
					if (this.tasks[i].task_id === task["id"]) {
						if (!task["realized"]) {
							this.tasks[i] = task;
						} else {
							this.tasks.splice(Number(i), 1);
							this.tasksRealized.push(task);
						}
						return true;
					}
				}

				for (let i in this.tasksRealized) {
					if (this.tasksRealized[i].task_id === task["id"]) {
						if (task["realized"]) {
							this.tasksRealized[i] = task;
						} else {
							this.tasksRealized.splice(Number(i), 1);
							this.tasks.push(task);
						}
						break;
					}
				}
			}
		});
	}

	/**
	 * Criar tarefa
	 * @returns {any}
	 */
	createTask() {
		if (this.taskName === "") {
			return false;
		}

		if (this.creating) {
			return false;
		}

		this.creating = true;

		return this.taskService
			.create({
				name: this.taskName,
				getTask: true,
				task_list_id: this.listID
			})
			.subscribe(data => {
				this.taskName = "";
				this.creating = false;
				this.tasks.splice(0, 0, data);
			});
	}

	/**
	 * Realizar Tarefa
	 * @param taskId
	 * @param realized
	 */
	realizeTask(taskID, realized) {
		let task = null;

		if (realized) {
			// Busca em Tarefas não realizas
			for (let i in this.tasks) {
				if (this.tasks[i].task_id === taskID) {
					task = this.tasks[i];
					this.tasks.splice(Number(i), 1);
				}
			}
			task.realized = true;
			this.tasksRealized.push(task);
		} else {
			// Busca em Tarefas realizas
			for (let i in this.tasksRealized) {
				if (this.tasksRealized[i].task_id === taskID) {
					task = this.tasksRealized[i];
					this.tasksRealized.splice(Number(i), 1);
				}
			}
			task.realized = false;
			this.tasks.push(task);
		}

		this.taskService
			.update(taskID, {
				realized: realized ? 1 : 0
			})
			.subscribe(data => {
				console.log(data);
			});
	}

	onEndSortable(event: any): void {
		let realized = event.to.id === "realized" ? 1 : 0;
		let taskId = 0;

		if (event.to.id === "realized") {
			taskId = this.tasksRealized[event.newIndex].id;
		} else {
			taskId = this.tasks[event.newIndex].id;
		}

		this.taskService
			.sortable(taskId, event.newIndex, realized)
			.subscribe(data => {
				console.log(data);
			});
	}

	/**
	 * Favorita ou desfavorita tarefa
	 * @param {number} taskID
	 * @param {number} favorite
	 */
	setFavorite(taskID: number, favorite: number, event) {
		if (event) {
			event.stopImmediatePropagation();
		}

		// Busca em Tarefas não realizas
		for (let i in this.tasks) {
			if (this.tasks[i].task_id === taskID) {
				this.tasks[i].favorite = favorite;

				//
				if (favorite) {
					let task = this.tasks[i];
					this.tasks.splice(Number(i), 1);
					this.tasks.splice(0, 0, task);
					break;
				}
			}
		}

		// Busca em Tarefas realizas
		for (let i in this.tasksRealized) {
			if (this.tasksRealized[i].task_id === taskID) {
				this.tasksRealized[i].favorite = favorite;

				//
				if (favorite) {
					let task = this.tasksRealized[i];
					this.tasksRealized.splice(Number(i), 1);
					this.tasksRealized.splice(0, 0, task);
				}

				break;
			}
		}

		this.taskService
			.update(taskID, {
				favorite: favorite ? 1 : 0
			})
			.subscribe(data => {
				console.log(data);
			});
	}

	/**
	 * Fecha janela de edição de tarefa
	 */
	closeEdit() {
		this.router.navigate(["/tasks/list", this.listID]);

		this.openEdit = false;
	}

	clickTask(taskId) {
		this.selectTask = taskId;

		if (this.openEdit) {
			this.dblclickTask(taskId);
		}
	}

	dblclickTask(taskId) {
		this.router.navigate(["/tasks/list", this.listID, "task", taskId]);

		this.openEdit = true;
	}

	ngOnDestroy() {
		this.subParams.unsubscribe();
		this.subRouter.unsubscribe();
	}
}
