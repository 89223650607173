import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {TasksService} from '../models/tasks.service';

@Injectable()
export class TasksResolver implements Resolve<any> {

    constructor(
        private taskService: TasksService
    ) {

    }

    resolve(route: ActivatedRouteSnapshot) {
        let params = {...route.queryParams};
        params['task_list_id'] = route.params.id;
        return this.taskService.search(this.taskService.processFilters(params));
    }
}
