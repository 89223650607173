/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./relogin.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./relogin.component";
import * as i3 from "../../shareds/global.service";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "../../../services/auth.service";
var styles_ReloginComponent = [i0.styles];
var RenderType_ReloginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReloginComponent, data: {} });
export { RenderType_ReloginComponent as RenderType_ReloginComponent };
export function View_ReloginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "page-login"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "login-screen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "logo"], ["src", "assets/images/logo-md.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Conectando ao servidor..."])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "_loading-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "_load"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_ReloginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-relogin", [], null, null, null, View_ReloginComponent_0, RenderType_ReloginComponent)), i1.ɵdid(1, 114688, null, 0, i2.ReloginComponent, [i3.GlobalService, i4.Router, i4.ActivatedRoute, i5.Location, i6.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReloginComponentNgFactory = i1.ɵccf("app-relogin", i2.ReloginComponent, View_ReloginComponent_Host_0, {}, {}, []);
export { ReloginComponentNgFactory as ReloginComponentNgFactory };
