import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {UsersService} from '../../models/users.service';
import {debounceTime, distinctUntilChanged, map, mergeMap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Helpers} from '../../../services/helpers';
import {MaskPhoneDirective} from '../../directives/mask-phone/mask-phone.directive';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Global} from '../../../services/global';

@Component({
	selector: 'app-input-users',
	templateUrl: './input-users.component.html',
	styleUrls: ['./input-users.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: InputUsersComponent,
		multi: true
	}]
})
export class InputUsersComponent implements OnInit, OnChanges, ControlValueAccessor {

	// ID do usuário (Model)
	_value: any;
	@Input() allUsers = false;
	@Input()
	get value() {
		return this._value;
	}

	set value(value) {
		this._value = value;
	}

	@Output() valueChange = new EventEmitter();

	// Usuário selecionado
	@Input()
	get user() {
		return this._selectedUser;
	}

	set user(user) {
		if (Helpers.empty(user)) {
			this.selectedUser = '';
			this.value = 0;
			this.textModel = '';
		} else {
			this.selectedUser = user;
			this.setFocus(false);
			this.value = this.selectedUser.id;
		}
		this._selectedUser = this.selectedUser;
	}

	_selectedUser: any;

	// Evento Change
	@Output() change = new EventEmitter();

	searchTextChanged = new Subject<string>();
	textModel: string;
	users = [];
	loading = false;

	// Usuário selecionado
	selectedUser: any;

	// Ver Autocomplete
	showAutocomplete = false;

	// Index selecionado
	selectNumber = 0;

	MASTER = 0;

	// Both onChange and onTouched are functions
	onChange: any = () => {
	};
	onTouched: any = () => {
	};

	constructor(
		private usersService: UsersService
	) {

	}

	ngOnInit() {

		this.MASTER = Global.user.user_master_bdi;

		this.searchTextChanged
			.pipe(debounceTime(350))
			.pipe(distinctUntilChanged())
			.pipe(mergeMap(search => this.searchUsers(search)))
			.subscribe(data => {
				this.users = data.results;
				this.loading = false;
			});
	}

	/**
	 * Changes
	 */
	ngOnChanges() {
		if (Helpers.empty(this.value)) {
			this.selectedUser = '';
			this.textModel = '';
		}
	}

	/**
	 * Seleciona usuário
	 * @param user
	 */
	selectUser(user: any) {
		this.selectedUser = user;
		this.setFocus(false);
		this.value = this.selectedUser.id;
		this.updateChange();
	}

	/**
	 * Remover seleção
	 * @param user
	 */
	removeSelectUser() {
		this.selectedUser = '';
		this.textModel = '';
		this.value = 0;
		this.updateChange();
	}

	setFocus(focus) {
		this.showAutocomplete = focus;
	}

	/**
	 * Busca Usuários pelo texto no Input
	 * @param search
	 * @returns {Observable<any>}
	 */
	searchUsers(search) {
		return this.usersService.search({
			all_users: this.allUsers,
			'name': search
		});
	}

	/**
	 * Evento change no input
	 * @param $event
	 */
	changeLocationInput($event) {
		this.loading = true;
		this.showAutocomplete = true;
		let text = $event.target.value;
		this.searchTextChanged.next(text);
	}

	/**
	 * Update Model
	 * @param {KeyboardEvent} event
	 */
	updateChange() {
		this.valueChange.emit(this.value);
		this.change.emit();
		this.onChange(this.value);
	}

	@HostListener('document:keydown.arrowup', ['$event'])
	handleUpEvent(event: KeyboardEvent) {
		if (this.showAutocomplete) {
			this.selectNumber--;
			if (this.selectNumber < 0) {
				this.selectNumber = this.users.length - 1;

				if (this.selectNumber < 0) {
					this.selectNumber = 0;
				}
			}
		}
	}

	@HostListener('document:keydown.arrowdown', ['$event'])
	handleDownEvent(event: KeyboardEvent) {
		if (this.showAutocomplete) {
			this.selectNumber++;
			if (this.selectNumber >= this.users.length) {
				this.selectNumber = 0;
			}
		}
	}

	@HostListener('document:keydown.enter', ['$event'])
	handleEnterEvent(event: KeyboardEvent) {
		if (this.showAutocomplete) {
			this.selectUser(this.users[this.selectNumber]);
		}
	}

	///////////////
	// OVERRIDES //
	///////////////

	/**
	 * Writes a new item to the element.
	 * @param value the value
	 */
	writeValue(value: number): void {
		this.value = value;
		this.updateChange();
	}

	/**
	 * Registers a callback function that should be called when the control's value changes in the UI.
	 * @param fn
	 */
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	/**
	 * Registers a callback function that should be called when the control receives a blur event.
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

}
