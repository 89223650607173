import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {debounceTime, distinctUntilChanged, filter, mergeMap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {PropertiesService} from '../../models/properties.service';
import {ContactsService} from '../../models/contacts.service';

@Directive({
    selector: '[appContactsDuplicateCod]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: ContactsDuplicateCodDirective,
            multi: true
        }
    ]
})
export class ContactsDuplicateCodDirective  implements Validator {

    @Input() currentId: any;

    currentCod = '';
    focus = false;
    searchTextChanged = new Subject<string>();
    codExist = false;

    private _onChange: () => void;

    constructor(
        private elementRef: ElementRef,
        private contactsService: ContactsService
    ) {
        this.searchTextChanged
            .pipe(debounceTime(350))
            .pipe(distinctUntilChanged())
            .pipe(filter(t => !!t))
            .pipe(mergeMap(search => this.searchCod(search)))
            .subscribe(data => {
                console.log(this.currentId);
                if (data.results.length) {
                    if (data.results[0].cod.toLowerCase() === this.currentCod.toLowerCase()
                        && Math.floor(this.currentId) !== Math.floor(data.results[0].id)) {
                        this.codExist = true;
                    }
                }
                this._onChange();
            });
    }

    /**
     *
     * @param search
     * @returns {Observable<any>}
     */
    searchCod(search) {
        return this.contactsService.search({
            'cod': search
        });
    }

    @HostListener('keyup', ['$event'])
    onKeyup($event: any) {
        if (this.currentCod !== $event.target.value) {
            this.codExist = false;
            this.currentCod = $event.target.value;
            this.searchTextChanged.next(this.currentCod);
            this._onChange();
        }
    }

    registerOnValidatorChange(fn: () => void): void {
        this._onChange = fn;
    }

    validate(c: AbstractControl): ValidationErrors | null {
        if (this.codExist) {
            return {
                'codExist': true
            };
        } else {
            return null;
        }

    }

}
