import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {PropertiesService} from '../models/properties.service';

@Injectable()
export class PropertiesViewResolver implements Resolve<any> {

	constructor(
		private propertiesService: PropertiesService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {
		return this.propertiesService.get(route.params.id);
	}
}

