import { OnInit, ChangeDetectorRef, OnDestroy, EventEmitter } from '@angular/core';
import { Global } from '../../../services/global';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { NotificationsService } from '../../models/notifications.service';
import { skip, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../shareds/global.service';
import { Subject } from 'rxjs';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(_router, _notificationsService, _globalService, _changeDetectorRef, _authService) {
        var _this = this;
        this._router = _router;
        this._notificationsService = _notificationsService;
        this._globalService = _globalService;
        this._changeDetectorRef = _changeDetectorRef;
        this._authService = _authService;
        this.needComplete = Global.user.realestate.type === 0;
        this.chatPartnersBadged = 0;
        this.chatPropertiesBadged = 0;
        this.chatAgendaBadged = 0;
        this.menuShowVarChange = new EventEmitter();
        this.subMenuOpen = 'all';
        this.menuFastMobile = true;
        this.MASTER = 0;
        this.APP_MOBILE = false;
        // Destruir todos os subscrive
        this.destroy$ = new Subject();
        this.user = Global.user;
        this.MASTER = Global.user.user_master_bdi;
        this.APP_MOBILE = environment.mobile;
        console.log(Global.badges);
        this.chatPartnersBadged = Global.badges.chat_partners;
        this.chatPropertiesBadged = Global.badges.properties;
        this.chatAgendaBadged = Global.badges.agenda;
        this._globalService
            .user
            .pipe(takeUntil(this.destroy$))
            .pipe(skip(1))
            .subscribe(function (user) {
            if (user === null) {
                return;
            }
            _this.user = user;
        });
        this._globalService
            .menuFastMobile
            .pipe(takeUntil(this.destroy$))
            .pipe(skip(1))
            .subscribe(function (menuFastMobile) {
            _this.menuFastMobile = menuFastMobile;
        });
        this._globalService
            .menuMobile
            .pipe(takeUntil(this.destroy$))
            .pipe(skip(1))
            .subscribe(function (menuShow) {
            _this.menuShowVar = menuShow;
        });
        this._globalService
            .badges
            .pipe(takeUntil(this.destroy$))
            .pipe(skip(1))
            .subscribe(function (badges) {
            _this.chatPartnersBadged = Global.badges.chat_partners;
            _this.chatPropertiesBadged = Global.badges.properties;
            _this.chatAgendaBadged = Global.badges.agenda;
        });
    }
    Object.defineProperty(HeaderComponent.prototype, "menuShowVar", {
        get: function () {
            return this.menuShow;
        },
        set: function (val) {
            this.menuShow = val;
            this.menuShowVarChange.emit(this.menuShow);
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user = Global.user;
        this.firstCheckWidth();
        this._router.events.subscribe(function (events) {
            _this.userDropState = false;
            _this.menuShowVar = false;
        });
    };
    HeaderComponent.prototype.onPan = function (evt) {
        console.log("(" + evt.center.x + ", " + evt.center.y + ")");
    };
    HeaderComponent.prototype.openWebsite = function () {
        window.open(Global.user.realestate.website, '_system');
    };
    HeaderComponent.prototype.changeUserDropState = function () {
        this.userDropState = this.userDropState ? false : true;
    };
    HeaderComponent.prototype.changeMenu = function () {
        this.menuShowVar = !this.menuShowVar;
        console.log(this.menuShowVar);
    };
    HeaderComponent.prototype.closeMenu = function () {
        this.menuShowVar = false;
    };
    HeaderComponent.prototype.closeUserMenu = function () {
        this.userDropState = false;
    };
    HeaderComponent.prototype.openSubmenu = function (subMenu, event) {
        if (this.innerWidth < 1023) {
            event.stopPropagation();
            this.subMenuOpen = this.subMenuOpen === subMenu ? 'none' : subMenu;
        }
    };
    HeaderComponent.prototype.firstCheckWidth = function () {
        this.innerWidth = window.innerWidth;
        if (window.innerWidth > 1023) {
            this.subMenuOpen = 'all';
        }
        else {
            this.subMenuOpen = 'none';
        }
    };
    HeaderComponent.prototype.logout = function () {
        this._authService.logout();
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
    };
    HeaderComponent.prototype.onResize = function (event) {
        this.innerWidth = window.innerWidth;
        if (this.innerWidth > 1023) {
            this.subMenuOpen = 'all';
        }
        else {
            this.subMenuOpen = 'none';
        }
    };
    return HeaderComponent;
}());
export { HeaderComponent };
