import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Properties} from '../../interfaces/properties';

@Component({
	selector: 'app-properties-card-simple',
	templateUrl: './properties-card-simple.component.html',
	styleUrls: ['./properties-card-simple.component.scss']
})
export class PropertiesCardSimpleComponent implements OnInit {

	@Input() property: Properties;
	@Input() link = false;

	constructor() {
	}

	ngOnInit() {
	}

}
