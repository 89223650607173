import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	OnChanges,
	OnInit,
	Output,
	ViewChild, ViewEncapsulation
} from '@angular/core';
import {Calendar} from '../../../services/calendar';
import * as moment from 'moment';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Helpers} from '../../../services/helpers';

@Component({
	selector: 'app-input-datepicker',
	templateUrl: './input-datepicker.component.html',
	styleUrls: ['./input-datepicker.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => InputDatepickerComponent),
		multi: true
	}]
})
export class InputDatepickerComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {

	@Input('allDay') _allDay = false;
	@Output() allDayChange = new EventEmitter;

	@Input() theme = 'black';
	// Se deve mostrar o horário
	@Input() noTime: string;
	@Output() changeDate = new EventEmitter;

	// Calendário
	calendar = new Calendar();
	weeks: Array<any>;
	weeks_name: Array<any>;
	_openDatePicker = false;
	emptyCalendar = true;
	allDay = false;

	// Data selecionada
	selectedDate: moment.Moment;
	selectedDateString: string;
	currentDay: number;
	currentDate: string;
	selectDay: number;
	selectMonth: number;
	selectYear: number;
	monthName: string;

	// Data do calendário
	calendarDate: moment.Moment;
	calendarMonth: number;
	calendarYear: number;
	selectedDateStringView = '';
	oldSelectedDateStringView = '';
	hours = '';
	oldHours = '';

	hourModel: string;
	minuteModel: string;

	// Elements
	@ViewChild('hour') elHourRef: ElementRef;
	@ViewChild('minute') elMinuteRef: ElementRef;

	// Both onChange and onTouched are functions
	onChange: any = () => {
	};
	onTouched: any = () => {
	};

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
	) {
		this.selectedDate = moment();
		this.selectedDateString = this.selectedDate.format('DD/M/YYYY');
		this.selectMonth = this.selectedDate.month();
		this.selectYear = this.selectedDate.year();

		this.currentDate = moment().format('DD/M/YYYY');
		this.currentDay = moment().date();

		this.calendarDate = this.selectedDate;

		this.monthName = this.calendar.months_name[this.selectMonth];
		this.calendarYear = this.selectYear;

		this.weeks_name = this.calendar.weeks_name;

		// Resgata Mês atual
		this.weeks = this.calendar.getMonth(this.calendarDate.month(), this.calendarDate.year());
	}

	ngOnInit() {
		this.oldSelectedDateStringView = '';
		this.oldHours = '';
		this.hourModel = '0';
		this.minuteModel = '0';
		this.allDay = this._allDay;

		console.log(this._allDay);
	}

	ngAfterViewInit() {

	}

	ngOnChanges(event) {
		console.log(event);
	}

	changeAllDay() {
		this.allDayChange.emit(this.allDay);
	}

	selectDate(day): void {
		if (this.calendarMonth + 1 !== day.month) {
			this.setMonth(day.month - 1, day.year);
		}

		this.selectedDateString = day.date;
		this.selectedDate = moment(day.date, 'D/M/YYYY');
		this.selectedDateStringView = moment(day.date, 'D/M/YYYY').format('DD MMM YYYY');
		this.emptyCalendar = false;
		this.updateInput();
	}

	setMonth(month, year) {
		this.calendarDate.year(year).month(month);
		this.calendarYear = this.calendarDate.year();
		this.calendarMonth = this.calendarDate.month();
		this.monthName = this.calendar.months_name[this.calendarDate.month()];
		this.weeks = this.calendar.getMonth(this.calendarMonth, this.calendarYear);
	}

	nextMonth() {
		this.calendarDate.add('1', 'M');
		this.calendarYear = this.calendarDate.year();
		this.calendarMonth = this.calendarDate.month();
		this.monthName = this.calendar.months_name[this.calendarDate.month()];
		this.weeks = this.calendar.getMonth(this.calendarMonth, this.calendarYear);
	}

	previousMonth() {
		this.calendarDate.subtract('1', 'M');
		this.calendarYear = this.calendarDate.year();
		this.calendarMonth = this.calendarDate.month();
		this.monthName = this.calendar.months_name[this.calendarDate.month()];
		this.weeks = this.calendar.getMonth(this.calendarMonth, this.calendarYear);
	}

	toggleDatePicker(open: boolean) {
		if (open) {
			this.oldSelectedDateStringView = this.selectedDateStringView;
			this.oldHours = this.hours;
		} else {
			if (this._openDatePicker != open) {
				this.updateChanges();
			}
		}

		this._openDatePicker = open;

		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Cancela novo horário
	 */
	cancel() {
		this.selectedDateStringView = this.oldSelectedDateStringView;
		this.hours = this.oldHours;
		this._openDatePicker = false;
	}

	/**
	 * Limpa calendário
	 */
	empty(event) {
		event.stopPropagation();
		event.stopPropagation();

		this.emptyAllCalendar();

		this.updateChanges();
	}

	emptyAllCalendar() {
		this.emptyCalendar = true;
		this.selectedDateStringView = '';
		this.oldSelectedDateStringView = '';
		this.hours = '';
		this.oldHours = '';
		this._openDatePicker = false;
	}

	updateInput() {
		this.hours = ('00' + this.hourModel).substr(-2) + ':' + ('00' + this.minuteModel).substr(-2);
	}

	changeHour(target) {
		let number = Number(target.value) || 0;
		if (number > 59) {
			number = 59;
		}

		if (number < 0) {
			number = 0;
		}

		target.value = number;
		this.hourModel = number.toString();

		this.updateInput();

		this.emptyCalendar = false;
	}

	changeMinute(target) {
		let number = Number(target.value) || 0;
		if (number > 59) {
			number = 59;
		}

		if (number < 0) {
			number = 0;
		}

		target.value = number;
		this.minuteModel = number.toString();

		this.updateInput();

		this.emptyCalendar = false;
	}

	/**
	 * Atualiza valores para ngModel
	 */
	updateChanges() {

		if (this.emptyCalendar) {
			this.onChange('');
			this.changeDate.emit('');
		} else {
			let date = this.selectedDate.format('YYYYMMDD');

			if (!this.allDay && !this.noTime) {
				date += ('00' + this.hourModel).substr(-2);
				date += ('00' + this.minuteModel).substr(-2);
				date += ('00').substr(-2);
			}

			this.onChange(date);
			this.changeDate.emit(date);
		}
	}

	///////////////
	// OVERRIDES //
	///////////////

	/**
	 * Writes a new item to the element.
	 * @param value the value
	 */
	writeValue(value: string): void {

		if (!Helpers.empty(value)) {
			let date = Helpers.onlyNumbers(value);

			if (date.length === 8) {
				this.selectedDate = moment(date, 'YYYYMMDD');
			} else if (date.length === 12) {
				this.selectedDate = moment(date, 'YYYYMMDDHHmm');
				this.hourModel = this.selectedDate.hour().toString();
				this.minuteModel = this.selectedDate.minute().toString();
				this.updateInput();
			} else if (date.length === 14) {
				this.selectedDate = moment(date, 'YYYYMMDDHHmmss');
				this.hourModel = this.selectedDate.hour().toString();
				this.minuteModel = this.selectedDate.minute().toString();
				this.updateInput();
			}

			// Data não valida
			if (!this.selectedDate.isValid()) {
				this.emptyAllCalendar();
				return;
			}

			let day = {
				'day': this.selectedDate.date(),
				'month': this.selectedDate.month() + 1,
				'year': this.selectedDate.year(),
				'date': this.selectedDate.format('DD/M/YYYY')
			};

			this.selectDate(day);
		} else {
			this.emptyAllCalendar();
		}
	}

	/**
	 * Registers a callback function that should be called when the control's value changes in the UI.
	 * @param fn
	 */
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	/**
	 * Registers a callback function that should be called when the control receives a blur event.
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

}
