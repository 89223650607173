var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CrudManager } from '../../services/crud-manager';
import * as i0 from "@angular/core";
var LocationNeighborhoodsSubneighborhoodsService = /** @class */ (function (_super) {
    __extends(LocationNeighborhoodsSubneighborhoodsService, _super);
    function LocationNeighborhoodsSubneighborhoodsService() {
        var _this = _super.call(this) || this;
        _this.path = '/admin/locations/neighborhoods-subneighborhoods';
        return _this;
    }
    LocationNeighborhoodsSubneighborhoodsService.ngInjectableDef = i0.defineInjectable({ factory: function LocationNeighborhoodsSubneighborhoodsService_Factory() { return new LocationNeighborhoodsSubneighborhoodsService(); }, token: LocationNeighborhoodsSubneighborhoodsService, providedIn: "root" });
    return LocationNeighborhoodsSubneighborhoodsService;
}(CrudManager));
export { LocationNeighborhoodsSubneighborhoodsService };
