import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ModalContactDuplicateService } from '../../shareds/modal-contact-duplicate.service';
import { PlaySound } from 'src/app/services/PlaySound';

@Component({
	selector: 'app-modal-delete',
	templateUrl: './modal-delete.component.html',
	styleUrls: ['./modal-delete.component.scss'],
	animations: [
		trigger('EnterLeave', [
			transition(':enter', [
				style({ opacity: '0.0' }),
				animate('0.3s ease-out', style({ opacity: '1.0' }))
			]),
			transition(':leave', [
				animate('0.3s ease-out', style({ opacity: '0.0' }))
			])
		])
	]
})
export class ModalDeleteComponent implements OnInit {

	@Input() id: string;
	@Input() error = false;
	@Input() title: string;
	@Input() openModal = false;
	@Input() fn: any;
	@Output() confirm = new EventEmitter();
	@Output() openModalChange = new EventEmitter();

	constructor(
	) {
	}

	ngOnInit() {
	}

	confirmDelete(): void {
		this.confirm.emit();
		this.close();
	}

	open() {
		this.openModal = true;
		this.openModalChange.emit(this.openModal);
		if (this.error) {
			PlaySound.play('error');
		}
	}

	close() {
		this.openModal = false;
		this.openModalChange.emit(this.openModal);
	}
}
