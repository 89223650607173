var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { TasksService } from '../models/tasks.service';
var TasksResolver = /** @class */ (function () {
    function TasksResolver(taskService) {
        this.taskService = taskService;
    }
    TasksResolver.prototype.resolve = function (route) {
        var params = __assign({}, route.queryParams);
        params['task_list_id'] = route.params.id;
        return this.taskService.search(this.taskService.processFilters(params));
    };
    return TasksResolver;
}());
export { TasksResolver };
