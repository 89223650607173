import {Injectable} from '@angular/core';
import {CrudManager} from '../../services/crud-manager';
import {isArray} from 'util';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class TicketsService extends CrudManager {
	path = '/admin/tickets';

	constructor() {
		super();
	}

	processFilters(data = {}) {

		let params = {...data};

		params['limit'] = 20;

		if (params['closed']) {
			params['closed'] = Number(params['closed']);
		}

		return params;
	}

	enableContacts(id: number) {
        return this.http
            .post(environment.API + this.path + '/enabled-contacts/' + id, {})
            .pipe(map(data => this.responseTreatment.pipe(data)));
    }

}
