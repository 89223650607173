import { SocketIOResponseService } from '../shareds/SocketIO-response.service';
import { WebSocket } from '../../services/WebSocket';
import * as i0 from "@angular/core";
import * as i1 from "../shareds/SocketIO-response.service";
var PartnersChatService = /** @class */ (function () {
    function PartnersChatService(socketIOResponseService) {
        this.socketIOResponseService = socketIOResponseService;
    }
    /**
     * Criar Post
     * @param {number} ticket_id
     * @param body
     * @returns {Observable<any>}
     */
    PartnersChatService.prototype.createPost = function (ticket_id, body) {
        var data = {
            type: 'request',
            path: 'tickets/' + ticket_id + '/posts',
            body: body
        };
        return this.socketIOResponseService.response(data);
    };
    /**
     * Criar Post com anexo
     * @param {number} ticket_id
     * @param body
     * @returns {Observable<any>}
     */
    PartnersChatService.prototype.createPostAttachments = function (ticket_id, body) {
        var data = {
            type: 'request',
            method: 'POST',
            path: 'tickets/' + ticket_id + '/attachments',
            body: body
        };
        return this.socketIOResponseService.response(data);
    };
    /**
     * Criar conversa
     * @param body
     * @returns {Observable<any>}
     */
    PartnersChatService.prototype.create = function (body) {
        var data = {
            type: 'request',
            path: 'tickets',
            body: body
        };
        return this.socketIOResponseService.response(data);
    };
    /**
     * Atualizar Conversa
     * @param {number} ticket_id
     * @param body
     * @returns {Observable<any>}
     */
    PartnersChatService.prototype.update = function (ticket_id, body) {
        var data = {
            type: 'request',
            method: 'POST',
            path: 'tickets/' + ticket_id,
            body: body
        };
        return this.socketIOResponseService.response(data);
    };
    /**
     * Atualizar Conversa
     * @param {number} ticket_id
     * @param body
     */
    PartnersChatService.prototype.typing = function (ticket_id) {
        var data = {
            type: 'event',
            event: 'typing',
            body: {
                ticket_id: ticket_id
            }
        };
        WebSocket.sendMessage(data);
    };
    PartnersChatService.ngInjectableDef = i0.defineInjectable({ factory: function PartnersChatService_Factory() { return new PartnersChatService(i0.inject(i1.SocketIOResponseService)); }, token: PartnersChatService, providedIn: "root" });
    return PartnersChatService;
}());
export { PartnersChatService };
