/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input-autocomplete-location.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../directives/click-outside/click-outside.directive";
import * as i4 from "@angular/forms";
import * as i5 from "./input-autocomplete-location.component";
import * as i6 from "../../models/location-cities.service";
import * as i7 from "../../models/location-neighborhoods-subneighborhoods.service";
var styles_InputAutocompleteLocationComponent = [i0.styles];
var RenderType_InputAutocompleteLocationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputAutocompleteLocationComponent, data: {} });
export { RenderType_InputAutocompleteLocationComponent as RenderType_InputAutocompleteLocationComponent };
function View_InputAutocompleteLocationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "app-users--loading-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-spinner"]], null, null, null, null, null))], null, null); }
function View_InputAutocompleteLocationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "td td-overflow-text text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "location"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-map-marker-alt"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.city; _ck(_v, 3, 0, currVal_0); }); }
function View_InputAutocompleteLocationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "td td-overflow-text text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "location"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-map-marker-alt"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.neighborhood; _ck(_v, 3, 0, currVal_0); }); }
function View_InputAutocompleteLocationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectLocation(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputAutocompleteLocationComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputAutocompleteLocationComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.city; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.neighborhood; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectNumber == _v.context.index); _ck(_v, 0, 0, currVal_0); }); }
function View_InputAutocompleteLocationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "autocomplete-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputAutocompleteLocationComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.locations; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_InputAutocompleteLocationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "app-autocomplete field-container"]], [[2, "error-input", null]], [[null, "clickOutside"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("clickOutside" === en)) {
        var pd_1 = (_co.setFocus(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 540672, null, 0, i3.ClickOutsideDirective, [i1.ElementRef], null, { clickOutside: "clickOutside" }), (_l()(), i1.ɵeld(2, 0, null, null, 5, "input", [["autocapitalize", "off"], ["autocomplete", "asdasd"], ["autocorrect", "off"], ["spellcheck", "false"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "focus"], [null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("focus" === en)) {
        var pd_4 = (_co.setFocus(true) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.value = $event) !== false);
        ad = (pd_5 && ad);
    } if (("input" === en)) {
        var pd_6 = (_co.changeLocationInput($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(5, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(7, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputAutocompleteLocationComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputAutocompleteLocationComponent_2)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.value; _ck(_v, 5, 0, currVal_8); var currVal_9 = _co.loading; _ck(_v, 9, 0, currVal_9); var currVal_10 = _co.showAutocomplete; _ck(_v, 11, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 7).ngClassValid; var currVal_6 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_InputAutocompleteLocationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "input-autocomplete-location", [], null, [["document", "keydown.arrowup"], ["document", "keydown.arrowdown"], ["document", "keydown.enter"], ["document", "keydown.Tab"]], function (_v, en, $event) { var ad = true; if (("document:keydown.arrowup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).handleUpEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keydown.arrowdown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).handleDownEvent($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:keydown.enter" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).handleEnterEvent($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:keydown.Tab" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2).handleTabEvent($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, View_InputAutocompleteLocationComponent_0, RenderType_InputAutocompleteLocationComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.InputAutocompleteLocationComponent]), i1.ɵdid(2, 638976, null, 0, i5.InputAutocompleteLocationComponent, [i6.LocationCitiesService, i7.LocationNeighborhoodsSubneighborhoodsService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var InputAutocompleteLocationComponentNgFactory = i1.ɵccf("input-autocomplete-location", i5.InputAutocompleteLocationComponent, View_InputAutocompleteLocationComponent_Host_0, { onlyInCity: "onlyInCity", city: "city", neighborhood: "neighborhood", error: "error" }, {}, []);
export { InputAutocompleteLocationComponentNgFactory as InputAutocompleteLocationComponentNgFactory };
