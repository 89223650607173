import { Component, OnInit } from "@angular/core";
import { GlobalService } from "../../shareds/global.service";
import { Global } from "../../../services/global";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { AuthService } from "../../../services/auth.service";
import { Location } from "@angular/common";

@Component({
	selector: "app-relogin",
	templateUrl: "./relogin.component.html",
	styleUrls: ["./relogin.component.scss"]
})
export class ReloginComponent implements OnInit {
	redirectUrl = "";

	constructor(
		private _globalService: GlobalService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private authService: AuthService
	) {}

	ngOnInit() {
		this._globalService.updateLoadingScreen(false);
		this.reconect();
		this.redirectUrl =
			this.activatedRoute.snapshot.queryParams.redirectUrl || "";
	}

	reconect() {
		if (Global.userLogged) {
			return true;
		}

		return this.authService
			.checkUserLogged()
			.pipe(
				map(data => {
					Global.badges = data.badges;
					Global.info = data.get_info;
					this.authService.setUser(data.user);
				})
			)
			.pipe(
				catchError((err: any) => {
					// 30001 - Usuário não encontrado;
					if (err && err.error && (err.error.code === 3 || err.error.code === 30001)) {
						console.log("navigateByUrl");
						this.router.navigateByUrl(
							this.router.createUrlTree(["/login"], {
								queryParams: {
									redirectUrl: this.redirectUrl
								}
							})
						);
					} else {
						setTimeout(() => this.reconect(), 3000);
					}

					return of(false);
				})
			)
			.subscribe(e => {
				if (e !== false) {
					if (this.redirectUrl) {
						this.router.navigateByUrl(this.redirectUrl);
						this.location.replaceState(this.redirectUrl);
					} else {
						this.router.navigateByUrl("/");
						this.location.replaceState("/");
					}
				}
			});
	}
}
