import { PartnersPropertiesGetResolverService } from "./content/resolves/partners-properties-get-resolver.service";
import { AutomaticMessageResolverService } from "./content/resolves/automatic-message-resolver.service";
import { PartnerPropertiesResolversService } from "./content/resolves/partner-properties-resolvers.service";
import { PartnerClientDemandResolverService } from "./content/resolves/partner-chat-contact-resolver.service";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ContactsInteractionsSingleResolver } from "./content/resolves/contacts-interactions-single-resolver";
import { TasksRealizedResolver } from "./content/resolves/tasks-realized-resolver.service";
import { ContactsInteractionsResolver } from "./content/resolves/contacts-interactions-resolver";
import { EquivalenceSubNeighborhoodResolverService } from "./content/resolves/equivalence-sub-neighborhood-resolve.service";
import { LoginComponent } from "./content/pages/login/login.component";
import { CalendarComponent } from "./content/pages/calendar/calendar.component";
import { SettingsMetatagsComponent } from "./content/pages/settings-metatags/settings-metatags.component";
import { SubNeighborhoodErrorListComponent } from "./content/pages/equivalence/sub-neighborhood-error/sub-neighborhood-error-list/sub-neighborhood-error-list.component";
import { PartnershipAddComponent } from "./content/pages/partnership/partnership-add/partnership-add.component";
import { EquivalenceCityResolverService } from "./content/resolves/equivalence-city-resolver.service";
import { UsersAddComponent } from "./content/pages/users-add/users-add.component";
import { SalesFunnelListComponent } from "./content/pages/sales-funnel-list/sales-funnel-list.component";
import { TasksEditResolver } from "./content/resolves/tasks-edit-resolver.service";
import { CityAddComponent } from "./content/pages/equivalence/city/city-add/city-add.component";
import { AgendaViewResolver } from "./content/resolves/agenda-view-resolver";
import { CityListComponent } from "./content/pages/equivalence/city/city-list/city-list.component";
import { NeighborhoodListComponent } from "./content/pages/equivalence/neighborhood/neighborhood-list/neighborhood-list.component";
import { NeighborhoodErrorListComponent } from "./content/pages/equivalence/neighborhood-error/neighborhood-error-list/neighborhood-error-list.component";
import { FunnelSalesStepsResolverService } from "./content/resolves/funnel-sales-steps-resolver.service";
import { TicketsViewComponent } from "./content/pages/tickets-view/tickets-view.component";
import { SettingsGeneralComponent } from "./content/pages/settings-general/settings-general.component";
import { NeighborhoodAddComponent } from "./content/pages/equivalence/neighborhood/neighborhood-add/neighborhood-add.component";
import { InfoPlatformResolver } from "./content/resolves/info-platform-resolver.service";
import { LogoutPageComponent } from "./content/pages/logout-page/logout-page.component";
import { TaskListsComponent } from "./content/pages/task-lists/task-lists.component";
import { SettingsAccountComponent } from "./content/pages/settings-account/settings-account.component";
import { PagesComponent } from "./content/pages/pages.component";
import { PropertiesInfoResolver } from "./content/resolves/properties-info-resolver";
import { NeighborhoodErrorAddComponent } from "./content/pages/equivalence/neighborhood-error/neighborhood-error-add/neighborhood-error-add.component";
import { UsersListResolverService } from "./content/resolves/users-list-resolver.service";
import { TaskListAddComponent } from "./content/pages/task-lists/task-list-add/task-list-add.component";
import { SubNeighborhoodErrorAddComponent } from "./content/pages/equivalence/sub-neighborhood-error/sub-neighborhood-error-add/sub-neighborhood-error-add.component";
import { PartnershipResolver } from "./content/resolves/partnership.service";
import { PortalsComponent } from "./content/pages/portals/portals.component";
import { ContactsViewResolver } from "./content/resolves/contacts-view-resolver";
import { TasksComponent } from "./content/pages/tasks/tasks.component";
import { TasksEditComponent } from "./content/pages/tasks-edit/tasks-edit.component";
import { PartnersContactsLoadService } from "./content/resolves/partners-contacts-load.service";
import { ContactsInfoResolver } from "./content/resolves/contacts-info-resolver";
import { PartnershipConversationService } from "./content/resolves/partnership-conversation.service";
import { EquivalenceNeighborhoodErrorResolverService } from "./content/resolves/equivalence-neighborhood-error-resolve.service";
import { RealEstateGetService } from "./content/resolves/real-estate-get.service";
import { EquivalenceSubNeighborhoodErrorResolverService } from "./content/resolves/equivalence-sub-neighorhood-error-resolve.service";
import { ContactsSearchService } from "./content/resolves/contacts-search.service";
import { TicketsListComponent } from "./content/pages/tickets-list/tickets-list.component";
import { EquivalenceNeighborhoodResolverService } from "./content/resolves/equivalence-neighborhood-resolve.service";
import { NotificationsComponent } from "./content/pages/notifications/notifications.component";
import { PropertiesViewResolver } from "./content/resolves/properties-view-resolver";
import { SettingsWebsiteDesignComponent } from "./content/pages/settings-website-design/settings-website-design.component";
import { SubNeighborhoodListComponent } from "./content/pages/equivalence/sub-neighborhood/sub-neighborhood-list/sub-neighborhood-list.component";
import { PropertiesSearchResolver } from "./content/resolves/properties-search-resolver";
import { TasksResolver } from "./content/resolves/tasks-resolver.service";
import { CalendarViewDayComponent } from "./content/pages/calendar/calendar-view-day/calendar-view-day.component";
import { CalendarAddComponent } from "./content/pages/calendar/calendar-add/calendar-add.component";
import { NotFoundListComponent } from "./content/pages/equivalence/not-found/list/list.component";
import { NeedAuthGuardLogin } from "./services/NeedAuthGuardLogin";
import { NeedAuthGuard } from "./services/NeedAuthGuard";
import { EquivalenceNotFoundResolverService } from "./content/resolves/equivalence-not-found-resolver.service";
import { TasksListResolver } from "./content/resolves/tasks-list-resolver.service";
import { SubNeighborhoodAddComponent } from "./content/pages/equivalence/sub-neighborhood/sub-neighborhood-add/sub-neighborhood-add.component";
import { UsersListComponent } from "./content/pages/users-list/users-list.component";
import { SettingsThemeComponent } from "./content/pages/settings-theme/settings-theme.component";
import { AgendaResolver } from "./content/resolves/agenda-resolver.service";
import { BadgesResolverService } from "./content/resolves/badges-resolver.service";
import { TokenInterceptorService } from "./services/token-interceptor.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ModalPropertyViewComponent } from "./content/components/modal-property-view/modal-property-view.component";
import { UsersViewResolver } from "./content/resolves/users-view-resolver";
import { ReloginComponent } from "./content/pages/relogin/relogin.component";
import { RegisterComponent } from "./content/pages/register/register.component";
import { CompleteRegisterGuard } from "./services/CompleteRegisterGuard";
import { ContactsViewComponent } from "./content/views/contacts/contacts-view/contacts-view.component";
import { ContactsViewAddInteractionsComponent } from "./content/views/contacts/contacts-view/contacts-view-add-interactions/contacts-view-add-interactions.component";
import { ContactsViewEditInteractionsComponent } from "./content/views/contacts/contacts-view/contacts-view-edit-interactions/contacts-view-edit-interactions.component";

const routes: Routes = [
	// Adicionar chat de parceria
	{
		path: "add-chat-partners",
		component: PartnershipAddComponent,
		outlet: "modal",
		resolve: {
			contacts: PartnersContactsLoadService,
			singleContact: PartnerClientDemandResolverService,
			singleProperty: PartnerPropertiesResolversService,
			property: PartnersPropertiesGetResolverService
		}
	},

	{
		path: "view-property/:id",
		component: ModalPropertyViewComponent,
		outlet: "modal",
		resolve: {
			property: PropertiesViewResolver
		}
	},

	// Login
	{
		path: "login",
		component: LoginComponent,
		canActivate: [NeedAuthGuardLogin],
		data: {
			title: "Login"
		}
	},

	// Register
	{
		path: "register",
		component: RegisterComponent,
		canActivate: [NeedAuthGuardLogin],
		data: {
			title: "Registro"
		}
	},

	// re-login-loop
	{
		path: "re-login-loop",
		component: ReloginComponent
	},

	// Pages
	{
		path: "",
		component: PagesComponent,
		canActivate: [NeedAuthGuard],
		children: [
			// Completar Registro
			{
				path: "complete-register",
				redirectTo: "/partnership/conversation/complete-register"
			},
			// Home
			{
				path: "",
				loadChildren: "./content/views/home/home.module#HomeModule"
			},

			// Properties
			{
				path: "properties",
				loadChildren:
					"./content/views/properties/properties.module#PropertiesModule"
			},

			// Partnership
			{
				path: "partnership",
				loadChildren:
					"./content/views/partnership/partnership.module#PartnershipModule",
				canActivate: [CompleteRegisterGuard]
			},

			// Demanda de Clientes
			{
				path: "clients-shared",
				loadChildren:
					"./content/views/clients-shared/clients-shared.module#ClientsSharedModule"
			},

			// Demanda de Imóveis
			{
				path: "properties-demand",
				loadChildren:
					"./content/views/properties-demand/properties-demand.module#PropertiesDemandModule",
				canActivate: [CompleteRegisterGuard]
			},

			// Demanda de Clientes
			{
				path: "clients-demand",
				loadChildren:
					"./content/views/clients-demand/clients-demand.module#ClientsDemandModule",
				canActivate: [CompleteRegisterGuard]
			},

			// Contacts
			{
				path: "contacts",
				loadChildren:
					"./content/views/contacts/contacts.module#ContactsModule"
			},

			// Notifications
			{
				path: "notifications",
				component: NotificationsComponent,
				data: {
					title: "Notificações"
				}
			},

			// Users
			{
				path: "users",
				children: [
					{
						path: "list",
						component: UsersListComponent,
						resolve: {
							users: UsersListResolverService
						}
					},
					{
						path: "add",
						component: UsersAddComponent,
						data: {
							title: "Adicionar Corretor"
						}
					},
					{
						path: "edit/:id",
						component: UsersAddComponent,
						resolve: {
							user: UsersViewResolver
						}
					}
				]
			},
			// Sales Funnel
			{
				path: "sales-funnel/list",
				component: SalesFunnelListComponent,
				resolve: {
					steps: FunnelSalesStepsResolverService
				},
				data: {
					title: "Funil de Vendas"
				},
				children: [
					{
						path: "contacts/view/:id",
						component: ContactsViewComponent,
						resolve: {
							contact: ContactsViewResolver,
							interactions: ContactsInteractionsResolver
						},
						children: [
							{
								path: "add-interaction",
								component: ContactsViewAddInteractionsComponent
							},
							{
								path: "edit-interaction/:idInteraction",
								component: ContactsViewEditInteractionsComponent,
								resolve: {
									interaction: ContactsInteractionsSingleResolver
								}
							}
						]
					}
				]
			},
			// Tasks
			{
				path: "tasks",
				resolve: {
					taskLists: TasksListResolver
				},
				children: [
					{
						path: "add-list",
						component: TaskListAddComponent,
						outlet: "modal"
					},
					{
						path: "",
						component: TasksComponent,
						data: {
							title: "Caixa de Entrada"
						},
						children: [
							{
								path: "list/:id",
								component: TaskListsComponent,
								resolve: {
									tasks: TasksResolver,
									tasksRealized: TasksRealizedResolver
								},
								children: [
									{
										path: "task/:task_id",
										component: TasksEditComponent,
										resolve: {
											task: TasksEditResolver
										}
									}
								]
							}
						]
					}
				]
			},
			// Calendar
			{
				path: "calendar",
				component: CalendarComponent,
				data: {
					title: "Calendário"
				},
				children: [
					{
						path: "add",
						component: CalendarAddComponent,
						data: {
							title: "Adicionar Compromissos"
						}
					},
					{
						path: "edit/:id",
						component: CalendarAddComponent,
						resolve: {
							commitments: AgendaViewResolver
						}
					},
					{
						path: "day/:day/:month/:year",
						component: CalendarViewDayComponent
					}
				]
			},

			// Tickets
			{
				path: "tickets",
				children: [
					{
						path: "list",
						component: TicketsListComponent
					},
					{
						path: "view/:id",
						component: TicketsViewComponent
					}
				]
			},
			{
				path: "settings",
				children: [
					// Portals
					{
						path: "portals",
						component: PortalsComponent,
						resolve: {
							realestate: RealEstateGetService
						},
						data: {
							title: "Configurações de Portais"
						}
					},
					// Settings
					{
						path: "account",
						component: SettingsAccountComponent,
						resolve: {
							realestate: RealEstateGetService
						},
						data: {
							title: "Meu Perfil"
						}
					},
					{
						path: "website",
						component: SettingsWebsiteDesignComponent,
						resolve: {
							realestate: RealEstateGetService
						},
						data: {
							title: "Configurações do Site"
						}
					},
					{
						path: "theme",
						component: SettingsThemeComponent,
						resolve: {
							realestate: RealEstateGetService
						},
						data: {
							title: "Tema"
						}
					},
					{
						path: "meta-tags",
						component: SettingsMetatagsComponent,
						resolve: {
							realestate: RealEstateGetService
						},
						data: {
							title: "SEO"
						}
					},
					{
						path: "general",
						component: SettingsGeneralComponent,
						resolve: {
							realestate: RealEstateGetService
						},
						data: {
							title: "Configurações"
						}
					}
				]
			},
			// Equivalencias
			{
				path: "equivalence",
				children: [
					{
						path: "not-found/list",
						component: NotFoundListComponent,
						resolve: {
							locations: EquivalenceNotFoundResolverService
						}
					},
					{
						path: "city",
						children: [
							{
								path: "add",
								component: CityAddComponent,
								data: {
									title: "Adicionar Cidade"
								}
							},
							{
								path: "list",
								component: CityListComponent,
								resolve: {
									locations: EquivalenceCityResolverService
								}
							}
						]
					},
					{
						path: "neighborhood",
						children: [
							{
								path: "add",
								component: NeighborhoodAddComponent,
								data: {
									title: "Adicionar Bairro"
								}
							},
							{
								path: "list",
								component: NeighborhoodListComponent,
								resolve: {
									locations: EquivalenceNeighborhoodResolverService
								}
							}
						]
					},
					{
						path: "neighborhood-error",
						children: [
							{
								path: "add",
								component: NeighborhoodErrorAddComponent,
								data: {
									title: "Adicionar Bairro Erro"
								}
							},
							{
								path: "list",
								component: NeighborhoodErrorListComponent,
								resolve: {
									locations: EquivalenceNeighborhoodErrorResolverService
								}
							}
						]
					},
					{
						path: "sub-neighborhood",
						children: [
							{
								path: "add",
								component: SubNeighborhoodAddComponent,
								data: {
									title: "Adicionar Sub Bairro"
								}
							},
							{
								path: "list",
								component: SubNeighborhoodListComponent,
								resolve: {
									locations: EquivalenceSubNeighborhoodResolverService
								}
							}
						]
					},
					{
						path: "sub-neighborhood-error",
						children: [
							{
								path: "add",
								component: SubNeighborhoodErrorAddComponent,
								data: {
									title: "Adicionar Sub Bairro Erro"
								}
							},
							{
								path: "list",
								component: SubNeighborhoodErrorListComponent,
								resolve: {
									locations: EquivalenceSubNeighborhoodErrorResolverService
								}
							}
						]
					}
				]
			},
			{
				path: "logout",
				component: LogoutPageComponent
			}
		]
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: "enabled"
		})
	],
	exports: [RouterModule],
	providers: [
		AgendaResolver,
		UsersListResolverService,
		PropertiesSearchResolver,
		InfoPlatformResolver,
		BadgesResolverService,
		PartnersContactsLoadService,
		TasksListResolver,
		RealEstateGetService,
		TasksResolver,
		PropertiesViewResolver,
		ContactsViewResolver,
		ContactsInfoResolver,
		PropertiesInfoResolver,
		UsersViewResolver,
		TasksEditResolver,
		TasksRealizedResolver,
		PartnershipResolver,
		PartnershipConversationService,
		ContactsSearchService,
		FunnelSalesStepsResolverService,
		EquivalenceNotFoundResolverService,
		EquivalenceNeighborhoodResolverService,
		EquivalenceSubNeighborhoodResolverService,
		EquivalenceSubNeighborhoodErrorResolverService,
		EquivalenceNeighborhoodErrorResolverService,
		EquivalenceCityResolverService,
		ContactsInteractionsResolver,
		AgendaViewResolver,
		ContactsInteractionsSingleResolver,
		PartnerClientDemandResolverService,
		PartnerPropertiesResolversService,
		AutomaticMessageResolverService,
		PartnersPropertiesGetResolverService,
		CompleteRegisterGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptorService,
			multi: true
		}
	]
})
export class AppRoutingModule {}
