import {Injectable} from '@angular/core';
import {CrudManager} from '../../services/crud-manager';
import {isArray} from 'util';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class PropertiesService extends CrudManager {
	path = '/admin/properties';

	constructor() {
		super();
	}

	processFilters(data = {}) {

		let params = {...data};

		params['property_id'] = undefined;

		// Converter tipos
		if (params['type_in']) {
			if (!isArray(params['type_in'])) {
				params['type_in'] = [params['type_in']];
			}

			let type_in = [];
			for (let i in params['type_in']) {
				type_in.push(parseInt(params['type_in'][i]));
			}
			params['type_in'] = type_in;
		}

		if (params['bathrooms']) {
			params['bathrooms'] = parseInt(params['bathrooms']);
		}

		if (params['bedrooms']) {
			params['bedrooms'] = parseInt(params['bedrooms']);
		}

		if (params['price_gte']) {
			params['price_gte'] = parseInt(params['price_gte']);
		}

		// if (params['status']) {
		//     params['status'] = parseInt(params['status']);
		// }

		if (params['suites']) {
			params['suites'] = parseInt(params['suites']);
		}

		if (params['vacancies']) {
			params['vacancies'] = parseInt(params['vacancies']);
		}

		if (!params['status']) {
			params['status'] = 1;
		}

		return params;
	}

	sortableProperty(id: number, sortable: number) {
		return this.http
			.post(environment.API + this.path + '/' + id + '/featured-home/' + sortable, {})
			.pipe(map(data => this.responseTreatment.pipe(data)))
			.pipe(catchError(this.responseTreatment.handleError));
	}

}
