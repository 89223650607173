<div class="contact-view page-flex">
	<div class="flex-header">
		<app-nav-bar
			[title]="this.contact.name"
			[backIcon]="true"
			(backEvent)="backUrl()"
		>
		</app-nav-bar>
	</div>

	<div class="flex-body">
		<div class="header-contact">
			<div class="max-width">
				<div class="table">
					<div class="td td-min align-top">
						<app-initials
							[name]="contact.name"
							[size]="'md'"
						></app-initials>
					</div>
					<div class="td header-info align-top">
						<div>
							<div
								class="float-right icon-edit"
								(click)="createChatPartner()"
							>
								<i class="far fa-handshake"></i>
							</div>

							<div
								class="float-right icon-edit"
								[routerLink]="['/contacts/edit', contact.id]"
							>
								<i class="fas fa-pencil-alt"></i>
							</div>

							<div class="contact-cod">
								{{ contact.cod }}
							</div>
							<div class="contact-name">
								{{ contact.name }}
							</div>
						</div>

						<div
							class="contact-type"
							[style.color]="colors[contact.type]"
						>
							{{ contact.type_name }}
						</div>

						<div class="email" *ngIf="contact.email">
							{{ contact.email }}
						</div>

						<div class="phones" *ngIf="contact.phones.length">
							<div
								class="phone"
								*ngFor="let phone of contact.phones"
							>
								{{ phone.number | phone }}
								<div class="phone-actions">
									<span
										class="whatsapp show-mobile"
										(click)="openWhatsapp(phone.number)"
									>
										<i class="fab fa-whatsapp"></i>
									</span>

									<span
										class="whatsapp hide-mobile"
										(click)="openWhatsappWeb(phone.number)"
									>
										<i class="fab fa-whatsapp"></i>
									</span>

									<a href="tel:0{{ phone.number }}">
										<span>
											<i class="fas fa-phone"></i>
										</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="contact-tickets">
					<div
						class="tickets-users"
						*ngIf="
							contact.tickets_cache &&
							contact.tickets_cache.length > 0
						"
					>
						<a
							[routerLink]="[
								'/partnership/conversation',
								ticket.id
							]"
							*ngFor="let ticket of contact.tickets"
							class="ticket-single"
						>
							<div class="ticket-name">
								{{ ticket.name }}
							</div>
							<div *ngFor="let user of ticket.users">
								<app-initials
									[name]="user.name"
									[clean]="true"
									size="xs"
								></app-initials>
								{{ user.name }} -
								{{ user.realestate.name }}
							</div>
						</a>
					</div>
				</div>

				<div class="contact-funnel-steps">
					<ng-container
						*ngFor="let step of steps_funnel; let index = index"
					>
						<div
							class="step"
							[class.active]="contact.funnel_step_index >= index"
							(click)="updateFunnelStep(step, index)"
						>
							{{ step.name }}
						</div>
					</ng-container>
				</div>

				<div class="tabs">
					<div
						class="tab"
						[class.active]="currentTab === 0"
						(click)="changePageTab(0)"
					>
						Atividades do Cliente
					</div>

					<div
						class="tab"
						[class.active]="currentTab === 1"
						(click)="changePageTab(1)"
					>
						Informações
					</div>

					<!--<div class="tab" [class.active]="currentTab === 2" (click)=changePageTab(2)>-->
					<!--Imóveis do Proprietário-->
					<!--</div>-->

					<!--<div class="tab" [class.active]="currentTab === 3" (click)=changePageTab(3)>-->
					<!--Chat de Parcerias-->
					<!--</div>-->
				</div>
			</div>
		</div>

		<div class="contact-interactions" *ngIf="currentTab === 0">
			<div class="max-width">
				<div class="btn btn-primary" [routerLink]="urlNewCommitments">
					Novo Compromisso
				</div>

				<div
					class="interaction card"
					*ngFor="let interaction of interactions"
				>
					<div
						class="interaction-icon contact_nota"
						*ngIf="interaction.type == 'contact_nota'"
					>
						<i class="fas fa-sticky-note"></i>
					</div>

					<div
						class="interaction-icon task"
						*ngIf="interaction.type == 'task'"
					>
						<i class="fas fa-tasks"></i>
					</div>

					<div class="interaction-text {{ interaction.type }}">
						{{ interaction.type_name }}
					</div>

					<div
						class="interaction-icon commitment"
						*ngIf="interaction.type == 'commitment'"
					>
						<i
							class="fas fa-map-marker-alt"
							*ngIf="interaction.commitment.type == 'visit'"
						></i>
						<i
							class="fas fa-users"
							*ngIf="interaction.commitment.type == 'meeting'"
						></i>
						<i
							class="far fa-envelope-open"
							*ngIf="interaction.commitment.type == 'email'"
						></i>
						<i
							class="fas fa-phone"
							*ngIf="interaction.commitment.type == 'call'"
						></i>
						<i
							class="far fa-bookmark"
							*ngIf="interaction.commitment.type == 'after_sales'"
						></i>
						<i
							class="fas fa-tasks"
							*ngIf="interaction.commitment.type == 'task'"
						></i>
						<i
							class="fas fa-ticket-alt"
							*ngIf="interaction.commitment.type == 'ticket'"
						></i>
					</div>

					<!-- Tarefa -->
					<div
						class="interaction-task"
						*ngIf="interaction.type == 'task'"
					>
						<div class="title">
							{{ interaction.task.name }}
						</div>
						<div class="user-name">
							Por {{ interaction.user.name }}
						</div>
						<div class="date">
							<i class="far fa-clock"></i>
							{{
								interaction.created_at
									| amDateFormat: "DD/MM/YYYY [ás] HH:mm"
							}}
						</div>

						<div class="event-action">
							<label
								class="checked realized"
								*ngIf="interaction.task.realized"
								(click)="setRealizedTask(interaction)"
							>
								<i class="fas fa-check"></i> Realizado
							</label>

							<label
								class="checked"
								*ngIf="!interaction.task.realized"
								(click)="setRealizedTask(interaction)"
							>
								<i class="fas fa-check"></i> Não realizado
							</label>

							<div class="action-icon">
								<a
									[routerLink]="[
										'edit-interaction',
										interaction.id
									]"
								>
									<i class="fas fa-pencil-alt"></i>
								</a>
							</div>

							<div
								class="action-icon"
								(click)="openDeleteModal(interaction)"
							>
								<i class="far fa-trash-alt"></i>
							</div>
						</div>
					</div>
					<!--// Tarefa -->

					<!-- Agenda -->
					<div
						class="interaction-commitments {{
							interaction.commitment.type
						}}"
						*ngIf="interaction.type == 'commitment'"
					>
						<div class="title">
							<div class="event-type">
								<i
									class="fas fa-map-marker-alt"
									*ngIf="
										interaction.commitment.type == 'visit'
									"
								></i>
								<i
									class="fas fa-users"
									*ngIf="
										interaction.commitment.type == 'meeting'
									"
								></i>
								<i
									class="far fa-envelope-open"
									*ngIf="
										interaction.commitment.type == 'email'
									"
								></i>
								<i
									class="fas fa-phone"
									*ngIf="
										interaction.commitment.type == 'call'
									"
								></i>
								<i
									class="far fa-bookmark"
									*ngIf="
										interaction.commitment.type ==
										'after_sales'
									"
								></i>
								<i
									class="fas fa-tasks"
									*ngIf="
										interaction.commitment.type == 'task'
									"
								></i>
								<i
									class="fas fa-ticket-alt"
									*ngIf="
										interaction.commitment.type == 'ticket'
									"
								></i>
								{{ interaction.commitment.type_name }}
							</div>

							{{ interaction.commitment.name }}
						</div>

						<div class="user-name">
							Por {{ interaction.user.name }}
						</div>

						<div class="date">
							<i class="far fa-clock"></i>
							{{
								interaction.created_at
									| amDateFormat: "DD/MM/YYYY [ás] HH:mm"
							}}
						</div>

						<div class="commitments-date">
							Compromisso marcado para
							<span class="text-primary">{{
								interaction.commitment.start_at
									| amDateFormat
										: "DD [de] MMMM [de] YYYY [ás] HH:mm"
							}}</span>
						</div>

						<div class="description">
							{{ interaction.commitment.description }}
						</div>

						<div class="event-action">
							<label
								class="checked realized"
								*ngIf="interaction.commitment.realized"
								(click)="setRealizedCommitment(interaction)"
							>
								<i class="fas fa-check"></i> Realizado
							</label>

							<label
								class="checked"
								*ngIf="!interaction.commitment.realized"
								(click)="setRealizedCommitment(interaction)"
							>
								<i class="fas fa-check"></i> Não realizado
							</label>

							<div class="action-icon">
								<a
									[routerLink]="[
										'edit-interaction',
										interaction.id
									]"
								>
									<i class="fas fa-pencil-alt"></i>
								</a>
							</div>

							<div
								class="action-icon"
								(click)="openDeleteModal(interaction)"
							>
								<i class="far fa-trash-alt"></i>
							</div>
						</div>
					</div>
					<!--// Agenda -->

					<!-- Agenda -->
					<div
						class="interaction-contact-nota"
						*ngIf="interaction.type == 'contact_nota'"
					>
						<div class="title">
							Nota de {{ interaction.user.name }}
						</div>

						<div class="user-name">
							Por {{ interaction.user.name }}
						</div>

						<div class="date">
							<i class="far fa-clock"></i>
							{{
								interaction.created_at
									| amDateFormat: "DD/MM/YYYY [ás] HH:mm"
							}}
						</div>

						<div class="description">
							{{ interaction.message }}
						</div>

						<div class="event-action">
							<div class="action-icon">
								<a
									[routerLink]="[
										'edit-interaction',
										interaction.id
									]"
								>
									<i class="fas fa-pencil-alt"></i>
								</a>
							</div>

							<div
								class="action-icon"
								(click)="openDeleteModal(interaction)"
							>
								<i class="far fa-trash-alt"></i>
							</div>
						</div>
					</div>
					<!--// Agenda -->
				</div>
			</div>
		</div>

		<div class="contact-info" *ngIf="currentTab === 1">
			<div class="max-width">
				<div>
					<div class="contact-info-title">
						Informações Básicas
					</div>
					<div class="field row">
						<div class="name col-md-6">
							Código
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.cod }}
						</div>
					</div>

					<div class="field row">
						<div class="name col-md-6">
							Nome
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.name }}
						</div>
					</div>

					<div class="field row" *ngIf="contact.email">
						<div class="name col-md-6">
							Email
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.name }}
						</div>
					</div>

					<div class="field row">
						<div class="name col-md-6">
							Tipo do contato
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.type_name }}
						</div>
					</div>

					<div class="field row" *ngIf="contact.gender">
						<div class="name col-md-6">
							Sexo
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.gender == 0 ? "Feminino" : "Masculino" }}
						</div>
					</div>

					<div class="field row" *ngIf="contact.type_people">
						<div class="name col-md-6">
							Tipo
						</div>
						<div class="value col-md-6 text-right">
							{{
								contact.type_people == 0 ? "Física" : "Juridica"
							}}
						</div>
					</div>

					<div class="field row" *ngIf="contact.date_birth">
						<div class="name col-md-6">
							Data de Nascimento
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.date_birth }}
						</div>
					</div>

					<div class="field row" *ngIf="contact.RG">
						<div class="name col-md-6">
							RG
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.RG }}
						</div>
					</div>

					<div class="field row" *ngIf="contact.CPF">
						<div class="name col-md-6">
							CPF
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.CPF }}
						</div>
					</div>

					<div class="field row" *ngIf="contact.CNPJ">
						<div class="name col-md-6">
							CNPJ
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.CNPJ }}
						</div>
					</div>

					<div class="field row" *ngIf="contact.naturalness">
						<div class="name col-md-6">
							NATURALIDADE
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.naturalness }}
						</div>
					</div>

					<div class="field row" *ngIf="contact.nationality">
						<div class="name col-md-6">
							NACIONALIDADE
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.nationality }}
						</div>
					</div>

					<div class="field row" *ngIf="contact.occupation">
						<div class="name col-md-6">
							PROFISSÃO
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.occupation }}
						</div>
					</div>
				</div>

				<div>
					<div class="field row" *ngIf="contact.CEP">
						<div class="name col-md-6">
							CEP
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.CEP }}
						</div>
					</div>

					<div class="field row" *ngIf="contact.address">
						<div class="name col-md-6">
							ENDEREÇO
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.address }}
						</div>
					</div>

					<div class="field row" *ngIf="contact.number">
						<div class="name col-md-6">
							Número
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.number }}
						</div>
					</div>

					<div class="field row" *ngIf="contact.neighborhood">
						<div class="name col-md-6">
							Bairro
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.neighborhood }}
						</div>
					</div>

					<div class="field row" *ngIf="contact.city">
						<div class="name col-md-6">
							Cidade
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.city }}
						</div>
					</div>

					<div class="field row" *ngIf="contact.estate">
						<div class="name col-md-6">
							Estado
						</div>
						<div class="value col-md-6 text-right">
							{{ contact.estate }}
						</div>
					</div>
				</div>

				<div
					*ngFor="
						let perfil of contact.propertie_search;
						let index = index
					"
				>
					<div class="contact-info-title">
						Perfil do imóvel buscado {{ index + 1 }}
					</div>

					<div class="div">
						<div class="field row">
							<div class="name col-md-6">
								Finalidade
							</div>
							<div class="value col-md-6 text-right">
								{{ !perfil.finality ? "Compra" : "Locação" }}
							</div>
						</div>

						<div class="field row" *ngIf="perfil.location.estate">
							<div class="name col-md-6">
								Estado
							</div>
							<div class="value col-md-6 text-right">
								{{ perfil.location.estate }}
							</div>
						</div>

						<div class="field row" *ngIf="perfil.location.city">
							<div class="name col-md-6">
								Cidade
							</div>
							<div class="value col-md-6 text-right">
								{{ perfil.location.city }}
							</div>
						</div>

						<div
							class="field row"
							*ngIf="perfil.location.neighborhood"
						>
							<div class="name col-md-6">
								Bairro
							</div>
							<div class="value col-md-6 text-right">
								{{ perfil.location.neighborhood }}
							</div>
						</div>

						<div class="field row" *ngIf="perfil.price">
							<div class="name col-md-6">
								Valor buscado
							</div>
							<div class="value col-md-6 text-right">
								{{ perfil.price }}
							</div>
						</div>

						<div class="field row" *ngIf="perfil.types">
							<div class="name col-md-6">
								Tipo de Imóvel
							</div>
							<div class="value col-md-6 text-right">
								Apartamento, Sobrado
							</div>
						</div>

						<div class="field row" *ngIf="perfil.price">
							<div class="name col-md-6">
								Tipo de Imóvel
							</div>
							<div class="value col-md-6 text-right">
								Apartamento, Sobrado
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="prop-owner" *ngIf="currentTab === 2"></div>

		<div class="prop-owner" *ngIf="currentTab === 3"></div>
	</div>
</div>

<router-outlet> </router-outlet>

<app-modal-delete
	[openModal]="modalDelete"
	(confirm)="deleteInteractions(currentInteraction.id)"
	[title]="modalTitle"
>
	Você tem certeza que deseja excluir permanentemente o item?
</app-modal-delete>
