/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./string-time.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "angular2-moment/duration.pipe";
import * as i3 from "./string-time.component";
var styles_StringTimeComponent = [i0.styles];
var RenderType_StringTimeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StringTimeComponent, data: {} });
export { RenderType_StringTimeComponent as RenderType_StringTimeComponent };
export function View_StringTimeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DurationPipe, []), (_l()(), i1.ɵted(1, null, ["\u00A0", "\n"])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v, 0), _co.dateString, "seconds")); _ck(_v, 1, 0, currVal_0); }); }
export function View_StringTimeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-string-time", [], null, null, null, View_StringTimeComponent_0, RenderType_StringTimeComponent)), i1.ɵdid(1, 245760, null, 0, i3.StringTimeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StringTimeComponentNgFactory = i1.ɵccf("app-string-time", i3.StringTimeComponent, View_StringTimeComponent_Host_0, { date: "date", dateFormat: "dateFormat" }, {}, []);
export { StringTimeComponentNgFactory as StringTimeComponentNgFactory };
