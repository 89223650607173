import { Pipe, PipeTransform } from "@angular/core";
import { Helpers } from "../../services/helpers";

@Pipe({
	name: "buyersPrice"
})
export class BuyersPricePipe implements PipeTransform {
	transform(propersearch: [any], args?: any): any {
		let maxValue = 0;
		for (let i in propersearch) {
			if (propersearch[i]["price"]) {
				if (parseFloat(propersearch[i]["price"]) > maxValue) {
					maxValue = parseFloat(propersearch[i]["price"]);
				}
			}
		}

		if (maxValue !== 0) {
			maxValue = maxValue / 100.0;
			return "Até R$" + Helpers.numberFormat(maxValue);
		} else {
			return "Valor não informado";
		}
	}
}
