import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ModalDefault} from '../../../shareds/modal-default.service';
import {AgendaService} from '../../../models/agenda.service';
import {PlaySound} from '../../../../services/PlaySound';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {RouterHistoric} from '../../../shareds/router-historic.service';
import {Helpers} from '../../../../services/helpers';
import {CalendarSharedService} from '../../../shareds/calendar.service';
import {Location} from '@angular/common';
import { TitleService } from 'src/app/services/title.service';

@Component({
	selector: 'app-calendar-add',
	templateUrl: './calendar-add.component.html',
	styleUrls: ['./calendar-add.component.scss']
})
export class CalendarAddComponent implements OnInit {

	@Output() saved = new EventEmitter();
	@Input() form: FormGroup;

	private formSubmitAttempt = false;

	openModal = false;
	type = '';

	id = 0;

	constructor(
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private location: Location,
		private router: Router,
		private toastr: ToastrService,
		private agendaService: AgendaService,
		private changeDetectorRef: ChangeDetectorRef,
		private calendarSharedService: CalendarSharedService,
		private _titleService: TitleService
	) {

	}

	ngOnInit() {

		let date = moment(this.route.snapshot.queryParams.day + '000000', 'YYYYMMDDHHmmss').isValid();

		this.form = this.fb.group({
			name: ['', [Validators.required]],
			type: ['', []],
			all_day: [date, []],
			realized: [0, []],
			start_at: [date ? this.route.snapshot.queryParams.day + '000000' : '', [Validators.required]],
			shared_users: [[], []],
			description: ['', []],
		});

		this.loadCommitment(this.route.snapshot.data.commitments);

	}

	loadCommitment(data: any) {

		if (Helpers.empty(data)) {
			return false;
		}

		this.id = Number(data.id);
		this._titleService.setEditTitle(data.name);

		this.form.get('name').setValue(data.name);
		this.form.get('type').setValue(data.type);
		this.form.get('all_day').setValue(data.all_day);
		this.form.get('start_at').setValue(data.start_at);
		this.form.get('realized').setValue(data.realized);
		this.form.get('shared_users').setValue(data.shared_users_data);
		this.form.get('description').setValue(data.description);

	}

	clean() {

	}

	/**
	 * Define tipo do evento
	 * @param {string} type
	 */
	setType(type: string) {
		this.type = type;
		this.form.get('type').setValue(type);
	}

	allDay(event: any) {
		console.log(event);
		this.form.get('all_day').setValue(event);
	}

	/**
	 * Verifica se campo é valido
	 * @param {string} field
	 * @returns {boolean}
	 */
	isFieldValid(field: string) {
		return (!this.form.get(field).valid && this.formSubmitAttempt);
	}

	close() {
		// Se url antiga for visualização de dia
		// alert(RouterHistoric.urlContain('/calendar/day'));
		if (RouterHistoric.urlContain('/calendar/day')) {
			this.location.back();
		} else {
			this.router.navigateByUrl('/calendar');
		}
	}

	save() {

		this.formSubmitAttempt = false;

		/**
		 * Formulário com erros
		 */
		if (!this.form.valid) {
			this.formSubmitAttempt = true;
			PlaySound.play('error');
			this.toastr.error('Preencha os campos corretamente.');

			this.changeDetectorRef.detectChanges();

			let el = document.getElementsByClassName('error-input')[0];
			if (!!el) {
				el.scrollIntoView();
			}

			return false;
		}

		let data = {...this.form.value};

		data.all_day = data.all_day ? 1 : 0;
		data.realized = data.realized ? 1 : 0;

		if (!this.id) {
			return this.agendaService
				.create(data)
				.subscribe(resp => {
					this.toastr.success('Evento adicionado com sucesso!');
					this.close();
					this.calendarSharedService.refresh();
				}, err => {
					this.toastr.error('Erro ao criar evento.');
				});
		} else {
			return this.agendaService
				.update(this.id, data)
				.subscribe(resp => {
					this.toastr.success('Evento atualizado com sucesso!');
					this.close();
					this.calendarSharedService.refresh();
				}, err => {
					this.toastr.error('Erro ao atualizar evento.');
				});
		}

	}

}
