/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logout-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./logout-page.component";
import * as i3 from "../../../services/auth.service";
var styles_LogoutPageComponent = [i0.styles];
var RenderType_LogoutPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoutPageComponent, data: {} });
export { RenderType_LogoutPageComponent as RenderType_LogoutPageComponent };
export function View_LogoutPageComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_LogoutPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logout-page", [], null, null, null, View_LogoutPageComponent_0, RenderType_LogoutPageComponent)), i1.ɵdid(1, 114688, null, 0, i2.LogoutPageComponent, [i3.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutPageComponentNgFactory = i1.ɵccf("app-logout-page", i2.LogoutPageComponent, View_LogoutPageComponent_Host_0, {}, {}, []);
export { LogoutPageComponentNgFactory as LogoutPageComponentNgFactory };
