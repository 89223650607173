var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { CrudManager } from '../../services/crud-manager';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
var TasksService = /** @class */ (function (_super) {
    __extends(TasksService, _super);
    function TasksService() {
        var _this = _super.call(this) || this;
        _this.path = '/admin/tasks';
        return _this;
    }
    TasksService.prototype.processFilters = function (data) {
        if (data === void 0) { data = {}; }
        var params = __assign({}, data);
        if (params['task_list_id']) {
            params['task_list_id'] = Number(params['task_list_id']);
        }
        if (!params['realized']) {
            params['realized'] = 0;
        }
        else {
            params['realized'] = Number(params['realized']);
        }
        return params;
    };
    TasksService.prototype.sortable = function (taskId, sortable, realized) {
        var _this = this;
        if (realized === void 0) { realized = 0; }
        return this.http
            .post(environment.API + this.path + '/' + taskId + '/sortable/' + sortable + '/' + realized, {})
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }));
    };
    /**
     * Criar Comentário
     * @param {number} taskID
     * @param body
     * @returns {Observable<any>}
     */
    TasksService.prototype.createComment = function (taskID, body) {
        var _this = this;
        return this.http
            .post(environment.API + this.path + '/' + taskID + '/comments', body)
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }));
    };
    /**
     * Buscar Comentários
     * @param {number} taskID
     * @param body
     * @returns {Observable<any>}
     */
    TasksService.prototype.searchComment = function (taskID, body) {
        var _this = this;
        var params = {};
        params['long_polling'] = true;
        params['filters'] = body;
        return this.http
            .post(environment.API + this.path + '/' + taskID + '/comments/search', params)
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }));
    };
    /**
     * Criar subtarefa
     * @param {number} taskID
     * @param body
     * @returns {Observable<any>}
     */
    TasksService.prototype.createSubTask = function (taskID, body) {
        var _this = this;
        return this.http
            .post(environment.API + this.path + '/' + taskID + '/sub-tasks', body)
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }));
    };
    /**
     * Atualizar subtarefa
     * @param {number} taskID
     * @param body
     * @returns {Observable<any>}
     */
    TasksService.prototype.updateSubTask = function (subTaskID, body) {
        var _this = this;
        return this.http
            .post(environment.API + this.path + '/sub-tasks/' + subTaskID, body)
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }));
    };
    /**
     * Deletar subtarefa
     * @param {number} taskID
     * @param body
     * @returns {Observable<any>}
     */
    TasksService.prototype.deleteSubTask = function (subTaskID) {
        var _this = this;
        return this.http
            .delete(environment.API + this.path + '/sub-tasks/' + subTaskID)
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }));
    };
    TasksService.ngInjectableDef = i0.defineInjectable({ factory: function TasksService_Factory() { return new TasksService(); }, token: TasksService, providedIn: "root" });
    return TasksService;
}(CrudManager));
export { TasksService };
