/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./textarea-auto-sizing.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./textarea-auto-sizing.component";
var styles_TextareaAutoSizingComponent = [i0.styles];
var RenderType_TextareaAutoSizingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextareaAutoSizingComponent, data: {} });
export { RenderType_TextareaAutoSizingComponent as RenderType_TextareaAutoSizingComponent };
export function View_TextareaAutoSizingComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { elBtnRef: 0 }), i1.ɵqud(402653184, 2, { elTexteareaRef: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "fake"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[2, 0], ["textareaEl", 1]], null, 7, "textarea", [["id", "textarea-resize"], ["name", "value"], ["rows", "1"], ["type", "text"]], [[8, "placeholder", 0], [4, "height", null], [1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keydown"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keydown" === en)) {
        var pd_4 = (_co.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(5, 540672, null, 0, i2.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.MaxLengthValidator]), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(8, 540672, null, 0, i2.FormControlDirective, [[6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_j]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlDirective]), i1.ɵdid(10, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(11, 0, [[1, 0], ["submitScreen", 1]], null, 0, "button", [["class", "over-screen"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_10 = _co.maxlength; _ck(_v, 5, 0, currVal_10); var currVal_11 = _co._value; _ck(_v, 8, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; var currVal_1 = _co.height; var currVal_2 = (i1.ɵnov(_v, 5).maxlength ? i1.ɵnov(_v, 5).maxlength : null); var currVal_3 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 10).ngClassValid; var currVal_8 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_TextareaAutoSizingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-textarea-auto-sizing", [], null, null, null, View_TextareaAutoSizingComponent_0, RenderType_TextareaAutoSizingComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.TextareaAutoSizingComponent]), i1.ɵdid(2, 114688, null, 0, i3.TextareaAutoSizingComponent, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var TextareaAutoSizingComponentNgFactory = i1.ɵccf("app-textarea-auto-sizing", i3.TextareaAutoSizingComponent, View_TextareaAutoSizingComponent_Host_0, { value: "value", placeholder: "placeholder", maxHeight: "maxHeight", enterSubmit: "enterSubmit", breakLine: "breakLine", focus: "focus" }, { resizeEvent: "resizeEvent" }, []);
export { TextareaAutoSizingComponentNgFactory as TextareaAutoSizingComponentNgFactory };
