import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "@angular/router";
var TitleService = /** @class */ (function () {
    function TitleService(_titleService, route) {
        this._titleService = _titleService;
        this.route = route;
    }
    TitleService.prototype.setTitle = function (title) {
        this._titleService.setTitle(title + " | Banco dos Im\u00F3veis");
    };
    TitleService.prototype.setEditTitle = function (title) {
        this._titleService.setTitle("Editar " + title + " | Banco dos Im\u00F3veis");
    };
    TitleService.prototype.setListTitle = function (title, page) {
        if (!!page) {
            title = title + " - Pagina " + page + " | Banco dos Im\u00F3veis";
        }
        else {
            title = "" + title;
        }
        this._titleService.setTitle(title);
    };
    TitleService.ngInjectableDef = i0.defineInjectable({ factory: function TitleService_Factory() { return new TitleService(i0.inject(i1.Title), i0.inject(i2.ActivatedRoute)); }, token: TitleService, providedIn: "root" });
    return TitleService;
}());
export { TitleService };
