var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CrudManager } from '../../services/crud-manager';
import * as i0 from "@angular/core";
var ContactsInteractionsService = /** @class */ (function (_super) {
    __extends(ContactsInteractionsService, _super);
    function ContactsInteractionsService() {
        var _this = _super.call(this) || this;
        _this.path = '/admin/contacts/:id/interactions';
        return _this;
    }
    ContactsInteractionsService.prototype.setContact = function (contact_id) {
        this.path = '/admin/contacts/' + contact_id + '/interactions';
        return this;
    };
    ContactsInteractionsService.ngInjectableDef = i0.defineInjectable({ factory: function ContactsInteractionsService_Factory() { return new ContactsInteractionsService(); }, token: ContactsInteractionsService, providedIn: "root" });
    return ContactsInteractionsService;
}(CrudManager));
export { ContactsInteractionsService };
