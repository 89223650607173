import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var WebsocketService = /** @class */ (function () {
    function WebsocketService() {
    }
    WebsocketService.changeMessage = function (data) {
        WebsocketService.messageSource.next(data);
    };
    WebsocketService.changeStatus = function (status) {
        WebsocketService.statusSource.next(status);
    };
    WebsocketService.changeMutation = function (data) {
        WebsocketService.mutationSource.next(data);
    };
    WebsocketService.changeEvents = function (data) {
        WebsocketService.eventsSource.next(data);
    };
    WebsocketService.messageSource = new BehaviorSubject(false);
    WebsocketService.statusSource = new BehaviorSubject('');
    WebsocketService.mutationSource = new BehaviorSubject({});
    WebsocketService.eventsSource = new BehaviorSubject({});
    WebsocketService.message = WebsocketService.messageSource.asObservable();
    WebsocketService.status = WebsocketService.statusSource.asObservable();
    WebsocketService.mutation = WebsocketService.mutationSource.asObservable();
    WebsocketService.events = WebsocketService.eventsSource.asObservable();
    WebsocketService.ngInjectableDef = i0.defineInjectable({ factory: function WebsocketService_Factory() { return new WebsocketService(); }, token: WebsocketService, providedIn: "root" });
    return WebsocketService;
}());
export { WebsocketService };
