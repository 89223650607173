///<reference path="../../services/global.ts"/>
import {Injectable} from '@angular/core';
import {InfoPlatformService} from '../models/info-platform.service';
import {Resolve} from '@angular/router';
import {map} from 'rxjs/operators';
import {Global} from '../../services/global';

@Injectable()
export class InfoPlatformResolver implements Resolve<any> {

	constructor(private infoPlatformService: InfoPlatformService) {
	}

	resolve() {
		return this.infoPlatformService
			.getInfo()
			.pipe(map(data => {
				Global.info = data;
				return data;
			}));
	}
}
