<div class="modal-screen modal-select-properties change-mobile-page">

	<div class="modal-body d-flex">

		<div class="header-page">
			<div class="i-close" (click)="close()">
				<div class="icon-close-only black s32x32 display-close"></div>
				<img src="/assets/svg/home.svg" class="display-back">
			</div>

			<div class="title">
				Adicionar lista
			</div>
		</div>

		<div class="search-list-container">

			<form [formGroup]="form" (submit)="save()">
				<div>
					<div class="row m-0">
						<div class="col-12">
							<div class="field-container" [class.error-input]="isFieldValid('name')">
								<input type="text" formControlName="name">
								<div class="name">Nome da Lista</div>
							</div>

							<app-field-error-display [displayError]="isFieldValid('name')" errorMsg="Informe o nome da Lista">
							</app-field-error-display>
						</div>
					</div>
					<div class="row m-0">
						<div class="col-12">
							<div class="field-container pr-sm-0">
								<app-input-multi-users formControlName="shared_users">
								</app-input-multi-users>
								<div class="name">
									<i class="fas fa-user-plus"></i>
									Compartilhar com usuários
								</div>
							</div>
						</div>
					</div>

					<div class="text-center pt-2">
						<button class="btn btn-primary" (click)="save()" type="button">
							Criar Lista
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
