var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { CrudManager } from '../../services/crud-manager';
import { Global } from '../../services/global';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
var LocationSearchService = /** @class */ (function (_super) {
    __extends(LocationSearchService, _super);
    function LocationSearchService() {
        var _this = _super.call(this) || this;
        _this.path = '/admin/locations/lazy-search';
        _this.path = '/realestate/lazy-search?token=' + Global.user.realestate.hash;
        return _this;
    }
    LocationSearchService.prototype.setRealEstate = function (realEstate) {
        this.path = '/realestate/lazy-search?token=' + realEstate.hash;
    };
    /**
     * Buscar recursos
     * @param _body
     * @returns {Observable<any>}
     */
    LocationSearchService.prototype.search = function (_body) {
        var _this = this;
        var body = __assign({}, _body);
        return this.http
            .post(environment.API + '/admin/locations/lazy-search/equivalence', body)
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }))
            .pipe(catchError(this.responseTreatment.handleError));
    };
    LocationSearchService.ngInjectableDef = i0.defineInjectable({ factory: function LocationSearchService_Factory() { return new LocationSearchService(); }, token: LocationSearchService, providedIn: "root" });
    return LocationSearchService;
}(CrudManager));
export { LocationSearchService };
