<div class="modal-screen" *ngIf="openModal" [@EnterLeave]="openModal">

    <div class="i-close" (click)="close()">
        <i class="fa fa-times" aria-hidden="true"></i>
    </div>

    <div class="dialog-content">
        <div class="dialog text-center d-t">
            <div class="icon-circle ng-scope">
                <i class="far fa-envelope-open" *ngIf="emailExist == true"></i>
                <i class="fas fa-phone" *ngIf="phoneExist == true"></i>
            </div>

            <div class="text m-t ng-scope ng-hide" *ngIf="emailExist == true">
                O email <span class="strong">{{ contact.email }}</span> já está sendo usado pelo contato <span class="strong">{{ contact.name }}</span>!
            </div>

            <div class="text m-t ng-scope" *ngIf="phoneExist == true">
                O telefone <span class="strong">{{ contact.phones[0].number | phone }}</span> já está sendo usado pelo contato <span class="strong">{{ contact.name }}</span>!
            </div>

            <div class="btn-group m-t ng-scope">

                <div class="btn btn-default m-r" *ngIf="phoneExist == true" (click)="useOther()">
                    Usar outro Telefone
                </div>

                <div class="btn btn-default m-r" *ngIf="emailExist == true" (click)="useOther()">
                    Usar outro email
                </div>

                <div class="btn btn-danger" (click)="useThisContact()" *ngIf="!contactPage">Usar este contato</div>
                <div class="btn btn-danger" (click)="viewContact()" *ngIf="contactPage">Visualizar Contato</div>
            </div>
        </div>

    </div>
</div>
