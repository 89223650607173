import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {TasksListService} from '../models/tasks-list.service';

@Injectable()
export class TasksListResolver implements Resolve<any> {

	constructor(
		private taskListService: TasksListService
	) {

	}

	resolve(route: ActivatedRouteSnapshot) {
		return this.taskListService.getLists();
	}
}
