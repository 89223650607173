/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-property-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../pipes/price.pipe";
import * as i4 from "./modal-property-view.component";
import * as i5 from "@angular/router";
var styles_ModalPropertyViewComponent = [i0.styles];
var RenderType_ModalPropertyViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalPropertyViewComponent, data: { "animation": [{ type: 7, name: "ModalAnimation", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: "0.0" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: "1.0" }, offset: null }, timings: "0.3s ease-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: "0.0" }, offset: null }, timings: "0.3s ease-out" }], options: null }], options: {} }] } });
export { RenderType_ModalPropertyViewComponent as RenderType_ModalPropertyViewComponent };
function View_ModalPropertyViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "price sale"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Venda "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "number"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" R$ ", " "])), i1.ɵppd(4, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.property.sale_price)); _ck(_v, 3, 0, currVal_0); }); }
function View_ModalPropertyViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "price rent"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Loca\u00E7\u00E3o "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "number"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" R$ ", " "])), i1.ɵppd(4, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.property.rent_price)); _ck(_v, 3, 0, currVal_0); }); }
function View_ModalPropertyViewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "benif-unit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "bullet"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵted(5, null, [": ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.$implicit.value; _ck(_v, 5, 0, currVal_1); }); }
function View_ModalPropertyViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Detalhes do im\u00F3vel"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPropertyViewComponent_4)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.property.details; _ck(_v, 4, 0, currVal_0); }, null); }
function View_ModalPropertyViewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Descri\u00E7\u00E3o"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.property.description; _ck(_v, 4, 0, currVal_0); }); }
function View_ModalPropertyViewComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-md-4 col-sm-12 benif-unit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_ModalPropertyViewComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Caracter\u00EDsticas"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "row m-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPropertyViewComponent_7)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.property.benefits_names; _ck(_v, 5, 0, currVal_0); }, null); }
function View_ModalPropertyViewComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-md-4 col-sm-12 benif-unit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check text-secondary"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_ModalPropertyViewComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pr\u00F3ximos do Im\u00F3vel"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "row m-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPropertyViewComponent_9)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.property.places_nearby_names; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_ModalPropertyViewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.PricePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 38, "div", [["class", "modal-screen modal-select-properties change-mobile-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 37, "div", [["class", "modal-body d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "header-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "i-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "icon-close-only black s32x32 display-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["class", "display-back"], ["src", "assets/svg/home.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Visualizar Im\u00F3vel "])), (_l()(), i1.ɵeld(9, 0, null, null, 30, "div", [["class", "search-list-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 10, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "bdi-btn float-right hide-mobile"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createPartners() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "far fa-handshake"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Solicitar parceria "])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "property-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "cod"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), (_l()(), i1.ɵted(17, null, [" ", " "])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["class", "bdi-btn show-mobile"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createPartners() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "i", [["class", "far fa-handshake"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Solicitar parceria "])), (_l()(), i1.ɵeld(21, 0, null, null, 1, "div", [["class", "images"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 16, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 4, "div", [["class", "prices"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPropertyViewComponent_1)), i1.ɵdid(26, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPropertyViewComponent_2)), i1.ɵdid(28, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPropertyViewComponent_3)), i1.ɵdid(30, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPropertyViewComponent_5)), i1.ɵdid(32, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPropertyViewComponent_6)), i1.ɵdid(34, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPropertyViewComponent_8)), i1.ɵdid(36, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(37, 0, null, null, 2, "div", [["class", "bdi-btn full"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createPartners() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(38, 0, null, null, 0, "i", [["class", "far fa-handshake"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Solicitar parceria "]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = !!_co.property.sale_price; _ck(_v, 26, 0, currVal_3); var currVal_4 = !!_co.property.rent_price; _ck(_v, 28, 0, currVal_4); var currVal_5 = _co.property.details.length; _ck(_v, 30, 0, currVal_5); var currVal_6 = _co.property.description; _ck(_v, 32, 0, currVal_6); var currVal_7 = _co.property.benefits_names.length; _ck(_v, 34, 0, currVal_7); var currVal_8 = _co.property.places_nearby_names.length; _ck(_v, 36, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.property.cod; _ck(_v, 16, 0, currVal_0); var currVal_1 = _co.property.title; _ck(_v, 17, 0, currVal_1); var currVal_2 = _co.property.images[0].url; _ck(_v, 22, 0, currVal_2); }); }
export function View_ModalPropertyViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-modal-property-view", [], null, null, null, View_ModalPropertyViewComponent_0, RenderType_ModalPropertyViewComponent)), i1.ɵprd(512, null, i3.PricePipe, i3.PricePipe, []), i1.ɵdid(2, 638976, null, 0, i4.ModalPropertyViewComponent, [i5.Router, i5.ActivatedRoute, i3.PricePipe], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ModalPropertyViewComponentNgFactory = i1.ɵccf("app-modal-property-view", i4.ModalPropertyViewComponent, View_ModalPropertyViewComponent_Host_0, {}, {}, []);
export { ModalPropertyViewComponentNgFactory as ModalPropertyViewComponentNgFactory };
