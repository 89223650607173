var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Global } from '../../../../services/global';
import { Contacts } from '../../../interfaces/contacts';
import { PlaySound } from '../../../../services/PlaySound';
import { ToastrService } from 'ngx-toastr';
import { ContactsService } from '../../../models/contacts.service';
import { ModalContactDuplicateService } from '../../../shareds/modal-contact-duplicate.service';
import { Helpers } from '../../../../services/helpers';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { TitleService } from 'src/app/services/title.service';
import { RouterHistoric } from '../../../shareds/router-historic.service';
import { Location } from '@angular/common';
var ContactsAddComponent = /** @class */ (function () {
    function ContactsAddComponent(fb, contactsService, route, location, modalContactDuplicateService, toastr, _titleService, router) {
        var _this = this;
        this.fb = fb;
        this.contactsService = contactsService;
        this.route = route;
        this.location = location;
        this.modalContactDuplicateService = modalContactDuplicateService;
        this.toastr = toastr;
        this._titleService = _titleService;
        this.router = router;
        this.id = 0;
        this.propertie_search_tab = 0;
        this.contact = new Contacts();
        // Mostrar informações de etapa de funil e etc
        this.displayForm = false;
        this.nextCod = '';
        this.starting = true;
        this.formSubmitAttempt = false;
        this.saving = false;
        // Destruir todos os subscrive
        this.destroy$ = new Subject();
        this.types = Global.info.contacts.types;
        this.types_properties = Global.info.properties.types;
        this.steps_funnel = Global.info.contacts.steps_funnel;
        this.users_admin = Global.info.users_admin;
        this.estates = Global.info.estates;
        this.configureForm();
        // Define Default
        this.setType({ id: 7 });
        this.setFunnelStep({ id: this.steps_funnel[0].id });
        if (this.route.snapshot.params.id) {
            forkJoin([
                this.contactsService.get(this.route.snapshot.params.id),
                this.contactsService.getAny('info')
            ]).subscribe(function (res) {
                _this.loadContact(res[0]);
                _this.nextCod = res[1].next_cod;
                _this.starting = false;
            });
        }
        else {
            this.contactsService.getAny('info').subscribe(function (properties_info) {
                _this.starting = false;
                _this.nextCod = properties_info.next_cod;
            }, function () {
                _this.starting = false;
            });
        }
        // this.nextCod = this.route.snapshot.data.properties_info.next_cod;
    }
    ContactsAddComponent.prototype.ngOnInit = function () {
    };
    ContactsAddComponent.prototype.configureForm = function () {
        var users = this.users_admin.map(function (c) {
            // if (data.realtors_managers_in) {
            //     return new FormControl(data.realtors_managers_in.indexOf(c.id) !== -1);
            // } else {
            return new FormControl(false);
            // }
        });
        var types = this.types.map(function (c) {
            return new FormControl(false);
        });
        var types_properties = this.types_properties.map(function (c) {
            return new FormControl(false);
        });
        this.form = this.fb.group({
            cod: ['', []],
            name: ['', [Validators.required]],
            email: ['', [Validators.email]],
            phones: this.fb.array([]),
            type: ['', []],
            funnel_step: ['', []],
            realtors_managers: new FormArray(users),
            gender: ['', []],
            type_people: ['', []],
            RG: ['', []],
            CPF: ['', []],
            CNPJ: ['', []],
            CEP: ['', []],
            address: ['', []],
            number: ['', []],
            estate: ['', []],
            city: ['', []],
            neighborhood: ['', []],
            date_birth: ['', []],
            nationality: ['', []],
            naturalness: ['', []],
            occupation: ['', []],
            comments: ['', []],
            propertie_search: this.fb.array([]),
        });
        this.addPhone();
        this.addPerfilSearch();
    };
    ContactsAddComponent.prototype.loadContact = function (data) {
        var _this = this;
        if (!data) {
            return false;
        }
        this.id = data.id;
        this.contact = data;
        var realtors_managers = this.users_admin.map(function (v, i) {
            console.log(v.id);
            if (data.realtors_managers.indexOf(v.id) !== -1) {
                _this.form.controls['realtors_managers']
                    .at(i)
                    .setValue(true);
            }
        });
        this.setType({ id: data.type });
        this.setFunnelStep({ id: data.type.funnel_step });
        if (!Helpers.empty(data.propertie_search)) {
            this.removePerfilSearch(0);
            for (var i = 0; i < data.propertie_search.length; i++) {
                // data.propertie_search[i].price = data.propertie_search[i].price / 100.0;
                data.propertie_search[i].price = data.propertie_search[i].price;
                this.addPerfilSearch();
            }
            this.form.get('propertie_search')
                .patchValue(data.propertie_search);
        }
        if (!Helpers.empty(data.phones)) {
            var control = this.form.get('phones');
            for (var i = control.length - 1; i >= 0; i--) {
                control.removeAt(i);
            }
            for (var i = 0; i < data.phones.length; i++) {
                this.addPhone();
            }
            this.form.get('phones')
                .patchValue(data.phones);
        }
        // cod
        this.form.patchValue({
            cod: data.cod || '',
            name: data.name || '',
            email: data.email || '',
            type: data.type || '',
            funnel_step: data.funnel_step || '',
            realtors_managers: data.realtors_managers || '',
            gender: data.gender || '',
            type_people: data.type_people || '',
            RG: data.RG || '',
            CPF: data.CPF || '',
            CNPJ: data.CNPJ || '',
            CEP: data.CEP || '',
            address: data.address || '',
            number: data.number || '',
            estate: data.estate || '',
            city: data.city || '',
            neighborhood: data.neighborhood || '',
            date_birth: data.date_birth || '',
            nationality: data.nationality || '',
            naturalness: data.naturalness || '',
            occupation: data.occupation || '',
            comments: data.comments || '',
        });
        this._titleService.setEditTitle(data.name);
    };
    /**
     * Verifica se campo é valido
     * @param {string} field
     * @returns {boolean}
     */
    ContactsAddComponent.prototype.isFieldValid = function (field) {
        return (!this.form.get(field).valid && this.formSubmitAttempt);
    };
    /**
     * Remove Telefone
     * @param index
     */
    ContactsAddComponent.prototype.removePhone = function (index) {
        this.form.get('phones')
            .removeAt(index);
    };
    /**
     * Adicionar telefone
     */
    ContactsAddComponent.prototype.addPhone = function () {
        this.form.get('phones')
            .push(this.fb.group({
            type: ['Residencial', []],
            operator: ['', []],
            number: ['', []]
        }));
    };
    /**
     * Remover perfil de busca Imóvel
     * @param index
     */
    ContactsAddComponent.prototype.removePerfilSearch = function (index) {
        this.form.get('propertie_search')
            .removeAt(index);
        this.subscribePropertiesSearch();
    };
    /**
     * Limpar perfil de busca Imóvel
     * @param index
     */
    ContactsAddComponent.prototype.cleanPerfilSearch = function (index) {
        var types = this.types.map(function (c) {
            return false;
        });
        this.form.get('propertie_search.' + index).patchValue({
            bedroom: 0,
            bathroom: 0,
            suite: 0,
            vacancy: 0,
            area_total: 0,
            area_useful: 0,
            subtypes: [],
            types: types,
            price: '',
            finality: '',
            location: {
                estate: '',
                city: '',
                neighborhood: '',
            }
        });
    };
    /**
     * Adicionar Perfil de busca Imóvel
     */
    ContactsAddComponent.prototype.addPerfilSearch = function () {
        var types = this.types.map(function (c) {
            return new FormControl(false);
        });
        this.form.get('propertie_search')
            .push(this.fb.group({
            bedroom: [0, []],
            bathroom: [0, []],
            suite: [0, []],
            vacancy: [0, []],
            area_total: [0, []],
            area_useful: [0, []],
            subtypes: new FormArray([]),
            types: new FormArray(types),
            price: ['', []],
            finality: ['', []],
            location: this.fb.group({
                estate: ['', []],
                city: ['', []],
                neighborhood: ['', []],
            })
        }));
        var index = this.form.get('propertie_search').length - 1;
        this.subscribePropertiesSearch();
    };
    ContactsAddComponent.prototype.subscribePropertiesSearch = function () {
        var _this = this;
        this.destroy$.next();
        var _loop_1 = function (i) {
            var index = i;
            this_1.form.get('propertie_search.' + index)
                .valueChanges
                .pipe(distinctUntilChanged(function (a, b) { return JSON.stringify(a) === JSON.stringify(b); }))
                .pipe(takeUntil(this_1.destroy$))
                .subscribe(function (property_search) {
                var city = property_search.location.city;
                var estate = property_search.location.estate;
                var neighborhood = property_search.location.neighborhood;
                var location_required = !Helpers.empty(neighborhood) || (Helpers.empty(city) && !Helpers.empty(estate)) || (!Helpers.empty(city) && Helpers.empty(estate));
                location_required = location_required || !Helpers.empty(Helpers.filterObject(property_search));
                if (location_required) {
                    _this.form.get('propertie_search.' + index + '.location.estate').setValidators([Validators.required]);
                    _this.form.get('propertie_search.' + index + '.location.estate').updateValueAndValidity();
                    _this.form.get('propertie_search.' + index + '.location.city').setValidators([Validators.required]);
                    _this.form.get('propertie_search.' + index + '.location.city').updateValueAndValidity();
                }
                else {
                    _this.form.get('propertie_search.' + index + '.location.estate').setValidators([]);
                    _this.form.get('propertie_search.' + index + '.location.estate').updateValueAndValidity();
                    _this.form.get('propertie_search.' + index + '.location.city').setValidators([]);
                    _this.form.get('propertie_search.' + index + '.location.city').updateValueAndValidity();
                }
            });
        };
        var this_1 = this;
        for (var i = 0; i < this.form.get('propertie_search').length; i++) {
            _loop_1(i);
        }
    };
    /**
     * Define Status
     * @param status
     */
    ContactsAddComponent.prototype.setType = function (type) {
        this.contact.type = type.id;
        this.form.controls.type.setValue(type.id);
        if (this.contact.type === 3 || this.contact.type === 8 || this.contact.type === 1) {
            this.displayForm = true;
        }
        else {
            this.displayForm = false;
        }
    };
    /**
     * Define Etapa
     * @param status
     */
    ContactsAddComponent.prototype.setFunnelStep = function (funnel_step) {
        this.contact.funnel_step = funnel_step.id;
        this.form.controls.funnel_step.setValue(funnel_step.id);
    };
    /**
     * Alerta que existe imóvel duplicado
     * @param {Contacts} contact
     * @param {string} type
     */
    ContactsAddComponent.prototype.setContactDuplicate = function (contact, type) {
        if (!!contact) {
            if (this.contact.id === contact.id) {
                return;
            }
            this.contactDuplicate = contact;
            this.contactDuplicateType = type;
            this.modalContactDuplicateService.open('user-duplicate');
        }
    };
    ContactsAddComponent.prototype.useOtherContact = function (contact) {
        if (this.contactDuplicateType === 'email') {
            this.form.get('email').setValue('');
        }
        else {
            for (var x in contact.phones) {
                for (var i = 0; i < this.form.get('phones').length; i++) {
                    var number = this.form.get('phones').at(i).get('number').value;
                    if (contact.phones[x].number.toString() === number) {
                        this.form.get('phones').at(i).get('number').setValue('');
                    }
                }
            }
        }
    };
    ContactsAddComponent.prototype.useThisContact = function (contact) {
        // this.contact.contact_id = contact.id;
        // this.contact.contact = contact;
    };
    /**
     * Salva Contato
     */
    ContactsAddComponent.prototype.save = function () {
        var _this = this;
        if (this.saving) {
            return false;
        }
        this.saving = true;
        this.formSubmitAttempt = true;
        var contact = __assign({}, this.form.value);
        /**
         * Telefones
         */
        var phones = [];
        for (var x in contact.phones) {
            if (!Helpers.empty(contact.phones[x].number)) {
                phones.push(contact.phones[x]);
            }
        }
        contact.phones = phones;
        /**
         * Telefones
         */
        var propertie_search = [];
        if (this.contact.type === 3 || this.contact.type === 8) {
            for (var x in contact.propertie_search) {
                var item = contact.propertie_search[x];
                if (!Helpers.empty(Helpers.filterObject(item))) {
                    item.types = item.types
                        .map(function (v, i) { return v ? _this.types[i].id : null; })
                        .filter(function (v) { return v !== null; });
                    // Finalidade Venda
                    if (this.contact.type === 3) {
                        item.finality = 0;
                    }
                    // Finalidade Locação
                    if (this.contact.type === 8) {
                        item.finality = 1;
                    }
                    // item.price = item.price;
                    propertie_search.push(item);
                }
            }
        }
        contact.propertie_search = propertie_search;
        /**
         * Subtipos
         */
        contact.realtors_managers = this.form.value.realtors_managers
            .map(function (v, i) { return v ? _this.users_admin[i].id : null; })
            .filter(function (v) { return v !== null; });
        var el = document.getElementsByClassName('error-input')[0];
        if (!!el) {
            el.scrollIntoView();
        }
        /**
         * Formulário com erros
         */
        if (!this.form.valid) {
            PlaySound.play('error');
            this.toastr.error('Preencha os campos corretamente.');
            this.saving = false;
            return false;
        }
        if (this.id) {
            this.contactsService
                .update(this.id, contact)
                .subscribe(function (data) {
                _this.toastr.success('Contato editado com sucesso!');
                _this.router.navigateByUrl('/contacts/list');
                _this.saving = false;
            });
        }
        else {
            this.contactsService
                .create(contact)
                .subscribe(function (data) {
                _this.toastr.success('Contato adicionado com sucesso!');
                _this.router.navigate(['/contacts/view', data.id]);
                _this.saving = false;
            }, function (err) {
                PlaySound.play('error');
                _this.toastr.error(err.message);
                _this.saving = false;
            });
        }
    };
    ContactsAddComponent.prototype.backUrl = function () {
        if (RouterHistoric.urlContain('/contacts/')) {
            this.location.back();
        }
        else {
            this.router.navigateByUrl('/contacts/list');
        }
    };
    // Destroy
    ContactsAddComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
    };
    return ContactsAddComponent;
}());
export { ContactsAddComponent };
