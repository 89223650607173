import {Injectable} from '@angular/core';
import {CrudManager} from '../../services/crud-manager';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RealEstateService extends CrudManager {
    path = '/admin/realestate';

    constructor() {
        super();
    }

    /**
     * Retorna próprioa imobiliária
     * @returns {Observable<any>}
     */
    getMe() {
        return this.getAny('me');
    }

    /**
     * Retorna próprioa imobiliária
     * @returns {Observable<any>}
     */
    updateMe(data: any) {
        return this.updateAny('me', data);
    }

}

