<div class="app-users autocomplete" (clickOutside)="setFocus(false)">
	<input type="text"
		   (focus)="setFocus(true)"
		   [(ngModel)]="textModel"
		   (input)="changeLocationInput($event)">

	<div class="app-users--loading-icon" *ngIf="loading">
		<i class="fas fa-spinner"></i>
	</div>

	<ul class="autocomplete-list" *ngIf="showAutocomplete">
		<li class="title-list" *ngIf="locations.neighborhood.length">Bairros</li>
		<li class="location-row" [class.active]="selectNumber == index"
			(click)="setLocation(location)"
			*ngFor="let location of locations.neighborhood; let index = index">

			<div class="table">
				<div class="td text-muted td-min align-top">
					<div class="location">
						<i class="fas fa-map-marker-alt"></i>
					</div>
				</div>
				<div class="td text-muted pl-2">
					<div class="location">
						<b>{{location.neighborhood}}</b><br>
						{{location.city}} - {{location.estate}}
					</div>
				</div>
			</div>

		</li>

		<li class="title-list" *ngIf="locations.city.length">Cidades</li>
		<li class="location-row" [class.active]="selectNumber == locations.neighborhood.length + index"
			(click)="setLocation(location)"
			*ngFor="let location of locations.city; let index = index">

			<div class="table">
				<div class="td text-muted td-min align-top">
					<div class="location">
						<i class="fas fa-map-marker-alt"></i>
					</div>
				</div>
				<div class="td text-muted pl-2">
					<div class="location">
						<b>{{location.city}}</b><br>
						{{location.estate}}
					</div>
				</div>
			</div>

		</li>

		<li class="notfound" *ngIf="!locations.neighborhood.length && locations.city.length">
			Nenhum localização encontrado.
		</li>
	</ul>
</div>

<div *ngIf="selectedsUser">
	<div class="table locations-selected-row" *ngFor="let location of value">
		<div class="td td-min">
			<div class="location">
				<i class="fas fa-map-marker-alt"></i>
			</div>
		</div>
		<div class="td td-overflow-text pl-2">
			<div class="location" *ngIf="location.neighborhood">
				<b>{{location.neighborhood}}</b><br>
				{{location.city}} - {{location.estate}}
			</div>
			<div class="location" *ngIf="!location.neighborhood">
				<b>{{location.city}}</b><br>
				{{location.estate}}
			</div>
		</div>
		<div class="td text-primary font-weight-bold remove cursor-pointer" (click)="removeSelectLocation(location)">
			<i class="fas fa-times"></i>
		</div>
	</div>
</div>
