import { Injectable } from '@angular/core';
import {CrudManager} from '../../services/crud-manager';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {isArray} from "util";

@Injectable({
  providedIn: 'root'
})
export class ContactsService extends CrudManager {
    path = '/admin/contacts';

    constructor() {
        super();
    }

    processFilters(data = {}) {
        let params = {...data};

        // Converter tipos
        if (params['type_in']) {
            if (!isArray(params['type_in'])) {
                params['type_in'] = [ params['type_in'] ];
            }

            let type_in = [];
            for (let i in params['type_in']) {
                type_in.push(parseInt(params['type_in'][i]));
            }
            params['type_in'] = type_in;
        }

        // Converter users
        if (params['realtors_managers_in']) {
            if (!isArray(params['realtors_managers_in'])) {
                params['realtors_managers_in'] = [ params['realtors_managers_in'] ];
            }

            let realtors_managers_in = [];
            for (let i in params['realtors_managers_in']) {
                realtors_managers_in.push(parseInt(params['realtors_managers_in'][i]));
            }
            params['realtors_managers_in'] = realtors_managers_in;
        }

        return params;
    }

    /**
     * Favorita Contato(s)
     * @param body
     * @returns {Observable<any>}
     */
    favorite(body) {
        return this.http
            .post(environment.API + this.path + '/favorite', body)
            .pipe(map(data => this.responseTreatment.pipe(data)));
    }

    /**
     * Arquivar Contato(s)
     * @param body
     * @returns {Observable<any>}
     */
    archive(body) {
        return this.http
            .post(environment.API + this.path + '/archive', body)
            .pipe(map(data => this.responseTreatment.pipe(data)));
    }
}
