import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CalendarSharedService {
	count = 0;
	private messageSource = new BehaviorSubject(0);
	event = this.messageSource.asObservable();

	constructor() { }

	refresh() {
		this.count++;
		this.messageSource.next(this.count);
	}

}
