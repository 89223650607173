import { ToastrService } from 'ngx-toastr';
import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Users} from '../../interfaces/users';
import {Helpers} from '../../../services/helpers';
import {UsersService} from '../../models/users.service';
import {skip} from 'rxjs/operators';

@Component({
	selector: 'app-users-list',
	templateUrl: './users-list.component.html',
	styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

	@ViewChild('topProperties') elTopProperties: ElementRef;

	users: Users[];
	users_count: number;

	perPage = 20;
	currentPage = 1;

	// Em processo de busca de imóveis
	searching = false;

	// Filtros
	filters: FormGroup;

	// Subs
	sub: any;

	actions = [
		{
			id: 'add',
			body: '<i class="fas fa-plus"></i>'
		}
	];

	delete_modal: boolean;
	user_id_delete: number;

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private changeDetectorRef: ChangeDetectorRef,
		private usersService: UsersService,
		private toastrService: ToastrService
	) {
	}

	ngOnInit() {

		this.users = this.route.snapshot.data.users.results;
		this.users_count = this.route.snapshot.data.users.count;

		this.configureFilters(this.route.snapshot.queryParams);

		this.sub = this.route.queryParams
			.pipe(skip(1))
			.subscribe(data => {
				this.configureFilters(this.route.snapshot.queryParams);
				this.loadUsers(this.route.snapshot.queryParams);
			});
	}

	loadUsers(data) {
		this.searching = true;

		return this.usersService
			.search(data)
			.subscribe(users => {
				this.users = [];
				this.elTopProperties.nativeElement.scrollTop = 0;
				this.changeDetectorRef.detectChanges();

				this.users = users.results;
				this.users_count = users.count;
				this.searching = false;
			});
	}

	setAction(action: any) {
		if (action.id === 'add') {
			this.router.navigate(['/users/add']);
		}
	}

	updateParams(newSearch = true) {

		// Reset página
		if (newSearch) {
			this.filters.controls.offset.setValue(undefined);
			this.currentPage = 1;
		}

		this.router.navigate([], {
			queryParams: Helpers.filterArray(this.getFilters())
		});

	}


	/**
	 * Configura os filtros inicialmente, vindo da URL
	 * @param data
	 */
	configureFilters(data?: any) {

		this.filters = this.fb.group({
			offset: [data.offset || '', []],
			sort: [data.sort || '', []]
		});
	}

	/**
	 * Faz o tratamento dos filtros antes da busca
	 * @returns {any}
	 */
	getFilters() {
		let filters = this.filters.value;

		return filters;
	}

	/**
	 * Trocar página
	 * @param {number} page
	 */
	setPage(page: number) {
		page = page - 1;

		console.log(page);

		this.filters.controls.offset.setValue(page * this.perPage);

		this.updateParams(false);
	}


	setDeleteUser(user_id) {
		this.user_id_delete = user_id;
		this.delete_modal = true;
	}

	deleteUser() {
		this.usersService.delete(this.user_id_delete).subscribe(data => {
			this.toastrService.success('Corretor deletado com sucesso!');

			for (let i = 0; i < this.users.length; i++) {
				const element = this.users[i];
				if (this.users[i].id === this.user_id_delete) {
					this.users.splice(i, 1);
				}
			}

		}, err => {
			this.toastrService.error(err.message);
		});
	}
}
