import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscribable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {ResponseTreatment} from '../../services/response-treatment';
import {Users} from '../interfaces/users';
import {CrudManager} from '../../services/crud-manager';

@Injectable({
	providedIn: 'root'
})
export class UsersService extends CrudManager {
	path = '/admin/accounts';

	constructor() {
		super();
	}

	updateMe(data: any) {
		return this.updateAny('me', data);
	}
}
