<div class="modal-screen" *ngIf="openModal" [@EnterLeave]="openModal">
	<div class="modal-body pd">
		<div class="i-close inside" (click)="close()">
			<i class="fa fa-times" aria-hidden="true"></i>
		</div>

		<div class="title">
			{{ title }}
		</div>

		<div class="modal-box-text">
			<ng-content></ng-content>
		</div>

		<div class="text-center">
			<span class="btn-container">
				<button class="btn btn-main" (click)="close()">
					Cancelar
				</button>
			</span>

			<span class="pr-1 pl-1"></span>

			<span class="btn-container" (click)="confirmDelete()">
				<button class="btn btn-danger">
					Deletar
				</button>
			</span>
		</div>
	</div>
</div>
