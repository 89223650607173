import { ActivatedRoute } from '@angular/router';
import { LocationCitiesService } from './../../../../models/location-cities.service';
import { PlaySound } from './../../../../../services/PlaySound';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationNeighborhoodsService } from 'src/app/content/models/location-neighborhoods.service';
import { ToastrService } from 'ngx-toastr';
import { Global } from 'src/app/services/global';
import { Route } from '@angular/compiler/src/core';
import { Location } from '@angular/common';

@Component({
	selector: 'app-city-add',
	templateUrl: './city-add.component.html',
	styleUrls: ['./city-add.component.scss'],
})
export class CityAddComponent implements OnInit {
	form: FormGroup;
	formSubmitAttempt = false;
	estates: any;

	constructor(
		private fb: FormBuilder,
		private _cityEqService: LocationCitiesService,
		private toastr: ToastrService,
		private route: ActivatedRoute,
		private location: Location
	) {

	}

	ngOnInit() {
		this.form = this.fb.group({
			estate_id: ['', [Validators.required]],
			name: ['', [Validators.required]],
		});

		if (!!this.route.snapshot.queryParams.estate) {
			this.form.get('estate_id').setValue(this.route.snapshot.queryParams.estate);
		}

		this.estates = Global.info.estates;
	}

	// getCity(estate: any) {
	// 	this.form.get('estate_id').setValue(estate.id);
	// }

	save(addNew?: boolean) {
		let data = this.form.value;
		this.formSubmitAttempt = true;

		if (!this.form.valid) {
			PlaySound.play('error');
			this.toastr.error('Preencha os campos corretamente.');
			return false;
		}

		return this._cityEqService.create(this.form.value).subscribe(res => {
			this.toastr.success('Cidade criada com sucesso');
			this.backUrl();
		}, err => {
			if(err.message) {
				alert(err.message);
			}
		});
	}

	/**
	 * Verifica se campo é valido
	 * @param {string} field
	 * @returns {boolean}
	 */
	isFieldValid(field: string) {
		return !this.form.get(field).valid && this.formSubmitAttempt;
	}

	backUrl() {
		this.location.back();
	}
}
