export class WebserverResponseTypes {
	static NEW_POST = 'new_post';
	static NEW_TICKET = 'new_ticket';
	static NEW_TICKET_MANY = 'new_ticket_many';
	static UPDATE_TICKET = 'update_ticket';
	static USER_TYPING = 'user_typing';
	static USER_VIEW_TICKET = 'user_view_ticket';
	static BADGES_CHANGE = 'badges_change';
}

