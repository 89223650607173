import {Directive, ElementRef, HostListener, Input, OnInit, Renderer} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Helpers } from '../../../services/helpers';

@Directive({
	selector: '[appMaskPhone]',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: MaskPhoneDirective,
		multi: true
	}]
})
export class MaskPhoneDirective implements OnInit {

	onTouched: any = () => { };
	onChange: any = () => { };

	constructor(
		private el: ElementRef,
		private _renderer: Renderer) {
	}

	ngOnInit() {
		this._renderer.setElementAttribute(this.el.nativeElement, 'autocomplete', 'off');
		this._renderer.setElementAttribute(this.el.nativeElement, 'autocorrect', 'off');
		this._renderer.setElementAttribute(this.el.nativeElement, 'autocapitalize', 'off');
		this._renderer.setElementAttribute(this.el.nativeElement, 'spellcheck', 'false');
	}

	tratament(value) {
		if (!value) {
			value = '';
		}

		let newVal = value.replace(/\D/g, '');

		if (newVal.length >= 12) {
			if (newVal[0] == '5' && newVal[1] == '5') {
				newVal = newVal.substring(2);
			}
		}

		if (newVal.length === 0) {
			newVal = '';
		} else if (newVal.length <= 3) {
			if (newVal.length === 3) {
				newVal = newVal.replace(/^(\d{0,2})(\d)/, '($1) $2');
			}
		} else if (newVal.length <= 6) {
			newVal = newVal.replace(/^(\d{0,2})(\d)/, '($1) $2');
		} else if (newVal.length <= 10) {
			newVal = newVal.replace(/^(\d{0,2})(\d{0,4})(\d{0,4})/, '($1) $2-$3');
		} else {
			newVal = newVal.substring(0, 11);
			newVal = newVal.replace(/^(\d{0,2})(\d{0,5})(\d{0,4})/, '($1) $2-$3');
		}

		this.el.nativeElement.value = newVal;
		this.onChange(Helpers.onlyNumbers(newVal));

		setTimeout(() => {
			this.el.nativeElement.selectionStart = newVal.length;
			this.el.nativeElement.selectionEnd = newVal.length;
		}, 1);

		this.el.nativeElement.selectionStart = newVal.length;
		this.el.nativeElement.selectionEnd = newVal.length;
	}

	writeValue(value: any): void {
		this.tratament(value);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	@HostListener('input', ['$event'])
	onInput($event: any) {
		this.tratament(this.el.nativeElement.value);
	}

}
