var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { LocationNeighborhoodsService } from './../models/location-neighborhoods.service';
var EquivalenceNeighborhoodResolverService = /** @class */ (function () {
    function EquivalenceNeighborhoodResolverService(_neighService) {
        this._neighService = _neighService;
    }
    EquivalenceNeighborhoodResolverService.prototype.resolve = function (route) {
        var params = __assign({}, route.queryParams);
        return this._neighService.search(params);
    };
    return EquivalenceNeighborhoodResolverService;
}());
export { EquivalenceNeighborhoodResolverService };
