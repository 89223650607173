import { CrudManager } from './../../services/crud-manager';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class AutomaticMessageService extends CrudManager {
	path = '/admin/tickets/automatic-messages';

	constructor() {
		super();
	}

}
