import {HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';

export class ResponseTreatment {
    pipe(data) {
        return data.body;
    }

    handleError(error: HttpErrorResponse): any {
        if (error instanceof ErrorEvent) {
            return throwError(error.error);
        } else {
            console.error(
                `O servidor está com o erro ${error.status}, ` +
                `seu body foi: ${error.error.message}`
            );

            if (!!error.error.message) {
                return throwError(error.error);
            } else {
                error.error.message = 'Ocorreu um erro no servidor';

                return throwError(error.error);
            }
        }
    }
}
