<div class="page-flex">

	<div class="flex-header">
		<app-nav-bar [title]="'Configurar Design'">
		</app-nav-bar>
	</div>

	<div class="flex-body">
		<div class="max-width">
			<div class="card p-4">
				<div class="row">
					<div class="col-md-12">
						<div class="title-form">
							Logo da imobiliaria
							<small>Logo da sua imobiliaria que irá ficar no seu site.</small>
						</div>

						<div class="text-center">

							<div class="img-border logo-realestate position-relative">

								<img [src]="realestate.logo">

								<div class="loading" *ngIf="loadingLogo">
									<div class="loading-icon">
										<div class="load">
											<div></div>
										</div>
									</div>
								</div>

							</div>

							<small>Tamanho máximo da imagem é 360x250, caso seja enviado uma imagem maior ela será redimensionada.</small>
							<br>

							<div class="btn btn-round btn-primary position-relative" ngFileDrop [options]="optionsUpload" (uploadOutput)="onUploadOutput($event, 'realestate_logo')" [uploadInput]="uploadInput">
								<input type="file" ngFileSelect [options]="optionsUpload" (uploadOutput)="onUploadOutput($event, 'realestate_logo')" [uploadInput]="uploadInput">
								<i class="fas fa-camera"></i> Mudar
							</div>

						</div>

						<hr><br>

						<div class="title-form">
							Imagem de fundo
							<small>Imagem de fundo que irá ficar no seu site.</small>
						</div>

						<div class="text-center">

							<div class="img-border background-image-realestate position-relative">
								<img [src]="realestate.background">

								<div class="loading">
									<div class="loading-icon" *ngIf="loadingBackground">
										<div class="load">
											<div></div>
										</div>
									</div>
								</div>
							</div>


							<small>Tamanho máximo da imagem é 1920x1080, caso seja enviado uma imagem maior ela será redimensionada.</small>
							<br>
							<div class="btn btn-round btn-primary position-relative" ngFileDrop [options]="optionsUpload" (uploadOutput)="onUploadOutput($event, 'realestate_background')"
								[uploadInput]="uploadInput">
								<input type="file" ngFileSelect [options]="optionsUpload" (uploadOutput)="onUploadOutput($event, 'realestate_background')"
								[uploadInput]="uploadInput">
								<i class="fas fa-camera"></i> Mudar
							</div>
						</div>

						<hr><br>

						<div class="title-form">
							Informações e configurações do modelo
							<small>Imagem de fundo que irá ficar no seu site.</small>
						</div>


						<form [formGroup]="form">
							<!--<div class="row">-->
								<!--<div class="col-md-4">-->
									<!--<div class="field-container">-->
										<!--<input type="text" formControlName="COLOR_PRIMARY" [(colorPicker)]="form.get('COLOR_PRIMARY').value" [value]="form.get('COLOR_PRIMARY').value">-->
										<!--<div class="name">Cor Primária</div>-->
									<!--</div>-->
								<!--</div>-->

								<!--<div class="col-md-4">-->
									<!--<div class="field-container">-->
										<!--<input type="text" formControlName="COLOR_SECUNDARY" [(colorPicker)]="form.get('COLOR_SECUNDARY').value" [value]="form.get('COLOR_SECUNDARY').value">-->
										<!--<div class="name">Cor Secundária</div>-->
									<!--</div>-->
								<!--</div>-->
							<!--</div>-->

							<div class="row">
								<div class="col-md-8">
									<div class="field-container">
										<input type="text" formControlName="SLOGAN">
										<div class="name">Frase</div>
									</div>
								</div>
							</div>

						</form>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="flex-footer">
		<div class="actions-bottom">
			<!--<button class="btn btn-light" (click)="save()" type="button" *ngIf="">-->
			<!--Salvar e voltar-->
			<!--</button>-->
			<button class="btn btn-primary" (click)="save()" type="button">
				Salvar
			</button>
		</div>
	</div>
</div>
