<div class="page-flex no-over">

	<div class="flex-header">
		<app-nav-bar title="Localizações Não Encontradas">
		</app-nav-bar>
	</div>

	<div class="loading" *ngIf="searching">
		<div class="loading-icon">
			<div class="load">
				<div></div>
			</div>
		</div>
	</div>

	<div class="flex-body" #topProperties>
		<div class="equivalence-list">

			<div class="max-width">

				<div class="header-search">

					<h1 class="title">
						<div class="row">
							<div class="col">
								<i class="fas fa-key"></i> &nbsp; Localizações não encontradas
							</div>
						</div>
					</h1>

					<div class="tabs">
						<div class="tab active">
							Todos ({{locations_count}})
						</div>
					</div>

					<div class="row m-0 pt-3">
						<div class="col m-0 p-0">
							<form [formGroup]="filters" (change)="updateParams()" class="d-inline-block">
								<input type="text" formControlName="textual_search" class="input"
									placeholder="Buscar localização...">
							</form>
						</div>
						<div class="col text-right m-0 p-0">
							<form [formGroup]="filters" (change)="updateParams()" class="d-inline-block">
								<label class="select-container no-name">
									<select formControlName="sort">
										<option [value]="''">Ordernar por</option>
										<option [value]="'-error_count'">N° de ocorrências</option>
										<option [value]="'neighborhood'">Alfabética - Bairro</option>
										<option [value]="'city'">Alfabética - Cidade</option>
										<option [value]="'estate'">Alfabética - Estado</option>
									</select>
								</label>
							</form>
						</div>
					</div>
				</div>

				<div class="row">

				</div>

				<div class="not-found text-center" *ngIf="!locations.length">

					<div class="icon">
						<i class="fas fa-key"></i>
					</div>

					<h3>Nenhuma localização encontrada.</h3>

				</div>

				<table class="w-100 list-table hide-mobile" *ngIf="locations.length">
					<tr class="header-table">
						<td class="align-middle" (click)="sort('cod')">
							<div class="text-nowrap font-weight-bold">
								Bairro
								<i class="fas fa-angle-down" *ngIf="filters.controls.sort.value == 'cod'"></i>
								<i class="fas fa-angle-up" *ngIf="filters.controls.sort.value == '-cod'"></i>
							</div>
						</td>
						<td class="align-middle" (click)="sort('name')">
							<div class="text-nowrap font-weight-bold">
								Cidade
								<i class="fas fa-angle-down" *ngIf="filters.controls.sort.value == 'name'"></i>
								<i class="fas fa-angle-up" *ngIf="filters.controls.sort.value == '-name'"></i>
							</div>
						</td>
						<td class="align-middle" (click)="sort('name')">
							<div class="text-nowrap font-weight-bold">
								Estado
								<i class="fas fa-angle-down" *ngIf="filters.controls.sort.value == 'name'"></i>
								<i class="fas fa-angle-up" *ngIf="filters.controls.sort.value == '-name'"></i>
							</div>
						</td>
						<td></td>
					</tr>

					<tr class="list-row" *ngFor="let location of locations">
						<td class="td-overflow-text">
							<div class="text-nowrap font-weight-bold" [class.text-danger]="location.error_count">
								{{ location.neighborhood }} ({{ location.error_count }})
							</div>
						</td>
						<td class="td-overflow-text">
							<div class="text-nowrap">
								{{ location.city }}
							</div>
						</td>
						<td class="td-overflow-text">
							<div class="text-nowrap">
								{{ location.estate }}
							</div>
						</td>
						<td class="align-middle td-min">
							<div class="text-nowrap">

								<div class="icon" [routerLink]="['/properties/list']" [queryParams]="{
								estate: location.estate,
								city: location.city,
								neighborhood: location.neighborhood
							}">
									<i class="fas fa-home"></i>
								</div>

								<div class="icon" (click)="openModal(location)">
									<i class="fas fa-plus"></i>
								</div>

								<div class="icon" (click)="setItemDelete(location.id)">
									<i class="far fa-trash-alt"></i>
								</div>

							</div>
						</td>
					</tr>


				</table>

				<table class="w-100 list-table show-mobile" *ngIf="locations.length">
					<tr class="header-table">
						<td class="align-middle">
						</td>
						<td class="align-middle">
						</td>
					</tr>

					<tr class="list-row" *ngFor="let location of locations">
						<td class="td td-overflow-text">
							<span class="text-nowrap font-weight-bold" [class.text-danger]="location.error_count">
								{{ location.neighborhood }} ({{ location.error_count }})
							</span>
							<div class="text-nowrap">
								{{ location.city }} - {{ location.estate }}
							</div>
						</td>
						<td class="td align-middle td-min">
							<div class="text-nowrap">

								<div class="icon" [routerLink]="['/properties/list']" [queryParams]="{
								estate: location.estate,
								city: location.city,
								neighborhood: location.neighborhood
							}">
									<i class="fas fa-home"></i>
								</div>

								<div class="icon" (click)="openModal(location)">
									<i class="fas fa-plus"></i>
								</div>

								<div class="icon">
									<i class="far fa-trash-alt"></i>
								</div>

							</div>
						</td>
					</tr>


				</table>

				<div class="pt-4">
					<app-pagination [total]="locations_count" [perPage]="perPage" (changePage)="setPage($event)"
						[(currentPage)]="currentPage">
					</app-pagination>
				</div>
			</div>
		</div>
	</div>
</div>


<app-modal [(openModal)]="modalStatus" title="DESEJA ADICIONAR ESSA LOCALIZAÇÃO COMO...">

	<div class="text-center">
		<div class="btn-group pb-3" *ngIf="!!currentLocation">
			<div class="btn btn-light" [routerLink]="['/equivalence/city/add']"
				[queryParams]="{estate: currentLocation.estate}">
				Cidade
			</div>

			<div class="btn btn-light" [routerLink]="['/equivalence/neighborhood/add']"
				[queryParams]="{neighborhood: currentLocation.neighborhood}">
				Bairro
			</div>

			<div class="btn btn-light" [routerLink]="['/equivalence/neighborhood-error/add']"
				[queryParams]="{neighborhood: currentLocation.neighborhood}">
				Bairro(Erro)
			</div>

			<div class="btn btn-light" [routerLink]="['/equivalence/sub-neighborhood/add']"
				[queryParams]="{neighborhood: currentLocation.neighborhood}">
				Subbairro
			</div>

			<div class="btn btn-light" [routerLink]="['/equivalence/sub-neighborhood-error/add']"
				[queryParams]="{subneighborhood: currentLocation.neighborhood}">
				Subbairro(Erro )
			</div>
		</div>
	</div>

	<div class="text-center">
		<div class="btn btn-primary" (click)="modalStatus = false">
			Cancelar
		</div>
	</div>
</app-modal>

<app-modal-delete title="Deletar Localização"
[openModal]="delete_modal"
(confirm)="deleteConfirm()">
Você deseja deletar essa Localização?</app-modal-delete>
