import {Injectable} from '@angular/core';
import {ResponseTreatment} from '../../services/response-treatment';
import {Users} from '../interfaces/users';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class NotificationsService {

	responseTreatment = new ResponseTreatment;

	constructor(private http: HttpClient) {
	}

	/**
	 * Badges
	 * @param data
	 * @returns {Observable<Object>}
	 */
	badges(): Observable<Users> {
		return this.http
			.get(environment.API + '/admin/notifications/badges')
			.pipe(map(
				res => this.responseTreatment.pipe(res)
			));
	}
}
