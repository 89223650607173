var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AgendaService } from '../../../models/agenda.service';
import { PlaySound } from '../../../../services/PlaySound';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { RouterHistoric } from '../../../shareds/router-historic.service';
import { Helpers } from '../../../../services/helpers';
import { CalendarSharedService } from '../../../shareds/calendar.service';
import { Location } from '@angular/common';
import { TitleService } from 'src/app/services/title.service';
var CalendarAddComponent = /** @class */ (function () {
    function CalendarAddComponent(route, fb, location, router, toastr, agendaService, changeDetectorRef, calendarSharedService, _titleService) {
        this.route = route;
        this.fb = fb;
        this.location = location;
        this.router = router;
        this.toastr = toastr;
        this.agendaService = agendaService;
        this.changeDetectorRef = changeDetectorRef;
        this.calendarSharedService = calendarSharedService;
        this._titleService = _titleService;
        this.saved = new EventEmitter();
        this.formSubmitAttempt = false;
        this.openModal = false;
        this.type = '';
        this.id = 0;
    }
    CalendarAddComponent.prototype.ngOnInit = function () {
        var date = moment(this.route.snapshot.queryParams.day + '000000', 'YYYYMMDDHHmmss').isValid();
        this.form = this.fb.group({
            name: ['', [Validators.required]],
            type: ['', []],
            all_day: [date, []],
            realized: [0, []],
            start_at: [date ? this.route.snapshot.queryParams.day + '000000' : '', [Validators.required]],
            shared_users: [[], []],
            description: ['', []],
        });
        this.loadCommitment(this.route.snapshot.data.commitments);
    };
    CalendarAddComponent.prototype.loadCommitment = function (data) {
        if (Helpers.empty(data)) {
            return false;
        }
        this.id = Number(data.id);
        this._titleService.setEditTitle(data.name);
        this.form.get('name').setValue(data.name);
        this.form.get('type').setValue(data.type);
        this.form.get('all_day').setValue(data.all_day);
        this.form.get('start_at').setValue(data.start_at);
        this.form.get('realized').setValue(data.realized);
        this.form.get('shared_users').setValue(data.shared_users_data);
        this.form.get('description').setValue(data.description);
    };
    CalendarAddComponent.prototype.clean = function () {
    };
    /**
     * Define tipo do evento
     * @param {string} type
     */
    CalendarAddComponent.prototype.setType = function (type) {
        this.type = type;
        this.form.get('type').setValue(type);
    };
    CalendarAddComponent.prototype.allDay = function (event) {
        console.log(event);
        this.form.get('all_day').setValue(event);
    };
    /**
     * Verifica se campo é valido
     * @param {string} field
     * @returns {boolean}
     */
    CalendarAddComponent.prototype.isFieldValid = function (field) {
        return (!this.form.get(field).valid && this.formSubmitAttempt);
    };
    CalendarAddComponent.prototype.close = function () {
        // Se url antiga for visualização de dia
        // alert(RouterHistoric.urlContain('/calendar/day'));
        if (RouterHistoric.urlContain('/calendar/day')) {
            this.location.back();
        }
        else {
            this.router.navigateByUrl('/calendar');
        }
    };
    CalendarAddComponent.prototype.save = function () {
        var _this = this;
        this.formSubmitAttempt = false;
        /**
         * Formulário com erros
         */
        if (!this.form.valid) {
            this.formSubmitAttempt = true;
            PlaySound.play('error');
            this.toastr.error('Preencha os campos corretamente.');
            this.changeDetectorRef.detectChanges();
            var el = document.getElementsByClassName('error-input')[0];
            if (!!el) {
                el.scrollIntoView();
            }
            return false;
        }
        var data = __assign({}, this.form.value);
        data.all_day = data.all_day ? 1 : 0;
        data.realized = data.realized ? 1 : 0;
        if (!this.id) {
            return this.agendaService
                .create(data)
                .subscribe(function (resp) {
                _this.toastr.success('Evento adicionado com sucesso!');
                _this.close();
                _this.calendarSharedService.refresh();
            }, function (err) {
                _this.toastr.error('Erro ao criar evento.');
            });
        }
        else {
            return this.agendaService
                .update(this.id, data)
                .subscribe(function (resp) {
                _this.toastr.success('Evento atualizado com sucesso!');
                _this.close();
                _this.calendarSharedService.refresh();
            }, function (err) {
                _this.toastr.error('Erro ao atualizar evento.');
            });
        }
    };
    return CalendarAddComponent;
}());
export { CalendarAddComponent };
