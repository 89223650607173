var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { CrudManager } from '../../services/crud-manager';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { isArray } from "util";
import * as i0 from "@angular/core";
var ContactsService = /** @class */ (function (_super) {
    __extends(ContactsService, _super);
    function ContactsService() {
        var _this = _super.call(this) || this;
        _this.path = '/admin/contacts';
        return _this;
    }
    ContactsService.prototype.processFilters = function (data) {
        if (data === void 0) { data = {}; }
        var params = __assign({}, data);
        // Converter tipos
        if (params['type_in']) {
            if (!isArray(params['type_in'])) {
                params['type_in'] = [params['type_in']];
            }
            var type_in = [];
            for (var i in params['type_in']) {
                type_in.push(parseInt(params['type_in'][i]));
            }
            params['type_in'] = type_in;
        }
        // Converter users
        if (params['realtors_managers_in']) {
            if (!isArray(params['realtors_managers_in'])) {
                params['realtors_managers_in'] = [params['realtors_managers_in']];
            }
            var realtors_managers_in = [];
            for (var i in params['realtors_managers_in']) {
                realtors_managers_in.push(parseInt(params['realtors_managers_in'][i]));
            }
            params['realtors_managers_in'] = realtors_managers_in;
        }
        return params;
    };
    /**
     * Favorita Contato(s)
     * @param body
     * @returns {Observable<any>}
     */
    ContactsService.prototype.favorite = function (body) {
        var _this = this;
        return this.http
            .post(environment.API + this.path + '/favorite', body)
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }));
    };
    /**
     * Arquivar Contato(s)
     * @param body
     * @returns {Observable<any>}
     */
    ContactsService.prototype.archive = function (body) {
        var _this = this;
        return this.http
            .post(environment.API + this.path + '/archive', body)
            .pipe(map(function (data) { return _this.responseTreatment.pipe(data); }));
    };
    ContactsService.ngInjectableDef = i0.defineInjectable({ factory: function ContactsService_Factory() { return new ContactsService(); }, token: ContactsService, providedIn: "root" });
    return ContactsService;
}(CrudManager));
export { ContactsService };
