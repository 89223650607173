import { Injectable } from '@angular/core';
import {CrudManager} from '../../services/crud-manager';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TasksListService extends CrudManager {
	path = '/admin/tasks/lists';

	constructor() {
		super();
	}

	processFilters(data = {}) {

		let params = {...data};
		return params;
	}

	getLists() {
		return this.http
			.get(environment.API + this.path)
			.pipe(map(data => this.responseTreatment.pipe(data)));
	}

}
