<div class="drop-menu" (clickOutside)="closeDrop()" [slim]="true">
    <div class="drop-header" (click)="changeDrop()">
        <ng-content select="[id=drop-btn]">
        </ng-content>
    </div>

    <div class="drop-body"
         *ngIf="dropStatus">
        <ul class="drop-list" (click)="closeDrop()">
            <ng-content select="[id=drop-list]"></ng-content>
        </ul>
    </div>
</div>
