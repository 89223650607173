import {Directive, ElementRef, HostListener, Input, OnInit, Renderer} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Helpers } from '../../../services/helpers';

@Directive({
	selector: '[appMaskNumber]',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: MaskNumberDirective,
		multi: true
	}]
})
export class MaskNumberDirective implements OnInit {
	@Input() suffix = '';
	@Input() prefix = '';
	@Input() decimals = 2;

	onTouched: any = () => { };
	onChange: any = () => { };

	constructor(
		private el: ElementRef,
			private _renderer: Renderer) {
	}

	ngOnInit() {
		this._renderer.setElementAttribute(this.el.nativeElement, 'autocomplete', 'off');
		this._renderer.setElementAttribute(this.el.nativeElement, 'autocorrect', 'off');
		this._renderer.setElementAttribute(this.el.nativeElement, 'autocapitalize', 'off');
		this._renderer.setElementAttribute(this.el.nativeElement, 'spellcheck', 'false');
	}

	@HostListener('input', ['$event'])
	onInput($event: any) {
		this.tratament(this.el.nativeElement.value);
	}

	@HostListener('click', ['$event'])
	changes() {
		let value = this.el.nativeElement.value;

		// Nunca deixa cursor depois do suffixo
		// if (this.el.nativeElement.selectionStart >= value.length - this.suffix.length) {
		// 	this.el.nativeElement.selectionStart = value.length;
		// 	this.el.nativeElement.selectionEnd = value.length;
		// }
	}

	@HostListener('keydown', ['$event'])
	keyDown(event: KeyboardEvent) {
		// this.fixCaretPosition();
		// this.backspaceDelete(event);
	}

	fixCaretPosition() {

		this.el.nativeElement.selectionStart = 0;
		this.el.nativeElement.selectionEnd = 0;

		// let indexSuffix = this.el.nativeElement.value.indexOf(' ' + this.suffix);

		// if (!!this.suffix && this.el.nativeElement.selectionStart > indexSuffix) {
		// 	this.el.nativeElement.selectionStart = indexSuffix;
		// 	this.el.nativeElement.selectionEnd = indexSuffix;
		// }

		// let indexPrefix = this.el.nativeElement.value.indexOf(this.prefix + ' ');

		// if (!!this.prefix && this.el.nativeElement.selectionStart < indexPrefix) {
		// 	this.el.nativeElement.selectionStart = indexPrefix;
		// 	this.el.nativeElement.selectionEnd = indexPrefix;
		// }
	}

	backspaceDelete(event?: KeyboardEvent) {
		// if (event.key === 'Backspace') {
		// 	console.log('TCL: MaskNumberDirective -> backspaceDelete -> this.el.nativeElement.selectionStart', this.el.nativeElement.selectionStart);

		// 	let char = (<string>this.el.nativeElement.value).charAt(this.el.nativeElement.selectionStart);
		// 	console.log('TCL: MaskNumberDirective -> backspaceDelete -> char', char);
		// }
	}

	tratament(value: string) {

		if (value === undefined) {
			value = '';
		}

		let newValOnlyNumbers = Helpers.onlyNumbers(value);
		let newVal = (Helpers.numberFormat(Number(newValOnlyNumbers) / Math.pow(10, this.decimals), this.decimals)).toString();

		if (!!this.prefix) {
			newVal = this.prefix + newVal;
		}

		if (!!this.suffix) {
			newVal = newVal + this.suffix;
		}

		let lastLength = value.length;
		let newLength = newVal.length;

		let lastCaret = this.el.nativeElement.selectionStart;

		this.el.nativeElement.value = newVal;
		this.onChange(newValOnlyNumbers);

		/**
		 * Caret
		 */

		setTimeout(() => {
			this.el.nativeElement.selectionStart = newVal.length;
			this.el.nativeElement.selectionEnd = newVal.length;
		}, 1);

		this.el.nativeElement.selectionStart = newVal.length;
		this.el.nativeElement.selectionEnd = newVal.length;

		// if (newLength - lastLength === 0) {
		// 	this.el.nativeElement.selectionStart = lastCaret;
		// 	this.el.nativeElement.selectionEnd = lastCaret;
		// } else if (newLength - lastLength === 1) {
		// 	this.el.nativeElement.selectionStart = lastCaret + 1;
		// 	this.el.nativeElement.selectionEnd = lastCaret + 1;
		// } else if (newLength - lastLength === -1) {
		// 	this.el.nativeElement.selectionStart = lastCaret - 1;
		// 	this.el.nativeElement.selectionEnd = lastCaret - 1;
		// }
        //
		// // Nunca deixa cursor depois do suffixo
		// if (this.el.nativeElement.selectionStart >= newVal.length - this.suffix.length) {
		// 	this.el.nativeElement.selectionStart = newVal.length - this.suffix.length;
		// 	this.el.nativeElement.selectionEnd = newVal.length - this.suffix.length;
		// }
	}

	writeValue(value: any): void {
		this.tratament(value);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

}
