<div class="app-autocomplete field-container" (clickOutside)="setFocus(false)" [class.error-input]="error">
    <input type="text"
           autocomplete="asdasd"
           autocorrect="off"
           autocapitalize="off"
           spellcheck="false"
           (focus)="setFocus(true)"
           [(ngModel)]="value"
           *ngIf="!selectedLocation"
           (input)="changeLocationInput($event)"
            #input>

    <div class="user" *ngIf="selectedLocation">
        <div class="table">
            <div class="td td-overflow-text pl-2 text-primary font-weight-bold">
                <span class="location"><i class="fas fa-map-marker-alt"></i> {{selectedLocation.neighborhood}} - {{selectedLocation.city}}, {{selectedLocation.estate}}</span>
            </div>
            <div class="td text-primary font-weight-bold remove cursor-pointer" (click)="removeLocation()">
                <i class="fas fa-times"></i>
            </div>
        </div>
    </div>

    <div class="app-users--loading-icon" *ngIf="loading">
        <i class="fas fa-spinner"></i>
    </div>

    <ul class="autocomplete-list" *ngIf="showAutocomplete && !selectedLocation">
        <li [class.active]="selectNumber == index"
            (mouseenter)="selectNumber = index"
            (click)="selectLocation(location)"
            *ngFor="let location of locations; let index = index">
            <div class="table">
                <div class="td text-muted td-min">
                    <div class="location">
                        <i class="fas fa-map-marker-alt"></i>
                    </div>
                </div>
                <div class="td text-muted pl-2">
                    <div class="location">
                        <b>{{location.neighborhood}}</b><br>
                        {{location.city}}, {{location.estate}}
                    </div>
                </div>
            </div>
        </li>

        <li *ngIf="!locations.length">
            <div class="table">
                <div class="td td-overflow-text text-muted">
                    <span class="location"><i class="fas fa-map-marker-alt"></i> Nenhuma localização encontrada.</span>
                </div>
            </div>
        </li>
    </ul>
</div>
