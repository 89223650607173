/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pagination.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./pagination.component";
var styles_PaginationComponent = [i0.styles];
var RenderType_PaginationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaginationComponent, data: {} });
export { RenderType_PaginationComponent as RenderType_PaginationComponent };
function View_PaginationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "page"]], [[2, "disable", null], [2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setPage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.disable; var currVal_1 = _v.context.$implicit.active; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.number; _ck(_v, 1, 0, currVal_2); }); }
function View_PaginationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "pagination"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "page"]], [[2, "hidden", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.incrementPage((_co.currentPage - 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" < "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaginationComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "page"]], [[2, "hidden", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.incrementPage((_co.currentPage + 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" > "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.pages; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.last; _ck(_v, 1, 0, currVal_0); var currVal_2 = !_co.next; _ck(_v, 5, 0, currVal_2); }); }
export function View_PaginationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaginationComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.total_pages > 1); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PaginationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pagination", [], null, null, null, View_PaginationComponent_0, RenderType_PaginationComponent)), i1.ɵdid(1, 638976, null, 0, i3.PaginationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaginationComponentNgFactory = i1.ɵccf("app-pagination", i3.PaginationComponent, View_PaginationComponent_Host_0, { total: "total", perPage: "perPage", currentPage: "currentPage" }, { changePage: "changePage" }, []);
export { PaginationComponentNgFactory as PaginationComponentNgFactory };
