import { Pipe, PipeTransform } from '@angular/core';
import {Helpers} from '../../services/helpers';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

    transform(number: any, args?: any): any {
        if (isNaN(number)) {
            return number;
        } else {
            number = number / 100.0;
            return Helpers.numberFormat(number);
        }
    }

}
