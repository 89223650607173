import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Helpers} from '../../../services/helpers';
import {Users} from '../../interfaces/users';

/**
 * Componente para mostrar imagem ou inicial do nome do usuário
 */
@Component({
	selector: 'app-user-img',
	templateUrl: './user-img.component.html',
	styleUrls: ['./user-img.component.scss']
})
export class UserImgComponent implements OnInit, OnChanges {

	@Input() user: Users;
	@Input() size;

	image: string;
	name = 'A';

	constructor() {
	}

	ngOnInit() {
		if (!Helpers.empty(this.user)) {
			if (this.user.image) {
				this.image = this.user.image;
			} else {
				this.name = this.user.name;
			}
		}
	}

	ngOnChanges() {
		this.image = undefined;
		this.name = undefined;

		if (!Helpers.empty(this.user)) {
			if (this.user.image) {
				this.image = this.user.image;
			} else {
				this.name = this.user.name;
			}
		}
	}
}
