import { environment } from 'src/environments/environment';
import { PlaySound } from './../../../services/PlaySound';
import { Global } from './../../../services/global';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../../models/users.service';
import { humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput, UploadStatus } from 'ngx-uploader';
import {GlobalService} from '../../shareds/global.service';

@Component({
  selector: 'app-settings-account',
  templateUrl: './settings-account.component.html',
  styleUrls: ['./settings-account.component.scss']
})
export class SettingsAccountComponent implements OnInit {
	realestate: any;
	form: FormGroup;
	formSubmitAttempt: boolean;
	user: any;
	phones: FormArray;
	loadingProfile: boolean;

	// Upload
	formData: FormData;
	files: UploadFile[];
	uploadInput: EventEmitter<UploadInput>;
	humanizeBytes: Function;
	dragOver: boolean;
	optionsUpload: UploaderOptions;

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private toastr: ToastrService,
		private _globalService: GlobalService,
		private changeDetectorRef: ChangeDetectorRef,
		private _usersService: UsersService
	) {
	}

	ngOnInit() {
		// this.realestate = this.route.snapshot.data.realestate;
		this.user = Global.user;
		this.buildForm();

		// for (let i = 0; i < 2; i++) {
		// 	this.addPhone();
		// }

		// for (let i = 0; i < this.user.phones.length; i++) {
		// 	this.user.phones[i];
		// 	this.form.get('phones') as FormArray;
		// }

		this.optionsUpload = { concurrency: 1 };
		this.files = [];
		this.uploadInput = new EventEmitter<UploadInput>();
		this.humanizeBytes = humanizeBytes;
	}

	buildForm() {
		this.form = this.fb.group({
			name: [this.user.name, [Validators.required]],
			email: [this.user.email, [Validators.required, Validators.email]],
			cpf: [this.user.cpf || ''],
			creci: [this.user.creci || ''],
			current_password: [''],
			new_password: [''],
			confirm_password: [''],
			phones: this.fb.array([
				this.fb.group({
					number: this.setValuePhone(0)
				}),
				this.fb.group({
					number: this.setValuePhone(1)
				})
			])
		});
	}


	onUploadOutput(output: UploadOutput): void {

		console.log(output.type);

		if (output.type === 'allAddedToQueue') {
			let type = 'perfil_user';
			this.loadingProfile = true;


			const event: UploadInput = {
				type: 'uploadAll',
				url: environment.API + '/admin/uploads/' + type,
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + Global.token
				},
			};

			this.uploadInput.emit(event);

		} else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
			this.files.push(output.file);
		} else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
			const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
			this.files[index] = output.file;
		} else if (output.type === 'cancelled' || output.type === 'removed') {
			this.files = this.files.filter((file: UploadFile) => file !== output.file);
		} else if (output.type === 'done') {

			if (output.file.response.success) {
				this.loadingProfile = false;
				this.user.image = output.file.response.body[0].url + '?time=' + Date.now();
				Global.user.image = output.file.response.body[0].url + '?time=' + Date.now();

				// Update
				this._globalService.updateUser(Global.user);

				this.toastr.success('Foto atualizado com sucesso!');

			} else {
				PlaySound.play('error');
				this.toastr.error('Erro ao atualizar foto. Verifique a internet.');
			}

		} else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
			console.log(output.file.name + ' rejected');
		}

		this.files = this.files.filter(file => file.progress.status !== UploadStatus.Done);
	}


	setValuePhone(i): string {
		if (!!this.user.phones[i]) {
			return this.user.phones[i].number;
		} else {
			return '';
		}
	}

	// createPhone(): FormGroup {
	// 	return this.fb.group({
	// 		number: ''
	// 	});
	// }

	// addPhone(): void {
	// 	this.phones = this.form.get('phones') as FormArray;
	// 	this.phones.push(this.createPhone());
	// }

	changePassword() {
		this._usersService.updateMe({
			password: this.form.value.new_password
		}).subscribe(() => {
			this.toastr.success('Senha atualizada com sucesso!');
		})
	}

	save() {
		let data = this.form.value;
		this.formSubmitAttempt = true;

		this.form.value['phones'].forEach(element => {
			if (element['number'] === null) {
				element['number'] = '';
			}
		});

		if (!this.form.valid) {
			PlaySound.play('error');
			this.toastr.error('Preencha os campos corretamente.');
			return false;
		}

		return this._usersService
			.updateMe(data)
			.subscribe(user => {
				this._globalService.updateUser(user);
				this.toastr.success('Dados atualizados com sucesso!');
			}, err => {
				PlaySound.play('error');
				this.toastr.error(err.message);
			});
	}


	/**
     * Verifica se campo é valido
     * @param {string} field
     * @returns {boolean}
     */
	isFieldValid(field: string) {
		return (!this.form.get(field).valid && this.formSubmitAttempt);
	}

	isEmailInvalid(field: string) {
		// console.log('TCL: SettingsAccountComponent -> isEmailInvalid -> this.form.get(field)', this.form.get(field));

		if (this.form.get(field).errors !== null && this.form.get(field).errors !== undefined) {
			return (this.form.get(field).errors.email && this.formSubmitAttempt);
		} else {
			return false;
		}
	}
}
