import { OnDestroy, OnInit } from '@angular/core';
import { ModalContactDuplicateService } from './modal-contact-duplicate.service';
import { InjectorInstance } from '../../services/InjectorInstance';
import { Helpers } from '../../services/helpers';
import * as i0 from "@angular/core";
var ModalDefault = /** @class */ (function () {
    function ModalDefault() {
        this.openModal = false;
        this.id = Helpers.unique_hash();
        //
        this.modalContactDuplicateService = InjectorInstance.Injector.get(ModalContactDuplicateService);
    }
    ModalDefault.prototype.ngOnInit = function () {
        this.modalContactDuplicateService.add(this);
    };
    ModalDefault.prototype.ngOnDestroy = function () {
        this.modalContactDuplicateService.remove(this.id);
    };
    ModalDefault.prototype.open = function () {
        this.openModal = true;
    };
    ModalDefault.prototype.close = function () {
        this.openModal = false;
    };
    ModalDefault.ngInjectableDef = i0.defineInjectable({ factory: function ModalDefault_Factory() { return new ModalDefault(); }, token: ModalDefault, providedIn: "root" });
    return ModalDefault;
}());
export { ModalDefault };
