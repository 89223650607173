import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalAnimation} from '../../animations/animations';
import {ModalPageMobileService} from '../../shareds/modal-page-mobile.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {GlobalService} from '../../shareds/global.service';
import {Global} from '../../../services/global';

@Component({
	selector: 'app-modal-page-mobile',
	templateUrl: './modal-page-mobile.component.html',
	styleUrls: ['./modal-page-mobile.component.scss'],
	animations: [
		trigger('ModalAnimation', [
			transition(':enter', [
				style({
					opacity: '0.0',
					transform: 'translateY(100%)',
				}),
				animate('0.25s ease-out', style({
					opacity: '1.0',
					transform: 'translateY(0%)',
				}))
			]),
			transition(':leave', [
				animate('0.25s ease-out', style({
					opacity: '0.0',
					transform: 'translateY(100%)',
				}))
			])
		]),
		trigger('FadeIn', [
			transition(':enter', [
				style({
					opacity: '0.0',
				}),
				animate('0.25s ease-out', style({
					opacity: '1.0'
				}))
			]),
			transition(':leave', [
				style({
					opacity: '1.0',
				}),
				animate('0.25s ease-out', style({
					opacity: '0.0'
				}))
			])
		])
	],
})
export class ModalPageMobileComponent implements OnInit, OnDestroy {

	@Input() title = '';
	@Input() name: any;

	@Input() clean = false;
	@Input() animation = 'fadein';

	openPage = false;

	constructor(
		private _globalService: GlobalService,
		private modalPageMobileService: ModalPageMobileService
	) {

	}

	ngOnInit() {
		this.modalPageMobileService.add(this);
	}

	animationFadeIn() {
		if (this.animation === 'fadein') {
			return this.openPage;
		}

		return null;
	}

	animationBottom() {
		if (this.animation === '') {
			return this.openPage;
		}

		return null;
	}

	open() {
		this.openPage = true;
		this._globalService.updateFastMenuMobile(false);
	}

	close() {
		this.openPage = false;
		this.modalPageMobileService.removeHash(this);
		this._globalService.updateFastMenuMobile(true);
	}

	ngOnDestroy() {
		this.modalPageMobileService.remove(this);
		this._globalService.updateFastMenuMobile(true);
	}

}
