var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { CrudManager } from '../../services/crud-manager';
import * as i0 from "@angular/core";
var ClientsDemandService = /** @class */ (function (_super) {
    __extends(ClientsDemandService, _super);
    function ClientsDemandService() {
        var _this = _super.call(this) || this;
        _this.path = '/admin/clients-demand';
        return _this;
    }
    ClientsDemandService.prototype.processFilters = function (data) {
        if (data === void 0) { data = {}; }
        var params = __assign({}, data);
        return params;
    };
    ClientsDemandService.ngInjectableDef = i0.defineInjectable({ factory: function ClientsDemandService_Factory() { return new ClientsDemandService(); }, token: ClientsDemandService, providedIn: "root" });
    return ClientsDemandService;
}(CrudManager));
export { ClientsDemandService };
