import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Contacts} from '../../content/interfaces/contacts';
import {ModalContactDuplicateService} from '../../content/shareds/modal-contact-duplicate.service';
import {trigger, state, transition, style, animate} from '@angular/animations';
import {PlaySound} from '../../services/PlaySound';
import {Router} from '@angular/router';

@Component({
	selector: 'app-modal-contact-duplicate',
	templateUrl: './modal-contact-duplicate.component.html',
	styleUrls: ['./modal-contact-duplicate.component.scss'],
	animations: [
		trigger('EnterLeave', [
			transition(':enter', [
				style({opacity: '0.0'}),
				animate('0.3s ease-out', style({opacity: '1.0'}))
			]),
			transition(':leave', [
				animate('0.3s ease-out', style({opacity: '0.0'}))
			])
		])
	]
})
export class ModalContactDuplicateComponent implements OnInit, OnChanges, OnDestroy {

	emailExist = true;
	phoneExist = false;
	openModal = false;

	@Input() contactPage = false;
	@Input() id: string;
	@Input() contact: Contacts;
	@Input() type: string;
	@Output('useOtherPhone') _useOtherPhone = new EventEmitter();
	@Output('useOtherEmail') _useOtherEmail = new EventEmitter();
	@Output('useThisContact') _useThisContact = new EventEmitter();

	@Output()
	public clickOutside = new EventEmitter();

	constructor(
		private modalContactDuplicateService: ModalContactDuplicateService,
		private router: Router
	) {

	}

	ngOnInit() {
		this.modalContactDuplicateService.add(this);
	}

	ngOnChanges() {
		if (this.type === 'email') {
			this.emailExist = true;
			this.phoneExist = false;
		} else if (this.type === 'phone') {
			this.emailExist = false;
			this.phoneExist = true;
		}
	}

	useOther() {
		if (this.emailExist) {
			this._useOtherEmail.emit(this.contact);
		}

		if (this.phoneExist) {
			this._useOtherPhone.emit(this.contact);
		}

		this.openModal = false;
	}

	useThisContact() {
		this._useThisContact.emit(this.contact);
		this.openModal = false;
	}

	viewContact() {
		this.router.navigate(['/contacts/edit', this.contact.id]);
		this.openModal = false;
	}

	open() {
		this.openModal = true;
		PlaySound.play('error');
	}

	close() {
		this.useOther();
		this.openModal = false;
	}

	ngOnDestroy(): void {
		this.modalContactDuplicateService.remove(this.id);
	}

}
