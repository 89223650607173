import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var TaskSharedService = /** @class */ (function () {
    function TaskSharedService() {
        this.messageSource = new BehaviorSubject(false);
        this.currentTask = this.messageSource.asObservable();
    }
    TaskSharedService.prototype.changeTask = function (task) {
        this.messageSource.next(task);
    };
    TaskSharedService.ngInjectableDef = i0.defineInjectable({ factory: function TaskSharedService_Factory() { return new TaskSharedService(); }, token: TaskSharedService, providedIn: "root" });
    return TaskSharedService;
}());
export { TaskSharedService };
