import {Injectable, Input, OnDestroy, OnInit} from '@angular/core';
import {PlaySound} from '../../services/PlaySound';
import {ModalContactDuplicateService} from './modal-contact-duplicate.service';
import {InjectorInstance} from '../../services/InjectorInstance';
import {HttpClient} from '@angular/common/http';
import {Helpers} from '../../services/helpers';

@Injectable({
	providedIn: 'root'
})
export class ModalDefault implements OnInit, OnDestroy {

	openModal = false;

	@Input() id = Helpers.unique_hash();

	//
	private modalContactDuplicateService = InjectorInstance.Injector.get<ModalContactDuplicateService>(ModalContactDuplicateService);

	ngOnInit() {
		this.modalContactDuplicateService.add(this);
	}

	ngOnDestroy() {
		this.modalContactDuplicateService.remove(this.id);
	}

	open() {
		this.openModal = true;
	}

	close() {
		this.openModal = false;
	}
}
