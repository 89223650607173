import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {SubheaderComponent} from './subheader/subheader.component';
import {RouterModule} from '@angular/router';
import {ComponentsModule} from '../components/components.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ComponentsModule
    ],
    declarations: [HeaderComponent, SubheaderComponent],
    exports: [HeaderComponent, SubheaderComponent],
})
export class LayoutModule {
}
