import {PlaySound} from './../../../services/PlaySound';
import {ActivatedRoute} from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Component, OnInit, ChangeDetectorRef, EventEmitter} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {RealEstateService} from '../../models/real-estate.service';
import {humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput, UploadStatus} from 'ngx-uploader';
import {environment} from '../../../../environments/environment';
import {Global} from '../../../services/global';

@Component({
    selector: 'app-settings-website-design',
    templateUrl: './settings-website-design.component.html',
    styleUrls: ['./settings-website-design.component.scss']
})
export class SettingsWebsiteDesignComponent implements OnInit {

    realestate: any;
    form: FormGroup;
    formSubmitAttempt: boolean;
    estates: any;
    color_primary = '#00adeb';
    loadingLogo = false;
    loadingBackground = false;

    // Upload
    formData: FormData;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;
    optionsUpload: UploaderOptions;


    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private changeDetectorRef: ChangeDetectorRef,
        private realEstateService: RealEstateService
    ) {
    }

    ngOnInit() {
        this.realestate = this.route.snapshot.data.realestate;
        this.realestate.settings.COLOR_PRIMARY = '#' + this.realestate.settings.COLOR_PRIMARY;
        this.realestate.settings.COLOR_SECUNDARY = '#' + this.realestate.settings.COLOR_SECUNDARY;

        this.optionsUpload = {concurrency: 1};
        this.files = [];
        this.uploadInput = new EventEmitter<UploadInput>();
        this.humanizeBytes = humanizeBytes;

        this.buildForm();
    }

    buildForm() {
        this.form = this.fb.group({
            COLOR_PRIMARY: [this.realestate.settings.COLOR_PRIMARY, [Validators.required]],
            COLOR_SECUNDARY: [this.realestate.settings.COLOR_SECUNDARY, [Validators.required]],
            SLOGAN: [this.realestate.settings.SLOGAN]
        });
    }

    save() {
        let data = this.form.value;
        this.formSubmitAttempt = true;

        if (this.form.invalid) {
            console.log('error');

            PlaySound.play('error');
            this.toastr.error('Preencha os campos corretamente.');
            return false;
        }

        console.log('TCL: SettingsWebsiteDesignComponent -> save -> data', data);
        data.COLOR_PRIMARY = data.COLOR_PRIMARY.substr(1);
        data.COLOR_SECUNDARY = data.COLOR_SECUNDARY.substr(1);


        return this.realEstateService
            .updateMe(data)
            .subscribe(realestate => {
                console.log(realestate);
            });
    }

    onUploadOutput(output: UploadOutput, type: string): void {

        if (output.type === 'allAddedToQueue') {

            if (type === 'realestate_logo') {
                this.loadingLogo = true;
            }

            if (type === 'realestate_background') {
                this.loadingBackground = true;
            }

            const event: UploadInput = {
                type: 'uploadAll',
                url: environment.API + '/admin/uploads/' + type,
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + Global.token
                },
            };

            this.uploadInput.emit(event);

        } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
            this.files.push(output.file);
        } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
            this.files[index] = output.file;
        } else if (output.type === 'cancelled' || output.type === 'removed') {
            this.files = this.files.filter((file: UploadFile) => file !== output.file);
        } else if (output.type === 'done') {

            if (type === 'realestate_logo') {
                this.loadingLogo = false;
                this.realestate.logo = output.file.response.body[0].url + '?time=' + Date.now();
            }

            if (type === 'realestate_background') {
                this.realestate.background = output.file.response.body[0].url + '?time=' + Date.now();
                this.loadingBackground = false;
            }

        } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
            console.log(output.file.name + ' rejected');
        }

        this.files = this.files.filter(file => file.progress.status !== UploadStatus.Done);
    }


    /**
     * Verifica se campo é valido
     * @param {string} field
     * @returns {boolean}
     */
    isFieldValid(field: string) {
        return (!this.form.get(field).valid && this.formSubmitAttempt);
    }

}
