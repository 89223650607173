import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {debounceTime, distinctUntilChanged, filter, mergeMap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ContactsService} from '../models/contacts.service';
import {Helpers} from '../../services/helpers';
import {Contacts} from '../interfaces/contacts';

@Directive({
	selector: '[appContactDuplicateEmail]'
})
export class ContactDuplicateEmailDirective {

	focus = false;
	currentContact: any;
	currentText = '';
	searchTextChanged = new Subject<string>();

	@Input() contactId = 0;
	@Output() appContactDuplicateEmail = new EventEmitter();

	constructor(
		private elementRef: ElementRef,
		private contactsService: ContactsService
	) {
		this.searchTextChanged
			.pipe(debounceTime(350))
			.pipe(distinctUntilChanged())
			.pipe(filter(t => !!t))
			.pipe(mergeMap(search => this.searchUsers(search)))
			.subscribe(data => {
				this.searchTextChanged.next(null);
				this.setContact();
				for (let i in data['results']) {
					if (data['results'].hasOwnProperty(i)) {
						let contact: Contacts = data['results'][i];

						console.log(this.contactId);
						console.log(contact.id);

						if (contact.id === this.contactId) {
							return;
						}
					}
				}

				// leidesebastiao06@gmail.com
				for (let i in data['results']) {
					if (data['results'].hasOwnProperty(i)) {
						let contact: Contacts = data['results'][i];

						if (contact.email === this.currentText) {
							this.setContact(contact);
							return;
						}
					}
				}
			});
	}

	setContact(contact?) {
		this.currentContact = contact;

		if (!this.focus) {
			if (this.currentContact && !!this.elementRef.nativeElement.value) {
				this.appContactDuplicateEmail.emit(this.currentContact);
			}
		}
	}

	@Input('maskInput') maskInput: string;

	searchUsers(search) {
		return this.contactsService.search({
			'email': search
		});
	}

	@HostListener('focus', ['$event'])
	onFocus($event: any) {
		this.focus = true;
	}

	@HostListener('blur', ['$event'])
	onBlur() {
		this.focus = false;

		if (this.currentContact && !!this.elementRef.nativeElement.value) {
			this.appContactDuplicateEmail.emit(this.currentContact);
		}
	}

	@HostListener('change')
	onChange() {
		console.log('test');
	}

	@HostListener('keyup', ['$event'])
	onKeyup($event: any) {
		let newVal = $event.target.value;
		console.log(newVal);

		if (this.currentText === newVal) {
			return;
		}

		this.currentText = newVal;

		this.setContact();

		if (Helpers.verifyEmail(newVal)) {
			this.searchTextChanged.next(newVal);
		} else {
			this.searchTextChanged.next(null);
		}

	}

}
