import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ModalContactDuplicateService {

	private modals: any[] = [];

	add(modal: any) {
		// add modal to array of active modals
		this.modals.push(modal);
	}

	remove(id: string) {
		// remove modal from array of active modals
		this.modals = this.modals.filter(x => x.id !== id);
	}

	open(id: string) {
		console.log(id);
		console.log(this.modals);
		// open modal specified by id
		let modal: any = this.modals.filter(x => x.id === id)[0];
		if (modal) {
			modal.open();
		}

	}

	close(id: string) {
		// close modal specified by id
		let modal: any = this.modals.filter(x => x.id === id)[0];
		if (modal) {
			modal.close();
		}
	}
}
