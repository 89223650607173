import { MaskNumberDirective } from './../directives/mask-number/mask-number.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertiesCardComponent } from './properties-card/properties-card.component';
import { ContactsCardComponent } from './contacts-card/contacts-card.component';
import { UsersCardComponent } from './users-card/users-card.component';
import { TicketsCardComponent } from './tickets-card/tickets-card.component';
import { InitialsComponent } from './initials/initials.component';
import { PricePipe } from '../pipes/price.pipe';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserImgComponent } from './user-img/user-img.component';
import { StringTimeComponent } from './string-time/string-time.component';
import { MomentModule } from 'angular2-moment';
import { TextareaAutoSizingComponent } from './textarea-auto-sizing/textarea-auto-sizing.component';
import { InputDatepickerComponent } from './input-datepicker/input-datepicker.component';
import { ClickOutsideDirective } from '../directives/click-outside/click-outside.directive';
import { InputUsersComponent } from './input-users/input-users.component';
import { InputContactsComponent } from './input-contacts/input-contacts.component';
import { TicketImageComponent } from './ticket-image/ticket-image.component';
import { PhonePipe } from '../pipes/phone.pipe';
import { InputNumberIncrementComponent } from './input-number-increment/input-number-increment.component';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { MaskInputDirective } from '../directives/mask-input/mask-input.directive';
import { FieldErrorDisplayComponent } from '../../components/field-error-display/field-error-display.component';
import { ModalContactDuplicateComponent } from '../../components/modal-contact-duplicate/modal-contact-duplicate.component';
import { ContactDuplicateEmailDirective } from '../directives/contact-duplicate-email.directive';
import { ContactDuplicatePhoneDirective } from '../directives/contact-duplicate-phone/contact-duplicate-phone.directive';
import { InputAutocompleteLocationComponent } from './input-autocomplete-location/input-autocomplete-location.component';
import { ModalComponent } from './modal/modal.component';
import { PropertiesDuplicateCodDirective } from '../directives/properties-duplicate-cod/properties-duplicate-cod.directive';
import { MaskPhoneDirective } from '../directives/mask-phone/mask-phone.directive';
import { RouterModule } from '@angular/router';
import { ModalPropertyViewComponent } from './modal-property-view/modal-property-view.component';
import { DropMenuComponent } from './drop-menu/drop-menu.component';
import { PartnersPropertyCardComponent } from './partners-property-card/partners-property-card.component';
import { PartnersContactCardComponent } from './partners-contact-card/partners-contact-card.component';
import { ContactsDuplicateCodDirective } from '../directives/contacts-duplicate-cod/contacts-duplicate-cod.directive';
import { SelectUsersComponent } from './select-users/select-users.component';
import { BuyersPricePipe } from '../pipes/buyers-price.pipe';
import { InputMultiUsersComponent } from './input-multi-users/input-multi-users.component';
import { ModalSelectUsersComponent } from './modal-select-users/modal-select-users.component';
import { ModalSelectPropertiesComponent } from './modal-select-properties/modal-select-properties.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { SvgDefinitionsComponent } from './svg-definitions/svg-definitions.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ModalPageMobileComponent } from './modal-page-mobile/modal-page-mobile.component';
import { RouterModalPageMobileDirective } from '../directives/router-modal-page-mobile/router-modal-page-mobile.directive';
import { EquivalenceSearchNeighborhoodsComponent } from './equivalence-search-neighborhoods/equivalence-search-neighborhoods.component';
import { EquivalenceSearchCitiesComponent } from './equivalence-search-cities/equivalence-search-cities.component';
import { EquivalenceSearchSubneighborhoodsComponent } from './equivalence-search-subneighborhoods/equivalence-search-subneighborhoods.component';
import { ModalDeleteComponent } from './modal-delete/modal-delete.component';
import { InputLocationsComponent } from './input-locations/input-locations.component';
import { InfiniteScrollDirective } from '../directives/infinite-scroll/infinite-scroll.directive';
import { PartnersClientSharedComponent } from './partners-client-shared/partners-client-shared.component';
import { PartnersPropertyDemandComponent } from './partners-property-demand/partners-property-demand.component';
import { InputUploadComponent } from './input-upload/input-upload.component';
import {NgxUploaderModule} from 'ngx-uploader';
import {MaskEmailDirective} from '../directives/mask-email/mask-email.directive';
import {MaskCepDirective} from '../directives/mask-cep/mask-cep.directive';
import { PropertiesCardSimpleComponent } from './properties-card-simple/properties-card-simple.component';
import { PartnersAttachmentCardComponent } from './partners-attachment-card/partners-attachment-card.component';
import {SizeFilePipe} from '../pipes/SizeFile.pipe';
import { PartnersImageFullScreenComponent } from './partners-image-full-screen/partners-image-full-screen.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import {MaskBirthDirective} from '../directives/mask-birth/mask-birth.directive';
import {MaskRGDirective} from '../directives/mask-RG/mask-rg.directive';
import {MaskCPFDirective} from '../directives/mask-CPF/mask-cpf.directive';
import {MaskCNPJDirective} from '../directives/mask-CNPJ/mask-cnpj.directive';
import {PartnershipChatCustomPhone} from './partnership-chat-custom-phone/modal-select-users.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		InfiniteScrollModule,
		NgxUploaderModule,
		MomentModule
	],
	declarations: [
		PropertiesCardComponent,
		ContactsCardComponent,
		UsersCardComponent,
		TicketsCardComponent,
		InitialsComponent,
		BuyersPricePipe,
		PricePipe,
		PhonePipe,
		SizeFilePipe,
		AutocompleteComponent,
		UserImgComponent,
		StringTimeComponent,
		TextareaAutoSizingComponent,
		InputDatepickerComponent,
		ClickOutsideDirective,
		MaskCepDirective,
		InputUsersComponent,
		InputContactsComponent,
		TicketImageComponent,
		MaskInputDirective,
		InputNumberIncrementComponent,
		PaginationComponent,
		FieldErrorDisplayComponent,
		ContactDuplicateEmailDirective,
		ModalContactDuplicateComponent,
		ContactDuplicatePhoneDirective,
		InputAutocompleteLocationComponent,
		PropertiesDuplicateCodDirective,
		ContactsDuplicateCodDirective,
		MaskPhoneDirective,
		MaskBirthDirective,
		MaskRGDirective,
		MaskCPFDirective,
		MaskCNPJDirective,
		MaskEmailDirective,
		ModalComponent,
		ModalPropertyViewComponent,
		DropMenuComponent,
		PartnersPropertyCardComponent,
		PartnersContactCardComponent,
		SelectUsersComponent,
		InputMultiUsersComponent,
		ModalSelectUsersComponent,
		PartnershipChatCustomPhone,
		RouterModalPageMobileDirective,
		ModalSelectPropertiesComponent,
		SvgIconComponent,
		SvgDefinitionsComponent,
		ModalPageMobileComponent,
		EquivalenceSearchNeighborhoodsComponent,
		EquivalenceSearchCitiesComponent,
		EquivalenceSearchSubneighborhoodsComponent,
		ModalDeleteComponent,
		InputLocationsComponent,
		InfiniteScrollDirective,
		PartnersClientSharedComponent,
		PartnersPropertyDemandComponent,
		InputUploadComponent,
		MaskNumberDirective,
		PropertiesCardSimpleComponent,
		PartnersAttachmentCardComponent,
		PartnersImageFullScreenComponent,
		NavBarComponent,
		LoadingPageComponent
	],
	exports: [
		PropertiesCardComponent,
		ContactsCardComponent,
		UsersCardComponent,
		TicketsCardComponent,
		InitialsComponent,
		PricePipe,
		PhonePipe,
		SizeFilePipe,
		BuyersPricePipe,
		AutocompleteComponent,
		UserImgComponent,
		StringTimeComponent,
		TextareaAutoSizingComponent,
		InputDatepickerComponent,
		ClickOutsideDirective,
		MaskCepDirective,
		InputUsersComponent,
		InputContactsComponent,
		TicketImageComponent,
		InputNumberIncrementComponent,
		MaskInputDirective,
		PaginationComponent,
		FieldErrorDisplayComponent,
		ContactDuplicateEmailDirective,
		ModalContactDuplicateComponent,
		ContactDuplicatePhoneDirective,
		InputAutocompleteLocationComponent,
		PropertiesDuplicateCodDirective,
		ContactsDuplicateCodDirective,
		MaskPhoneDirective,
		MaskBirthDirective,
		MaskRGDirective,
		MaskCPFDirective,
		MaskCNPJDirective,
		MaskEmailDirective,
		ModalComponent,
		ModalPropertyViewComponent,
		DropMenuComponent,
		PartnersPropertyCardComponent,
		PartnersContactCardComponent,
		SelectUsersComponent,
		InputMultiUsersComponent,
		ModalSelectUsersComponent,
		PartnershipChatCustomPhone,
		RouterModalPageMobileDirective,
		ModalSelectPropertiesComponent,
		SvgIconComponent,
		SvgDefinitionsComponent,
		ModalPageMobileComponent,
		EquivalenceSearchNeighborhoodsComponent,
		EquivalenceSearchCitiesComponent,
		EquivalenceSearchSubneighborhoodsComponent,
		ModalDeleteComponent,
		InputLocationsComponent,
		InfiniteScrollDirective,
		PartnersClientSharedComponent,
		PartnersPropertyDemandComponent,
		InputUploadComponent,
		MaskNumberDirective,
		PropertiesCardSimpleComponent,
		PartnersAttachmentCardComponent,
		PartnersImageFullScreenComponent,
		NavBarComponent,
		LoadingPageComponent
	]
})
export class ComponentsModule {
}
