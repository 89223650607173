import {Environment} from './environment.interface';

export const environment: Environment = {
	production: true,
	master: false,
	mobile: false,
	API: 'https://api.bancodosimoveis.com.br/v1',
	SERVER: 'https://chat.bancodosimoveis.com.br',
	firebase: {
		apiKey: 'AIzaSyD1ilb9aDd5MLiORKJCsokwpchloau2sFk',
		authDomain: 'bancodosimoveis-staging.firebaseapp.com',
		databaseURL: 'https://bancodosimoveis-staging.firebaseio.com',
		projectId: 'bancodosimoveis-staging',
		storageBucket: 'bancodosimoveis-staging.appspot.com',
		messagingSenderId: '734575384449'
	}
};
