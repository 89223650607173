import * as io from 'socket.io-client';
import {environment} from '../../environments/environment';
import {WebsocketService} from '../content/shareds/websocket.service';
import {Injectable} from '@angular/core';
import {Global} from './global';

@Injectable({
	providedIn: 'root'
})
export class WebSocket {

	static messageID = 0;
	static queuePackets = [];
	static socket: SocketIOClient.Socket | any = false;
	static room: any;

	/**
	 * Enviar Mensagem
	 * @param data
	 * @param {boolean} save
	 * @returns {boolean}
	 */
	static sendMessage(data: any, save: boolean = false) {

		if (!Global.user) {
			return;
		}

		data.request_id = Global.user.id + '_' + this.messageID++;

		if (!WebSocket.socket || !WebSocket.socket.connected) {
			if (save) {
				WebSocket.queuePackets.push(data);
			}

			return false;
		}

		if (WebSocket.socket.connected) {
			WebSocket.socket.emit('message', data);
		}

		return data.request_id;
	}

	/**
	 * Constructor
	 * @param {WebsocketService} websocketService
	 */
	constructor() {
	}

	/**
	 * Conecta com servidor
	 * @param {string} token
	 */
	static connect(data: any) {
		WebSocket.socket = io(environment.SERVER, {
			query: `token=${data.token}`
		});

		WebSocket.socket.on('connect', function () {
			console.log('Conectado');

			WebsocketService.changeStatus('connect');

			if (WebSocket.room) {
				WebSocket.sendMessage(WebSocket.room);
			}

			/**
			 * Envia todas as mensagens
			 */
			for (let i in WebSocket.queuePackets) {
				console.log(WebSocket.queuePackets[i]);
				WebSocket.sendMessage(WebSocket.queuePackets[i]);
			}

			WebSocket.queuePackets = [];
		});

		WebSocket.socket.on('message', WebSocket.message);
		WebSocket.socket.on('disconnect', WebSocket.disconnect);
		WebSocket.socket.on('reconnecting', WebSocket.reconnecting);
	}

	static setRoom(room: string, _id?: number) {
		WebSocket.room = {
			type: 'room',
			content: {
				body: {
					name: room,
					id: _id,
				}
			}
		};

		WebSocket.sendMessage(WebSocket.room);
	}

	static message(data) {

		console.log(data);

		WebsocketService.changeMessage(data);

		if (data.type === 'mutation') {
			WebsocketService.changeMutation(data);
		}

		if (data.type === 'event') {
			WebsocketService.changeEvents(data);
		}
	}

	static disconnect() {
		WebsocketService.changeStatus('disconnect');
	}

	static reconnecting() {
		console.log('Reconectando');
		WebsocketService.changeStatus('reconnecting');
	}

	static isConnected() {
		return WebSocket.socket.connected;
	}

}
