<div class="select-users">
    <div class="select" (click)="showAutocomplete = !showAutocomplete">
        <div *ngIf="selectedUser">
            <app-user-img [user]="selectedUser" [size]="'small'">
            </app-user-img> {{ selectedUser.name }}
        </div>
    </div>

    <ul class="autocomplete-list" *ngIf="showAutocomplete">
        <li class="option"
            *ngFor="let user of users; let index = index"
            (mouseenter)="selectNumber = index"
            (click)="selectUser(user)"
            [class.active]="selectNumber == index">
            <app-user-img [user]="user" [size]="'small'"></app-user-img> {{ user.name }}
        </li>
    </ul>
</div>
