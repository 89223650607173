/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./users-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../user-img/user-img.component.ngfactory";
import * as i3 from "../user-img/user-img.component";
import * as i4 from "@angular/router";
import * as i5 from "./users-card.component";
var styles_UsersCardComponent = [i0.styles];
var RenderType_UsersCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UsersCardComponent, data: {} });
export { RenderType_UsersCardComponent as RenderType_UsersCardComponent };
export function View_UsersCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "card user-card text-center p-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-user-img", [], null, null, null, i2.View_UserImgComponent_0, i2.RenderType_UserImgComponent)), i1.ɵdid(2, 638976, null, 0, i3.UserImgComponent, [], { user: [0, "user"], size: [1, "size"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "role"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "email"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["class", "btn btn-shadow btn-icon btn-round bg-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(12, 2), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "fas fa-edit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn btn-shadow btn-icon btn-round bg-red"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteEmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "fas fa-trash"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; var currVal_1 = "big"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_5 = _ck(_v, 12, 0, "/users/edit", _co.user.id); _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.user.name; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.user.role_name; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.user.email; _ck(_v, 8, 0, currVal_4); }); }
export function View_UsersCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-users-card", [], null, null, null, View_UsersCardComponent_0, RenderType_UsersCardComponent)), i1.ɵdid(1, 114688, null, 0, i5.UsersCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UsersCardComponentNgFactory = i1.ɵccf("app-users-card", i5.UsersCardComponent, View_UsersCardComponent_Host_0, { user: "user" }, { delete: "delete" }, []);
export { UsersCardComponentNgFactory as UsersCardComponentNgFactory };
