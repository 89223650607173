import { of } from 'rxjs';
import { Global } from './global';
import { CookieService } from 'ngx-cookie-service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i2 from "@angular/router";
var TokenInterceptorService = /** @class */ (function () {
    function TokenInterceptorService(cookieService, _router) {
        this.cookieService = cookieService;
        this._router = _router;
    }
    TokenInterceptorService.prototype.intercept = function (req, next) {
        var _this = this;
        var token = this.cookieService.get('_UID_');
        var request = req.clone({
            setHeaders: {
                'Authorization': 'Bearer ' + token
            }
        });
        // return next.handle(request);
        /**
         * continues request execution
         */
        return next.handle(request).pipe(catchError(function (error, caught) {
            _this.handleAuthError(error);
            return of(error);
        }));
    };
    /**
     * manage errors
     * @param err
     * @returns {any}
     */
    TokenInterceptorService.prototype.handleAuthError = function (err) {
        if (err.error.code === 3 && Global.userLogged) {
            this._router.navigate(['/login']);
        }
        throw err;
    };
    TokenInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function TokenInterceptorService_Factory() { return new TokenInterceptorService(i0.inject(i1.CookieService), i0.inject(i2.Router)); }, token: TokenInterceptorService, providedIn: "root" });
    return TokenInterceptorService;
}());
export { TokenInterceptorService };
